import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { Colors } from '../../../models/Colors'
import { asyncGetActions, asyncGetOwnerAndMemberGroups } from '../../../store/reducers/AcyncActions'
import { IProjectsTabsNavProps } from '../models/IProjectsTabsNavProps'
import emptyImg from '../../../assets/icons/empty.png'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import ProjectTabBtn from './ProjectTabBtn/ProjectTabBtn'
import { tabsSlice } from '../store/reducers/TabsReducer'
import overviewIcon from '../../../assets/icons/read-svgrepo-com.svg'
import uploadIcon from '../../../assets/icons/upload-svgrepo-com.svg'
import loupeIcon from '../../../assets/icons/select-area-svgrepo-com.svg'
import annotateIcon from '../../../assets/icons/annotate-tool-svgrepo-com.svg'
import datasetIcon from '../../../assets/icons/data-center-data-warehouse-svgrepo-com.svg'
import generateIcon from '../../../assets/icons/element-plus-svgrepo-com.svg'
import versionsIcon from '../../../assets/icons/folders-svgrepo-com.svg'
// import deployIcon from '../../../assets/icons/rocket-launch-svgrepo-com.svg'
import healthIcon from '../../../assets/icons/stats.svg'
import lockIcon from '../../../assets/icons/lock-on-svgrepo-com.svg'
import settingsIcon from '../../../assets/icons/settings-svgrepo-com.svg'
import { datasetsReducer } from '../store/reducers/DatasetsReducer'
import { StyledModeWrapper, StyledProjectName } from '../constants/styled'
import usersGroupIcon from '../../../assets/icons/users-group-rounded.svg'
import ProjectSelect, { Option } from '../../../UI/ProjectSelect/ProjectSelect'
import { useParams } from 'react-router-dom'
import ProjectTabBtnDisabled from './ProjectTabBtn/ProjectTabBtn_disabled'
import { autoMarkupSlice } from '../../Draw/store/AutoMarkupSlice'
import chartIcon from '../../../assets/icons/chart-mixed.svg'
import { tasksReducer } from '../store/reducers/TasksReducer'
import { IGetTaskByIdResponce } from '../../../services/TasksService'
import { useTranslation } from 'react-i18next'
import { imagesListSlice } from '../../ImagesList/store/ImagesListReducer'

interface IIconDisabledProps {
  disabled?: boolean
}

export const StyledUsersGroupIcon = styled.i<IIconDisabledProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${usersGroupIcon});
  mask-image: url(${usersGroupIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledStatisticsIcon = styled.i<IIconDisabledProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${chartIcon});
  mask-image: url(${chartIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledOverview = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${overviewIcon});
  mask-image: url(${overviewIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledUploadIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${uploadIcon});
  mask-image: url(${uploadIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledLoupeIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${loupeIcon});
  mask-image: url(${loupeIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledDatasetIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${datasetIcon});
  mask-image: url(${datasetIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledGenerateIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${generateIcon});
  mask-image: url(${generateIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledVersionsIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${versionsIcon});
  mask-image: url(${versionsIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
// const StyledDeployIcon = styled.i<IIconDisabledProps>`
//   -webkit-mask-size: cover;
//   mask-size: cover;
//   -webkit-mask-image: url(${deployIcon});
//   mask-image: url(${deployIcon});
//   background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
//   width: 25px;
//   height: 25px;
// `
const StyledHealthIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${healthIcon});
  mask-image: url(${healthIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledAnnotateIcon = styled.i<IIconDisabledProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${annotateIcon});
  mask-image: url(${annotateIcon});
  background-color: ${props => props.disabled ? Colors.darkOrange : Colors.orange};
  width: 25px;
  height: 25px;
`
const StyledLockIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${lockIcon});
  mask-image: url(${lockIcon});
  background-color: ${Colors.orange};
  min-width: 20px;
  min-height: 20px;
`
const StyledSettingsIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${settingsIcon});
  mask-image: url(${settingsIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
`
const StyledProjectsTabsNav = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  min-width: 260px;
  display: flex;
  flex-direction: column;

  margin-bottom: 15px;
  padding-bottom: 15px;
  
  position: sticky;
  top: 60px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  gap: 5px;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  text-align: center;

  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;

  padding: 15px 15px 8px 15px;
`
const StyledBr = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${Colors.black};
`
const StyledImage = styled.img`
  width: 180px;
  margin-bottom: 10px;
  border-radius: 6px;
`
// const StyledProjectType = styled.h4`
//   text-align: left;
//   color: ${Colors.lightgray};
// `

const ProjectsTabsNav: FC<IProjectsTabsNavProps> = ({navigate, roleNumber, ...props}) => {
  const { t } = useTranslation()
  const currentVersion = useAppSelector(state => state.versionsReducer.currentVersion)
  const projectInfo = useAppSelector(state => state.WorkspacesReducer.projectInfo)
  const isLoading = useAppSelector(state => state.WorkspacesReducer.isRoleLoading)
  const progress = useAppSelector(state => state.AutoMarkupReducer.progress)
  const activeTab = useAppSelector(state => state.TabsReducer.activeTab)
  const userInfo = useAppSelector(state => state.userReduser.user)
  const dispatch = useAppDispatch()
  const params = useParams()

  const [mode, setMode] = useState<Option>({"title": "", "value": ""});
  
  const options: Option[] = [
    { "title": `${t('other.admin')}`, "value": "ADMIN" },
    { "title": `${t('other.moderator')}`, "value": "MODER" },
    { "title": `${t('other.labeller')}`, "value": "LABELLER" },
    { "title": `${t('other.guest')}`, "value": "USER" },
  ]

  useEffect(() => {
    dispatch(asyncGetActions())
    dispatch(asyncGetOwnerAndMemberGroups())
    dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(tasksReducer.actions.setCurrentTask({} as IGetTaskByIdResponce))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(true))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])
  
  useEffect(() => {
    if (roleNumber === 1 || roleNumber === 2 || roleNumber === 3 || roleNumber === 4) {
      dispatch(tabsSlice.actions.setViewMode(options[0]))
      setMode(options[0])
    }
    if (roleNumber === 5) {
      dispatch(tabsSlice.actions.setViewMode(options[1]))
      setMode(options[1])
    }
    if (roleNumber === 6) {
      dispatch(tabsSlice.actions.setViewMode(options[2]))
      setMode(options[2])
    }
    if (roleNumber === 7) {
      dispatch(tabsSlice.actions.setViewMode(options[3]))
      setMode(options[3])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleNumber])

  const handleRoleSelect = (value: string) => {
    const findedMode = options.find((item) => item.value === value);
    if (findedMode) {
      setMode(findedMode);
      dispatch(tabsSlice.actions.setViewMode(findedMode))
    }
  };
  // console.log('viewMode', viewMode)

  const onTabClick = (tab: string, roleNumber?: number) => {
    if (roleNumber && roleNumber <= 4) {
      navigate(`/projects/${projectInfo.id}/overview`)
    }
    if (tab === 'versions') {
      if (currentVersion?.id) {
        navigate(`/projects/${projectInfo.id}/versions/version/${currentVersion.id}`)
      }
      if (!currentVersion?.id) {
        navigate(`/projects/${projectInfo.id}/versions`)
      }
    }
    if (params.tab !== tab) {
      navigate(`/projects/${projectInfo.id}/${tab}`)
      dispatch(tabsSlice.actions.setActiveTab(tab))
      dispatch(imagesListSlice.actions.setInitialState())
      dispatch(datasetsReducer.actions.setInitialState())
    }
  }

  return (
    <StyledProjectsTabsNav>
        <StyledHeader>
            <StyledImage src={projectInfo.projectLogoUrl} alt="ProjectImage"/>
            <FlexContainer paddingProps='0 15px;' direction='row' justify='space-between' align='center'>
              <p>{userInfo.firstName}</p>
              {roleNumber <= 4 && mode?.title ? 
                <StyledModeWrapper>
                  <StyledSettingsIcon/>
                  <ProjectSelect options={options} mode='rows' selected={mode || null} onChange={handleRoleSelect} placeholder="Select mode"/>
                </StyledModeWrapper>
              : ''}
            </FlexContainer>
            <FlexContainer paddingProps='0 15px' direction='row' justify='flex-start' align='center' gap='5px'>
              <StyledLockIcon/>
              <StyledProjectName>{projectInfo.name}</StyledProjectName>
            </FlexContainer>
            <FlexContainer paddingProps='0 15px' direction='row' justify='flex-start' align='center' gap='5px'>
              {/* <StyledProjectType>{projectInfo.type === 0 ? 'Object detection' :''}</StyledProjectType> */}
            </FlexContainer>
        </StyledHeader>
        <StyledBr/>
          <Wrapper>
            
            {roleNumber <= 4
              ? <ProjectTabBtn onClick={() => onTabClick('manage')} active={activeTab === 'manage'} imageProps={<StyledUsersGroupIcon/>}>{t('authorized.project.nav.management')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledUsersGroupIcon/>}>{t('authorized.project.nav.management')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('statistics', roleNumber)} active={activeTab === 'statistics'} imageProps={<StyledStatisticsIcon/>}>{t('authorized.project.nav.statistics')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledStatisticsIcon/>}>{t('authorized.project.nav.statistics')}</ProjectTabBtnDisabled>
            }

            <ProjectTabBtn onClick={() => onTabClick('overview')} active={activeTab === 'overview'} imageProps={<StyledOverview/>}>{t('authorized.project.nav.overview')}</ProjectTabBtn>

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('upload', roleNumber)} active={activeTab === 'upload'} imageProps={<StyledUploadIcon/>}>{t('authorized.project.nav.upload')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledUploadIcon/>}>{t('authorized.project.nav.upload')}</ProjectTabBtnDisabled>
            }
            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('assign', roleNumber)} active={activeTab === 'assign'} imageProps={<StyledLoupeIcon/>}>{t('authorized.project.nav.assign')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledLoupeIcon/>}>{t('authorized.project.nav.assign')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 6 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('annotate')} active={activeTab === 'annotate'} imageProps={<StyledAnnotateIcon/>}>{t('authorized.project.nav.annotate')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledAnnotateIcon/>}>{t('authorized.project.nav.annotate')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('datasets', roleNumber)} active={activeTab === 'datasets'} imageProps={<StyledDatasetIcon/>}>{t('authorized.project.nav.datasets')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledDatasetIcon/>}>{t('authorized.project.nav.datasets')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('health')} active={activeTab === 'health'} imageProps={<StyledHealthIcon/>}>{t('authorized.project.nav.healthCheck')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledHealthIcon/>}>{t('authorized.project.nav.healthCheck')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('generate', roleNumber)} active={activeTab === 'generate'} imageProps={<StyledGenerateIcon/>}>{t('authorized.project.nav.generate')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledGenerateIcon/>}>{t('authorized.project.nav.generate')}</ProjectTabBtnDisabled>
            }

            {roleNumber <= 4 && !isLoading
              ? <ProjectTabBtn onClick={() => onTabClick('versions', roleNumber)} active={activeTab === 'versions'} imageProps={<StyledVersionsIcon/>}>{t('authorized.project.nav.versions')}</ProjectTabBtn>
              : <ProjectTabBtnDisabled imageProps={<StyledVersionsIcon/>}>{t('authorized.project.nav.versions')}</ProjectTabBtnDisabled>
            }   
          </Wrapper>
    </StyledProjectsTabsNav>
  )
}

export default ProjectsTabsNav