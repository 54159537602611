import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IArchive, IFullVersionInfo, IGenerateLabel, IOptionReq, IShortVersionInfo, IVersionImage } from '../../../../services/VersionsService';

export interface IFullVersionWithArchive {
    versionNumber: number;
    id: string;
    name: string;
    project: string;
    createdAt: string;
    updatedAt: string;

    versionInfo: {
        labels: IGenerateLabel[] | string[];
        renameLabels: IGenerateLabel[];
        rebalance: {
            testCount: number;
            trainCount: number;
            validCount: number;
        }
        preprocessing: IOptionReq[];
        augmentation: IOptionReq[];
    }
    trainTotal: number;
    validTotal: number;
    testTotal: number;
    archive: IArchive[];
}

interface ISetCurrentVersionAction {
    version: IFullVersionInfo;
    archive: IArchive[];
}


export interface IGrayscaleAugOption {
    method: string;
    selected: boolean;
    percentage: number;
}


interface IReducerState {
    isLoading: boolean;
    error: string | null;

    versionsSummary: IShortVersionInfo[];
    currentVersion: IFullVersionWithArchive;
    progress: any;
    isGenerating: boolean;
    job: string;

    renameLabels: IGenerateLabel[];
    labels: string[];
    versionApplyRename: boolean;
}

interface IRenamePayload {
    name: string;
    id: string;
}

const initialState: IReducerState = {
    isLoading: false,
    error: '',

    versionsSummary: [],
    currentVersion: {} as IFullVersionWithArchive,
    isGenerating: false,
    progress: 0,
    job: '',
    renameLabels: [],
    labels: [],

    versionApplyRename: false
}

export const versionsReducer = createSlice(
{
    name: 'generate',
    initialState,

    reducers: {
        setInitialState(state) {
            state.currentVersion = initialState.currentVersion;
            state.versionsSummary = initialState.versionsSummary;
            state.error = '';
            state.isLoading = false;
            state.progress = 0;
            state.job = '';
            state.isGenerating = false;
        },
        setLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setErrorState(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },

        setCurrentVersion(state, action: PayloadAction<ISetCurrentVersionAction>) {
            const res = JSON.parse(JSON.stringify(action.payload))
            state.currentVersion = {...res.version, archive: res.archive}
            state.labels = res.version.versionInfo.labels
            state.currentVersion.versionInfo.labels = res.version.versionInfo.renameLabels
            state.renameLabels = res.version.versionInfo.renameLabels
        },

        renameVersion(state, action: PayloadAction<IRenamePayload>) {
            const findedVersion = state.versionsSummary.find(version => version.id === action.payload.id)
            if (findedVersion) {
                findedVersion.name = action.payload.name
            }
            if (state.currentVersion?.id) {
                state.currentVersion.name = action.payload.name
            }
        },

        deleteVersion(state, action: PayloadAction<string>) {
            state.currentVersion = initialState.currentVersion
            const filtetedSummary = state.versionsSummary.filter(versionFromSummary => versionFromSummary.id !== action.payload)
            state.versionsSummary = filtetedSummary
        },

        setVersionsSummary(state, action: PayloadAction<IShortVersionInfo[]>) {
            state.versionsSummary = action.payload
        },
        setIsGenerating(state, action: PayloadAction<boolean>) {
            state.isGenerating = action.payload
        },
        setProgress(state, action: PayloadAction<any>) {
            state.progress = action.payload
        },
        setJob(state, action: PayloadAction<string>) {
            state.job = action.payload
        },


        renameLabel(state, action: PayloadAction<IGenerateLabel>) {
            const generateLabels = state.currentVersion.versionInfo.labels as IGenerateLabel[]
            const findedGenerateLabel = generateLabels.find(label => label.oldName === action.payload.oldName)
            if (findedGenerateLabel) {
                findedGenerateLabel.newName = action.payload.newName
            }
        },

        rejectVersionsRenameLabels(state) {
                  console.log('in reject HIII HEITLER')
            const currentVersionGenerateLabels = state.labels.map(label => ({oldName: label, newName: label}))
            if (state?.currentVersion?.id) {
                state.currentVersion.versionInfo.labels = currentVersionGenerateLabels
            }
            
        },

        setVersionRenamedGenerateLabels(state, action: PayloadAction<IGenerateLabel[]>) {
        console.log('in set HIII HEITLER')
            if (state?.currentVersion?.id) {
                state.currentVersion.versionInfo.labels = action.payload
                state.currentVersion.versionInfo.labels.forEach(label => {
                    if (!label.newName) {
                        label.newName = label.oldName
                    }
                })
            }

        },
    }
},

)

export default versionsReducer.reducer;