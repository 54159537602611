/* eslint-disable @typescript-eslint/no-unused-vars */
import rough from 'roughjs/bundled/rough.esm'
import { ICanvasElement } from '../components/DrawMain'
import AnnotateService, { IGetDatasetImageInfoResponce, IGetImageInfoResponce } from '../../../services/AnnotateService'
import ProjectService from '../../../services/ProjectService'
import { Option } from '../../../UI/ProjectSelect/ProjectSelect';
import { INewImage } from '../../../services/BatchesService';

interface IPoint {
  x: number;
  y: number;
}

interface ILine {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export interface blackoutPolygon {
  id: number;
  polygonPoints: number[][] | undefined;
  type: string;
  roughElement: any;
}

interface ICursorOnPoint {
  point: number[];
  index: number;
}

const generator = rough.generator()

export class CreateElement {
  static simpleElement = (
    id: number, x1: number, y1: number, x2: number, y2: number, type: string, label:string, labelId?: string, labelColor?: string,
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null, 
    success?: boolean | null, info?: string | null, prediction_id?: string | null
  ) => {
    let roughElement

    if (type === 'line') {
      roughElement = generator.line(x1, y1, x2, y2, {roughness: 0, strokeWidth: 3, stroke: labelColor ? labelColor : '#ff8888'})
    }

    if (type === 'pointsRect') {
      roughElement = generator.rectangle(x1, y1, x2-x1, y2-y1, {
        roughness: 0, strokeWidth: 2, stroke: labelColor ? labelColor : '#ff8888', 
        fill: labelColor ? labelColor + 21 : "#ff888867", fillStyle: "solid", fillWeight: 5,
        strokeLineDash: [2]
      })
    }

    if (type === 'rectangle') {
      automarkup
       ?
       roughElement = generator.rectangle(x1, y1, x2-x1, y2-y1, {   
        roughness: 0, strokeWidth: 2, stroke: labelColor ? labelColor : '#ff8888', 
        fill: labelColor ? labelColor + 21 : "#ff888821", fillStyle: "cross-hatch", fillWeight: 5, 
        hachureAngle: 0, hachureGap: 17
      })
       : 
       roughElement = generator.rectangle(x1, y1, x2-x1, y2-y1, {   
        roughness: 0, strokeWidth: 2, stroke: labelColor ? labelColor : '#ff8888', 
        fill: labelColor ? labelColor + 21 : "#ff888821", fillStyle: "solid", fillWeight: 5,
       })
    }

    if (type === 'imageRec') {
      roughElement = generator.rectangle(x1, y1, x2-x1, y2-y1, {roughness: 0, strokeWidth: 0, stroke: "none"})
    }

    if (type === 'lineDashed') {
      roughElement = generator.line(x1, y1, x2, y2, {roughness: 0, strokeWidth: .5, stroke: "#ffffff", strokeLineDash: [3]})
    }

    return {
      id, x1, y1, x2, y2, type, roughElement, label, labelId, labelColor,
      name, classId, model_id, automarkup, success, info, prediction_id
    }
  }

  static point = (
    id: number, x1: number, y1: number, type: string, label:string, labelId?: string, labelColor?: string, 
    parent?: string | number, pointText?: string | number, elements?: any,
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null, 
    success?: boolean | null, info?: string | null, prediction_id?: string | null
  ) => {
    let roughElement
    let pointNumber
    let pointParentId = parent
    let x2 = x1
    let y2 = y1

    if (type === 'circle') {
      roughElement = generator.circle(x1, y1, 11, {
        roughness: 0, 
        strokeWidth: 1, 
        stroke: labelColor ? labelColor : '#ff8888', 
        fill: labelColor ? labelColor + 41 : "#ff888821", 
        fillStyle: "solid", fillWeight: 5
      })
    }
  
    if (elements?.length) {
      let parentPointsArr = elements.filter((elem: any) => elem.pointParentId === pointParentId)
      let pointsArr = []
  
      elements.forEach((element: any) => {
        if (element.type === 'circle' && element.labelColor === labelColor) {
          pointsArr.push(true)
        }
        if (!pointText && pointText !== 0) {
          pointNumber = parentPointsArr.length
        }
        if (pointText || pointText === 0) {
          pointNumber = pointText
        }
      });
    }
    
    return {
      id, pointParentId, x1, y1, x2, y2, type, roughElement, label, labelId, labelColor, pointNumber,
      name, classId, model_id, automarkup, success, info, prediction_id
    }
  }

  static dragPoint = (x1: number, y1: number) => {
    let roughElement = generator.circle(x1, y1, 5, {
      roughness: 0, 
      strokeWidth: 1, 
      stroke: '#0b0b0b', 
      fill: "#ffffffd8", 
      fillStyle: "solid", fillWeight: 5
    })
    return {roughElement}
  }

  static polygon = (
    id: number, x1: number, y1: number, x2: number, y2: number, type: string, label:string, labelId?: string, labelColor?: string, polygonPoints?: number[][],         
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null,        
    success?: boolean | null, info?: string | null, prediction_id?: string | null
  ) => {
    let roughElement
    if (type === 'polygon') {
      roughElement = generator.polygon(polygonPoints, {
        roughness: 0, strokeWidth: 1, stroke: labelColor ? labelColor : '#ff8888', 
        fill: labelColor ? labelColor + 21 : "#ff888821", fillStyle: "solid", fillWeight: 5,
      })
    }
    return {
      id, x1, y1, x2, y2, polygonPoints, type, roughElement, label, labelId, labelColor, 
      name, classId, model_id, automarkup, success, info, prediction_id
    }
  }

  static BlackoutPolygon = (id: number, type: string, polygonPoints?: number[][]) => {
    let roughElement
    if (type === 'polygon') {
      roughElement = generator.polygon(polygonPoints, {
        roughness: 0, strokeWidth: 0, stroke: 'none', 
        fill: "#00000099", fillStyle: "solid", fillWeight: 5,
      })
    }
    return {id, polygonPoints, type, roughElement}
  }
}

// Класс с методами для обновления элементов рисовалки
export class UpdateElement {
  //Обновление обычного элемента: прямоугольники, линии
  static simple = (
    elements: any, setElements: (any), id: number, x1: number, y1: number, x2: number, y2: number, type: string, label:string, labelId?: string, labelColor?: string, isOverwrite?:boolean,
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null, 
    success?: boolean | null, info?: string | null, prediction_id?: string | null
  ) => {
    const updatedElement = CreateElement.simpleElement(
      id, x1, y1, x2, y2, type, label, labelId, labelColor,
      name, classId, model_id, false, success, info, prediction_id
    )
    const elementsCopy = [...elements]
    elementsCopy[id] = updatedElement
    // console.log('isOverwrite', isOverwrite)
    if (!isOverwrite) {
      setElements(elementsCopy, true) 
    }
    if (isOverwrite === true) {
      setElements(elementsCopy) 
    }
  }

  //Обновление точки
  static point = (
    elements: any, setElements: (any), id: number, x1: number, y1: number, type: string, label:string, labelId?: string, labelColor?: string, pointParentId?: number | string, pointText?: number,
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null, 
    success?: boolean | null, info?: string | null, prediction_id?: string | null, isOverwrite?: boolean
  ) => {
    const updatedElement = CreateElement.point(
      id, x1, y1, type, label, labelId, labelColor, pointParentId, pointText, elements,
      name, classId, model_id, false, success, info, prediction_id
    )
    const elementsCopy = [...elements]
    elementsCopy[id] = updatedElement
    if (!isOverwrite) {
      setElements(elementsCopy, true) 
    }
    if (isOverwrite === true) {
      setElements(elementsCopy) 
    }
  }

  //Обновление линий ресуемого полигона
  static polygonLines = (elements: any, setElements: (any), id: number, x1: number, y1: number, x2: number, y2: number, type: string, label:string, labelId?: string, labelColor?: string) => {
    const updatedElement = CreateElement.simpleElement(id, x1, y1, x2, y2, type, label, labelId, labelColor)
    const elementsCopy = [...elements]
    elementsCopy[id] = updatedElement
    setElements(elementsCopy) 
  }

  //Обновление полигона
  static polygon = (
    elements: any, setElements: (any), id: number, x1: number, y1: number, x2: number, y2: number, type: string, label:string, labelId?: string, labelColor?: string, polygonPoints?: number[][],
    name?: string | null, classId?: string | null, model_id?: string | null, automarkup?: boolean | null, 
    success?: boolean | null, info?: string | null, prediction_id?: string | null
  ) => {
    const updatedElement = CreateElement.polygon(
      id, x1, y1, x2, y2, type, label, labelId, labelColor, polygonPoints,
      name, classId, model_id, false, success, info, prediction_id
    )
    const elementsCopy = [...elements]
    elementsCopy[id] = updatedElement
    setElements(elementsCopy, true) 
  }
}

// x-x1, y-y1 чек позиций, правильно ли они расположены друг к другу
// 5 - смещение
// Если оба выражения находятся в пределах 10 точек, то возвращает переданную position
// В противном случае null 
export const nearPoint = (clientX: number, clientY: number, x: number, y: number, position: string) => {
  return Math.abs(clientX - x) < 10 && Math.abs(clientY-y) < 10 ? position : null
}
  
//Проверка нахождения элемента под курсором и возврат позиции курсора на элементе
export const positionWithinElement = (x: number, y: number, element:ICanvasElement) => {
  const {type, x1, x2, y1, y2, polygonPoints} = element
  
  if (type === 'pointsRect') {
    const topLeft = nearPoint(x, y, x1, y1, 'topLeft')
    const topRight = nearPoint(x, y, x2, y1, 'topRight')
    const bottomLeft = nearPoint(x, y, x1, y2, 'bottomLeft')
    const bottomRight = nearPoint(x, y, x2, y2, 'bottomRight')
    const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null
  
    return topLeft || topRight || bottomLeft || bottomRight || inside
  } 

  if (type === 'polygon') {
    let cursorOnPoint: null | string = null

    polygonPoints?.forEach(polygonPoint => {
      const onPoint = nearPoint(x, y, polygonPoint[0], polygonPoint[1], 'onPoint')
      if (onPoint) {
        cursorOnPoint = onPoint
      }
    })

    const inside = rayTracingOnPolygon(x, y, element)
    return cursorOnPoint || inside
  }

  if (type === 'imageRec') {
    const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "insideImage" : null
    return inside
  }

  if (type === 'rectangle') {
    const topLeft = nearPoint(x, y, x1, y1, 'topLeft')
    const topRight = nearPoint(x, y, x2, y1, 'topRight')
    const bottomLeft = nearPoint(x, y, x1, y2, 'bottomLeft')
    const bottomRight = nearPoint(x, y, x2, y2, 'bottomRight')
    const inside = x >= x1 && x <= x2 && y >= y1 && y <= y2 ? "inside" : null
  
    return topLeft || topRight || bottomLeft || bottomRight || inside
  } 

    //check line
  if (type === 'line') {
    const a = { x: x1, y: y1 }
    const b = { x: x2, y: y2 }
    const c = { x, y }
    // offset равен ~10 точкам вокруг линии
    const offset = distance(a, b) - (distance(a, c) + distance(b, c))
    const start = nearPoint(x, y, x1, y1, 'start')
    const end = nearPoint(x, y, x2, y2, 'end')
    const inside = Math.abs(offset) < 1 ? "inside" : null
    
    return start || end || inside
  }

  //Point
  if (type === 'circle') {
    const circleCenter = {x: x1, y: y1}
    const cursorPosition = {x: x, y: y}
    const distanceFromCursorToRadius = distance(cursorPosition, circleCenter)
    // console.log('distanceFromCursorToRadius', distanceFromCursorToRadius)
    return distanceFromCursorToRadius < 8 || distanceFromCursorToRadius === 0 ? 'inside' : null
  }
}

//векторное произведение двух векторов
const getVector = (ax: number,ay: number,bx: number,by: number) => {
	return ax*by-bx*ay;
}

//проверка пересечения по векторам
const crossingCheck = (p1: IPoint, p2: IPoint, p3: IPoint, p4: IPoint)  => {
	const v1 = getVector(p4.x - p3.x, p4.y - p3.y, p1.x - p3.x, p1.y - p3.y);
	const v2 = getVector(p4.x - p3.x, p4.y - p3.y, p2.x - p3.x, p2.y - p3.y);
	const v3 = getVector(p2.x - p1.x, p2.y - p1.y, p3.x - p1.x, p3.y - p1.y);
	const v4 = getVector(p2.x - p1.x, p2.y - p1.y, p4.x - p1.x, p4.y - p1.y);

	if (v1 * v2 < 0 && v3 * v4 < 0) return true;
	else return false;
}

//Трассировка луча на многоугольник. Проверка, находится ли курсор внутри многоуголика.
//Метод трассировки луча.
export const rayTracingOnPolygon = (x: number, y: number, element: ICanvasElement) => {
  if (element.polygonPoints) {
    const ray = {x1: x, y1: y, x2: 10000, y2: y}
    const polygonPoints = [...element.polygonPoints]
    const polygonLines: ILine[] = []
    
    let counter = 0

    polygonPoints.forEach((point, index) => {
      const line: ILine = {x1: 0, x2: 0, y1: 0, y2: 0}

      if (!polygonPoints[index + 1]) {
        line.x1 = polygonPoints[index][0]
        line.y1 = polygonPoints[index][1]
        line.x2 = polygonPoints[0][0]
        line.y2 = polygonPoints[0][1]
      }
      if (polygonPoints[index + 1]) {
        line.x1 = polygonPoints[index][0]
        line.y1 = polygonPoints[index][1]
        line.x2 = polygonPoints[index + 1][0]
        line.y2 = polygonPoints[index + 1][1]
      }

      polygonLines.push(line)
    })

    polygonLines.forEach(line => {
      const isCrossing = crossingCheck(
        {x: ray.x1, y: ray.y1 - 1}, {x: 10000, y: ray.y1 - 2}, 
        {x: line.x1, y: line.y1}, {x: line.x2, y: line.y2}
      )
      if (isCrossing) {
        counter += 1
      }
    })

    if (counter % 1 === 0 && counter % 2 !== 0) {
      return 'inside'
    }
    return null
  }
}
  
export const distance = (a: any, b: any) => Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))

//Возвращает существующий элемент и позицию
export const getElementAtPosition = (x: number, y: number, elements:ICanvasElement[]) => {
  return elements
    .map((element) => ({...element, position: positionWithinElement(x, y, element)}))
    .find((element) => element?.position !== null)
}

//Регулировка координат элемента. Верхний-левый угол прямоугольника всегда будет начальной точкой,
//независимо от способа рисования (справа-налево, слева-направо и т.п.).
export const adjustElementCoordinates = (element: ICanvasElement) => {
  const {x1, y1, x2, y2, type} = element
  if (type === "rectangle" || type === "pointsRect") {
    const minX = Math.min(x1, x2)
    const maxX = Math.max(x1, x2)
    const minY = Math.min(y1, y2)
    const maxY = Math.max(y1, y2)
    return {x1:minX, y1:minY, x2:maxX, y2: maxY}
  } else {
    if (x1 < x2 || (x1 === x2 && y1 < y2)) {
      //Возврат этих же координат, т.к. находятся на правильных местах
      return {x1, y1, x2, y2}
    } else {
      return {x1:x2, y1:y2, x2:x1, y2:y1}
    }
  }
}

export const cursorForPosition = (position: string) => {
  switch (position) {
    case 'topLeft':
    case 'bottomRight':
    case "start":
    case "end":
    case "onPoint":
      return "nwse-resize";
    case "topRight":  
    case "bottomLeft":  
      return "nesw-resize";
    default: 
      return "move";
  }
}

export const resizedCords = (clientX: number, clientY: number, position: string, cords: any) => {
  const {x1, y1, x2, y2} = cords
  let resizedPointCords
  if (position === 'topLeft' || position === 'start' || position === 'onPoint') {
    resizedPointCords = {x1: clientX, y1: clientY, x2, y2}
  }
  if (position === 'topRight') {
    resizedPointCords = {x1, y1: clientY, x2: clientX, y2}
  }
  if (position === "bottomLeft") {
    resizedPointCords = {x1: clientX, y1, x2, y2: clientY}
  }
  if (position === "bottomRight" || position === "end" || position === 'onPoint') {
    resizedPointCords = {x1, y1, x2: clientX, y2: clientY}
  }
  return resizedPointCords
}

//Получение меток в зависимости от экшена при открытии рисовалки
export const getAnnotsInfo = async (imgParam: string) => {
  try {
    const {data} = await AnnotateService.getImageInfo(imgParam)
    console.log('getAnnotsInfo', data)
    return data
  } catch (error) {
    console.log('getAnnotsInfo', error)
  }
}

export const dataConverterForApi = (elements: any[], naturalImageWidth: number, naturalImageHeight: number, filename: string, projectId: string) => {
  if (elements?.length) {
    const elementsWithZeroPontCords = elements.filter((elem: any) => elem.id !== 0).map((elem: any) => (
      {
        xmin: Math.round(elem.x1 + naturalImageWidth/2),
        xmax: Math.round(elem.x2 + naturalImageWidth/2),
        ymin: Math.round(elem.y1 + naturalImageHeight/2),
        ymax: Math.round(elem.y2 + naturalImageHeight/2),
        points: elem?.polygonPoints,
        type: elem.type,
        label: elem.labelId,
        color: elem.labelColor,

        name: elem.label,
        classId: elem.classId, 
        model_id: elem.model_id,
        automarkup: elem.automarkup,
        success: elem.success,
        info: elem.info,
        prediction_id: elem.prediction_id
      }
    ))

    const elementsWithPolygons = setPolygonsCords(elementsWithZeroPontCords, naturalImageWidth/2, naturalImageHeight/2)
    const elementsWithoutPoints = elementsWithPolygons.filter((elem:any) => elem.type !== 'circle' && elem.type !== 'pointsRect')
    const sordedElements = sortElementsWithPoints(elements, naturalImageWidth, naturalImageHeight)
    
    if (projectId && filename) {
        const apiElems = {
          imageId: filename,
          subStatus: '',
          boxes: [...elementsWithoutPoints, ...sordedElements]
        }
        return apiElems
    }
    return null
  }
}

//Получение меток с бэка и формирование данных метод под формат рисовалки.
export const setApiLabels = async (elements: any, imageWidth: number, imageHeight: number, imageInfo: IGetImageInfoResponce ) => {
    const forDraw: any = []
    if (imageInfo?.image) {
      await imageInfo?.boxes.forEach((element: any) => {
        if (element.type === 'polygon') {
          const arrPoints: number[][] = element.points.map((point: any) => [point.x - imageWidth/2, point.y - imageHeight/2])
          const newElem = CreateElement.polygon(
            elements.length, 
            element.xmin - imageWidth/2, 
            element.ymin - imageHeight/2, 
            element.xmax - imageWidth/2, 
            element.ymax - imageHeight/2,
            element.type || 'rectangle',
            element.name, 
            element.label, 
            element.color,
            arrPoints,

            element.name,
            element.classId, 
            element.model_id,
            element.automarkup,
            element.success,
            element.info,
            element.prediction_id
          )
          forDraw.push(newElem)
        }

        if (element.type === 'rectangle') {
          const newElem = CreateElement.simpleElement(
            elements.length, 
            element.xmin - imageWidth/2, 
            element.ymin - imageHeight/2, 
            element.xmax - imageWidth/2, 
            element.ymax - imageHeight/2,
            element.type || 'rectangle',
            element.name, 
            element.label, 
            element.color,

            element.name,
            element.classId, 
            element.model_id,
            element.automarkup,
            element.success,
            element.info,
            element.prediction_id
          )
          forDraw.push(newElem)
        }

        if (element.type === 'points') {
          const parent = CreateElement.simpleElement(
            forDraw.length, 
            element.xmin - imageWidth/2, 
            element.ymin - imageHeight/2, 
            element.xmax - imageWidth/2, 
            element.ymax - imageHeight/2,
            element.type === 'points' ? 'pointsRect' : 'rectangle',
            element.name, 
            element.label, 
            element.color,

            element.name,
            element.classId, 
            element.model_id,
            element.automarkup,
            element.success,
            element.info,
            element.prediction_id
          )
          forDraw.push(parent)
          

          if (element?.points?.length) {
            element.points.forEach((point: any) => {
              const newElem = CreateElement.point(
                forDraw.length,
                point.x - imageWidth/2, 
                point.y - imageHeight/2, 
                'circle',
                parent.label, 
                parent.labelId, 
                parent.labelColor,
                parent.id + 1,
                '',
                forDraw,

                point.name,
                point.classId, 
                point.model_id,
                point.automarkup,
                point.success,
                point.info,
                point.prediction_id
              )
              forDraw.push(newElem)
            })
          }
        }

      }); 
    }
    return forDraw
}

export const getPolygonPointsFromPolygonLines = (polygonLines: ICanvasElement[]) => {
  const points: number[][] = []

  if (polygonLines?.length) {
    polygonLines.forEach(line => {
      points.push([line.x1, line.y1])
    })
  }

  return points
}

//Функция проверки нахождения ВСЕХ точек внутри прямоугольника точек
export const checkPointsOnPointsRect = (points: any[], elements: ICanvasElement[]) => {
  if (points?.length) {
    const boolArray: string[] = []
    points.forEach(point => {
      const isPointOnImageRect = getElementAtPosition(point.x1, point.y1, elements)
      if (isPointOnImageRect?.position === 'insideRect') {
        boolArray.push('insideRect')
      }
      if (isPointOnImageRect?.position === null 
          || isPointOnImageRect?.position === undefined
          || isPointOnImageRect?.position === 'topLeft'
          || isPointOnImageRect?.position === 'topRight'
          || isPointOnImageRect?.position === 'bottomLeft'
          || isPointOnImageRect?.position === 'bottomRight'
        ) {
        boolArray.push('outside :(')
      }
    })
    const isEvery = boolArray.every(bool => bool === 'insideRect')
    return isEvery
  }
}

export const findPointsMinMax = (points: any[]) => {
  const pointsX: number[] = []
  const pointsY: number[] = []

  points.forEach(point => {
    pointsX.push(point.x1)
    pointsY.push(point.y1)
  })

  return {
    xmin: Math.round(Math.min(...pointsX)),
    ymin: Math.round(Math.min(...pointsY)),
    xmax: Math.round(Math.max(...pointsX)),
    ymax: Math.round(Math.max(...pointsY))
  }
}

export const checkPolygonPointsOnImageRect = (polygonPoints: number[][] | undefined, elements: ICanvasElement[]) => {
  if (polygonPoints?.length) {
    const boolArray: string[] = []
    polygonPoints.forEach(point => {
      const isPointOnImageRect = getElementAtPosition(point[0], point[1], elements)
      if (isPointOnImageRect?.position === 'insideImage') {
        boolArray.push('insideImage')
      }
      if (isPointOnImageRect?.position === null || isPointOnImageRect?.position === undefined) {
        boolArray.push('outside :(')
      }
    })
    const isEvery = boolArray.every(bool => bool === 'insideImage')
    return isEvery
  }
}

export const getPolygonNearPoint = (polygonPoints: number[][] | undefined, offsetClientX: number, offsetClientY: number) => {
  if (polygonPoints?.length) {
    let cursorOnPoint: ICursorOnPoint = {point: [], index: -1}
    polygonPoints?.forEach((polygonPoint, index) => {
      const onPoint = nearPoint(offsetClientX, offsetClientY, polygonPoint[0], polygonPoint[1], 'onPoint')
      if (onPoint === 'onPoint') {
        cursorOnPoint = {point: polygonPoint, index: index}
      }
    })
    if (cursorOnPoint?.point?.length) {
      return cursorOnPoint
    }
  }
}

export const getMinMax = (polygonZeroElements: any[]) => {
  const polygons: any[] = []
  polygonZeroElements.forEach(elem => {
    
    const polygonPointsX: number[] = []
    const polygonPointsY: number[] = []

    elem.points.forEach((point: any) => {
      polygonPointsX.push(point[0])
      polygonPointsY.push(point[1])
    })

    polygons.push({
      ...elem,
      xmin: Math.min(...polygonPointsX),
      ymin: Math.min(...polygonPointsY),
      xmax: Math.max(...polygonPointsX),
      ymax: Math.max(...polygonPointsY),
      points: elem.points,
      type: elem.type,
      label: elem.label,
      color: elem.color,
    })
  })
  // console.log('polygonPointsX', polygonPointsX)
  // console.log('polygonPointsY', polygonPointsY)
  // console.log('Math.max(...polygonPointsX)', Math.max(...polygonPointsX))
  // console.log('polygonsminMax', polygons)
  return polygons
}

export const setArrCordsToObj = (polygonZeroElements: any[]) => {
  const elemsWithObjPoints: any[] = []
  polygonZeroElements.forEach(elem => {
    const elemObjPoints: any[] = []
    elem.points.forEach((point: any) => {
      elemObjPoints.push({x: point[0], y: point[1]})
    })
    elemsWithObjPoints.push({...elem, points: elemObjPoints})
  })
  return elemsWithObjPoints
}

export const setPolygonsCords = (elementsWithZeroPontCords: any[], naturalImageWidth: number, naturalImageHeight: number) => {
  const elementsWithoutPolygons = elementsWithZeroPontCords.filter(elem => elem.type !== 'polygon')
  const polygonElements = elementsWithZeroPontCords.filter(elem => elem.type === 'polygon')
  const polygonZeroElements: any[] = []

  if (polygonElements.length) {
    polygonElements.forEach(elem => {
      const elemPoints: any[] = []

      elem.points.forEach((point: any) => {
        elemPoints.push([point[0] + naturalImageWidth, point[1] + naturalImageHeight])
      })

      polygonZeroElements.push({...elem, points: elemPoints})
    })
  }

  const polygonsWithMinMax = getMinMax(polygonZeroElements)
  const polegonsWithArrCords = setArrCordsToObj(polygonsWithMinMax)

  return [...elementsWithoutPolygons, ...polegonsWithArrCords]
}

export const setPointsNumbers = (pointsWithoutDeleted: ICanvasElement[], deletedPointId: number) => {
  if (pointsWithoutDeleted.length) {
    const pointsCopy = JSON.parse(JSON.stringify(pointsWithoutDeleted))
    pointsCopy.forEach((point : any) => {
      let pointIndex = pointsCopy.indexOf(point)
      if (pointIndex !== -1) {
        pointsCopy[pointIndex].pointNumber = pointIndex
      }
    })
    return pointsCopy
  }
  return []
}

//В ПРОЦЕССЕ РАЗРАБОТКИ! Получение площади элемента по Гауссу
export const getGaussArea = (element: ICanvasElement) => {
  if (element.polygonPoints) {
    const points = [...element.polygonPoints]
  }
}

export const sortElementsWithPoints = (elements: any[], naturalImageWidth: number, naturalImageHeight: number) => {
  const pointsRects = elements.filter(elem => elem.type === 'pointsRect')
  const sortedElements: any[] = []
  pointsRects.forEach(rect =>  {
    const rectPoints = elements.filter(elem => elem.pointParentId === rect.id)
    if (rectPoints?.length) {
      const rectPointsForAPI = rectPoints.map(point => ({x: point.x1 + naturalImageWidth/2, y: point.y1 + naturalImageHeight/2}))
      if (rectPointsForAPI.length) {
        sortedElements.push({
          label: rect.labelId, 
          type: "points",
          name: rect.label,
          color: rect.labelColor,
          xmin: rect.x1 + naturalImageWidth/2,
          ymin: rect.y1 + naturalImageHeight/2,
          xmax: rect.x2 + naturalImageWidth/2,
          ymax: rect.y2 + naturalImageHeight/2,
          points: rectPointsForAPI,

          classId: rect.classId,
          model_id: rect.model_id,
          automarkup: rect.automarkup,
          success: rect.success,
          info: rect.info,
          prediction_id: rect.prediction_id
        })
      }

    }
  })

  return sortedElements
}

export const getApiElemsWithStatus = (apiElems: any, imageStatus: string | null | undefined, moderate: string | undefined, viewMode: Option, cleared: boolean) => {
  if (cleared) {
    return {...apiElems, subStatus: null}
  }
  if (viewMode.value === 'USER') {
    return {...apiElems, subStatus: imageStatus}
  }
  if (viewMode.value === 'ADMIN') {
    return {...apiElems, subStatus: 'review'}
  }
  if (viewMode.value === 'MODER' && moderate === 'skip') {
    return {...apiElems, subStatus: imageStatus}
  }
  if (viewMode.value === 'MODER' && moderate === 'reject') {
    return {...apiElems, subStatus: 'rejected'}
  }
  if (viewMode.value === 'MODER' && moderate === 'approve') {
    return {...apiElems, subStatus: 'approved'}
  }
  if (viewMode.value === 'LABELLER') {
    return {...apiElems, subStatus: 'review'}
  }
}

//Рисование "Драг поинтов" элемента, чистый визуал
export const drawSelectedElemDragPoints = (selectedElement: ICanvasElement | null, setSelectedElementDragPoints: React.Dispatch<React.SetStateAction<any[]>>) => {
  if (selectedElement) {
    const {x1, y1, y2, x2, polygonPoints} = selectedElement

    if (selectedElement.type === 'rectangle' || selectedElement.type === 'pointsRect') {
      const leftTopDragPoint = CreateElement.dragPoint(x1, y1)
      const rightTopDragPoint = CreateElement.dragPoint(x2, y1)
      const leftBottomDragPoint = CreateElement.dragPoint(x1, y2)
      const rightBottomDragPoint = CreateElement.dragPoint(x2, y2)
      setSelectedElementDragPoints([leftTopDragPoint, rightTopDragPoint, leftBottomDragPoint, rightBottomDragPoint])
    }
    if (selectedElement.type === 'polygon') {
      const points: any[] = []
      polygonPoints?.forEach(polygonPoint => {
        const point = CreateElement.dragPoint(polygonPoint[0], polygonPoint[1])
        points.push(point)
      })
      setSelectedElementDragPoints(points)
    }
  }
}

//Выделение элемента и затемнение остальных, путём рисования затемнённого полигона
export const drawBlackoutPolygon = (imageRect: any, selectedElement: ICanvasElement, setBlackoutPolygon: React.Dispatch<React.SetStateAction<blackoutPolygon | null>>) => {
  if (selectedElement) {
    setBlackoutPolygon(null)
    const {id, x1, y1, y2, x2, polygonPoints} = selectedElement
    if (selectedElement.type === 'rectangle' || selectedElement.type === 'pointsRect') {
      const blackoutPoints = [
        [imageRect.x1, imageRect.y1], //imageRect TopLeft
        [imageRect.x2, imageRect.y1], //imageRect TopRight
        [imageRect.x2, imageRect.y2], //imageRect BottomRight
        [imageRect.x1, imageRect.y2], //imageRect BottomLeft

        [x1, y1], //TopLeft
        [x2, y1], //TopRight
        [x2, y2], //BottomRight
        [x1, y2], //BottomLeft
        [x1, y1], //TopLeft
 
        [imageRect.x1, imageRect.y2], //BottomLeft
      ]
      const blackoutPolygon = CreateElement.BlackoutPolygon(id, 'polygon', blackoutPoints)
      setBlackoutPolygon(blackoutPolygon)
    }
    if (selectedElement.type === 'polygon' && polygonPoints) {
      const blackoutPoints = [
        [imageRect.x1, imageRect.y1], //imageRect TopLeft
        [imageRect.x2, imageRect.y1], //imageRect TopRight
        [imageRect.x2, imageRect.y2], //imageRect BottomRight
        [imageRect.x1, imageRect.y2], //imageRect BottomLeft

        ...polygonPoints, //All polygonPoins
        polygonPoints[0], //Polygon StartPoint

        [imageRect.x1, imageRect.y2], //imageRect BottomLeft
      ]
      const blackoutPolygon = CreateElement.BlackoutPolygon(id, 'polygon', blackoutPoints)
      setBlackoutPolygon(blackoutPolygon)
    }
  }
}

const getPointsFromElements = (elements: any) => {
  const blackoutPoints: number[][] = []
  elements.forEach((elem: any) => {
    if (elem.type === 'rectangle' || elem.type === 'pointsRect') {
      const points = [
        [elem.x1, elem.y1], //TopLeft
        [elem.x2, elem.y1], //TopRight
        [elem.x2, elem.y2], //BottomRight
        [elem.x1, elem.y2], //BottomLeft
        [elem.x1, elem.y1], //TopLeft node
        elements[0].type === 'polygon' 
          ? [elements[0].polygonPoints[0][0], elements[0].polygonPoints[0][1]] //First elem node if polygon
          : [elements[0].x1, elements[0].y1] //First elem node if !polygon
      ]
      blackoutPoints.push(...points)
    }
    if (elem.type === 'polygon') {
      const points = [
        ...elem.polygonPoints, //All polygonPoins
        elem.polygonPoints[0], //Polygon StartPoint node
        elements[0].type === 'polygon' 
          ? [elements[0].polygonPoints[0][0], elements[0].polygonPoints[0][1]] //First elem node if polygon
          : [elements[0].x1, elements[0].y1] //First elem node if !polygon
      ]
      blackoutPoints.push(...points)
    }
  });
  console.log('blackoutPoints', blackoutPoints)
  if (blackoutPoints?.length) {
    return blackoutPoints
  }
}

//Выделение Классов(метки одного типа/имени) и затемнение остальных, путём рисования затемнённого полигона
export const drawBlackoutForClass = (imageRect: any, elements: any, setBlackoutPolygon: React.Dispatch<React.SetStateAction<blackoutPolygon | null>>) => {
  if (imageRect && elements?.length) {
    const pointsFromElements = getPointsFromElements(elements)
    
    if (pointsFromElements?.length) {
      const blackoutPoints: number[][] = [
        [imageRect.x1, imageRect.y1], //imageRect TopLeft
        [imageRect.x2, imageRect.y1], //imageRect TopRight
        [imageRect.x2, imageRect.y2], //imageRect BottomRight
        [imageRect.x1, imageRect.y2], //imageRect BottomLeft
  
        ...pointsFromElements,
  
        [imageRect.x1, imageRect.y2], //imageRect BottomLeft
      ]
      const blackoutPolygon = CreateElement.BlackoutPolygon(imageRect.id, 'polygon', blackoutPoints)
      console.log('blackoutPolygon', blackoutPolygon)
      setBlackoutPolygon(blackoutPolygon)
    }
  }
}

export const getImagePath = async (openDrawAction: string, imagesFromList: INewImage[], selectedImages: INewImage[],imgParam: string) => {
  if (openDrawAction === 'images') {
    const findedImage = imagesFromList.find((image) => image.id === imgParam)
    if (findedImage) {
      return findedImage.imageUrl
    }
  }
  if (openDrawAction === 'selectedImages') {
    const findedImage = selectedImages.find((image) => image.id === imgParam)
    if (findedImage) {
      return findedImage.imageUrl
    }
  }
}