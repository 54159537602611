import React, { useEffect, useState } from 'react'
import { ImageListWrapper, StyledFilterText, StyledImagesList } from '../constants/styled'
import ListSearch from './ListSearch'
import ListFooter from './ListFooter'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { EImagesPage, IImagesListReducerState, imagesListSlice } from '../store/ImagesListReducer'
import { asyncGetListImages } from '../store/ImagesListAsyncActions'
import { useParams } from 'react-router-dom'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { t } from 'i18next'
import Loader from '../../../UI/Loader/Loader'
import ListImage from './ListImage'

interface IWrapperProps {
  page: IImagesListReducerState['currentPageName']
  heightProps?: string;
  onImageClick?: any;
}

export const Wrapper: React.FC<IWrapperProps> = ({ page, heightProps, onImageClick, ...props }) => {
  const dispatch = useAppDispatch()
  const userRoleNumber = useAppSelector((state) => state.TabsReducer.userRoleNumber)
  const split = useAppSelector((state) => state.ImagesListReducer.pageStates[page].split)
  const { isLoading, error } = useAppSelector(state => state.ImagesListReducer)
  const params = useParams()
  const {
    selectedSplit,
    selectedImages,
    images,

    pages,
    currentPage,
    imagesTotal,
    total,
    remain,
    limits,
    currentLimit,
    imagesOnPage
  } = useAppSelector((state) => state.ImagesListReducer)

  console.log('pages', pages, 'currentPage', currentPage)

  const [searchContent, setSearchContent] = useState('')

  useEffect(() => {
    if (selectedSplit?.value) {
      dispatch(imagesListSlice.actions.setSelectedSplit(selectedSplit))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSplit])

  useEffect(() => {
    dispatch(imagesListSlice.actions.setInitialState())
    const payload = {
      id:getPayloadId(page),
      page: page,
      limit: +currentLimit.value,
      offset: 0,
      split: selectedSplit,
      search: searchContent
    }
    console.log('payload', payload)
    dispatch(asyncGetListImages(payload))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, page])

  //SEARCH START
  const getPayloadId = (page: string) => {
      if (page === "batchPage" && params?.batchId) {
        return params.batchId
      }
      if (page === "assignPage") {
        return params?.id
      }
      if (page === "taskPage") {
        return params?.jobId
      }
      if (page === "datasetPage") {
        return params?.datasetId
      }
      if (page === "datasetsPage") {
        return params?.id
      }
      if (page === "versionPage") {
        return params?.versionId
      }
      if (page === "libraryPage") {
  
      }
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchContent(e.target.value)
  }

  const onSearchClick = () => {
    dispatch(imagesListSlice.actions.setCurrentPage(1))
    const payload = {
      id: getPayloadId(page),
      page: page,
      limit: +currentLimit.value,
      offset: 0,
      split: selectedSplit,
      search: searchContent
    }
    dispatch(imagesListSlice.actions.setError(''))
    dispatch(asyncGetListImages(payload))
  }

  const onSplitChange = (value: string) => {
    const findedSplit = split.find((option) => option.value === value)
    if (findedSplit) {
      if (findedSplit === selectedSplit) {
        return
      }
      dispatch(imagesListSlice.actions.deselectAllImages())
      dispatch(imagesListSlice.actions.setSelectedSplit(findedSplit))
      dispatch(imagesListSlice.actions.setCurrentPage(1))
      const payload = {
        id: getPayloadId(page),
        page: page,
        limit: +currentLimit.value,
        offset: 0,
        split: findedSplit,
        search: searchContent
      }
      dispatch(imagesListSlice.actions.setError(''))
      dispatch(asyncGetListImages(payload))
    }
  }

  const onSortChange = () => {}

  const onSelectAllClick = () => {
    if (images?.length) {
      dispatch(imagesListSlice.actions.setAllSelected())
    }
  }

  const onDeselectClick = () => {
    if (selectedImages?.length) {
      dispatch(imagesListSlice.actions.deselectAllImages())
    }
  }
  //SEARCH END

  //FOOTER START
  const onLimitChange = (value: string) => {
    const findedLimit = limits.find((option) => option.value === value)
    if (findedLimit) {
      if (findedLimit === currentLimit) {
        return
      }
      dispatch(imagesListSlice.actions.setSelectedLimit(findedLimit))
      dispatch(imagesListSlice.actions.setCurrentPage(1))
      const payload = {
        id: getPayloadId(page),
        page: page,
        limit: +findedLimit.value,
        offset:0,
        split: selectedSplit,
        search: searchContent
      }
      dispatch(imagesListSlice.actions.setError(''))
      dispatch(asyncGetListImages(payload))
    }
  }

  const onLessThanClick = () => {
    if (!pages[currentPage - 2]) {
      return
    }
    const payload = {
      id: getPayloadId(page),
      page: page,
      limit: +currentLimit.value,
      offset: (currentPage - 2) * +currentLimit.value,
      split: selectedSplit,
      search: searchContent
    }
    dispatch(imagesListSlice.actions.setCurrentPage(pages[currentPage - 2]))
    dispatch(asyncGetListImages(payload))
  }

  const onGreaterThanClick = () => {
    if (!pages[currentPage]) {
      return
    }
    const payload = {
      id: getPayloadId(page),
      page: page,
      limit: +currentLimit.value,
      offset: (currentPage) * +currentLimit.value,
      split: selectedSplit,
      search: searchContent
    }
    dispatch(imagesListSlice.actions.setCurrentPage(pages[currentPage]))
    dispatch(asyncGetListImages(payload))
  }
  //FOOTER END

  return (
    <ImageListWrapper heightProps={heightProps || '100%'}>
      <ListSearch
        searchContent={searchContent}
        onSearchChange={onSearchChange}
        selectedSplit={selectedSplit}
        onSplitChange={onSplitChange}
        split={split}
        selectedImages={selectedImages}
        onSelectAllClick={onSelectAllClick}
        onDeselectClick={onDeselectClick}
        currentLimit={currentLimit}
        onSearchClick={onSearchClick}
        userRoleNumber={userRoleNumber}
        page={EImagesPage[page]}
      />
      <StyledImagesList>
        {error && !isLoading
          ? <FlexContainer direction='row' justify='center' align='center' heightProps='100%'>
              <StyledFilterText>{t('imagesList.errorLoading')}</StyledFilterText>
            </FlexContainer> 
          : ''
        }
        {!error && isLoading
          ? <FlexContainer direction='row' justify='center' align='center' heightProps='100%'>
              <Loader/>
            </FlexContainer> 
          : ''
        }
        {!error && !isLoading && !images?.length
          ? <FlexContainer direction='row' justify='center' align='center' heightProps='100%'>
              <StyledFilterText>{t('other.empty')}</StyledFilterText>
            </FlexContainer> 
          : ''
        }
        {!isLoading && !error && images?.map((image, index)=> <ListImage page={page} image={image} selectedImages={selectedImages} onImageClick={onImageClick} key={image.id} userRoleNumber={userRoleNumber}/>)}
      </StyledImagesList>
      <ListFooter
        onLessThanClick={onLessThanClick}
        onGreaterThanClick={onGreaterThanClick}
        pages={pages}
        currentPage={currentPage}
        total={total}
        limits={limits}
        imagesOnPage={imagesOnPage}
        currentLimit={currentLimit}
        onLimitChange={onLimitChange}
      />
    </ImageListWrapper>
  )
}
