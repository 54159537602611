import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { IProjectsWrapperProps } from '../models/IProjectsWrapperProps'
import Projects from './Projects'
import Workspaces from './Workspaces'

const StyledProjectsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 90px;
  gap: 15px;
  width: 100%;

  max-height: 100%;
  min-height: 100%;

  position: relative;
`

const StyledContent = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  /* overscroll-behavior: none!important; */
  padding: 0 15px 0px 15px; 
`

export const ProjectsWrapper: FC<IProjectsWrapperProps> = ({ navigate, ...props }) => {
  const [isManagementMode, setIsManagementMode] = useState(false)
  const [isManagementClicked, setIsManagementClicked] = useState(false)

  return (
    <StyledContent id='pageContainer'>
      <StyledProjectsWrapper>
        <Workspaces setIsManagementMode={setIsManagementMode} setIsManagementClicked={setIsManagementClicked} />
        <Projects
          navigate={navigate}
          isManagementMode={isManagementMode}
          isManagementClicked={isManagementClicked}
          setIsManagementMode={setIsManagementMode}
          setIsManagementClicked={setIsManagementClicked}
        />
      </StyledProjectsWrapper>
    </StyledContent>

  )
}
