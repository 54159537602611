import React, { FC, ReactChild, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'

interface ITooltipProps {
    visible: boolean;
    children: ReactNode | ReactChild;
}

const StyledTooltip = styled.div<ITooltipProps>`
    position: absolute;

    top: 10px;
    right: 100%;
    left: 80%;
    z-index: 0;


    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    font-weight: bold;
    text-align: center;

    border-radius: 50%;
    background-color: ${Colors.gray};

    width: 0;
    height: 0;

    cursor: pointer;

    :hover {
        color: ${Colors.orange};
        border-color: ${Colors.orange};
        background-color: ${Colors.dark};
    }

    ::after {
        content: '';
        display: ${({ visible }) => visible ? 'block' : 'none'};

        position: absolute;
        bottom: 20px;

        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid ${Colors.orange};
    }
`
const StyledTooltipContent = styled.div<ITooltipProps>`
    overflow-wrap: break-word;

    position: absolute;
    bottom: 30px;
    left: -20px;

    width: 240px;
    height: 80px;
    padding: 10px;

    background-color: ${Colors.dark};
    display: ${({ visible }) => visible ? 'block' : 'none'};

    border: 1px solid ${Colors.orange};
    border-radius: 15px;
`

const ButtonTooltip: FC<ITooltipProps> = ({children, visible, ...props}) => {


  return (
    <StyledTooltip visible={visible}>
        <StyledTooltipContent visible={visible}>
            {children}
        </StyledTooltipContent>
    </StyledTooltip>
  )
}

export default ButtonTooltip