import React, { FC, useEffect, useState } from 'react'
import {
  StyledAccodrionLink,
  StyledAccordionImagesWrapper,
  StyledAccordionSectionContent,
  StyledAccordionSectionTitle,
  StyledErrorText,
  StyledGenerateAccordion,
  StyledLink,
  StyledModalWindowTitle
} from '../../../../constants/styled'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { IAllAnnotatedDatasetsImages } from '../../../../store/reducers/DatasetsReducer'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import GenerateAccordionImage from './GenerateAccordionImage'
import { NavigateFunction, useParams } from 'react-router-dom'
import SplitCard from './SplitCard'
import { Colors } from '../../../../../../models/Colors'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import RebalanceSplitForm from './RebalanceSplitForm'
import PreprocessingTab from './PreprocessingTab'
import VersionsService, { ICreateVersionRequest, IGenerateLabel } from '../../../../../../services/VersionsService'
import { versionsReducer } from '../../../../store/reducers/VersionsReducer'
import GenerateLoaderForm from './GenerateLoaderForm'
import { asyncGetVersionById, asyncGetVersionsSummary } from '../../../../../../store/reducers/AcyncActions'
import { SettingsReducer } from '../../../../../UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'
import { IProject } from '../../../../../../services/ProjectService'

interface IGenerateAccordionProps {
  allDatasetsImages: IAllAnnotatedDatasetsImages[]
  projectInfo: IProject
  navigate: NavigateFunction
  generateLabels: IGenerateLabel[]
}

const GenerateAccordion: FC<IGenerateAccordionProps> = ({ allDatasetsImages, projectInfo, navigate, generateLabels, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const params = useParams()
  const [sectionsActive, setSectionsActive] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false
  })

  const [jsxImages, setJsxImages] = useState<any>()
  const [splitPercents, setSplitPercents] = useState({
    train: 80,
    valid: 15,
    test: 5
  })

  const simpleOptions = useAppSelector((state) => state.generateReducer.simpleOptions)
  const { images, imagesTotal} = useAppSelector(state => state.ImagesListReducer)
  const { resizeOption, tileOption, autoContrastOption, grayscaleOption, blurOption, noiseOption, brightness } = useAppSelector((state) => state.generateReducer)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isGenerating = useAppSelector(state => state.versionsReducer.isGenerating)
  const [job, setJob] = useState('')

  const getGenerateProgress = async (job: string) => {
    try {
      if (job) {
        console.log(job)
        const subscribeOnGenerate = await VersionsService.getVersionProgress(job)
        if (subscribeOnGenerate?.data?.progress) {
          console.log('dispatched!!!!')
          dispatch(versionsReducer.actions.setProgress(subscribeOnGenerate.data.progress))
        }
        console.log('subscribeOnGenerate', subscribeOnGenerate)
        if (!subscribeOnGenerate?.data?.progress === null) {
          dispatch(
            modalWindowSlice.actions.setChildren(
              <FlexContainer direction="column" justify="center" align="center" gap="30px">
                <span>{t('errors.serverError')}</span>
                <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps="150px">
                  {t('buttons.close')}
                </ProjectButton>
              </FlexContainer>
            )
          )
          // await JobsService.deleteJob(job)
          dispatch(versionsReducer.actions.setIsGenerating(false))
          throw new Error('No Responce.data :(')
        }
        if (!subscribeOnGenerate.data) {
          dispatch(
            modalWindowSlice.actions.setChildren(
              <FlexContainer direction="column" justify="center" align="center" gap="30px">
                <span>{t('errors.serverError')}</span>
                <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps="150px">
                  {t('buttons.close')}
                </ProjectButton>
              </FlexContainer>
            )
          )
          // await JobsService.deleteJob(job)
          dispatch(versionsReducer.actions.setIsGenerating(false))
          throw new Error('No Responce.data :(')
          
        }
        if (subscribeOnGenerate.data?.version?.id) {
          dispatch(modalWindowSlice.actions.setInititialState())
          dispatch(versionsReducer.actions.setIsGenerating(false))
          dispatch(asyncGetVersionById(subscribeOnGenerate.data?.version?.id))
          navigate(`/projects/${projectInfo.id}/versions/version/${subscribeOnGenerate.data.version?.id}`)
        }
        if (!subscribeOnGenerate.data?.version?.id) {
          if (job) {
            console.log('Retry!')
            setTimeout(() => {
              getGenerateProgress(job)
            }, 500)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(projectInfo.group))
    navigate(`/settings/group/${projectInfo.group}`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const subscribe = async (payload: ICreateVersionRequest) => {
    console.log('generatePayload', payload)
    try {
      const responce = await VersionsService.createVersion(payload)
      
      console.log('subscribe', responce)

      if (responce?.data?.job) {
        setJob(responce.data.job)
        getGenerateProgress(responce.data.job)
        dispatch(versionsReducer.actions.setIsGenerating(true))
        dispatch(versionsReducer.actions.setJob(responce.data.job))
        //GenerateForm --- LOADER!
      }
      dispatch(asyncGetVersionsSummary(payload.project))
    } catch (error: any) {
      console.log(error)
      // eslint-disable-next-line no-cond-assign
      if ((error.response.data.error = 'Превышена дисковая квота')) {
        dispatch(versionsReducer.actions.setIsGenerating(false))
        dispatch(modalWindowSlice.actions.setTitle('Oops!'))
        dispatch(
          modalWindowSlice.actions.setChildren(
            <FlexContainer direction="column" justify="flex-start" align="center" gap="20px">
              <StyledErrorText visible>{t('errors.diskQuotaError')}</StyledErrorText>
              <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>
              <ProjectButton margin="30px 0 0 0" onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>
                {t('buttons.close')}
              </ProjectButton>
            </FlexContainer>
          )
        )
      }
    }
  }

  useEffect(() => {
    if (images.length) {
      const accordionImages = images
        ? images.map((listImage, index) =>
            index < 5
              ? images?.map((image, index) =>
                  index < 1 ? (
                    <GenerateAccordionImage
                      projectInfo={projectInfo}
                      navigate={navigate}
                      name={image.displayName}
                      imagePath={image.thumbnailUrl}
                      dataset={''}
                      filename={''}
                      key={image.id}
                    />
                  ) : (
                    ''
                  )
                )
              : ''
          )
        : ''
      setJsxImages(accordionImages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDatasetsImages])

  const onSectionClick = (sectionNumber: string) => {
    if (sectionNumber === 'first') {
      setSectionsActive({
        ...sectionsActive,
        first: !sectionsActive.first,
        second: false,
        third: false,
        fourth: false,
        fifth: false
      })
    }
    if (sectionNumber === 'second') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: !sectionsActive.second,
        third: false,
        fourth: false,
        fifth: false
      })
    }
    if (sectionNumber === 'third') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: false,
        third: !sectionsActive.third,
        fourth: false,
        fifth: false
      })
    }
    if (sectionNumber === 'fourth') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: false,
        third: false,
        fourth: !sectionsActive.fourth,
        fifth: false
      })
    }
    if (sectionNumber === 'fifth') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: !sectionsActive.fifth
      })
    }
  }

  const onContinueClick = (sectionNumber: string) => {
    if (sectionNumber === 'first') {
      setSectionsActive({
        ...sectionsActive,
        first: !sectionsActive.first,
        second: false,
        third: false,
        fourth: false,
        fifth: false
      })
      setTimeout(() => {
        setSectionsActive({
          ...sectionsActive,
          first: false,
          second: true,
          third: false,
          fourth: false,
          fifth: false
        })
      }, 300)
    }
    if (sectionNumber === 'second') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: !sectionsActive.second,
        third: false,
        fourth: false,
        fifth: false
      })
      setTimeout(() => {
        setSectionsActive({
          ...sectionsActive,
          first: false,
          second: false,
          third: true,
          fourth: false,
          fifth: false
        })
      }, 300)
    }
    if (sectionNumber === 'third') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: false,
        third: !sectionsActive.third,
        fourth: false,
        fifth: false
      })
      setTimeout(() => {
        setSectionsActive({
          ...sectionsActive,
          first: false,
          second: false,
          third: false,
          fourth: true,
          fifth: false
        })
      }, 300)
    }
    if (sectionNumber === 'fourth') {
      setSectionsActive({
        ...sectionsActive,
        first: false,
        second: false,
        third: false,
        fourth: !sectionsActive.fourth,
        fifth: true
      })
      setTimeout(() => {
        setSectionsActive({
          ...sectionsActive,
          first: false,
          second: false,
          third: false,
          fourth: false,
          fifth: true
        })
      }, 300)
    }
    if (sectionNumber === 'fifth') {
      setSectionsActive({ ...sectionsActive, fifth: !sectionsActive.fifth })
    }
  }

  const onViewAllClick = () => {
    navigate(`/projects/${projectInfo.id}/datasets`)
  }

  const onUploadMoreClick = () => {
    navigate(`/projects/${projectInfo.id}/upload`)
  }

  const onRebalanceClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.rebalanceFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<RebalanceSplitForm splitPercents={splitPercents} setSplitPercents={setSplitPercents} />))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onGenerateClick = () => {
    if (!isGenerating && params.id) {
      const preprocessingOptions = []
      if (resizeOption.selected) {
        preprocessingOptions.push(resizeOption)
      }
      if (tileOption.selected) {
        preprocessingOptions.push(tileOption)
      }
      if (autoContrastOption.selected) {
        preprocessingOptions.push(autoContrastOption)
      }
      if (simpleOptions[0].selected) {
        preprocessingOptions.push(simpleOptions[0])
      }
      if (simpleOptions[1].selected) {
        preprocessingOptions.push(simpleOptions[1])
      }
      const augmentationOptions = []
      if (grayscaleOption.selected) {
        const grayScaleCopy = { ...grayscaleOption, method: 'grayscale' }
        augmentationOptions.push(grayScaleCopy)
      }
      if (blurOption.selected) {
        augmentationOptions.push(blurOption)
      }
      if (noiseOption.selected) {
        augmentationOptions.push(noiseOption)
      }
      if (brightness.selected) {
        augmentationOptions.push(brightness)
      }
      const payload = {
        service: 'generate',
        project: params.id,
        renameLabels: generateLabels,
        rebalance: {
          validCount: splitPercents.valid,
          testCount: splitPercents.test,
          trainCount: splitPercents.train
        },
        preprocessing: preprocessingOptions,
        augmentation: augmentationOptions
      }
      dispatch(modalWindowSlice.actions.setChildren(<GenerateLoaderForm />))
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(versionsReducer.actions.setProgress(0))
      subscribe(payload)
    }
  }

  const onButtonGenerateClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateNewVer')))
    dispatch(modalWindowSlice.actions.setChildren(
        <FlexContainer direction="column" justify="center" align="center" widthProps="100%" paddingProps="0 15px">
          <StyledModalWindowTitle style={{color: Colors.superLightRed}}>
            {isGenerating ? t('authorized.project.generateAndVersions.ifIsGeneratins') : t('authorized.project.generateAndVersions.endText')}
          </StyledModalWindowTitle>
          <FlexContainer direction="row" justify="space-between" align="center" paddingProps="35px 0 0 0">
            <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onGenerateClick} disabled={isGenerating} >{t('buttons.continue')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      )
    )
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledGenerateAccordion>
      <StyledAccordionSectionTitle active={sectionsActive.first} onClick={() => onSectionClick('first')}>
        1. {t('authorized.project.generateAndVersions.generateTitle1')}
      </StyledAccordionSectionTitle>
      <StyledAccordionSectionContent active={sectionsActive.first}>
        <FlexContainer direction="column" justify="center" align="flex-start">
          <h6>
            {t('authorized.project.generateAndVersions.generateImages', {count: imagesTotal})}
          </h6>
          <StyledAccordionImagesWrapper>
            {jsxImages}
            <StyledAccodrionLink onClick={onViewAllClick}>{t('authorized.project.generateAndVersions.viewAll')}</StyledAccodrionLink>
          </StyledAccordionImagesWrapper>
          <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 20px 10px 20px" margin="10px 0 0 0">
            <ProjectButton onClick={() => onContinueClick('first')}>{t('buttons.continue')}</ProjectButton>
            <ProjectButton widthProps="200px" onClick={onUploadMoreClick}>
              {t('buttons.uploadMore')}
            </ProjectButton>
          </FlexContainer>
        </FlexContainer>
      </StyledAccordionSectionContent>

      <StyledAccordionSectionTitle active={sectionsActive.second} onClick={() => onSectionClick('second')}>
        2. {t('authorized.project.generateAndVersions.generateTitle2')}
      </StyledAccordionSectionTitle>
      <StyledAccordionSectionContent active={sectionsActive.second}>
        <h6>{t('authorized.project.generateAndVersions.rebalanceText')}</h6>
        <FlexContainer direction="row" justify="space-around" paddingProps="10px" gap="20px" margin="10px 0 0 0">
          <SplitCard percent={splitPercents.train} percentColor="#8f2ceb">
            Training Set
          </SplitCard>
          <SplitCard percent={splitPercents.valid} percentColor={Colors.green}>
            Validation Set
          </SplitCard>
          <SplitCard percent={splitPercents.test} percentColor="#07d6cd">
            Testing Set
          </SplitCard>
        </FlexContainer>
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 20px 10px 20px" margin="15px 0 0 0">
          <ProjectButton onClick={() => onContinueClick('second')}>{t('buttons.continue')}</ProjectButton>
          <ProjectButton widthProps="200px" onClick={onRebalanceClick}>
            {t('authorized.project.generateAndVersions.rebalanceButton')}
          </ProjectButton>
        </FlexContainer>
      </StyledAccordionSectionContent>

      <StyledAccordionSectionTitle active={sectionsActive.third} onClick={() => onSectionClick('third')}>
        3. {t('authorized.project.generateAndVersions.generateTitle3')}
      </StyledAccordionSectionTitle>
      <StyledAccordionSectionContent active={sectionsActive.third}>
        <h6>{t('authorized.project.generateAndVersions.preprocessingText')}</h6>
        <PreprocessingTab type="preprocessing" />
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 20px 10px 20px" margin="15px 0 0 0">
          <ProjectButton onClick={() => onContinueClick('third')}>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
      </StyledAccordionSectionContent>

      <StyledAccordionSectionTitle active={sectionsActive.fourth} onClick={() => onSectionClick('fourth')}>
        4. {t('authorized.project.generateAndVersions.generateTitle4')}
      </StyledAccordionSectionTitle>
      <StyledAccordionSectionContent active={sectionsActive.fourth}>
        <h6>{t('authorized.project.generateAndVersions.augText')}</h6>
        <PreprocessingTab type="augmentation" />
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 20px 10px 20px" margin="15px 0 0 0">
          <ProjectButton onClick={() => onContinueClick('fourth')}>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
      </StyledAccordionSectionContent>

      <StyledAccordionSectionTitle active={sectionsActive.fifth} onClick={() => onSectionClick('fifth')}>
        5. {t('authorized.project.generateAndVersions.generateTitle5')}
      </StyledAccordionSectionTitle>
      <StyledAccordionSectionContent active={sectionsActive.fifth}>
        {isGenerating ? <h6 style={{color: Colors.superLightRed}}>{t('authorized.project.generateAndVersions.ifIsGeneratins')}</h6> : <h6>{t('authorized.project.generateAndVersions.endText')}</h6>}
        <FlexContainer direction="column" justify="center" align="center" gap="10px" paddingProps="15px"></FlexContainer>
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 20px 10px 20px" margin="15px 0 0 0">
          {isGenerating ? <ProjectButton disabled>{t('buttons.continue')}</ProjectButton> : <ProjectButton onClick={onButtonGenerateClick}>{t('buttons.continue')}</ProjectButton>}
        </FlexContainer>
      </StyledAccordionSectionContent>
    </StyledGenerateAccordion>
  )
}

export default GenerateAccordion
