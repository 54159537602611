import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClassModel, IGetAutoMarkupClassById, IGetAutoMarkupClassesSummaryResponce, IGetAutoMarkupModelsSummaryResponce } from '../../../services/AutoMarkupService';

export interface IAutoMarkupModelWithTagClass {
  id: string;
  name: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  usedOnSingleImage: boolean;
  usedOnMultipleImages: boolean;
  type: string;
  Classes: string[];
}

export interface IAutoMarkupClassWithModelChecked {
  id: string;
  name: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  checked: boolean;
  Models: IClassModel[];
}

interface IAutoMarkupState {
  isLoading: boolean;
  error: string;
  progress: string;

  drawImagesCount: number;

  modelsSummary: IGetAutoMarkupModelsSummaryResponce;
  selectedModel: IAutoMarkupModelWithTagClass;
  tags: string[];
  testQuantity: number;
  testDemo: 1 | 2;

  classesSummary: IGetAutoMarkupClassesSummaryResponce,
  selectedClass: IGetAutoMarkupClassById,

  classesState: IAutoMarkupClassWithModelChecked[],

  automarkupCloseClick: boolean;

  imagesToSegment: string[];
}

const initialState: IAutoMarkupState = {
    isLoading: false,
    error: '',
    progress: "",

    drawImagesCount: 0,

    classesSummary: {} as IGetAutoMarkupClassesSummaryResponce,
    selectedClass: {} as IGetAutoMarkupClassById,

    modelsSummary: {} as IGetAutoMarkupModelsSummaryResponce,
    selectedModel: {} as IAutoMarkupModelWithTagClass,
    tags: [],
    testQuantity: 1,
    testDemo: 1,

    classesState: [],

    automarkupCloseClick: false,

    imagesToSegment: [],
}

export const autoMarkupSlice = createSlice(
{
    name: 'autoMarkupSlice',
    initialState,

    reducers: {
        setInitialState(state) {
          state.isLoading = initialState.isLoading;
          state.error = initialState.error;
          state.progress = initialState.progress;
          state.drawImagesCount = initialState.drawImagesCount;
          state.modelsSummary = initialState.modelsSummary;
          state.selectedModel = initialState.selectedModel;
          state.classesState = initialState.classesState;
          state.selectedClass = initialState.selectedClass;
          state.classesSummary = initialState.classesSummary;
          state.automarkupCloseClick = false;
        },
        setError(state, action: PayloadAction<string>) {
          state.error = action.payload
        },          
        setLoading(state, action: PayloadAction<boolean>) {
          state.isLoading = action.payload
        },

        setProgress(state, action: PayloadAction<any>) {
          state.progress = action.payload
        },

        setDrawImagesCount(state, action: PayloadAction<number>) {
          state.drawImagesCount = action.payload
        },

        setClassesSummary(state, action: PayloadAction<IGetAutoMarkupClassesSummaryResponce>) {
          state.classesSummary = action.payload
        },
        setSelectedClass(state, action: PayloadAction<IGetAutoMarkupClassById>) {
          state.selectedClass = action.payload
        },

        setModelsSummary(state, action: PayloadAction<IGetAutoMarkupModelsSummaryResponce>) {
          state.modelsSummary = action.payload
        },
        setSelectedModel(state, action: PayloadAction<IAutoMarkupModelWithTagClass>) {
          state.selectedModel = action.payload
        },

        setTags(state, action: PayloadAction<string[]>) {
          state.tags = action.payload
        },

        setTestQuantity(state, action: PayloadAction<number>) {
          state.testQuantity = action.payload
        },

        setTestDemo(state, action: PayloadAction<1 | 2>) {
          state.testDemo = action.payload
        },

        setClassState(state, action: PayloadAction<IAutoMarkupClassWithModelChecked>) {
          const findedClass = state.classesState.find(mClass => mClass.id === action.payload.id)
          if (findedClass && action.payload.checked === false) {
            state.classesState = state.classesState.filter(mClass => mClass.id !== action.payload.id)
          }
          if (!findedClass && action.payload.checked === true) {
            state.classesState.push(action.payload)
          }
        },

        setAutomarkupCloseClick(state, action: PayloadAction<boolean>) {
          state.automarkupCloseClick = action.payload
        },

        setImagesToSegment(state, action: PayloadAction<string[]>) {
          state.imagesToSegment = action.payload
        },
    }
},

)

export default autoMarkupSlice.reducer;