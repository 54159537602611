import React from 'react'
import { StyledMarkupTagWrapper, StyledMarkupText } from '../../../../Styled/styled';
import { StyledCrossIcon } from '../../../../../ProjectModule/constants/styled';

interface IAutoMarkupTagProps {
    text: string;
    onDelete: (arg0: string) => void
}

const AutoMarkupTag: React.FC<IAutoMarkupTagProps> = ({text, onDelete, ...props}) => {
  return (
    <StyledMarkupTagWrapper>
        <StyledMarkupText>{text}</StyledMarkupText>
        <StyledCrossIcon onClick={() => onDelete(text)}/>
    </StyledMarkupTagWrapper>
  )
}

export default AutoMarkupTag