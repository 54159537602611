import React, { FC, useState } from 'react'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux'
import GenerateOptionsForm from '../GenerateOptionsForm'
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer'
import { generateReducer } from '../../../../../store/reducers/GenerateReducer'
import {
  StyledAfterBothImagesWrapper,
  StyledAfterImageWrapper,
  StyledBeforeImage,
  StyledBeforeImageWrapper,
  StyledPercentageWrapper,
  StyledSelect
} from '../../../../../constants/styled'
import Input from '../../../../../../../UI/Input/Input'
import CanvasImage from '../CanvasImage'
import owlImage from '../../../../../../../assets/images/owl.jpg'
import owlAnnotatedImage from '../../../../../../../assets/images/owlAnnotated.png'
import owlContrastImage from '../../../../../../../assets/images/owlContrast.png'
import owlIsolateImage from '../../../../../../../assets/images/OwlIsolate.png'
import owlGrayScaleImage from '../../../../../../../assets/images/owlGrayscale.png'
import owlNoice from '../../../../../../../assets/images/owlNoise.png'
import ReactSlider from 'react-slider'
import { Colors } from '../../../../../../../models/Colors'
import styled from 'styled-components'
import CanvasDarken from '../CanvasDarken'
import CanvasLighten from '../CanvasLighten'
import { useTranslation } from 'react-i18next'

interface IFormOptionForm {
  imagePath?: string
  type: string
  globalType: string
}

const StyledWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  .horizontal-slider {
    width: 100%;
    height: 50px;
    margin: auto;
  }

  .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 7px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }

  .example-thumb.active {
    background-color: grey;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #8f2ceb;
  }

  .example-track.example-track-1 {
    background: ${Colors.green};
  }

  .example-track.example-track-2 {
    background: #07d6cd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    top: 15px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
`

const FormOptionForm: FC<IFormOptionForm> = ({ imagePath, type, globalType, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const showButton = useAppSelector((state) => state.generateReducer.showButton)
  const showAugmentationButton = useAppSelector((state) => state.generateReducer.showAugmentationButton)
  const { resizeOption, tileOption, autoContrastOption, simpleOptions, blurOption, noiseOption, brightness, grayscaleOption } = useAppSelector((state) => state.generateReducer)
  const [resizeOptionInfo, setResizeOptionInfo] = useState({ width: 180, height: 180 })
  const [tileOptionInfo, setTileOptionInfo] = useState({ columns: 1, rows: 1 })
  const [select, setSelect] = useState('Fill')
  const [reactSliderValue, setReactSliderValue] = useState(10)
  const [blurValue, setBlurValue] = useState(1)
  const [imagesToAugmentCountValue, setImagesToAugmentCountValue] = useState(10)

  const [brightnessValue, setBrightnessValue] = useState(1)
  const [brightnessSelect, setBrightnessSelect] = useState('both')

  const [blurPercentage, setBlurPercentage] = useState(0)
  const [noisePercentage, setNoisePercentage] = useState(0)
  const [brightnessPercentage, setBrightnessPercentage] = useState(0)
  const [grayscalePercentage, setGrayscalePercentage] = useState(0)

  const onCantelClick = () => {
    if (globalType === 'preprocessing') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateTitle3')))
    } else {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateTitle4')))
    }
    dispatch(modalWindowSlice.actions.setModalWidth(''))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <GenerateOptionsForm
          type={globalType}
          tileOption={tileOption}
          resizeOption={resizeOption}
          simpleOptions={simpleOptions}
          autoContrastOption={autoContrastOption}
          blurOption={blurOption}
          brightness={brightness}
          noiseOption={noiseOption}
          grayscaleOption={grayscaleOption}
        />
      )
    )
  }

  const onApplyClick = () => {
    if (type === 'resize') {
      dispatch(
        generateReducer.actions.setResizeOptionState({ method: type, params: { width: resizeOptionInfo.width, height: resizeOptionInfo.height, fit: select }, selected: true })
      )
    }
    if (type === 'tile') {
      dispatch(generateReducer.actions.setTileOptionState({ method: type, params: { columns: tileOptionInfo.columns, rows: tileOptionInfo.rows }, selected: true }))
    }
    if (type === 'negate') {
      dispatch(generateReducer.actions.setAutoContrastOptionState({ ...autoContrastOption, selected: true }))
    }
    if (type === 'Auto-Orient') {
      // dispatch(generateReducer.actions.setSimpleOptionsState({ method: type, selected: true, }))
    }
    if (type === 'isolate') {
      dispatch(generateReducer.actions.setSimpleOptionsState({ method: type, selected: true, displayName: t('authorized.project.generateAndVersions.formOptions.isolateOption')}))
    }
    if (type === 'grayscale') {
      dispatch(generateReducer.actions.setSimpleOptionsState({ method: type, selected: true, displayName: t('authorized.project.generateAndVersions.formOptions.grayscaleOption')}))
    }

    if (type === 'Grayscale Aug...') {
      dispatch(generateReducer.actions.setGrayscaleOptionState({ method: type, selected: true, percentage: grayscalePercentage }))
    }
    if (type === 'blur') {
      dispatch(generateReducer.actions.setBlurOptionState({ method: type, selected: true, params: { maxBlur: blurValue * 5 }, percentage: blurPercentage }))
    }
    if (type === 'noise') {
      dispatch(generateReducer.actions.setNoiseOptionState({ method: type, selected: true, params: { maxNoisePercentage: reactSliderValue }, percentage: noisePercentage }))
    }
    if (type === 'brightness') {
      dispatch(
        generateReducer.actions.setBrightnessOptionState({
          method: type,
          selected: true,
          params: { brightnessPercentage: reactSliderValue, mode: brightnessSelect },
          percentage: brightnessPercentage
        })
      )
    }

    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(generateReducer.actions.setShowButton())
    dispatch(generateReducer.actions.setShowAugmentButton())
  }

  const onWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= 0) {
      setResizeOptionInfo({ ...resizeOptionInfo, width: +e.target.value })
    }
    if (+e.target.value < 0) {
      setResizeOptionInfo({ ...resizeOptionInfo, width: 180 })
    }
  }

  const onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= 0) {
      setResizeOptionInfo({ ...resizeOptionInfo, height: +e.target.value })
    }
    if (+e.target.value < 0) {
      setResizeOptionInfo({ ...resizeOptionInfo, height: 180 })
    }
  }

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelect(e.target.value)
  }

  const onTileRowsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= 0) {
      setTileOptionInfo({ ...tileOptionInfo, rows: +e.target.value })
    }
  }

  const onTileColumnsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (+e.target.value >= 0) {
      setTileOptionInfo({ ...tileOptionInfo, columns: +e.target.value })
    }
  }

  const onReactSliderChange = (value: number, index: number) => {
    setReactSliderValue(value)
  }

  const onBlurChange = (value: number, index: number) => {
    setBlurValue(value / 5)
  }

  const onAugChange = (value: number, index: number) => {
    setImagesToAugmentCountValue(value)
  }

  const onBrightnessChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBrightnessSelect(e.target.value)
  }

  const onBrightnessValueChange = (value: number, index: number) => {
    setBrightnessValue(value)
  }

  const onBlurPercentageChange = (value: number) => {
    setBlurPercentage(value)
  }

  const onNoisePercentageChange = (value: number) => {
    setNoisePercentage(value)
  }

  const onBrightnessPercentageChange = (value: number) => {
    setBrightnessPercentage(value)
  }

  const onGrayscalePercentageChange = (value: number) => {
    setGrayscalePercentage(value)
  }

  return (
    <FlexContainer direction="column" justify="center" align="space-between">
      <FlexContainer direction="row" justify="space-around" align="flex-start">
        <FlexContainer direction="column" justify="space-around" align="center" gap="30px" margin="0 0 40px 0" widthProps="250px" paddingProps="0 20px">
          {type === 'resize' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={''}
                  type={'Resize'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'isolate' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlAnnotatedImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={owlIsolateImage}
                  type={'Isolate Objects'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'negate' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={owlContrastImage}
                  type={'Auto Contrast'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'grayscale' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={owlGrayScaleImage}
                  type={'Grayscale'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}

          {type === 'tile' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={''}
                  type={'Tile'}
                  select={select}
                  rows={tileOptionInfo.rows}
                  columns={tileOptionInfo.columns}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'Grayscale Aug...' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={owlGrayScaleImage}
                  type={'Grayscale'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'noise' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={owlNoice}
                  type={'Noise'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={reactSliderValue}
                  blur={blurValue}
                  imagesToAugmentCount={imagesToAugmentCountValue}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'blur' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterImageWrapper>
                <CanvasImage
                  image={''}
                  type={'Blur'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={reactSliderValue}
                  blur={blurValue}
                  imagesToAugmentCount={imagesToAugmentCountValue}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <span>{t('authorized.project.generateAndVersions.mofified')}</span>
              </StyledAfterImageWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'brightness' ? (
            <>
              <StyledBeforeImageWrapper>
                <StyledBeforeImage src={owlImage} />
                <span>{t('authorized.project.generateAndVersions.original')}</span>
              </StyledBeforeImageWrapper>
              <StyledAfterBothImagesWrapper>
                <CanvasDarken
                  image={''}
                  type={'d'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
                <CanvasLighten
                  image={''}
                  type={'l'}
                  select={select}
                  resizeHeight={resizeOptionInfo.height}
                  resizeWidth={resizeOptionInfo.width}
                  rows={0}
                  columns={0}
                  noice={0}
                  blur={0}
                  imagesToAugmentCount={0}
                  brightnessValue={brightnessValue}
                  brightnessSelect={brightnessSelect}
                />
              </StyledAfterBothImagesWrapper>
            </>
          ) : (
            ''
          )}
        </FlexContainer>
        <FlexContainer direction="column" justify="flex-start" align="center" gap="20px" widthProps="220px">
          {type === 'isolate' ? <h5>{t('authorized.project.generateAndVersions.formOptions.isolateText')}</h5> : ''}
          {type === 'resize' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.resizeOption')}</h5>
              <StyledSelect value={select} onChange={onSelectChange}>
                <option value="Contain">Contain</option>
                <option value="Fill">Fill</option>
                <option value="Inside">Inside</option>
                <option value="Outside">Outside</option>
              </StyledSelect>
              <Input type="number" widthProps="170px" label={t('errors.width')} value={resizeOptionInfo.width} onChange={onWidthChange} />
              <Input type="number" widthProps="170px" label={t('errors.height')} value={resizeOptionInfo.height} onChange={onHeightChange} />
            </>
          ) : (
            ''
          )}
          {type === 'negate' ? <h5>{t('authorized.project.generateAndVersions.formOptions.autoContrastOption')}</h5> : ''}
          {type === 'grayscale' ? <h5>{t('authorized.project.generateAndVersions.formOptions.grayscaleOption')}</h5> : ''}

          {type === 'tile' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.tileOption')}</h5>

              <Input
                type="number"
                widthProps="170px"
                label={t('authorized.project.generateAndVersions.formOptions.rows')}
                value={tileOptionInfo.rows}
                onChange={onTileRowsChange}
              />
              <Input
                type="number"
                widthProps="170px"
                label={t('authorized.project.generateAndVersions.formOptions.columns')}
                value={tileOptionInfo.columns}
                onChange={onTileColumnsChange}
              />
            </>
          ) : (
            ''
          )}
          {type === 'Grayscale Aug...' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.grayscaleOption')}</h5>
              <StyledWrapper>
                <StyledPercentageWrapper>
                  <h5>{t('authorized.project.generateAndVersions.imagesPecentage')}</h5>
                  {grayscalePercentage + '%'}
                </StyledPercentageWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={100}
                  min={1}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onGrayscalePercentageChange}
                />
              </StyledWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'noise' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.noiseOption')}</h5>
              <StyledWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={10}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={5}
                  onChange={onReactSliderChange}
                />
                {reactSliderValue + '%'}
              </StyledWrapper>
              <StyledWrapper>
                <StyledPercentageWrapper>
                  <h5>{t('authorized.project.generateAndVersions.imagesPecentage')}</h5>
                  {noisePercentage + '%'}
                </StyledPercentageWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={100}
                  min={1}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onNoisePercentageChange}
                />
              </StyledWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'blur' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.blurOption')}</h5>
              <StyledWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={25}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onBlurChange}
                />
                {blurValue * 5 + ' percents'}
              </StyledWrapper>
              <StyledWrapper>
                <StyledPercentageWrapper>
                  <h5>{t('authorized.project.generateAndVersions.imagesPecentage')}</h5>
                  {blurPercentage + '%'}
                </StyledPercentageWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={100}
                  min={1}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onBlurPercentageChange}
                />
              </StyledWrapper>
            </>
          ) : (
            ''
          )}
          {type === 'brightness' ? (
            <>
              <h5>{t('authorized.project.generateAndVersions.formOptions.brightnessVariants')}</h5>
              <StyledSelect value={brightnessSelect} onChange={onBrightnessChange}>
                <option value="both">{t('authorized.project.generateAndVersions.formOptions.brightnessBoth')}</option>
                <option value="lighten">{t('authorized.project.generateAndVersions.formOptions.brightnessLighten')}</option>
                <option value="darken">{t('authorized.project.generateAndVersions.formOptions.brightnessDarken')}</option>
              </StyledSelect>

              <StyledWrapper>
                {brightnessValue * 10 - 10 + '%'}
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={10}
                  min={1}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onBrightnessValueChange}
                />
              </StyledWrapper>
              <StyledWrapper>
                <StyledPercentageWrapper>
                  <h5>{t('authorized.project.generateAndVersions.imagesPecentage')}</h5>
                  {brightnessPercentage + '%'}
                </StyledPercentageWrapper>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={1}
                  max={100}
                  min={1}
                  ariaLabel={'Upper thumb'}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}></div>}
                  pearling
                  minDistance={1}
                  onChange={onBrightnessPercentageChange}
                />
              </StyledWrapper>
            </>
          ) : (
            ''
          )}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer direction="row" justify="center" align="center" paddingProps="0 15px" gap="40px">
        {showButton && globalType === 'preprocessing' ? <ProjectButton onClick={onCantelClick}>{t('buttons.back')}</ProjectButton> : ''}
        {showAugmentationButton && globalType === 'augmentation' ? <ProjectButton onClick={onCantelClick}>{t('buttons.back')}</ProjectButton> : ''}
        <ProjectButton onClick={onApplyClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default FormOptionForm
