import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface IUserResponce {
    id: string;
    balance: number;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    type: number;
    isActive: boolean;
    updatedAt: string;
    createdAt: string;
    role: IRole;
}

export interface IRole {
    id: string;
    name: string;
    displayName: string;
    roleType: string;
    createdAt: string;
    updatedAt: string;
}

export interface IUsersMeResponse {
    id: string;
    balance: number;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    isActive: boolean;
    updatedAt: string;
    createdAt: string;
    role: IRole;
}

export interface ICreateUserRequest {
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    userType: string;    
}

export interface ICreateUserResponse {
  user: IUserResponce;
  message: string;
}

interface IGetAllUsersResponse {
    users: IUserResponce[];
    total: number;
}

export interface IGetUserByIdResponse {
    user: IUserResponce;
}

interface IPutUserByIdResponse {
    user: IUserResponce;
    message: string;
}

export interface IGetUsersMeResponse {
    user: IUsersMeResponse
}

export default class UserService {
    static createUser(user: ICreateUserRequest): Promise<AxiosResponse<ICreateUserResponse>> {
        return $authHost.post<ICreateUserResponse>('/users', {user})
    }

    static getAllUsers(): Promise<AxiosResponse<IGetAllUsersResponse>> {
        return $authHost.get<IGetAllUsersResponse>('/users')
    }

    static async getUserById(id: string): Promise<AxiosResponse<IGetUserByIdResponse>> {
        return $authHost.get<IGetUserByIdResponse>(`/users/${id}`)
    }
    
    static getUserInfo(): Promise<AxiosResponse<IGetUsersMeResponse>> {
        return $authHost.get<IGetUsersMeResponse>('/users/me')
    }

    static async updateUser(id: string, user: IUserResponce): Promise<AxiosResponse<IPutUserByIdResponse>> {
        return $authHost.put<IPutUserByIdResponse>(`/users/`, {id: id, firstName: user.firstName, lastName: user.lastName})
    }

    static async deleteUser(id: string): Promise<void> {
        return $authHost.delete(`/users/${id}`)
    } 
}
