import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components';
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer';
import { StyledSelectWrapper, StyledUserCardAvatar } from '../../../../constants/styled';
import ProjectSelect from '../../../../../../UI/ProjectSelect/ProjectSelect';
import { Option } from '../../../../store/reducers/TabsReducer';

interface IGroupUserCheckboxProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>, setChecked: any) => void;
    value: string;
    title: string;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorText: React.Dispatch<React.SetStateAction<string>>;
    checkboxesValue: any[];
    setCheckboxesValue: React.Dispatch<React.SetStateAction<any[]>>;
    firstName: string;
    lastName: string;
    optionsTable: Option[];
}

const StyledInput = styled.input`
    width: 18px;
    height: 18px;
`

const StyledCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    font-size: 14px;
`

const GroupUserCheckbox: FC<IGroupUserCheckboxProps> = ({optionsTable, onChange, setVisible, setErrorText, setCheckboxesValue, checkboxesValue, value, title, firstName, lastName, ...props}) => {

  const [role, setRole] = useState("");
  const [valueWithRole, setValueWithRole] = useState('')
  const [checked, setChecked] = useState(false)

  const handleRoleSelect = (value: string) => {
    setRole(value);
    setErrorText('')
    setVisible(false)

    if (checked) {
      const checkboxesValueCopy = [...checkboxesValue]
      const findedEmail = checkboxesValueCopy.find(email => email.email === title)
      if (findedEmail) {
        findedEmail.role = value
        setCheckboxesValue(checkboxesValueCopy)
      }
    }
  };

  const showError = () => {
    setErrorText('Please, select role before mark user')
    setVisible(true)
  }

  useEffect(() => {
    if (value) {
      setValueWithRole(value + ' ' + role)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[role])

  const selectedRole = optionsTable.find((item) => item.value === role);
  return (
    <StyledCheckboxWrapper>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='10px'>
          <StyledInput type='checkbox' value={valueWithRole} onChange={(e) => role ? onChange(e, setChecked) : showError()} checked={checked}/>
          <StyledUserCardAvatar widthProps='40px' heightProps='40px' minWidth="40px"/>
          <FlexContainer direction='column' justify='center' align='flex-start'>
            <span>{firstName + ' ' + lastName}</span>
            <span>{title}</span>
          </FlexContainer>
        </FlexContainer>
        <StyledSelectWrapper>
          <ProjectSelect options={optionsTable} mode='rows' selected={selectedRole || null} onChange={handleRoleSelect} placeholder="Выберите роль"/>
        </StyledSelectWrapper>
    </StyledCheckboxWrapper>
  )
}

export default GroupUserCheckbox