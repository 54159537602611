import React from 'react'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { StyledPackagesWrapper } from '../../constants/styled'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { ISelectQuotaFormProps } from '../../constants/interfaces'
import QuotaCard from './QuotaCard'
import { useTranslation } from 'react-i18next'

const SelectQuotaForm: React.FC<ISelectQuotaFormProps> = ({groupQuotas, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='30px'>
      <StyledPackagesWrapper>
        {groupQuotas?.map(quota => <QuotaCard key={quota.id} quota={quota} groupQuotas={groupQuotas}/>)}
      </StyledPackagesWrapper>
      <FlexContainer direction='row' justify='center' align='center' paddingProps='0 20px'>
        <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default SelectQuotaForm