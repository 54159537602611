import React, { FC } from 'react'
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { PasswordRecoveryForm } from '../../../modules/PasswordRecoveryForm/components/PasswordRecoveryForm';
import { IPagesProps } from '../../../models/IPagesProps';
import UserNav from '../../../components/UserNav/UserNav';

export const PasswordRecoveryPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <FlexContainer paddingProps='0' heightProps='80vh' justify='center' align='center' direction='column' gap='10px'>
        <PasswordRecoveryForm navigate={navigate}></PasswordRecoveryForm>
      </FlexContainer>
    </>
  )
}
