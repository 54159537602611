/* eslint-disable no-lone-blocks */
import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { StyledBr } from '../../../../Projects/constants/Styled'
import { StyledHeader, StyledProjects } from '../../../constants/styled'
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import DatasetJob from './components/DatasetJob/DatasetJob'
import TaskColumn from './components/TaskColumn/TaskColumn'
import UnnanotatedBatch from './components/UnassignedBatch/UnassignedBatch'
import AnnotatingJob from './components/AnnotatingJob/AnnotatingJob'
import { asyncGetAllBatches, asyncGetDatasetsSummary, asyncGetTasksSummary, asyncUpdateTasksStatuses } from '../../../../../store/reducers/AcyncActions'
import RoleTaskColumn from './components/RoleTaskColumn/RoleTaskColumn'
import { Option } from '../../../../../UI/ProjectSelect/ProjectSelect'
import { ISummaryTask } from '../../../../../services/TasksService'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import Loader from '../../../../../UI/Loader/Loader'
import { useTranslation } from 'react-i18next'
import { IProject } from '../../../../../services/ProjectService'

interface IProjectAnnotateProps {
  navigate: NavigateFunction
  projectInfo: IProject
}

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 30px 30px;
  border-radius: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

export const UserWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 30px 100px 30px;
  border-radius: 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ProjectAnnotate: FC<IProjectAnnotateProps> = ({ projectInfo, navigate, ...props }) => {
  const { t } = useTranslation()
  const isRoleLoading = useAppSelector((state) => state.WorkspacesReducer.isRoleLoading)
  const isSettingsLoading = useAppSelector((state) => state.SettingsReducer.isLoading)
  const batches = useAppSelector((state) => state.batchesReducer.summaryBatches)
  const tasks = useAppSelector((state) => state.tasksReducer.tasksSummary)
  const datasets = useAppSelector((state) => state.datasetsReducer.datasetsSummary)
  const viewMode = useAppSelector((state) => state.TabsReducer.viewMode)
  const dispatch = useAppDispatch()
  const params = useParams()

  const isDatasetsLoading = useAppSelector((state) => state.datasetsReducer.isLoading)
  const isBatchesLoading = useAppSelector((state) => state.batchesReducer.isLoading)
  const isTasksLoading = useAppSelector((state) => state.tasksReducer.isLoading)

  const [mode, setMode] = useState<Option>({ title: '', value: '' })
  const [filteredTasks, setFilteredTasks] = useState<ISummaryTask[]>([])

  const [isAnnotateLoading, setIsAnnotateLoading] = useState(true)

  console.log('tasks', tasks)
  console.log('filteredTasks', filteredTasks)

  useEffect(() => {
    if (!isDatasetsLoading && !isBatchesLoading && !isTasksLoading) {
      setIsAnnotateLoading(false)
    } else setIsAnnotateLoading(true)
  }, [isTasksLoading, isBatchesLoading, isDatasetsLoading])

  const tasksObserver = () => {
    if (tasks?.tasks?.length) {
      const unavailableTask = tasks?.tasks.find((task) => task?.status.name === 'in_queue' || task?.status.name === 'processing' || task?.status.name === 'automarkup')
      if (unavailableTask?.task && params.id && params.tab === 'annotate') {
        setTimeout(() => {
          dispatch(asyncUpdateTasksStatuses(params.id!, true))
        }, 15000)
        console.log('unavailableTask => asyncGetTasksSummary')
      }
    }
  }

  useEffect(() => {
    if (params.tab === 'annotate') {
      dispatch(tabsSlice.actions.setActiveTab('annotate'))
      if (params.id) {
        dispatch(asyncGetAllBatches(params.id))
        dispatch(asyncGetTasksSummary(params.id))
        dispatch(asyncGetDatasetsSummary(params.id))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options: Option[] = [
    { title: t('authorized.project.annotate.filterOptions.all'), value: 'all' },
    { title: t('authorized.project.annotate.filterOptions.in_pool'), value: 'in_pool' },
    { title: t('authorized.project.annotate.filterOptions.in_work'), value: 'in_work' },
    { title: t('authorized.project.annotate.filterOptions.on_review'), value: 'on_review' },
    { title: t('authorized.project.annotate.filterOptions.approved'), value: 'approved' },
    { title: t('authorized.project.annotate.filterOptions.rejected'), value: 'rejected' },
    { title: t('authorized.project.annotate.filterOptions.in_queue'), value: 'in_queue' },
    { title: t('authorized.project.annotate.filterOptions.processing'), value: 'processing' },
    { title: t('authorized.project.annotate.filterOptions.automarkup'), value: 'automarkup' }
  ]

  const handleFilterSelect = (value: string) => {
    const findedMode = options.find((item) => item.value === value)
    if (findedMode) {
      setMode(findedMode)
    }
  }

  useEffect(() => {
    if (mode?.value && tasks?.tasks?.length) {
      if (mode.value === 'all') {
        setFilteredTasks(tasks.tasks)
      }
      if (mode.value === 'in_pool') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'in_pool')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'in_work') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'in_work')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'on_review') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'on_review')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'approved') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'approved')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'rejected') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'rejected')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'in_queue') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'in_queue')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'processing') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'processing')
        setFilteredTasks(filteredTasks)
      }
      if (mode.value === 'automarkup') {
        const filteredTasks = tasks.tasks.filter((task) => task.status.name === 'automarkup')
        setFilteredTasks(filteredTasks)
      }
    }
    if (!tasks?.tasks?.length) {
      setFilteredTasks([])
    }
    if (!mode?.value && tasks?.tasks?.length) {
      setFilteredTasks(tasks.tasks)
    }
    // tasksObserver()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, tasks])

  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo?.name ? (
          <h3>
            {projectInfo?.name} {t('authorized.project.nav.annotate')}
          </h3>
        ) : (
          <h3>{t('authorized.project.nav.annotate')}</h3>
        )}
      </StyledHeader>
      <StyledBr />

      {viewMode.value === 'ADMIN' ? (
        <Wrapper>
          <TaskColumn navigate={navigate} projectInfo={projectInfo} taskType={t('authorized.project.annotate.unassigned')} counter={batches?.length ? batches.length : 0}>
            {!isBatchesLoading ? (
              batches?.map((batch) => (
                <UnnanotatedBatch
                  navigate={navigate}
                  projectInfo={projectInfo}
                  counter={batch?.imagesCount}
                  date={batch?.name}
                  batchInfo={batch?.batch}
                  key={batch?.batch}
                  batch={batch}
                />
              ))
            ) : (
              <FlexContainer direction="column" justify="center" align="center" heightProps="80%" gap="20px">
                <Loader />
              </FlexContainer>
            )}
          </TaskColumn>
          <TaskColumn
            options={options}
            handleFilterSelect={handleFilterSelect}
            mode={mode}
            navigate={navigate}
            projectInfo={projectInfo}
            taskType={t('authorized.project.annotate.annotating')}
            counter={tasks?.total ? tasks?.total : 0}
          >
            {!isTasksLoading ? (
              filteredTasks?.map((task, index) => (
                <AnnotatingJob
                  navigate={navigate}
                  projectInfo={projectInfo}
                  date={task?.name ? task?.name : ''}
                  annotated={task?.annotatedCount === 0 ? task?.annotatedCount : task?.annotatedCount}
                  unannotated={task?.unannotatedCount === 0 ? task?.unannotatedCount : task?.unannotatedCount}
                  jobInfo={task}
                  key={task?.task ? task?.task : performance.now() + index}
                />
              ))
            ) : (
              <FlexContainer direction="column" justify="center" align="center" heightProps="80%" gap="20px">
                <Loader />
              </FlexContainer>
            )}
          </TaskColumn>
          <TaskColumn navigate={navigate} projectInfo={projectInfo} taskType={t('authorized.project.annotate.dataset')} counter={datasets.total ? datasets.total : 0}>
            {!isDatasetsLoading ? (
              datasets?.datasets?.map((dataset) => <DatasetJob navigate={navigate} projectInfo={projectInfo} datasetInfo={dataset} key={dataset.dataset} />)
            ) : (
              <FlexContainer direction="column" justify="center" align="center" heightProps="80%" gap="20px">
                <Loader />
              </FlexContainer>
            )}
          </TaskColumn>
        </Wrapper>
      ) : (
        ''
      )}
      {(viewMode.value === 'MODER' || viewMode.value === 'LABELLER') && !isAnnotateLoading ? (
        <Wrapper>
          <RoleTaskColumn
            type="pool"
            headerText={t('authorized.project.annotate.workers.toWork')}
            title={t('authorized.project.annotate.workers.toWorkText')}
            tasks={tasks.tasks}
            navigate={navigate}
            projectInfo={projectInfo}
          />
          <RoleTaskColumn
            type="processing"
            headerText={t('authorized.project.annotate.workers.inWork')}
            title={t('authorized.project.annotate.workers.inWorlText')}
            tasks={tasks.tasks}
            navigate={navigate}
            projectInfo={projectInfo}
          />
          <RoleTaskColumn
            type="done"
            headerText={t('authorized.project.annotate.workers.completed')}
            title={t('authorized.project.annotate.workers.completedText')}
            tasks={tasks.tasks}
            navigate={navigate}
            projectInfo={projectInfo}
          />
        </Wrapper>
      ) : (
        ''
      )}
      {viewMode.value === 'USER' && !isAnnotateLoading ? (
        <UserWrapper>
          <span>{isRoleLoading || isSettingsLoading ? <Loader /> : t('other.haventAccess')}</span>
        </UserWrapper>
      ) : (
        ''
      )}
    </StyledProjects>
  )
}

export default ProjectAnnotate
