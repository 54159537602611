import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { Colors } from '../../../../../models/Colors'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { StyledBr, StyledHeader } from '../../../../Projects/constants/Styled'
import { IOverviewProps } from '../../../models/IOverviewProps'
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import { useTranslation } from 'react-i18next'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import Loader from '../../../../../UI/Loader/Loader'
import Editor from '../../../../Editor'
import { StyledVHContentWrapper } from '../../../constants/styled'

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 80px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 25px;
  margin-left: 20px;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  height: 100%;

  ul {
    margin: 0;
    padding-left: 20px;
  }
`
const StyledReadme = styled.span`
  color: ${Colors.orange};
  cursor: pointer;
  :hover {
    color: ${Colors.lightorange};
  }
`

const StyledEditorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-bottom: 15px;
  margin-top: 25px;
`

const ProjectOverview: FC<IOverviewProps> = ({ navigate, projectInfo, param, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isEditMode, setIsEditMode] = useState(false)
  const [isCancelEdit, setIsCancelEdit] = useState(true)

  const { isLoading, error, editorHtmlState, readme } = useAppSelector((state) => state.editorReducer)

  useEffect(() => {
    if (param === 'overview') {
      dispatch(tabsSlice.actions.setActiveTab('overview'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onReadmeClick = () => {
    setIsEditMode(true)
  }

  const onApplyClick = () => {
    setIsEditMode(false)
  }

  const onCancelEditClick = () => {
    setIsCancelEdit(!isCancelEdit)
    setIsEditMode(false)
  }

  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo.name ? (
          <h3 style={{ width: '100%', textAlign: 'left' }}>
            {projectInfo.name} {t('authorized.project.overview.overview')}
          </h3>
        ) : (
          <h3>{t('authorized.project.overview.overview')}</h3>
        )}
        {isEditMode && !error && !isLoading ? (
          <FlexContainer direction="row" justify="flex-end" align="center" gap="10px">
            <ProjectButton onClick={onCancelEditClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onApplyClick}>{t('buttons.apply')}</ProjectButton>
          </FlexContainer>
        ) : (
          <ProjectButton onClick={onReadmeClick}>{t('buttons.edit')}</ProjectButton>
        )}
      </StyledHeader>
      <StyledVHContentWrapper>
        <StyledBr />
        <Wrapper>
          {isLoading ? (
            <FlexContainer direction="row" justify="center" margin="100px 0 0 0">
              <Loader />
            </FlexContainer>
          ) : (
            ''
          )}
          {!isLoading && error ? (
            <FlexContainer direction="row" justify="center" margin="100px 0 0 0">
              {error}
            </FlexContainer>
          ) : (
            ''
          )}
          {!readme && !error && !isLoading && !isEditMode ? (
            <>
              <span>
                <StyledReadme onClick={onReadmeClick}>{t('authorized.project.overview.addLink')}</StyledReadme>
                {t('authorized.project.overview.addLinkText')}
              </span>
              <span>{t('authorized.project.overview.listTItle')}</span>
              <ul>
                <li>{t('authorized.project.overview.listItem1')}</li>
                <li>{t('authorized.project.overview.listItem2')}</li>
                <li>{t('authorized.project.overview.listItem3')}</li>
                <li>{t('authorized.project.overview.listItem4')}</li>
                <li>{t('authorized.project.overview.listItem5')}</li>
              </ul>
            </>
          ) : (
            ''
          )}
          {readme && !error && !isLoading && !isEditMode ? '' : ''}
        </Wrapper>
        {isEditMode ? (
          <StyledEditorWrapper>
            <Editor onloadContent={''} readOnly={!isEditMode} cancel={isCancelEdit} overview={true} />
          </StyledEditorWrapper>
        ) : (
          ''
        )}
      </StyledVHContentWrapper>
    </StyledProjects>
  )
}

export default ProjectOverview
