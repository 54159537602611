import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledBr, StyledRangeNumber, StyledSlideP } from '../../../../Styled/styled'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import AutoMarkupSlideForm from '../../AutoMarkupSlideForm'
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { Colors } from '../../../../../../models/Colors'
import AutoMarkupService, { IAutoMarkupTaskToCreate } from '../../../../../../services/AutoMarkupService'
import JobsService from '../../../../../../services/JobsService'
import { autoMarkupSlice } from '../../../../store/AutoMarkupSlice'
import { useNavigate, useParams } from 'react-router-dom'
import InputRadio from '../../../../../../UI/InputRadio/InputRadio'
import { StyledLink } from '../../../../../ProjectModule/constants/styled'
import { SettingsReducer } from '../../../../../UserSettings/reducers/SettingsReducer'
import QuotaLimitForm from '../../../../../../UI/QuotaLimitForm/QuotaLimitForm'
import { drawTabsSlice } from '../../../../store/DrawTabsReducer'
import { getMaxTrehold, getPrice, getTitle, getTreholdDefault } from '../../../../Utils/AutoMarkupHelpers'
import { useTranslation } from 'react-i18next'
import { imagesListSlice } from '../../../../../ImagesList/store/ImagesListReducer'

interface ICounterSlideProps {
  type: string;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 15px;
  width: 90%;

  .horizontal-slider {
    width: 100%;
    height: 48px;
    margin: auto;
    cursor: pointer;
  }

  /* .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: red;
    border: 8px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    
  } */

  .example-thumb.active {
    border: 2px solid orange;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #ddd;
  }

  .example-track.example-track-1 {
    background:  #ddd;
  }

  .example-track.example-track-2 {
    background: #ddd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    /* top: 20px; */
    /* width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px; */
  }
`

const StyledThumb = styled.div`
  position: absolute;
  top: 10px;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  border: 2px solid ${Colors.gray};
  background-color: #fff;
  color: ${Colors.gray};
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
`;

const StyledThumbValue = styled.div`
      position: absolute;
      top: 25px;
      left: -7px;
      
      text-align: center;
      min-width: 35px;
      height: 25px;
      color: #fff;

`

const Thumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
        {state.valueNow}
    </StyledThumbValue>
  </StyledThumb>
);

const PercentsThumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
        {state.valueNow + '%'}
    </StyledThumbValue>
  </StyledThumb>
);

const LoadingSlide: FC<ICounterSlideProps> = ({type, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const {classesState} = useAppSelector(state => state.AutoMarkupReducer)
  const { groupInfo } = useAppSelector(state => state.WorkspacesReducer)
  const currentTask = useAppSelector(state => state.tasksReducer.currentTask)
  const { selectedModel, tags } = useAppSelector(state => state.AutoMarkupReducer)
  const isCloseClick = useAppSelector(state => state.AutoMarkupReducer.automarkupCloseClick)
  const classesPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE')
  const textPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE_TEXT')
  const segmentPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE_SEGMENTATION')
  const quota = useAppSelector(state => state.SettingsReducer.groupQuotas)
    .find(quota => quota.name === 'automarkup_credits_monthly')

  const imagesToSegment = useAppSelector(state => state.AutoMarkupReducer.imagesToSegment)

  const { selectedSplit, selectedImages, images } = useAppSelector(state => state.ImagesListReducer)
  
  const [sliderValue, setSliderValue] = useState(1)
  const [threshold, setThreshold] = useState(65)
  const [checked, setChecked] = useState(false);
  const [createChecked, setCreateChecked] = useState(true);

  const [isEnoughBalance, setIsEnoughBalance] = useState(false)

  useEffect(() => {
    dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(false))
  }, [])
  
  const onCancelClick = () => {
    if (selectedModel.type === 'segmentation') {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setModalWidth('580px'))
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
      dispatch(modalWindowSlice.actions.setVisible())
    }
    if (selectedModel.type === 'text') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.textEntering')))
      dispatch(modalWindowSlice.actions.setModalWidth('80%'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='textModels'/>))
    }
    if (selectedModel.type === 'classes') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.classesSelection')))
      dispatch(modalWindowSlice.actions.setModalWidth('80%'))
      dispatch(modalWindowSlice.actions.setModalHeight('90%'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='models'/>))
    }
  }

  const deleteTask = async (job: string) => {
    try {
      const responce = await JobsService.deleteJob(job)
      if (responce.status === 404 || responce.status === 200) {
        return
      }
    } catch (error) {
      console.log('deleteTask', error)
    }
  }

  const getProgress = async (job : string) => {
    try {
        if (job) {
            const autoMarkupResponce = await AutoMarkupService.getAutoMarkupTaskById(job)
            console.log('autoMarkupResponce', autoMarkupResponce)

            if (autoMarkupResponce.status === 500) {
              dispatch(modalWindowSlice.actions.setChildren(
              <FlexContainer direction='column' justify='center' align='center' gap='30px'>
                <span>{t('errors.network')}</span>
                <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps='150px'>{t('buttons.close')}</ProjectButton>
              </FlexContainer>  
              ))
              deleteTask(job)
              throw new Error('No Responce.data :(')
            }

            if (autoMarkupResponce.status === 202){
                console.log(autoMarkupResponce?.data?.progress)
                dispatch(autoMarkupSlice.actions.setProgress(autoMarkupResponce?.data?.progress))
            }
  
            if (autoMarkupResponce.status === 200 && currentTask.task.project && currentTask.task.id) {
                console.log('DONE!')
                dispatch(autoMarkupSlice.actions.setProgress(0))
                dispatch(modalWindowSlice.actions.setInititialState())
                dispatch(autoMarkupSlice.actions.setInitialState())
                dispatch(imagesListSlice.actions.setInitialState())
                // if (selectedSplit?.value) {
                //   selectedSplit.value === 'all'
                //   ? dispatch(imagesListSlice.actions.setSelectedSplit({title: t('imagesList.automarkup'), value: 'automarkup' }))
                //   : dispatch(imagesListSlice.actions.setSelectedSplit({ title: t('imagesList.allTypes'), value: 'all' }))
                // }
                navigate(`/projects/${currentTask.task.project}/job/annotate/${currentTask.task.id}`)

                deleteTask(job)
            }

            if (isCloseClick) {
              return
            }

            if (autoMarkupResponce.status === 202) {
                if (isCloseClick) {
                  return
                }
                if (job) {
                    console.log('Retry!, isCloseClick', isCloseClick)
                    setTimeout(() => {
                        getProgress(job)
                    }, 500);
                }
            }
        }
    } catch (error: any) {
      console.log(error)
      if (error.response.status === 400) {
        dispatch(modalWindowSlice.actions.setModalWidth('500px'))
        dispatch(modalWindowSlice.actions.setModalHeight('auto'))
        dispatch(modalWindowSlice.actions.setChildren(<QuotaLimitForm text={t('errors.coinsQuotaError')}/>))
        return
      }
      dispatch(drawTabsSlice.actions.setErrorState('400'))
      dispatch(modalWindowSlice.actions.setTitle('Oops!'))
      dispatch(modalWindowSlice.actions.setChildren(error.responce.data.error))
    }
  }

  const subscribe = async (payload: IAutoMarkupTaskToCreate) => {
    try {
        const responce = await AutoMarkupService.createAutoMarkupTask(payload)
        console.log('subscribe', responce)
        getProgress(responce.data.job)

        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.progressTitle')))
        dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='loading'/>))
        dispatch(modalWindowSlice.actions.setVisible())
    } catch (error: any) {
      console.log(error)
      if (error.response.status === 400) {
        dispatch(modalWindowSlice.actions.setModalWidth('500px'))
        dispatch(modalWindowSlice.actions.setModalHeight('auto'))
        dispatch(modalWindowSlice.actions.setChildren(<QuotaLimitForm text={t('errors.coinsQuotaError')}/>))
        return
      }
      dispatch(drawTabsSlice.actions.setErrorState('400'))
      dispatch(modalWindowSlice.actions.setTitle('Oops!'))
      dispatch(modalWindowSlice.actions.setChildren(error.responce.data.error))
    }
  }

  console.log('paramparam', params)

  const payloadConstructor = () => {
    const payload: any = {}
    if (selectedModel && !isEnoughBalance) {
      payload.taskId = currentTask.task.id
      payload.remarkup = checked
      payload.score_threshold = threshold/100

      if (selectedModel.type === 'classes') {
        const classesToAutoMarkup = classesState.map((cl: any) => ({id: cl.id, name: cl.name}))
        payload.service = 'automarkup'
        payload.createNewLabel = createChecked
        payload.models = [{
          id: selectedModel.id,
          name: selectedModel.name,
          classes: classesToAutoMarkup
        }]
      }
      if (selectedModel.type === 'text') {
        payload.service = 'automarkupText'
        payload.createNewLabel = createChecked
        payload.models =[{
          id: selectedModel.id,
          name: selectedModel.name,
          textPrompts: tags
        }]
      }
      if (selectedModel.type === 'segmentation') {
        payload.service = 'segmentation'
        payload.models = [{
          id: selectedModel.id,
          name: selectedModel.name,
          classes: []
        }]
      }

      //Формирование списка изображений
      if (sliderValue && images?.length && !selectedImages?.length) {
        const imagesToPayload: string[] = []
        for (let i = 0; i <= sliderValue -1; i++) {
          console.log(images[i], i)
          imagesToPayload.push(images[i].id)
        }
        payload.images = imagesToPayload
      }
      if (sliderValue && selectedImages?.length) {
        const imagesToPayload: string[] = []
        for (let i = 0; i <= sliderValue -1; i++) {
          console.log(selectedImages[i], i)
          imagesToPayload.push(selectedImages[i].id)
        }
        payload.images = imagesToPayload
      }
      if (sliderValue && type === 'segment') {
        const imagesToPayload: string[] = []
        for (let i = 0; i <= sliderValue -1; i++) {
          console.log(imagesToSegment[i], i)
          imagesToPayload.push(imagesToSegment[i])
        }
        payload.images = imagesToPayload
      }
    }
    return payload
  }

  const onContinueClick = () => {    
    const payload = payloadConstructor()
    console.log(payload)
    subscribe(payload)
  }

  const onReactSliderChange = (value: number | readonly number[], index: number) => {
    console.log('value', value)
    if (typeof value === 'number') {
        setSliderValue(value)

        if (quota) {
          const isEnoughBalance = (value * +getPrice(selectedModel, classesPrice, textPrice, textPrice)) > (quota.limit - quota.used)
          setIsEnoughBalance(isEnoughBalance)
          console.log('isEnoughBalance', isEnoughBalance)
        }
    }
  }

  const onThresholdChange = (value: number | readonly number[], index: number) => {
    console.log('value', value)
    if (typeof value === 'number') {
        setThreshold(value)
    }
  }

  const getImagesSliderMax = () => {
    if (type === 'segment') {
      return imagesToSegment?.length
    }
    if (selectedImages?.length) {
      return selectedImages?.length
    }
    if (!selectedImages?.length) {
      return images.length
    }
  }

  const onSettingsClick = () => {
    if (groupInfo?.id) {
      dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
      navigate(`/settings/group/${groupInfo.id}`)
      dispatch(modalWindowSlice.actions.setInititialState())
      return
    }
    navigate(`/settings`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='20px'>
         <StyledSlideP>
            {getTitle(selectedModel)}
        </StyledSlideP>
        <StyledSlideP>
            {t('authorized.project.draw.automarkupTool.availableCredits')}
            <StyledLink onClick={onSettingsClick}>{quota? ' ' + (quota.limit - quota.used) : ''}</StyledLink>
        </StyledSlideP>

        <FlexContainer  direction='column' justify='flex-start' align='center' gap='8px'>
          <StyledSlideP>
            {t('authorized.project.draw.automarkupTool.selectTrashold')}
          </StyledSlideP>
          <StyledWrapper>
            <StyledRangeNumber isCoincidence={threshold === 1}>1%</StyledRangeNumber>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[getTreholdDefault(selectedModel.type)]}
              min={1}
              max={getMaxTrehold(selectedModel.type)}
              ariaValuetext={state => `${state.valueNow}`}
              renderThumb={PercentsThumb}
              minDistance={1}
              onChange={onThresholdChange}
            />
            <StyledRangeNumber isCoincidence={selectedModel.type === 'text' ? threshold === 30 : threshold === 99}>{selectedModel.type === 'text' ? 30 : 99}%</StyledRangeNumber>
          </StyledWrapper>
        </FlexContainer>

        <FlexContainer  direction='column' justify='flex-start' align='center' gap='8px' margin='10px 0 0 0'>
          <StyledSlideP>
            {t('authorized.project.draw.automarkupTool.selectImages')}
          </StyledSlideP>
          <StyledWrapper>
            <StyledRangeNumber isCoincidence={sliderValue === 1}>1</StyledRangeNumber>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[1]}
              min={1}
              max={getImagesSliderMax()}
              ariaLabel={['Lower thumb', 'Upper thumb']}
              ariaValuetext={state => `${state.valueNow}`}
              renderThumb={Thumb}
              step={1}
              minDistance={1}
              onChange={onReactSliderChange}
            />
            <StyledRangeNumber isCoincidence={sliderValue === getImagesSliderMax()}>{getImagesSliderMax()}</StyledRangeNumber>
          </StyledWrapper>
        </FlexContainer>
        {type !== 'segment'
          ?
            <FlexContainer direction='row' justify='center' align='center' widthProps='100%'>
              <InputRadio 
                type="checkbox"
                label={t('authorized.project.draw.createAutoClasses')}  
                checked={createChecked} 
                onClick={() => setCreateChecked(!createChecked)}
              />
            </FlexContainer>
          : ''
        }

        <FlexContainer direction='row' justify='center' align='center' widthProps='100%'>
          <InputRadio 
            type="checkbox"
            label={t('authorized.project.draw.automarkupTool.clearMarkup')}  
            checked={checked} 
            onClick={() => setChecked(!checked)}
          />
        </FlexContainer>

        <StyledBr/>
        <FlexContainer direction='row' justify='space-between' align='center' paddingProps='10px 20px'>
          <span>{t('authorized.settings.topUpForm.total')}:</span>
          <span>{t('authorized.project.draw.automarkupTool.cretit', {count: +getPrice(selectedModel, classesPrice, textPrice, segmentPrice) * sliderValue})}</span>
        </FlexContainer>

        <FlexContainer direction='row' justify='space-around'>
          <ProjectButton onClick={onCancelClick}>{t('buttons.back')}</ProjectButton>
          <ProjectButton onClick={onContinueClick} disabled={isEnoughBalance}>{t('buttons.continue')}</ProjectButton>
          {/* <ProjectButton 
            disabled={!isTestPassed} 
            withTooltip
            tooltipText="Опробуйте тестовую разметку перед началом работы с авто разметкой"
          >
            Continue
          </ProjectButton> */}
        </FlexContainer>
    </FlexContainer>
  )
}

export default LoadingSlide