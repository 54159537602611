import { convertToRaw, EditorState, convertFromHTML, ContentState } from 'draft-js'
import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import './editorStyles.css'
import createVideoPlugin from '@draft-js-plugins/video'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { ForumReducer } from '../Forum/store/slices/ForumReducer'
import { modalWindowSlice } from '../../store/reducers/modalWindowReducer'
import PasteImageForm from './PasteImageForm'
import { useTranslation } from 'react-i18next'
import { editorSlice } from './editorReducer'

export default function Index({ onloadContent, readOnly, cancel, overview}) {
  const { t } = useTranslation()
  const editorHtmlContent = useAppSelector((state) => state.ForumReducer.editorHtmlState)

  const [isCancel, setIsCancel] = useState(false)
  const dispatch = useAppDispatch()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [text, setText] = useState()

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState)
    const { blocks } = convertToRaw(editorState.getCurrentContent())
    /*let text = blocks.reduce((acc, item) => {
      acc = acc + item.text;
      return acc;
    }, "");*/
    let text = editorState.getCurrentContent().getPlainText('\u0001')
    setText(text)
    dispatch(ForumReducer.actions.setEditorContent(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
    dispatch(editorSlice.actions.setEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent()))))
  }

  const htmlToDraftBlocks = (onloadContent) => {
    const blocksFromHtml = htmlToDraft(onloadContent)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState)
    return editorState
  }

  useEffect(() => {
    if (onloadContent) {
      setEditorState(htmlToDraftBlocks(onloadContent))
    }
    if (!cancel) {
      setEditorState(htmlToDraftBlocks(onloadContent))
    }
  }, [onloadContent, cancel])

  const onPaste = async (pasteEvent) => {
    const item = pasteEvent.clipboardData.items[0]
    console.log(item)
    if (item.type.indexOf('image') === 0) {
      const blob = item.getAsFile()
      const reader = new FileReader()
      reader.onload = function (event) {
        const currentState = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        dispatch(modalWindowSlice.actions.setTitle(t('editor.imagePaste')))
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(
          modalWindowSlice.actions.setChildren(
            <PasteImageForm imageBase={event.target.result} currentState={currentState} setEditorState={setEditorState} htmlToDraftBlocks={htmlToDraftBlocks} />
          )
        )
        dispatch(modalWindowSlice.actions.setVisible())
      }
      reader.readAsDataURL(blob)
    }
  }

  return (
    <div onPaste={onPaste}>
      {/* <div style={{maxWidth: '100px'}}>{editorHtmlContent}</div> */}
      {/* {<div style={{ height: "80px", overflow: "auto" }}>{text}</div>} */}
      <Editor
        editorState={editorState}
        toolbarClassName={readOnly ? 'readOnlyToolbar' : 'toolbarClassName'}
        wrapperClassName={readOnly ? 'readOnlyWrapper' : 'wrapperClassName'}
        editorClassName={readOnly ? 'readOnlyEditor' : 'editorClassName'}
        onEditorStateChange={onEditorStateChange}
        readOnly={readOnly}
        placeholder={t('forum.enterText')}
        mention={{
          separator: ' ',
          trigger: '@',
          suggestions: []
        }}
      />
    </div>
  )
}
