import React from 'react'
import Tooltip from '../../../../UI/Tooltip/Tooltip'
import { StyledAutoMarkupSegmentToolImage, StyledAutoMarkupToolImage, StyledAutoMarkupToolTitle, StyledAutoMarkupToolWrapper, StyledTooltipWrapper } from '../../Styled/styled';
import { useTranslation } from 'react-i18next';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import Loader from '../../../../UI/Loader/Loader';

interface IAutoMarkupToolProps {
    description: string;
    title: string;
    image: any;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

const AutoMarkupTool: React.FC<IAutoMarkupToolProps> = ({image, title, description, onClick, disabled, isLoading, ...props}) => {
  const { t } = useTranslation()
  return (
    <StyledAutoMarkupToolWrapper onClick={onClick}>
      {isLoading && title === t('authorized.project.draw.automarkupTool.makupType3') 
        ? <FlexContainer direction='row' justify='center' align='center'><Loader/></FlexContainer>
        : <>
            {title === t('authorized.project.draw.automarkupTool.makupType3') 
              ? <StyledAutoMarkupSegmentToolImage srcProps={image} disabled={disabled}/>
              : <StyledAutoMarkupToolImage srcProps={image} disabled={disabled}/>
            }
            <StyledTooltipWrapper>
              <Tooltip>{description}</Tooltip>
            </StyledTooltipWrapper>
            <StyledAutoMarkupToolTitle>
              {title}
            </StyledAutoMarkupToolTitle>
          </>
      }

    </StyledAutoMarkupToolWrapper>
  )
}

export default AutoMarkupTool