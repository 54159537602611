import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, Params } from 'react-router-dom'
import { StyledCreateTopicWrapper, StyledForumsWrapper } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { asyncGetCategoryById, asyncGetSubCategoryById } from '../../../../../store/reducers/AcyncActions'
import CreateTopicHeader from './CreateTopicHeader'
import Input from '../../../../../UI/Input/Input'
import Editor from '../../../../Editor'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import ForumService from '../../../../../services/ForumService'
import { ForumReducer } from '../../../store/slices/ForumReducer'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { IUsersMeResponse } from '../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ICreateTopicProps {
  navigate: NavigateFunction
  params: Readonly<Params<string>>
  user: IUsersMeResponse
  isSub: boolean
}

const CreateTopicPage: FC<ICreateTopicProps> = ({ navigate, isSub, params, user, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const editorHtml = useAppSelector((state) => state.ForumReducer.editorHtmlState)
  const currentCategory = useAppSelector((state) => state.ForumReducer.currentCategory)
  const currentSubCategory = useAppSelector((state) => state.ForumReducer.currentSubCategory)

  const [topicTitle, setTopicTitle] = useState('')

  useEffect(() => {
    if (params.topics && params.categoryId && params.subcategoryId) {
      dispatch(asyncGetCategoryById(params.categoryId))
      dispatch(asyncGetSubCategoryById(params.subcategoryId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopicTitle(e.target.value)
  }

  const onCreateTopicClick = async () => {
    try {
      if (editorHtml && topicTitle) {
        dispatch(ForumReducer.actions.setLoading(true))
        if (isSub) {
          const responce = await ForumService.createTopic(topicTitle, editorHtml, currentSubCategory.category.id)
          console.log('CreateTopic', responce)
          navigate(`/forum/${currentCategory.category.id}/${currentSubCategory.category.id}/topic/${responce.data.forumTopic.id}`)
          window.scrollTo(0, 0)
        }
        if (!isSub && params.categoryId) {
          console.log('click')
          const responce = await ForumService.createTopic(topicTitle, editorHtml, params.categoryId)
          console.log('CreateTopic', responce)
          // await dispatch(asyncGetCategoriesSummary())
          const topicInfoResponce = await ForumService.getTopicById(responce.data.forumTopic.id)
          dispatch(ForumReducer.actions.setCreatedTopicToMain(topicInfoResponce.data))
          navigate('/forum')
          window.scrollTo(0, 0)
        }
        dispatch(ForumReducer.actions.setLoading(false))
      }
      if (!editorHtml || !topicTitle) {
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle('Attention!'))
        dispatch(modalWindowSlice.actions.setTitleColor('red'))
        dispatch(
          modalWindowSlice.actions.setChildren(
            <FlexContainer direction="column" justify="center" align="center" gap="20px">
              <span>{t('other.formError')}</span>
              <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>Ок</ProjectButton>
            </FlexContainer>
          )
        )
        dispatch(modalWindowSlice.actions.setVisible())
      }
    } catch (error) {
      dispatch(ForumReducer.actions.setLoading(false))
      dispatch(ForumReducer.actions.setError('Error on CreateTopic'))
    }
  }

  return (
    <StyledForumsWrapper>
      <CreateTopicHeader navigate={navigate} currentCategory={currentCategory} currentSubCategory={currentSubCategory} />
      <StyledCreateTopicWrapper>
        <Input label={t('other.name')} placeholder={t('forum.enterText')} widthProps="100%" value={topicTitle} onChange={onTitleChange} />
        <Editor onloadContent={''} readOnly={false} cancel={true} overview={false}/>
        <FlexContainer direction="row" justify="center" align="center">
          <ProjectButton onClick={onCreateTopicClick}>{t('forum.createTopic')}</ProjectButton>
        </FlexContainer>
      </StyledCreateTopicWrapper>
    </StyledForumsWrapper>
  )
}

export default CreateTopicPage
