import React, { FC, useEffect, useState } from 'react'
import { StyledDiv, StyledOptionEdit, StyledOptionInfo, StyledPreprocessingOption } from '../../../../constants/styled'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../../hooks/redux'
import { generateReducer } from '../../../../store/reducers/GenerateReducer'
import FormOptionForm from './FormOptionForm/FormOptionForm'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { t } from 'i18next'

interface ITabOptionProps {
  type: string
  info?: any
  selected: boolean
  reducerInfo?: any
  globalType: string
  apply?: number
  displayName: string
}

const TabOption: FC<ITabOptionProps> = ({ type, info, reducerInfo, globalType, apply, displayName, ...props }) => {
  const dispatch = useAppDispatch()
  const [uppercaseFirstLetter, setUppercaseFirstLetter] = useState(displayName)

  useEffect(() => {
    if (displayName) {
      setUppercaseFirstLetter(displayName.charAt(0).toUpperCase() + displayName.slice(1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOptionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    dispatch(modalWindowSlice.actions.setTitle(type))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('560px'))
    dispatch(generateReducer.actions.setShowAugmentButton())
    dispatch(modalWindowSlice.actions.setChildren(<FormOptionForm globalType={globalType} type={type} />))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (type === 'resize') {
      dispatch(generateReducer.actions.setResizeOptionState({ method: type, params: { width: 0, height: 0, fit: 'fill' }, selected: false }))
    }
    if (type === 'tile') {
      dispatch(generateReducer.actions.setTileOptionState({ method: type, params: { columns: 0, rows: 0 }, selected: false }))
    }
    if (type === 'negate') {
      dispatch(generateReducer.actions.setAutoContrastOptionState({ method: type, selected: false }))
    }
    if (type === 'isolate') {
      dispatch(generateReducer.actions.setSimpleOptionsState({ method: type, selected: false, displayName: t('authorized.project.generateAndVersions.formOptions.isolateOption')}))
    }
    if (type === 'grayscale') {
      dispatch(generateReducer.actions.setSimpleOptionsState({ method: type, selected: false, displayName: t('authorized.project.generateAndVersions.formOptions.grayscaleOption')}))
    }
    if (type === 'Grayscale Aug...') {
      dispatch(generateReducer.actions.setGrayscaleOptionState({ method: type, selected: false, percentage: 0 }))
    }
    if (type === 'blur') {
      dispatch(generateReducer.actions.setBlurOptionState({ method: type, selected: false, params: { maxBlur: 10 }, percentage: 0 }))
    }
    if (type === 'noise') {
      dispatch(generateReducer.actions.setNoiseOptionState({ method: type, selected: false, params: { maxNoisePercentage: 10 }, percentage: 0 }))
    }
    if (type === 'brightness') {
      dispatch(generateReducer.actions.setBrightnessOptionState({ method: type, selected: false, params: { brightnessPercentage: 10, mode: 'both' }, percentage: 0 }))
    }

    dispatch(generateReducer.actions.setShowButton())
    dispatch(generateReducer.actions.setShowAugmentButton())
  }

  return (
    <StyledPreprocessingOption {...props}>
      <StyledOptionEdit onClick={onDeleteClick}>{t('other.delete')}</StyledOptionEdit>
      <StyledDiv onClick={onOptionClick}>
        <FlexContainer direction="row" justify="flex-start" align="center" gap="8px">
          <span>{uppercaseFirstLetter + ':'}</span>
          {apply ? <StyledOptionInfo>{info && info}</StyledOptionInfo> : ''}
        </FlexContainer>
        <FlexContainer direction="column" justify="space-between" align="flex-start">
          {apply ? '' : <StyledOptionInfo>{info && info}</StyledOptionInfo>}
          {apply ? <StyledOptionInfo>{t('authorized.project.generateAndVersions.applyTo') + ' ' + apply + t('authorized.project.generateAndVersions.generatePercents')}</StyledOptionInfo> : ''}
        </FlexContainer>
      </StyledDiv>
    </StyledPreprocessingOption>
  )
}

export default TabOption
