import React, { useEffect } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { StyledQuotaPriceDescription } from '../../constants/styled'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { useAppDispatch } from '../../../../hooks/redux'
import SelectQuotaForm from './SelectQuotaForm'
import { IGroupQuota } from '../../../../services/QuotasService'
import { useTranslation } from 'react-i18next'
import EditQuotaForm from './EditQuotaForm'
import InputRadio from '../../../../UI/InputRadio/InputRadio'

interface IWarningPriceFormProps {
    groupQuotas: IGroupQuota[];
    quota: IGroupQuota
}

const WarningPriceForm: React.FC<IWarningPriceFormProps> = ({groupQuotas, quota}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [checked, setChecked] = React.useState(false)

  useEffect(() => {
    const notify = localStorage.getItem('notify')
    if (notify === 'true') {
      setChecked(true)
    }
  }, [])

  const onCloseClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.quotasFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setModalWidth('80vw'))
    dispatch(modalWindowSlice.actions.setActiveScrollTrue())
    dispatch(modalWindowSlice.actions.setTooltipText(t('authorized.settings.quotasFormTooltip')))
    dispatch(modalWindowSlice.actions.setChildren(<SelectQuotaForm groupQuotas={groupQuotas}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onContinueClick = () => {
    if (checked) {
      localStorage.setItem('notify', 'true')
    }
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(quota.displayName))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setChildren(<EditQuotaForm quota={quota} groupQuotas={groupQuotas}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <FlexContainer direction='column' justify='flex-start' align='center' gap='20px'>
      <FlexContainer direction='column' justify='center' align='center' paddingProps='0 20px' margin='0 0 30px 0'>
        <StyledQuotaPriceDescription>{t('authorized.settings.baseCost')}</StyledQuotaPriceDescription>
        <StyledQuotaPriceDescription>{t('authorized.settings.extendText')}</StyledQuotaPriceDescription>
      </FlexContainer>
    
        <FlexContainer direction='row' justify='center' align='center'>
          <InputRadio
            widthProps='14px'
            heightProps='14px'
            checked={checked}
            onChange={() => setChecked(!checked)}
            type="checkbox"
            label={t('other.noNotify')}  
          />
        </FlexContainer>
  
      <FlexContainer direction='row' justify='space-around' paddingProps='30px 0 0 0'>
        <ProjectButton onClick={onCloseClick} widthProps='140px'>{t('buttons.back')}</ProjectButton>
        <ProjectButton onClick={onContinueClick} widthProps='140px'>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default WarningPriceForm