import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";
import { INewImage } from "./BatchesService";

export interface IDataset {
    id: string;
    name: string;
    project: string;
    public: boolean,
    updatedAt: string;
    createdAt: string;
    performer: string;
    description: string;
}

export interface datasetAnnots {
    filename: string;
    displayname: string;
    size: number;
    sizeInMegaPixels: number;
    width: number;
    height: number;
}

export interface ISummaryDataset {
    dataset: string;
    name: string;
    description: string;
    annotator: {
      id: string;
      email: string;
    },
    moderator: {
      id: string;
      email: string;
    },
    annotatedCount: number;
    unannotatedCount: number;
    imagesCount: number;
  }

export interface IDatasetsLabel {
    id: string;
    name: string;
}

export interface IDatasetsAnnotations {
    label: string;
    image: string;
    xmin: number;
    ymin: number;
    xmax: number;
    ymax: number;
    type: string;
    name: string;
    color: string;
}

export interface ICteateDatasetRequest {
    taskId: string;
    images: string[] | any[];
    projectId: string;
    desctiption?: string;
    name?:string;
}

export interface ICreateDatasetResponce {
    dataset: {
        id: string;
        name: string;
        description: string;
        project: string;
        userId: string;
        moderatorId: string;
        annotatorId: string;
        public: boolean;
        updatedAt: string;
        createdAt: string;
        datasetInfo: {
            dataset: {
                generatedAt: string
                totalImages: number
                totalAnnotations: number
                imagesWithoutAnnotations: number
                averageAnnotationsPerImage: number
                averageClassesPerImage: number
            }
            classBalance: {
                amount: number;
                color: string;
                name: string;
                represented: string;
            }[]
            averageImageSizeMP: number;
            imageSizeRange: {
                from: number;
                to: number;
            }
            medianImageRatio: {
                width: number;
                height: number;
                type: string;
            }
        }
    }
}

export interface IPutDatasetResponce {
    dataset: IDataset;
    message: string;
}

export interface IGetDatasetByIdResponce {
    dataset: {
        id: string;
        name: string;
        description: string;
        project: string;
        userId: string;
        moderatorId: string;
        annotatorId: string;
        public: boolean,
        createdAt: string;
        updatedAt: string;
        annotator: {
          id: string;
          email: string;
        },
        moderator: {
          id: string;
          email: string;
        },
        user: {
          id: string;
          email: string;
        },
        approvedTotal: number;
        rejectedTotal: number;
        reviewTotal: number;
        automarkupTotal: number;
        annotatedTotal: number;
        unannotatedTotal: number;
      },

    imagesTotal: number;
    total: number;
    remain: number;
    imagesOnPage: number;
    images: INewImage[]
}

export interface IDeleteDatasetResponce {
    message: string;
}

export interface IGetDatasetsSummaryResponce {
    datasets: ISummaryDataset[],
    total: number;
}

export interface IClassBalance {
    amount: number;
    color: string;
    name: string;
    represented: string;
}

export interface IGetHealthStatsResponce {
    totalImages: number;
    totalAnnotations: number;
    imagesWithoutAnnotations: number;
    averageAnnotationsPerImage: number;
    averageClassesPerImage: number;
    averageImageSizeMP: number;
    generatedAt: string;
    classBalance: IClassBalance[];
    imageSizeRange: {
        from: number;
        to: number;
    };
    medianImageRatio: {
        width: number;
        height: number;
        type: string;
    };
}

export interface IGenerateHealthStatReq {
    service: string;
    project: string;
}

export interface IGenerateHealthStatResponce {
    job: string;
    service: string;
    message: string;
}

export interface IGetHealthStatProgressResponce {
    message?: string | number;
    progress?: number;
}

export interface IDeleteHealthStatJobResponce {
    message: string;
}

export interface IAllDataset {
    dataset: string;
    total: number;
    imagesCount: number;
    remain: number;
    unannotated: datasetAnnots[];
    annotated: datasetAnnots[];
}

export interface IGetDatasetsAllRes {
    imagesTotal: number;
    total: number;
    remain: number;
    imagesOnPage: number;
    images: INewImage[]
}

export interface IGetDatasetLabelsRes {
    labels: string[];
}

//new


export default class DatasetsService {
    static async createDataset(payload: ICteateDatasetRequest): Promise<AxiosResponse<ICreateDatasetResponce>> {
        return $authHost.post<ICreateDatasetResponce>('/datasets', payload)
    }

    static async putDataset(id: string, name: string, description: string): Promise<AxiosResponse<IPutDatasetResponce>> {
        return $authHost.put<IPutDatasetResponce>('/datasets', {id: id, name: name, description: description})
    }

    static async getDatasetById(id: string, limit?: number, offset?: number, status?: string, subStatus?: string, search?: string): Promise<AxiosResponse<IGetDatasetByIdResponce>> {
        return $authHost.get<IGetDatasetByIdResponce>(`/datasets/${id}?limit=${limit}&offset=${offset}&status=${status}&subStatus=${subStatus}&search=${search}`)
    }

    static async deleteDataset(id: string): Promise<AxiosResponse<IDeleteDatasetResponce>> {
        return $authHost.delete<IDeleteDatasetResponce>(`/datasets/${id}`)
    }

    static async getDatasetsSummary(projectId: string): Promise<AxiosResponse<IGetDatasetsSummaryResponce>> {
        return $authHost.get<IGetDatasetsSummaryResponce>(`/datasets/summary/${projectId}`)
    }

    static async getDatasetsAll(projectId: string, limit?: number, offset?: number, status?: string, subStatus?: string, search?: string): Promise<AxiosResponse<IGetDatasetsAllRes>> {
        return $authHost.get<IGetDatasetsAllRes>(`/datasets/all/${projectId}?limit=${limit}&offset=${offset}&status=${status}&subStatus=${subStatus}&search=${search}`)
    }

    static async getHealthStats(projectId: string): Promise<AxiosResponse<IGetHealthStatsResponce>> {
        return $authHost.get<IGetHealthStatsResponce>(`/datasets/health/${projectId}`)
    }

    static async generateHealthStat(payload: IGenerateHealthStatReq): Promise<AxiosResponse<IGenerateHealthStatResponce>> {
        return $authHost.post<IGenerateHealthStatResponce>('/datasets/regenerate', payload)
    }

    static async getHealthStatProgress(id: string): Promise<AxiosResponse<IGetHealthStatProgressResponce>> {
        return $authHost.get<IGetHealthStatProgressResponce>(`/datasets/regenerate/${id}`)
    }

    static async deleteHealthStatJob(id: string): Promise<AxiosResponse<IDeleteHealthStatJobResponce>> {
        return $authHost.delete<IDeleteHealthStatJobResponce>(`/datasets/regenerate/${id}`)
    }

    static async getDatasetLabels(projectId: string): Promise<AxiosResponse<IGetDatasetLabelsRes>> {
        return $authHost.get<IGetDatasetLabelsRes>(`/datasets/labels/${projectId}`)
    }
}