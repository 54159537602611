import styled from "styled-components"
import { AuthErrorProps } from "../../../models/IAuthErrorProps"
import { Colors } from "../../../models/Colors"

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: 1px solid ${Colors.gray};
    border-radius: 15px;
    padding: 30px 35px 35px 35px;
    background-color: ${Colors.grayInvisible};
    width: 400px;
    @media (max-width: 680px) {
        width: 350px;
        padding: 20px 25px 20px 25px;
        gap:10px;
    }
`

export const StyledFormHeader = styled.h2`
    margin-bottom: 35px;
`

export const StyledSupTitle = styled.p`
    text-align: center;
    color : ${Colors.texttable};
    font-size: 14px;
`

export const StyledLink = styled.span`
    cursor: pointer;
    :hover{
        color: ${Colors.orange};
    }
`

export const StyledErrorWrapepr = styled.div<AuthErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    border: 1px solid ${Colors.darkred};
    border-radius: 15px;
    padding: 15px;
    max-width: 400px;
    min-width: 400px;
    min-height: 80px;
    overflow-wrap: break-word;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 680px) {
        max-width: 350px;
        min-width: 350px;
    }
`

export const StyledErrorText = styled.p`
    color: ${Colors.red};
    font-size: 14px;
    font-weight: bold;
`
 