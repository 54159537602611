import styled from "styled-components";
import { Colors } from "../../../models/Colors";
import commentsIcon from '../../../assets/icons/drawIcons/comments-svgrepo-com.svg'
import userIcon from '../../../assets/icons/drawIcons/user-square.svg'
import editIcon from '../../../assets/icons/edit-svgrepo-com.svg'
import commentIcon from '../../../assets/icons/message.svg'
import likeIcon from '../../../assets/icons/like.svg'
import likeHeart from '../../../assets/icons/like_heart.svg'


interface ITabProps {
    active: boolean;
}

export const StyledEditIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${editIcon});
  mask-image: url(${editIcon});
  background-color: ${Colors.orange};
  width: 15px;
  height: 15px;
`

export const StyledLikeIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${likeIcon});
  mask-image: url(${likeIcon});
  background-color: ${Colors.WHITE};
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    background-color: ${Colors.lightorange}
  }
`

export const StyledLikeHeartIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${likeHeart});
  mask-image: url(${likeHeart});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    color: ${Colors.lightorange}
  }
`

export const StyledCommentIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${commentIcon});
  mask-image: url(${commentIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    color: ${Colors.lightorange}
  }
`

export const StyledCommentsIcon = styled.i`
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(${commentsIcon});
    mask-image: url(${commentsIcon});
    background-color: ${Colors.orange};
    min-width: 40px;
    min-height: 40px;
`

export const StyledUserIcon = styled.i`
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(${userIcon});
    mask-image: url(${userIcon});
    background-color: ${Colors.semilightblue};
    min-width: 30px;
    min-height: 30px;
`

export const StyledTopicUserIcon = styled.i`
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(${userIcon});
    mask-image: url(${userIcon});
    background-color: ${Colors.semilightblue};
    min-width: 80px;
    min-height: 80px;
`

export const StyledForumWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  height: auto;
  min-height: 90vh;
  width: 100%;
  padding: 0 25px 25px 25px;
  margin-top: 90px;
  background-color: ${Colors.dark};
`
export const StyledBr = styled.span`
    width: 100%;
    height: 2px;
    background-color: ${Colors.gray};
`
//Categories
export const StyledForumContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 900px;
    max-width: 1000px;
    width: 100%;
`
export const StyledContentTabs = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    padding: 0 15px;
`

//Forums
export const StyledForumTab = styled.div<ITabProps>`
    width: auto;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    padding: 10px;
    color: ${({active}) => active? Colors.orange : Colors.texttable};
    border-right: 1px solid ${({active}) => active? Colors.orange : Colors.gray};
    border-left: 1px solid ${({active}) => active? Colors.orange : Colors.gray};

    cursor: pointer;
    transition: all .1s linear;
    :hover {
        color: ${({active}) => active? Colors.orange : Colors.WHITE};
        background: ${Colors.black};
        border-color: ${Colors.lightorange};
    }
`

export const StyledForumsWrapper = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
`

export const StyledCategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 8px;
    gap: 5px;
    width: 100%;
`

export const StyledCategoryHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 5px;
    font-size: 14px;
    background: ${Colors.black};
    color: ${Colors.texttable};
    font-weight: bold;
`
//sub
export const StyledSubCategoryHeader = styled.h4`
    transition: all .1s linear;
    font-size: 16px;
    font-weight: bold;
    min-width: 350px;
`

export const StyledSubCategory = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 0 8px;
    width: 100%;
    min-height: 70px;
    cursor: pointer;
    transition: all .1s linear;
    border: 1px dashed ${Colors.black};
    :hover ${StyledSubCategoryHeader}{
        color: ${Colors.orange};
    }
    :hover {
        background-color: ${Colors.darkerBlack};
    }
`
export const StyledSubCategoryTopics = styled.h5`
    transition: all .1s linear;
    font-size: 14px;
    font-weight: bold;
`

export const StyledSubCategoryDescription = styled.p`
    color: ${Colors.textgray};
    font-size: 14px;
`

export const StyledSubCategoryLastHeader = styled.h6`
    font-size: 13px;   
    height: 18px; 
    cursor: pointer;
    transition: color .1s linear;
    max-width: 120px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
        text-decoration: underline;
        color: ${Colors.lightgreen};
    }
`
export const StyledyLastTopicHeader = styled.h6`
    font-size: 13px;   
    height: 18px; 
    cursor: pointer;
    transition: color .1s linear;
    width: 90%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
        text-decoration: underline;
        color: ${Colors.lightgreen};
    }
`


export const StyledLastSubCategoryPostWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    min-width: 180px;
    font-size: 16px;
    padding: 5px 5px 5px 13px;

    transition: all .1s linear;

    :hover {
        background: ${Colors.blackDarker};
    }
    :hover ${StyledSubCategoryLastHeader} {
        color: ${Colors.lightgreen};
        text-decoration: underline;
    }
`

export const StyledSubCategoryUserSpan = styled.div`
    font-size: 13px;    
    height: 18px;
`

export const StyledSubCategoryUserLink = styled.div`
    margin-left: 5px;
    height: 18px;
    font-size: 13px;
    text-decoration: none;

    width: 65%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${Colors.lightgreen};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
    :visited {
        color: ${Colors.lightgreen};
    }

`


export const StyledSubCategoryDate = styled.div`
    font-size: 13px;   
    height: 18px; 
`

export const StyledDate = styled.span`
    font-size: 13px;   
    height: 18px; 
    color: ${Colors.textgrayInput};
`


//InfoBlock
export const StyledInfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    width: 25%;
    padding: 30px 0 0 0;
`

export const StyledInfoCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    min-height: 100px;
    padding: 5px 10px;
    
    border: 1px solid ${Colors.gray};
    border-radius: 15px;
`

export const StyledInfoCardHeader = styled.h4`
    padding: 10px 8px;
    color: ${Colors.textgray};
`

export const StyledInfoCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    width: 100%;
    padding: 10px 5px;
    color: ${Colors.textgray};
    font-size: 14px;
`

export const StyledLink = styled.span`
    margin-left: 5px;
    height: 18px;
    font-size: 13px;
    text-decoration: none;
    color: ${Colors.lightgreen};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
    :visited {
        color: ${Colors.lightgreen};
    }
`

export const StyledLastInfoPostContent = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  max-width: 90%;
  height: 16px;
  padding: 4px 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #FFFFFF;
`

export const StyledInfoPost = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    min-width: 180px;
    width: 100%;
    padding: 4px 10px;
    font-size: 16px;


    cursor: pointer;
    transition: all .1s linear;

    :hover {
        background-color: ${Colors.darkerBlack};
    }
    :hover ${StyledSubCategoryLastHeader} {
        color: ${Colors.lightgreen};
        text-decoration: underline;
    }
`

export const StyledDashedBr = styled.span`
    width: 100%;
    height: 2px;
    margin: 8px 0 5px 0;
    border: 1px dashed ${Colors.gray};
`
export const StyledEdit = styled.div`
display: flex;
flex-direction: row;
align-items: center;
cursor: pointer;
margin-left: 10px;
&:hover {
  color: ${Colors.orangeSaturated};
}
`
export const StyledTextButton = styled.span`
  font-size: 12px;
  color: ${Colors.WHITE};
  transition: all .1s linear;
  &:hover {
  color: ${Colors.orangeSaturated};
}
`

//topics
interface IRouteProps {
    isLink: boolean;
    active?: boolean;
}


export const StyledTopicsHeader = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;

    width: 100%;
    height: auto;
`

export const StyledTopicsHeaderRoutes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    font-size: 14px;
`

export const StyledHeaderRoute = styled.div<IRouteProps>`
    color: ${({active}) => active? Colors.textgray : Colors.lightgray};
    cursor: ${({isLink}) => isLink ? 'pointer' : 'auto'};;
    transition: all .1s linear;
    :hover {
        color: ${({isLink}) => isLink ? Colors.orange : Colors.textgray};
    }
`

export const StyledTopicsHeaderTitle =  styled.h2`
    margin-top: 15px;
    color: ${Colors.textgray};
`

export const StyledTopicsHeaderSubTitle = styled.p`
    font-size: 15px;
    color: ${Colors.popupTextgray};
`

export const StyledTopicsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
`

export const StyledSelfEndDiv = styled.div`
    align-self: flex-end;
`

export const StyledTopicDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #494949;
    width: 100%;
`
export const StyledTopicUser  = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    

    max-width: 150px;
    min-width: 150px;
    width: 100%;
    height: min-content;
    padding: 15px;
    margin: 15px 15px;

    border: 1px solid ${Colors.gray};
    border-radius: 5px;
`
export const StyledTopicUserName = styled.h4`
    margin: 10px 0;
    max-width: 120px;

    color: ${Colors.green};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    
    cursor: pointer;
    transition: all .1s linear;
    :hover {
        color: ${Colors.lightgreen};
    }
`
export const StyledTopicUserEmail = styled.h6`
    margin: 10px 0;
    max-width: 120px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledTopicEditorWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 15px;
    margin-top: 25px;
`

export const StyledTopicMessageToolbar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    gap: 15px;

    border-top: 1px solid ${Colors.gray};
`

export const StyledTopicAnswer = styled.span`
    transition: all .1s linear;
    cursor: pointer;
    :hover {
        color: ${Colors.orange};
    }
`


//topic component
export const StyledTopicHeader = styled.h6`
    font-size: 13px;   
    height: auto; 
    cursor: pointer;
    transition: color .1s linear;
    width: 320px;

    /* white-space: wrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */

    :hover {
        text-decoration: underline;
        color: ${Colors.lightgreen};
    }
`

export const StyledTopicWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    width: 100%;
    font-size: 16px;
    padding: 5px 5px 5px 13px;
    border: 1px dashed ${Colors.darkerBlack};
    transition: all .1s linear;

    cursor: pointer;

    :hover {
        background: ${Colors.black};
    }
    :hover ${StyledTopicHeader} {
        color: ${Colors.lightgreen};
        text-decoration: underline;
    }
`
export const StyledTopicStats =  styled.span`
    font-size: 13px;
    color: ${Colors.textgray};
`

export const StyledTopicNavStat =  styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.textgray};
`


//create topic

export const StyledCreateTopicWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    padding: 20px 10px;
    background-color: ${Colors.darker};



`

export const StyledNoMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 180px;
    min-height: 64px;
    font-size: 15px;
`

export const StyledTopicMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #494949;
    width: 100%;

    position: relative;
`

export const StyledMessageEdit = styled.div`
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 100;
`

//StyledTopicMessageField

export const StyledTopicMessageButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;

    border-bottom: 1px solid #494949;
    border-bottom: 1px solid #494949;
    border-bottom: 1px solid #494949;
`
