import React, { FC } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { IGetCategoryByIdResponce, IGetTopicByIdResponce } from '../../../../../services/ForumService'
import { StyledBr, StyledHeaderRoute, StyledTopicsHeader, StyledTopicsHeaderRoutes, StyledTopicsHeaderTitle } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import TopicDropdown from './TopicDropdown'
import { IUsersMeResponse } from '../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ITopicsHeaderProps {
  user: IUsersMeResponse
  navigate: NavigateFunction
  currentTopic: IGetTopicByIdResponce
  currentCategory: IGetCategoryByIdResponce
  setIsEditClicked: React.Dispatch<React.SetStateAction<boolean>>
  currentSubCategory: IGetCategoryByIdResponce
}

const TopicPageHeader: FC<ITopicsHeaderProps> = ({ user, navigate, currentCategory, currentSubCategory, setIsEditClicked, currentTopic, ...props }) => {
  const { t } = useTranslation()
  return (
    <StyledTopicsHeader>
      <StyledTopicsHeaderRoutes>
        <StyledHeaderRoute isLink={true} onClick={() => navigate('/forum')}>
         {t('forum.forum')}
        </StyledHeaderRoute>
        <span>{'/'}</span>
        <StyledHeaderRoute isLink={true} onClick={() => navigate(`/forum/${currentCategory.category.id}/${currentSubCategory.category.id}/topics`)}>
          {currentSubCategory?.category?.name ? currentSubCategory?.category?.name.charAt(0).toUpperCase() + currentSubCategory?.category?.name.slice(1) : 'SubCategory'}
        </StyledHeaderRoute>
        <span>{'/'}</span>
        <StyledHeaderRoute isLink={false} active>
          {currentTopic?.topic?.title}
        </StyledHeaderRoute>
      </StyledTopicsHeaderRoutes>
      <StyledBr />

      <FlexContainer direction="row" justify="space-between" align="center" paddingProps="10px">
        <StyledTopicsHeaderTitle>
          {currentTopic?.topic?.title ? currentTopic?.topic?.title.charAt(0).toUpperCase() + currentTopic?.topic?.title.slice(1) : 'SubCategory'}
        </StyledTopicsHeaderTitle>
        {user?.id === currentTopic?.topic?.author?.id || user?.role?.name === 'global_admin' || user?.role?.name === 'developer' ? (
          <div>
            {(user && user?.id === currentTopic?.topic?.author?.id) || (user && (user?.role?.name === 'global_admin' || user?.role?.name === 'developer')) ? (
              <TopicDropdown
                currentSubCategory={currentSubCategory}
                currentCategory={currentCategory}
                navigate={navigate}
                currentTopic={currentTopic}
                user={user}
                setIsEditClicked={setIsEditClicked}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </FlexContainer>
      <StyledBr />
    </StyledTopicsHeader>
  )
}

export default TopicPageHeader
