import React, { FC, useEffect, useState } from 'react'
import { StyledHeader, StyledInputSearchIcon, StyledLink, StyledManagement, StyledManagementContent, StyledParagraph, StyledUserPlusIcon } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { StyledUsersGroupIcon } from '../../ProjectsTabsNav'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import InviteGroupUserForm from './InviteUserForm/InviteUserForm'
import { ProjectBr, StyledALink } from '../../../../../styles/GlobalStyles'
import Input from '../../../../../UI/Input/Input'
import UsersTable from './UsersTable/UsersTable'
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import { NavigateFunction } from 'react-router-dom'
import { IProject } from '../../../../../services/ProjectService'
import { IProjectUserFullInfo } from '../../../../Projects/store/reducers/WorkspacesReducer'
import { ISummaryRole } from '../../../../../services/AccessService'
import { UseProjectUsersQuery } from '../../../../../hooks/UseProjectUsersQuery'
import { useTranslation } from 'react-i18next'
import { StyledManagementContentWrapper } from '../../../../Projects/constants/Styled'

export interface IProjectManagementProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: string;

  projectUsersInfo: IProjectUserFullInfo[]
  projectRoles: ISummaryRole[]
  roleNumber: number;
}

const ProjectManagement: FC<IProjectManagementProps> = ({navigate, projectInfo, param, projectRoles, roleNumber, projectUsersInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(state => state.WorkspacesReducer.isRoleLoading)

  const [users, setUsers] = useState<IProjectUserFullInfo[]>([])
  const [usersFilter, setUsersfilter] = useState({query: ''})
  const queryUsers = UseProjectUsersQuery(users, usersFilter.query)
  
  useEffect(() => {
    if (param === 'manage') {
      dispatch(tabsSlice.actions.setActiveTab('manage'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (projectUsersInfo?.length && !isLoading) {
      setUsers(projectUsersInfo)
    }
  }, [projectUsersInfo, isLoading])

  const onInviteClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setModalWidth('460px'))
    // dispatch(modalWindowSlice.actions.setModalHeight('600px'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.projectManagement.formTitle')))
    dispatch(modalWindowSlice.actions.setChildren(<InviteGroupUserForm roleNumber={roleNumber}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }
  
  return (
    <StyledManagement>
      <StyledHeader>
        <FlexContainer direction='row' justify='flex-start' align='center' gap="10px">
          <StyledUsersGroupIcon/>
          <h3>{t('authorized.projects.groups.groupManagement.management')}</h3>
        </FlexContainer>
        {roleNumber <= 4 ? 
          <ProjectButton heightProps='30px' widthProps='180px' onClick={onInviteClick}>
            <StyledUserPlusIcon/> 
            {t('authorized.projects.groups.groupManagement.formTitle')}
          </ProjectButton>
        :''}
      </StyledHeader>
      <StyledManagementContentWrapper id='test'>
        <ProjectBr/>
        <StyledParagraph >
          {t('authorized.project.projectManagement.paragraph1')} (<StyledLink onClick={() => navigate('./projects?mode=management')}>{t('authorized.project.projectManagement.pLink')}</StyledLink>). <br/> 
          {t('authorized.project.projectManagement.paragraph2')}
        </StyledParagraph>
        <StyledParagraph>
          <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.seeGuide')}</StyledALink>
        </StyledParagraph>
        <ProjectBr/>
        <StyledManagementContent>
          <Input value={usersFilter.query} onChange={(e) => setUsersfilter({...usersFilter, query: e.target.value})} type="text" withIcon={<StyledInputSearchIcon/>} label={t('authorized.projects.groups.groupManagement.inputTitle')} placeholder='user.email@mail.ru'/>
          <h4>{t('authorized.projects.groups.groupManagement.usersCount')} {projectUsersInfo?.length ? projectUsersInfo.length : 0}</h4>
          <UsersTable 
            projectInfo={projectInfo} 
            projectRoles={projectRoles} 
            roleNumber={roleNumber}
            projectUsersInfo={queryUsers}
          />
        </StyledManagementContent>
      </StyledManagementContentWrapper>
    </StyledManagement>
  )
}

export default ProjectManagement