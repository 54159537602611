import React, { useEffect, useState } from 'react'
import { ITransactionsBlockType, ITransactionsProps } from '../../constants/interfaces'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ContentSection from '../SettingsContent/ContentSection'
import { ProjectBr, StyledProjectPageNumber } from '../../../../styles/GlobalStyles'
import Loader from '../../../../UI/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { SettingsReducer } from '../../reducers/SettingsReducer'
import ProjectTable from '../../../../UI/ProjectTable/ProjectTable'
import FiatOperation from './FiatOperation'
import CoinsOperation from './CoinsOperation'
import { TransactionsTabs } from './TransactionsTabs'
import { asyncGetCoinsTransactions, asyncGetFiatTransactions } from '../../../../store/reducers/AcyncActions'
import { getPagesCount } from '../../../../utils/getPagesCount'
import { useTranslation } from 'react-i18next'

const Transactions: React.FC<ITransactionsProps> = ({user, group}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [activeTab, setActiveTab] = useState('fiat')
  const { fiatOperations, coinsOperations, isLoading, error} = useAppSelector(state => state.PaymentsReducer)
  const currentTab = useAppSelector(state => state.SettingsReducer.activeTab)
  const [coinsPages, setCoinsPages] = useState<number[]>([])
  const [fiatPages, setFiatPages] = useState<number[]>([])
  const [currentPage, setCurrentPage] = useState({
    fiat: 1,
    coins: 1,
    limit: 10
  })

  useEffect(() => {
    if (currentPage.coins === 1) {
      dispatch(asyncGetCoinsTransactions(0, currentPage.limit))
    }
    if (currentPage.coins) {
      dispatch(asyncGetCoinsTransactions(currentPage.coins, currentPage.limit))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.coins])

  useEffect(() => {
    if (currentPage.fiat === 1) {
      dispatch(asyncGetFiatTransactions(0, currentPage.limit))
    }
    if (currentPage.fiat) {
      dispatch(asyncGetFiatTransactions(currentPage.fiat, currentPage.limit))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage.fiat])

  useEffect(() => {
    if (currentTab !== 'Transactions') {
      dispatch(SettingsReducer.actions.setActiveTab('Transactions'))
    }
    getPagesCount(fiatOperations.total, currentPage.limit, setFiatPages)
    getPagesCount(coinsOperations.total, currentPage.limit, setCoinsPages)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fiatOperations, coinsOperations])

  const getRenderСondition = (block: ITransactionsBlockType) => {
    if (block === "fiat") {
      return activeTab === 'fiat' && !isLoading && !error
    }
    if (block === "coins") {
      return activeTab === 'coins' && !isLoading && !error
    }
    if (block === "error") {
      return !isLoading && error 
    }
    if (block === "loading") {
      return isLoading && !error
    }
  }

  const onPageClick = (page: number, type: 'fiat' | 'coins') => {
    if (type === 'fiat') {
      setCurrentPage({...currentPage, fiat: page})
    }
    if (type === 'coins') {
      setCurrentPage({...currentPage, coins: page})
    }
  }

  return (
    <>
      <FlexContainer direction='row' justify='space-between' align='center'>
        <h2>{t('authorized.settings.transactionsTitle')}</h2>
      </FlexContainer>  
      <ContentSection subtitle={t('authorized.settings.transactionsP')}>
        <ProjectBr/>
        <TransactionsTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
        {getRenderСondition('fiat') && 
          <ProjectTable headerItems={[t('authorized.settings.operationId'), t('authorized.settings.operationDate'), t('authorized.settings.operationType'), t('authorized.settings.operationCost'), t('authorized.settings.operationStatus')]} pages={fiatPages}>
            {fiatOperations?.transaction?.map((operation: any) => <FiatOperation operation={operation}/>)}
          </ProjectTable>
        }
        {getRenderСondition('coins') && 
          <ProjectTable headerItems={[t('authorized.settings.operationId'), t('authorized.settings.operationDate'), t('authorized.settings.operationType'), t('authorized.settings.operationCost')]} pages={coinsPages}>
            {coinsOperations?.internalTransaction?.map((operation: any) => <CoinsOperation operation={operation}/>)}
          </ProjectTable>
        }
        {getRenderСondition('error') && 
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
            {error}
          </FlexContainer>
        }
        {getRenderСondition('loading') && 
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
            <Loader/>
          </FlexContainer>
        }

        {getRenderСondition('fiat') && 
          <FlexContainer direction='row' justify='center' align='center' widthProps='100%' gap='5px'>
            {fiatPages?.map(page => 
              <StyledProjectPageNumber active={currentPage.fiat === page} key={page} onClick={() => onPageClick(page, 'fiat')}>{page}</StyledProjectPageNumber> 
            )}
          </FlexContainer>
        }
        {getRenderСondition('coins') && 
          <FlexContainer direction='row' justify='center' align='center' widthProps='100%' gap='5px'>
            {coinsPages?.map(page => 
              <StyledProjectPageNumber active={currentPage.coins === page} key={page} onClick={() => onPageClick(page, 'coins')}>{page}</StyledProjectPageNumber> 
            )}
          </FlexContainer>
        }

      </ContentSection> 
    </>
  )
}

export default Transactions