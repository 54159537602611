import React, { FC, useState } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { asyncCreateCategory } from '../../../../store/reducers/AcyncActions'
import Input from '../../../../UI/Input/Input'
import { IGetCategoryByIdResponce } from '../../../../services/ForumService'
import { useTranslation } from 'react-i18next'

interface ICreateCategoryFormProps {
  categoryInfo?: IGetCategoryByIdResponce;
  isSub?: boolean;
}

const CreateCategoryForm: FC<ICreateCategoryFormProps> = ({categoryInfo, isSub}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [titleValue, setTitleValue] = useState('')
  const [descriptionValue, setDescriptionValue] = useState('')
  console.log(isSub)
  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
  }

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(e.target.value)
  }

  const onCreateClick = () => {
    if (titleValue && isSub) {
      dispatch(asyncCreateCategory('10', titleValue, descriptionValue))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    if (titleValue && !isSub) {
      dispatch(asyncCreateCategory('10', titleValue, descriptionValue, categoryInfo?.category.id))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
  }

  return (
    <FlexContainer direction='column' justify='center' align="center" gap='20px'>
      <Input label={t('other.name')} value={titleValue} onChange={onTitleChange} placeholder={t('forum.enterText')}/>
      <Input label={t('authorized.project.assign.description')} value={descriptionValue} onChange={onDescriptionChange} placeholder={t('forum.enterText')}/>
      <FlexContainer direction='row' justify='center' align='center' gap='50px'>
        <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onCreateClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default CreateCategoryForm