import React from 'react'
import { IPackageProps } from '../../constants/interfaces'
import { StyledPackageHeaderWrapper, StyledPackageQuotaTooltipWrapper, StyledPackageTitle, StyledPackageTitleWrapper, StyledPackageWrapper, StyledQuotaCardImage } from '../../constants/styled'
import PackageQuota from './PackageQuota'
import packageIcon from '../../../../assets/icons/package-plus.svg'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import Tooltip from '../../../../UI/Tooltip/Tooltip'
import { useAppDispatch } from '../../../../hooks/redux'
import ExtendPackageForm from './ExtendPackageForm'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { useTranslation } from 'react-i18next'

const Package: React.FC<IPackageProps> = ({groupPackage, quotas, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onPackageClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.packagePurchase')))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setChildren(<ExtendPackageForm groupPackage={groupPackage}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledPackageWrapper onClick={onPackageClick}>
      <StyledPackageHeaderWrapper>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='5px'>
          <StyledQuotaCardImage srcProps={packageIcon}/>
          <StyledPackageTitle>{groupPackage?.name}</StyledPackageTitle>
          <StyledPackageQuotaTooltipWrapper>
            <Tooltip>{t('authorized.settings.packageTooltip')}</Tooltip>
          </StyledPackageQuotaTooltipWrapper>
        </FlexContainer>
        <h2>{groupPackage.cost} RV</h2>
      </StyledPackageHeaderWrapper>
      {quotas?.map(quota => 
        <PackageQuota 
          key={+performance.now() + quota.id} 
          quota={quota}
          withTooptip={true}
        />
      )}
    </StyledPackageWrapper>
  )
}

export default Package