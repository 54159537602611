import React, { FC, useLayoutEffect, useState } from 'react'
import { StyledCanvas, StyledCanvasBrightnessWrapper, StyledCanvasOptionImage } from '../../../../constants/styled'
import owlImage from '../../../../../../assets/images/owl.jpg'

interface ICanvasImageProps {
  type: string
  select: string
  resizeWidth?: number
  resizeHeight?: number
  image: any
  rows: number
  columns: number
  noice: number
  blur: number
  imagesToAugmentCount: number
  brightnessValue: number
  brightnessSelect: string
}

const CanvasDarken: FC<ICanvasImageProps> = ({
  type,
  noice,
  brightnessValue,
  brightnessSelect,
  blur,
  imagesToAugmentCount,
  rows,
  columns,
  resizeHeight,
  resizeWidth,
  select,
  image,
  ...props
}) => {
  const [percents, setPercents] = useState(-brightnessValue * 10 + 10 + '%')
  useLayoutEffect(() => {
    const owlImage = document.getElementById('owlImageDarken') as HTMLImageElement
    const canvas = document.getElementById('canvasDarken') as HTMLCanvasElement
    const context = canvas.getContext('2d')
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height)

      context.translate(90, 90)
      if (type === 'd' && brightnessSelect === 'both') {
        context.drawImage(owlImage, -90, -90, 90, 90)
        canvas.style.filter = `brightness(${1.2 - brightnessValue / 10})`
        setPercents(-brightnessValue * 10 + 10 + '%')
      }
      if (type === 'd' && brightnessSelect === 'darken') {
        context.drawImage(owlImage, -90, -90, 90, 90)
        canvas.style.filter = `brightness(${1.2 - brightnessValue / 10})`
        setPercents(-brightnessValue * 10 + 10 + '%')
      }
      if (type === 'd' && brightnessSelect === 'lighten') {
        context.drawImage(owlImage, -90, -90, 90, 90)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, rows, columns, resizeWidth, resizeHeight, select, image, noice, blur, brightnessValue, brightnessSelect])

  return (
    <StyledCanvasBrightnessWrapper>
      <StyledCanvasOptionImage src={owlImage} id="owlImageDarken" />
      <StyledCanvas id="canvasDarken" width={90} height={90} />
      {percents}
    </StyledCanvasBrightnessWrapper>
  )
}

export default CanvasDarken
