import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactChild, ReactNode } from 'react';

interface IReducerState {
    title: string;
    children: ReactNode | ReactChild;
    visible: boolean;
    reassignVisible: boolean;

    isAssignEmpty: boolean;
    images: any[];
    total: number;

    unassingedColumn: any;
    annotatingColumn: any;
    datasetColumn: any;

    unassingedTasks: any;
    annotatingTasks: any;
    datasetTasks: any;

    unassingedBatches: any;
    annotatingBatches: any;
    datasetBatches: any;
}

const initialState: IReducerState = {
    title: '',
    children: '',
    visible: false,
    reassignVisible: false,
    
    isAssignEmpty: false,
    images: [],
    total: 0,

    unassingedColumn: '',
    annotatingColumn: '',
    datasetColumn: '',

    unassingedTasks: '',
    annotatingTasks: '',
    datasetTasks: '',

    unassingedBatches: '',
    annotatingBatches: '',
    datasetBatches: '',
}

export const datasetsNavReducer = createSlice(
{
    name: 'datasetsNavReducer',
    initialState,

    reducers: {
        setInitialState(state) {
          state.title = initialState.title
          state.visible = initialState.visible
          state.visible = initialState.reassignVisible
          state.images = initialState.images
          state.total = initialState.total
          state.isAssignEmpty = initialState.isAssignEmpty
          state.annotatingBatches = initialState.annotatingBatches
          state.annotatingColumn = initialState.annotatingColumn
          state.annotatingTasks = initialState.annotatingTasks
          state.children = initialState.children
          state.datasetBatches = initialState.datasetBatches
          state.datasetColumn = initialState.datasetColumn
          state.datasetTasks = initialState.datasetTasks
          state.unassingedBatches = initialState.unassingedBatches
          state.annotatingBatches = initialState.annotatingBatches
          state.datasetBatches = initialState.datasetBatches
        },
        setChildren(state, action: PayloadAction<ReactNode | ReactChild>) {
          state.children = action.payload
        },
        setVisible(state) {
          state.visible = true
        },
        setVisibleFalse(state) {
          state.visible = false
        },
        setReassignVisible(state) {
          state.reassignVisible = !state.reassignVisible
        },
        setReassignVisibleFalse(state) {
          state.reassignVisible = false
        },
        setTitle(state, action: PayloadAction<string>) {
          state.title = action.payload
        },
        setInititialState(state) {
          state.visible = false;
          state.children = '';
          state.title = '';
        },
        setIsEmptyFalse(state) {
            state.isAssignEmpty = false;
        },
        setIsEmptyTrue(state) {
            state.isAssignEmpty = true;
        },
        setAssignImages(state, action: PayloadAction<any>) {
            state.images.push(action.payload)
        },
      }
},

)

export default datasetsNavReducer.reducer;