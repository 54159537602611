import React, { FC } from 'react'
import styled from 'styled-components';
import { Colors } from '../../../../../../../models/Colors';
import DatasetJobDropDown from './DatasetJobDropDown';
import { NavigateFunction } from 'react-router-dom';
import FlexContainer from './../../../../../../../components/FlexContainer/FlexContainer';
import { ISummaryDataset } from '../../../../../../../services/DatasetsService';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { imagesListSlice } from '../../../../../../ImagesList/store/ImagesListReducer';
import { IProject } from '../../../../../../../services/ProjectService';

interface IDatasetJobProps {
  projectInfo: IProject;
  navigate: NavigateFunction;
  datasetInfo: ISummaryDataset;
}
interface IStyledWrapperProps {
    active?: boolean;
}

const StyledBatch = styled.div`
  width: 100%;
  height: 140px;
  padding: 10px 35px 10px 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;

  position: relative;

  transition: all 0.1s linear;     

  cursor: pointer;
  &:hover {
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
const StyledDate = styled.h6`
  color: ${Colors.textgray};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledCounter = styled.p`
  color: ${Colors.textgray};
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 20px;
`
const StyledLabeller = styled.h6`
  color: ${Colors.textgray};
  height: 20px;
`

const StyledDropdown = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.1s linear; 
  cursor: pointer;
  :hover img {
    box-shadow: 0 4px 30px rgba(0,0,0,0.25), 0 10px 30px rgba(0,0,0,0.22);
  }
  z-index: 1;
`
const StyledNameFirstLetter = styled.div<IStyledWrapperProps>`
  margin-top: 5px;
  width: 20px;
  height: 20px;
  background-color: ${({ active }) => active ? Colors.dark :  Colors.darker};
  border: 1px solid ${({ active }) => active ? Colors.orange : Colors.lightgray};
  border-radius: 50%;
  padding: 5px;

  font-size: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.1s linear;
  cursor: pointer;
`
export const StyledBr = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${Colors.gray};
`
const StyledTeammateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledName = styled.div`
    height: 20px;
    font-size: 12px;
`

const DatasetJob: FC<IDatasetJobProps> = ({projectInfo, navigate, datasetInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.userReduser.user)

  const onJobClick = () => {
    if (projectInfo?.id && datasetInfo?.dataset) {
      dispatch(imagesListSlice.actions.setInitialState())
      navigate(`/projects/${projectInfo.id}/dataset/dataset/${datasetInfo.dataset}`)
    }
  }

  return (
    <StyledBatch onClick={onJobClick}>
      <StyledDropdown>
        <DatasetJobDropDown projectInfo={projectInfo} datasetInfo={datasetInfo}/>
      </StyledDropdown>
      <StyledDate>{datasetInfo.name}</StyledDate>
      <StyledCounter>{t('authorized.project.annotate.images', { count: datasetInfo.imagesCount})}</StyledCounter>
      <StyledBr></StyledBr>
          <StyledTeammateWrapper>
            <StyledLabeller>{t('other.labeller')}:</StyledLabeller>
            {datasetInfo?.annotator?.email 
              ? 
                <StyledNameFirstLetter active={currentUser?.id === datasetInfo?.annotator?.id}>
                  {datasetInfo?.annotator?.email[0]}
                </StyledNameFirstLetter>
              : ""
            }
            {datasetInfo?.annotator?.email 
              ? 
                <FlexContainer direction='column' justify='center'>
                  <StyledName>{datasetInfo?.annotator?.email}</StyledName>
                </FlexContainer>
              : <StyledName>{t('authorized.project.annotate.notAssigned')}</StyledName> 
            }
          </StyledTeammateWrapper>
      
          <StyledTeammateWrapper>
            <StyledLabeller>{t('other.moderator')}:</StyledLabeller>
            {datasetInfo?.moderator?.email 
              ? 
                <StyledNameFirstLetter active={currentUser?.id === datasetInfo?.moderator?.id}>
                  {datasetInfo?.moderator?.email[0]}
                </StyledNameFirstLetter>
              : ""
            }
            {datasetInfo?.moderator?.email 
              ? 
                <FlexContainer direction='column' justify='center'>
                  <StyledName>{datasetInfo?.moderator?.email}</StyledName>
                </FlexContainer>
              : <StyledName>{t('authorized.project.annotate.notAssigned')}</StyledName> 
            }
          </StyledTeammateWrapper>

    </StyledBatch>
  )
}

export default DatasetJob