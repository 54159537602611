import React, { FC, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { StyledModelDescription, StyledModelImageName, StyledModelImageSkeleton, StyledModelImg, StyledModelImgWrapper } from '../../Styled/styled'
import emptyImage from '../../../../assets/icons/empty.png'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import AutoMarkupSlideForm from './AutoMarkupSlideForm'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { IAutoMarkupModelWithClass } from '../../../../services/AutoMarkupService'
import { IAutoMarkupModelWithTagClass, autoMarkupSlice } from '../../store/AutoMarkupSlice'
import { useTranslation } from 'react-i18next'
import { getTestImagesCount } from '../../Utils/AutoMarkupHelpers'

interface ISlideModelProps {
  name: string
  imagePath: string
  isTestPassed: boolean
  modelInfo?: IAutoMarkupModelWithClass
  tagModelInfo?: IAutoMarkupModelWithTagClass
  tags?: string[]
}

const Model: FC<ISlideModelProps> = ({ name, imagePath, isTestPassed, modelInfo, tagModelInfo, tags, ...props }) => {
  const { t } = useTranslation()
  const { ref, inView } = useInView({
    threshold: 0.1
  })

  const dispatch = useAppDispatch()
  const drawImagesCount = useAppSelector(state => state.AutoMarkupReducer.drawImagesCount)
  const { selectedSplit, selectedImages, images } = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {

  }, [])

  const onOneClick = () => {
    if (tags?.length && tagModelInfo?.type === 'text') {
      dispatch(autoMarkupSlice.actions.setTags(tags))
      dispatch(autoMarkupSlice.actions.setTestQuantity(1))
      dispatch(autoMarkupSlice.actions.setTestDemo(1))
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="trashold" />))
    }
    if (tagModelInfo?.type === 'classes') {
      dispatch(autoMarkupSlice.actions.setTestQuantity(1))
      dispatch(autoMarkupSlice.actions.setTestDemo(1))
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="trashold" />))
    }
  }

  const onTwentyClick = () => {
    if (tags?.length && tagModelInfo?.type === 'text') {
      dispatch(autoMarkupSlice.actions.setTags(tags))
      dispatch(autoMarkupSlice.actions.setTestQuantity(20))
      dispatch(autoMarkupSlice.actions.setTestDemo(2))
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="trashold" />))
    }
    if (tagModelInfo?.type === 'classes') {
      dispatch(autoMarkupSlice.actions.setTestQuantity(20))
      dispatch(autoMarkupSlice.actions.setTestDemo(2))
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="trashold" />))
    }
  }

  const onSelectModelClick = () => {
    if (tags?.length && tagModelInfo?.type === 'text') {
      dispatch(autoMarkupSlice.actions.setTags(tags))
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="counter" />))
    }
    if (tagModelInfo?.type === 'classes') {
      dispatch(autoMarkupSlice.actions.setSelectedModel(tagModelInfo))
      dispatch(modalWindowSlice.actions.setModalWidth('500px'))
      dispatch(modalWindowSlice.actions.setModalHeight('auto'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="counter" />))
    }
  }

  const getImagesSliderMax = () => {
    if (selectedImages?.length) {
      return selectedImages?.length
    }
    if (!selectedImages?.length) {
      return images.length
    }
  }

  return (
    <StyledModelDescription ref={ref}>
      <StyledModelImgWrapper>
        <FlexContainer direction="column" justify="center" align="center">
          {inView ? <StyledModelImg src={imagePath ? imagePath : emptyImage} alt="alt" /> : <StyledModelImageSkeleton />}
          <StyledModelImageName>{inView ? name : ' '}</StyledModelImageName>
        </FlexContainer>
        <FlexContainer direction="row" justify="space-around" margin="15px 0 10px 0" self="flex-end">
          {!isTestPassed && (
            <ProjectButton onClick={onOneClick} FontSizeProps="12px" widthProps="90px" heightProps="40px" disabled={tagModelInfo?.type === 'text' && !tags?.length}>
              {t('authorized.project.draw.automarkupTool.test1')}
            </ProjectButton>
          )}
          {!isTestPassed && (
            <ProjectButton onClick={onTwentyClick} FontSizeProps="12px" widthProps="90px" heightProps="40px" disabled={tagModelInfo?.type === 'text' && !tags?.length}>
              {t('authorized.project.draw.automarkupTool.test20')}
              {getTestImagesCount(getImagesSliderMax)}
            </ProjectButton>
          )}
          {isTestPassed && (
            <ProjectButton onClick={onSelectModelClick} FontSizeProps="12px" widthProps="90px" heightProps="40px" disabled={tagModelInfo?.type === 'text' && !tags?.length}>
              {t('buttons.continue')}
            </ProjectButton>
          )}
        </FlexContainer>
      </StyledModelImgWrapper>
    </StyledModelDescription>
  )
}

export default Model
