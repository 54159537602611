import React from 'react'
import { IPackageQuotaProps } from '../../constants/interfaces'
import { StyledPackageQuotaImage, StyledPackageQuotaName } from '../../constants/styled'
import usersIcon from '../../../../assets/icons/users-svgrepo-com.svg'
import eyeIcon from '../../../../assets/icons/eye-svgrepo-com.svg'
import imageIcon from '../../../../assets/icons/image-1-svgrepo-com.svg'
import diskIcon from '../../../../assets/icons/data-center-data-warehouse-svgrepo-com.svg'
import projectsIcon from '../../../../assets/icons/project.svg'
import coinsIcon from '../../../../assets/icons/coins.svg'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'

const PackageQuota: React.FC<IPackageQuotaProps> = ({quota, ...props}) => {

  const getImageFromQuotaName = (name: string) => {
    if (name === 'group_members') {
      return(usersIcon)
    }
    if (name === 'source_images') {
      return(imageIcon)
    }
    if (name === 'automarkup_credits_monthly') {
      return(coinsIcon)
    }
    if (name === 'disk_quotas') {
      return(diskIcon)
    }
    if (name === 'generated_images') {
      return(imageIcon)
    }
    if (name === 'projects') {
      return(projectsIcon)
    }
    if (name === 'automarkup_daily_runs') {
      return(eyeIcon)
    }
  }

  return (
    <FlexContainer widthProps='100%' direction='row' justify='flex-start' align='center' gap='10px'>
      <StyledPackageQuotaImage srcProps={getImageFromQuotaName(quota.name)}/>
      <FlexContainer direction='column' justify='flex-start' align='flex-start'>
        <StyledPackageQuotaName>{'+ ' + quota?.limit}</StyledPackageQuotaName>
        <StyledPackageQuotaName>{quota?.displayName || 'Квота'}</StyledPackageQuotaName>
      </FlexContainer>
    </FlexContainer>
  )
}

export default PackageQuota