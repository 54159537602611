/* eslint-disable @typescript-eslint/no-unused-vars */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ruNs1 from './locales/ru/ns1.json'
import enNs1 from './locales/en/ns1.json'

export const defaultNS = 'ns1';
const fallbackLng = process.env.REACT_APP_LANGUAGE

i18n
  // detect user language
  // .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  .init({
    debug: true,
    fallbackLng: fallbackLng ? fallbackLng : 'en',
    defaultNS,
    resources: {
      en: {
        ns1: enNs1
      },
      ru: {
        ns1: ruNs1,
      }
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });


export default i18n;