import React, { FC } from 'react'
import UserNav from '../../components/UserNav/UserNav'
import { IPagesProps } from '../../models/IPagesProps'
import { ForumMainWrapper } from '../../modules/Forum'

export const ForumPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='forum' navigate={navigate}></UserNav>
      <ForumMainWrapper navigate={navigate}/>
    </>
  )
}