import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import Input from '../../../UI/Input/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { Colors } from '../../../models/Colors';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import { ISignUpFormProps } from '../interfaces/ISignUpFormProps';
import { setInputFocusError } from '../helpers/inputFocusColorChange';
import { StyledErrorText, StyledErrorWrapepr, StyledEyeIcon, StyledForm, StyledFormHeader, StyledLink, StyledSupTitle } from '../constants/StyledConts';
import { validateFirstname, validateLastname, validatePasswordMismatch, validatePasswordWeight } from '../helpers/validateForms';
import { usePhoneValidation } from 'react-international-phone';
import ProjectPhoneInput from './ProjectPhoneInput';
import { useAppSelector } from '../../../hooks/redux';
import { useAppDispatch } from '../../../hooks/redux';
import { registrationErrorTotal } from '../reducers/registrationErrorTotal';
import { userSlice } from '../../../store/reducers/userReducer';
import AuthService from '../../../services/AuthService';
import { invitedRegErrorTotal } from '../reducers/invitedRegErrorTotal';
import InputText from '../../../UI/InputText/InputText';
import { useTranslation } from 'react-i18next';
import { ProjectGrayLink } from '../../../styles/GlobalStyles';

export const SignUpForm: FC<ISignUpFormProps> = ({...props}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const total = useAppSelector(state => state.invitedRegErrorTotal.total)

  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  
  //Input values  
  const [firstnameValue, setFirstNameValue] = useState('')
  const [lastnameValue, setLastnameValue] = useState('')
  const [PasswordValue, setPasswordValue] = useState('')
  const [repeatPasswordValue, setRepeatPasswordValue] = useState('')
  const [phone, setPhone] = useState('');
  const phoneValidation = usePhoneValidation(phone);

  //Focused input state   
  const [firstNameFocusColor, setFirstnameFocusColor] = useState(Colors.gray)
  const [lastNameFocusColor, setLastnameFocusColor] = useState(Colors.gray)
  const [phoneFocusColor, setPhoneFocusColor] = useState(Colors.gray)
  const [PasswordFocusColor, setPasswordFocusColor] = useState(Colors.gray)
  const [repeatPasswordFocusColor, setRepeatPasswordFocusColor] = useState(Colors.gray)
  const [isPasswordHidden, setIsPasswordHidden] = useState(false)

  const [didKnow, setDidKnow] = useState('')


  //token
  const [token, setToken] = useState("")

  useEffect(() => {
    if (params.token) {
      setToken(params.token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        submitClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)

    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, repeatPasswordValue, PasswordValue, lastnameValue, firstnameValue])

  const onSignedClick = () => {
    navigate('/signin')
  }

  const firstnameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameValue(e.target.value)
    const validateResult = validateFirstname(e.target.value)
    setInputFocusError(setVisible, setErrorText, setFirstnameFocusColor, validateResult) 
    console.log('firstname', validateResult)
    if (validateResult.isValid) {
      dispatch(invitedRegErrorTotal.actions.setIsFirstNameError(validateResult.isValid))
    }
  }

  const lastnameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastnameValue(e.target.value)
    const validateResult = validateLastname(e.target.value)
    setInputFocusError(setVisible, setErrorText, setLastnameFocusColor, validateResult) 
    console.log('lastname', validateResult)
    if (validateResult.isValid) {
      dispatch(invitedRegErrorTotal.actions.setIsLastNameError(validateResult.isValid))
    }
  }

  const passwordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value)
    const validateResult = validatePasswordWeight(e.target.value)
    setInputFocusError(setVisible, setErrorText, setPasswordFocusColor, validateResult) 
    console.log('password', validateResult)
    if (validateResult.isValid) {
      dispatch(invitedRegErrorTotal.actions.setIsPasswordError(validateResult.isValid))
    }
  }

  const repeatPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPasswordValue(e.target.value)
    const validateResult = validatePasswordMismatch(PasswordValue, e.target.value)
    setInputFocusError(setVisible, setErrorText, setRepeatPasswordFocusColor, validateResult) 
    console.log('repeat', validateResult)
    if (validateResult.isValid) {
      dispatch(invitedRegErrorTotal.actions.setIsPasswordMismatchError(validateResult.isValid))
    }
  }

  const checkPhoneValidation = () => {
    if (!phoneValidation.isValid) {
      // setVisible(true)
      // setPhoneFocusColor(Colors.red)
      // setErrorText('Неверно введён номер телефона')
      // dispatch(registrationErrorTotal.actions.setIsPhoneError(phoneValidation.isValid))
    }
    if (phoneValidation.isValid) {
      dispatch(invitedRegErrorTotal.actions.setIsPhoneError(phoneValidation.isValid))
    }
  }

  const showErrorField = (text:string) => {
      setVisible(true)
      setErrorText(text)
  }

  const submitClickHandler = async () => {
    try {
      dispatch(invitedRegErrorTotal.actions.checkTotal())
      console.log(total)

      if (!total) {
        showErrorField(t('other.formError'))
      }
  
      if (total && token) {
        const userData = {
          firstName: firstnameValue, 
          lastName: lastnameValue,
          inviteCode: token,
          phone: phone,
          didKnow: didKnow,
          password: PasswordValue,
          userType: 0
        } 
        dispatch(userSlice.actions.userAuth())
        const responce = await AuthService.invitedRegistration(userData)
        dispatch(userSlice.actions.userRegistrationSuccess())
        if (responce.data.token) {
          localStorage.setItem("token", responce.data.token)
          navigate('/projects')
        }
        console.log('acyncRegistration', responce)
      }

    } catch (error: any) {
      console.log('AsyncRegistration', error)
      showErrorField(error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message)
    }
  }

  return (
    <>
    <StyledErrorWrapepr visible={visible}>
        <StyledErrorText>{errorText}</StyledErrorText>
    </StyledErrorWrapepr>
    
    <StyledForm onSubmit={(e:any) => {e.preventDefault()}}>
        <StyledFormHeader>{t('notAuthorized.signup.signup')}</StyledFormHeader>
        <FlexContainer direction='row' gap='20px' justify='center' paddingProps='0'>
          <Input 
            widthProps='140px' 
            label={t('notAuthorized.inputTitles.firstName')} 
            focusColor={firstNameFocusColor} 
            borderColor={firstNameFocusColor}
            value={firstnameValue} 
            onChange={firstnameChangeHandler}
            placeholder='Ivan'
          />
          <Input 
            widthProps='140px' 
            label={t('notAuthorized.inputTitles.lastName')}
            focusColor={lastNameFocusColor} 
            borderColor={lastNameFocusColor} 
            value={lastnameValue} 
            onChange={lastnameChangeHandler}
            placeholder='Ivanov'
          />
        </FlexContainer>
        <ProjectPhoneInput 
          label={t('notAuthorized.inputTitles.phoneNumber')}
          phone={phone} 
          setPhone={setPhone}
          onChange={checkPhoneValidation}
          placeholder='+79285553535'
          focusColor={phoneFocusColor} 
          borderColor={phoneFocusColor} 
        />
        <FlexContainer direction='row' justify='center' align='center' gap="5px" margin='0 0 0 30px'>
          <Input 
            withTooltip={true}
            tooltipText={t('notAuthorized.passwordTooltip')}
            label={t('notAuthorized.inputTitles.password')}
            focusColor={PasswordFocusColor} 
            borderColor={PasswordFocusColor} 
            value={PasswordValue} 
            onChange={passwordChangeHandler}
            placeholder={t('notAuthorized.placeholders.enterPassword')}
            type={isPasswordHidden ? "text" : 'password'}
          />
          <StyledEyeIcon active={isPasswordHidden} onClick={() => setIsPasswordHidden(!isPasswordHidden)}/>
        </FlexContainer>
        <FlexContainer direction='row' justify='center' align='center' widthProps='100%'>
          <InputText 
            value={didKnow} 
            label={t('notAuthorized.inputTitles.whereFind')} 
            onChange={(e) => setDidKnow(e.target.value)} 
            placeholder={t('notAuthorized.placeholders.whereFind')} 
            heightProps='60px'
            containerWidth='auto'
          />
        </FlexContainer>
        {/* <Input 
            label='Repeat password' 
            focusColor={repeatPasswordFocusColor} 
            borderColor={repeatPasswordFocusColor}
            value={repeatPasswordValue} 
            onChange={repeatPasswordChangeHandler}
            type={isPasswordHidden ? "text" : 'password'}
            placeholder='Repeat password'
        /> */}
        <ProjectButton margin='10px 0 0 0' widthProps='150px' heightProps='35px' onClick={submitClickHandler} FontSizeProps='16px'>{t('notAuthorized.signup.signup')}</ProjectButton>
        <StyledSupTitle>{t('notAuthorized.signup.haveAcc')} <StyledLink onClick={onSignedClick}>{t('buttons.signIn')}</StyledLink></StyledSupTitle>
        <ProjectGrayLink href={process.env.REACT_APP_RULES_URL} target='_blank'>{t('notAuthorized.signup.agreement')}</ProjectGrayLink>
    </StyledForm>
    </>
  )
}

