import React, { useEffect, useState } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import GenerateOptionsForm from '../TabsContentPages/ProjectGenerate/Components/GenerateOptionsForm'
import { versionsReducer } from '../../store/reducers/VersionsReducer'
import { generateReducer } from '../../store/reducers/GenerateReducer'
import { GenerateLabelsContainer, StyledGenerateImage } from '../../constants/styled'
import arrowRight from '../../../../assets/icons/arrow-right.svg'
import Input from '../../../../UI/Input/Input'
import { ICreateExportRequest } from '../../../../services/VersionsService'
import ExportVersionPercentageForm from '../TabsContentPages/ProjectVersions/ExportVersionPercentageForm'
import ExportVersionForm from '../TabsContentPages/ProjectVersions/ExportVersionForm'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IRenameLabelFormProps {
  type: string;
  subscribe?: (payload: ICreateExportRequest) => Promise<void>
  selectValue?: string
  checked?: boolean;
}

const RenameLabelsForm: React.FC<IRenameLabelFormProps> = ({ type, subscribe, selectValue, checked, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { resizeOption, tileOption, autoContrastOption, simpleOptions, blurOption, noiseOption, brightness, grayscaleOption } = useAppSelector((state) => state.generateReducer)
  const modalVisible = useAppSelector((state) => state.modalWindowReducer.visible)
  const { generateDatasetLabels } = useAppSelector((state) => state.generateReducer)
  const { currentVersion } = useAppSelector((state) => state.versionsReducer)
  const projectInfo = useAppSelector((state) => state.WorkspacesReducer.projectInfo)
  const [labels, setLabels] = useState<any[]>([])
  const navigate = useNavigate()
  const params = useParams()

  console.log('labels', labels)

  useEffect(() => {
    if (type === 'dataset' && generateDatasetLabels?.length) {
      setLabels(generateDatasetLabels)
    }
    if (type === 'version' && currentVersion?.versionInfo?.labels?.length) {
      const labels = JSON.parse(JSON.stringify(currentVersion.versionInfo.labels))
      setLabels(labels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible])

  const onNameChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    let tempValues = JSON.parse(JSON.stringify(labels))
    tempValues[index].newName = event.target.value
    setLabels(tempValues)
  }

  const onCancelClick = () => {
    if (type === 'dataset') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateTitle3')))
      dispatch(modalWindowSlice.actions.setModalWidth(''))
      dispatch(
        modalWindowSlice.actions.setChildren(
          <GenerateOptionsForm
            type={'preprocessing'}
            tileOption={tileOption}
            resizeOption={resizeOption}
            simpleOptions={simpleOptions}
            autoContrastOption={autoContrastOption}
            blurOption={blurOption}
            brightness={brightness}
            noiseOption={noiseOption}
            grayscaleOption={grayscaleOption}
          />
        )
      )
    }
    if (type === 'version') {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(versionsReducer.actions.rejectVersionsRenameLabels())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.exportVersion')))
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setChildren(<ExportVersionForm projectInfo={projectInfo} currentVersion={currentVersion} navigate={navigate} params={params} />))
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }

  const onApplyClick = (type: string) => {
    console.log(type)
    if (type === 'dataset' && labels?.length) {
      dispatch(generateReducer.actions.setRenamedGenerateLabels(labels))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    if (type === 'version' && subscribe && selectValue) {
      const payload = {
        service: 'export',
        version: currentVersion.id,
        renameLabels: labels,
        format: selectValue,
        useOriginalFileNames: checked!
      }
      subscribe(payload)
      dispatch(versionsReducer.actions.setProgress(0))
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.exportVersion')))
      dispatch(modalWindowSlice.actions.setChildren(<ExportVersionPercentageForm />))
      dispatch(versionsReducer.actions.rejectVersionsRenameLabels())
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }

  return (
    <FlexContainer direction="column" justify="flex-start" align="space-between">
      <GenerateLabelsContainer>
        {labels.map((label, index) => (
          <FlexContainer direction="row" justify="center" align="center" gap="20px" key={index}>
            <Input label={t('authorized.project.generateAndVersions.oldName')} placeholder={label.oldName} widthProps="180px" disabled heightProps="25px" />
            <StyledGenerateImage srcProps={arrowRight} />
            <Input
              type="text"
              value={label.newName}
              onChange={(e) => onNameChange(index, e)}
              label={t('authorized.project.generateAndVersions.newName')}
              placeholder={label.newName}
              widthProps="180px"
              heightProps="25px"
            />
          </FlexContainer>
        ))}
      </GenerateLabelsContainer>
      <FlexContainer direction="row" justify="center" align="center" paddingProps="0 15px" gap="40px">
        <ProjectButton onClick={onCancelClick}>{t('buttons.back')}</ProjectButton>
        <ProjectButton onClick={() => onApplyClick(type)}>{t('buttons.apply')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameLabelsForm
