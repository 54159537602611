const regExpObj = {
    email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
}

// Проверка на валидность email
export const validateEmail = (emailValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.email.test(emailValue)) {
        isValid = true;
    }
    if (!regExpObj.email.test(emailValue)) {
        isValid = false;
        errorMessage = 'Please enter a valid email'
    }
    return {errorMessage, isValid}
}

