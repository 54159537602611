import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react'
import Styles from './styles.module.css'
import { ReactComponent as ArrowDown } from '../../assets/icons/GroupMark.svg'
import OptionEl from './OptionElem';

export interface Option {
  title: string;
  value: string;
  type?: string;
};

interface SelectProps {
  selected: Option | null;
  options: Option[];
  placeholder?: string;
  mode?: "rows" | "cells";
  status?: "default" | "invalid";
  onChange?: (selected: Option["value"]) => void;
  onClose?: () => void;
  toTop?: 'toTop' | '';
};

const ProjectSelect: FC<SelectProps> = ({mode, options, placeholder, status, selected, onChange, onClose, toTop, ...props}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;
      if (target instanceof Node && !rootRef.current?.contains(target)) {
        isOpen && onClose?.();
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  const handleOptionClick = (value: Option["value"]) => {
    setIsOpen(false);
    onChange?.(value);
  };
  
  const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className={Styles.selectWrapper}
      ref={rootRef}
      data-is-active={isOpen}
      data-mode={mode}
      data-testid="selectWrapper"
    >
      <div className={Styles.arrow}>
        <ArrowDown onClick={() => setIsOpen((prev) => !prev)}/>
      </div>
      <div
        className={Styles.placeholder}
        data-status={status}
        data-selected={!!selected?.value}
        onClick={handlePlaceHolderClick}
        role="button"
        tabIndex={0}
        ref={placeholderRef}
      >
        {selected?.title || placeholder}
      </div>
      {isOpen && (
        <ul className={toTop ? Styles.toTop : Styles.select} data-testid="selectDropdown">
          {toTop 
            ? options.map((option) => (
                <OptionEl
                  key={option.value}
                  option={option}
                  onClick={handleOptionClick}
                />
              )).reverse()
            : options.map((option) => (
              <OptionEl
                key={option.value}
                option={option}
                onClick={handleOptionClick}
              />
            ))
          }

        </ul>
      )}
    </div>
  );
};

export default ProjectSelect;