import React, { FC, useEffect, useState } from 'react'
import { StyledBr, StyledTableItem, StyledUsersTable, StyledUsersTableHeader } from '../../constants/Styled'
import GroupUserCard from '../GroupUserCard/GroupUserCard'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { ISummaryRole } from '../../../../services/AccessService'
import { Option } from '../../../../UI/ProjectSelect/ProjectSelect'
import { IGroupUserFullInfo } from '../../store/reducers/WorkspacesReducer'
import { IFullGroup } from '../../../../services/GroupService'
import { useTranslation } from 'react-i18next'

interface IUsersTableProps {
  groupRoles: ISummaryRole[];
  groupUsersInfo: IGroupUserFullInfo[] | null | undefined;
  roleNumber: number;
  groupInfo: IFullGroup;
}

const UsersTable: FC<IUsersTableProps> = ({groupInfo, groupRoles, groupUsersInfo, roleNumber, ...props}) => {
  const { t } = useTranslation()
  const [optionsTable, setOptionsTable] = useState<Option[]>([])
  const [users, setUsers] = useState<IGroupUserFullInfo[]>([])

  useEffect(() => {
    if (groupRoles.length) {
      const options: Option[] = []
      groupRoles.forEach(role => {
        options.push({title: role.displayName, value: role.name})
      })
      setOptionsTable(options)
    }

    if (groupUsersInfo?.length) {
      setUsers(groupUsersInfo)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRoles, groupUsersInfo])

  return (
    <StyledUsersTable>
        <StyledUsersTableHeader>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableUser')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableRole')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableStatus')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableActions')}</StyledTableItem>
        </StyledUsersTableHeader>
        <StyledBr margin='0 0 15px 0'/>
        <FlexContainer direction='column' justify='center' align='flex-start' gap="15px">
          {users?.length ? users.map(user => 
            <GroupUserCard 
              key={user.user.id} 
              userInfo={user.user} 
              userRole={user.groupRole} 
              optionsTable={optionsTable} 
              groupUsersInfo={users}
              setGroupUsersInfo={setUsers}
              roleNumber={roleNumber}
              groupInfo={groupInfo}
            />
          ) : ''}
        </FlexContainer>
        
    </StyledUsersTable>
  )
}

export default UsersTable