import React, { useEffect } from 'react'
import { IContentSectionProps } from '../../constants/interfaces'
import { StyledContentSection, StyledContentSectionTitle , StyledContentSectionSubTitle, StyledContentSectionTitleWrapper} from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import QuotaPackagesForm from '../QuotaPackagesForm/QuotaPackagesForm'
import { asyncGetGroupPackages } from '../../../../store/reducers/AcyncActions'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const GroupUsage: React.FC<IContentSectionProps> = ({title, subtitle, children, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { groupPackages } = useAppSelector(state => state.SettingsReducer)
  const params = useParams()

  useEffect(() => {
    dispatch(asyncGetGroupPackages(params.groupId!))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onUpgrateClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.groupPackages')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setModalWidth('80vw'))
    dispatch(modalWindowSlice.actions.setActiveScrollTrue())
    dispatch(modalWindowSlice.actions.setTooltipText(t('authorized.settings.packagesTooltip')))
    dispatch(modalWindowSlice.actions.setChildren(<QuotaPackagesForm/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledContentSection>
      {title || subtitle 
        ?
        <FlexContainer direction='column' justify='flex-start' align='flex-start' gap='15px'>
          <StyledContentSectionTitleWrapper>
            <StyledContentSectionTitle>{title} </StyledContentSectionTitle>
            {groupPackages?.length ? <ProjectButton active heightProps='25px' widthProps='auto' FontSizeProps='14px' paddingProps='0 8px' onClick={onUpgrateClick}>{t('authorized.settings.groupPackages')}</ProjectButton> : ''}
          </StyledContentSectionTitleWrapper>
          <StyledContentSectionSubTitle>{subtitle}</StyledContentSectionSubTitle>
        </FlexContainer>
        : ''
      }
      {children}
    </StyledContentSection>
  )
}

export default GroupUsage