import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { Colors } from '../../../models/Colors'
import { IUserNavDropdownProps } from '../../../models/IUserNavDropdownProps'
import DropdownButton from '../../../UI/Buttons/DropdownButton/DropdownButton'
import { StyledBurger, StyledHeaderDropdownWrapper } from '../Styled/styled'
import { asyncAnnotateImage } from '../../../store/reducers/AcyncActions'
import { dataConverterForApi, getApiElemsWithStatus } from '../Utils/DrawMethods'
import { useTranslation } from 'react-i18next'
import SendMailForm from '../../../UI/forms/SendMailForm'
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer'
import { getToolsRenderCondition } from '../Utils/AutoMarkupHelpers'

interface IDrawHeaderDropdownProps {
  elements: any[];
  params: any;
  moderate: string;
  imageStatus: string | null | undefined;
  cleared: boolean;
  isGuestViewMode: boolean;
}

const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  .username {
    height: 30px;
    padding: 5px;
  }
`
const StyledSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:30px;
  height: 30px;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  .count {
    position: absolute;
    top: -6px;
    right: 4px;
  }
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${props => props.visible ? 'block' : 'none'};
  width: 160px;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  position: absolute;
  top: 100%;
  right: 40%;
  z-index:999;
`

const DrawHeaderDropdown: FC<IDrawHeaderDropdownProps> = ({elements, params, moderate, imageStatus, cleared, isGuestViewMode, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const viewMode = useAppSelector(state => state.TabsReducer.viewMode)
  const currentProject = useAppSelector(state => state.WorkspacesReducer.projectInfo)
  const roleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)
  const imageInfo = useAppSelector(state => state.DrawTabsReducer.imageInfo)

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }
  
  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }

  const onSaveClick = () => {
    const apiElems = dataConverterForApi(elements, imageInfo!.image.width, imageInfo!.image.height, params.imgName!, params.id!)
    const apiElemsWithStatus = getApiElemsWithStatus(apiElems, imageStatus, moderate, viewMode, cleared)
    dispatch(asyncAnnotateImage(apiElemsWithStatus))
  }

  const onSupportClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('other.supportFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<SendMailForm/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledHeaderDropdownWrapper>
      <StyledDropDown {...props}>
        <FlexContainer paddingProps='0' direction='row' justify='center' align='center' onClick={onDropdownClick}>
          <StyledSettings>
            <StyledBurger {...props} onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}/>
          </StyledSettings>
        </FlexContainer>
        <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
          <DropdownButton onClick={getToolsRenderCondition(isGuestViewMode, currentProject?.objectType, 'any', roleNumber) ? () => {} : () => onSaveClick()} disabled={getToolsRenderCondition(isGuestViewMode, currentProject?.objectType, 'any', roleNumber)}>{t('authorized.project.draw.nav.saveAnnot')}</DropdownButton>
          <DropdownButton onClick={onSupportClick}>{t('authorized.header.support')}</DropdownButton>
        </StyledDropdownContent>
      </StyledDropDown>
    </StyledHeaderDropdownWrapper>
  )
}

export default DrawHeaderDropdown