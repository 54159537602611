import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../../../i18n';
import { INewImage } from '../../../services/BatchesService';

interface IsetPagesInfoPayload {
  limit: number;
  imagesTotal: number;
  remain: number;
  imagesOnPage: number;
  total: number;
}

export enum EImagesPage {
    batchPage = 'batchPage',
    assignPage = 'assignPage',
    taskPage = 'taskPage',
    datasetPage = 'datasetPage',
    datasetsPage = 'datasetsPage',
    versionPage = 'versionPage',
    libraryPage = 'libraryPage',
}

export interface ISplit {
  title: string;
  value: string;
}

export interface IImagesListReducerState {
  isLoading: boolean
  error: string | null
  currentPageName: 'batchPage' | 'assignPage' | 'taskPage' | 'datasetPage' | 'datasetsPage' | 'versionPage' | 'libraryPage'

  selectedImages: INewImage[]
  images: INewImage[]

  pages: number[]
  currentPage: number
  imagesTotal: number
  total: number
  limits: ISplit[];
  currentLimit: ISplit;
  remain: number
  imagesOnPage: number

  selectedSplit: ISplit | null

  pageStates: {
    batchPage: {
      split: ISplit[]
    }
    assignPage: {
      split: ISplit[]
    }
    taskPage: {
      split: ISplit[]
    }
    datasetPage: {
      split: ISplit[]
    }
    datasetsPage: {
      split: ISplit[]
    }
    versionPage: {
      split: ISplit[]
    }
    libraryPage: {
      split: ISplit[]
    }
  }
}

const initialState: IImagesListReducerState = {
  isLoading: false,
  error: '',
  currentPageName: 'batchPage',

  selectedImages: [],
  images: [],

  pages: [],
  currentPage: 1,
  imagesTotal: 0,
  total: 0,
  remain: 0,
  limits: [
    { title: '50', value: '50' },
    { title: '100', value: '100' },
    { title: '200', value: '200' },
    { title: '500', value: '500' },
    { title: '1000', value: '1000' },
  ],
  currentLimit: { title: '50', value: '50' },
  imagesOnPage: 0,

  selectedSplit: { title: i18n.t('imagesList.allTypes'), value: 'all' },

  pageStates: {
    batchPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' }
      ]
    },
    assignPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' }
      ]
    },
    taskPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' },
        { title: i18n.t('imagesList.automarkup'), value: 'automarkup' },
        { title: i18n.t('imagesList.approved'), value: 'approved' },
        { title: i18n.t('imagesList.rejected'), value: 'rejected' },
        { title: i18n.t('imagesList.forReview'), value: 'review' }
      ]
    },
    datasetPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' }
      ]
    },
    datasetsPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' }
      ]
    },
    versionPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: 'Train', value: 'train' },
        { title: 'Valid', value: 'valid' },
        { title: 'Test', value: 'test' },
      ]
    },
    libraryPage: {
      split: [
        { title: i18n.t('imagesList.allTypes'), value: 'all' },
        { title: i18n.t('imagesList.annotated'), value: 'annotated' },
        { title: i18n.t('imagesList.unannotated'), value: 'unannotated' }
      ]
    }
  }
}

export const imagesListSlice = createSlice({
  name: 'imagesList',
  initialState,

  reducers: {
    setInitialState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.currentPageName = initialState.currentPageName;
      state.selectedImages = initialState.selectedImages;
      state.images = initialState.images;
      state.pages = initialState.pages;
      state.currentPage = initialState.currentPage;
      state.imagesTotal = initialState.imagesTotal;
      state.total = 0;
      state.remain = 0;
      state.currentLimit = initialState.currentLimit;
      state.imagesOnPage = 0;
      state.selectedSplit = initialState.selectedSplit;
      state.pageStates = initialState.pageStates;


    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setCurrentPageName(state, action: PayloadAction<IImagesListReducerState['currentPageName']>) {
      state.currentPageName = action.payload
    },

    setImages(state,  action: PayloadAction<INewImage[]>) {
        state.images = action.payload
    },

    setPagesInfo(state, action: PayloadAction<IsetPagesInfoPayload>) {
        state.imagesTotal = action.payload.imagesTotal
        state.remain = action.payload.remain
        state.imagesOnPage = action.payload.imagesOnPage
        state.total = action.payload.total

        const pages = action.payload.total / action.payload.limit
        const pagesArr = []
        if (Number.isInteger(pages)) {
          for(let i = 1; i <= pages; i++) {
            pagesArr.push(i)
          }
          state.pages = pagesArr
        } else {
          for(let i = 1; i <= pages + 1; i++) {
            pagesArr.push(i)
          }
          state.pages = pagesArr
        }
    },

    setAllSelected(state) {
      state.images.forEach((image) => {
        const findedImage = state.selectedImages.find((selectedImage) => selectedImage.id === image.id)
        if (!findedImage) {
          state.selectedImages.push(image)
        }
      })
    },

    deselectAllImages(state) {
      state.selectedImages = []
    },

    selectImage(state, action: PayloadAction<INewImage>) {
      const findedImage = state.selectedImages.find((selectedImage) => selectedImage.id === action.payload.id)
      if (!findedImage) {
        state.selectedImages.push(action.payload)
      }
    },

    deselectImage(state, action: PayloadAction<INewImage>) {
      const filteredSelectedImages = state.selectedImages.filter(image => image.id !== action.payload.id)
      state.selectedImages = filteredSelectedImages
    },

    setSelectedSplit(state, action: PayloadAction<ISplit>) {
        state.selectedSplit = action.payload
    },

    setSelectedLimit(state, action: PayloadAction<ISplit>) {
        state.currentLimit = action.payload
    },

    setCurrentPage(state, action: PayloadAction<number>) {
        state.currentPage = action.payload
    }
    

    //batchPage actions start

    //batchPage actions end
  }
})

export default imagesListSlice.reducer;