import React from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledLoadingText, StyledModalWindowTitle } from '../../../../constants/styled'
import Loader from '../../../../../../UI/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
// import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'
// import JobsService from '../../../../../../services/JobsService'

const GenerateLoaderForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const progress = useAppSelector((state) => state.versionsReducer.progress)
  // const job = useAppSelector(state => state.versionsReducer.job)

  // const onCancelClick = async () => {
  //   if (job) {
  //     try {
  //       const stopResponce = await JobsService.stopJob(job)
  //       console.log('stopResponce', stopResponce)
  //       const deleteResponce = await JobsService.deleteJob(job)
  //       console.log('deleteResponce', deleteResponce)
  //       dispatch(modalWindowSlice.actions.setInititialState())
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }
  const onCloseClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="5px" paddingProps="10px">
      <Loader />
      <StyledLoadingText>{progress}%</StyledLoadingText>
      <StyledModalWindowTitle>{t('authorized.project.generateAndVersions.generateLoadText1')}</StyledModalWindowTitle> <br/>
      <StyledModalWindowTitle>{t('authorized.project.generateAndVersions.generateLoadText2')}</StyledModalWindowTitle>
      <ProjectButton onClick={onCloseClick}>{t('buttons.close')}</ProjectButton>
    </FlexContainer>
  )
}

export default GenerateLoaderForm
