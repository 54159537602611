import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface ILabel {
    id: string;
    name: string;
    color: string;
    project: string;
    updatedAt: string;
    createdAt: string;
}

export interface ICreateLabelResponce {
    label: {
        id: string;
        name: string;
        color: string;
        project: string;
        updatedAt: string;
        createdAt: string;
    };
    message: string;
}

export interface IUpdateLabelResponce {
    label: ILabel;
    message: string;
}

export interface IDeleteLabelResponce {
    message: {
        type: string;
        example: string;
    }
}

export interface IGetProjectLabelsResponce {
    labels: ILabel[];
    message: string;
}


export default class LabelService {
    
    static async createLabel(name: string, color: string, project: string): Promise<AxiosResponse<ICreateLabelResponce>> {
        return $authHost.post<ICreateLabelResponce>('/labels', {name: name, color: color, project: project})
    }

    static async putLabel(name: string, color: string, id: string): Promise<AxiosResponse<IUpdateLabelResponce>> {
        return $authHost.put<IUpdateLabelResponce>('/labels', {name: name, color: color, id: id})
    }

    static async getLabelInfo(labelId: string): Promise<AxiosResponse<ICreateLabelResponce>> {
        return $authHost.get<ICreateLabelResponce>(`/labels/${labelId}`)
    }

    static async deleteLabel(labelId: string): Promise<AxiosResponse<IDeleteLabelResponce>> {
        return $authHost.delete<IDeleteLabelResponce>(`/labels/${labelId}`)
    }

    static async getProjectLabels(projectId: string): Promise<AxiosResponse<IGetProjectLabelsResponce>> {
        return $authHost.get<IGetProjectLabelsResponce>(`/labels/project/${projectId}`)
    }
}