import React, { ReactChild, ReactNode } from 'react'
import styled from 'styled-components';
import { Colors } from '../../models/Colors';
import copyIcon from '../../assets/icons/copy.svg'

interface IClipBoardCopyTextProps {
  children: ReactNode | ReactChild;
  fsProps?: string;
  colorProps?: string;
  minWidth?: string;
  maxWidth?: string;
}

interface ITextProps {
  fsProps?: string;
  colorProps?: string;
  
  minWidth?: string;
  maxWidth?: string;
}

const StyledWarpper = styled.div<ITextProps>`
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  height: 40px;
  gap: 5px;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: ${Colors.orange};

  font-weight: bold;
  font-size: 14px;

  min-width: ${({minWidth}) => minWidth || '100px'};
  max-width: ${({maxWidth}) => maxWidth || '235px'};

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;

  cursor: pointer;
  transition: all .1s linear;
  
  :hover {
      background-color: ${Colors.darkOrange};
  }
`

const StyledText = styled.span<ITextProps>`
  font-size: ${({fsProps}) => fsProps || '12px'};
  color: ${({colorProps}) => colorProps || '#000000'};
  height: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  min-width: ${({minWidth}) => minWidth || '80px'};
  max-width: ${({maxWidth}) => maxWidth || '200px'};

`

export const StyledShareIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${copyIcon});
  mask-image: url(${copyIcon});
  background-color: ${Colors.black};
  width: 20px;
  height: 20px;

  transition: all .1s linear;
`

const ClipboardText: React.FC<IClipBoardCopyTextProps> = ({children, fsProps, colorProps, minWidth, maxWidth}) => {
  const [copied, setCopied] = React.useState(false)

  const onCopyClick = () => {
    if (typeof children === "string") {
        const textField = document.createElement('textarea');
        textField.innerText = children;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000)
    }
  }

  return (
    <StyledWarpper onClick={onCopyClick}>
      <StyledShareIcon/>
      <StyledText fsProps={fsProps} colorProps={colorProps} minWidth={minWidth} maxWidth={maxWidth}>
        {copied ? 'Скопировано' : children}
      </StyledText>
    </StyledWarpper>
  )
}

export default ClipboardText