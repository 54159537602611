import React, { useEffect, useState } from 'react'
import { IQuotaCardProps } from '../../constants/interfaces'
import usersIcon from '../../../../assets/icons/users-svgrepo-com.svg'
import diskIcon from '../../../../assets/icons/data-center-data-warehouse-svgrepo-com.svg'
import projectsIcon from '../../../../assets/icons/project.svg'
import coinsIcon from '../../../../assets/icons/coins.svg'
import { StyledCardQuotaText, StyledPackageQuotaTooltipWrapper, StyledPackageTitle, 
         StyledPackageTitleWrapper, StyledQuotaCardImage, StyledQuotaCardWrapper } from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import Tooltip from '../../../../UI/Tooltip/Tooltip'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import EditQuotaForm from './EditQuotaForm'
import { useTranslation } from 'react-i18next'
import WarningPriceForm from './WarningPriceForm'

const QuotaCard: React.FC<IQuotaCardProps> = ({quota, groupQuotas,  ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [image, setImage] = useState<any>('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (quota.name === 'group_members') {
      setImage(usersIcon)
      setText(t('authorized.settings.membersQuota'))
    }
    if (quota.name === 'automarkup_credits_monthly') {
      setImage(coinsIcon)
      setText(t('authorized.settings.creditsQuota'))
    }
    if (quota.name === 'disk_quotas') {
      setImage(diskIcon)
      setText(t('authorized.settings.diskQuota'))
    }
    if (quota.name === 'projects') {
      setImage(projectsIcon)
      setText(t('authorized.settings.projectsQuota'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExtendClick = () => {
    const notify = localStorage.getItem('notify')
    if (notify === 'true') {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(quota.displayName))
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
      dispatch(modalWindowSlice.actions.setChildren(<EditQuotaForm quota={quota} groupQuotas={groupQuotas}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(quota.displayName))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setChildren(<WarningPriceForm quota={quota} groupQuotas={groupQuotas}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledQuotaCardWrapper>
      <StyledPackageTitleWrapper>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='5px'>
          <StyledQuotaCardImage srcProps={image}/>
          <StyledPackageTitle>{quota.displayName}</StyledPackageTitle>
          <StyledPackageQuotaTooltipWrapper>
            <Tooltip>{quota.description}</Tooltip>
          </StyledPackageQuotaTooltipWrapper>
        </FlexContainer>
        <h2>1500 RV/{t('other.month')}</h2>
      </StyledPackageTitleWrapper>
      <StyledCardQuotaText>{text}</StyledCardQuotaText>
      <FlexContainer direction='row' justify='center' align='center'>
        <ProjectButton onClick={onExtendClick}>{t('authorized.settings.groupIncrease')}</ProjectButton>
      </FlexContainer>
    </StyledQuotaCardWrapper>
  )
}

export default QuotaCard