import React, { FC } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import {
  StyledLastSubCategoryPostWrapper,
  StyledSubCategoryDate,
  StyledSubCategoryLastHeader,
  StyledSubCategoryUserLink,
  StyledSubCategoryUserSpan,
  StyledUserIcon
} from '../../constants/styled'
import { ISubcategory } from '../../../../services/ForumService'
import { useTranslation } from 'react-i18next'

interface ILastSubCategoryPost {
  subCategoryInfo: ISubcategory
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const LastSubCategoryPost: FC<ILastSubCategoryPost> = ({ subCategoryInfo, ...props }) => {
  const { t } = useTranslation()

  return (
    <StyledLastSubCategoryPostWrapper {...props}>
      <StyledUserIcon />
      <FlexContainer direction="column" justify="flex-start" align="flex-start">
        <StyledSubCategoryLastHeader>{subCategoryInfo?.LatestTopic?.title.charAt(0).toUpperCase() + subCategoryInfo?.LatestTopic?.title.slice(1)}</StyledSubCategoryLastHeader>
        <FlexContainer direction="row" justify="flex-start" align="center">
          <StyledSubCategoryUserSpan>{t('forum.By')}</StyledSubCategoryUserSpan>
          <StyledSubCategoryUserLink>{subCategoryInfo?.LatestTopic?.author?.firstName}</StyledSubCategoryUserLink>
        </FlexContainer>
        <StyledSubCategoryDate>{subCategoryInfo?.LatestTopic?.createdAt?.slice(0, 10)}</StyledSubCategoryDate>
      </FlexContainer>
    </StyledLastSubCategoryPostWrapper>
  )
}

export default LastSubCategoryPost
