import React, { FC, useEffect, useState } from 'react'
import InputRadio from '../../../../UI/InputRadio/InputRadio';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { StyledBr, StyledMarkupClassWrapper } from '../../Styled/styled';
import { IAutoMarkupClassWithModel } from '../../../../services/AutoMarkupService';
import { useDispatch } from 'react-redux';
import { autoMarkupSlice } from '../../store/AutoMarkupSlice';
import { useAppSelector } from '../../../../hooks/redux';

interface IMarkupClassProps {
  label: string;
  classInfo: IAutoMarkupClassWithModel;
  queryClasses: IAutoMarkupClassWithModel[]
}

const MarkupClass: FC<IMarkupClassProps> = ({label, classInfo, queryClasses, ...props}) => {
  const dispatch = useDispatch()
  const classesState = useAppSelector(state => state.AutoMarkupReducer.classesState)
  
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const findedClass = classesState.find(cl => cl.id === classInfo.id)
    if (findedClass && findedClass?.checked) {
      console.log('findedClass', findedClass)
      setChecked(true)
      dispatch(autoMarkupSlice.actions.setClassState({...classInfo, checked: true}))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesState, queryClasses])
  
  const onClassClick = () => {
    if (checked) {
      setChecked(false)
      dispatch(autoMarkupSlice.actions.setClassState({...classInfo, checked: false}))
    }
    if (!checked) {
      setChecked(true)
      dispatch(autoMarkupSlice.actions.setClassState({...classInfo, checked: true}))
    }
  }

  return (
    <StyledMarkupClassWrapper onClick={onClassClick}>
        <InputRadio 
          type="checkbox"
          label={label}  
          checked={checked} 
          onClick={onClassClick}
        />
    </StyledMarkupClassWrapper>

  )
}

export default MarkupClass