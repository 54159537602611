import React, { FC, ReactChild, ReactNode } from 'react'
import { StyledSplitCardText, StyledStplitCardPercents, StyledVersionsSplitCard } from '../../../constants/styled';
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer';

interface ISplitCardProps {
    percent: number;
    children: ReactChild | ReactNode;
    percentColor: string;
    imagesCount: string | number;
}

const VersionSplitCard: FC<ISplitCardProps> = ({percentColor, children, percent, imagesCount, ...props}) => {
  return (
    <StyledVersionsSplitCard>
        <FlexContainer direction='row' justify='space-between' align='center' gap='10px' paddingProps='0 20px'>
            <StyledSplitCardText>{children}</StyledSplitCardText>
            <StyledStplitCardPercents percentColor={percentColor}>{percent}%</StyledStplitCardPercents>
        </FlexContainer>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='10px' paddingProps='0 20px'>
            <h2>{imagesCount ? imagesCount : 0}</h2>
            <h3>Images</h3>
        </FlexContainer>
    </StyledVersionsSplitCard>

  )
}

export default VersionSplitCard