import React, { FC, useState } from 'react'
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer'
import { asyncDeleteLabel, asyncPutLabel } from '../../../../../../../store/reducers/AcyncActions'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import Input from '../../../../../../../UI/Input/Input'
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux'
import { useTranslation } from 'react-i18next'

interface ILabelProps {
  id: string
  color: string
  name: string
  projectId: string
}

const EditLabelForm: FC<ILabelProps> = ({ id, color, name, projectId, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const labels = useAppSelector((state) => state.DrawTabsReducer.labels)

  const [labelName, setLabelName] = useState(name)
  const [labelColor, setLabelColor] = useState(color)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (labels?.length <= 1) {
      return
    }
    dispatch(asyncDeleteLabel(id))
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelName(e.target.value)
  }

  const onColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelColor(e.target.value)
  }

  const onButtonConfirmClick = () => {
    dispatch(asyncPutLabel(labelName, labelColor, id, projectId))
    setModalVisivble()
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="15px">
      <Input label={t('other.name')} placeholder={t('forum.enterText')} value={labelName} onChange={onNameChange} />
      <Input label={t('other.color')} type="color" value={labelColor} onChange={onColorChange} />
      <FlexContainer direction="row" justify="space-around" paddingProps="20px 0 0 0">
        <ProjectButton widthProps="140px" onClick={onDeleteClick} disabled={labels?.length <= 1}>
          {t('other.delete')}
        </ProjectButton>
        <ProjectButton widthProps="140px" onClick={onButtonConfirmClick}>
          {t('other.edit')}
        </ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default EditLabelForm
