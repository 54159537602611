import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { Colors } from '../../models/Colors'
import { IInputProps } from '../../models/IInputProps'
import { ITextAreaProps } from '../../models/ITextAreaProps'
import Tooltip from '../Tooltip/Tooltip'

const StyledTextArea = styled.textarea<ITextAreaProps>`
  width: ${props => props.widthProps || '300px'};
  height:${props => props.heightProps || '35px'};
  padding:${props => props.paddingProps || '4px'};
  background-color:${props => props.backgroundProps || Colors.darker};
  border: 1px solid ${Colors.gray};
  border-color: ${props => props.borderColor || Colors.gray};
  border-radius: 6px;
  color: ${props => props.textColor || Colors.textgray};
  resize: none;
  transition: all 0.1s linear;
  overflow: none;
  &:focus { 
    outline: none;
    transition: all 0.1s linear;
    border: 1px solid ${props => props.focusColor || Colors.orange}
   };
  ::placeholder {
    font-size: 12px;
    color: #4B6379;
  }
`
const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  padding-left: 3px;
  margin-bottom: 5px;
  
  font-size: 16px;
  font-weight: 300;
  
`

const InputText: FC<ITextAreaProps> = ({label, withTooltip, tooltipText, containerWidth, ...props}) => {
  
  return (
    <div style={{overflow: 'none', width: containerWidth || '100%'}}>
      <FlexContainer direction='row' gap='10px' justify='flex-start' paddingProps='0'>
        {label? <StyledLabel labelColor={props.labelColor}>{label}</StyledLabel> : ''}
        {withTooltip? <Tooltip>{tooltipText}</Tooltip> : ''}
      </FlexContainer>
      <StyledTextArea {...props}/>
    </div>
    
  )
}

export default InputText