import React, { useState } from 'react'
import { HotkeyText, StyledHandIcon, StyledHotKeysTitle, StyledHotkeysContent, StyledHotkeysWrapper, StyledKey, StyledPointIcon, StyledPolygonIcon, StyledRectangleIcon, StyledRedoIcon, StyledRightArrow, StyledUndoIcon } from '../../Styled/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../models/Colors'
import { useTranslation } from 'react-i18next'

const HotKeys = () => {
  const { t } = useTranslation()
  const [active, setActive] = useState(false)
  return (
    <StyledHotkeysWrapper active={active} id='kavo' onClick={() => setActive(!active)}>
      <StyledHotKeysTitle>Hotkeys</StyledHotKeysTitle>
      <StyledHotkeysContent active={active} onClick={e => e.stopPropagation()}>
        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>SPACE</StyledKey>
          <StyledRightArrow/>
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.space')}</HotkeyText>
        </FlexContainer>
        
        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>1</StyledKey>
          <StyledRightArrow/>
          <StyledHandIcon disabled={false}/> 
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.1')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>2</StyledKey>
          <StyledRightArrow/>
          <StyledRectangleIcon disabled={false}/> 
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.2')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>3</StyledKey>
          <StyledRightArrow/>
          <StyledPolygonIcon disabled={false}/> 
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.3')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>4</StyledKey>
          <StyledRightArrow/>
          <StyledPointIcon disabled={false}/> 
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.4')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px' widthProps='auto'>
           <StyledKey>CTRL</StyledKey>
           <HotkeyText color={Colors.WHITE}>(Meta)</HotkeyText> 
           <HotkeyText color={Colors.WHITE}>+</HotkeyText> 
           <StyledKey>Z</StyledKey>
          </FlexContainer>
          <StyledRightArrow/>
          <StyledUndoIcon disabled={false}/>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px' widthProps='auto'>
           <StyledKey>CTRL</StyledKey>
           <HotkeyText color={Colors.WHITE}>(Meta)</HotkeyText> 
           <HotkeyText color={Colors.WHITE}>+</HotkeyText> 
           <StyledKey>SHIFT</StyledKey>
          </FlexContainer>
          <StyledRightArrow/>
          <StyledRedoIcon disabled={false}/>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px' widthProps='auto'>
           <StyledKey>A</StyledKey>/<StyledKey>D</StyledKey>
          </FlexContainer>
          <StyledRightArrow/>
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.ad')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px' widthProps='auto'>
           <StyledKey>W</StyledKey>/<StyledKey>S</StyledKey>
          </FlexContainer>
          <StyledRightArrow/>
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.ws')}</HotkeyText>
        </FlexContainer>

        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <StyledKey>BACKSPACE</StyledKey>
          <StyledRightArrow/>
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.backspace')}</HotkeyText>
        </FlexContainer>


        <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px'>
          <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='2px' widthProps='auto'>
            <HotkeyText color={Colors.WHITE} fSize='10px'>{t('authorized.project.draw.drawKeys.drawKeysModerator')}</HotkeyText>
            <StyledKey>Enter</StyledKey>
          </FlexContainer>
          <StyledRightArrow/>
          <HotkeyText color={Colors.orange}>{t('authorized.project.draw.drawKeys.approveMarkup')}</HotkeyText>
        </FlexContainer>

        
        
      </StyledHotkeysContent>
    </StyledHotkeysWrapper>
  )
}

export default HotKeys