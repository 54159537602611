import React, { FC } from 'react'
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from '../../../../../../../models/Colors';
import FlexContainer from './../../../../../../../components/FlexContainer/FlexContainer';
import AnnotatingJobDropDown from './AnnotatingJobDropDown';
import { ISummaryTask } from '../../../../../../../services/TasksService';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { imagesListSlice } from '../../../../../../ImagesList/store/ImagesListReducer';
import { IProject } from '../../../../../../../services/ProjectService';

interface IAnnotatingJobProps {
    date: string;
    projectInfo: IProject;
    navigate: NavigateFunction;
    annotated: string | number;
    unannotated: string | number;
    jobInfo: ISummaryTask;
}

interface IStyledWrapperProps {
    active?: boolean;
}
  
const StyledBatch = styled.div`
  width: 100%;
  height: 195px;
  padding: 10px 35px 10px 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;

  position: relative;

  transition: all 0.1s linear;     

  cursor: pointer;
  &:hover {
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
const StyledDate = styled.h6`
  color: ${Colors.textgray};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledCounter = styled.p`
  color: ${Colors.textgray};
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 20px;
`
const StyledLabeller = styled.h6`
  color: ${Colors.textgray};
  height: 20px;
`
const StyledDropdown = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.1s linear; 
  cursor: pointer;
  :hover img {
    box-shadow: 0 4px 30px rgba(0,0,0,0.25), 0 10px 30px rgba(0,0,0,0.22);
  }
  z-index: 1;
`
const StyledNameFirstLetter = styled.div<IStyledWrapperProps>`
  margin-top: 5px;
  width: 20px;
  height: 20px;
  background-color: ${({ active }) => active ? Colors.dark :  Colors.darker};
  border: 1px solid ${({ active }) => active ? Colors.orange : Colors.lightgray};
  border-radius: 50%;
  padding: 5px;

  font-size: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.1s linear;
  cursor: pointer;
`
export const StyledBr = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${Colors.gray};
`

const StyledName = styled.div`
    height: 20px;
    font-size: 12px;
`

const StyledTeammateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface StyledTaskStatusProps {
  statusName: string;
}
const StyledTaskStatus = styled.div<StyledTaskStatusProps>`
  height: 20px;
  font-size: 12px;
  color: ${props => props.statusName === 'in_pool' ? Colors.deeplightblue 
    : props.statusName === 'in_work' ? Colors.lightgreen 
    : props.statusName === 'on_review' ? Colors.orange
    : props.statusName === 'approved' ? Colors.lightblue
    : props.statusName === 'rejected' ? Colors.red
    : props.statusName === 'processing' ? Colors.lightPurple
    : props.statusName === 'in_queue' ? Colors.lightPurple
    : props.statusName === 'automarkup' ? Colors.lightPurple
    : ''};
`

const AnnotatingJob: FC<IAnnotatingJobProps> = ({date, projectInfo, annotated, unannotated, navigate, jobInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.userReduser.user)
  const userRoleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)

  const onJobClick = () => {
    console.log('userRoleNumber', userRoleNumber)
    if ((jobInfo?.status?.name === 'processing' || 
         jobInfo?.status?.name === 'in_queue'   ||
         jobInfo?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer') ) {
      return
    }
    if ((jobInfo?.status?.name === 'on_review' || 
         jobInfo?.status?.name === 'in_queue'  ||
         jobInfo?.status?.name === 'approved'  ||
         jobInfo?.status?.name === 'in_pool') && userRoleNumber === 6) {
      return
    }
    if ((jobInfo?.status?.name === 'rejected'  || 
         jobInfo?.status?.name === 'in_work'   ||
         jobInfo?.status?.name === 'approved'  ||
         jobInfo?.status?.name === 'in_pool') && userRoleNumber === 5) {
      return
    }
    if (userRoleNumber >= 7) {
      return
    }
    if (jobInfo && projectInfo.id) {
      dispatch(imagesListSlice.actions.setInitialState())
      navigate(`/projects/${projectInfo.id}/job/annotate/${jobInfo.task}`)
    }
  }
  
  return (
    <StyledBatch onClick={onJobClick}>
      <StyledDropdown>
        <AnnotatingJobDropDown projectInfo={projectInfo} jobInfo={jobInfo}/>
      </StyledDropdown>
      <StyledDate>{date}</StyledDate>
      <FlexContainer direction='row' justify='flex-start' align='center' gap='5px'>
        <StyledCounter>{t('other.status')}:</StyledCounter>
        <StyledTaskStatus statusName={jobInfo?.status?.name}>{jobInfo?.status?.displayName}</StyledTaskStatus>
      </FlexContainer>
      
      <StyledCounter>({annotated}) {t('authorized.project.annotate.annotated')}</StyledCounter>
      <StyledCounter>({unannotated}) {t('authorized.project.annotate.unannotated')}</StyledCounter>
      <StyledBr></StyledBr>
      <StyledTeammateWrapper>
        <StyledLabeller>{t('other.labeller')}:</StyledLabeller>
        {jobInfo?.annotator?.email 
          ? 
            <StyledNameFirstLetter active={currentUser?.id === jobInfo?.annotator?.id}>
              {jobInfo?.annotator?.email[0]}
            </StyledNameFirstLetter>
          : ""
        }
        {jobInfo?.annotator?.email 
          ? 
            <FlexContainer direction='column' justify='center'>
              <StyledName>{jobInfo?.annotator?.email}</StyledName>
            </FlexContainer>
          : <StyledName>{t('authorized.project.annotate.notAssigned')}</StyledName> 
        }
      </StyledTeammateWrapper>
      <StyledTeammateWrapper>
        <StyledLabeller>{t('other.moderator')}:</StyledLabeller>
        {jobInfo?.moderator?.email 
          ? 
            <StyledNameFirstLetter active={currentUser?.id === jobInfo?.moderator?.id}>
              {jobInfo?.moderator?.email[0]}
            </StyledNameFirstLetter>
          : ""
        }
        {jobInfo?.moderator?.email 
          ? 
            <FlexContainer direction='column' justify='center'>
              <StyledName>{jobInfo?.moderator?.email}</StyledName>
            </FlexContainer>
          : <StyledName>{t('authorized.project.annotate.notAssigned')}</StyledName> 
        }
      </StyledTeammateWrapper>
    </StyledBatch>
  )
}

export default AnnotatingJob