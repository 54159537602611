import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import PlusBtn from '../../../UI/Buttons/PlusBtn/PlusBtn'
import { StyledBr, StyledHeader } from '../constants/Styled'
import CreateGroupForm from './ModalForms/CreateGroupForm/CreateGroupForm'
import Workspace from './Workspace/Workspace'
import { useAppDispatch, useAppSelector } from './../../../hooks/redux';
import { asyncGetGroupAndUsersInfo, asyncGetGroupProjects, asyncGetLicensesWithoutId, asyncGetOwnerAndMemberGroups } from '../../../store/reducers/AcyncActions'
import { WorkspacesSlice } from '../store/reducers/WorkspacesReducer'
import { modalWindowSlice } from './../../../store/reducers/modalWindowReducer';
import { IShortGroup } from '../../../services/GroupService'
import { CenteredText, ProjectBr } from '../../../styles/GlobalStyles'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import NewGroup from './NewGroup'
import Loader from '../../../UI/Loader/Loader'
import { useTranslation } from 'react-i18next'

interface IWorkSpacesProps {
  setIsManagementMode: React.Dispatch<React.SetStateAction<boolean>>;
  setIsManagementClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledWorkspaces = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  min-width: 260px;

  height: auto;
  padding-bottom: 30px;
  position: sticky;
  top: 25px;

  display: flex;
  flex-direction: column;

  
  padding-bottom: 100px;
  margin-bottom: 15px;
`

const Wrapper = styled.div`
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 15px;
`

const StyledWorkSpacesrapper = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 280px;
  gap: 15px;
  padding-right: 5px;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

const Workspaces: FC<IWorkSpacesProps> = ({setIsManagementMode, setIsManagementClicked, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.userReduser?.user)
  const { ownerGroups, memberGroups } = useAppSelector(state => state.SettingsReducer)
  const isGroupsLoading = useAppSelector(state => state.SettingsReducer.isLoading)

  useEffect(() => {
    dispatch(asyncGetOwnerAndMemberGroups())
    dispatch(asyncGetLicensesWithoutId())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setModalVisible = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }
  
  const onPlusClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groups.createForm.title')))
    dispatch(modalWindowSlice.actions.setChildren(<CreateGroupForm setVisible={setModalVisible}/>))
    dispatch(modalWindowSlice.actions.setVisible())
    setIsManagementMode(false)
    setIsManagementClicked(false)
  }

  const onWorkSpaceClick = (group: IShortGroup) => {
    dispatch(WorkspacesSlice.actions.setGroupClicked(true))
    dispatch(asyncGetGroupAndUsersInfo(group.id))
    dispatch(asyncGetGroupProjects(group.id))
    setIsManagementMode(false)
    setIsManagementClicked(false)
  }

  return (
    <StyledWorkspaces>
        <StyledHeader>
          <h3>{t('authorized.projects.groups.groups')}</h3>
          {currentUser?.role?.name === 'global_admin' || currentUser?.role?.name === 'developer' 
            ? <PlusBtn onClick={onPlusClick}/>
            : ''
          }
        </StyledHeader>
        <StyledBr/>
        {isGroupsLoading 
          ? <FlexContainer justify='center' align='center' heightProps='50%'><Loader/></FlexContainer>
          : <Wrapper>
              <FlexContainer direction='column' justify='flex-start' align='flex-start' gap='5px' paddingProps='0 10px'>
                {ownerGroups?.length > 1 ? t('authorized.projects.groups.ownGroups') : t('authorized.projects.groups.ownGroup')}
                <ProjectBr width='240px' color={Colors.gray}/>
              </FlexContainer>
              <StyledWorkSpacesrapper>
                {ownerGroups?.map( group => 
                  <Workspace key={group.id} id={group.id} title={group.name} count={group.projects} onClick={() => onWorkSpaceClick(group)}/>
                )}
                {!ownerGroups?.length? <NewGroup onClick={onPlusClick}/> : ''}
              </StyledWorkSpacesrapper>
              <FlexContainer direction='column' justify='flex-start' align='flex-start' gap='5px' paddingProps='0 10px'>
                {t('authorized.projects.groups.groupMember')}
                <ProjectBr width='240px' color={Colors.gray}/>
              </FlexContainer>
              <StyledWorkSpacesrapper>
                {!isGroupsLoading && memberGroups?.map( group => 
                  <Workspace key={group.id} id={group.id} title={group.name} count={group.projects} onClick={() => onWorkSpaceClick(group)}/>
                )}
                {!memberGroups?.length 
                  ? 
                    <FlexContainer direction='row' justify='center' align='center' fsize='15px' widthProps='100%'>
                      <CenteredText>{t('authorized.projects.groups.notInvited')}</CenteredText>
                    </FlexContainer> 
                  : ''
                }
              </StyledWorkSpacesrapper>
            </Wrapper>
        } 
    </StyledWorkspaces>
  )
}

export default Workspaces