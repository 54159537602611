import { AxiosResponse } from "axios"
import { $authHost } from "../http"

export interface IAutoMarkupClass {
    id: string;
    name: string;
}

export interface IClassModel {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    usedOnSingleImage: boolean;
    usedOnMultipleImages: boolean;
}

export interface IAutoMarkupClassWithModel {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    checked?:boolean;
    Models: IClassModel[];
}

export interface IAutoMarkupModel {
    id: string;
    name: string;    
}

export interface IModelClass {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    usedOnSingleImage: boolean;
    usedOnMultipleImages: boolean;

    checked: boolean;
    Models: IClassModel[];
}

export interface IAutoMarkupModelWithClass {
    id: string;
    name: string;
    type: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    usedOnSingleImage: boolean;
    usedOnMultipleImages: boolean;
    Classes: IModelClass[];
}

export interface IAutoMarkupTask {
    id: string;
    quantity: number;
    path: string;
    progress: number;
    remarkup: boolean;
    classes: IAutoMarkupClass[];
    models: IAutoMarkupModel[];
    status: string;
}

//Auto Markups
export interface IModelToCreateTask {
    id: string;
    name: string;
    textPrompts?: string[];
    classes?: {
        id: string;
        name: string;
    }[]; 
}

export interface IAutoMarkupTaskToCreate {
    service: string;
    taskId: string;
    quantity: number;
    demo?: number;
    remarkup: boolean;
    imagesTypeList: string | undefined;
    createNewLabel?: boolean;
    models: IModelToCreateTask[];
}



export interface ICreateAutoMarkupTaskResponce {
    job: string;
    service: string;
    message: string;
}

export interface IGetAutoMarkupTasksSummaryResponce {
    tasks: IAutoMarkupTask[];
}

export interface IGetAutoMarkupTaskByIdResponce {
    taskId?:string;
    message?:string;
    progress?:string;
    error?:string;
}

interface IDeleteRecponce {
    message: string;
}

//Auto Markups Classes
export interface IAutoMarkupClassToCreate {
    name: string;
    displayName: string;
    modelId: string;
}

export interface ICreateAutoMarkupClassResponce {
    class: {
        id: string;
        name: string;
        displayName: string;
        updatedAt: string;
        createdAt: string;
    }
    message: string;
}

export interface IGetAutoMarkupClassesSummaryResponce {
    classes: IAutoMarkupClassWithModel[];
    total: number;
    remain: number;
}

export interface IGetAutoMarkupClassById {
    class: IAutoMarkupClassWithModel;
}

//Auto Markups Models
export interface IAutoMarkupModelToCreate {
    name: string;
    displayName: string;
}

export interface ICreateAutoMarkupModelResponce {
    model: {
        id: string;
        name: string;
        displayName: string;
        updatedAt: string;
        createdAt: string;
    }
    message: string;
}

export interface IGetAutoMarkupModelsSummaryResponce {
    models : IAutoMarkupModelWithClass[];
    total: number;
    remain: number;
}

export interface IGetAutoMarkupModelByIdResponce {
    model: IAutoMarkupModelWithClass;
}

export interface ICheckImagesResReq {
    images: string[]
}

export default class AutoMarkupService {
    //Auto Markups
    static async createAutoMarkupTask(autoMarkupTask: IAutoMarkupTaskToCreate): Promise<AxiosResponse<ICreateAutoMarkupTaskResponce>> {
        return $authHost.post<ICreateAutoMarkupTaskResponce>('/automarkup/', autoMarkupTask)
    }
    static async getAutoMarkupTasksSummary(): Promise<AxiosResponse<IGetAutoMarkupTasksSummaryResponce>> {
        return $authHost.get<IGetAutoMarkupTasksSummaryResponce>('/automarkup/')
    }
    static async getAutoMarkupTaskById(id: string): Promise<AxiosResponse<IGetAutoMarkupTaskByIdResponce>> {
        return $authHost.get<IGetAutoMarkupTaskByIdResponce>(`/automarkup/${id}`)
    }
    static async deleteAutoMarkupTaskById(id: string): Promise<AxiosResponse<IDeleteRecponce>> {
        return $authHost.delete<IDeleteRecponce>(`/automarkup/${id}`)
    }
    static async deleteAutoMarkupQueue(): Promise<AxiosResponse<IDeleteRecponce>> {
        return $authHost.delete<IDeleteRecponce>('/automarkup/clear')
    }

    //Проверка изображений, есть ли там хоть 1 аннотированное ._.
    static async checkImages(payload: ICheckImagesResReq): Promise<AxiosResponse<ICheckImagesResReq>> {
        return $authHost.post<ICheckImagesResReq>('/automarkup/check', payload)
    }

    //Auto Markups Classes
    static async createAutoMarkupClass(autoMarkupClass: IAutoMarkupClassToCreate): Promise<AxiosResponse<ICreateAutoMarkupClassResponce>> {
        return $authHost.post<ICreateAutoMarkupClassResponce>('/automarkup-classes/', autoMarkupClass)
    }
    static async getAutoMarkupClassesSummary(): Promise<AxiosResponse<IGetAutoMarkupClassesSummaryResponce>> {
        return $authHost.get<IGetAutoMarkupClassesSummaryResponce>('/automarkup-classes/?limit=100000000&displayModel=true')
    }
    static async getAutoMarkupClassById(id: string): Promise<AxiosResponse<IGetAutoMarkupClassById>> {
        return $authHost.get<IGetAutoMarkupClassById>(`/automarkup-classes/${id}`)
    }

    //Auto Markups Models
    static async createAutoMarkupModel(value: IAutoMarkupModelToCreate): Promise<AxiosResponse<ICreateAutoMarkupModelResponce>> {
        return $authHost.post<ICreateAutoMarkupModelResponce>('/automarkup-models/', value)
    }
    static async getAutoMarkupModelsSummary(): Promise<AxiosResponse<IGetAutoMarkupModelsSummaryResponce>> {
        return $authHost.get<IGetAutoMarkupModelsSummaryResponce>('/automarkup-models/?limit=100000000&displayClass=true')
    }
    static async getAutoMarkupModelById(id: string): Promise<AxiosResponse<IGetAutoMarkupModelByIdResponce>> {
        return $authHost.get<IGetAutoMarkupModelByIdResponce>(`/automarkup-models/${id}`)
    }

}