import React from 'react'
import { ICoinsOperationProps } from '../../constants/interfaces'
import { StyledProjectTableItem, StyledTableItemWrapper } from '../../../../styles/GlobalStyles'
import ClipBoardCopyText from '../../../../UI/ClipboardText/ClipboardText'

const CoinsOperation: React.FC<ICoinsOperationProps> = ({operation}) => {
  return (
    <StyledTableItemWrapper>
      <ClipBoardCopyText maxWidth='80px'>
        {operation.id}
      </ClipBoardCopyText>
      <StyledProjectTableItem>
        {operation.createdAt.slice(0, 10)}
      </StyledProjectTableItem>
      <StyledProjectTableItem>
        {operation.type}
      </StyledProjectTableItem>
      <StyledProjectTableItem>
        {operation.amount}
      </StyledProjectTableItem>
    </StyledTableItemWrapper>
  )
}

export default CoinsOperation