import React, { FC } from 'react'
import styled from 'styled-components'
import EditLabelForm from './EditLabelForm'
import { Colors } from '../../../../../../../models/Colors'
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer'
import { useAppDispatch } from '../../../../../../../hooks/redux'
import { useTranslation } from 'react-i18next'

interface ILabelProps {
  id: string
  color: string
  name: string
  projectId: string
  isDataset?: boolean
}

interface ILabelNameProps {
  colorProps: string
}

const StyledLabelName = styled.span<ILabelNameProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  border-color: ${({ colorProps }) => (colorProps ? colorProps : Colors.textgray)};
  min-width: 50px;
  height: 8;
  font-size: 12px;
  font-weight: bold;

  width: 100%;
  padding: 2px 5px;

  cursor: pointer;
  transition: all 0.1s linear;

  :hover {
    color: ${({ colorProps }) => (colorProps ? colorProps : Colors.textgray)};
    background-color: ${Colors.dark};
  }
`
const StyledLabelColor = styled.div<ILabelNameProps>`
  width: 8px;
  height: 8px;
  border-radius: 15px;
  background-color: ${({ colorProps }) => (colorProps ? colorProps : Colors.textgray)};
`

const Label: FC<ILabelProps> = ({ id, color, name, projectId, isDataset, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onLabelCLick = () => {
    if (!isDataset) {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.editClass')))
      dispatch(modalWindowSlice.actions.setChildren(<EditLabelForm id={id} color={color} name={name} projectId={projectId} />))
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }

  return (
    <StyledLabelName colorProps={color} onClick={onLabelCLick}>
      {name}
      <StyledLabelColor colorProps={color} />
    </StyledLabelName>
  )
}

export default Label
