import React, { useState } from 'react'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { useTranslation } from 'react-i18next'
import ProjectButton from '../Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../hooks/redux'
import { modalWindowSlice } from '../../store/reducers/modalWindowReducer'
import InputText from '../InputText/InputText'
import { $authHost } from '../../http'
import Input from '../Input/Input'
import { StyledSlideP } from '../../modules/Draw/Styled/styled'
import Loader from '../Loader/Loader'

const SendMailForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [text, setText] = useState('')
  const [error, setError] = useState('')
  const [subject, setSubject] = useState('')
  const [isLoading, setIsLoadind] = useState(false)
  const [messageRes, setMessageRes] = useState('')

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onSendClick = async () => {
    if (text) {
      try {
        setIsLoadind(true)
        const responce = await $authHost.post('support/send-mail', {subject: subject, message: text})
        setMessageRes(responce.data.message)
        setIsLoadind(false)
      } catch (error) {
        setIsLoadind(false)
        setError(t('errors.serverError'))
      }
    }
  }

  return (
    <FlexContainer direction='column' justify='space-between' align='center' gap='15px'>
        {!messageRes && !isLoading && !error
          ? 
            <FlexContainer direction='column' justify='space-between' align='center' gap='15px' paddingProps='0 20px'>
              <Input 
                label={t('other.supportSubject')} 
                placeholder={t('forum.enterText')} 
                value={subject} 
                onChange={(e) => setSubject(e.target.value)}
                withTooltip
                tooltipText={t('other.supportFeedback')}
                widthProps='100%'
               />
              <InputText 
                value={text} 
                label={t('other.supportInputTitle')} 
                onChange={(e) => setText(e.target.value)} 
                placeholder={t('forum.enterText')}
                widthProps='100%'
                heightProps='140px'
              />
            </FlexContainer>
          : ''
        }
        {messageRes && !isLoading && !error ? <FlexContainer direction='row' justify='center' align='center'><StyledSlideP>{messageRes}</StyledSlideP></FlexContainer> : ''}
        {!messageRes && isLoading && !error ? <Loader/> : ''}
        {!messageRes && !isLoading && error ? <FlexContainer direction='row' justify='center' align='center'><StyledSlideP>{error}</StyledSlideP></FlexContainer>: ''}
        <FlexContainer direction='row' justify='center' align='center' gap='15px'>
          <ProjectButton widthProps='135px' FontSizeProps='16px' onClick={onCancelClick}>{t('buttons.close')}</ProjectButton>
          {messageRes || isLoading? '' : <ProjectButton widthProps='135px' disabled={!text} FontSizeProps='16px' onClick={onSendClick}>{t('buttons.send')}</ProjectButton>}
        </FlexContainer>
    </FlexContainer>
  )
}

export default SendMailForm