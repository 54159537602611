import { useMemo } from 'react'

import { IProjectUserFullInfo } from '../modules/Projects/store/reducers/WorkspacesReducer'

export const UseProjectUsersQuery = (userInfo: IProjectUserFullInfo[], query: string) => {
  const searchedClasses = useMemo(() => {
    if (!userInfo?.length) {
      return null
    }
    if (userInfo?.length) {
      return userInfo.filter(
        (userInfo) => 
          userInfo?.user?.email?.toLowerCase()?.includes(query?.toLowerCase()) || 
          userInfo?.user?.firstName?.toLowerCase()?.includes(query?.toLowerCase())
      )
    }
  }, [query, userInfo])

  if (query) {
    return searchedClasses
  }
  if (!query) {
    return userInfo
  }
}
