import React, { FC, useState } from 'react'
import { ITooltipProps } from './ITooltipProps'
import styled from 'styled-components'
import { Colors } from '../../models/Colors'

const StyledTooltip = styled.div<ITooltipProps>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${Colors.lightgray};
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  border: 1px solid ${Colors.lightgray};
  border-radius: 50%;
  background-color: ${Colors.gray};

  width: 16px;
  height: 16px;

  cursor: pointer;

  :hover {
    color: ${Colors.orange};
    border-color: ${Colors.orange};
    background-color: ${Colors.dark};
  }

  ::after {
    content: '';
    display: ${({ visible }) => (visible ? 'block' : 'none')};

    position: absolute;
    bottom: 20px;
    z-index: 3000;

    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${Colors.orange};
  }
`
const StyledTooltipContent = styled.div<ITooltipProps>`
  overflow-wrap: break-word;

  position: absolute;
  bottom: 30px;
  left: ${({ leftTooltip }) => (leftTooltip ? '-200px' : '-20px')};
  z-index: 3000;

  width: 240px;
  height: min-content;
  padding: 10px;

  background-color: ${Colors.dark};
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  border: 1px solid ${Colors.orange};
  border-radius: 15px;
`
const StyledSpan = styled.span`
  width: 22px;
  height: 22px;
  text-align: center;
`

const Tooltip: FC<ITooltipProps> = ({ children, leftTooltip, ...props }) => {
  const [display, setDisplay] = useState(false)

  const onTooltipHover = () => {
    setDisplay(!display)
  }
  const onTooltipLeave = () => {
    setDisplay(false)
  }

  return (
    <StyledTooltip visible={display}>
      <StyledSpan onMouseOver={onTooltipHover} onMouseLeave={onTooltipLeave}>
        i
      </StyledSpan>
      <StyledTooltipContent visible={display} leftTooltip={leftTooltip}>
        {children}
      </StyledTooltipContent>
    </StyledTooltip>
  )
}

export default Tooltip
