import React, { useEffect, useState } from 'react'
import { IArchive } from '../../../../../services/VersionsService'
import { StyledArchiveName, StyledArchiveWrapper, StyledExportFormText, StyledLink, StyledTimerWrapper, StyledTooltip } from '../../../constants/styled'
import moment from 'moment'
import { useAppDispatch } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import styled from 'styled-components'
import { Colors } from '../../../../../models/Colors'
import crossIcon from '../../../../../assets/icons/cross.svg'
import { getNoun } from '../../../../../utils/getNoun'
import { asyncDeleteArchive } from '../../../../../store/reducers/AcyncActions'
import axios from 'axios'
import { userSlice } from '../../../../../store/reducers/userReducer'
import DownloadProgressBar from '../../../../../UI/DownloadProgressBar/DownloadProgressBar'
import { useTranslation } from 'react-i18next'

interface IVersionArchiveProps {
  archive: IArchive
}

export const StyledTooltipWrapper = styled.div`
  position: relative;
  height: 22px;
  :hover ${StyledTooltip} {
    display: 'block';
  }
`

export const StyledCrossIcon = styled.i`
  display: block;
  position: relative;
  z-index: 2;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${crossIcon});
  mask-image: url(${crossIcon});
  background-color: ${Colors.red};
  width: 22px;
  height: 22px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.1s linear;
  :hover {
    background-color: ${Colors.lightred};
  }
`

const VersionArchive: React.FC<IVersionArchiveProps> = ({ archive, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [deleteTime, setDeleteTime] = useState('n')
  const lang = process.env.REACT_APP_LANGUAGE

  const $downloadAuthHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'blob',
    onDownloadProgress: function (progressEvent: any) {
      const percentComplete = Math.floor((progressEvent.loaded / progressEvent.total) * 100)
      dispatch(userSlice.actions.setPercentComplete(percentComplete))
    }
  })

  const onCanselClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDeleteClick = () => {
    dispatch(asyncDeleteArchive(archive.id, archive.versionId))
  }

  const onCrossClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitleColor(Colors.red))
    dispatch(modalWindowSlice.actions.setTitle(t('other.deletion')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="column" justify="center" align="center" gap="30px">
          <StyledExportFormText>Удалить архив {archive.filename} ?</StyledExportFormText>
          <FlexContainer direction="row" justify="space-between" paddingProps="0 20px">
            <ProjectButton onClick={onCanselClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onDeleteClick}>{t('other.delete')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      )
    )
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onArchiveClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.archiveDownload')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="column" justify="center" align="center" gap="30px">
          <StyledExportFormText>{t('authorized.project.generateAndVersions.downloadArchive')} {archive.filename} ?</StyledExportFormText>
          <FlexContainer direction="row" justify="space-between" paddingProps="0 20px">
            <ProjectButton onClick={onCanselClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onDownloadClick}>{t('buttons.continue')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      )
    )
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDownloadClick = async () => {
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="column" justify="center" align="center" gap="30px">
          <DownloadProgressBar />
        </FlexContainer>
      )
    )
    $downloadAuthHost
      .get<any>(`/download/${archive.id}`)
      .then((response) => {
        const href = URL.createObjectURL(response.data)
        window.open(href, '_blank')
        dispatch(
          modalWindowSlice.actions.setChildren(
            <FlexContainer direction="column" justify="center" align="center" gap="30px">
              <StyledExportFormText>{t('authorized.project.generateAndVersions.exportText1')}</StyledExportFormText>
              <StyledLink href={href} id="link">
                {t('authorized.project.generateAndVersions.exportText2')}
              </StyledLink>
              <ProjectButton onClick={onCanselClick} widthProps="150px">
                {t('buttons.close')}
              </ProjectButton>
            </FlexContainer>
          )
        )
        dispatch(userSlice.actions.setPercentComplete(0))
      })
      .catch((error) => {
        console.log('onDownloadClick', error)
        dispatch(
          modalWindowSlice.actions.setChildren(
            <FlexContainer direction="column" justify="center" align="center" gap="30px">
              <StyledExportFormText>{t('errors.serverError')}</StyledExportFormText>
              <ProjectButton onClick={onCanselClick} widthProps="150px">
                {t('buttons.close')}
              </ProjectButton>
            </FlexContainer>
          )
        )
      })
  }

  useEffect(() => {
    if (archive?.createdAt) {
      if (archive.downloaded) {
        const duration = moment.duration(moment().diff(archive.createdAt))
        const mins = Math.round(duration.asMinutes())
        const stringTime = (120 - mins).toString()
        if (lang === 'ru') {
          setDeleteTime(stringTime + getNoun(+stringTime, ' минута', ' минуты', ' минут'))
        } else {
          setDeleteTime(stringTime + getNoun(+stringTime, ' min', ' min', ' min'))
        }

        return
      }
      if (!archive.downloaded) {
        const duration = moment.duration(moment().diff(archive.createdAt))
        const hours = Math.round(duration.asHours())
        const stringTime = (24 - hours).toString()
        if (+stringTime <= 2) {
          const minutesFromHours = (+stringTime * 60).toString()
          if (lang === 'ru') {
            setDeleteTime(minutesFromHours + ' минут' + getNoun(+minutesFromHours, ' минута', ' минуты', ' минут'))
          } else {
            setDeleteTime(minutesFromHours + ' min' + getNoun(+minutesFromHours, ' min', ' min', ' min'))
          }

          return
        }
        if (lang === 'ru') {
          setDeleteTime(stringTime + ' ' + getNoun(+stringTime, 'час', 'часа', 'часов'))
        } else {
          setDeleteTime(stringTime + ' ' + getNoun(+stringTime, 'hour', 'hours', 'hours'))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archive?.createdAt])

  return (
    <StyledArchiveWrapper>
      <StyledArchiveName onClick={onArchiveClick}>{archive.filename}</StyledArchiveName>
      <StyledTimerWrapper>
        <span>
          {t('authorized.project.generateAndVersions.automaricDelete')} {deleteTime}
        </span>
        <StyledCrossIcon onClick={onCrossClick} />
      </StyledTimerWrapper>
    </StyledArchiveWrapper>
  )
}

export default VersionArchive
