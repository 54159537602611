import React from 'react'
import { StyledNewGroup, StyledNewGroupTitle } from '../constants/Styled'
import { useTranslation } from 'react-i18next';

interface INewGroupProps {
  onClick: () => void;
}

const NewGroup: React.FC<INewGroupProps> = ({...props}) => {
  const { t } = useTranslation()
  return (
    <StyledNewGroup activeProps={false} {...props}>
      <StyledNewGroupTitle activeProps={false}>{t('authorized.projects.groups.newGroup')}</StyledNewGroupTitle>
    </StyledNewGroup>
  )
}

export default NewGroup