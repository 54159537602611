import React, { FC, useState } from 'react'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../../UI/Input/Input'
import { asyncPutVersion } from '../../../../../store/reducers/AcyncActions'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { useAppDispatch } from '../../../../../hooks/redux'
import { IFullVersionWithArchive } from '../../../store/reducers/VersionsReducer'
import { useTranslation } from 'react-i18next'

interface IRenameVersionFormProps {
  versionInfo: IFullVersionWithArchive
}

const RenameVersionForm: FC<IRenameVersionFormProps> = ({ versionInfo, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [versionName, setVersionName] = useState('')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVersionName(e.target.value)
  }

  const onButtonRenameClick = () => {
    dispatch(asyncPutVersion(versionInfo.id, versionName))
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="15px">
      <Input label={t('authorized.project.annotate.forms.newNameTitle')} placeholder={t('forum.enterText')} value={versionName} onChange={onNameChange} />
      <FlexContainer direction="row" justify="space-around" paddingProps="20px 0 0 0">
        <ProjectButton onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onButtonRenameClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameVersionForm
