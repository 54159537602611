import React, { FC } from 'react'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { IGetCategoryByIdResponce, ISubcategory } from '../../../../../services/ForumService'
import DeleteCategoryForm from './DeleteCategoryForm'
import RenameCategoryForm from './RenameCategoryForm'
import EditDescriptionForm from './EditDescriptionForm'
import CreateCategoryForm from '../../ForumMain/CreateCategoryForm'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IEditCategoryFormProps {
    categoryInfo: IGetCategoryByIdResponce;
    isSub: boolean;
    subCategoryInfo?: ISubcategory;
    navigate: NavigateFunction;
}

const EditCategoryFormMain: FC<IEditCategoryFormProps> = ({categoryInfo, navigate, isSub, subCategoryInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onRenameClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Rename Category'))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<RenameCategoryForm isSub={isSub} navigate={navigate} categoryInfo={categoryInfo} subInfo={subCategoryInfo}/>))
  }

  const onDeleteClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Delete Category?'))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<DeleteCategoryForm isSub={isSub} navigate={navigate} categoryInfo={categoryInfo} subCategoryInfo={subCategoryInfo}/>))
  }

  const onEditClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Rename Category'))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<EditDescriptionForm isSub={isSub} navigate={navigate} categoryInfo={categoryInfo} subInfo={subCategoryInfo}/>))
  }

  const onCreateClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Create Category'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<CreateCategoryForm categoryInfo={categoryInfo} isSub={isSub}/>))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
  }

  const onCreateTopicClick = () => {
    navigate(`/forum/create/${categoryInfo.category.id}/create-topic/`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction='column' justify='center' align="center" gap='20px'>
      <FlexContainer direction='row' justify='space-between' align='center' gap='20px' paddingProps='0 10px'>
        <h4>{t('other.delete')}</h4>
        <ProjectButton onClick={onDeleteClick}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
      <FlexContainer direction='row' justify='space-between' align='center' gap='20px' paddingProps='0 10px'>
        <h4>{t('other.rename')}</h4>
        <ProjectButton onClick={onRenameClick}>{t('other.rename')}</ProjectButton>
      </FlexContainer>
      {isSub &&       
        <FlexContainer direction='row' justify='space-between' align='center' gap='20px' paddingProps='0 10px'>
            <h4>{t('authorized.project.assign.description')}</h4>
            <ProjectButton onClick={onEditClick}>{t('other.edit')}</ProjectButton>
        </FlexContainer>
      }
      {!isSub &&       
        <FlexContainer direction='row' justify='space-between' align='center' gap='20px' paddingProps='0 10px'>
            <h4>{t('forum.createSubCategory')}</h4>
            <ProjectButton onClick={onCreateClick}>{t('buttons.create')}</ProjectButton>
        </FlexContainer>
      }
      {!isSub &&       
        <FlexContainer direction='row' justify='space-between' align='center' gap='20px' paddingProps='0 10px'>
            <h4>{t('forum.createTopic')}</h4>
            <ProjectButton onClick={onCreateTopicClick}>{t('buttons.create')}</ProjectButton>
        </FlexContainer>
      }
      <FlexContainer direction='row' justify='center' align='center' gap='50px'>
        <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>{t('buttons.cancel')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default EditCategoryFormMain