import React from 'react'
import './loadingText.css'

const LoadingText = () => {
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h4 className='loadingText'></h4>
  )
}

export default LoadingText