import React, { FC, ReactChild, ReactNode } from 'react'
import { StyledCardStat, StyledCardTitle, StyledHealthCardWrapper } from '../../../constants/styled'

interface IHealthCardWrapperProps {
    children: ReactChild | ReactNode;
    title: string;
    stat: string | number;

}

const HealthCard: FC<IHealthCardWrapperProps> = ({children, title, stat}) => {
  return (
    <StyledHealthCardWrapper>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardStat>{stat}</StyledCardStat>
        {children}
    </StyledHealthCardWrapper>
  )
}

export default HealthCard