import React from 'react'
import { StyledAttrCardHeader, StyledAttributesWrapper } from '../../Styled/styled'
import { useAppSelector } from '../../../../hooks/redux'
import { StyledAttentionIcon, StyledImageIcon, StyledAttrCardText} from '../../Styled/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useTranslation } from 'react-i18next'

const DrawAttributes = () => {
  const { t } = useTranslation()
  const imageInfo = useAppSelector(state => state.DrawTabsReducer.imageInfo)

  return (
    <StyledAttributesWrapper>
      <FlexContainer direction='row' justify='flex-start' align='center' gap='10px'>
          <StyledImageIcon/>
          <FlexContainer direction='column' justify='flex-start' align='flex-start'>
            <StyledAttrCardHeader>{imageInfo?.image?.displayname || 'ImageName'}</StyledAttrCardHeader>
            <FlexContainer direction='row' justify='flex-start' align='center'>
              <StyledAttrCardText>{imageInfo?.image?.width + ' X ' + imageInfo?.image?.height}</StyledAttrCardText>
              <StyledAttrCardText>{imageInfo?.image?.sizeInMegaPixels + ' MP'}</StyledAttrCardText>
            </FlexContainer>
          </FlexContainer>
      </FlexContainer>

      <FlexContainer direction='row' justify='flex-start' align='center' gap='10px'>
          <StyledAttentionIcon/>

          <FlexContainer direction='column' justify='flex-start' align='flex-start'>
            <StyledAttrCardHeader>{t('other.status')}</StyledAttrCardHeader>
            <FlexContainer direction='row' justify='flex-start' align='center' gap='8px'>
              <StyledAttrCardText>{imageInfo?.image?.status || "Annotating"}</StyledAttrCardText>
            </FlexContainer>
          </FlexContainer>
      </FlexContainer>

      
    </StyledAttributesWrapper>
  )
}

export default DrawAttributes