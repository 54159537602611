import React, { useEffect } from 'react'
import UsersLineChart from '../../../../../UI/UsersLineChart/UsersLineChart';
import { IReducerStatAction } from '../../../store/reducers/StatisticsReducer';
import { StatisticsOption } from './ProjectStatistics';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface IStatOption {
  type: string;
  title: string;
  value: string;
  selected: boolean;  
}

interface IChartsFromSelectedProps {
  option: IStatOption;
  selectedUsers: string[];
  filteredUsersCounter: string | number;
  actionStats: {
    [key: string]: IReducerStatAction;
  }
  selectedRole: StatisticsOption | null;
}

const StyledChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`

const StyledCheckboxWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: -3px;

  font-size: 14px;
`

const StyledInput = styled.input`
  width: 15px;
  height: 15px;
`

const ChartFromSelected: React.FC<IChartsFromSelectedProps> = ({option, selectedUsers, filteredUsersCounter, actionStats, selectedRole}) => {
  const { t } = useTranslation()
  const [datasets, setDatasets] = React.useState<any>([])
  const [checked, setChecked] = React.useState(false)
  const [minMax, setMinMax] = React.useState<any[]>([])

  useEffect(() => {
    if (selectedUsers && actionStats && actionStats?.[option.value] && selectedRole) {
      const minMax = checked 
        ? [actionStats?.[option.value]?.minDataset, actionStats?.[option.value]?.maxDataset]
        : []
      setMinMax(minMax)
      if (selectedUsers.length === 1) {
       const findedDataset = actionStats?.[option.value]?.datasets?.find((dataset: any) => dataset?.userId === selectedUsers[0] && dataset?.userRole === selectedRole?.value)
       if (findedDataset) {
        setDatasets([findedDataset, ...minMax])
       }
      }
      if (selectedUsers.length === 2) {
        const filteredDatasets = actionStats?.[option.value]?.datasets?.filter((dataset: any) => dataset !== undefined)
        console.log('filteredDatasets', filteredDatasets)
        const findedFirstUserDataset = actionStats?.[option.value]?.datasets?.find((dataset: any) => dataset?.userId === selectedUsers[0] && dataset?.userRole === selectedRole?.value)
        const findedSecondUserDataset = actionStats?.[option.value]?.datasets?.find((dataset: any) => dataset?.userId === selectedUsers[1] && dataset?.userRole === selectedRole?.value)
        if (findedFirstUserDataset && findedSecondUserDataset) {
          setDatasets([findedFirstUserDataset, findedSecondUserDataset, ...minMax])
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers, filteredUsersCounter, actionStats, selectedRole, checked])

  return (
    <StyledChartWrapper>
      <StyledCheckboxWrapper>
        <StyledInput type='checkbox' checked={checked} onClick={() => setChecked(!checked)}/>
        <span>{t('authorized.project.statistics.minMax')}</span>
      </StyledCheckboxWrapper>
      <UsersLineChart 
        datasets={datasets} 
        labels={actionStats?.[option.value]?.dates ? actionStats?.[option.value]?.dates : []} 
        displayLegend 
        heightProps='300px' 
        displayTitle 
        chartTitle={option.title}
      />
    </StyledChartWrapper>
  )
}

export default ChartFromSelected