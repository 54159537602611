import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux"
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledLoadingText, StyledModalWindowTitle } from '../../../../Styled/styled'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import Loader from '../../../../../../UI/Loader/Loader'
import { useNavigate } from 'react-router-dom'
import { autoMarkupSlice } from '../../../../store/AutoMarkupSlice'
import { useTranslation } from 'react-i18next'

interface ILoadingSlideProps {}
  
const LoadingSlide: FC<ILoadingSlideProps> = ({...props}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const progress = useAppSelector(state => state.AutoMarkupReducer.progress)
  const currentTask = useAppSelector(state => state.tasksReducer.currentTask)

  useEffect(() => {
    dispatch(autoMarkupSlice.actions.setImagesToSegment([]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCloseClick = () => {
    if (currentTask?.task?.id) {
      dispatch(autoMarkupSlice.actions.setInitialState())
      dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(true))
      navigate(`/projects/${currentTask.task.project}/annotate`)
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }
  
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='30px' paddingProps='10px'>
      <Loader/>
      <StyledLoadingText>
        {progress || 0}%
      </StyledLoadingText>
      <StyledModalWindowTitle>
        {t('authorized.project.draw.automarkupTool.progressP')} <br/><br/>
        {t('authorized.project.draw.automarkupTool.progressP2')}
      </StyledModalWindowTitle>
      <FlexContainer direction='row' justify='space-around'>
        <ProjectButton onClick={onCloseClick}>{t('buttons.goToAnnotate')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}
  
export default LoadingSlide