import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../models/Colors';
import { ICreateProjectButtonProps } from '../../models/ICreateProjectButtonProps';
import { useTranslation } from 'react-i18next';

const StyledCreateProjectButton = styled.div<ICreateProjectButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;


  width: 330px;
  height: 110px;
  background: #2B2B2B;
  border-radius: 50px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;

  border: 1px solid ${Colors.gray};
  cursor: pointer;

  transition: all 0.2s linear;     

  :hover {
    border-color: ${Colors.orange};
    color: ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 370px; 
  height: 150px;
`

const CreateProjectButton: FC<ICreateProjectButtonProps> = ({...props}) => {
  const { t } = useTranslation()
  return (
    <StyledButtonWrapper>
      <StyledCreateProjectButton {...props}>
        + {t('authorized.projects.groupProjects.createProject')}
      </StyledCreateProjectButton>
    </StyledButtonWrapper>
  )
}

export default CreateProjectButton