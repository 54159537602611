import React, { FC, useEffect, useState } from 'react'
import { StyledCommentsIcon, StyledEdit, StyledEditIcon, StyledNoMessage, StyledSubCategory, StyledSubCategoryDescription, StyledSubCategoryHeader, StyledSubCategoryTopics, StyledTextButton } from '../../constants/styled';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import LastSubCategoryPost from './LastSubCategoryPost';
import { IGetCategoryByIdResponce, ISubcategory } from '../../../../services/ForumService';
import { useAppDispatch } from '../../../../hooks/redux';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import EditCategoryFormMain from './EditCategoryForm/EditCategoryFormMain';
import { NavigateFunction } from 'react-router-dom';
import { IUsersMeResponse } from '../../../../services/UserService';
import { useTranslation } from 'react-i18next';

interface ISubCategoryProps {
    subCategoryInfo: ISubcategory;
    categoryInfo: IGetCategoryByIdResponce;
    user: IUsersMeResponse;
    navigate: NavigateFunction;
}

const SubCategory: FC<ISubCategoryProps> = ({subCategoryInfo, categoryInfo, user, navigate, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [uppercaseFirstLetter, setUppercaseFirstLetter] = useState(subCategoryInfo.name)

  useEffect(() => {
    if (subCategoryInfo.name) {
      setUppercaseFirstLetter(uppercaseFirstLetter.charAt(0).toUpperCase() + uppercaseFirstLetter.slice(1))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onSubCategoryClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    window.scrollTo(0, 0)
    navigate(`/forum/${categoryInfo.category.id}/${subCategoryInfo.id}/topics`)
  }

  const onLastTopicClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setTitle('Edit Category'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<EditCategoryFormMain isSub={true} categoryInfo={categoryInfo} subCategoryInfo={subCategoryInfo} navigate={navigate}/>))
    dispatch(modalWindowSlice.actions.setVisible())
}

  return (
    <StyledSubCategory onClick={onSubCategoryClick}>
      <StyledCommentsIcon/>
      <FlexContainer direction='column' maxwidthProps='650px' widthProps='100%'>
        <StyledSubCategoryHeader>{subCategoryInfo.name.charAt(0).toUpperCase() + subCategoryInfo.name.slice(1)}</StyledSubCategoryHeader>
        <StyledSubCategoryDescription>{subCategoryInfo.description}</StyledSubCategoryDescription>
      </FlexContainer>
      <FlexContainer direction='column' widthProps='200px' justify='center' align='center'>
        <StyledSubCategoryTopics>{t('forum.topics')}</StyledSubCategoryTopics>
        <StyledSubCategoryDescription>{subCategoryInfo.topicsCount}</StyledSubCategoryDescription>
      </FlexContainer>
      {/* <FlexContainer direction='column'>
        <StyledSubCategoryTopics>Messages</StyledSubCategoryTopics>
        <StyledSubCategoryDescription>{subCategoryInfo.messagesCount}</StyledSubCategoryDescription>
      </FlexContainer> */}
      {subCategoryInfo?.LatestTopic 
        ? <LastSubCategoryPost subCategoryInfo={subCategoryInfo} onClick={onLastTopicClick}/>
        : <StyledNoMessage>{t('other.empty')} :(</StyledNoMessage>
      }
      
      {user?.role?.name === 'global_admin' || user?.role?.name === 'developer'
        ? <StyledEdit onClick={onEditClick}>
            <StyledEditIcon/>
            <StyledTextButton>{t('other.edit')}</StyledTextButton>
          </StyledEdit>
        : ''
      }
    </StyledSubCategory>
  )
}

export default SubCategory