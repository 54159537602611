import React, { useEffect, useState } from 'react'
import { StyledCoinsIcon, StyledContentCardWrapper, StyledSettingsTabContent } from '../../constants/styled'
import { IBlockType, ISettingsContentProps } from '../../constants/interfaces'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import Loader from '../../../../UI/Loader/Loader'
import ContentSection from './ContentSection'
import SettingsUserCard from './SettingsUserCard'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { asyncGetAllGroups, asyncGetGroupAndUsersInfo, asyncGetGroupProjects, asyncGetLicenses } from '../../../../store/reducers/AcyncActions'
import { WorkspacesSlice } from '../../../Projects/store/reducers/WorkspacesReducer'
import { SettingsReducer } from '../../reducers/SettingsReducer'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import Transactions from '../Transactions/Transactions'
import Licenses from '../Licenses/Licenses'
import TopUpBalanceForm from '../TopUpBalanceForm/TopUpBalanceForm'
import GroupSettings from '../GroupSettings/GroupSettings'
import { useTranslation } from 'react-i18next'

const SettingsContent: React.FC<ISettingsContentProps> = ({navigate, params, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.userReduser.user)
  const group = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const selectedLicense = useAppSelector(state => state.WorkspacesReducer.selectedLicense)
  const isUserLoading = useAppSelector(state => state.userReduser.isLoading)
  const { error, isLoading, groupQuotas, licenses, settings, subscriptions} = useAppSelector(state => state.SettingsReducer)
  const [activeTab, setActiveTab] = useState(1)
  console.log('subscriptions', subscriptions)

  useEffect(() => {
    if (params?.tab === 'group') {
      dispatch(asyncGetAllGroups())
      dispatch(asyncGetLicenses(params.groupId!))
      dispatch(asyncGetGroupProjects(params.groupId!))
      dispatch(asyncGetGroupAndUsersInfo(params.groupId!))
      dispatch(SettingsReducer.actions.setActiveTab(params.groupId!))      
    }
    if (params?.tab === 'account') {
      dispatch(SettingsReducer.actions.setActiveTab('Login & Security'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const onGoToGroupClick = () => {
    dispatch(WorkspacesSlice.actions.setGroupClicked(true))
    navigate('/projects')
  }

  const onGoToManagementClick = () => {
    dispatch(WorkspacesSlice.actions.setGroupClicked(false))
    navigate('./projects?mode=management')
  }

  const onChangePlanClick = () => {
    navigate(`/settings/group/${params.groupId!}/licenses`)
  }

  const getRenderСondition = (block: IBlockType) => {
    if (block === "account") {
      return params?.tab === 'account' && !isLoading && !error && !isUserLoading && !params.licenses
    }
    if (block === "group") {
      return params?.tab === 'group' && !isLoading && !error && !isUserLoading && !params.licenses
    }
    if (block === "licenses") {
      return params.licenses && !isLoading && !error
    }
    if (block === "error") {
      return !isLoading && error && !isUserLoading
    }
    if (block === "loading") {
      return (isLoading || isUserLoading) && !error
    }
    if (block === "transactions") {
      return params?.tab === 'transactions' && !isLoading && !error && !isUserLoading 
    }
    if (block === "subscriptions") {
      return params?.tab === 'subscriptions' && !isLoading && !error && !isUserLoading 
    }
  }

  const onTopupClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.topUpForm.formTitle')))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setChildren(<TopUpBalanceForm user={user} group={group}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }
  return (
    <StyledSettingsTabContent>
      {getRenderСondition('account')
        ? <>
            <ContentSection title={t('authorized.settings.urProfile')} subtitle={t('authorized.settings.profileP')}>
              <SettingsUserCard userInfo={user} navigate={navigate}/>
              <StyledContentCardWrapper>
                  <FlexContainer direction='row' justify='flex-start' align='center' gap='8px'>
                    <StyledCoinsIcon/> 
                    <div>{t('authorized.settings.balance')}: {user?.balance || '0'} RV</div> 
                  </FlexContainer>
                  <ProjectButton widthProps='140px' onClick={onTopupClick}>{t('buttons.topUp')}</ProjectButton>
              </StyledContentCardWrapper>
            </ContentSection>
          </>
        : ''
      }

      {/* {getRenderСondition('subscriptions')
        ? <></>
        : ''
      } */}

      {getRenderСondition('transactions') && 
        <Transactions user={user} group={group}/>
      }

      {getRenderСondition('group') &&
        <GroupSettings
          selectedLicense={selectedLicense}
          groupQuotas={groupQuotas}
          onGoToGroupClick={onGoToGroupClick}
          onChangePlanClick={onChangePlanClick}
          onGoToManagementClick={onGoToManagementClick}
        />
      }

      {getRenderСondition('licenses') && 
        <Licenses
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          licenses={licenses}
          user={user}
          group={group}
          selectedLicense={selectedLicense}    
          settings={settings}    
        />
      }  

      {getRenderСondition('loading')
        ? <FlexContainer direction='column' justify='center' align='center' widthProps='100%' heightProps='85vh'>
            <Loader/>
          </FlexContainer>
        : ''
      }
      {getRenderСondition('error')
        ? <FlexContainer direction='column' justify='center' align='center' widthProps='100%' heightProps='85vh'>
            {error}
          </FlexContainer>
        : ''
      }

    </StyledSettingsTabContent>
  )
}

export default SettingsContent