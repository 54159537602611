import React, { FC } from 'react'
import UserNav from '../../components/UserNav/UserNav'
import { IPagesProps } from '../../models/IPagesProps'
import { ProjectTabsWrapper } from '../../modules/ProjectModule'

export const ProjectPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='projects' navigate={navigate}></UserNav>
      <ProjectTabsWrapper navigate={navigate}/>
    </>
  )
}
