import React, { FC, useEffect, useState } from 'react'
import { IUserNavProps } from '../../models/IUserNavProps'
import logo from '../../assets/icons/video-camera 1.svg'
import HeaderButton from '../../UI/Buttons/HeaderButton/HeaderButton'
import FlexContainer from '../FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import DropdownButton from '../../UI/Buttons/DropdownButton/DropdownButton'
import { StyledDropDown, StyledDropdownContent, StyledHeader, StyledLogo, StyledText, StyledTriangle, StyledUserName, StyledWrapper } from './styled'
import bellIcon from '../../assets/icons/bell-svgrepo-com.svg'
import { Colors } from '../../models/Colors'
import styled from 'styled-components'
import { asyncGetAllRoles, asyncSetReducersToInitialState } from '../../store/reducers/AcyncActions'
import { SettingsReducer } from '../../modules/UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'
import { modalWindowSlice } from '../../store/reducers/modalWindowReducer'
import SendMailForm from '../../UI/forms/SendMailForm'

const StyledBell = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${bellIcon});
  mask-image: url(${bellIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

const StyledLink = styled.h3`
  display: inline;
  text-decoration: none;
  color: ${Colors.orange};
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: ${Colors.lightorange};
  }
`

const UserNav: FC<IUserNavProps> = ({ navigate, activeType, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.userReduser.user)
  const [dropdownVisible, setDropdownVisible] = useState(false)

  useEffect(() => {
    dispatch(asyncGetAllRoles())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const LogoClickHandler = () => {
    dispatch(asyncSetReducersToInitialState())
    navigate('/')
  }

  const ProjectsClickHandler = () => {
    dispatch(asyncSetReducersToInitialState())
    navigate('/projects')
  }

  const onForumClick = () => {
    dispatch(asyncSetReducersToInitialState())
    navigate('/forum')
  }

  const usernameClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }

  const onSettingsClick = () => {
    navigate('/settings/account')
    dispatch(SettingsReducer.actions.setActiveTab(''))
  }

  const onLogoutClick = () => {
    localStorage.removeItem('token')
    navigate('/signin')
  }

  const onSupportClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('other.supportFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<SendMailForm />))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onLibraryClick = () => {
    dispatch(asyncSetReducersToInitialState())
    navigate('/library')
  }

  const SignInClickHandler = () => {
    navigate('/signin')
  }

  const SignUpClickHandler = () => {
    navigate('/signup')
  }

  console.log('process.env.REACT_APP_LINK_TO_GITBOOK', process.env.REACT_APP_LINK_TO_GITBOOK)

  return (
    <StyledHeader {...props}>
      <StyledWrapper>
        <StyledLogo onClick={LogoClickHandler}>
          <img src={logo} alt="Logo" />
          <StyledText>RevisorVision</StyledText>
        </StyledLogo>
        <FlexContainer direction="row" widthProps="auto" paddingProps="0">
          <HeaderButton active={activeType === 'projects'} onClick={ProjectsClickHandler}>
            {t('authorized.header.projects')}
          </HeaderButton>
          <HeaderButton active={activeType === 'library'} onClick={onLibraryClick}>
            {t('authorized.header.library')}
          </HeaderButton>
          {/* <HeaderButton>Documentation</HeaderButton> */}
          <HeaderButton active={activeType === 'forum'} onClick={onForumClick}>
            {t('authorized.header.forum')}
          </HeaderButton>
          <a href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer" style={{
            textDecoration: 'none'
          }}>
            <HeaderButton active={activeType === 'docs'} onClick={() => {}}>
              {t('docs.loginScreen2')}
            </HeaderButton>
          </a>
        </FlexContainer>
      </StyledWrapper>
      {user?.email ? (
        <StyledDropDown>
          <FlexContainer paddingProps="0" gap="5px" direction="row" justify="center" align="center" onClick={usernameClickHandler}>
            <StyledBell />
            <StyledUserName>{user.firstName}</StyledUserName>
            <StyledTriangle active={dropdownVisible} />
          </FlexContainer>
          <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
            <DropdownButton onClick={onSettingsClick}>{t('authorized.header.settings')}</DropdownButton>
            <DropdownButton onClick={onSupportClick}>{t('authorized.header.support')}</DropdownButton>
            <DropdownButton colorProps="red" onClick={onLogoutClick}>
              {t('authorized.header.logout')}
            </DropdownButton>
          </StyledDropdownContent>
        </StyledDropDown>
      ) : (
        <div className="auth">
          <StyledLink onClick={SignInClickHandler}>{t('notAuthorized.signin.signin')}</StyledLink>
          <StyledLink> / </StyledLink>
          <StyledLink onClick={SignUpClickHandler}>{t('notAuthorized.signup.signup')}</StyledLink>
        </div>
      )}
    </StyledHeader>
  )
}

export default UserNav
