import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { Colors } from '../../../../models/Colors'
import { IModalProps } from '../../../../models/IModalProps'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { tasksReducer } from '../../store/reducers/TasksReducer'
import { useNavigate } from 'react-router-dom'
import { datasetsNavReducer } from '../../store/reducers/DatasetsNavReducer'
import DatasetsService, { ICteateDatasetRequest } from '../../../../services/DatasetsService'
import { datasetsReducer } from '../../store/reducers/DatasetsReducer'
import { useTranslation } from 'react-i18next'
import Input from '../../../../UI/Input/Input'
import InputText from '../../../../UI/InputText/InputText'
import Loader from '../../../../UI/Loader/Loader'

const StyledBackground = styled.div<IModalProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #0000007b;
  transition: opacity 0.1s linear;
  height: ${({ visible }) => (visible ? '100vh' : '0')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 1001;
`
const StyledModal = styled.div`
  max-width: 380px;
  width: 100%;
  height: 100vh;
  padding: 20px 20px 30px 20px;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1006;
  color: ${Colors.textgray};
`
const StyledTitle = styled.h3`
  margin: 15px 0;
  text-align: center;
  font-size: 18px;
`
export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.black};
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
`

const DatasetsNav: FC<IModalProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentTask = useAppSelector((state) => state.tasksReducer.currentTask)
  const datasetsNavVisible = useAppSelector((state) => state.datasetsNavReducer.visible)
  const currentProject = useAppSelector((state) => state.WorkspacesReducer.projectInfo)
  const isLoading = useAppSelector((state) => state.datasetsReducer.isLoading)

  const { selectedSplit, selectedImages } = useAppSelector((state) => state.ImagesListReducer)

  const [name, setName] = useState('')
  const [textAreaText, setTextAreaText] = useState('')

  const onBgClick = () => {
    dispatch(datasetsNavReducer.actions.setVisibleFalse())
    dispatch(datasetsNavReducer.actions.setInititialState())
  }

  const getImages = () => {
    if (selectedImages?.length && selectedSplit?.value !== 'unannotated') {
      const images = selectedImages?.map((image) => image.id)
      return images
    }
    return []
  }

  const onAssignClick = () => {
    const createDatasetObj: ICteateDatasetRequest = {
      taskId: currentTask.task.id,
      images: getImages(),
      projectId: currentProject.id,
      desctiption: textAreaText,
      name: name
    }

    dispatch(tasksReducer.actions.setTotal(0))
    const createDataset = async (payload: ICteateDatasetRequest) => {
      try {
        dispatch(datasetsReducer.actions.setLoadingState(true))
        const responce = await DatasetsService.createDataset(payload)
        navigate(`/projects/${currentProject.id}/dataset/dataset/${responce.data.dataset.id}`)
        dispatch(datasetsReducer.actions.setLoadingState(false))
        dispatch(datasetsNavReducer.actions.setVisibleFalse())
        console.log('asyncCreateDataset', responce)
      } catch (error) {
        dispatch(datasetsReducer.actions.setLoadingState(false))
        dispatch(datasetsReducer.actions.setErrorState('Error on create Dataset'))
        console.log('asyncCreateDataset', error)
      }
    }
    createDataset(createDatasetObj)
  }

  const getImagesCount = () => {
    if (selectedImages?.length && selectedSplit?.value !== 'unannotated') {
      return selectedImages?.length
    }
    return currentTask?.task?.annotatedTotal
  }

  return (
    <StyledBackground visible={datasetsNavVisible} onClick={onBgClick}>
      <StyledModal onClick={(e) => e.stopPropagation()}>
        <StyledTitle>{t('authorized.project.taskPage.addToDatasetTitle')}</StyledTitle>
        <StyledBr></StyledBr>
        {isLoading ? (
          <FlexContainer direction="column" justify="center" align="center" heightProps="100%" gap="20px">
            <Loader />
          </FlexContainer>
        ) : (
          <FlexContainer direction="column" align="flex-start" gap="15px">
            <h5>
              {t('authorized.project.taskPage.totalImages')}: <span>{getImagesCount()}</span>
            </h5>
            <Input label={t('other.name')} widthProps="100%" placeholder={t('forum.enterText')} value={name} onChange={(e) => setName(e.target.value)} />
            <InputText
              value={textAreaText}
              label={t('authorized.project.assign.description')}
              onChange={(e) => setTextAreaText(e.target.value)}
              placeholder={t('forum.enterText')}
              widthProps="100%"
              heightProps="100px"
            />
          </FlexContainer>
        )}

        <StyledButtonsWrapper>
          <StyledBr></StyledBr>
          <ProjectButton widthProps="170px" FontSizeProps="16px" onClick={onAssignClick}>
            {t('authorized.project.taskPage.addToDataset')}
          </ProjectButton>
        </StyledButtonsWrapper>
      </StyledModal>
    </StyledBackground>
  )
}

export default DatasetsNav
