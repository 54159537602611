import React, { FC, useEffect, useState } from 'react'
import { StyledCrossIcon, StyledGroupUserCard, StyledMarkIcon, StyledRoleName, StyledTableItem, StyledTooltip, StyledTooltipWrapper, StyledUserCardAvatar, StyledUserCredentials, StyledUserEmail } from '../../constants/Styled';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import ProjectSelect, { Option } from '../../../../UI/ProjectSelect/ProjectSelect';
import { Colors } from '../../../../models/Colors';
import { IGroupUserFullInfo, WorkspacesSlice } from '../../store/reducers/WorkspacesReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import RolesService, { IAddUserToGroupReq } from '../../../../services/RolesService';
import { IFullGroup } from '../../../../services/GroupService';
import { ISummaryRole } from '../../../../services/AccessService';
import { useTranslation } from 'react-i18next';

interface IUserRole {
  id: string;
  name: string;
  displayName: string;
  createdAt: string;
  updatedAt: string;
  roleGroupId: string;
}

interface GroupUserCardProps {
    userInfo: IGroupUserFullInfo["user"];
    userRole: IGroupUserFullInfo["groupRole"];
    groupUsersInfo: IGroupUserFullInfo[]
    setGroupUsersInfo: React.Dispatch<React.SetStateAction<IGroupUserFullInfo[]>>
    optionsTable: Option[];
    roleNumber: number;
    groupInfo: IFullGroup;
}

const GroupUserCard: FC<GroupUserCardProps> = ({groupInfo, userInfo, userRole, groupUsersInfo, setGroupUsersInfo, optionsTable, roleNumber, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const groupRoles = useAppSelector(state => state.rolesReducer.groupRoles)
  const [role, setRole] = useState("");
  const [options, setOptions] = useState<Option[]>([])
  const [roleInfo, setRoleInfo] = useState<IUserRole>({} as IUserRole)
  // !!! true === false !!!
  const [canSaveOrDelete, setCanSaveOrDelete] = useState(false)
  const [canChange, setCanChange] = useState(false)
  
  const selectedRole = options.find((item) => item.value === role);

  useEffect(() => {
    if (userRole.name) {
        setRole(userRole.name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userRole?.id) {
      setRoleInfo(userRole)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUserRoleNumberInUserCard = () => {
        if (roleInfo.name === 'group_owner' && roleNumber && role) {
          setCanChange(2 <= roleNumber)
          setCanSaveOrDelete(2 <= roleNumber && roleInfo.name === role)
        }
        if (roleInfo.name === 'group_admin' && roleNumber && role) {
          setCanChange(3 <= roleNumber)
          setCanSaveOrDelete(3 <= roleNumber && roleInfo.name === role)
        }
        if (roleInfo.name === 'group_user' && roleNumber && role) {
          setCanChange(4 <= roleNumber)
          setCanSaveOrDelete(4 <= roleNumber && roleInfo.name === role)
        }
    }
    
    if (groupUsersInfo?.length) {
      getUserRoleNumberInUserCard()
    }

    //Select options filter
    if (optionsTable?.length && roleNumber === 1) {
      setOptions(optionsTable)
    }
    if (optionsTable?.length && roleNumber === 2) {
      const filteredOptions = optionsTable.filter(option => option.value !== 'group_owner')
      setOptions(filteredOptions)
    }
    if (optionsTable?.length && roleNumber === 3) {
      const filteredOptions = optionsTable.filter(option => option.value !== 'group_owner' && option.value !== 'group_admin')
      setOptions(filteredOptions)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupUsersInfo, roleNumber, role, optionsTable])

  const handleRoleSelect = (value: string) => {
    setRole(value);
  };

  const onDeleteUserClick = async () => {
    if (!canSaveOrDelete) {
      try {
        dispatch(WorkspacesSlice.actions.setLoadingStart())
        console.log('groupInfo', groupInfo.id)
        const responce = await RolesService.deleteGroupUser(groupInfo.id, userInfo.id)
        console.log('onDeleteUserClick', responce)
        const filteredUsers = groupUsersInfo.filter(user => user.user.id !== userInfo.id)
        setGroupUsersInfo(filteredUsers)
        dispatch(WorkspacesSlice.actions.setLoadingEnd())
      } catch (error) {
        dispatch(WorkspacesSlice.actions.setErrorState('Error on DeleteUserClick'))
        console.log('onDeleteUserClick', error)
      }
    }
  }

  const asyncPutGroupUsers = async (id: string, groupMembers: IAddUserToGroupReq, usersInfo: IGroupUserFullInfo[], findedRole: ISummaryRole) => {
    try {
      dispatch(WorkspacesSlice.actions.setLoadingStart())
      const responce = await RolesService.putGroupUsers(id, groupMembers)
      console.log('asyncPutGroupUsers', responce)
      setGroupUsersInfo(usersInfo)
      setRole(findedRole.name)
      setRoleInfo(findedRole)
      if (findedRole.name === 'group_owner' && roleNumber && role) {
        setCanChange(2 <= roleNumber)
        setCanSaveOrDelete(2 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'group_admin' && roleNumber && role) {
        setCanChange(3 <= roleNumber)
        setCanSaveOrDelete(3 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'group_user' && roleNumber && role) {
        setCanChange(4 <= roleNumber)
        setCanSaveOrDelete(4 <= roleNumber && findedRole.name === role)
      }
      dispatch(WorkspacesSlice.actions.setLoadingEnd())
    } catch (error) {
      dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncPutGroupUsers'))
      console.log('asyncPutGroupUsers', error)
    }
  }

  const onSaveUserRoleClick = () => {
    if (roleInfo.name !== role) {
      const findedRole = groupRoles.find(groupRole => groupRole.name === role) // 
      const groupUsersInfoCopy: IGroupUserFullInfo[] = JSON.parse(JSON.stringify(groupUsersInfo))
      
      if (findedRole) {
        const usersToApi = groupUsersInfo.map(user => ({user: {id: user.user.id}, role: {id: user.groupRole.id}}))
        const findedUserInUsersToApi = usersToApi.find(user => user.user.id === userInfo.id)
        const findedUserInGroupUsers = groupUsersInfoCopy.find(user => user.user.id === userInfo.id)

        if (findedUserInUsersToApi && findedUserInGroupUsers) {
          findedUserInUsersToApi.role.id = findedRole.id
          findedUserInGroupUsers.groupRole.id = findedRole.id
          asyncPutGroupUsers(groupInfo.id, {groupMembers: [findedUserInUsersToApi]}, groupUsersInfoCopy, findedRole)
        }
      }
    }
  }

  return (
    <StyledGroupUserCard>
        <StyledTableItem>
            <FlexContainer direction='row' justify='flex-start' align='center'>
                <StyledUserCardAvatar minWidth="45px"/>
                <FlexContainer direction='column' justify='center' align='center'>
                    <StyledUserCredentials>{userInfo.firstName? userInfo.firstName + ' ' + userInfo.lastName : t('authorized.project.projectManagement.tableUser')}</StyledUserCredentials>
                    <StyledUserEmail>{userInfo.email}</StyledUserEmail>
                </FlexContainer>
            </FlexContainer>
        </StyledTableItem>
        <StyledTableItem>
            {canChange
                ? <StyledTableItem><StyledRoleName>{roleInfo.displayName}</StyledRoleName></StyledTableItem>
                : <ProjectSelect options={options} mode='rows' selected={selectedRole || null} onChange={handleRoleSelect} placeholder={t('authorized.project.projectManagement.selectRole')}/>
            }
        </StyledTableItem>
        <StyledTableItem>{userInfo.isActive ? "Active" : "Pending"}</StyledTableItem>
        <StyledTableItem>
            <FlexContainer direction='row' justify='flex-start' align='center' gap='20px'>

                <StyledTooltipWrapper isAdmin={roleInfo.name === role}>
                    <StyledTooltip>{t('other.save')}</StyledTooltip>
                    <StyledMarkIcon onClick={onSaveUserRoleClick} color={roleInfo.name === role ? Colors.gray : ''} hoverColor={roleInfo.name === role ? Colors.gray : ''} cursorProps={roleInfo.name === role}/>
                </StyledTooltipWrapper>

                <StyledTooltipWrapper isAdmin={canSaveOrDelete}>
                    <StyledTooltip >{t('other.delete')}</StyledTooltip>
                    <StyledCrossIcon onClick={onDeleteUserClick} color={canSaveOrDelete ? Colors.gray : ''} hoverColor={canSaveOrDelete ? Colors.gray : ''} cursorProps={canSaveOrDelete}/>
                </StyledTooltipWrapper>

            </FlexContainer>
        </StyledTableItem>
    </StyledGroupUserCard>
  )
}

export default GroupUserCard