import styled from 'styled-components'
import attentionIcon from '../../../assets/icons/attention-circle.svg'
import annotateIcon from '../../../assets/icons/drawIcons/annotate-svgrepo-com.svg'
import arrowLeftIcon from '../../../assets/icons/drawIcons/arrow-left.svg'
import polygonIcon from '../../../assets/icons/drawIcons/automation-4p-svgrepo-com.svg'
import commentsIcon from '../../../assets/icons/drawIcons/comments-svgrepo-com.svg'
import pointIcon from '../../../assets/icons/drawIcons/common-point.svg'
import editAttrsIcon from '../../../assets/icons/drawIcons/edit-attributes-svgrepo-com.svg'
import greaterThanIcon from '../../../assets/icons/drawIcons/greater-than-svgrepo-com.svg'
import handIcon from '../../../assets/icons/drawIcons/hand-move-tool-svgrepo-com.svg'
import historyIcon from '../../../assets/icons/drawIcons/history-svgrepo-com.svg'
import codeIcon from '../../../assets/icons/drawIcons/html-coding-svgrepo-com.svg'
import lessThanIcon from '../../../assets/icons/drawIcons/less-than-svgrepo-com.svg'
import lineIcon from '../../../assets/icons/drawIcons/line-chart-svgrepo-com.svg'
import markNullIcon from '../../../assets/icons/drawIcons/mark-null.svg'
import minusIcon from '../../../assets/icons/drawIcons/minus.svg'
import plusIcon from '../../../assets/icons/drawIcons/plus.svg'
import rectangleIcon from '../../../assets/icons/drawIcons/rectangle-tool-svgrepo-com.svg'
import redoIcon from '../../../assets/icons/drawIcons/redo-svgrepo-com.svg'
import tagsIcon from '../../../assets/icons/drawIcons/tags-svgrepo-com.svg'
import undoIcon from '../../../assets/icons/drawIcons/undo-svgrepo-com.svg'
import eyeImage from '../../../assets/icons/eye-svgrepo-com.svg'
import burgerIcon from '../../../assets/icons/hamburger-menu-svgrepo-com.svg'
import imageIcon from '../../../assets/icons/image-1-svgrepo-com.svg'
import { Colors } from '../../../models/Colors'

//interfaces start
interface ISpanProps {
  textColor: string
}
interface IGreaterAndLessThanProps {
  widthProps?: string
  heightProps?: string
}
interface ITabBtnProps {
  active?: boolean
  disabled?: boolean
}

interface IToolIconProps {
  disabled?: boolean
}
//interfaces end

//svg start
export const StyledBurger = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${burgerIcon});
  mask-image: url(${burgerIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledLineIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${lineIcon});
  mask-image: url(${lineIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledCodeIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${codeIcon});
  mask-image: url(${codeIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledArrow = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${arrowLeftIcon});
  mask-image: url(${arrowLeftIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledRightArrow = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${arrowLeftIcon});
  mask-image: url(${arrowLeftIcon});
  background-color: ${Colors.orange};
  min-width: 25px;
  height: 20px;
  transform: rotate(180deg);

  margin-top: 3px;
`

export const StyledAnnotateIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${annotateIcon});
  mask-image: url(${annotateIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledCommentsIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${commentsIcon});
  mask-image: url(${commentsIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledEditIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${editAttrsIcon});
  mask-image: url(${editAttrsIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledGreaterThanIcon = styled.i<IGreaterAndLessThanProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${greaterThanIcon});
  mask-image: url(${greaterThanIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '25px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '25px')};
`
export const StyledLessThanIcon = styled.i<IGreaterAndLessThanProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${lessThanIcon});
  mask-image: url(${lessThanIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '25px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '25px')};
`
export const StyledHistoryIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${historyIcon});
  mask-image: url(${historyIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledTagsIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${tagsIcon});
  mask-image: url(${tagsIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
export const StyledPolygonIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${polygonIcon});
  mask-image: url(${polygonIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`
export const StyledRedoIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${redoIcon});
  mask-image: url(${redoIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledUndoIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${undoIcon});
  mask-image: url(${undoIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledHandIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${handIcon});
  mask-image: url(${handIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledRectangleIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${rectangleIcon});
  mask-image: url(${rectangleIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledEyeIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${eyeImage});
  mask-image: url(${eyeImage});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledDisabledEyeIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${eyeImage});
  mask-image: url(${eyeImage});
  background-color: ${Colors.textgray};
  width: 25px;
  height: 25px;
  opacity: 0.3;
`

export const StyledPointIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${pointIcon});
  mask-image: url(${pointIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : '1')};
`

export const StyledMarkNullIcon = styled.i<IToolIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${markNullIcon});
  mask-image: url(${markNullIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  opacity: ${({ disabled }) => (disabled ? 1 : 0.3)};
`

export const StyledAttentionIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${attentionIcon});
  mask-image: url(${attentionIcon});
  background-color: ${Colors.orange};
  min-width: 25px;
  min-height: 25px;
`

export const StyledImageIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${imageIcon});
  mask-image: url(${imageIcon});
  background-color: ${Colors.orange};
  min-width: 25px;
  min-height: 25px;
`

export const StyledPlusIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${plusIcon});
  mask-image: url(${plusIcon});
  background-color: ${Colors.orange};
  min-width: 20px;
  min-height: 20px;
`

export const StyledMinusIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${minusIcon});
  mask-image: url(${minusIcon});
  background-color: ${Colors.orange};
  min-width: 30px;
  min-height: 30px;
`
//svg end

//header start
export const StyledDrawHeader = styled.div`
  width: 100%;
  height: 55px;
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: ${Colors.dark};

  border-bottom: 1px solid ${Colors.orange};
`

export const StyledArrowAndInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  max-width: 180px;
  width: 100%;
`

export const StyledArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  border: 1px solid ${Colors.dark};
  border-radius: 50px;

  transition: all 0.2s linear;
  cursor: pointer;

  :hover {
    border: 1px solid ${Colors.orange};
    background-color: ${Colors.black};
  }
`

export const StyledImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
export const StyledImageInfoHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`
export const StyledColoredSpan = styled.span<ISpanProps>`
  color: ${(props) => props.textColor};
  font-size: 11px;
  height: 20px;

  transition: all 0.2s linear;
`
//Slider Header start
export const StyledSliderTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;
  width: 180px;
  height: 40px;

  border: 1px solid ${Colors.gray};
  border-radius: 15px;

  position: relative;
`
//Slider Header end

export const StyledHeaderDropdownWrapper = styled.div`
  max-width: 140px;
  width: 100%;
  height: 30px;

  transition: all 0.1s linear;
  cursor: pointer;
  :hover img {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25), 0 10px 30px rgba(0, 0, 0, 0.22);
  }
`
//header end

//DrawWrapper start
export const StyledDrawWrapper = styled.div`
  width: 100%;
  height: ${window.innerHeight - 55}px;

  position: relative;
  background-color: rgba(0, 0, 0, 0.25);
  overflow: hidden;
`
//DrawWrapper end

//DrawTabsNav start
export const StyledDrawTabsNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  width: 85px;
  height: ${window.innerHeight - 55}px;
  padding: 10px;

  background-color: ${Colors.dark};
  border-right: 1px solid ${Colors.orange};

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`

export const StyledDrawTabBtn = styled.div<ITabBtnProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-size: 11px;
  width: 70px;
  height: 70px;

  border: 1px solid ${Colors.dark};
  border-radius: 15px;
  border-color: ${(props) => (props.active ? Colors.orange : '')};
  background-color: ${(props) => (props.active ? Colors.black : '')};

  transition: all 0.2s linear;
  cursor: pointer;

  :hover {
    border-color: ${Colors.orange};
    background-color: ${Colors.black};
  }
`
export const StyledDrawTabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  width: 220px;
  height: ${window.innerHeight - 55}px;
  padding: 15px 20px 15px 20px;

  background-color: ${Colors.dark};
  border-right: 1px solid ${Colors.orange};

  position: absolute;
  top: 0;
  left: 85px;
  z-index: 100;
`
export const StyledDrawTabContentHeader = styled.div`
  font-size: 15px;
  color: ${Colors.orange};
`
export const StyledBr = styled.span`
  height: 2px;
  width: 100%;
  background: ${Colors.gray};
`

export const StyledDrawNavContent = styled.div`
  width: 180px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  white-space: wrap;
  text-overflow: ellipsis;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
//DrawTabsNav end

//HOTKEYS START
interface IStyledHotkeysWrapperProps {
  active: boolean
}

export const StyledHotKeysTitle = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 84px;
  height: 30px;

  border-radius: 10px 10px 0 0;

  cursor: pointer;
  background-color: ${Colors.orange};
  color: ${Colors.WHITE};
  transition: all 0.1s linear;

  :hover {
    background-color: ${Colors.lightorange};
  }
`

export const StyledHotkeysWrapper = styled.div<IStyledHotkeysWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  height: 330px;
  width: 305px;

  position: absolute;
  left: 0;
  z-index: 101;
  bottom: ${({ active }) => (active ? '-1px' : '-300px')};

  transition: all 0.1s linear;

  /* transform: translateY() */
`

export const StyledHotkeysContent = styled.div<IStyledHotkeysWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  height: 300px;
  width: 305px;

  padding: 15px 5px 10px 15px;

  border: 1px solid ${Colors.orange};
  background-color: ${Colors.black};
  color: ${Colors.WHITE};

  overflow: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.dark}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.gray}; /* отступ вокруг бегунка */
  }

  opacity: ${(props) => (props.active ? 1 : 0)};

  transition: all 0.1s linear;
`

export const StyledHotkeyText = styled.div``

interface IHotkeyTextProps {
  color?: string
  fSize?: string
}

export const HotkeyText = styled.span<IHotkeyTextProps>`
  color: ${({ color }) => color || '#fff'};
  font-size: ${({ fSize }) => fSize || '12px'};
  font-weight: 600;
`

export const StyledKey = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0 4px;
  font-size: 12px;

  border: 1px solid ${Colors.WHITE};
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
`

//HOTKEYS END

//Tools Menu start
export const StyledToolsMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  height: 400px;
  width: 60px;
  padding: 10px;

  background-color: ${Colors.dark};
  border: 1px solid ${Colors.orange};
  border-radius: 10px;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto 0;
  z-index: 1000;
`

export const StyledTolltip = styled.div<ITabBtnProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 216px;
  height: 145px;

  border-radius: 15px;

  position: absolute;
  right: 65px;
  z-index: 100;

  display: ${(props) => (props.active ? 'block' : 'none')};

  cursor: default;
`

export const StyledTooltipText = styled.div<ITabBtnProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 216px;
  height: 80px;
  padding: 5px;
  border-radius: 0 0 15px 15px;

  background-color: ${Colors.dark};

  position: absolute;
  right: 64px;
  top: 30px;
  z-index: 101;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  display: ${(props) => (props.active ? 'block' : 'none')};

  cursor: default;
`

export const StyledTippyBox = styled.div<ITabBtnProps>`
  width: 260px;
  height: 165px;

  position: absolute;
  right: 30px;
  z-index: 102;

  display: ${(props) => (props.active ? 'block' : 'none')};

  cursor: default;
`

export const StyledTool = styled.div<ITabBtnProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-size: 12px;
  width: 40px;
  height: 40px;

  border: 1px solid ${Colors.dark};
  border-radius: 10px;
  border-color: ${(props) => (props.active ? Colors.orange : '')};
  background-color: ${(props) => (props.active ? Colors.black : '')};

  transition: all 0.2s linear;
  cursor: pointer;

  :hover {
    border-color: ${({ disabled }) => (disabled ? '' : Colors.orangeSaturated)};
    background-color: ${({ disabled }) => (disabled ? '' : Colors.black)};
  }

  :hover ${StyledTolltip} {
    display: block;
  }

  :hover ${StyledTooltipText} {
    display: block;
  }
`

export const StyledDisabledTool = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  font-size: 12px;
  width: 40px;
  height: 40px;

  border: 1px solid ${Colors.dark};
  border-radius: 10px;
  border-color: ${Colors.gray};
  background: #252525;

  transition: all 0.2s linear;
  opacity: 0.3;
`
//Tools Menu end

//Canvas start
export const StyledCanvasWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${Colors.black};

  position: absolute;
  top: 0;
  left: 305px;
  z-index: 90;
  overflow-y: hidden;
`

interface IStyledCanvasProps {
  widthProps?: number
  heightProps?: number
}

export const StyledCanvas = styled.canvas<IStyledCanvasProps>`
  background-color: ${Colors.black};
`

export const StyledZoomPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: 0 10px;
  width: 180px;
  height: 45px;
  padding: 3px;

  background-color: ${Colors.darker};
  border: 1px solid ${Colors.orangeSaturated};
  border-radius: 15px;

  position: absolute;
  bottom: 10px;
  left: 345px;
  z-index: 102;
`

export const StyledModeratePanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: 0 10px;
  width: 250px;
  height: 40px;
  padding: 3px;

  background-color: ${Colors.darker};
  border: 1px solid ${Colors.orangeSaturated};
  border-radius: 15px;

  position: absolute;
  top: 70px;
  left: -35px;
  z-index: 102;

  opacity: 0.3;
  transition: opacity 0.2s linear;
  :hover {
    opacity: 1;
  }
`
export const StyledZoomPanelPercents = styled.span`
  width: 50px;
  text-align: center;
  font-size: 16px;
`

export const StyledImgContainer = styled.span`
  opacity: 0;
  img {
    width: 0;
    height: 0;
  }
`

export const StyledPre = styled.pre`
  width: 180px;
  height: 100%;
  font-size: 11px;
  background-color: ${Colors.back};
  padding: 5px;

  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

interface IStyledLabelFormProps {
  active: boolean
}

export const StyledLabelForm = styled.div<IStyledLabelFormProps>`
  display: ${({ active }) => (active ? 'block' : 'none')};

  width: 220px;
  padding: 10px 0;

  background-color: ${Colors.dark};
  border: 1px solid ${Colors.orange};
  border-radius: 15px;

  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 2000;
`

export const StyledLabelsContaier = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-height: 435px;
  padding: 5px;
  overflow: auto;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

//FirstSlide
interface IStyledSlidePProps {
  align?: string
  widthProps?: string
  fSize?: string
  color?: string
}

export const StyledSlideP = styled.p<IStyledSlidePProps>`
  padding: 0 10px;
  font-size: ${({ fSize }) => fSize || '16px'};
  width: ${({ widthProps }) => widthProps || 'auto'};
  text-align: ${({ align }) => align || 'auto'};
  color: ${(props) => props.color || Colors.WHITE};
`

export const StyledSlideColoredSpan = styled.p`
  color: ${Colors.orange};

  transition: all 0.2s linear;
  cursor: pointer;

  :hover {
    color: ${Colors.lightorange};
    text-decoration: underline;
  }
`

export const StyledAutoMarkupToolWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  margin: 15px 0;

  padding: 20px 20px;

  border: 1px solid ${Colors.gray};
  border-radius: 10px;

  width: 160px;
  height: 150px;

  position: relative;

  transition: all 0.1s linear;
  cursor: pointer;

  :hover {
    border-color: ${Colors.orange};
    box-shadow: 0 7px 16px rgba(22, 22, 22, 0.6), 0px 10px 20px rgba(22, 22, 22, 0.6);
  }
`

interface IStyledAutoMarkupToolImageProps {
  srcProps?: any
  disabled?: boolean
}

export const StyledAutoMarkupToolImage = styled.i<IStyledAutoMarkupToolImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${(props) => props.srcProps});
  mask-image: url(${(props) => props.srcProps});
  background-color: ${({ disabled }) => (disabled ? Colors.black : Colors.orange)};
  width: 55px;
  height: 55px;
  min-width: 55px;
`

export const StyledAutoMarkupSegmentToolImage = styled.i<IStyledAutoMarkupToolImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${(props) => props.srcProps});
  mask-image: url(${(props) => props.srcProps});
  background-color: ${({ disabled }) => (disabled ? Colors.black : Colors.orange)};
  width: 100px;
  height: 45px;
  min-width: 55px;
`

export const StyledTooltipWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const StyledAutoMarkupToolTitle = styled.p`
  text-align: center;
  font-size: 14px;
`

//TryModelsSlide
export const StyledTryModelsSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  height: 100%;
`

export const StyledTextModelsSlideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  height: 100%;
`

export const StyledTryColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
  height: 520px;

  padding: 0 15px;
`

export const StyledTextColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
  height: 520px;

  padding: 0 15px;
`

export const StyledLeftTryColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  min-width: 400px;
  height: 100%;
`

export const StyledTextLeftTryColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  min-width: 400px;
  height: 100%;
`

export const StyledClassesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  min-width: 220px;
  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.btngray}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledRightTryColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;

  width: 100%;
  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledVerticalBr = styled.div`
  width: 2px;
  height: 100%;
  background-color: ${Colors.darkerBlack};
`

//StyledMerkupClassWrapper
export const StyledMarkupClassWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: ${Colors.darkerDark};
  width: 260px;
  margin: 10px 20px 0 0;

  border: 1px solid ${Colors.blackDarker};
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    border-color: ${Colors.orange};
  }
`
export const StyledMarkupTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  background-color: ${Colors.darkerDark};
  width: 380px;
  margin: 10px 20px 0 0;
  padding: 5px 10px;
  border: 1px solid ${Colors.blackDarker};
  transition: all 0.1s linear;

  :hover {
    border-color: ${Colors.orange};
  }
`

export const StyledMarkupText = styled.div`
  max-width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

//models
export const StyledSlideModelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 35px;
  flex-wrap: wrap;

  margin-top: 15px;
  width: 100%;
  min-height: 350px;
  height: 100%;
`
export const StyledTextSlideModelsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  gap: 35px;
  flex-wrap: wrap;

  padding: 50px 0 0 0;
  width: 100%;
  height: 100%;
`

//model
export const StyledModelDescription = styled.div`
  overflow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;

  height: 350px;
`
export const StyledModelImgWrapper = styled.div`
  width: 260px;
  height: 100%;
  background-color: #4a4a4a4f;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #494949;
  border-radius: 5px;
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    border: 2px solid ${Colors.orange};
  }
`
export const StyledModelImg = styled.img`
  width: 180px;
  height: 180px;
  margin-top: 15px;
`

export const StyledModelImageName = styled.h6`
  width: 100%;
  padding: 5px 10px;
  text-align: center;
  font-size: 10px;
  color: ${Colors.lightgray};
  white-space: wrap;
  text-overflow: ellipsis;
`

export const StyledModelImageSkeleton = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 8px;
  background-color: #525252;
`

//loaderSlide

export const StyledLoadingText = styled.h5`
  color: ${Colors.WHITE};
`

export const StyledModalWindowTitle = styled.h5`
  text-align: center;
  font-size: 14px;
`

interface IStyledRangeNumberProps {
  isCoincidence: boolean
}

export const StyledRangeNumber = styled.span<IStyledRangeNumberProps>`
  color: ${({ isCoincidence }) => (isCoincidence ? Colors.orange : '#fff')};
  width: 55px;
`

//Draw Annots

export const StyledDrawAnnotsTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  width: 180px;
`

interface IStyledAnnotsTabProps {
  active: boolean
}

export const StyledAnnotsTab = styled.div<IStyledAnnotsTabProps>`
  font-size: 14px;
  font-weight: 500;

  color: ${({ active }) => (active ? Colors.orange : 'WHITE')};

  transition: all 0.1s linear;
  cursor: pointer;

  :hover {
    color: ${Colors.lightorange};
  }
`

export const StyledAttributesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;

  width: 220px;
  height: 100%;
`

export const StyledAttrCardHeader = styled.div`
  color: ${Colors.WHITE};
  font-size: 13px;
  font-weight: bold;

  width: 150px;

  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledAttrCardText = styled.div`
  color: ${Colors.lightgray};
  font-size: 12px;

  width: 75px;

  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
