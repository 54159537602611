import React, { FC, ReactChild, ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import { useAppSelector } from '../../../../../../../hooks/redux';
import { Colors } from '../../../../../../../models/Colors';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import ProjectSelect from '../../../../../../../UI/ProjectSelect/ProjectSelect';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../../services/ProjectService';

interface ITaskColumnProps {
  taskType: string;
  counter: number | string;
  children?: ReactChild | ReactNode;
  datasetCounter?: number | string;
  navigate: NavigateFunction;
  projectInfo: IProject;
  options?: any;
  handleFilterSelect?: any;
  mode?: any;
}

const StyledColumn = styled.div`
  width: 30%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 25px 15px 10px 15px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;
  overflow: auto;

  ::-webkit-scrollbar {
      width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledText = styled.span`
  font-size: 14px;
  color: ${Colors.textgrayInput};
  text-align: center;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 0 10px;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.lightgray}; /* цвет зоны отслеживания */
    border-radius: 20px;  
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 1px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`
const StyledSelectContainer = styled.div`
  width: 240px;
  margin-bottom:15px;
`

const TaskColumn: FC<ITaskColumnProps> = ({options, handleFilterSelect, mode, taskType, counter, children, datasetCounter, navigate, projectInfo, ...props}) => {
  const { t } = useTranslation()
  const tasksSummary = useAppSelector(state => state.tasksReducer.tasksSummary)

  const onUploadClick = () => {
    navigate(`/projects/${projectInfo.id}/upload`)
  }

  return (
    <StyledColumn>
      <FlexContainer direction='column' paddingProps='0' gap='15px' justify='flex-start' align='center' heightProps='120px'>
        <h4>{taskType} ({counter})</h4>
        {/* Unassigned */}
        {taskType === t('authorized.project.annotate.unassigned')
          ? <ProjectButton FontSizeProps='15px' widthProps='100%' heightProps='35px' paddingProps='10px' onClick={onUploadClick}>{t('authorized.project.annotate.uploadMore')}</ProjectButton>
          : ''
        }
        {/* Annotating */}
        {taskType === t('authorized.project.annotate.annotating') && !tasksSummary?.tasks?.length
          ?  <StyledText>{t('authorized.project.annotate.annotatingText')}</StyledText>
          : ''
        }
        {taskType === t('authorized.project.annotate.annotating') 
          ? <StyledSelectContainer> <ProjectSelect options={options} mode='rows' selected={mode || null} onChange={handleFilterSelect} placeholder={t('authorized.project.annotate.selectFilter')}/></StyledSelectContainer>
          : ''
        }
        {/* Dataset */}
        {taskType === t('authorized.project.annotate.dataset')
          ?  <StyledText>{t('authorized.project.annotate.datasetText')}</StyledText>
          : ''
        }
      </FlexContainer>


        
      <Wrapper>
        {children}
      </Wrapper>
    </StyledColumn>
  )
}

export default TaskColumn