export enum Colors {
    WHITE = '#FFFFFF',
    placeholder = '#636363',
    darkblack = '#000',
    darker = '#282828',
    darkerDark = '#202020',
    middledark = '#262626',
    dark = '#252525',
    grayInvisible = '#323232',
    black = '#2B2B2B',
    darkerBlack = '#2D2D2D',
    blackDarker = '#373737',
    gray = '#494949',
    btngray = '#414141',
    lightgray = '#8F8F8F',
    texttable = '#DDDDDD',
    popupTextgray = '#bbbbbb',
    textgray = '#E4E4E4',
    textgrayInput = '#a9a9a9',
    orangeSaturated = '#FF820E',
    lightred = '#ff7c7c',
    superLightRed = 'rgb(255, 231, 231)',
    red = '#F25151',
    darkred = '#8D2929',
    redSaturated = 'rgba(66, 11, 11, 0.645)',
    orange = '#FFA000',
    lightorange = '#ffad20',
    dirtyorange = '#F39F53',
    darkOrange = '#7a4900',
    blue = '#2BBFFF',
    semilightblue = '#42cbfd',
    lightblue = '#68E4FF',
    deeplightblue = '#2bacfc',
    darkblue = '#1E8BC3',
    lightgreen = '#6FFFA8',
    green = '#68CF6C',
    dirtygreen = '#5E9060',
    back = '#343434',
    buttonText = '#CFCACA',
    purple = '#7d02ae',
    lightPurple = '#b700ff',
}

// export const TableColors = [
//     '#14EAAA', '#D0FF12', '#4C0DFF', '#FA04FF', '#FE4646', '#2BBFFF', '#F670D9', '#33FF00', '#fff', '#FFA000', '#6F00B4'
// ]

export const TableColors = [
    '#ffff01', '#914397', '#ffab00', '#1841d1', '#cef6dc', '#841c35', '#68019a', '#a2c871', '#fff', '#d8effd', '#c84553'
]