import React, { useEffect } from 'react'
import SettingsNavButton from './SettingsNavButton'
import { ISettingsNavProps } from '../../constants/interfaces'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { asyncGetGroupQuotas, asyncGetOwnerGroups } from '../../../../store/reducers/AcyncActions'
import { SettingsReducer } from '../../reducers/SettingsReducer'
import { SettingsTabsNav, StyledNavGroupsSection, StyledNavSection, StyledSectionTitle } from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import Loader from '../../../../UI/Loader/Loader'
import { useTranslation } from 'react-i18next'


const SettingsNav: React.FC<ISettingsNavProps> = ({navigate, params, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { activeTab, error, isLoading, ownerGroups}= useAppSelector(state => state.SettingsReducer)

  useEffect(() => {
    dispatch(asyncGetOwnerGroups())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (params.groupId) {
      dispatch(asyncGetGroupQuotas(params.groupId))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, params.groupId])

  const onTabClick = (isGroup: boolean, id?: string) => {
    if (id && isGroup) {
      navigate(`/settings/group/${id}`)
      dispatch(SettingsReducer.actions.setActiveTab(id))
    }
    if ((id === 'Login & Security' || id === 'Вход и безопасность') && !isGroup) {
      navigate(`/settings/account`)
      dispatch(SettingsReducer.actions.setActiveTab(id))
    }

    if ((id === 'Subscriptions' || id === 'Подписки') && !isGroup) {
      navigate(`/settings/subscriptions`)
      dispatch(SettingsReducer.actions.setActiveTab(id))
    }
    if ((id === 'Transactions' || id === 'Транзакции') && !isGroup) {
      navigate(`/settings/transactions`)
      dispatch(SettingsReducer.actions.setActiveTab(id))
    }
  }

  return (
    <SettingsTabsNav>
      <StyledNavSection>
        <StyledSectionTitle>{t('authorized.settings.account')}:</StyledSectionTitle>
        <SettingsNavButton id='Login & Security' text={t('authorized.settings.loginSecurity')} activeTab={activeTab} onClick={() => onTabClick(false, 'Login & Security')}/>
        {/* <SettingsNavButton id='Subscriptions' text='Subscriptions' activeTab={activeTab} onClick={() => onTabClick(false, 'Subscriptions')}/> */}
        <SettingsNavButton id='Transactions' text={t('authorized.settings.transactions')} activeTab={activeTab} onClick={() => onTabClick(false, 'Transactions')}/>
      </StyledNavSection>
      <StyledSectionTitle>{t('authorized.settings.groups')}:</StyledSectionTitle>
      <StyledNavGroupsSection>
        
        {isLoading && !ownerGroups?.length
         ? 
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
            <Loader/>
          </FlexContainer> 
         : ''
        }
        {!isLoading && !ownerGroups?.length && !error
         ? 
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
            Empty
          </FlexContainer> 
         : ''
        }
        {ownerGroups?.map(group => 
          <SettingsNavButton key={group.id} id={group.id} text={group.name} activeTab={activeTab} onClick={() => onTabClick(true, group.id)}/>
        )}
        

      </StyledNavGroupsSection>
    </SettingsTabsNav>
 )
}

export default SettingsNav