import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../models/Colors'
import { useAppSelector } from '../../hooks/redux'

interface IStyledBarFillProps {
    progress: number;
}

const StyledBarWrapper = styled.div`
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
`

const StyledBar = styled.div`
  width: 100%;
  background-color: whitesmoke;
  border-radius: 15px;
`

const StyledBarFill = styled.span<IStyledBarFillProps>`
  display: block;
  height: 20px;
  background-color: ${Colors.orange};
  border-radius: 15px;
  width: ${({progress}) => progress ? progress + '%' : '0%'}; 
  transition: width 30ms ease-in-out;
`

const StyledProgressText = styled.span`
  font-size: 1.5rem;
  padding: 10px;
`


const DownloadProgressBar = () => {
  const progress = useAppSelector(state => state.userReduser.percentComplete)
  return (
    <StyledBarWrapper>
      <StyledBar>
        <StyledBarFill progress={progress}/>
      </StyledBar>
      <StyledProgressText>{progress ? progress + ' %' : 'Download starting...'}</StyledProgressText>
    </StyledBarWrapper>
  )
}

export default DownloadProgressBar