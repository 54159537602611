import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../../models/Colors'
import { IInputProps } from '../../../../../models/IInputProps'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../../UI/Input/Input'
import InputRadio from '../../../../../UI/InputRadio/InputRadio'
import { IFirstSlideProps } from '../../../models/IFirstSlideProps'
import { useAppDispatch } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { useTranslation } from 'react-i18next'

const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  font-size: 14px;
  font-weight: 300;
  margin-left: 3px;
`

const FirstSlide: FC<IFirstSlideProps> = ({slidesValues, setSlidesValues, setVisible, setFirstSlide, setSecondSlide, setThirdSlide, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlidesValues({...slidesValues, usage: e.target.value})
  }

  const onCantelClick = () => {
    setVisible(false)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onContinueClick = () => {
    if (!slidesValues.name) {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groups.createForm.errors.enterGroupName')))
      dispatch(modalWindowSlice.actions.setTitleColor(`${Colors.red}`))
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groups.createForm.title')))
        dispatch(modalWindowSlice.actions.setTitleColor(''))
      }, 2000);
      return
    }
    setFirstSlide(false)
    setSecondSlide(false)
    setThirdSlide(true)
  }

  const onGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlidesValues({...slidesValues, name: e.target.value})
  }

  return (
    <FlexContainer direction='column' gap='20px'>
      <StyledLabel>В каких целях будет использоваться группа?</StyledLabel>
      <FlexContainer gap='20px' direction='row' justify='space-between'>
        <InputRadio 
          label={t('authorized.projects.groups.createForm.types.work')}
          type="radio" 
          value="Work" 
          checked={slidesValues.usage === "Work" ? true : false} 
          onChange={onRadioClick}
        />
        <InputRadio 
          label={t('authorized.projects.groups.createForm.types.academia')}
          type="radio" 
          value="Academia" 
          checked={slidesValues.usage === "Academia" ? true : false} 
          onChange={onRadioClick}
        />
        <InputRadio 
          label={t('authorized.projects.groups.createForm.types.hobby')}
          type="radio" 
          value="Hobbies" 
          checked={slidesValues.usage === "Hobbies" ? true : false} 
          onChange={onRadioClick}
        />
      </FlexContainer>
      <Input label={t('authorized.projects.groups.createForm.inputTitle')} placeholder={t('authorized.projects.groups.createForm.errors.enterGroupName')} value={slidesValues.name} onChange={onGroupNameChange} />
      <FlexContainer margin='10px 0 0 0' widthProps='100%' direction='row' align='center' justify='space-between'>
        <ProjectButton onClick={onCantelClick} FontSizeProps='16px'>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onContinueClick} FontSizeProps='16px'>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default FirstSlide