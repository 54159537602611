import React, { FC, useEffect, useState } from 'react'
import Input from '../../../UI/Input/Input';
import { Colors } from '../../../models/Colors';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import { ISignUpFormProps } from '../../RegistrationForm/interfaces/ISignUpFormProps';
import { setInputFocusError } from './../helpers/inputFocusColorChange';
import { StyledErrorText, StyledErrorWrapepr, StyledEyeIcon, StyledForm, StyledFormHeader, StyledLink, StyledSupTitle } from '../constants/StyledConts';
import { validateEmail } from '../helpers/validateForms';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { loginErrorTotal } from './../reducers/loginErrorTotal';
import { validatePasswordWeight } from '../../RegistrationForm/helpers/validateForms';
import AuthService from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { userSlice } from './../../../store/reducers/userReducer';
import { asyncGetUserInfo } from '../../../store/reducers/AcyncActions';
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { useTranslation } from 'react-i18next';
import { StyledALink } from '../../../styles/GlobalStyles'

export const SignInForm: FC<ISignUpFormProps> = ({...props}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const total = useAppSelector(state => state.loginErrorTotal.total)

  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  
  //Input values  
  const [emailValue, setEmailValue] = useState('')
  const [PasswordValue, setPasswordValue] = useState('')

  //Focused input state   
  const [emailFocusColor, setEmailFocusColor] = useState(Colors.gray)
  const [PasswordFocusColor, setPasswordFocusColor] = useState(Colors.gray)

  const [isPasswordHidden, setIsPasswordHidden] = useState(false)

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        submitClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  }, [emailValue, PasswordValue])
  
  useEffect(() => {
    dispatch(asyncGetUserInfo())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSignUpClick = () => {
    navigate('/signup')
  }

  const onRecoveryClick = () => {
    navigate('/password-recovery')
  }

  const showErrorField = (text:string) => {
    setVisible(true)
    setErrorText(text)
  }

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value)
    const validateResult = validateEmail(e.target.value)
    setInputFocusError(setVisible, setErrorText, setEmailFocusColor, validateResult) 
    if (validateResult.isValid) {
      dispatch(loginErrorTotal.actions.setIsEmailEror(validateResult.isValid))
    }
  }

  const passwordChangeHadler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value)
    const validateResult = validatePasswordWeight(e.target.value)
    setInputFocusError(setVisible, setErrorText, setPasswordFocusColor, validateResult) 
    
    if (validateResult.isValid) {
      dispatch(loginErrorTotal.actions.setIsPasswordError(validateResult.isValid))
    }
  }

  const asyncUserLogin = async () => {
    try {
      const responce = await AuthService.login(emailValue, PasswordValue)
      const token = responce?.data?.token
      localStorage.setItem('token', token)
      dispatch(userSlice.actions.userLoginSuccess(emailValue))
      navigate('/')
    } catch (error:any) {
      console.log(error);
      if (error?.code === "ERR_NETWORK") {
        showErrorField(t('errors.network'))
        return
      }
      if (error?.response?.data?.message === "Аккаунт не активирован!") {
        navigate('/email-enter')
      }
      showErrorField(error?.response?.data?.error)
    }
  }

  const submitClickHandler = () => {
    if (!total) {
      showErrorField(t('other.formError'))
    }
    if (total) {
      asyncUserLogin()
    }
  }

  return (
    <>
    <StyledErrorWrapepr visible={visible}>
        <StyledErrorText>{errorText}</StyledErrorText>
    </StyledErrorWrapepr>
    <StyledForm onSubmit={(e:any) => {e.preventDefault()}}>
        <StyledFormHeader>{t('notAuthorized.signin.signin')}</StyledFormHeader>
        <StyledSupTitle>
          {t('docs.loginScreen1')}
          <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.loginScreen2')}</StyledALink>
        </StyledSupTitle>
        <Input 
            label={t('notAuthorized.inputTitles.email')} 
            focusColor={emailFocusColor} 
            borderColor={emailFocusColor} 
            value={emailValue} 
            onChange={emailChangeHandler}
            type='email'
            placeholder='ivan.ivanov@mail.ru'
        /> 
        <FlexContainer direction='row' justify='center' align='center' gap="5px" margin='0 0 0 30px'>
          <Input
              withTooltip={true}
              tooltipText={t('notAuthorized.passwordTooltip')} 
              label={t('notAuthorized.inputTitles.password')} 
              focusColor={PasswordFocusColor} 
              borderColor={PasswordFocusColor} 
              value={PasswordValue} 
              onChange={passwordChangeHadler}
              placeholder={t('notAuthorized.placeholders.enterPassword')}
              type={isPasswordHidden ? "text" : 'password'}
          />
          <StyledEyeIcon active={isPasswordHidden} onClick={() => setIsPasswordHidden(!isPasswordHidden)}/>
        </FlexContainer>

        <StyledSupTitle>{t('notAuthorized.signin.forgot')} <StyledLink onClick={onRecoveryClick}>{t('notAuthorized.signin.forgotLink')}</StyledLink></StyledSupTitle>
        <ProjectButton margin='5px 0' widthProps='160px' onClick={submitClickHandler}>{t('buttons.signIn')}</ProjectButton>
        <StyledSupTitle>{t('notAuthorized.signin.haventAcc')}<StyledLink onClick={onSignUpClick}> {t('notAuthorized.signup.signup')}</StyledLink></StyledSupTitle>
    </StyledForm>
    </>
  )
}

