import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILoginErrorTotal {
  isEmailError: boolean;
  isPasswordError: boolean;
  total: boolean;

}

const initialState: ILoginErrorTotal = {
  isEmailError: false,
  isPasswordError: false,
  total: false
}

export const loginErrorTotal = createSlice(
{
    name: 'loginErrorTotal',
    initialState,

    reducers: {
      setIsEmailEror(state, action: PayloadAction<boolean>) {
        state.isEmailError = action.payload
        if (state.isEmailError && state.isPasswordError) {
            state.total = true
        }
      },
      setIsPasswordError(state, action: PayloadAction<boolean>) {
        state.isPasswordError = action.payload
        if (state.isEmailError && state.isPasswordError) {
            state.total = true
        }
      },
    }
},
)

export default loginErrorTotal.reducer;