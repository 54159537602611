import React from 'react'
import { SettingsWrapper } from './constants/styled'
import { IUserSettingsProps } from './constants/interfaces'
import SettingsNav from './components/SettingsNav/SettingsNav'
import { useParams } from 'react-router-dom'
import SettingsContent from './components/SettingsContent/SettingsContent'

const UserSettings: React.FC<IUserSettingsProps> = ({ children, navigate, ...props }) => {
  const params = useParams()

  return (
    <SettingsWrapper>
      <SettingsNav navigate={navigate} params={params} />
      <SettingsContent navigate={navigate} params={params} />
    </SettingsWrapper>
  )
}

export default UserSettings
