import React, { useRef, useState } from 'react'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import ReactSlider from 'react-slider'
import uploadIcon from '../../../../assets/icons/cloud-upload-regular-240.png'
import { validateFile } from './validateFile'
import { StyledCroppWrapper, StyledDropFileDiv, StyledDropFileInput, StyledImgContainer, StyledInputLabel, StyledInputWrapper, 
         StyledThumb, StyledThumbValue, StyledTypesText, StyledWrapper } from '../../constants/styled'
import AvatarEditor from 'react-avatar-editor'
import Loader from '../../../../UI/Loader/Loader'
import { useTranslation } from 'react-i18next'

const PercentsThumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
      {state.valueNow + '%'}
    </StyledThumbValue>
  </StyledThumb>
);

const ChangeAvatarForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [src, setSrc] = useState<File | string>('');
  const [zoom, setZoom] = useState(10)
  const [preview, setPreview] = useState<any>('');  
  const [modalOpen, setModalOpen] = useState(false);

  const wrapperRef: React.MutableRefObject<any> = useRef();  
  const inputRef: React.MutableRefObject<any> = useRef();  
  const cropRef: React.MutableRefObject<any> = useRef();
  
  const onCancelClick = () => {
    if (modalOpen) {
      setModalOpen(false);
      setPreview('')
      setSrc('')
    }
    if (!modalOpen) {
      dispatch(modalWindowSlice.actions.setVisibleFalse())
      dispatch(modalWindowSlice.actions.setInititialState())
    }
  }

  const onLoaderCancelClick = () => {
    dispatch(modalWindowSlice.actions.setVisibleFalse())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onContinueClick = () => {
    if (!modalOpen && !preview) {
      return
    }
    if (modalOpen) {
      handleSave()
    }

    //Если загружено изображение
    if (!modalOpen && preview) {
      dispatch(modalWindowSlice.actions.setModalHeight('350px'))
      dispatch(modalWindowSlice.actions.setChildren(
        <FlexContainer direction='column' justify='space-between' align='center' heightProps='230px' margin='30px 0 0 0'>
          <Loader/>
          <FlexContainer direction='row' justify='space-around'>
            <ProjectButton onClick={onLoaderCancelClick} widthProps='140px'>{t('buttons.cancel')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      ))
    }
  }

  //cropper 
  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      setModalOpen(false);
    }
  }

  const onZoomChange = (value: number | readonly number[], index: number) => {
    console.log('value', value)
    if (typeof value === 'number') {
        setZoom(value)
    }
  }

  //Drop File
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.add('dragover')
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.remove('dragover')
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.remove('dragover')
  }

  const handleFileEvent =  (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files)
    if (e.target.files && chosenFiles?.length) {
      if (validateFile(chosenFiles[0])) {
        const fileUrl = URL.createObjectURL(chosenFiles[0])
        setSrc(fileUrl)
        setModalOpen(true)
      }
    }
  }

  return (
    <FlexContainer direction='column' justify='flex-start' align='center'>

      <StyledInputWrapper visible={!modalOpen}>
        <StyledDropFileDiv
          ref={wrapperRef}
          onDragEnter={(e: React.DragEvent<HTMLDivElement>) => onDragEnter(e)}
          onDragLeave={(e: React.DragEvent<HTMLDivElement>) => onDragLeave(e)}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => onDrop(e)}
        >
          <StyledInputLabel>
            <img src={uploadIcon} alt="Upload" />
            <h3>{t('authorized.project.upload.drag1')}</h3>
            <h4>{t('authorized.project.upload.drag2')}</h4>
            <StyledTypesText>{t('authorized.project.upload.support')} .JPG .PNG .JPEG</StyledTypesText>
            <StyledTypesText>{t('authorized.project.upload.maxSize')}</StyledTypesText>
          </StyledInputLabel>
          <StyledDropFileInput ref={inputRef} type="file" multiple={false} onChange={handleFileEvent} accept="image/png, image/jpeg, image/jpg"/>
        </StyledDropFileDiv>  
      </StyledInputWrapper>
      <StyledWrapper>
        <StyledCroppWrapper visible={!modalOpen}>
          <StyledImgContainer>
            <img
              src={
                preview 
                ||
                "https://www.signivis.com/img/custom/avatars/member-avatar-01.png"
              }
              alt=""
              width="150"
              height="150"
            />
          </StyledImgContainer>
          <div>{t('authorized.settings.avatar')}</div>
        </StyledCroppWrapper>

        {modalOpen &&
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={zoom / 10}
            rotate={0}
          />
        }

        {modalOpen &&        
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            defaultValue={[10]}
            min={10}
            max={50}
            ariaValuetext={state => `${state.valueNow}`}
            renderThumb={PercentsThumb}
            minDistance={1}
            onChange={onZoomChange}
          />
        }

      </StyledWrapper>

      <FlexContainer direction='row' justify='space-around'>
        <ProjectButton onClick={onCancelClick} widthProps='140px'>{t('buttons.cancel')}</ProjectButton>
        {modalOpen && <ProjectButton onClick={onContinueClick} widthProps='140px'>{t('buttons.continue')}</ProjectButton>}
        {!modalOpen && preview && <ProjectButton onClick={onContinueClick} widthProps='140px'>{t('buttons.continue')}</ProjectButton>}
      </FlexContainer>
    </FlexContainer>
  )
}

export default ChangeAvatarForm
