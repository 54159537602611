import React, { FC, useEffect, useState } from 'react'
import { IEmailConfirmation } from '../interfaces/IEmailConfirmation';
import Input from '../../../UI/Input/Input';
import { Colors } from '../../../models/Colors';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import { StyledErrorText, StyledErrorWrapepr, StyledForm, StyledFormHeader, StyledSupTitle } from '../constants/StyledConts';
import { validateEmail } from '../helpers/validateForms';
import { setInputFocusError } from '../helpers/inputFocusColorChange';
import AuthService from '../../../services/AuthService';
import { useTranslation } from 'react-i18next';

export const PasswordEnterEmailForm: FC<IEmailConfirmation> = ({navigate, ...props}) => {
  const { t } = useTranslation()
  //Error state
  const [visible, setVisible] = useState(false)
  const [isError, setIsError] = useState(true)
  const [errorText, setErrorText] = useState('')

  //Input values  
  const [inputValue, setInputValue] = useState('')

  //Focused input state   
  const [inputFocusColor, setinputFocusColor] = useState(Colors.gray)

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        submitClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)

    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, inputValue])

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    console.log(e.target.value)
    const validateResult = validateEmail(e.target.value)
    setInputFocusError(setVisible, setErrorText, setinputFocusColor, setIsError, validateResult) 
  }

  const showErrorField = (text:string) => {
    setVisible(true)
    setErrorText(text)
  }

  const sendEmail = async () => {
    try {
      const responce = await AuthService.forgotPassword(inputValue)
      if (responce.data.token) {
        localStorage.setItem('token', responce.data.token)
        navigate(`/auth/reset-password/${responce.data.token}`)
      }
      console.log('sendEmail', responce)
    } catch (error: any) {
      if (error?.code === "ERR_NETWORK") {
        setVisible(true)
        setErrorText(t('other.formError'))
        return
      }
      showErrorField(error.response.data.error ? error.response.data.error : error.response.data.message)
      console.log(error);
    }
  }

  const submitClickHandler = () => {
    if (isError === false) {
      sendEmail()
    }
  }

  return (
    <>
      <StyledErrorWrapepr visible={visible}>
          <StyledErrorText>{errorText}</StyledErrorText>
      </StyledErrorWrapepr>
      <StyledForm onSubmit={(e: any) => {e.preventDefault()}}>
          <StyledFormHeader>{t('notAuthorized.passReset.title')}</StyledFormHeader>
          <StyledSupTitle>{t('notAuthorized.passRecovery.paragraph')}</StyledSupTitle>
          <Input 
              label={t('notAuthorized.inputTitles.email')}
              focusColor={inputFocusColor} 
              borderColor={inputFocusColor} 
              value={inputValue} 
              onChange={inputChangeHandler}
              type='text'
              placeholder='ivan.ivanov@mail.ru'
          />
          <ProjectButton margin='10px 0' widthProps='160px' onClick={submitClickHandler}>{t('buttons.continue')}</ProjectButton>
      </StyledForm>
    </>
  )
}


