import i18n from "../../../i18n";

const regExpObj = {
    email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    usernames: /(?=.{2,100}$)(?![_!@#$%&*^ ;\/)(.'\d-])(?!.*[_.]{2})[a-zA-Z\d._']+(?<![_.\d' -])$/
}
    
// Проверка на совпадение паролей
export const validatePasswordMismatch = (PasswordValue:string, repeatPasswordValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (PasswordValue === repeatPasswordValue) {
        isValid = true;
    }
    if (PasswordValue !== repeatPasswordValue) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidMismatch')
    }
    return {errorMessage, isValid}
}

// Проверка на "сложность" пароля
export const validatePasswordWeight = (PasswordValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.password.test(PasswordValue)) {
        isValid = true;
    }
    if (!regExpObj.password.test(PasswordValue)) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidPass')
    }
    return {errorMessage, isValid}
}

// Проверка на валидность email
export const validateEmail = (emailValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.email.test(emailValue)) {
        isValid = true;
    }
    if (!regExpObj.email.test(emailValue)) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidEmail')
    }
    return {errorMessage, isValid}
}

// Проверка имени и фамилии
export const validateFirstname = (firstnameValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.usernames.test(firstnameValue)) {
        isValid = true;
    }
    if (!regExpObj.usernames.test(firstnameValue)) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidName')
    }
    return {errorMessage, isValid}
}

export const validateLastname = (lastnameValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.usernames.test(lastnameValue)) {
        isValid = true;
    }
    if (!regExpObj.usernames.test(lastnameValue)) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidName')
    }
    return {errorMessage, isValid}
}

