import React from 'react'
import { IFiatOperationProps } from '../../constants/interfaces'
import { StyledProjectTableItem, StyledTableItemWrapper } from '../../../../styles/GlobalStyles'
import ClipBoardCopyText from '../../../../UI/ClipboardText/ClipboardText'

const FiatOperation: React.FC<IFiatOperationProps> = ({operation}) => {
  return (
    <StyledTableItemWrapper>
      <ClipBoardCopyText maxWidth='80px'>
        {operation.invoiceId}
      </ClipBoardCopyText>
      <StyledProjectTableItem>
        {operation.createdAt.slice(0, 10)}
      </StyledProjectTableItem>
      <StyledProjectTableItem>
        {operation.type}
      </StyledProjectTableItem>
      <StyledProjectTableItem>
        {operation.amount}
      </StyledProjectTableItem>
      <StyledProjectTableItem>
        {operation.status}
      </StyledProjectTableItem>
    </StyledTableItemWrapper>
  )
}

export default FiatOperation