import { $authHost } from '../http/index'
import { AxiosResponse } from 'axios'
import { ILocalRole } from '../models/ILocalRole'
import { IProjectMember } from '../modules/Projects/models/IGroupMember'

export interface IProject {
  id: string
  group: string
  name: string
  user: string
  objectType: string
  createdAt: string
  updatedAt: string
  projectLogoUrl?: string;
}

export interface ICreateProjectResponce {
  project: IProject
  message: string
}

export interface IGetGroupProjectsResponce {
  projects: IProject[]
  remain: number
  total: number
}

export interface IGetOneProjectsResponce {
  project: IProject
  localRole: ILocalRole
  members: IProjectMember[]
}

export interface IPutProjectsResponce {
  project: IProject
  message: string
}

export interface IDeleteProjectById {
  message: string
}

interface ICreateProjectPayload {
  name: string
  group: string
  classNames: string
  objectType: string
  accessType: string
}

export default class ProjectService {
  static async createProject(payload: ICreateProjectPayload): Promise<AxiosResponse<ICreateProjectResponce>> {
    return $authHost.post<ICreateProjectResponce>('/projects', payload)
  }

  static async getAllProjects(): Promise<AxiosResponse<IProject[]>> {
    return $authHost.get<IProject[]>('/projects')
  }

  static async getOneProjectById(id: string): Promise<AxiosResponse<IGetOneProjectsResponce>> {
    return $authHost.get<IGetOneProjectsResponce>(`/projects/${id}`)
  }

  static async getAllGroupProjects(groupId: string): Promise<AxiosResponse<IGetGroupProjectsResponce>> {
    return $authHost.get<IGetGroupProjectsResponce>(`/projects`, {
      params: {
        group: groupId,
        limit: 100
      }
    })
  }

  static async putProjectById(project: IProject): Promise<AxiosResponse<IGetOneProjectsResponce>> {
    return $authHost.put<IGetOneProjectsResponce>(`/projects/`, { id: project.id, name: project.name, group: project.group, type: '1' })
  }

  static async deleteProjectById(id: string): Promise<AxiosResponse<IDeleteProjectById>> {
    return $authHost.delete<IDeleteProjectById>(`/projects/${id}`)
  }
}
