import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import { IProjectButtonProps } from './../../../models/IProjectButtonProps'
import ButtonTooltip from './ButtonTooltip'

const StyledButton = styled.div<IProjectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${(props) => props.margin || '0'};

  width: ${(props) => props.widthProps || '120px'};
  padding: ${(props) => props.paddingProps || '0px'};
  height: ${(props) => props.heightProps || '40px'};
  border: 1px solid transparent;
  border: ${({ active }) => (active ? `1px solid ${Colors.orange}` : '')};
  border-radius: 15px;
  background: ${({ active }) => (active ? Colors.black : Colors.btngray)};

  font-style: normal;
  font-size: ${(props) => props.FontSizeProps || '14px'};
  font-weight: 400;
  color: ${Colors.buttonText};
  text-align: center;
  line-height: 17px;
  user-select: none;
  transition: all 0.1s linear;

  cursor: pointer;
  &:hover {
    color: ${Colors.orangeSaturated};
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

const StyledRelativeDiv = styled.div`
  font-size: 16px;
  position: relative;
  z-index: 10;
`

const StyledDisabledButton = styled.div<IProjectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${(props) => props.margin || '0'};

  width: ${(props) => props.widthProps || '120px'};
  padding: ${(props) => props.paddingProps || '0px'};
  height: ${(props) => props.heightProps || '40px'};
  border: 1px solid transparent;
  border: 1px solid ${Colors.darkOrange};
  border-radius: 15px;
  background: ${({ active }) => (active ? Colors.black : Colors.btngray)};

  font-style: normal;
  font-size: ${(props) => props.FontSizeProps || '14px'};
  font-weight: 400;
  color: ${Colors.darkOrange};
  text-align: center;
  line-height: 17px;
  user-select: none;
  transition: all 0.1s linear;

  cursor: pointer;
  &:hover {
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
`

const ProjectButton: FC<IProjectButtonProps> = ({ children, ...props }) => {
  const [display, setDisplay] = useState(false)

  const onTooltipHover = () => {
    setDisplay(!display)
  }

  const onTooltipLeave = () => {
    setDisplay(false)
  }

  return (
    <StyledRelativeDiv>
      {props.withTooltip && <ButtonTooltip visible={display}>{props.tooltipText}</ButtonTooltip>}
      {!props.disabled && <StyledButton {...props}>{children}</StyledButton>}
      {props.disabled && (
        <StyledDisabledButton {...props} onMouseOver={onTooltipHover} onMouseLeave={onTooltipLeave}>
          {children}
        </StyledDisabledButton>
      )}
    </StyledRelativeDiv>
  )
}

export default ProjectButton
