import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnnotateReq, IGetImageInfoResponce, IGetDatasetImageInfoResponce } from '../../../services/AnnotateService';
import { ILabel, IUpdateLabelResponce } from '../../../services/LabelService';
import { IProject } from '../../../services/ProjectService';
import { ITaskImages } from '../../../services/TasksService';
import i18n from '../../../i18n';
import { INewImage } from '../../../services/BatchesService';

interface IReducerState {
  labels: ILabel[]
  isLoading: boolean;
  error: string;
  activeBtn: {name: string; displayName: string;}
  code: string;
  label: string;
  labelVisible: boolean;
  info: any[];
  annotation: IAnnotateReq
  annots: any;
  isAnnotated: boolean;
  project: IProject;
  action: string;
  currentImageIndex: number;

  reviewImages: INewImage[];
  rejectedImages: INewImage[];
  approvedImages: INewImage[];

  imageInfo: IGetImageInfoResponce | null

  guestViewMode: boolean;
  modelPanelActivity: boolean;

  autoMarkupLength: number;
  annotatedLength: number;

  previousPage: string;
}

const initialState: IReducerState = {
  labels: [],
  isLoading: false,
  error: '',
  activeBtn: {name: 'Annotations', displayName: i18n.t('authorized.project.draw.nav.annotations')},
  code: '',
  label: '',
  labelVisible: false,
  info: [],
  annotation: {
    imageId: '',
    subStatus: '',
    boxes: [],
  },
  annots: [],
  isAnnotated: false,
  project: {
    id: '',
    group: '',
    name: '',
    user: '',
    objectType: '',
    createdAt: '',
    updatedAt: '',
  },
  action: '',
  currentImageIndex: 0,

  reviewImages: [],
  rejectedImages: [],
  approvedImages: [],

  imageInfo: null,

  guestViewMode: false,
  modelPanelActivity: false,

  autoMarkupLength: 0,
  annotatedLength: 0,

  previousPage: ''
}

export const drawTabsSlice = createSlice(
{
    name: 'drawTabs',
    initialState,

    reducers: {
      //Initial
      setInitialState(state) {
        state.labels = initialState.labels;
        state.isLoading = initialState.isLoading;
        state.error = initialState.error;
        state.activeBtn = initialState.activeBtn;
        state.code = initialState.code;
        state.label = initialState.label;
        state.labelVisible = initialState.labelVisible;
        state.info = initialState.info;
        state.annotation = initialState.annotation;
        state.annots = initialState.annots;
        state.isAnnotated = initialState.isAnnotated;
        state.project = initialState.project;
        state.action = initialState.action;
        state.currentImageIndex = initialState.currentImageIndex;
        state.guestViewMode = initialState.guestViewMode;
        state.autoMarkupLength = 0
        state.annotatedLength = 0
        state.previousPage = ''
      },
      //error and loading start
      setErrorState(state, action: PayloadAction<string>) {
        state.error = action.payload
      },
      
      setLoadingState(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload
      },
      //error and loading end
      onAnnotationsClick(state) {
        state.activeBtn = {name: 'Annotations', displayName: i18n.t('authorized.project.draw.nav.annotations')}
      },

      onAttributesClick(state) {
        state.activeBtn = {name: 'Attributes', displayName: i18n.t('authorized.project.draw.nav.atribytes')}
      },

      onCommentsClick(state) {
        state.activeBtn = {name: 'Comments', displayName: i18n.t('authorized.project.draw.nav.comments')}
      },

      onTagsClick(state) {
        state.activeBtn = {name: 'Tags', displayName: i18n.t('authorized.project.draw.nav.tags')}
      },

      onHistoryClick(state) {
        state.activeBtn = {name: 'History', displayName: i18n.t('authorized.project.draw.nav.history')}
      },

      onRawClick(state) {
        state.activeBtn = {name: 'Raw', displayName: i18n.t('authorized.project.draw.nav.code')}
      },

      setCode(state, action: PayloadAction<string>) {
        state.code = action.payload
      },

      setLabel(state, action: PayloadAction<string>) {
        state.label = action.payload
      },

      setLabelVisibleFalse(state) {
        state.labelVisible = false
      },

      setLabelVisibleTrue(state) {
        state.labelVisible = true
      },

      setAnnotation(state, action: PayloadAction<IAnnotateReq>) {
        state.annotation = action.payload
      },

      setLabels(state, action: PayloadAction<ILabel[]>) {
        state.labels = action.payload
      },

      updateLabel(state, action: PayloadAction<IUpdateLabelResponce>) {
        const findedLabel = state.labels.find(label => label.id === action.payload.label.id)
        if (findedLabel) {
          findedLabel.name = action.payload.label.name
          findedLabel.color = action.payload.label.color
        }
      },
      addLabel(state, action: PayloadAction<any>) {
        state.labels.push(action.payload)
      },

      updateAnnotations(state, action: PayloadAction<any>) {
        state.annots = action.payload
        state.isAnnotated = action.payload.annotated
      },
      setProjectInfo(state, action: PayloadAction<IProject>) {
        state.project = action.payload
      },
    
      deleteLabel(state, action: PayloadAction<string>) {
        state.labels = state.labels.filter(label => label.id !== action.payload)
      },
      setAction(state, action: PayloadAction<string>) {
        state.action = action.payload
      },
      setPreviousPage(state, action: PayloadAction<string>) {
        state.previousPage = action.payload
      },
      setCurrentImageIndex(state, action: PayloadAction<number>) {
        state.currentImageIndex = action.payload
      },

      setRevievImages(state, action: PayloadAction<INewImage[]>) {
        state.reviewImages = action.payload
      },
      setRejectedImages(state, action: PayloadAction<INewImage[]>) {
        state.rejectedImages = action.payload
      },
      setApprovedImages(state, action: PayloadAction<INewImage[]>) {
        state.approvedImages = action.payload
      },

      setImageInfo(state, action: PayloadAction<IGetImageInfoResponce>) {
        state.imageInfo = action.payload
      },

      setGuestViewMode(state, action: PayloadAction<boolean>) {
        state.guestViewMode = action.payload
      },

      setModerPanelActivity(state, action: PayloadAction<boolean>) {
        state.modelPanelActivity = action.payload;
      },

      setAutoMarkupLength(state, action: PayloadAction<number>) {
        state.autoMarkupLength = action.payload
      },
      setAnnotatedLength(state, action: PayloadAction<number>) {
        state.annotatedLength = action.payload
      },
    }
},

)

export default drawTabsSlice.reducer;