import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../../../../models/Colors'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import { NavigateFunction } from 'react-router-dom';
import AnnotatingJob from '../AnnotatingJob/AnnotatingJob';
import { ISummaryTask } from '../../../../../../../services/TasksService';
import { useAppSelector } from '../../../../../../../hooks/redux';
import { IProject } from '../../../../../../../services/ProjectService';

interface IRoleTaskColumnProps {
    type: string;
    headerText: string;
    title?: string;

    tasks: ISummaryTask[] | null;

    navigate: NavigateFunction;
    projectInfo: IProject;
}

const StyledColumn = styled.div`
  width: 30%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 25px 15px 10px 15px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;
  overflow: auto;

  ::-webkit-scrollbar {
      width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledText = styled.span`
  font-size: 14px;
  color: ${Colors.textgrayInput};
  text-align: center;
`

const RoleTaskColumn: FC<IRoleTaskColumnProps> = ({type, headerText, title, tasks, navigate, projectInfo, ...props}) => {
  const viewMode = useAppSelector(state => state.TabsReducer.viewMode)
  const [tasksInPool, setTasksInPool] = useState<ISummaryTask[]>([])
  const [tasksInProgress, setTasksInProgress] = useState<ISummaryTask[]>([])
  const [doneTasks, setDoneTasks] = useState<ISummaryTask[]>([])

  useEffect(() => {
    //MODERATOR TASKS
    if (type === 'pool' && tasks?.length && (viewMode.value === 'MODER')) {
      const tasksToPool = tasks.filter(task => task?.status?.name === 'in_pool')
      console.log('moder', tasksToPool)
      setTasksInPool(tasksToPool)
    }
    if (type === 'processing' && tasks?.length && (viewMode.value === 'MODER')) {
      const tasksInProgress = tasks.filter(task => task?.status?.name === 'on_review' || task?.status?.name === 'in_work')
      console.log('moder', tasksInProgress)
      setTasksInProgress(tasksInProgress)
    }
    if (type === 'done' && tasks?.length && viewMode.value === 'MODER') {
      const doneTasks = tasks.filter(task => task?.status?.name === 'approved' || task?.status?.name === 'rejected')
      console.log('moder', doneTasks)
      setDoneTasks(doneTasks)
    }
    
    //LABELLER TASKS
    if (type === 'pool' && tasks?.length && viewMode.value === 'LABELLER') {
      const tasksToPool = tasks.filter(task => task?.status?.name === 'in_pool')
      console.log('LABELLER', tasksToPool)
      setTasksInPool(tasksToPool)
    }
    if (type === 'processing' && tasks?.length && viewMode.value === 'LABELLER') {
      const tasksInProgress = tasks.filter(task => task?.status?.name === 'in_work' || task?.status?.name === 'rejected')
      console.log('LABELLER', tasksInProgress)
      setTasksInProgress(tasksInProgress)
    }
    if (type === 'done' && tasks?.length && viewMode.value === 'LABELLER') {
      const doneTasks = tasks.filter(task => task?.status?.name === 'on_review' || task?.status?.name === 'approved')
      console.log('LABELLER', doneTasks)
      setDoneTasks(doneTasks)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, viewMode])

  
  return (
    <StyledColumn>
        <FlexContainer direction='column' paddingProps='0' gap='15px' justify='flex-start' align='center' heightProps='120px'>
          <h4>{headerText}</h4>
          <StyledText>{title ? title : ''}</StyledText>
        </FlexContainer>
        
        <Wrapper>
          {type === 'pool' ? tasksInPool?.length ? tasksInPool.map((task, index) => 
            <AnnotatingJob 
              navigate={navigate} 
              projectInfo={projectInfo} 
              date={task?.name ? task?.name : ''} 
              annotated={task?.annotatedCount === 0 ? task?.annotatedCount : task?.annotatedCount} 
              unannotated={task?.unannotatedCount === 0 ? task?.unannotatedCount : task?.unannotatedCount} 
              jobInfo={task} 
              key={task?.task? task?.task : performance.now() + index} 
            />
          ) : '' : ''}
          {type === 'processing' ? tasksInProgress?.length ? tasksInProgress.map((task, index) => 
            <AnnotatingJob 
              navigate={navigate} 
              projectInfo={projectInfo} 
              date={task?.name ? task?.name : ''} 
              annotated={task?.annotatedCount === 0 ? task?.annotatedCount : task?.annotatedCount} 
              unannotated={task?.unannotatedCount === 0 ? task?.unannotatedCount : task?.unannotatedCount} 
              jobInfo={task} 
              key={task?.task? task?.task : performance.now() + index} 
            />
          ) : '' : ''}
          {type === 'done' ? doneTasks?.length ? doneTasks.map((task, index) => 
            <AnnotatingJob 
              navigate={navigate} 
              projectInfo={projectInfo} 
              date={task?.name ? task?.name : ''} 
              annotated={task?.annotatedCount === 0 ? task?.annotatedCount : task?.annotatedCount} 
              unannotated={task?.unannotatedCount === 0 ? task?.unannotatedCount : task?.unannotatedCount} 
              jobInfo={task} 
              key={task?.task? task?.task : performance.now() + index} 
            />
          ) : '' : ''}
            
        </Wrapper>
    </StyledColumn>
  )
}

export default RoleTaskColumn

