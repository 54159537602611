import React from 'react'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { CenteredText } from '../../../styles/GlobalStyles'
import Input from '../../Input/Input'
import ProjectButton from '../ProjectButton/ProjectButton'
import { Colors } from '../../../models/Colors'
import { IFullGroup } from '../../../services/GroupService'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer'
import { asyncDeleteGroupById } from '../../../store/reducers/AcyncActions'
import { WorkspacesSlice } from '../../../modules/Projects/store/reducers/WorkspacesReducer'

interface IDeleteGroupFormProps {
    groupInfo: IFullGroup;
}

const DeleteGroupForm: React.FC<IDeleteGroupFormProps> = ({groupInfo}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.userReduser.user)
  const [inputValue, setInputValue] = React.useState('')

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }
  
  const onButtonDeleteClick = () => {
    if (inputValue !== groupInfo.name) {
      return
    }
    if (currentUser?.role?.name === 'global_admin' || currentUser?.role?.name === 'developer') {
      dispatch(asyncDeleteGroupById(groupInfo.id))
      dispatch(WorkspacesSlice.actions.setGroupClicked(false))
      onCancelClick()
    }
  }

  return (
    <FlexContainer direction='column' justify='space-between' align='center' gap='10px'>
      <CenteredText>{t('authorized.projects.groupProjects.project.deleteGrText')}</CenteredText>
      <CenteredText color={Colors.orange}>{groupInfo.name}</CenteredText>
      <Input
        type='text'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={t('authorized.projects.groupProjects.project.deleteGroupPl')}
        label={t('authorized.projects.groups.createForm.inputTitle')}
      />  
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton FontSizeProps='16px' onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton FontSizeProps='16px' onClick={onButtonDeleteClick} disabled={inputValue !== groupInfo.name}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default DeleteGroupForm