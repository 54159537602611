import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";
import { INewImage } from "./BatchesService";

export interface IVersionImage {
    filename: string;
    displayname: string;
}

export interface IShortVersionInfo {
    versionNumber: number;
    id: string;
    name: string;
    project: string;
    createdAt: string;
    updatedAt: string;
    versionInfo: {
        labels: string[];
        renameLabels: IGenerateLabel[];
        rebalance: {
            testCount: number;
            trainCount: number;
            validCount: number;
        }
        preprocessing: IOptionReq[];
        augmentation: IOptionReq[];
    } | null;
}

export interface IOptionReq {
    method: string;
    percentage?: number;
    selected: boolean;
    params?: any;
}

export interface IGenerateLabel {
    oldName: string;
    newName: string;
}

export interface ICreateVersionRequest {
    service: string;
    project: string;
    renameLabels: IGenerateLabel[];
    rebalance: {
        validCount: number;
        testCount: number;
        trainCount: number;
    };
    preprocessing: IOptionReq[];
    augmentation: IOptionReq[];
}

export interface ICreateVersionResponce {
    job: string;
    service: string;
    message: string;
}

export interface IGetVersionProgressResponce {
    version?: {
        versionNumber: number;
        id: string;
        name: string;
        project: string;
        updatedAt: string;
        createdAt: string;
    }
    message: string | number;
    progress?: {
        progress: number;
    };
}

export interface IPutVersionResponce {
    version: IShortVersionInfo;
    message: string;
}

export interface IFullVersionInfo {
    versionNumber: number;
    id: string;
    name: string;
    project: string;
    createdAt: string;
    updatedAt: string;

    versionInfo: {
        labels: string[];
        renameLabels: IGenerateLabel[];
        rebalance: {
            testCount: number;
            trainCount: number;
            validCount: number;
        }
        preprocessing: IOptionReq[];
        augmentation: IOptionReq[];
    }
    trainTotal: number;
    validTotal: number;
    testTotal: number;
}

export interface IGetVersionByIdResponce {
    version: IFullVersionInfo;
    archive: IArchive[];
    imagesTotal: number;
    total: number;
    remain: number;
    imagesOnPage: number;
    images: INewImage[]
}

export interface IDeleteVersionResponce {
    message: string;
}

export interface IGetVersionsSummaryResponce {
    versions: IShortVersionInfo[];
}

export interface ICreateExportRequest {
    service: string;
    version: string;
    format: string;
    renameLabels: IGenerateLabel[];
    useOriginalFileNames: boolean;
}

export interface IGetExportProgressResponce {
    link?: string;
    message: string | number;
    progress?: number;
}

export interface IArchive {
    id: string;
    userId: string;
    groupId: string;
    versionId: string;
    filename: string;
    size: string;
    downloaded: boolean;
    createdAt: string;
}

export default class VersionsService {
    static async createVersion(payload: ICreateVersionRequest): Promise<AxiosResponse<ICreateVersionResponce>> {
        return $authHost.post<ICreateVersionResponce>('/versions/generate', payload)
    }

    static async getVersionProgress(id: string): Promise<AxiosResponse<IGetVersionProgressResponce>> {
        return $authHost.get<IGetVersionProgressResponce>(`/versions/generate/${id}`)
    }

    static async putVersion(id: string, name: string): Promise<AxiosResponse<IPutVersionResponce>> {
        return $authHost.put<IPutVersionResponce>('/versions', {id: id, name: name})
    }

    static async getVersionById(id: string, limit?: number, offset?: number, dataset?: string, search?: string): Promise<AxiosResponse<IGetVersionByIdResponce>> {
        return $authHost.get<IGetVersionByIdResponce>(`/versions/${id}?limit=${limit}&offset=${offset}&dataset=${dataset}&search=${search}`)
    }

    static async deleteVersion(id: string): Promise<AxiosResponse<IDeleteVersionResponce>> {
        return $authHost.delete<IDeleteVersionResponce>(`/versions/${id}`)
    }

    static async getVersionsSummary(projectId: string): Promise<AxiosResponse<IGetVersionsSummaryResponce>> {
        return $authHost.get<IGetVersionsSummaryResponce>(`/versions/project/${projectId}`)
    }

    //export

    static async createExportVersion(payload: ICreateExportRequest): Promise<AxiosResponse<ICreateVersionResponce>> {
        return $authHost.post<ICreateVersionResponce>('/versions/export', payload)
    }

    static async getExportVersionProgress(id: string): Promise<AxiosResponse<IGetExportProgressResponce>> {
        return $authHost.get<IGetExportProgressResponce>(`/versions/export/${id}`)
    }

    //archive
    static async deleteArchive(id: string): Promise<AxiosResponse<IDeleteVersionResponce>> {
        return $authHost.delete<IDeleteVersionResponce>(`/versions/archives/${id}`)
    }
}