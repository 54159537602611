import React, { FC, useEffect } from 'react'
import { NavigateFunction, Params, useParams } from 'react-router-dom';
import styled from 'styled-components';
import editIcon from '../../../../../../../assets/icons/edit-svgrepo-com.svg'
import EditDescription from './EditDescription';
import Label from './Label';
import AddLabelForm from './AddLabelForm';
import { Colors } from '../../../../../../../models/Colors';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { asyncGetProjectLabels} from '../../../../../../../store/reducers/AcyncActions';
import TasksService from '../../../../../../../services/TasksService';
import { drawTabsSlice } from '../../../../../../Draw/store/DrawTabsReducer';
import { datasetsNavReducer } from '../../../../../store/reducers/DatasetsNavReducer';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import { assignNavReducer } from '../../../../../store/reducers/AssignNavReducer';
import { StyledHeader } from '../../../../../constants/styled';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import TimeLine from '../../../../../../../UI/TimeLine/TimeLine';
import { useTranslation } from 'react-i18next';
import { ImagesList } from '../../../../../../ImagesList';
import { EImagesPage } from '../../../../../../ImagesList/store/ImagesListReducer';
import { ProjectBr } from '../../../../../../../styles/GlobalStyles';
import { INewImage } from '../../../../../../../services/BatchesService';
import AutoMarkupSlideForm from '../../../../../../Draw/components/AutoMarkupSlideForm/AutoMarkupSlideForm';
import { IProject } from '../../../../../../../services/ProjectService';

interface IBatchProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: Readonly<Params<string>>;
}

export const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 20px;
`

const StyledEditIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${editIcon});
  mask-image: url(${editIcon});
  background-color: ${Colors.orange};
  width: 15px;
  height: 15px;
`

const StyledBatch = styled.div`
  width: 100%;
  max-width: 250px;
  height: auto;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;

  position: relative;

  transition: all 0.1s linear;     

  &:hover {
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
const StyledDate = styled.h6`
  color: ${Colors.textgray};
`
const StyledCounter = styled.h6`
  color: ${Colors.textgray};
`
const StyledLabeller = styled.h5`
  color: ${Colors.textgray};
  height: 20px;
`
const StyledName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.textgray};
  height: 20px;
  font-size: 14px;
`
const JobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
  width: 100%;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 40px;
`
const JobOverview = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  padding: 10px;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledTitle = styled.span`
  
`
const StyledTextButton = styled.span`
  font-size: 12px;
`
const StyledEdit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.orangeSaturated};
  }
`

export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.black};
  margin: 10px 0;
`
const StyledLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-height: 350px;
  width: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

interface StyledTaskStatusProps {
  statusName: string;
}

const StyledTaskStatus = styled.div<StyledTaskStatusProps>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  font-size: 12px;

  color: ${props => 
      props.statusName === 'in_pool' ? Colors.deeplightblue 
    : props.statusName === 'in_work' ? Colors.lightgreen 
    : props.statusName === 'on_review' ? Colors.orange
    : props.statusName === 'approved' ? Colors.lightblue
    : props.statusName === 'rejected' ? Colors.red
    : props.statusName === 'processing' ? Colors.lightPurple
    : props.statusName === 'in_queue' ? Colors.lightPurple
    : props.statusName === 'automarkup' ? Colors.lightPurple
    : ''};
`

const JobPage:FC<IBatchProps> = ({projectInfo, navigate, param, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.userReduser.user)
  const userRoleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const taskTimeline = useAppSelector(state => state.StatisticsReducer.taskTimeline)
  const labels = useAppSelector(state => state.DrawTabsReducer.labels)
  const isRoleLoading = useAppSelector(state => state.WorkspacesReducer.isRoleLoading)
  const { currentTask, isLoading, selectedAnnotator, selectedModerator} = useAppSelector(state => state.tasksReducer)
  const { selectedSplit, images, selectedImages, total } = useAppSelector(state => state.ImagesListReducer)
  const isAutoMarkupsLoading = useAppSelector(state => state.AutoMarkupReducer.isLoading)

  //Выход из страницы, если задача в "запретом" для пользовтеля статусе
  useEffect(() => {
    dispatch(asyncGetProjectLabels(params.id!))
    if ((currentTask?.task?.status?.name === 'on_review' || 
         currentTask?.task?.status?.name === 'in_pool'   ||
         currentTask?.task?.status?.name === 'approved') && userRoleNumber === 6) {
      navigate(`/projects/${params.id}/annotate`)
    }
    if ((currentTask?.task?.status?.name === 'rejected'  || 
         currentTask?.task?.status?.name === 'in_work'   ||
         currentTask?.task?.status?.name === 'approved'  ||
         currentTask?.task?.status?.name === 'in_pool')  && userRoleNumber === 5) {
      navigate(`/projects/${params.id}/annotate`)
    }
    if (userRoleNumber >= 7) {
      navigate(`/projects/${params.id}/annotate`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDrawViewMode = () => {
    if (currentTask?.task?.moderator?.id === currentUser?.id ||
        currentTask?.task.annotator?.id === currentUser?.id || userRoleNumber <= 4) {
        return false
    }
    return true
  }

  const navigateImagesToDraw = (image: INewImage, action: string) => {
    if (isRoleLoading) {
      return
    }
    if (!labels?.length) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
      dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={currentTask.task.project}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }
    const drawViewMode = getDrawViewMode()

    if ((currentTask?.task?.status?.name === 'processing' || 
         currentTask?.task?.status?.name === 'in_queue'   ||
         currentTask?.task?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer') ) {
      return
    }

    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))
    dispatch(drawTabsSlice.actions.setAction(action))
    navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${image.id}`)
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${projectInfo.id}/job/annotate/${currentTask.task.id}`))
  }

  const onOpenSelectedClick = () => {
    if (isRoleLoading) {
      return
    }

    if ((currentTask?.task?.status?.name === 'processing' || 
         currentTask?.task?.status?.name === 'in_queue'   ||
         currentTask?.task?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer') ) {
      return
    }

    const drawViewMode = getDrawViewMode()
    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))

    dispatch(drawTabsSlice.actions.setAction('selectedImages'))
    navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${selectedImages[0].id}`)
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${projectInfo.id}/job/annotate/${currentTask.task.id}`))
  }

  useEffect(() => {
    if ((currentTask?.task?.status?.name === 'processing' || 
        currentTask?.task?.status?.name === 'in_queue'   ||
        currentTask?.task?.status?.name === 'automarkup') &&
       (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer') ) {
      navigate(`/projects/${params.id}/annotate`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask?.task?.status?.name])
  
  const onDatasetsImagesClick = () => {
    dispatch(datasetsNavReducer.actions.setVisible())
  }

  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    console.log(currentTask)
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.editInstruction')))
    dispatch(modalWindowSlice.actions.setChildren(<EditDescription jobId={currentTask.task.id} name={currentTask.task.name}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onReassignClick = () => {
    dispatch(assignNavReducer.actions.setReassignVisible())
  }

  const onAddClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
    dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={currentTask.task.project}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onToReviewClick = async () => {
    try {
      const responce = await TasksService.putTask(
        currentTask?.task.id, 
        currentTask?.task.name, 
        currentTask?.task?.description, 
        '5f06a80d-a732-4f0b-931b-76bdb7ea8c85', 
        currentTask?.task?.moderator?.id ? currentTask?.task.moderator.id : '', 
        currentTask?.task?.annotator?.id ? currentTask?.task.annotator.id : ''
      )
      console.log('onToReviewClick',responce)
      if (responce?.data?.task?.id && params.id) {
       navigate(`/projects/${params.id}/annotate`)
      }
    } catch (error) {
      console.log('onToReviewClick', error)
    }
  }

  const onApproveClick = async () => {
    try {
      const responce = await TasksService.putTask(
        currentTask?.task.id, 
        currentTask?.task.name, 
        currentTask?.task?.description, 
        '20876ef5-5dd6-474b-9763-66a26f7e1b5a', 
        currentTask?.task?.moderator?.id ? currentTask?.task.moderator.id : '', 
        currentTask?.task?.annotator?.id ? currentTask?.task.annotator.id : ''
      )
      console.log('onApproveClick',responce)
      if (responce?.data?.task?.id && params.id) {
        navigate(`/projects/${params.id}/annotate`)
      }
    } catch (error) {
      console.log('onToReviewClick', error)
    }
  }

  const onRejectClick = async () => {
    try {
      const responce = await TasksService.putTask(
        currentTask?.task.id, 
        currentTask?.task.name, 
        currentTask?.task?.description, 
        'ee56ac77-d954-436d-babb-5578e82124af', 
        currentTask?.task?.moderator?.id ? currentTask?.task.moderator.id : '', 
        currentTask?.task?.annotator?.id ? currentTask?.task.annotator.id : ''
      )
      console.log('onRejectClick',responce)
      if (responce?.data?.task?.id && params.id) {
        navigate(`/projects/${params.id}/annotate`)
      }
    } catch (error) {
      console.log('onToReviewClick', error)
    }
  }

  const getRenderCondition = (condition: string) => {
    if (condition === 'review') {
      return (
        (userRoleNumber === 6 || userRoleNumber < 5)     
         && currentTask?.task?.status?.name !== 'on_review' 
         && !currentTask.task?.unannotatedTotal                   
         && !currentTask?.task?.rejectedTotal
         && currentTask?.task?.annotatedTotal
      )
    }
    if (condition === 'reject') {
      return (
        currentTask?.task?.status?.name === 'on_review' 
        && currentTask?.task?.rejectedTotal
        && userRoleNumber <= 5
        && currentTask?.task?.annotatedTotal
      )
    }
    if (condition === 'approve') {
      return (
        (currentTask?.task?.status?.name === 'on_review' || currentTask?.task?.status?.name === 'rejected')
         && userRoleNumber <= 5
         && !currentTask?.task?.rejectedTotal
         && !currentTask?.task?.reviewTotal
         && currentTask?.task?.annotatedTotal
      )
    }
    if (condition === 'dataset') {
      return currentTask?.task?.annotatedTotal && userRoleNumber < 5
    }
    if (condition === 'automarkup') {
      return (
        userRoleNumber <= 4
      )
    }
    return false
  }

  const onAutoMarkupClick = () => {
    if (!isAutoMarkupsLoading) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setModalWidth('580px'))
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
      dispatch(modalWindowSlice.actions.setVisible())
    }
    if (isAutoMarkupsLoading) {
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }

  return (
    <StyledProjects>
      <StyledHeader>
          {currentTask?.task?.name? <h5>{currentTask?.task?.name}</h5>: <h3>{t('authorized.project.annotate.annotating')}</h3>}
          <FlexContainer direction='row' justify='flex-end' align='center' gap='10px'>
            {selectedImages?.length ? <ProjectButton onClick={onOpenSelectedClick} widthProps="180px" FontSizeProps='14px'>{t('authorized.project.draw.openSelected')}</ProjectButton> : ''}
            {getRenderCondition('dataset') ? <ProjectButton onClick={onDatasetsImagesClick} widthProps="180px" FontSizeProps='14px'>{t('authorized.project.taskPage.addToDataset')}</ProjectButton> : ''} 
            {getRenderCondition('automarkup') ? <ProjectButton onClick={onAutoMarkupClick} widthProps="180px" FontSizeProps='14px'>{t('imagesList.automarkup')}</ProjectButton> : ''} 
            {getRenderCondition('review')  ? <ProjectButton onClick={onToReviewClick} widthProps="180px" FontSizeProps='14px'>{t('buttons.sendToReview')}</ProjectButton> : ''}
            {getRenderCondition('reject')  ? <ProjectButton onClick={onRejectClick} widthProps="180px" FontSizeProps='14px'>{t('buttons.RejectTask')}</ProjectButton> : ''} 
            {getRenderCondition('approve') ? <ProjectButton onClick={onApproveClick} widthProps="180px" FontSizeProps='14px'>{t('buttons.approveTask')}</ProjectButton> : ''} 
          </FlexContainer>
      </StyledHeader>
      <ProjectBr/>

      <JobWrapper>
        <JobOverview className="JobOverview">
          <StyledBatch>
            <StyledDate>{currentTask.task? currentTask.task.createdAt.slice(0, 10) : 'Task Info'}</StyledDate>
            <StyledTaskStatus statusName={currentTask?.task?.status?.name}>{currentTask?.task?.status?.displayName}</StyledTaskStatus>
            <StyledCounter>{t('authorized.project.annotate.images', { count: currentTask?.imagesTotal})}:</StyledCounter>
            <StyledCounter>({currentTask?.task?.annotatedTotal ? currentTask?.task?.annotatedTotal : '0'}) {t('authorized.project.taskPage.annotated')}</StyledCounter>
            <StyledCounter>({currentTask?.task?.unannotatedTotal ? currentTask?.task?.unannotatedTotal : '0'}) {t('authorized.project.taskPage.unannotated')}</StyledCounter>
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledTitle>{t('authorized.project.taskPage.instructions')}</StyledTitle>
              {userRoleNumber <= 4 ? 
                <StyledEdit>
                  <StyledEditIcon/>
                  <StyledTextButton onClick={onEditClick}>{t('other.edit')}</StyledTextButton>
                </StyledEdit>
              : ''}
            </FlexContainer>
            {currentTask.task
              ? currentTask.task.description ? <StyledCounter>{currentTask.task.description}</StyledCounter>
              : <StyledCounter>{t('authorized.project.taskPage.noInstruction')}</StyledCounter>
              : 'Press F5'
            }
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledCounter>{t('authorized.project.projectManagement.users')}</StyledCounter>
              {userRoleNumber <= 4 ? 
                <StyledEdit onClick={onReassignClick}>
                  <StyledEditIcon/>
                  <StyledTextButton>{t('other.edit')}</StyledTextButton>
                </StyledEdit>
              : ''}
            </FlexContainer>

            <FlexContainer direction='row' gap='5px' justify='flex-start' align='center'>
              <StyledLabeller>{t('other.labeller')}:</StyledLabeller>
              <StyledName>{currentTask?.task?.annotator?.email || selectedAnnotator.email || t('authorized.project.annotate.notAssigned')}</StyledName>
            </FlexContainer>
            <FlexContainer direction='row' gap='5px' justify='flex-start' align='center'>
              <StyledLabeller>{t('other.moderator')}:</StyledLabeller>
              <StyledName>{currentTask?.task?.moderator?.email || selectedModerator.email || t('authorized.project.annotate.notAssigned')}</StyledName>
            </FlexContainer>
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledCounter>{t('authorized.project.taskPage.classes')}</StyledCounter>
              {userRoleNumber <= 4 ? 
                <StyledEdit onClick={onAddClick}>
                  <StyledEditIcon/>
                  <StyledTextButton>{t('buttons.add')}</StyledTextButton>
                </StyledEdit>
              : ''}
            </FlexContainer>
              <StyledLabelsWrapper>
                {labels?.length
                  ? labels?.map(label => <Label id={label.id} color={label.color} name={label.name} projectId={label.project} key={label.name}/>)
                  : ''
                }
              </StyledLabelsWrapper>

          </StyledBatch>
          {taskTimeline?.length ? <TimeLine limit={3} storyPoints={taskTimeline?.length ? taskTimeline : []} title={t('authorized.project.draw.nav.history')}/> : ''}
        </JobOverview>
        <ImagesList page={EImagesPage.taskPage} heightProps='auto' onImageClick={navigateImagesToDraw}/>
      </JobWrapper>
    </StyledProjects>
  )
}

export default JobPage