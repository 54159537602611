import React, { FC, useState } from 'react'
import { IGetTopicByIdResponce } from '../../../../../services/ForumService'
import { StyledTopicMessage } from '../../../constants/styled'
import Editor from '../../../../Editor'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { asyncCreateMessage } from '../../../../../store/reducers/AcyncActions'
import { IUsersMeResponse } from '../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ITopicMessageFieldProps {
  currentTopic: IGetTopicByIdResponce
  user: IUsersMeResponse
}

const TopicMessageField: FC<ITopicMessageFieldProps> = ({ currentTopic, user, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { messageState, editorHtmlState } = useAppSelector((state) => state.ForumReducer)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onloadContent, setOnloadContent] = useState('')
  const [isMessage, setIsMessage] = useState(false)

  // useEffect(() => {
  //   if (messageState) {
  //       setOnloadContent(messageState)
  //   }
  // }, [messageState])

  const onSendClick = () => {
    //проверка длины сообщения, минимальная длина = 5
    if (editorHtmlState.replace(/<[^>]+>/g, '').length > 5) {
      setOnloadContent('')
      dispatch(asyncCreateMessage(editorHtmlState, currentTopic.topic.id))
      setIsMessage(!isMessage)
    }
  }

  return (
    <StyledTopicMessage id="field">
      {user?.id && (
        <FlexContainer direction="row" justify="center" align="flex-end" gap="15px" margin="0" paddingProps="15px">
          <div>
            <Editor onloadContent={`<p${messageState}</p>`} readOnly={false} cancel={isMessage} overview={false}/>
          </div>
          <ProjectButton widthProps="200px" onClick={onSendClick}>
            {t('buttons.send')}
          </ProjectButton>
        </FlexContainer>
      )}
    </StyledTopicMessage>
  )
}

export default TopicMessageField
