import React, { FC, useState } from 'react'
import { StyledDate, StyledMessageEdit, StyledTopicAnswer, StyledTopicEditorWrapper, StyledTopicMessage, StyledTopicMessageToolbar } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import TopicPageUser from './TopicPageUser'
import Editor from '../../../../Editor'
import { IGetTopicByIdResponce, ITopicMessage } from '../../../../../services/ForumService'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { ForumReducer } from '../../../store/slices/ForumReducer'
import MessageDropdown from './MessageDropdown'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { asyncPutMessage } from '../../../../../store/reducers/AcyncActions'
import { useTranslation } from 'react-i18next'

interface ITopicMessageProps {
  message: ITopicMessage
  currentTopic: IGetTopicByIdResponce
  readOnly: boolean
}

const TopicMessage: FC<ITopicMessageProps> = ({ message, currentTopic, readOnly, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.userReduser.user)
  const [isEditClicked, setIsEditClicked] = useState(true)
  const [messageContent, setMessageContent] = useState(message.content)
  const editorState = useAppSelector((state) => state.ForumReducer.editorHtmlState)

  const onAnswerClick = () => {
    const field = document.getElementById('field')
    if (field) {
      window.scrollTo(0, field.offsetTop)
      dispatch(
        ForumReducer.actions.setEditorMessage(
          `<p><span style="color: rgb(65,168,95);">@${message?.author?.firstName ? message?.author?.firstName : message?.author?.email}</span><span>,</span></p>`
        )
      )
    }
  }

  const onCancelClick = () => {
    setIsEditClicked(true)
    setMessageContent(message.content)
  }

  const onEditClick = () => {
    if (editorState) {
      setMessageContent(editorState)
      dispatch(asyncPutMessage(message.id, editorState, currentTopic.topic.id))
    }
    setIsEditClicked(true)
  }

  return (
    <StyledTopicMessage>
      <StyledMessageEdit>
        {currentUser?.id === message?.author?.id || currentUser?.role?.name === 'global_admin' || currentUser?.role?.name === 'developer' ? (
          <MessageDropdown message={message} user={currentUser} setIsEditClicked={setIsEditClicked} />
        ) : (
          ''
        )}
      </StyledMessageEdit>

      <FlexContainer direction="row">
        <FlexContainer direction="column" justify="flex-start" align="center" widthProps="content-width" paddingProps="10px 0">
          <StyledDate>{t('forum.message')}:</StyledDate>
          <TopicPageUser user={message.author} currentTopic={null} />
          <StyledDate>{message?.createdAt?.slice(0, 10) + ` ${t('forum.in')} ` + message?.createdAt?.slice(11, 19)}</StyledDate>
        </FlexContainer>

        <StyledTopicEditorWrapper>
          <Editor onloadContent={messageContent} readOnly={isEditClicked} cancel={isEditClicked} overview={false}/>
        </StyledTopicEditorWrapper>
      </FlexContainer>
      {!isEditClicked ? (
        <FlexContainer direction="row" justify="flex-end" align="center" gap="20px" paddingProps="0 15px 15px 0">
          <ProjectButton widthProps="140px" onClick={onCancelClick}>
            {t('buttons.cancel')}
          </ProjectButton>
          <ProjectButton widthProps="140px" onClick={onEditClick}>
            {t('other.edit')}
          </ProjectButton>
        </FlexContainer>
      ) : (
        ''
      )}

      <StyledTopicMessageToolbar>
        {currentUser?.id ? <StyledTopicAnswer onClick={onAnswerClick}>{t('forum.reply')}</StyledTopicAnswer> : ''}
        {/* <FlexContainer direction='row' justify='flex-end' widthProps='100px' gap='10px'>
                <span>{message?.likes}</span>
                <StyledLikeIcon/>
            </FlexContainer> */}
      </StyledTopicMessageToolbar>
    </StyledTopicMessage>
  )
}

export default TopicMessage
