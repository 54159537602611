import React, { FC } from 'react'
import { useAppDispatch } from '../../../../../../hooks/redux';
import { StyledFormOptionImage, StyledFormOptionImageWrapper, StyledFormOptionWrapper, StyledImageName } from '../../../../constants/styled';
import emptyImage from '../../../../../../assets/icons/empty.png'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer';
import FormOptionForm from './FormOptionForm/FormOptionForm';
import RenameLabelsForm from '../../../RenameLabelsForm/RenameLabelsForm';
import { useTranslation } from 'react-i18next';

interface IFormOptionProps {
    globalType: string;
}

const RenameOption: FC<IFormOptionProps> = ({globalType, ...props}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onOptionClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.classesRename')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('500px'))
    dispatch(modalWindowSlice.actions.setChildren(<RenameLabelsForm type='dataset'/>))
  }

  return (
    <StyledFormOptionWrapper onClick={onOptionClick}>
      <StyledFormOptionImageWrapper>
        <StyledFormOptionImage src={emptyImage}/>
      </StyledFormOptionImageWrapper>
      <StyledImageName>{t('authorized.project.generateAndVersions.classesRename')}</StyledImageName>
    </StyledFormOptionWrapper>
  )
}

export default RenameOption