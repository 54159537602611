import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategory, IFullTopicInfo, IGetCategoryByIdResponce, IGetLastTopicsResponce, IGetTopicByIdResponce, IShortTopicInfo, ISubcategory, ITopicMessage } from '../../../../services/ForumService';
import Category from '../../components/Category/Category';

interface IReducerState {
    isLoading: boolean;
    error: string | null | boolean;

    shortCategoriesInfo: ICategory[];
    fullCategoriesInfo: IGetCategoryByIdResponce[];
    currentSubCategory: IGetCategoryByIdResponce;
    currentCategory: IGetCategoryByIdResponce;
    shortTopicsInfo: IShortTopicInfo[];
    currentTopic: IGetTopicByIdResponce;
    latestTopics: IGetLastTopicsResponce;

    editorHtmlState: string;

    currentMessage: ITopicMessage;
    messageState: string;
}

interface renamePayload {
    id: string;
    name: string;
}

interface IsetCreatedPayload {
    category: IGetCategoryByIdResponce;
    isSub: boolean;
}

interface IDeleteSubsPayload {
    subs: ISubcategory[];
    id: string;
}

interface IEditMessage {
    id: string;
    content: string;
}

const initialState: IReducerState = {
    isLoading: false,
    error: '',

    shortCategoriesInfo: [],
    fullCategoriesInfo: [], 
    currentSubCategory: {} as IGetCategoryByIdResponce,
    currentCategory: {} as IGetCategoryByIdResponce,
    shortTopicsInfo: [],
    currentTopic: {} as IGetTopicByIdResponce,
    latestTopics: {
        remain: 0,
        views: 0,
        topics: []
    },

    editorHtmlState: '',
    messageState: '',
    currentMessage: {} as ITopicMessage,

}

export const ForumReducer = createSlice(
{
    name: 'Forum',
    initialState,

    reducers: {
        setInitialState(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.shortCategoriesInfo = initialState.shortCategoriesInfo
            state.currentCategory = initialState.currentCategory
            state.shortTopicsInfo = initialState.shortTopicsInfo
            state.currentTopic = initialState.currentTopic
            state.currentMessage = initialState.currentMessage
            state.editorHtmlState = ''
            state.latestTopics = initialState.latestTopics
            state.currentSubCategory = initialState.currentSubCategory
            state.fullCategoriesInfo = initialState.fullCategoriesInfo
            state.messageState = initialState.messageState
        },
        clearEditorState(state) {
            state.editorHtmlState = ''
            state.messageState = initialState.messageState
        },
        //set
        setError(state, action: PayloadAction<string | boolean | null>) {
            state.error = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
        setShortCategoriesInfo(state, action:PayloadAction<ICategory[]>) {
            state.shortCategoriesInfo = action.payload
        },
        setFullCategoriesInfo(state, action: PayloadAction<IGetCategoryByIdResponce>) {
            const findedCategory = state.fullCategoriesInfo.find(category => category.category.id === action.payload.category.id)
            if (!findedCategory) {
                state.fullCategoriesInfo.push(action.payload)
            }
        },
        setCurrentCategory(state, action:PayloadAction<IGetCategoryByIdResponce>) {
            state.currentCategory = action.payload
        },
        setCurrentSubCategory(state, action:PayloadAction<IGetCategoryByIdResponce>) {
            state.currentSubCategory = action.payload
        },
        setCurrentMessage(state, action:PayloadAction<ITopicMessage>) {
            state.currentMessage = action.payload
        },
        setCreatetCategory(state, action: PayloadAction<IsetCreatedPayload>) {
            if (action.payload.isSub) {
                const parent = state.fullCategoriesInfo.find(category => category.category.id === action.payload.category.category.id)
                if (parent) {
                    parent.subcategories = action.payload.category.subcategories   
                }       
            }
            if (!action.payload.isSub) {
                state.fullCategoriesInfo.push(action.payload.category)
            }
        },
        setLastTopics(state, action: PayloadAction<IGetLastTopicsResponce>) {
            state.latestTopics = action.payload
        },
        setCurrentTopic(state, action: PayloadAction<IGetTopicByIdResponce>) {
            state.currentTopic = action.payload
        },
        setEditorContent(state, action:PayloadAction<string>) {
            state.editorHtmlState = action.payload
        },
        setEditorMessage(state, action:PayloadAction<string>) {
            state.messageState = action.payload
        },
        setNewMessage(state, action: PayloadAction<ITopicMessage>) {
            const message = state.currentTopic.messages.find(message => message.id === action.payload.id)
            if (!message) {
                state.currentTopic.messages.unshift(action.payload)
            }
        },
        setCreatedTopicToMain(state, action: PayloadAction<IGetTopicByIdResponce>) {
            const category = state.fullCategoriesInfo.find(category => category.category.id === action.payload.topic.forumCategory.id)
            if (category) {
                category.topics.push(action.payload.topic)
            }
        },

        //put
        renameCategory(state, action:PayloadAction<renamePayload>) {
            const fullCategory = state.fullCategoriesInfo.find(category => category.category.id === action.payload.id)
            if (fullCategory) {
                fullCategory.category.name = action.payload.name
            }
        },
        renameSubCategory(state, action:PayloadAction<IDeleteSubsPayload>) {
            const category = state.fullCategoriesInfo.find(cat => cat.category.id === action.payload.id)
            if (category) {
                category.subcategories = action.payload.subs
            }
        },
        putTopicTitle(state, action:PayloadAction<IFullTopicInfo>) {
            state.currentTopic.topic.title = action.payload.title
            let topic = state.currentCategory.topics.find(topic => topic.id === action.payload.id)
            if (topic) {
                topic.title = action.payload.title
            }

        },
        putTopicDescription(state, action:PayloadAction<IFullTopicInfo>) {
            state.currentTopic.topic.description = action.payload.description
            let topic = state.currentCategory.topics.find(topic => topic.id === action.payload.id)
            if (topic) {
                topic.description = action.payload.description
            }

        },

        //put message
        editMessageContent(state, action: PayloadAction<IEditMessage>) {
            const message = state.currentTopic.messages.find(message => message.id === action.payload.id)
            if (message) {
                message.content = action.payload.content
            }
        },


        //put description
        putSubcategoryDescription(state, action: PayloadAction<renamePayload>) {
            state.currentSubCategory.category.description = action.payload.name
            const subCategory = state.fullCategoriesInfo.find(category => category.subcategories.find(sub => sub.id === action.payload.id))
            if (subCategory) {
                subCategory.category.description = action.payload.name
            }
        },

        //delete
        deleteCategory(state, action: PayloadAction<string>) {
            state.fullCategoriesInfo = state.fullCategoriesInfo.filter(category => category.category.id !== action.payload)
        },
        setDeletedSubs(state, action: PayloadAction<IDeleteSubsPayload>) {
            const category = state.fullCategoriesInfo.find(cat => cat.category.id === action.payload.id)
            if (category) {
                category.subcategories = action.payload.subs
            }
        },
        deleteTopic(state, action: PayloadAction<string>) {
            state.currentSubCategory.topics.filter(topic => topic.id !== action.payload)
        },
        deleteMessage(state, action: PayloadAction<string>) {
            state.currentTopic.messages = state.currentTopic.messages.filter(message => message.id !== action.payload)
        }

    }
},

)

export default ForumReducer.reducer;