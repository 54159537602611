import React, { FC } from 'react'
import 'react-international-phone/style.css';
import { PhoneInput } from 'react-international-phone';
import styled from 'styled-components';
import { Colors } from '../../../models/Colors';
import { IInputProps } from '../../../models/IInputProps';
import { IProjectPhoneInput } from '../../../models/IProjectPhoneInput';

const StyledPhoneInput = styled.div<IInputProps>`
  .react-international-phone-input-container{
    display: flex;
  }
  
  .react-international-phone-country-selector-button{
    border: 2px solid ${Colors.gray};
    border-color: ${props => props.borderColor || Colors.gray};
    background-color:${props => props.backgroundProps || Colors.darker};
    height:${props => props.heightProps || '35px'};
    margin-right:2px;
    border-radius: 5px;
  }

  .react-international-phone-input {
    width: ${props => props.widthProps || '250px'};
    height:${props => props.heightProps || '35px'};
    padding:${props => props.paddingProps || '4px'};
    background-color:${props => props.backgroundProps || Colors.darker};
    border: 2px solid ${Colors.gray};
    border-color: ${props => props.borderColor || Colors.gray};
    border-radius: 6px;
    
    color: ${props => props.textColor || Colors.textgray};
    :focus {
      outline: none;
      border: 1px solid ${props => props.focusColor || Colors.orange}
    }
  }
`
const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  margin-bottom: 4px;
  padding-left: 3px;
  
  font-size: 16px;
  font-weight: 300;
`

const ProjectPhoneInput: FC<IProjectPhoneInput> = ({phone, setPhone, label, ...props}) => {
  return (
    <StyledPhoneInput {...props}>
        {label? <StyledLabel labelColor={props.labelColor}>{label}</StyledLabel> : ''}
        <PhoneInput 
          {...props}initialCountry="ru"
          value={phone}
          onChange={(phone) => setPhone(phone)}
        />
    </StyledPhoneInput>
  )
}

export default ProjectPhoneInput