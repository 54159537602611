import React, { FC } from 'react'
import styled from 'styled-components'
import selectedImg from '../../../../../../assets/icons/checkOrange.png'
import emptyImage from '../../../../../../assets/icons/empty.png'
import { Colors } from '../../../../../../models/Colors'
import { NavigateFunction } from 'react-router-dom'
import { IFullVersionInfo } from '../../../../../../services/VersionsService'
import { IProject } from '../../../../../../services/ProjectService'

interface IImageProps {
  name: string
  imagePath: string
  isSelectAllClick?: boolean
  dataset: string
  filename: string
  navigate: NavigateFunction
  projectInfo: IProject
  currentVersion?: IFullVersionInfo
}
interface ISelectedClick {
  selected?: boolean
}

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
`
export const StyledImgWrapper = styled.div<ISelectedClick>`
  width: 110px;
  height: 125px;
  background-color: ${({ selected }) => (selected ? '#fcb94649' : '#4a4a4a4f')};

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: ${({ selected }) => (selected ? '2px solid #FFA000' : '2px solid #494949')};
  border-radius: 5px;
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    border: 2px solid ${Colors.orange};
    background-color: #fcb94649;
  }
`
export const StyledImg = styled.img`
  max-width: 80px;
  max-height: 80px;
`

export const StyledImageName = styled.h6`
  width: 100px;
  text-align: center;
  font-size: 10px;
  color: ${Colors.lightgray};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`
const StyledSelectedImg = styled.img<ISelectedClick>`
  width: 20px;
  height: 20px;
  transition: visibility 0.1s linear;
  visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  position: absolute;
  top: 3px;
  left: 3px;
`

const GenerateAccordionImage: FC<IImageProps> = ({ name, imagePath, dataset, filename, navigate, projectInfo, currentVersion, ...props }) => {
  const onImageClick = () => {
    navigate(`/projects/${projectInfo.id}/datasets`)
  }
  return (
    <StyledDescription onClick={onImageClick}>
      <StyledSelectedImg src={selectedImg} />
      <StyledImgWrapper>
        <StyledImg src={imagePath ? imagePath : emptyImage} alt="alt" />
        <StyledImageName>{name}</StyledImageName>
      </StyledImgWrapper>
    </StyledDescription>
  )
}

export default GenerateAccordionImage
