import React, { FC, useEffect, useState } from 'react'
import { StyledForumsWrapper } from '../../constants/styled'
import Category from './Category'
import { IGetCategoryByIdResponce } from '../../../../services/ForumService'
import { NavigateFunction } from 'react-router-dom';
import { IUsersMeResponse } from '../../../../services/UserService';

interface ICategoriesWrapperProps {
  categories: IGetCategoryByIdResponce[];
  user: IUsersMeResponse;
  navigate: NavigateFunction;
}

const CategoriesWrapper: FC<ICategoriesWrapperProps> = ({categories, user, navigate, ...props}) => {
  const [filteredCategories, setFilteredCategories] = useState<IGetCategoryByIdResponce[]>([])
  const filterCategoriesAsPosition = (categories: IGetCategoryByIdResponce[], positions = 10) => {
    let position = 1
    const filtered = categories.filter(categories => categories.category.position === 0)
    const positioned: IGetCategoryByIdResponce[] = []
    categories.forEach(category => {
      const categoryAsPosition = categories.find(posCategory => posCategory.category.position === position)
      if (categoryAsPosition) {
        positioned.push(categoryAsPosition)
      }
      position += 1
    })

    return [...positioned, ...filtered]
  }

  useEffect(() => {
    setFilteredCategories(filterCategoriesAsPosition(categories, 10))
  }, [categories])
  

  return (
    <StyledForumsWrapper>
        {filteredCategories?.length
          ? filteredCategories.map((category) => 
            <Category categoryInfo={category} key={category.category.id} user={user} navigate={navigate}/>
          )
          : ''
        }
    </StyledForumsWrapper>
  )
}

export default CategoriesWrapper