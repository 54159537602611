import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { StyledBr } from '../../../../Projects/constants/Styled'
import { StyledHeader } from '../../../constants/styled'
import { IAssignProps } from '../../../models/IAssignProps'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import { useAppDispatch, useAppSelector } from './../../../../../hooks/redux'
import { assignNavReducer } from './../../../store/reducers/AssignNavReducer'
import { Colors } from '../../../../../models/Colors'
import { useParams } from 'react-router-dom'
import { tasksReducer } from '../../../store/reducers/TasksReducer'
import { useTranslation } from 'react-i18next'
import { ImagesList } from '../../../../ImagesList'
import { EImagesPage } from '../../../../ImagesList/store/ImagesListReducer'

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 65px;
`
export const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  -webkit-align-content: flex-start;
  gap: 10px;
  width: 100%;
  height: 69vh;
  overflow: auto;
  padding: 15px 10px 0 10px;

  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

const ProjectAssign: FC<IAssignProps> = ({ projectInfo, navigate, param, ...props }) => {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { images, selectedImages } = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {
    dispatch(tasksReducer.actions.setTabClicked('unannotated'))
    if (params.tab === 'assign') {
      dispatch(tabsSlice.actions.setActiveTab('assign'))
    }
    if (params.id) {
      dispatch(tasksReducer.actions.setCurrentProjectId(params.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAnnotateImagesClick = () => {
    dispatch(assignNavReducer.actions.setVisible())
  }

  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo.name ? (
          <h3>
            {projectInfo.name} - {t('authorized.project.assign.assignImages')}
          </h3>
        ) : (
          <h3>{t('authorized.project.assign.assignImages')}</h3>
        )}
        <FlexContainer direction="row" justify="flex-end" align="center" widthProps="520px" gap="15px">
          {images?.length || selectedImages?.length ? (
            <ProjectButton onClick={onAnnotateImagesClick} widthProps="140px">
              {t('buttons.createTask')}
            </ProjectButton>
          ) : (
            ''
          )}
        </FlexContainer>
      </StyledHeader>
      <StyledBr />

      <ImagesList page={EImagesPage.assignPage} />
    </StyledProjects>
  )
}

export default ProjectAssign
