import React, { useEffect, useState } from 'react'
import { NavigateFunction } from 'react-router-dom';
import { IProject } from '../../../../../services/ProjectService';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { StyledDateText, StyledErrorText, StyledHeader, StyledStatDateWrapper, StyledStatistics, StyledStatisticsContentWrapper, StyledUserChartsWrapper } from '../../../constants/styled';
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer';
import { StyledUsersGroupIcon } from '../../ProjectsTabsNav';
import { ProjectBr } from '../../../../../styles/GlobalStyles';
import Input from '../../../../../UI/Input/Input';
import moment from 'moment';
import DropdownSelect from '../../../../../UI/SelectDropdown/DropdownSelect';
import StatisticsTable from './StatisticsTable/StatisticsTable';
import Loader from '../../../../../UI/Loader/Loader';
import { Colors } from '../../../../../models/Colors';
import { statisticsSlice } from '../../../store/reducers/StatisticsReducer';
import { asyncGetActions, asyncGetStats, getUsersTasksStat } from '../../../../../store/reducers/AcyncActions';
import ChartFromSelected from './ChartsFromSelected';
import { tabsSlice } from '../../../store/reducers/TabsReducer';
import { useTranslation } from 'react-i18next';

export interface StatisticsOption {
  title: string;
  value: string;
  type: string;
};

interface IUserStatistics {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: any;
  roleNumber: number;
}

interface ICompareReq {
  fromDate: string;
  toDate: string;
  userIds: string;
  actions: string[];
  selectedRole: string;
}

const ProjectStatistics: React.FC<IUserStatistics> = ({navigate, param, projectInfo, roleNumber, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const projectUsers = useAppSelector(state => state.WorkspacesReducer.projectUsersInfo)
  const {isLoading, error, selectedUsers, filteredUsersCounter, statActions, actionStats} = useAppSelector(state => state.StatisticsReducer)

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days').startOf('isoWeek').format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'))

  const [options, setOptions] = useState([
    // MODER
    {type: 'moder', title: t('authorized.project.statistics.approved'), value: 'image_approved', selected: false},
    {type: 'moder', title: t('authorized.project.statistics.rejected'), value: 'image_rejected', selected: false},

    //LABELLER
    {type: 'labeller', title: t('authorized.project.statistics.annotatedImages'), value: 'image_annotated', selected: true},
    {type: 'labeller', title: t('authorized.project.statistics.markups'), value: 'new_markup', selected: true},
  ])

  const [selectedRole, setSelectedRole] = useState<StatisticsOption>({type: 'labeller', value: 'project_annotator', title: t('authorized.project.statistics.labellers')})

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [optionsTable, setOptionsTable] = useState<StatisticsOption[]>([
    {type: 'moder', value: 'project_moderator', title: t('authorized.project.statistics.moderators')},
    {type: 'labeller', value: 'project_annotator', title: t('authorized.project.statistics.labellers')},
  ])

  const [previousLoadStat, setPreviousLoadStat] = useState<ICompareReq>({
    fromDate: '',
    toDate: '',
    userIds: '',
    actions: [],
    selectedRole: ''
  })

  const deepEqual = (firstArg: ICompareReq, secondArg: ICompareReq) => {
    return JSON.stringify(firstArg) === JSON.stringify(secondArg);
  }

  const loadStatistics = async (isFirstLoad: boolean, userIds: string, projectId: string, fromDate:string, toDate: string, minMax: boolean, actions: string[]) => {
    try {
      console.log('loadStatistics START')
      if (!actions) {
        console.log('NO ACTIONS')
        return
      }
      dispatch(statisticsSlice.actions.setIsLoading(true))
      setPreviousLoadStat({fromDate, toDate, userIds, actions, selectedRole: selectedRole?.value})
      for (const item of actions) {
        await dispatch(asyncGetStats(true, userIds, projectId, fromDate, toDate, item, true))
      }
      dispatch(getUsersTasksStat(projectId, userIds, selectedRole.value))
      dispatch(statisticsSlice.actions.setIsLoading(false))
    } catch (error) {
      dispatch(statisticsSlice.actions.setError('Error on loadStatistics'))
      console.log('loadStatistics', error)
    }
  }

  useEffect(() => {
    if (statActions?.length) {
      dispatch(asyncGetActions())
    }
    if (projectUsers?.length && statActions.length) {
      const filteredProjectUsers = projectUsers.filter(user => user?.user?.isActive)
      console.log('filteredProjectUsers', filteredProjectUsers)
      let userIds = ''
      const actions: string[] = []

      options.forEach(option => {
        if (option.selected) {
          actions.push(option.value)
        }
      })
      filteredProjectUsers.forEach((user, index) => {
        index === filteredProjectUsers.length - 1 
          ? userIds += user.user.id
          : userIds += user.user.id + ','
      })
      const isReqEqualPrevious = deepEqual(previousLoadStat, {fromDate, toDate, userIds, actions, selectedRole: selectedRole?.value})
      if (!isReqEqualPrevious) {
        loadStatistics(true, userIds, projectInfo.id, fromDate, toDate, true, actions)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsers, selectedRole, options, fromDate, toDate])

  useEffect(() => {
    dispatch(tabsSlice.actions.setActiveTab('statistics'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFromDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== fromDate) {
      setFromDate(e.target.value)
    }
  }

  const onToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
    if (e.target.value !== fromDate) {
      setToDate(e.target.value)
    }
  }

  //dev stat
  let sp = [
    {
        userId: "124mmgujp/cv3",
        firstname: 'hFloppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "12fahdgvnfs3",
        firstname: 'Fjloppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "1fh2o3",
        firstname: 'kFloppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "121asdfaasdfasdf233",
        firstname: 'lFloppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
      userId: "124mmgujp/cv3",
      firstname: 'Floppa',
      lastname: 'cat',
      action: 'updated the labeling instructions at Task.',
      date: '08-08-2023 at 19:00'
  },
  {
      userId: "12fahdgvnfs3",
      firstname: 'Floppa',
      lastname: 'cat',
      action: 'updated the labeling instructions at Task.',
      date: '08-08-2023 at 19:00'
  },
  {
      userId: "1fh2o3",
      firstname: 'Floppa',
      lastname: 'cat',
      action: 'updated the labeling instructions at Task.',
      date: '08-08-2023 at 19:00'
  },
  {
      userId: "121asdfaasdfasdf233",
      firstname: 'Floppa',
      lastname: 'cat',
      action: 'updated the labeling instructions at Task.',
      date: '08-08-2023 at 19:00'
  },
]

useEffect(() => {
  if (selectedRole) {
    const optionsCopy = JSON.parse(JSON.stringify(options))
    if (selectedRole?.type === 'moder') {
      dispatch(statisticsSlice.actions.setSelectedUsers([]))
      optionsCopy.forEach((option: any) => {
        if (option.type === 'labeller') {
          option.selected = false
        }
        if (option.type === 'moder') {
          option.selected = true
        }
      })
    }
    if (selectedRole?.type === 'labeller') {
      dispatch(statisticsSlice.actions.setSelectedUsers([]))
      optionsCopy.forEach((option: any) => {
        if (option.type === 'moder') {
          option.selected = false
        }
        if (option.type === 'labeller') {
          option.selected = true
        }
      })
    }
    setOptions(optionsCopy)
  }

// eslint-disable-next-line react-hooks/exhaustive-deps
},[selectedRole])

const handleOptionClick = (value: string) => {
  const optionsCopy = [...options]
  optionsCopy.forEach(option => {
    if (option.value === value) {
      option.selected === true
        ? option.selected = false
        : option.selected = true
    }
  })
  setOptions(optionsCopy)
}
  
  return (
    <StyledStatistics>
        <StyledHeader>
          <FlexContainer direction='row' justify='space-between' align='center' gap="10px" widthProps='100%'>
            <FlexContainer direction='row' justify='flex-start' align='center' gap="10px">
              <StyledUsersGroupIcon/>
              <h3>{projectInfo?.name || 'Project'} {t('authorized.project.statistics.statistics')}</h3>
            </FlexContainer>
            <StyledStatDateWrapper>
              <StyledDateText>{t('other.from')}</StyledDateText>
              <Input type='date' widthProps='140px' value={fromDate} onChange={onFromDateChange} onkeydown="return false" onkeypress="return false" min={projectInfo?.createdAt?.slice(0, 10)} max={moment().format('YYYY-MM-DD')}/>
              <StyledDateText>{t('other.to')}</StyledDateText>
              <Input type='date' widthProps='140px' value={toDate} onChange={onToDateChange} onkeydown="return false" onkeypress="return false" min={projectInfo?.createdAt?.slice(0, 10)} max={moment().format('YYYY-MM-DD')}/>
              <DropdownSelect selected={null} options={options} placeholder={t('authorized.project.statistics.graphs')} mode='rows' onChange={handleOptionClick}/>
            </StyledStatDateWrapper>
          </FlexContainer>
        </StyledHeader>
        <ProjectBr/>

        <StyledStatisticsContentWrapper>
          <StatisticsTable 
            roleNumber={roleNumber} 
            projectInfo={projectInfo}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            optionsTable={optionsTable}
          />
        
          <StyledUserChartsWrapper>
            {options.map(option => {
              if (option.selected && !isLoading && !error && selectedUsers?.length && filteredUsersCounter) {
                return (
                  <ChartFromSelected 
                    key={option.value} 
                    option={option} 
                    selectedUsers={selectedUsers}
                    selectedRole={selectedRole}
                    filteredUsersCounter={filteredUsersCounter}
                    actionStats={actionStats}
                  />
                )
              }
            })}

            {error && !isLoading ? <StyledErrorText visible fsize='18px' colorProps={Colors.red}>{error}</StyledErrorText> : ''}
            {isLoading && !error ? <Loader/> : ''}
            {!selectedUsers?.length && !error && !isLoading && filteredUsersCounter ? <StyledErrorText visible fsize='18px' colorProps={Colors.WHITE}>{t('authorized.project.statistics.selectUsers')}</StyledErrorText> : ''}
            {!filteredUsersCounter && !error && !isLoading ? <StyledErrorText visible fsize='18px' colorProps={Colors.WHITE}>{t('authorized.project.statistics.noTypeUsers')} `{selectedRole?.title}`</StyledErrorText>: ''}
          </StyledUserChartsWrapper>
        </StyledStatisticsContentWrapper>
        
    </StyledStatistics>
  )
}

export default ProjectStatistics