import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import { IButtonProps } from '../../../models/IButtonProps'

const StyledButton = styled.div<IButtonProps>`
    display:flex;
    align-items: center;
    justify-content:center;
    position: relative;

    width: ${props => props.widthProps || '137px'} ;
    padding:${props => props.paddingProps || '0px'};
    height:${props => props.heightProps || '70px'};
    border-top: 5px solid transparent;
    border-top: ${({ active }) => active ? `5px solid ${Colors.orange}` : ''};
    background: ${({ active }) => active ? Colors.black : Colors.dark};

    font-size: ${props => props.FontSizeProps || '14px'};
    font-weight: bold;
    color: ${Colors.orange};
    text-align: center;
    transition: all 0.1s linear;

    cursor: pointer;
    &:hover {
        background: ${Colors.black};
        border-top: 5px solid ${Colors.orange};
    }

    @media (max-width: 1010px) {
      width: 100px;
    }
    @media (max-width: 860px) {
      width: 80px;
      font-size: 14px;
    }
    @media (max-width: 650px) {
      width: 60px;
      font-size: 12px;
    }
`

const HeaderButton: FC<IButtonProps> = ({children, ...props}) => {
  return (
      <StyledButton {...props}>
        {children}
      </StyledButton>
  )
}

export default HeaderButton