import React, { FC } from 'react'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { StyledVersionOptionInfo, StyledVersionOptionName } from '../../../constants/styled'
import { IFullVersionWithArchive } from '../../../store/reducers/VersionsReducer'
import { useTranslation } from 'react-i18next'

interface IVersionProcessingInfoProps {
  type: string
  currentVersion: IFullVersionWithArchive
}

const VersionProcessingInfo: FC<IVersionProcessingInfoProps> = ({ type, currentVersion, ...props }) => {
  const { t } = useTranslation()
  return (
    <FlexContainer direction="row" justify="flex-start" align="flex-start" paddingProps="15px" gap="35px">
      <StyledVersionOptionName>
        <h6>{type === 'PREPROCESSING' ? t('authorized.project.generateAndVersions.generateTitle3') : t('authorized.project.generateAndVersions.generateTitle4')}</h6>
      </StyledVersionOptionName>
      <FlexContainer direction="column" justify="flex-start" align="flex-start" gap="15px">
        {type === 'PREPROCESSING' ? (
          currentVersion?.versionInfo?.preprocessing?.length ? (
            currentVersion?.versionInfo?.preprocessing?.map((option, index) => (
              <FlexContainer key={Date.now() + index} direction="row" gap="30px">
                <StyledVersionOptionName>{option.method.charAt(0).toUpperCase() + option.method.slice(1)}: </StyledVersionOptionName>
                <StyledVersionOptionInfo>
                  <FlexContainer direction="column" justify="flex-start" align="flex-start">
                    <StyledVersionOptionInfo>{t('authorized.project.generateAndVersions.applied')}</StyledVersionOptionInfo>
                    <StyledVersionOptionInfo>
                      {option.method === 'resize' ? option.params.fit + ' ' + option.params.width + 'x' + option.params.height : ''}
                      {option.method === 'tile' ? option.params.columns + ' Columns x ' + option.params.rows + ' Rows' : ''}
                    </StyledVersionOptionInfo>
                  </FlexContainer>
                </StyledVersionOptionInfo>
              </FlexContainer>
            ))
          ) : (
            <StyledVersionOptionInfo>{t('authorized.project.generateAndVersions.noPreprocessing')}</StyledVersionOptionInfo>
          )
        ) : currentVersion?.versionInfo?.augmentation?.length ? (
          currentVersion?.versionInfo?.augmentation?.map((option, index) => (
            <FlexContainer key={Date.now() + index} direction="row" gap="30px">
              <StyledVersionOptionName>{option.method.charAt(0).toUpperCase() + option.method.slice(1)}: </StyledVersionOptionName>
              <StyledVersionOptionInfo>
                <FlexContainer direction="column" justify="flex-start" align="flex-start">
                  <StyledVersionOptionInfo>
                    {option.method === 'blur' ? option.params?.maxBlur + '%' : ''}
                    {option.method === 'noise' ? option.params?.maxNoisePercentage + '%' : ''}
                    {option.method === 'brightness' ? option.params?.brightnessPercentage + '% ' + option.params?.mode : ''}
                  </StyledVersionOptionInfo>
                  <StyledVersionOptionInfo>
                    {t('authorized.project.generateAndVersions.applyTo')} {option.percentage}
                    {t('authorized.project.generateAndVersions.generatePercents')}
                  </StyledVersionOptionInfo>
                </FlexContainer>
              </StyledVersionOptionInfo>
            </FlexContainer>
          ))
        ) : (
          <StyledVersionOptionInfo>{t('authorized.project.generateAndVersions.noAugmentation')}</StyledVersionOptionInfo>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default VersionProcessingInfo
