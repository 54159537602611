import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SSETypes } from './SSETypes'

interface IReducerState {
  tasksProgresses: any
  controller: AbortController | null
}

const initialState: IReducerState = {
  tasksProgresses: [],
  controller: null
}

export const eventSourceSlice = createSlice({
  name: 'eventSource',
  initialState,

  reducers: {
    setController(state, action: PayloadAction<AbortController>) {
      state.controller = action.payload
    },
    destroySSEConnect(state) {
      state.controller?.abort()
    },
    setEvent(state, action: PayloadAction<any>) {
      switch (action.payload.type) {
        case SSETypes.TAKS_PROGRESS:
          console.log(SSETypes.TAKS_PROGRESS, action.payload)
          
          break
        default:
          break
      }
    }
  }
})

export default eventSourceSlice.reducer
