import React, { FC } from 'react'
import { StyledClassCardTitle, StyledHealthClassBalanceCard } from '../../../constants/styled';
import BalanceClass from './BalanceClass';
import { IClassBalance } from '../../../../../services/DatasetsService';
import { useTranslation } from 'react-i18next';

interface IHealthClassBalanceProps {
    classes: IClassBalance[]
    total: number;
}

const HealthClassBalance: FC<IHealthClassBalanceProps> = ({classes, total, ...props}) => {
  const { t } = useTranslation()
  return (
    <StyledHealthClassBalanceCard>
      <StyledClassCardTitle>{t('authorized.project.healthCheck.classBalance')}</StyledClassCardTitle>
      {classes?.length
        ? classes?.map((balanceClass, index)=>
          <BalanceClass key={performance.now() + index} amount={balanceClass.amount} total={total} color={balanceClass.color} name={balanceClass.name} represented={balanceClass.represented}/>
        )
        : ''
      }
    </StyledHealthClassBalanceCard>
  )
}

export default HealthClassBalance