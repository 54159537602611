import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import {
  IProjectUploadTabWrapper,
  IStyledColoredLineProps,
  IStyledCroppWrapperProps,
  IStyledHealthClassName,
  IStyledLicenseCardProps,
  IStyledQuotaImageProps,
  IStyledRangeNumberProps,
  IStyledSettingsNavButtonProps,
  IUserCardAvatarProps
} from './interfaces'
import userCardAvatar from '../../../assets/icons/avatar.png'
import coinsIcon from '../../../assets/icons/coins.svg'
import uploadIcon from '../../../assets/icons/upload-svgrepo-com.svg'

export const StyledCoinsIcon = styled.i<IUserCardAvatarProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${coinsIcon});
  mask-image: url(${coinsIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '30px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '30px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '0')};

  border: 1px solid ${Colors.orange};
  border-radius: 5px;
`

//main
export const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  padding: 90px 15px 20px 15px;

  position: relative;
`

export const SettingsTabsNav = styled.div`
  border-radius: 5px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  height: 85vh;

  position: sticky;
  top: 60px;
`

export const StyledSettingsTabContent = styled.div`
  border-radius: 5px;
  width: 100%;
  height: auto;
`

export const StyledNavSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
`

export const StyledNavGroupsSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  width: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

export const StyledSectionTitle = styled.h4`
  color: ${Colors.WHITE};
  font-weight: bold;
  padding: 5px 5px 0 5px;
  text-align: left;
  width: 100%;
`

//button
export const StyledSettingsNavButton = styled.div<IStyledSettingsNavButtonProps>`
  height: 40px;
  width: 100%;
  padding: 8px;

  color: ${({ active }) => (active ? Colors.orange : Colors.WHITE)};
  background: ${({ active }) => (active ? 'rgba(255, 152, 27, 0.226)' : 'none')};
  border-radius: 4px;

  cursor: pointer;
  transition: all 0.1s linear;

  :hover {
    color: ${Colors.orange};
    background: ${({ active }) => (active ? 'rgba(255, 152, 27, 0.226)' : '#222222c0')};
  }
`

//content
export const StyledContentSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
  height: auto;

  background-color: ${Colors.dark};

  padding: 18px 20px 18px 20px;
  margin: 10px 0;
`

export const StyledContentSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 500;
    font-size: 18px;
  }
`

export const StyledContentSectionTitle = styled.div`
  font-weight: 600;
  font-size: 21px;
  height: 25px;
`

export const StyledContentSectionSubTitle = styled.h4`
  font-size: 16px;
`

export const StyledContentSectionHeader = styled.section``

//user
export const StyledUserCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 90px;
  padding: 15px;

  border: 1px solid ${Colors.btngray};
  border-radius: 5px;
`

export const StyledUserCardAvatar = styled.i<IUserCardAvatarProps>`
  display: block;
  background: url(${(props) => props.img || userCardAvatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: ${(props) => (props.widthProps ? props.widthProps : '45px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '45px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '0')};
  border-radius: 50px;

  cursor: pointer;
  transition: all 0.1s linear;
`

export const StyledAddAvatar = styled.i<IUserCardAvatarProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${uploadIcon});
  mask-image: url(${uploadIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '30px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '30px')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '0')};

  border: 1px solid ${Colors.orange};
  border-radius: 5px;

  opacity: 0;
  position: absolute;
  top: 3px;
  left: 4px;
  z-index: 10;

  cursor: pointer;
  transition: all 0.1s linear;
`

export const StyledAvatarWrapper = styled.div`
  position: relative;
  border-radius: 50px;
  width: 50px;
  height: 50px;

  :hover ${StyledUserCardAvatar} {
    opacity: 0;
  }

  :hover ${StyledAddAvatar} {
    opacity: 1;
  }

  :hover {
    border: 1px solid ${Colors.orange};
  }
`

export const StyledUserCredentials = styled.h5`
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledUserEmail = styled.h5`
  max-width: 190px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #c1c1c1;
`

export const StyledContentCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 90px;
  padding: 15px;

  border: 1px solid ${Colors.btngray};
  border-radius: 5px;
`

export const StyledCroppWrapper = styled.div<IStyledCroppWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;

  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

//Quota

export const StyledQuotaWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledHealthClassName = styled.h3`
  transition: all 0.1s linear;
`

export const StyledPackageQuotaName = styled.span`
  font-size: 16px;
  font-weight: 500;
  height: 20px;
`

export const StyledQuotaTotal = styled.div`
  font-size: 14px;
  font-weight: 400;
  height: 20px;
`

export const StyledQuotaPrice = styled.div`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 20px;
  text-align: right;
`

export const StyledQuotaPriceDescription = styled.div`
  font-size: 16px;
  font-weight:bold;
  text-align: center;
  cursor: pointer;
  color: ${Colors.superLightRed};

  transition: all 0.1s linear;
`

export const StyledBalanceClass = styled.div<IStyledHealthClassName>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  width: 100%;
  font-size: 16px;

  transition: all 0.1s linear;
  :hover {
    background: ${Colors.blackDarker};
  }
  :hover ${StyledHealthClassName} {
    color: ${({ color }) => (color ? color : '#fff')};
  }
`

export const StyledBalanceLineStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
`

export const StyledLineWrapper = styled.div`
  max-width: 500px;
  min-width: 500px;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: ${Colors.gray};

  position: relative;
`

export const StyledColoredLine = styled.div<IStyledColoredLineProps>`
  background-color: ${({ color }) => (color ? color : '#fff')};
  width: ${({ percentage }) => (percentage ? percentage + '%' : 0)};
  max-width: 500px;
  height: 5px;
  border-radius: 2px;
  position: absolute;
  left: 0;
`

export const StyledAmount = styled.span`
  min-width: 120px;
  text-align: left;
`

export const StyledQuotaImage = styled.i<IStyledQuotaImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${(props) => props.srcProps});
  mask-image: url(${(props) => props.srcProps});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  min-width: 25px;
`

export const StyledPackageQuotaImage = styled.i<IStyledQuotaImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${(props) => props.srcProps});
  mask-image: url(${(props) => props.srcProps});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
  min-width: 25px;
`

export const StyledQuotaCardImage = styled.i<IStyledQuotaImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${(props) => props.srcProps});
  mask-image: url(${(props) => props.srcProps});
  background-color: ${Colors.orange};
  width: 35px;
  height: 35px;
  min-width: 35px;
`

//avatar form

export const StyledInputLabel = styled.div`
  text-align: center;
  font-weight: 600;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${Colors.textgray};
  img {
    width: 100px;
  }
`

export const StyledTypesText = styled.h4`
  width: 300px;
  text-align: center;
  font-size: 16px;
  color: ${Colors.gray};
  margin-top: 5px;
`

export const StyledDropFileDiv = styled.div<IProjectUploadTabWrapper>`
  position: relative;
  width: 100%;
  height: 300px;
  border: 2px dashed ${Colors.orange};
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  opacity: ${({ isDragProps }) => (isDragProps ? `opacity: 0.6;` : '')};
  .dragover {
    opacity: 0.6;
  }

  :hover {
    opacity: 0.6;
  }
`

export const StyledDropFileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 100;
`

export const StyledImgContainer = styled.div`
  border-radius: 50%;
  margin-top: 20px;
  border: 3px solid ${Colors.orange};
  img {
    display: block;
    border-radius: 50%;
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 15px;
  width: 90%;

  margin-bottom: 20px;

  .horizontal-slider {
    width: 100%;
    height: 48px;
    margin: auto;
    cursor: pointer;
  }

  /* .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: red;
    border: 8px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    
  } */

  .example-thumb.active {
    border: 2px solid orange;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #ddd;
  }

  .example-track.example-track-1 {
    background: #ddd;
  }

  .example-track.example-track-2 {
    background: #ddd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    /* top: 20px; */
    /* width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px; */
  }
`

export const StyledRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 15px;
  width: 90%;

  margin-bottom: 20px;

  .horizontal-slider {
    width: 100%;
    height: 48px;
    margin: auto;
    cursor: pointer;
  }

  /* .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: red;
    border: 8px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    
  } */

  .example-thumb.active {
    border: 2px solid orange;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #ddd;
  }

  .example-track.example-track-1 {
    background: #ddd;
  }

  .example-track.example-track-2 {
    background: #ddd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    /* top: 20px; */
    /* width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px; */
  }
`

export const StyledThumb = styled.div`
  position: absolute;
  top: 10px;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  border: 2px solid ${Colors.gray};
  background-color: #fff;
  color: ${Colors.gray};
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
`

export const StyledThumbValue = styled.div`
  position: absolute;
  top: 25px;
  left: -7px;

  text-align: center;
  min-width: 35px;
  height: 25px;
  color: #fff;
`

export const StyledRangeNumber = styled.span<IStyledRangeNumberProps>`
  color: ${({ isCoincidence }) => (isCoincidence ? Colors.orange : '#fff')};
  width: 55px;
  text-align: center;
  font-size: 16px;
`

export const StyledInputWrapper = styled.div<IStyledCroppWrapperProps>`
  width: 100%;
  height: 100%;
  padding: 0 10px 10px 10px;
  border-radius: 20px;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dragover {
    opacity: 0.6;
  }
`
//Licenses
export const StyledLicenseCard = styled.div<IStyledLicenseCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 312px;
  min-height: 375px;
  padding: 28px;

  border: 1px solid ${({ active }) => (active ? Colors.orange : Colors.gray)};
  border-radius: 6px;

  transition: all 0.2s linear;

  :hover {
    box-shadow: 0 7px 16px rgba(22, 22, 22, 0.6), 10px 20px 20px rgba(22, 22, 22, 0.6);
    border-color: ${Colors.orange};
  }
`

export const StyledLicenseName = styled.div`
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
  letter-spacing: normal;
`

export const StyledLicensePriceWrapper = styled.div`
  position: relative;
`

export const StyledBaseCost = styled.div`
  text-decoration: line-through;
  position: absolute;
`

export const StyledLicensePrice = styled.div`
  font-size: 28px;
  line-height: 48px;
  font-weight: 600;
  margin-top: 10px;
`

export const StyledDiscount = styled.div`
  padding: 3px 13px;
  border-radius: 15px;
  color: ${Colors.orange};
  background: rgba(255, 152, 27, 0.226);
`

export const StyledNoDiscountText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: normal;
  min-height: 100px;
`

export const StyledCardQuota = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 14px;
`

export const StyledParagraph = styled.p`
  padding: 25px 15px;
  font-size: 16px;
`

//edit quota
export const StyledSlideP = styled.p`
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 16px;
`

export const StyledCenteredText = styled.p`
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
`

export const StyledPackageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  padding: 20px 20px;

  border: 1px solid ${Colors.gray};
  border-radius: 10px;

  width: 280px;
  min-height: 250px;
  height: auto;

  position: relative;

  transition: all 0.1s linear;
  cursor: pointer;

  :hover {
    border-color: ${Colors.orange};
    box-shadow: 0 7px 16px rgba(22, 22, 22, 0.6), 0px 10px 20px rgba(22, 22, 22, 0.6);
  }
`

export const StyledQuotaCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  padding: 20px 20px;

  border: 1px solid ${Colors.gray};
  border-radius: 10px;

  width: 280px;
  height: 250px;

  position: relative;

  transition: all 0.1s linear;

  :hover {
    border-color: ${Colors.orange};
    box-shadow: 0 7px 16px rgba(22, 22, 22, 0.6), 0px 10px 20px rgba(22, 22, 22, 0.6);
  }
`

export const StyledPackageTitle = styled.h3`
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  min-height: 50px;
`

export const StyledPackageQuota = styled.div``

export const StyledPackagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 25px;
`

export const StyledPackageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
`

export const StyledPackageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
`

export const StyledPackageQuotaTooltipWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const StyledCardQuotaText = styled.p`
  font-size: 15px;
  font-weight: 500;
`

export const LicenceSettingsTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

export const StyledLittleText = styled.p`
  font-size: 15px;
`
