import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer'
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../../../../UI/Input/Input'
import DatasetsService, { ISummaryDataset } from '../../../../../../../services/DatasetsService'
import { asyncRenameDataset } from '../../../../../../../store/reducers/AcyncActions'
import { useTranslation } from 'react-i18next'
import { IProject } from '../../../../../../../services/ProjectService'

interface IRenameJobFormProps {
  projectInfo: IProject;
  datasetInfo: ISummaryDataset;
}

const RenameDatasetJobForm: FC<IRenameJobFormProps> = ({projectInfo, datasetInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [jobName, setJobName] = useState(datasetInfo?.name ? datasetInfo.name : '')
  const [datasetDesc, setDatasetDesc] = useState('')

  useEffect(() => {
    const getDatasetFullInfo = async () => {
      const datasetFullInfo = await DatasetsService.getDatasetById(datasetInfo.dataset)
      if (datasetFullInfo.data.dataset.description) {
        setDatasetDesc(datasetFullInfo.data.dataset.description)
      }
    }
    getDatasetFullInfo()
  },[])

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobName(e.target.value)
  }

  const onButtonRenameClick = () => {
    dispatch(asyncRenameDataset(datasetInfo.dataset, jobName, datasetDesc))
    setModalVisivble()
  }
  
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.project.annotate.forms.newNameTitle')} placeholder={t('forum.enterText')} value={jobName} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton widthProps='140px' onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton widthProps='140px' onClick={onButtonRenameClick}>{t('other.rename')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameDatasetJobForm