import React, { FC } from 'react'
import { IPagesProps } from '../../../models/IPagesProps';
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { SignUpForm } from '../../../modules/RegistrationForm';
import UserNav from '../../../components/UserNav/UserNav';

export const Auth: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <FlexContainer paddingProps='0' heightProps='88vh' justify='center' align='center' direction='column' gap='10px'>
        <SignUpForm navigate={navigate}></SignUpForm>
      </FlexContainer>
    </>
  )
}

