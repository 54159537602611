import React, { FC } from 'react'
import UserNav from '../../../components/UserNav/UserNav'
import { IPagesProps } from '../../../models/IPagesProps'
import { Projects } from '../../../modules/Projects'

export const ProjectsPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='projects' navigate={navigate}></UserNav>
      <Projects navigate={navigate}></Projects>
    </>
  )
}

