import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface IBatch {
    id: string;
    imageCount: number;
    name: string;
    project: string;
    user: string;
    updatedAt: string;
    createdAt: string;
}

export interface IBatchImage {
    displayname: string;
    filename: string;
    size: number;
    sizeInMegaPixels: number;
    width: number;
    height: number;
}

interface ICreateBatchResponce {
    message: string;
    batch: IBatch
}

interface IRenameBatchResponce {
    batch: {
      id: string;
      name: string;
      user: string;
      project: string;
      imageCount: number;
      createdAt: string;
      updatedAt: string;
    };
    message: string;
  }

interface IDeleteBatchById {
    message: string;
}

export interface IGetBatchByIdRes {
    batch: IBatch;
    total: number;
    imagesCount: number;
    remain: number;
    unannotated: IBatchImage[];
    annotated: IBatchImage[];
    unannotatedTotal: number;
    annotatedTotal: number;
}

export interface IAssignBatch {
    batch: string;
    total: number;
    imagesCount: number;
    remain: number;
    unannotated: IBatchImage[];
    annotated: IBatchImage[];
}

export interface IGetBatchesImagesResponce {
    total: number;
    imagesCount: number;
    remain: number;
    batches: IAssignBatch[];
}

interface summaryBatch {
    batch: string;
    name: string;
    imagesCount: number;
}

interface IGetBatchSummary {
    batches: summaryBatch[];
    total: number;
}

//NEW START
export interface INewImage {
    id: string;
    displayName: string;
    imageUrl: string;
    thumbnailUrl: string;
}

export interface IGetBatchesImages_newRes {
    imagesOnPage: number;
    imagesTotal: number;
    remain: number;
    total: number;
    images: INewImage[];
}
export interface IGetBatchImages_newRes {
    imagesOnPage: number;
    imagesTotal: number;
    remain: number;
    total: number;
    images: INewImage[];
}
//NEW END

export default class BatchesService {
    static async createBatch(batchName: string, projectId: string): Promise<AxiosResponse<ICreateBatchResponce>> {
        return $authHost.post<ICreateBatchResponce>('/batches', {name: batchName, project: projectId})
    }

    static async renameBatch(newBatchName: string, batchId: string): Promise<AxiosResponse<IRenameBatchResponce>> {
        return $authHost.put<IRenameBatchResponce>('/batches', {name: newBatchName, id: batchId})
    }

    static async deleteBatch(batchId: string): Promise<AxiosResponse<IDeleteBatchById>> {
        return $authHost.delete<IDeleteBatchById>(`/batches/${batchId}`)
    }

    static async getBatchesSummary(projectId: string): Promise<AxiosResponse<IGetBatchSummary>> {
        return $authHost.get<IGetBatchSummary>(`/batches/summary/${projectId}`)
    }

    //Все неподписанные изображения
    static async getBatchesImages(projectId: string, limit: number, offset: number, status: string, subStatus: string, search: string): Promise<AxiosResponse<IGetBatchesImages_newRes>> {
        return $authHost.get<IGetBatchesImages_newRes>(`/batches/assign/${projectId}?limit=${limit}&offset=${offset}&status=${status}&subStatus=${subStatus}&search=${search}`)
    }

    static async getBatchImages(batchId: string, limit: number, offset: number, status: string, subStatus: string, search: string): Promise<AxiosResponse<IGetBatchImages_newRes>> {
        return $authHost.get<IGetBatchImages_newRes>(`/batches/${batchId}?limit=${limit}&offset=${offset}&status=${status}&subStatus=${subStatus}&search=${search}`)
    }
}
