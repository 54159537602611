import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetBatchByIdRes, IGetBatchesImagesResponce } from '../../../../services/BatchesService';

export interface ISummaryBatch {
    batch: string;
    name: string;
    imagesCount: number;
}

interface renameBatchPayload {
    id: string;
    name: string;
}

interface IReducerState {
    currentBatch: IGetBatchByIdRes;
    summaryBatches: ISummaryBatch[];
    batchesImages: IGetBatchesImagesResponce;
    currentSummaryBatch: ISummaryBatch

    isLoading: boolean;
    error: string | null;
    batchId: string;
}

const initialState: IReducerState = {
    currentBatch: {} as IGetBatchByIdRes,
    currentSummaryBatch: {} as ISummaryBatch,

    summaryBatches: [],
    batchesImages: {} as IGetBatchesImagesResponce,

    isLoading: false,
    error: '',
    batchId:'',
}

export const batchesReducer = createSlice(
{
    name: 'batches',
    initialState,

    reducers: {
        setInitialState(state) {
            state.currentBatch = initialState.currentBatch;
            state.summaryBatches = [];
            state.batchesImages = initialState.batchesImages
            state.isLoading = false;
            state.error = '';
            state.currentSummaryBatch = initialState.currentSummaryBatch
        },
        setAllImagesInitialState(state) {
            state.batchesImages = initialState.batchesImages;
            state.summaryBatches = [];
            state.currentBatch = initialState.currentBatch;
        },
        setLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setErrorState(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        

        setCurrentBatch(state, action: PayloadAction<IGetBatchByIdRes>) {
            state.currentBatch = action.payload;
        },

        setCurrentSummaryBatch(state, action: PayloadAction<ISummaryBatch>) {
            state.currentSummaryBatch = action.payload;
        },

        setSummaryBatches(state, action: PayloadAction<ISummaryBatch[]>) {
            state.summaryBatches = action.payload;
        },
        setAllBatchesImages(state, action: PayloadAction<IGetBatchesImagesResponce>) {
            state.batchesImages = action.payload
        }, 

        renameCurrentBatch(state, action: PayloadAction<string>) {
            state.currentBatch.batch.name = action.payload
        },

        renameBatch(state, action: PayloadAction<renameBatchPayload>) {
            const findedBatch = state.summaryBatches.find(batch => batch.batch === action.payload.id)
            if (findedBatch) {
                findedBatch.name = action.payload.name
            }
        },
        deleteBatch(state, action: PayloadAction<string>) {
            console.log('summaryBatches', state.summaryBatches)
            state.summaryBatches = state.summaryBatches.filter(batch => batch.batch !== action.payload)
        }, 
        setCurrentBatchId(state, action: PayloadAction<string>) {
            state.batchId = action.payload
        },
        
    }
},

)

export default batchesReducer.reducer;