import React, { FC, useState } from 'react'
import { IGetCategoryByIdResponce, ISubcategory } from '../../../../../services/ForumService'
import { useAppDispatch } from '../../../../../hooks/redux'
import { asyncPutCategory } from '../../../../../store/reducers/AcyncActions'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import EditCategoryFormMain from './EditCategoryFormMain'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import Input from '../../../../../UI/Input/Input'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IEditCategoryFormProps {
  categoryInfo: IGetCategoryByIdResponce
  isSub: boolean
  subInfo?: ISubcategory
  navigate: NavigateFunction
}

const EditDescriptionForm: FC<IEditCategoryFormProps> = ({ isSub, navigate, categoryInfo, subInfo }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [titleValue, setTitleValue] = useState('')

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitleValue(e.target.value)
  }

  const onRenameClick = () => {
    if (titleValue && isSub && subInfo) {
      dispatch(asyncPutCategory('10', categoryInfo, subInfo.id, subInfo.name, titleValue, categoryInfo.category.id))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    if (titleValue && !isSub) {
      dispatch(asyncPutCategory('10', categoryInfo, categoryInfo.category.id, categoryInfo.category.name, titleValue))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Edit Category'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<EditCategoryFormMain navigate={navigate} isSub={isSub} categoryInfo={categoryInfo} />))
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="20px">
      <Input label="Category Description" value={titleValue} onChange={onTitleChange} placeholder="Enter Category Description" />
      <FlexContainer direction="row" justify="center" align="center" gap="50px">
        <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onRenameClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default EditDescriptionForm
