import React, { FC, useState } from 'react'
import FlexContainer from '../../../../../../../../components/FlexContainer/FlexContainer';
import { useAppDispatch } from '../../../../../../../../hooks/redux';
import { asyncRenameBatch } from '../../../../../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../../../../../store/reducers/modalWindowReducer';
import ProjectButton from '../../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import Input from '../../../../../../../../UI/Input/Input';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../../../services/ProjectService';

interface IRenameBatchFormProps {
  projectInfo: IProject;
  batchInfo: string;
}

const RenameBatchForm: FC<IRenameBatchFormProps> = ({projectInfo, batchInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [batchName, setBatchName] = useState('')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBatchName(e.target.value)
  }

  const onButtonRenameClick = () => {    
    dispatch(asyncRenameBatch(batchName, batchInfo))
    setModalVisivble()
  }
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.project.annotate.forms.newNameTitle')} placeholder={t('forum.enterText')} value={batchName} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onButtonRenameClick}>{t('other.rename')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameBatchForm