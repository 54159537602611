import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../UI/Input/Input'
import { StyledBr, StyledErrorText, StyledInviteForm } from '../../constants/Styled'
import InviteCheckbox from '../InviteCheckbox/InviteCheckbox'
import { validateEmail } from '../../../LoginForm/helpers/validateForms'
import { Colors } from '../../../../models/Colors'
import { setInputFocusError } from '../../../LoginForm/helpers/inputFocusColorChange'
import RolesService, { IGroupMember } from '../../../../services/RolesService'
import { Option } from '../../../../UI/ProjectSelect/ProjectSelect'
import { IGroupUserFullInfo, WorkspacesSlice } from '../../store/reducers/WorkspacesReducer'
import GroupService from '../../../../services/GroupService'
import AccessService from '../../../../services/AccessService'
import UserService from '../../../../services/UserService'
import { StyledLink } from '../../../ProjectModule/constants/styled'
import { useNavigate } from 'react-router-dom'
import { SettingsReducer } from '../../../UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'

interface IInviteUserFormProps {
  roleNumber: number;
}

const InviteUserForm: FC<IInviteUserFormProps> = ({roleNumber, ...props}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const groupInfo = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const groupRoles = useAppSelector(state => state.rolesReducer.groupRoles)

  //inputs state   
  const [emailFocusColor, setEmailFocusColor] = useState(Colors.gray)
  const [inputValue, setInputValue] = useState('')
  const [checkboxesValue, setCheckboxesValue] = useState<any[]>([])

  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [isQuotaError, setIsQuotaError] = useState(false)

  //checkbox
  const [emails, setEmails] = useState<string[]>([])
  const [optionsTable, setOptionsTable] = useState<Option[]>([])

  useEffect(() => {
    if (groupRoles.length) {
      const options: Option[] = []
      groupRoles.forEach(role => {
        options.push({title: role.displayName, value: role.name})
      })

      if (options?.length && roleNumber === 1) {
        setOptionsTable(options)
      }
      if (options?.length && roleNumber === 2) {
        const filteredOptions = options.filter(option => option.value !== 'group_owner')
        setOptionsTable(filteredOptions)
      }
      if (options?.length && roleNumber === 3) {
        const filteredOptions = options.filter(option => option.value !== 'group_owner' && option.value !== 'group_admin')
        setOptionsTable(filteredOptions)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRoles, roleNumber])

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        enterEmailClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, errorText, visible])

  const onUserEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    const validateResult = validateEmail(e.target.value)
    setInputFocusError(setVisible, setErrorText, setEmailFocusColor, validateResult) 
  }

  const enterEmailClickHandler = () => {
    if (inputValue && !errorText) {
      setEmails([...emails, inputValue])
      setInputValue('')
    }
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, setChecked: any) => {
    if (e.target.checked) {
        setChecked(true)
        const checkboxesValueCopy = [...checkboxesValue]
        let targetEmail = e.target.value.split(" ")[0]
        let targetRole = e.target.value.split(" ")[1]
        checkboxesValueCopy.push({email: targetEmail, role: targetRole})

        setCheckboxesValue(checkboxesValueCopy)
        setVisible(false)
        setErrorText('')
        console.log('✅ Checkbox is checked', checkboxesValueCopy);
      } else {
        if (e.target.value) {
            setChecked(false)
            let targetEmail = e.target.value.split(" ")[0]
            const filteredCheckboxesValues = checkboxesValue.filter((value: any) => value.email !== targetEmail)
            setCheckboxesValue(filteredCheckboxesValues)
            console.log('⛔️ Checkbox is NOT checked', filteredCheckboxesValues);
        }
      }
  }

  const onCancelClick = () => {
    setIsQuotaError(false)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onInviteClick = async () => {
      try {
        if (!checkboxesValue?.length) {
          setVisible(true)
          setErrorText(t('authorized.projects.groups.groupManagement.selectUsers'))
          return
        }
        if (checkboxesValue?.length) {
          const objectToAPI: IGroupMember[] = []
          checkboxesValue.forEach(value => {
            const findedRole = groupRoles.find(role => role.name === value.role)
            if (findedRole) {
              objectToAPI.push({user: {email: value.email}, role: {id: findedRole.id}})
            }
          });
          dispatch(WorkspacesSlice.actions.setLoadingStart())
          const responce = await RolesService.addUserToGroup(groupInfo.id, {groupMembers: objectToAPI})
      
          const groupInfoResponce = await GroupService.getOneGroupById(groupInfo.id)
          dispatch(WorkspacesSlice.actions.setGroupInfo(groupInfoResponce.data.group))
      
          const usersInfo: IGroupUserFullInfo[] = []
      
          for (const member of groupInfoResponce.data.group.groupMembers) {
            const userRoleInfo = await AccessService.getRoleById(member.roleId)
            const userInfo = await UserService.getUserById(member.user)
            usersInfo.push({groupRole: userRoleInfo.data.role, user: userInfo.data.user})
          }
      
          dispatch(WorkspacesSlice.actions.setFullGroupUserInfo(usersInfo))
          
          console.log('asyncInviteUsersToGroup', responce)
          dispatch(WorkspacesSlice.actions.setLoadingEnd())

          if (responce.data.failedUsers?.length) {
            setVisible(true)
            setErrorText(t('authorized.projects.groups.groupManagement.errorQuotaUsers'))
            setIsQuotaError(true)
            return
          }

          dispatch(modalWindowSlice.actions.setInititialState())
        }
      } catch (error) {
        dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncInviteUsersToGroup'))
        console.log('asyncInviteUsersToGroup', error)
      }  
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
    navigate(`/settings/group/${groupInfo.id}`)
    setErrorText('')
    setVisible(false)
    setIsQuotaError(false)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction='column' justify='space-around' align='center' gap='30px'>
        <StyledErrorText visible={visible}>{errorText}</StyledErrorText>
        {isQuotaError && <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>}
        <FlexContainer direction='row' justify='space-around' align='center' >
            <Input 
                focusColor={emailFocusColor} 
                borderColor={emailFocusColor} 
                type='text' 
                label={t('notAuthorized.inputTitles.email')} 
                placeholder='email.to.invite@mail.net' 
                value={inputValue} 
                onChange={onUserEmailChangeHandler}
                widthProps='300px'
            />
            <ProjectButton onClick={enterEmailClickHandler} widthProps='90px' FontSizeProps='15px' margin='25px 0 0 0'>{t('buttons.add')}</ProjectButton>
        </FlexContainer>

        <FlexContainer direction='column' justify='flex-start' align='center' gap="5px">
            <FlexContainer direction='row' justify='space-between' paddingProps='0 10px'>
              <span>{t('authorized.project.projectManagement.users')}</span>
              <span>{t('authorized.projects.groups.groupManagement.groupRole')}</span>
            </FlexContainer>
            <StyledBr/>

            <StyledInviteForm>
                {emails?.length ? emails.map((email, index) => 
                  <InviteCheckbox 
                    setCheckboxesValue={setCheckboxesValue} 
                    checkboxesValue={checkboxesValue} 
                    setErrorText={setErrorText} 
                    setVisible={setVisible} 
                    key={email + index} 
                    value={email} 
                    onChange={onCheckboxChange} 
                    title={email}
                    optionsTable={optionsTable}
                  />
                ) : ''}
            </StyledInviteForm>            
        </FlexContainer>

        <FlexContainer direction='row' justify='space-between' paddingProps='0 20px 0 20px'>
          <ProjectButton onClick={onCancelClick} widthProps='130px' FontSizeProps='16px'>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onInviteClick} widthProps='130px' FontSizeProps='16px'>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
    </FlexContainer>
  )
}

export default InviteUserForm