import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface IBoxes {
    label: string;
    xmin: string;
    ymin: string;
    xmax: string;
    ymax: string;
    type: string;
}


export interface IAnnotateReq {
    imageId: string;
    boxes: IBoxes[];
    subStatus?: string;
}

export interface IAnnotateResponce {
    annotated: boolean;
}

export interface IGetImageInfoResponce {
    boxes: any;
    annotated:boolean;
    image: {
        id: string;
        status: string | null | undefined;
        subStatus: string | null | undefined;
        displayname: string;
        imageUrl: string;
        thumbnailUrl: string;
        size: number;
        width: number;
        height:  number;
        sizeInMegaPixels: number;
    }
}

export interface IGetDatasetImageInfoResponce {
    boxes: any;
    annotated:boolean;
    image: string;
    imageInfo: {
        displayname: string;
        filename: string;
        size: number;
        width: number;
        height: number;
        sizeInMegaPixels: number;
        status: string | null | undefined;
        automarkup: boolean;
    }
    dataset: string;
}

export default class LabelService {
    static async annotateImage(annotation: IAnnotateReq): Promise<AxiosResponse<IAnnotateResponce>> {
        return $authHost.post<IAnnotateResponce>('/annotate/', annotation)
    }

    static async getImageInfo(image: string): Promise<AxiosResponse<IGetImageInfoResponce>> {
        return $authHost.get<IGetImageInfoResponce>(`/annotate/${image}`)
    }
}