import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $authHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $formDataAuthHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const $downloadAuthHost = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'blob'
})

$authHost.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
})

$formDataAuthHost.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers["Content-Type"] = 'multipart/form-data';
    return config
})

$downloadAuthHost.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config
})

export {
    $host,
    $authHost,
    $formDataAuthHost,
    $downloadAuthHost
}
