import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../hooks/redux'
import { Colors } from '../../models/Colors'
import { IModalProps } from '../../models/IModalProps'
import { useAppDispatch } from './../../hooks/redux'
import { modalWindowSlice } from './../../store/reducers/modalWindowReducer'
import TopUpBalanceForm from '../../modules/UserSettings/components/TopUpBalanceForm/TopUpBalanceForm'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import Loader from '../Loader/Loader'
import { StyledExportFormText } from '../../modules/ProjectModule/constants/styled'
import ProjectButton from '../Buttons/ProjectButton/ProjectButton'
import Tooltip from '../Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'

interface IModalTitleProps {
  titleColor?: string
  widthProps?: string
  heightProps?: string
  scroll?: number
  activeScroll?: boolean
  isTitleDisabled?: boolean
}

const StyledBackground = styled.div<IModalProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  margin-top: ${({ scroll }) => (scroll ? scroll + 'px' : 0)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000007b;
  transition: opacity 0.1s linear;
  height: ${({ visible }) => (visible ? '100vh' : '0')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  z-index: 1001;
`
const StyledModal = styled.div<IModalTitleProps>`
  max-width: ${(props) => props.widthProps || '380px'};
  width: 100%;
  height: ${(props) => props.heightProps || 'auto'};
  padding: 20px 10px 20px 10px;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1006;

  color: ${Colors.textgray};
`
const StyledTitle = styled.h3<IModalTitleProps>`
  margin: 15px 0;
  color: ${(props) => (props.titleColor ? props.titleColor : '')};
  width: auto;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.black};
`

const Modal: FC<IModalProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.userReduser.user)
  const group = useAppSelector((state) => state.WorkspacesReducer.groupInfo)
  const modalChildren = useAppSelector((state) => state.modalWindowReducer.children)
  const modalTitle = useAppSelector((state) => state.modalWindowReducer.title)
  const modalVisible = useAppSelector((state) => state.modalWindowReducer.visible)
  const modalTitleColor = useAppSelector((state) => state.modalWindowReducer.titleColor)
  const modalWidth = useAppSelector((state) => state.modalWindowReducer.modalWidth)
  const modalHeihgt = useAppSelector((state) => state.modalWindowReducer.modalHeihgt)
  const isCloseability = useAppSelector((state) => state.modalWindowReducer.isCloseability)
  const scroll = useAppSelector((state) => state.modalWindowReducer.scroll)
  const activeScroll = useAppSelector((state) => state.modalWindowReducer.activeScroll)
  const tooltipText = useAppSelector((state) => state.modalWindowReducer.tooltipText)
  const isTitleDisabled = useAppSelector((state) => state.modalWindowReducer.isTitleDisabled)

  function disableScrolling() {
    document.body.style.overflowY = 'hidden'
  }

  function enableScrolling() {
    document.body.style.overflowY = 'auto'
  }

  useEffect(() => {
    if (modalVisible) {
      disableScrolling()
    }
    if (!modalVisible) {
      enableScrolling()
    }
  }, [modalVisible])

  const onBgClick = () => {
    dispatch(modalWindowSlice.actions.setVisibleFalse())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <StyledBackground visible={modalVisible} scroll={scroll} onClick={isCloseability ? onBgClick : () => console.log('click')}>
      <StyledModal scroll={scroll} activeScroll={activeScroll} onClick={(e) => e.stopPropagation()} widthProps={modalWidth} heightProps={modalHeihgt}>
        {isTitleDisabled ? (
          ''
        ) : (
          <FlexContainer direction="row" justify="center" align="center" gap="8px" widthProps="100%">
            <StyledTitle titleColor={modalTitleColor} isTitleDisabled={isTitleDisabled}>
              {modalTitle}
            </StyledTitle>
            {tooltipText ? <Tooltip>{tooltipText}</Tooltip> : ''}
          </FlexContainer>
        )}
        {isTitleDisabled ? '' : <StyledBr></StyledBr>}
        {typeof modalChildren !== 'string' ? modalChildren : ''}
        {modalChildren === 'balance' ? <TopUpBalanceForm user={user} group={group} /> : ''}
        {modalChildren === 'loading' ? (
          <FlexContainer direction="column" justify="center" align="center" margin="20px 0">
            <Loader />
          </FlexContainer>
        ) : (
          ''
        )}

        {modalChildren === 'successDelete' ? (
          <FlexContainer direction="column" justify="center" align="center" gap="30px">
            <StyledExportFormText>Успешно</StyledExportFormText>
            <ProjectButton onClick={onBgClick} widthProps="150px">
              {t('buttons.close')}
            </ProjectButton>
          </FlexContainer>
        ) : (
          ''
        )}

        {modalChildren === 'errorDelete' ? (
          <FlexContainer direction="column" justify="center" align="center" gap="30px">
            <StyledExportFormText>{t('errors.serverError')}</StyledExportFormText>
            <ProjectButton onClick={onBgClick} widthProps="150px">
              {t('buttons.close')}
            </ProjectButton>
          </FlexContainer>
        ) : (
          ''
        )}
      </StyledModal>
    </StyledBackground>
  )
}

export default Modal
