import { AppDispatch } from "../store"
import { IUser } from './../../models/IUser';
import { userSlice } from "./userReducer";
import AuthService from "../../services/AuthService";
import UserService from "../../services/UserService";
import { IGroupUserFullInfo, IProjectUserFullInfo, WorkspacesSlice } from "../../modules/Projects/store/reducers/WorkspacesReducer"
import GroupService from "../../services/GroupService";
import ProjectService, { IProject } from "../../services/ProjectService";
import ImagesService from "../../services/ImagesService";
import BatchesService from "../../services/BatchesService";
import { arraySplitter } from "../../utils/arraySplitter";
import { blopImagesReducer } from "../../modules/ProjectModule/store/reducers/BlopImagesReducer";
import { modalWindowSlice } from './modalWindowReducer';
import { assignNavReducer } from './../../modules/ProjectModule/store/reducers/AssignNavReducer';
import { batchesReducer } from "../../modules/ProjectModule/store/reducers/BatchesReducer";
import { tasksReducer } from './../../modules/ProjectModule/store/reducers/TasksReducer';
import TasksService, { ICreateTaskPayload, ICreateTaskRequest } from "../../services/TasksService";
import { drawTabsSlice } from "../../modules/Draw/store/DrawTabsReducer";
import LabelService from "../../services/LabelService";
import AnnotateService, { IAnnotateReq } from "../../services/AnnotateService";
import DatasetsService, { ICteateDatasetRequest } from "../../services/DatasetsService";
import { datasetsReducer } from "../../modules/ProjectModule/store/reducers/DatasetsReducer";
import { datasetsNavReducer } from '../../modules/ProjectModule/store/reducers/DatasetsNavReducer';
import { versionsReducer } from "../../modules/ProjectModule/store/reducers/VersionsReducer";
import VersionsService from "../../services/VersionsService";
import { ForumReducer } from "../../modules/Forum/store/slices/ForumReducer";
import ForumService from "../../services/ForumService";
import { IGetCategoryByIdResponce } from "../../services/ForumService";
import { generateReducer } from "../../modules/ProjectModule/store/reducers/GenerateReducer";
import AutoMarkupService from "../../services/AutoMarkupService";
import { autoMarkupSlice } from "../../modules/Draw/store/AutoMarkupSlice";
import AccessService from "../../services/AccessService";
import { rolesSlice } from "./rolesReducer";
import RolesService, { IAddUserToGroupReq, IAddUserToProjectReq } from "../../services/RolesService";
import StatsService from "../../services/StatsService";
import { statisticsSlice } from "../../modules/ProjectModule/store/reducers/StatisticsReducer";
import { tabsSlice } from "../../modules/ProjectModule/store/reducers/TabsReducer";
import { SettingsReducer } from "../../modules/UserSettings/reducers/SettingsReducer";
import LicensesService, { IExtendLicensePayload } from "../../services/LicensesService";
import QuotasService, { IExpandPackageReq, IExpandQuotaReq } from "../../services/QuotasService";
import { Colors } from "../../models/Colors";
import PaymentsService from "../../services/PaymentsService";
import { paymentsSlice } from "../../modules/UserSettings/reducers/PaymentsReducer";
import SettingsService from "../../services/SettingsService";
import SubscriptionsService from "../../services/SubscriptionsService";
import i18n from "../../i18n";
import { IAsyncGetListImagesPayload } from "../../modules/ImagesList/store/ImagesListAsyncActions";
import { ISplit } from "../../modules/ImagesList/store/ImagesListReducer";

//set ReducersToInitialState 
export const asyncSetReducersToInitialState = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setInitialState())
    dispatch(blopImagesReducer.actions.setInitialState())
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(assignNavReducer.actions.setInititialState())
    dispatch(batchesReducer.actions.setInitialState())
    dispatch(drawTabsSlice.actions.setInitialState())
    dispatch(datasetsReducer.actions.setInitialState())
    dispatch(datasetsNavReducer.actions.setInitialState())
    dispatch(generateReducer.actions.setInitialState())
    dispatch(versionsReducer.actions.setInitialState())
    dispatch(tabsSlice.actions.setInitialState())
    console.log('asyncSetReducersToInitialState True')
  } catch (error) {
    console.log('Error on asyncSetReducersToInitialState')
  }
}

//async Auth actions
export const asyncRegistration = (userData:IUser) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.userAuth())
    const responce = await AuthService.registration(userData)
    dispatch(userSlice.actions.userRegistrationSuccess())
    localStorage.setItem('token', responce.data.token)
    console.log('acyncRegistration', responce)
  } catch (error) {
    dispatch(userSlice.actions.userAuthError('Error on registration'))
    console.log('acyncRegistration', error)
  }
}

export const asyncLogin = (email:string, password:string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.userAuth())
    const responce = await AuthService.login(email, password)
    localStorage.setItem('token', responce.data.token)
    const user = await UserService.getUserInfo()
    dispatch(userSlice.actions.userLoginSuccess(user.data.user.email))
    console.log('acyncLogin', responce)
  } catch (error) {
    dispatch(userSlice.actions.userAuthError('Error on registration'))
    console.log('acyncLogin', error)
  }
}

export const asyncGetUserInfo = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.userAuth())
    const responce = await UserService.getUserInfo()
    dispatch(userSlice.actions.getUserInfoSuccess(responce.data.user))
    console.log('users/me', responce)
  } catch (error) {
    dispatch(userSlice.actions.userAuthError('Error on registration'))
    console.log('users/me', error)
  }
}

//async Groups actions
export const asyncGetAllGroups = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.getAllGroups()
    dispatch(WorkspacesSlice.actions.ShortGroupsInfoFetchingSuccess(responce.data.groups))
    console.log('asyncGetAllGroups', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get all groups'))
    console.log('asyncGetAllGroups', error)
  }
}

export const asyncCreateGroup = (name: string, licenseId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.createGroup(name, licenseId)
    await dispatch(asyncGetAllGroups())
    dispatch(WorkspacesSlice.actions.setLoadingEnd())
    console.log('asyncCreateGroup', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on create group'))
    console.log('asyncCreateGroup', error)
  }
}

export const asyncGetGroupProjects = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.getOneGroupById(id)
    dispatch(WorkspacesSlice.actions.FullGroupsInfoFetchingSuccess(responce.data.group))
    dispatch(WorkspacesSlice.actions.setSelectedLicense(responce.data.selectedLicense))
    const groupProjects = await ProjectService.getAllGroupProjects(id)
    dispatch(WorkspacesSlice.actions.setActiveTabContent(groupProjects.data.projects))
    console.log('asyncGetGroupProjects', groupProjects)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get group projects'))
    console.log('asyncGetGroupProjects', error)
  }
}

export const asyncGetOneGroupById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.getOneGroupById(id)
    dispatch(WorkspacesSlice.actions.setGroupInfo(responce.data.group))
    console.log('asyncGetOneGroupById', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get group projects'))
    console.log('asyncGetOneGroupById', error)
  }
}

export const asyncPutGroupNameById = (groupId: string, name: string, licenseId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.putGroupNameById(groupId, name, licenseId)
    dispatch(WorkspacesSlice.actions.changeGroupName(responce.data.group))
    console.log('asyncPutGroupNameById', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on put group'))
    console.log('asyncPutGroupNameById', error)
  }
}

export const asyncDeleteGroupById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.deleteGroupById(id)
    dispatch(WorkspacesSlice.actions.deleteGroup(id))
    console.log('asyncDeleteGroupById', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on delete group'))
    console.log('asyncDeleteGroupById', error)
  }
}

//async Projects actions
// export const asyncCreateProject = (projectName: string, groupId: string) => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(WorkspacesSlice.actions.setLoadingStart)
//     const responce = await ProjectService.createProject(projectName, groupId)
//     const projectResponce = await ProjectService.getOneProjectById(responce.data.project.id)
//     dispatch(WorkspacesSlice.actions.ProjectsFetchingSuccess(projectResponce.data.project))
//     dispatch(WorkspacesSlice.actions.setNewProject(projectResponce.data.project))
//     console.log('acyncCreateProject', projectResponce)
//   } catch (error) {
//     dispatch(WorkspacesSlice.actions.setErrorState('Error on create project'))
//     console.log('acyncCreateProject', error)
//   }
// }

export interface ICreateLabelReq {
  name: string;
}

// export const asyncCreateProjectAndLabels = (projectName: string, groupId: string, labels: string[]) => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(WorkspacesSlice.actions.setLoadingStart)
//     const createProjectResponce = await ProjectService.createProject(projectName, groupId)
//     const projectResponce = await ProjectService.getOneProjectById(createProjectResponce.data.project.id)
//     dispatch(WorkspacesSlice.actions.ProjectsFetchingSuccess(projectResponce.data.project))
//     dispatch(WorkspacesSlice.actions.setNewProject(projectResponce.data.project))
  
//     dispatch(drawTabsSlice.actions.setLoadingState(true))
//     for(let i = 0, j = 0; i < labels.length; i++, j++) {
//       // eslint-disable-next-line eqeqeq
//       if (j == 6) {
//         j = 0
//       }
//       // eslint-disable-next-line @typescript-eslint/no-unused-vars
//       const responce = await LabelService.createLabel(labels[i], labelsColors[j] , projectResponce.data.project.id)
//     }
//     dispatch(drawTabsSlice.actions.setLoadingState(false))
//     console.log('asyncCreateProjectLabels', projectResponce)

//   } catch (error) {
//     dispatch(drawTabsSlice.actions.setLoadingState(false))
//     dispatch(drawTabsSlice.actions.setErrorState('Error on Create Label and project'))
//     console.log('asyncCreateProjectLabels', error)
//   }
// }

export const asyncGetAllProjects = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart)
    const responce = await ProjectService.getAllProjects()
    dispatch(WorkspacesSlice.actions.AllProjectsFetchingSuccess(responce.data))
    console.log('acyncGetAllProjects', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get projects'))
    console.log('acyncGetAllProjects', error)
  }
}

export const asyncGetProjectById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart)
    const responce = await ProjectService.getOneProjectById(id)
    dispatch(WorkspacesSlice.actions.setProjectInfo(responce.data.project))
    console.log('acyncGetProjectById', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get project'))
    console.log('acyncGetProjectById', error)
  }
}

export const asyncRenameProject = (project: IProject) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart)
    const responce = await ProjectService.putProjectById(project)
    dispatch(WorkspacesSlice.actions.renameProjectName(responce.data.project))
    console.log('acyncRenameProject', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on rename project'))
    console.log('acyncRenameProject', error)
  }
}

export const asyncDeleteProject = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart)
    const responce = await ProjectService.deleteProjectById(id)
    dispatch(WorkspacesSlice.actions.deleteProject(id))
    console.log('acyncDeleteProject', responce)
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on delete project'))
    console.log('acyncDeleteProject', error)
  }
}

// Перенесено в uploadImagesForm
interface IImagesUploadObj {
  batchName: string;
  projectId: string;
  images: any[];
}

export const asyncImagesUpload = (imagesUploadObj: IImagesUploadObj) => async (dispatch: AppDispatch) => {
  try {
    dispatch(blopImagesReducer.actions.setIsLoading(true))
    dispatch(modalWindowSlice.actions.setChildren('Uploading images to server..'))
    const createBatchResponce = await BatchesService.createBatch(imagesUploadObj.batchName, imagesUploadObj.projectId)
    const batchId = createBatchResponce.data.batch.id
    const imagesArrayWithSubArrays = await arraySplitter(imagesUploadObj.images, 10)
    console.log(imagesArrayWithSubArrays)
    await imagesArrayWithSubArrays.forEach( async (sub: any) => {
      let fd = new FormData();
      for(let i =0; i < sub.length; i++) {
        fd.append("images", sub[i]);
      } 
      fd.append('batch', batchId)
      const uploadSubImages = await ImagesService.uploadFormData(fd)
      console.log(uploadSubImages)
      uploadSubImages.data.files.forEach((file) => {
        dispatch(blopImagesReducer.actions.setUploadedImages(file))
      })
    })

    dispatch(blopImagesReducer.actions.setIsLoading(false))
    dispatch(modalWindowSlice.actions.setChildren('Success!'))
    setTimeout(() => {
      dispatch(modalWindowSlice.actions.setVisibleFalse())
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(assignNavReducer.actions.setVisible())
    }, 2000);
   
  } catch (error) {
    dispatch(blopImagesReducer.actions.setIsLoading(false))
    dispatch(blopImagesReducer.actions.setIsError('Error on images upload'))
    console.log('asyncImagesUpload', error)
  }
}

//batches async actions
export const asyncGetAllBatches = (projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(batchesReducer.actions.setLoadingState(true))
    const responce = await BatchesService.getBatchesSummary(projectId)
    dispatch(batchesReducer.actions.setSummaryBatches(responce.data.batches))
    dispatch(batchesReducer.actions.setLoadingState(false))
    console.log('asyncGetAllBatches', responce)
  } catch (error) {
    dispatch(batchesReducer.actions.setLoadingState(false))
    dispatch(batchesReducer.actions.setErrorState('Error on get all batches'))
    console.log('asyncGetAllBatches', error)
  }
}

export const asyncRenameBatch = (newBatchName: string, batchId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(batchesReducer.actions.setLoadingState(true))
    const responce = await BatchesService.renameBatch(newBatchName, batchId)
    dispatch(batchesReducer.actions.renameBatch({name: responce.data.batch.name, id: responce.data.batch.id}))
    dispatch(batchesReducer.actions.setLoadingState(false))
    console.log('asyncRenameBatch', responce)
  } catch (error) {
    dispatch(batchesReducer.actions.setLoadingState(false))
    dispatch(batchesReducer.actions.setErrorState('Error on rename batch'))
    console.log('asyncRenameBatch', error)
  }
}

export const asyncDeleteBatch = (batchId: string, projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(batchesReducer.actions.setLoadingState(true))
    const responce = await BatchesService.deleteBatch(batchId)
    dispatch(batchesReducer.actions.deleteBatch(batchId))
    dispatch(batchesReducer.actions.setLoadingState(false))
    console.log('asyncDeleteBatch', responce)
  } catch (error) {
    dispatch(batchesReducer.actions.setLoadingState(false))
    dispatch(batchesReducer.actions.setErrorState('Error on delete batch'))
    console.log('asyncDeleteBatch', error)
  }
}


// Tasks async actions
export const asyncCreateTask = (task: ICreateTaskPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(tasksReducer.actions.setLoadingState(true))
    const responce = await TasksService.createTask(task)
    dispatch(tasksReducer.actions.setLoadingState(false))
    console.log('asyncCreateTask', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on create Task'))
    console.log('asyncCreateTask', error)
  }
}

export const asyncGetTasksSummary = (projectId: string, withoutLoading?: boolean) => async (dispatch: AppDispatch) => {
  try {
    if (!withoutLoading) {
      dispatch(tasksReducer.actions.setLoadingState(true))
    }
    const responce = await TasksService.getTasksSummary(projectId)
    dispatch(tasksReducer.actions.setTasksSummary(responce.data))
    dispatch(tasksReducer.actions.setCurrentProjectId(projectId))
    if (!withoutLoading) {
      dispatch(tasksReducer.actions.setLoadingState(false))
    }
    console.log('asyncTasksSummary', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on get Task summary'))
    console.log('asyncTasksSummary', error)
  }
}

export const asyncUpdateTasksStatuses = (projectId: string, withoutLoading?: boolean) => async (dispatch: AppDispatch) => {
  try {
    if (!withoutLoading) {
      dispatch(tasksReducer.actions.setLoadingState(true))
    }
    const responce = await TasksService.getTasksStatuses(projectId)
    dispatch(tasksReducer.actions.updateTasksStatuses(responce.data))
    if (!withoutLoading) {
      dispatch(tasksReducer.actions.setLoadingState(false))
    }
    console.log('asyncUpdateTasksStatuses', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on get Task summary'))
    console.log('asyncUpdateTasksStatuses', error)
  }
}

const getStatusFilter = (split: ISplit | null) => {
  if (split?.value === 'annotated' || split?.value === 'unannotated' || split?.value === 'automarkup') {
    return split.value
  }
  return ''
}
const getSubStatusFilter = (split: ISplit | null) => {
  if (split?.value === 'review' || split?.value === 'approved' || split?.value === 'rejected') {
    return split.value
  }
  return ''
}

export const asyncGetTaskById = (payload: IAsyncGetListImagesPayload) => async (dispatch: AppDispatch) => {
  try {
    if (payload?.id) {
      const imageStatusFilter = getStatusFilter(payload.split)
      const imagesSubStatusFilter = getSubStatusFilter(payload.split)
      dispatch(tasksReducer.actions.setLoadingState(true))
      const responce = await TasksService.getTaskById(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
      dispatch(tasksReducer.actions.setCurrentTask(responce.data))
      dispatch(tasksReducer.actions.setLoadingState(false))
      console.log('asyncGetTaskById', responce)
    }
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on get Task'))
    console.log('asyncGetTaskById', error)
  }
}

export const asyncRenameTask = (id: string, name: string, description: string, status: string, moderator: string, annotator: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(tasksReducer.actions.setLoadingState(true))
    const responce = await TasksService.putTask(id, name, description, status, moderator, annotator)
    dispatch(tasksReducer.actions.renameTask({id: id, name: name}))
    dispatch(tasksReducer.actions.setLoadingState(false))
    console.log('asyncRenameTask', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on rename Task'))
    console.log('asyncRenameTask', error)
  }
}

export const asyncChangeTaskPerformer = (id: string, name: string, description: string, status: string | null, moderator: string | null, annotator: string | null) => async (dispatch: AppDispatch) => {
  try {
    dispatch(tasksReducer.actions.setLoadingState(true))
    const responce = await TasksService.putTask(id, name, description, status, moderator, annotator)
    // dispatch(asyncGetTaskById(id))
    dispatch(tasksReducer.actions.setLoadingState(false))
    console.log('asyncChangeTaskPerformer', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on change task performer'))
    console.log('asyncChangeTaskPerformer', error)
  }
}

export const asyncChangeTaskDescription = (id: string, name: string, description: string, status: string, moderator: string, annotator: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(tasksReducer.actions.setLoadingState(true))
    const responce = await TasksService.putTask(id, name, description, status, moderator, annotator)
    if (responce.data.task.description) {
      dispatch(tasksReducer.actions.setTaskDescription(responce.data.task.description))
    }
    dispatch(tasksReducer.actions.setLoadingState(false))
    console.log('asyncChangeTaskDescription', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on edit description'))
    console.log('asyncChangeTaskDescription', error)
  }
}

export const asyncDeleteTask = (id: string, projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(tasksReducer.actions.setLoadingState(true))
    const responce = await TasksService.deleteTask(id)
    await dispatch(tasksReducer.actions.deleteTask(id))
    dispatch(asyncGetAllBatches(projectId))
    dispatch(tasksReducer.actions.setLoadingState(false))
    console.log('asyncDeleteTask', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(batchesReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on delete Task'))
    console.log('asyncDeleteTask', error)
  }
}

//Labels Async actions
export const asyncCreateLabel = (name: string, color: string, project: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const responce = await LabelService.createLabel(name, color, project)
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on Create Label'))
    console.log('asyncCreateLabel', error)
  }
}

export const asyncGetProjectLabels = (project: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    const responce = await LabelService.getProjectLabels(project)
    console.log('asyncGetProjectLabels', responce)
    dispatch(drawTabsSlice.actions.setLabels(responce.data.labels))
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on asyncGetProjectLabels'))
    console.log('asyncGetProjectLabels', error)
  }
}

export const asyncPutLabel = (name: string, color: string, id: string, projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    const labelResponce = await LabelService.putLabel(name, color, id)
    dispatch(drawTabsSlice.actions.updateLabel(labelResponce.data))
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on asyncPutLabel'))
    console.log('asyncPutLabel', error)
  }
}


//Labels async actions
export const asyncAnnotateImage = (annotation: IAnnotateReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    const responce = await AnnotateService.annotateImage(annotation)
    console.log('asyncAnnotateImage', responce)
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on asyncAnnotateImage'))
    console.log('asyncAnnotateImage', error)
  }
}

export const asyncCreateLabels = (projectId: string, color: string, label: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    const responce = await LabelService.createLabel(label, color, projectId)
    dispatch(drawTabsSlice.actions.addLabel(responce.data.label))
    console.log('asyncCreateLabels', responce)
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on asyncCreateLabels'))
    console.log('asyncCreateLabels', error)
  }
}

export const asyncDeleteLabel = (labelId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(drawTabsSlice.actions.setLoadingState(true))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const responce = await LabelService.deleteLabel(labelId)
    dispatch(drawTabsSlice.actions.deleteLabel(labelId))
    console.log('asyncCreateLabels', asyncCreateLabels)
    dispatch(drawTabsSlice.actions.setLoadingState(false))
  } catch (error) {
    dispatch(drawTabsSlice.actions.setLoadingState(false))
    dispatch(drawTabsSlice.actions.setErrorState('Error on asyncDeleteLabel'))
    console.log('asyncCreateLabels', error)
  }
}

//Datasets Async Actions 
export const asyncCreateDataset = (payload: ICteateDatasetRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.createDataset(payload)
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncCreateDataset', responce)
  } catch (error) {
    dispatch(datasetsReducer.actions.setLoadingState(false))
    dispatch(datasetsReducer.actions.setErrorState('Error on create Dataset'))
    console.log('asyncCreateDataset', error)
  }
}

export const asyncGetDatasetsSummary = (projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.getDatasetsSummary(projectId)
    dispatch(datasetsReducer.actions.setDatasetsSummary(responce.data))
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncGetDatasetsSummary', responce)
  } catch (error) {
    dispatch(datasetsReducer.actions.setLoadingState(false))
    dispatch(datasetsReducer.actions.setErrorState('Error on get Datasets summary'))
    console.log('asyncGetDatasetsSummary', error)
  }
}

export const asyncGetDatasetById= (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.getDatasetById(id)
    dispatch(datasetsReducer.actions.setCurrentDataset(responce.data))
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncGetDatasetById', responce)
  } catch (error) {
    dispatch(datasetsReducer.actions.setLoadingState(false))
    dispatch(datasetsReducer.actions.setErrorState('Error on get Dataset'))
    console.log('asyncGetDatasetById', error)
  }
}

export const asyncRenameDataset = (id: string, name: string, description: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.putDataset(id, name, description)
    dispatch(datasetsReducer.actions.renameDataset({id, name, description}))
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncRenameDataset', responce)
  } catch (error) {
    dispatch(datasetsReducer.actions.setLoadingState(false))
    dispatch(datasetsReducer.actions.setErrorState('Error on rename Dataset'))
    console.log('asyncRenameDataset', error)
  }
}

export const asyncChangeDatasetDescription = (id: string, name: string, description: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.putDataset(id, name, description)
    dispatch(datasetsReducer.actions.chengeDatasetDesctiption({id, name, description}))
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncRenameDataset', responce)
  } catch (error) {
    dispatch(datasetsReducer.actions.setLoadingState(false))
    dispatch(datasetsReducer.actions.setErrorState('Error on rename Dataset'))
    console.log('asyncRenameDataset', error)
  }
}

export const asyncDeleteDataset = (id: string, projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(datasetsReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.deleteDataset(id)
    dispatch(datasetsReducer.actions.deleteDataset(id))
    dispatch(asyncGetAllBatches(projectId))
    dispatch(datasetsReducer.actions.setLoadingState(false))
    console.log('asyncDeleteDataset', responce)
  } catch (error) {
    dispatch(tasksReducer.actions.setLoadingState(false))
    dispatch(tasksReducer.actions.setErrorState('Error on delete Dataset'))
    console.log('asyncDeleteDataset', error)
  }
}

export const asyncGetDatasetLabels = (projectId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(generateReducer.actions.setLoadingState(true))
    const responce = await DatasetsService.getDatasetLabels(projectId)
    dispatch(generateReducer.actions.setDatasetLabels(responce.data.labels))
    console.log('asyncGetDatasetLabels', responce)
    dispatch(generateReducer.actions.setLoadingState(false))
  } catch (error) {
    dispatch(generateReducer.actions.setLoadingState(false))
    dispatch(generateReducer.actions.setErrorState('Error on asyncGetDatasetLabels'))
    console.log('asyncGetDatasetLabels', error)
  }
}

// Versions 

export const asyncGetVersionsSummary = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(versionsReducer.actions.setLoadingState(true))
    const responce = await VersionsService.getVersionsSummary(id)
    dispatch(versionsReducer.actions.setVersionsSummary(responce.data.versions))
    dispatch(versionsReducer.actions.setLoadingState(false))
    console.log('asyncGetVersionsSummary', responce)
  } catch (error) {
    dispatch(versionsReducer.actions.setLoadingState(false))
    dispatch(versionsReducer.actions.setErrorState('Error on asyncGetVersionsSummary'))
    console.log('asyncGetVersionsSummary', error)
  }
}

export const asyncPutVersion = (id: string, name: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(versionsReducer.actions.setLoadingState(true))
    const responce = await VersionsService.putVersion(id, name)
    dispatch(versionsReducer.actions.renameVersion({id, name}))
    dispatch(versionsReducer.actions.setLoadingState(false))
    console.log('asyncPutVersion', responce)
  } catch (error) {
    dispatch(versionsReducer.actions.setLoadingState(false))
    dispatch(versionsReducer.actions.setErrorState('Error on asyncPutVersion'))
    console.log('asyncPutVersion', error)
  }
}

export const asyncGetVersionById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    const responce = await VersionsService.getVersionById(id)
    console.log('asyncGetVersionById', responce)
    dispatch(versionsReducer.actions.setCurrentVersion({
      version: responce.data.version,
      archive: responce.data.archive
    }))
    dispatch(versionsReducer.actions.setLoadingState(false))
    
  } catch (error) {
    dispatch(versionsReducer.actions.setLoadingState(false))
    dispatch(versionsReducer.actions.setErrorState('Error on asyncGetVersionById'))
    console.log('asyncGetVersionById', error)
  }
}

export const asyncDeleteVersion = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(versionsReducer.actions.setLoadingState(true))
    const responce = await VersionsService.deleteVersion(id)
    dispatch(versionsReducer.actions.deleteVersion(id))
    dispatch(versionsReducer.actions.setLoadingState(false))
    console.log('asyncDeleteVersion', responce)
  } catch (error) {
    dispatch(versionsReducer.actions.setLoadingState(false))
    dispatch(versionsReducer.actions.setErrorState('Error on asyncDeleteVersion'))
    console.log('asyncDeleteVersion', error)
  }
}

//Forum Async Actions

//Category
export const asyncGetCategoriesSummary = (limit='10') => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const shortInfo = await ForumService.getCategories()
    shortInfo.data.forEach(async (shortCategory) => {
      const fullInfo = await ForumService.getCategoryById(shortCategory.id, limit, 'createdAt')
      // console.log('fullInfo', fullInfo)
      dispatch(ForumReducer.actions.setFullCategoriesInfo(fullInfo.data))
    })
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncGetCategoriesSummary'))
    console.log('asyncGetCategoriesSummary', error)
  }
}

export const asyncCreateCategory = (limit='10', name:string, description: string, parent?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    if (!parent) {
      const responce = await ForumService.createCategory(name, description)   
      const infoResponce = await ForumService.getCategoryById(responce.data.category.id, limit, 'updatedAt')
      dispatch(ForumReducer.actions.setCreatetCategory({category: infoResponce.data, isSub: false}))
      dispatch(ForumReducer.actions.setLoading(false))
      console.log('asyncCreateCategory', responce)
    }
    if (parent) {
      const responce = await ForumService.createSubCategory(name, description, parent)   
      const parentResponce = await ForumService.getCategoryById(responce.data.category.parent, limit, 'updatedAt')
      dispatch(ForumReducer.actions.setCreatetCategory({category: parentResponce.data, isSub: true}))
      dispatch(ForumReducer.actions.setLoading(false))
      console.log('asyncCreateSubCategory', responce)
    }

  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncCreateCategory'))
    console.log('asyncCreateCategory', error)
  }
}

export const asyncPutCategory = (limit='10', categoryInfo:IGetCategoryByIdResponce, id: string, name:string, description: string, parent?: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    if (!parent) {
      const responce = await ForumService.putCategory(id, name, description)   
      dispatch(ForumReducer.actions.renameCategory({id: id, name: name}))
      dispatch(ForumReducer.actions.setLoading(false))
      console.log('asyncPutCategory', responce)
    }
    if (parent) {
      const responce = await ForumService.putSubCategory(id, name, description, parent) 
      console.log('asyncPutCategory', responce)
      const getFullCategory = await ForumService.getCategoryById(categoryInfo.category.id, limit, 'updatedAt')  
      dispatch(ForumReducer.actions.renameSubCategory({subs: getFullCategory.data.subcategories, id: categoryInfo.category.id}))
      dispatch(ForumReducer.actions.setLoading(false))
      
    }

  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncPutCategory'))
    console.log('asyncPutCategory', error)
  }
}

export const asyncGetCategoryById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.getCategoryById(id, '10', 'updatedAt')
    dispatch(ForumReducer.actions.setCurrentCategory(responce.data))
    dispatch(ForumReducer.actions.setLoading(false))
    console.log('asyncGetCategoryById', responce)
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncGetCategoryById'))
    console.log('asyncGetCategoryById', error)
  }
}

export const asyncGetSubCategoryById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.getCategoryById(id, '10', 'updatedAt')
    dispatch(ForumReducer.actions.setCurrentSubCategory(responce.data))
    dispatch(ForumReducer.actions.setLoading(false))
    console.log('asyncGetCategoryById', responce)
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncGetCategoryById'))
    console.log('asyncGetCategoryById', error)
  }
}

export const asyncDeleteCategory = (limit='10', categoryInfo:IGetCategoryByIdResponce, id: string, isSub: boolean) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    if (!isSub) {
      const responce = await ForumService.deleteCategory(id)
      dispatch(ForumReducer.actions.setLoading(false))
      console.log('asyncDeleteCategory', responce)
    }
    if (isSub) {
      const responce = await ForumService.deleteCategory(id)
      console.log('asyncDeleteCategory', responce)
      const getFullCategory = await ForumService.getCategoryById(categoryInfo.category.id, limit, 'updatedAt')
      dispatch(ForumReducer.actions.setDeletedSubs({subs: getFullCategory.data.subcategories, id: categoryInfo.category.id}))
      dispatch(ForumReducer.actions.setLoading(false))
      
    }
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncDeleteCategory'))
    console.log('asyncDeleteCategory', error)
  }
}

//topics
export const asyncGetLastTopics = (limit: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.getLastTopics(limit)
    dispatch(ForumReducer.actions.setLastTopics(responce.data))
    console.log('asyncGetLastTopics', responce)
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncGetLastTopics'))
    console.log('asyncGetLastTopics', error)
  }
}

export const asyncGetTopicById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.getTopicById(id)
    dispatch(ForumReducer.actions.setCurrentTopic(responce.data))
    console.log('asyncGetTopicById', responce)
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncGetTopicById'))
    console.log('asyncGetTopicById', error)
  }
}

export const putTopicTitle = (id: string, title:string, description: string, category: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.putTopic(id, title, description, category)
    dispatch(ForumReducer.actions.putTopicTitle(responce.data.topic))
    dispatch(ForumReducer.actions.setLoading(false))
    console.log('asyncPutTopic', responce)
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncPutTopic'))
    console.log('asyncPutTopic', error)
  }
}

export const asyncPutTopicDescription = (id: string, title:string, description: string, category: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.putTopic(id, title, description, category)
    dispatch(ForumReducer.actions.putTopicDescription(responce.data.topic))
    dispatch(ForumReducer.actions.setLoading(false))
    console.log('putTopicDescription', responce)
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on putTopicDescription'))
    console.log('putTopicDescription', error)
  }
}

export const asyncDeleteTopic = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.deleteTopic(id)
    dispatch(ForumReducer.actions.deleteTopic(id))
    dispatch(ForumReducer.actions.setLoading(false))
    console.log('asyncDeleteTopic', responce)
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncDeleteTopic'))
    console.log('asyncDeleteTopic', error)
  }
}


//messages 

export const asyncCreateMessage = (content:string, topic: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.createMessage(content, topic)
    dispatch(ForumReducer.actions.setNewMessage(responce.data.forumMessage))
    console.log('asyncCreateMessage', responce)
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncCreateMessage'))
    console.log('asyncCreateMessage', error)
  }
}

export const asyncPutMessage = (id: string, content:string, topic: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.putMessage(id, content, topic)
    console.log('asyncPutMessage', responce)
    dispatch(ForumReducer.actions.editMessageContent({id, content}))
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncPutMessage'))
    console.log('asyncPutMessage', error)
  }
}

export const asyncDeleteMessage = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(ForumReducer.actions.setLoading(true))
    const responce = await ForumService.deleteMessage(id)
    dispatch(ForumReducer.actions.deleteMessage(id))
    console.log('asyncDeleteMessage', responce)
    dispatch(ForumReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(ForumReducer.actions.setLoading(false))
    dispatch(ForumReducer.actions.setError('Error on asyncDeleteMessage'))
    console.log('asyncDeleteMessage', error)
  }
}

//Auto Markups Async Actions
//Classes
export const asyncGetClassesSummary = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(autoMarkupSlice.actions.setLoading(true))
    const responce = await AutoMarkupService.getAutoMarkupClassesSummary()
    dispatch(autoMarkupSlice.actions.setClassesSummary(responce.data))
    console.log('asyncGetClassesSummary', responce)
    dispatch(autoMarkupSlice.actions.setLoading(false))
  } catch (error) {
    dispatch(autoMarkupSlice.actions.setLoading(false))
    dispatch(autoMarkupSlice.actions.setError('Error on asyncGetClassesSummary'))
    console.log('asyncGetClassesSummary', error)
  }
}

export const asyncGetClassById = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(autoMarkupSlice.actions.setLoading(true))
    const responce = await AutoMarkupService.getAutoMarkupClassById(id)
    dispatch(autoMarkupSlice.actions.setSelectedClass(responce.data))
    console.log('asyncGetClassById', responce)
    dispatch(autoMarkupSlice.actions.setLoading(false))
  } catch (error) {
    dispatch(autoMarkupSlice.actions.setLoading(false))
    dispatch(autoMarkupSlice.actions.setError('Error on asyncGetClassById'))
    console.log('asyncGetClassById', error)
  }
}

export const asyncGetModelsSummary = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(autoMarkupSlice.actions.setLoading(true))
    const responce = await AutoMarkupService.getAutoMarkupModelsSummary()
    dispatch(autoMarkupSlice.actions.setModelsSummary(responce.data))
    console.log('asyncGetModelsSummary', responce)
    dispatch(autoMarkupSlice.actions.setLoading(false))
  } catch (error) {
    dispatch(autoMarkupSlice.actions.setLoading(false))
    dispatch(autoMarkupSlice.actions.setError('Error on asyncGetModelsSummary'))
    console.log('asyncGetModelsSummary', error)
  }
}

// export const asyncGetModelById = (id: string) => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(autoMarkupSlice.actions.setLoading(true))
//     const responce = await AutoMarkupService.getAutoMarkupModelById(id)
//     dispatch(autoMarkupSlice.actions.setSelectedModel(responce.data))
//     console.log('asyncGetModelById', responce)
//     dispatch(autoMarkupSlice.actions.setLoading(false))
//   } catch (error) {
//     dispatch(autoMarkupSlice.actions.setLoading(false))
//     dispatch(autoMarkupSlice.actions.setError('Error on asyncGetModelById'))
//     console.log('asyncGetModelById', error)
//   }
// }

//Roles
export const asyncGetAllRoles = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(rolesSlice.actions.setLoading(true))
    const responce = await AccessService.getRolesSummary()
    if (responce.data.roles.length) {
      responce.data.roles.forEach(role => {
        if (role.roleType === 'GLOBAL') {
          dispatch(rolesSlice.actions.addGlobalRole(role))
        }
        if (role.name.includes('group')) {
          dispatch(rolesSlice.actions.addGroupRole(role))
        }
        if (role.name.includes('group') || role.name.includes('project')) {
          dispatch(rolesSlice.actions.addProjectRole(role))
        }
        if (role.roleType === 'LOCAL') {
          dispatch(rolesSlice.actions.addLocalRoles(role))
        }
      })
    }
    console.log('asyncGetAllRoles', responce)
    dispatch(rolesSlice.actions.setLoading(false))
  } catch (error) {
    console.log('asyncGetAllRoles', error)
    dispatch(rolesSlice.actions.setError('Error on get roles'))
    dispatch(rolesSlice.actions.setLoading(false))
  }
}

//Group members
export const asyncGetGroupAndUsersInfo = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await GroupService.getOneGroupById(id)

    dispatch(WorkspacesSlice.actions.setGroupInitialState())

    dispatch(WorkspacesSlice.actions.setGroupInfo(responce.data.group))

    const usersInfo: IGroupUserFullInfo[] = []

    for (const member of responce.data.group.groupMembers) {
      const userRoleInfo = await AccessService.getRoleById(member.roleId)
      const userInfo = await UserService.getUserById(member.user)
      usersInfo.push({groupRole: userRoleInfo.data.role, user: userInfo.data.user})
    }

    dispatch(WorkspacesSlice.actions.setFullGroupUserInfo(usersInfo))
    console.log('asyncGetGroupAndUsersInfo', responce.data, 'usersInfo', usersInfo)

    dispatch(WorkspacesSlice.actions.setLoadingEnd())
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get group projects'))
    console.log('asyncGetGroupAndUsersInfo', error)
  }
}

export const asyncInviteUsersToGroup = (id: string, groupMembers: IAddUserToGroupReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await RolesService.addUserToGroup(id, groupMembers)

    const groupInfoResponce = await GroupService.getOneGroupById(id)
    dispatch(WorkspacesSlice.actions.setGroupInfo(groupInfoResponce.data.group))

    const usersInfo: IGroupUserFullInfo[] = []

    for (const member of groupInfoResponce.data.group.groupMembers) {
      const userRoleInfo = await AccessService.getRoleById(member.roleId)
      const userInfo = await UserService.getUserById(member.user)
      usersInfo.push({groupRole: userRoleInfo.data.role, user: userInfo.data.user})
    }

    dispatch(WorkspacesSlice.actions.setFullGroupUserInfo(usersInfo))
    
    console.log('asyncInviteUsersToGroup', responce)
    dispatch(WorkspacesSlice.actions.setLoadingEnd())
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncInviteUsersToGroup'))
    console.log('asyncInviteUsersToGroup', error)
  }
}

export const asyncPutGroupUsers = (id: string, groupMembers: IAddUserToGroupReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await RolesService.putGroupUsers(id, groupMembers)

    
    console.log('asyncInviteUsersToGroup', responce)
    dispatch(WorkspacesSlice.actions.setLoadingEnd())
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncInviteUsersToGroup'))
    console.log('asyncInviteUsersToGroup', error)
  }
}

export const asyncGetProjectInfoAndUsers = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart)
    const responce = await ProjectService.getOneProjectById(id)
    dispatch(WorkspacesSlice.actions.setProjectInfo(responce.data.project))

    const usersInfo: IProjectUserFullInfo[] = []
    for (const member of responce.data.members) {
      const userRoleInfo = await AccessService.getRoleById(member.roleId)
      const userInfo = await UserService.getUserById(member.user)
      usersInfo.push({projectRole: userRoleInfo.data.role, user: userInfo.data.user})
    }

    console.log('asyncGetProjectInfoAndUsers', responce.data, 'usersInfo', usersInfo)
    dispatch(WorkspacesSlice.actions.setFullProjectUsersInfo(usersInfo))
    dispatch(WorkspacesSlice.actions.setLoadingEnd())
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on get project'))
    console.log('acyncGetProjectById', error)
  }
}

//
export const asyncInviteUsersToProject = (id: string, projectMembers: IAddUserToProjectReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(WorkspacesSlice.actions.setLoadingStart())
    const responce = await RolesService.addUserToProject(id, projectMembers)
    
    const projectInfoResponce = await ProjectService.getOneProjectById(id)
    const groupInfoResponce = await GroupService.getOneGroupById(projectInfoResponce.data.project.group)

    const usersInfo: IGroupUserFullInfo[] = []
    const projectUsersInfo: IProjectUserFullInfo[] = []
    
    for (const member of groupInfoResponce.data.group.groupMembers) {
      const userRoleInfo = await AccessService.getRoleById(member.roleId)
      const userInfo = await UserService.getUserById(member.user)
      usersInfo.push({groupRole: userRoleInfo.data.role, user: userInfo.data.user})
    }
    
    for (const member of projectInfoResponce.data.members) {
      const userRoleInfo = await AccessService.getRoleById(member.roleId)
      const userInfo = await UserService.getUserById(member.user)
      projectUsersInfo.push({projectRole: userRoleInfo.data.role, user: userInfo.data.user})
    }

    dispatch(WorkspacesSlice.actions.setGroupInfo(groupInfoResponce.data.group))
    dispatch(WorkspacesSlice.actions.setProjectInfo(projectInfoResponce.data.project))
    dispatch(WorkspacesSlice.actions.setFullGroupUserInfo(usersInfo))
    dispatch(WorkspacesSlice.actions.setFullProjectUsersInfo(projectUsersInfo))

    console.log('asyncInviteUsersToProject', responce)
    dispatch(WorkspacesSlice.actions.setLoadingEnd())
  } catch (error) {
    dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncInviteUsersToProject'))
    console.log('asyncInviteUsersToProject', error)
  }
}

//Statistics
export const asyncGetStats = (onload: boolean, userIds: string, projectId: string, startDate: string, endDate: string, actionName: string, includeMinMax?: boolean) => async (dispatch: AppDispatch) => {
  try {
    const {data} = await StatsService.getStat(userIds, projectId, startDate, endDate, actionName, includeMinMax)
    onload
      ? dispatch(statisticsSlice.actions.setOnloadStats(data))
      : dispatch(statisticsSlice.actions.setStats(data))
    console.log('asyncGetStats', data)

    dispatch(statisticsSlice.actions.setActionStats({actionName, data}))
  } catch (error) {
    dispatch(statisticsSlice.actions.setError('Error on asyncGetStats'))
    console.log('asyncGetStats', error)
  }
}

export const asyncGetActions = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(statisticsSlice.actions.setIsLoading(true))
    const { data } = await StatsService.getStatActions()
    dispatch(statisticsSlice.actions.setStatActions(data))
    console.log('asyncGetActions', data)
    dispatch(statisticsSlice.actions.setIsLoading(false))
  } catch (error) {
    dispatch(statisticsSlice.actions.setError('Error on asyncGetActions'))
    console.log('asyncGetActions', error)
  }
}

export const getUsersTasksStat = (projectId: string, userIds: string, roleNames: string, roleIds?:string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(statisticsSlice.actions.setIsLoading(true))
    const { data } = await StatsService.getUserTasksStats(projectId, userIds, roleNames)
    dispatch(statisticsSlice.actions.setUsersTasksStats(data.userTasksStats))
    console.log('getUsersTasksStat', data)
    dispatch(statisticsSlice.actions.setIsLoading(false))
  } catch (error) {
    dispatch(statisticsSlice.actions.setError('Error on getUsersTasksStat'))
    console.log('getUsersTasksStat', error)
  }
}

export const asyncGetTaskTimeline = (entityId: string, entityType: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(statisticsSlice.actions.setIsLoading(true))
    const { data } = await StatsService.getTaskTimelines(entityId, entityType)
    dispatch(statisticsSlice.actions.setTaskTimeline(data.timelines))
    console.log('asyncGetTaskTimeline', data)
    dispatch(statisticsSlice.actions.setIsLoading(false))
  } catch (error) {
    dispatch(statisticsSlice.actions.setIsLoading(false))
    dispatch(statisticsSlice.actions.setError('Error on asyncGetTaskTimeline'))
    console.log('asyncGetTaskTimeline', error)
  }
}

//settings 
export const asyncGetOwnerGroups = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setLoading(true))
    const { data } = await GroupService.getAllOwnerGroups()
    dispatch(SettingsReducer.actions.setOwnerGroups(data.groups))
    console.log('asyncGetOwnerGroups', data)
    dispatch(SettingsReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetOwnerGroups'))
    console.log('asyncGetOwnerGroups', error)
  }
}

export const asyncGetMemberGroups = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setLoading(true))
    const { data } = await GroupService.getAllMemberGroups()
    dispatch(SettingsReducer.actions.setMemberGroups(data.groups))
    console.log('asyncGetMemberGroups', data)
    dispatch(SettingsReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetMemberGroups'))
    console.log('asyncGetMemberGroups', error)
  }
}

export const asyncGetOwnerAndMemberGroups = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setLoading(true))
    const getAllMemberGroupsRes = await GroupService.getAllMemberGroups()
    const getAllOwnerGroupsRes = await GroupService.getAllOwnerGroups()
    dispatch(SettingsReducer.actions.setOwnerGroups(getAllOwnerGroupsRes.data.groups))
    dispatch(SettingsReducer.actions.setMemberGroups(getAllMemberGroupsRes.data.groups))
    console.log('asyncGetOwnerAndMemberGroups', getAllMemberGroupsRes, getAllOwnerGroupsRes)
    dispatch(SettingsReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetOwnerAndMemberGroups'))
    console.log('asyncGetOwnerAndMemberGroups', error)
  }
}

export const asyncGetLicenses = (groupId: string) => async (dispatch: AppDispatch) => {
  try {
    const { data } = await LicensesService.getLicenses(groupId)
    dispatch(SettingsReducer.actions.setLicenses(data.licenses))
    console.log('asyncGetLicenses', data)
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetLicenses'))
    console.log('asyncGetLicenses', error)
  }
}

export const asyncGetLicensesWithoutId = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await LicensesService.getLicensesWithoutId()
    dispatch(SettingsReducer.actions.setLicenses(data.licenses))
    console.log('asyncGetLicensesWithoutId', data)
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetLicensesWithoutId'))
    console.log('asyncGetLicensesWithoutId', error)
  }
}

export const asyncGetGroupQuotas = (groupId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setLoading(true))
    const { data } = await QuotasService.getGroupQuotas(groupId)
    dispatch(SettingsReducer.actions.setGroupQuotas(data.groupQuotas))
    console.log('asyncGetGroupQuotas', data)
    dispatch(SettingsReducer.actions.setLoading(false))
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetGroupQuotas'))
    console.log('asyncGetGroupQuotas', error)
  }
}

export const asyncExpandQuota = (payload: IExpandQuotaReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setExpandLoading(true))
    const { data } = await QuotasService.expandQuota(payload)
    console.log('asyncExpandQuota', data)
    dispatch(asyncGetGroupQuotas(payload.groupId))
    dispatch(SettingsReducer.actions.setExpandLoading(false))
    dispatch(modalWindowSlice.actions.setInititialState())
  } catch (error: any) {
    dispatch(SettingsReducer.actions.setExpandLoading(false))
    if (error.message === "Request failed with status code 400") {
      dispatch(modalWindowSlice.actions.setTitle(i18n.t('authorized.settings.noMoney')))
      dispatch(modalWindowSlice.actions.setTitleColor(Colors.red))
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(i18n.t('authorized.settings.topUpForm.formTitle')))
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(modalWindowSlice.actions.setChildren('balance'))
        dispatch(modalWindowSlice.actions.setVisible())
      }, 2000)

      return
    }
    dispatch(SettingsReducer.actions.setError('Error on asyncExpandQuota'))
    console.log('asyncExpandQuota', error)
  }
}

export const asyncGetGroupPackages = (groupId: string) => async (dispatch: AppDispatch) => {
  try {
    if (groupId) {
      dispatch(SettingsReducer.actions.setExpandLoading(true))
      const responce = await QuotasService.getGroupPackages(groupId)
      dispatch(SettingsReducer.actions.setGroupPackages(responce.data.quotaPackages))
      console.log('asyncGetGroupPackages', responce)
      dispatch(SettingsReducer.actions.setExpandLoading(false))
    }
  } catch (error) {
    dispatch(SettingsReducer.actions.setExpandLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetGroupPackages'))
    console.log('asyncGetGroupPackages', error)
  }
}

export const asyncExpandPackage = (payload: IExpandPackageReq) => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setExpandLoading(true))
    const { data } = await QuotasService.expandPackage(payload)
    console.log('asyncExpandPackage', data)
    dispatch(asyncGetGroupPackages(payload.groupId))
    dispatch(asyncGetGroupQuotas(payload.groupId))
    dispatch(SettingsReducer.actions.setExpandLoading(false))
    dispatch(modalWindowSlice.actions.setInititialState())
  } catch (error: any) {
    dispatch(SettingsReducer.actions.setExpandLoading(false))
    if (error.message === "Request failed with status code 400") {
      dispatch(modalWindowSlice.actions.setTitle(i18n.t('authorized.settings.noMoney')))
      dispatch(modalWindowSlice.actions.setTitleColor(Colors.red))
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(i18n.t('authorized.settings.topUpForm.formTitle')))
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(modalWindowSlice.actions.setChildren('balance'))
        dispatch(modalWindowSlice.actions.setVisible())
      }, 2000)
      return
    }
    dispatch(SettingsReducer.actions.setError('Error on asyncExpandPackage'))
    console.log('asyncExpandPackage', error)
  }
}

export const asyncExtendLicense = (payload: IExtendLicensePayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(SettingsReducer.actions.setLoading(true))
    const { data } = await LicensesService.extendLicense(payload)
    console.log('asyncExtendLicense', data)
    dispatch(asyncGetLicenses(payload.groupId))
    dispatch(asyncGetGroupProjects(payload.groupId))
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(modalWindowSlice.actions.setInititialState())
  } catch (error: any) {
    dispatch(SettingsReducer.actions.setLoading(false))
    if (error.message === "Request failed with status code 400") {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setTitle(i18n.t('authorized.settings.topUpForm.formTitle')))
      dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
      dispatch(modalWindowSlice.actions.setChildren('balance'))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncExtendLicense'))
    console.log('asyncExtendLicense', error)
  }
}

export const asyncGetFiatTransactions = (offset: number = 0, limit: number = 1000) => async (dispatch: AppDispatch) => {
  try {
    dispatch(paymentsSlice.actions.setLoading(true))
    const fiatRes = await PaymentsService.getFiatTransactions(offset, limit)
    dispatch(paymentsSlice.actions.setFiatOperations(fiatRes.data))
    console.log('asyncGetFiatTransactions', fiatRes)
    dispatch(paymentsSlice.actions.setLoading(false))
  } catch (error) {
    dispatch(paymentsSlice.actions.setLoading(false))
    dispatch(paymentsSlice.actions.setError('Error on asyncGetFiatTransactions'))
    console.log('asyncGetFiatTransactions', error)
  }
}

export const asyncGetCoinsTransactions = (offset: number = 0, limit: number = 1000) => async (dispatch: AppDispatch) => {
  try {
    dispatch(paymentsSlice.actions.setLoading(true))
    const coinsRes = await PaymentsService.getCoinsTransactions(offset, limit)
    dispatch(paymentsSlice.actions.setCoinsOperations(coinsRes.data))
    console.log('asyncGetCoinsTransactions', coinsRes)
    dispatch(paymentsSlice.actions.setLoading(false))
  } catch (error) {
    dispatch(paymentsSlice.actions.setLoading(false))
    dispatch(paymentsSlice.actions.setError('Error on asyncGetCoinsTransactions'))
    console.log('asyncGetCoinsTransactions', error)
  }
}

export const asyncGetSettings = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await SettingsService.getSettings()
    dispatch(SettingsReducer.actions.setSettings(data.settings))
    console.log('asyncGetSettings', data)
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetSettings'))
    console.log('asyncGetSettings', error)
  }
}

export const asyncGetSubsctiptions = () => async (dispatch: AppDispatch) => {
  try {
    const { data } = await SubscriptionsService.getSubscriptions()
    dispatch(SettingsReducer.actions.setSubsctiptions(data.subsctiptions))
    console.log('asyncGetSubsctiptions', data)
  } catch (error) {
    dispatch(SettingsReducer.actions.setLoading(false))
    dispatch(SettingsReducer.actions.setError('Error on asyncGetSubsctiptions'))
    console.log('asyncGetSubsctiptions', error)
  }
}

//Archive 

export const asyncDeleteArchive = (archiveId: string, versionId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(modalWindowSlice.actions.setChildren('loading'))
    const { data } = await VersionsService.deleteArchive(archiveId)
    console.log('asyncDeleteArchive', data)
    dispatch(asyncGetVersionById(versionId))
    dispatch(modalWindowSlice.actions.setChildren('successDelete'))
  } catch (error) {
    dispatch(modalWindowSlice.actions.setChildren('errorDelete'))
    console.log('asyncDeleteArchive', error)
  }
}