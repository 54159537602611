import React, { useEffect, useState } from 'react'
import { IGroupQuotaProps } from '../../constants/interfaces'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import Tooltip from '../../../../UI/Tooltip/Tooltip';
import { StyledAmount, StyledBalanceClass, StyledBalanceLineStats, StyledColoredLine, 
         StyledHealthClassName, StyledLineWrapper, StyledQuotaImage } from '../../constants/styled';
import usersIcon from '../../../../assets/icons/users-svgrepo-com.svg'
import eyeIcon from '../../../../assets/icons/eye-svgrepo-com.svg'
import imageIcon from '../../../../assets/icons/image-1-svgrepo-com.svg'
import diskIcon from '../../../../assets/icons/data-center-data-warehouse-svgrepo-com.svg'
import projectsIcon from '../../../../assets/icons/project.svg'
import coinsIcon from '../../../../assets/icons/coins.svg'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useAppDispatch } from '../../../../hooks/redux';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import SelectQuotaForm from '../EditQuotaForm/SelectQuotaForm';
import { useTranslation } from 'react-i18next';

const GroupQuota: React.FC<IGroupQuotaProps> = ({desc, title, used, name, max, lineColor, withTooptip, IsExpandable, quota, groupQuotas, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [percentage, setPercentage] = useState(0)  
  const [image, setImage] = useState(usersIcon)
  
  useEffect(() => {
    if (max) {
      setPercentage(Math.ceil((used / max) * 100))
    }
    if (name === 'group_members') {
      setImage(usersIcon)
    }
    if (name === 'source_images') {
      setImage(imageIcon)
    }
    if (name === 'automarkup_credits_monthly') {
      setImage(coinsIcon)
    }
    if (name === 'disk_quotas') {
      setImage(diskIcon)
    }
    if (name === 'generated_images') {
      setImage(imageIcon)
    }
    if (name === 'projects') {
      setImage(projectsIcon)
    }
    if (name === 'automarkup_daily_runs') {
      setImage(eyeIcon)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [used, max])

  const onUpgrateClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.quotasFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setModalWidth('80vw'))
    dispatch(modalWindowSlice.actions.setActiveScrollTrue())
    dispatch(modalWindowSlice.actions.setTooltipText(t('authorized.settings.quotasFormTooltip')))
    dispatch(modalWindowSlice.actions.setChildren(<SelectQuotaForm groupQuotas={groupQuotas}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledBalanceClass color={lineColor ? lineColor : 'WHITE'}>
      <FlexContainer widthProps='100%' direction='row' justify='flex-start' align='center' gap='5px'>
        <StyledQuotaImage srcProps={image}/>
        <StyledHealthClassName>{title ? title : ''}</StyledHealthClassName>
        {withTooptip? <Tooltip>{desc}</Tooltip> : ''}
      </FlexContainer>
      
      <StyledBalanceLineStats>
        <FlexContainer direction='row' justify='space-between' align='center' gap='5px'>
          <StyledAmount>{used} / {max}</StyledAmount>
          {IsExpandable && <ProjectButton heightProps='25px' widthProps='auto' FontSizeProps='14px' paddingProps='0 8px' onClick={onUpgrateClick}>{t('authorized.settings.groupIncrease')}</ProjectButton>}
        </FlexContainer>
        <StyledLineWrapper>
           <StyledColoredLine percentage={percentage} color={lineColor ? lineColor : "#fff"}/>
        </StyledLineWrapper>
      </StyledBalanceLineStats>
    </StyledBalanceClass>
  )
}

export default GroupQuota