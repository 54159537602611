import React from 'react'
import {Route, Routes, useNavigate} from "react-router-dom";
import { EnterEmailPage, SignInPage, SignUpPage, InvitedSignUpPage, EmailConfirmationPage, PasswordRecoveryPage, PasswordEnterEmailPage, ProjectsPage, ProjectPage, DrawPage, NotFountPage, UserSettingsPage} from '../../pages';
import RequireAuth from './HOC/RequireAuth';
// import VersionImagesView from '../../modules/ProjectModule/components/TabsContentPages/ProjectVersions/VersionImagesView';
import { ForumPage } from '../../pages/ForumPage/ForumPage';

const PagesRouter = () => {
  const navigate = useNavigate();
  return (
    <Routes>
        {/* Groups Page */}
        <Route path='/' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectsPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/projects' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectsPage navigate={navigate}/>
          </RequireAuth>}
        />

        {/* Settings */}
        <Route path='/settings' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <UserSettingsPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/settings/:tab' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <UserSettingsPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/settings/:tab/:groupId' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <UserSettingsPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/settings/:tab/:groupId/:licenses' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <UserSettingsPage navigate={navigate}/>
          </RequireAuth>}
        />

        {/* Project page */}
        <Route path='/projects/:id/:tab' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/projects/:id/:tab/version/:versionId' element={
          <RequireAuth navigate={navigate} accessLevel={4}>
            <ProjectPage navigate={navigate}/>
          </RequireAuth>}
        />
{/* 
        <Route path='/projects/:id/:tab/version/:versionId/view' element={
          <RequireAuth navigate={navigate} accessLevel={4}>
            <VersionImagesView navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/projects/:id/:tab/version/:versionId/view/:type' element={
          <RequireAuth navigate={navigate} accessLevel={4}>
            <VersionImagesView navigate={navigate}/>
          </RequireAuth>}
        /> */}

        <Route path='/projects/:id/:tab/:batchId' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/projects/:id/:tab/annotate/:jobId' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectPage navigate={navigate}/>
          </RequireAuth>}
        />

        <Route path='/projects/:id/:tab/dataset/:datasetId' element={
          <RequireAuth navigate={navigate} accessLevel={7}>
            <ProjectPage navigate={navigate}/>
          </RequireAuth>}
        />

        {/* Unauthorized User routes*/}
        <Route path='/signin' element={<SignInPage navigate={navigate}/>}/>
        <Route path='/signup' element={<SignUpPage navigate={navigate}/>}/>
        <Route path='/signup/:token' element={<InvitedSignUpPage navigate={navigate}/>}/>
        <Route path='/email-enter' element={<EnterEmailPage navigate={navigate}/>}/>
        <Route path='/email-confirm' element={<EmailConfirmationPage navigate={navigate}/>}/>
        <Route path='/auth/activate/:token' element={<EmailConfirmationPage navigate={navigate}/>}/>
        <Route path='/password-recovery' element={<PasswordEnterEmailPage navigate={navigate}/>}/>
        <Route path='/auth/reset-password/:code' element={<PasswordRecoveryPage navigate={navigate}/>}/>
        <Route path='/projects/:id/:tab/annotate/:jobId/draw/:imgName' element={<DrawPage navigate={navigate}/>}/>

        <Route path='/forum' element={<ForumPage navigate={navigate}/>}/>
        <Route path='/forum/:categoryId/:subcategoryId/:topics' element={<ForumPage navigate={navigate}/>}/>
        <Route path='/forum/:categoryId/:subcategoryId/:create' element={<ForumPage navigate={navigate}/>}/>
        <Route path='/forum/:categoryId/:subcategoryId/:topic/:topicId' element={<ForumPage navigate={navigate}/>}/>
        <Route path='/forum/create/:categoryId/:createTopic' element={<ForumPage navigate={navigate}/>}/>

        {/* 404 */}
        <Route path='*' element={<NotFountPage navigate={navigate}/>}/>
    </Routes>
  )
}
export default PagesRouter