import React, { FC, useState } from 'react'
import { useAppDispatch } from '../../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer'
import { asyncCreateLabels } from '../../../../../../../store/reducers/AcyncActions'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import Input from '../../../../../../../UI/Input/Input'
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'

interface IEditDescriptionProps {
  projectId: string
}

const AddLabelForm: FC<IEditDescriptionProps> = ({ projectId, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [labelName, setLabelName] = useState('')
  const [labelColor, setLabelColor] = useState('#6b2dfb')
  console.log(labelColor)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onCantelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelName(e.target.value)
  }

  const onColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelColor(e.target.value)
  }

  const onButtonConfirmClick = () => {
    if (labelName) {
      dispatch(asyncCreateLabels(projectId, labelColor, labelName))
      setModalVisivble()
    }
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="15px">
      <Input label={t('other.name')} placeholder={t('forum.enterText')} value={labelName} onChange={onNameChange} />
      <Input label={t('authorized.project.taskPage.selectColor')} type="color" value={labelColor} onChange={onColorChange} />
      <FlexContainer direction="row" justify="space-around" paddingProps="20px 0 0 0">
        <ProjectButton onClick={onCantelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onButtonConfirmClick} disabled={!labelName}>{t('buttons.add')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default AddLabelForm
