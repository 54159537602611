import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { IUserNavDropdownProps } from '../../../../../models/IUserNavDropdownProps'
import { Colors } from '../../../../../models/Colors'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import DropdownButton from '../../../../../UI/Buttons/DropdownButton/DropdownButton'
import burgerIcon from '../../../../../assets/icons/hamburger-menu-svgrepo-com.svg'
import { asyncDeleteVersion, asyncGetVersionsSummary } from '../../../../../store/reducers/AcyncActions'
import RenameVersionForm from './RenameVersionForm'
import { IFullVersionWithArchive } from '../../../store/reducers/VersionsReducer'
import { useTranslation } from 'react-i18next'
import { IProject } from '../../../../../services/ProjectService'
import { IShortVersionInfo } from '../../../../../services/VersionsService'
import { useNavigate, useParams } from 'react-router-dom'

interface IVersionDropdownProps {
  projectInfo: IProject
  versionInfo: IFullVersionWithArchive
}

const StyledBurgerIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${burgerIcon});
  mask-image: url(${burgerIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  .username {
    height: 30px;
    padding: 5px;
  }
  position: relative;
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 160px;
  height: auto;

  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;

  position: absolute;
  top: 120%;
  right: -20px;
  z-index: 3000;
`
const StyledSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  font-weight: bold;
  font-size: 10px;
  cursor: pointer;

  .count {
    position: absolute;
    top: -6px;
    right: 4px;
  }
`

const VersionDropDown: FC<IVersionDropdownProps> = ({ projectInfo, versionInfo, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)
  const { isLoading, versionsSummary } = useAppSelector((state) => state.versionsReducer)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(false)
  }

  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }

  const onButtonDeleteClick = () => {
    setDropdownVisible(false)
    dispatch(asyncDeleteVersion(versionInfo.id || params.versionId!))
    setModalVisivble()
    
    const summaryCopy = [...versionsSummary]
    const filteredSummary = summaryCopy.filter(sumVersion => sumVersion.id !== versionInfo.id || params.versionId!)
    let firstVersionWithVersionInfo: IShortVersionInfo = {} as IShortVersionInfo
    filteredSummary.forEach(version => {
      if (version?.versionInfo) {
        firstVersionWithVersionInfo = version
        return
      } 
    })
    if (firstVersionWithVersionInfo?.versionInfo) {
      navigate(`/projects/${projectInfo.id}/versions/version/${firstVersionWithVersionInfo.id}`)
    }
    if (!firstVersionWithVersionInfo?.versionInfo) {
      navigate(`/projects/${projectInfo.id}/versions`)
    }

    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownVisible(false)
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.rename')))
    dispatch(modalWindowSlice.actions.setChildren(<RenameVersionForm versionInfo={versionInfo} />))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownVisible(false)
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.deleteVersion')))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="row" justify="space-around" paddingProps="20px 0 0 0">
          <ProjectButton onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onButtonDeleteClick}>{t('other.delete')}</ProjectButton>
        </FlexContainer>
      )
    )
  }

  return (
    <StyledDropDown {...props}>
      <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
        <DropdownButton onClick={onRenameClick} heightProps={'30px'}>
          {t('other.rename')}
        </DropdownButton>
        <DropdownButton onClick={onDeleteClick} colorProps="red" heightProps={'30px'}>
          {t('other.delete')}
        </DropdownButton>
      </StyledDropdownContent>
      <FlexContainer paddingProps="0" direction="row" justify="center" align="center" onClick={onDropdownClick}>
        <StyledSettings>
          <StyledBurgerIcon {...props} onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave} />
        </StyledSettings>
      </FlexContainer>
    </StyledDropDown>
  )
}

export default VersionDropDown
