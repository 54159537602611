import React from 'react'
import { useAppSelector } from '../../../../../hooks/redux'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { StyledVersionOptionInfo, StyledVersionOptionName } from '../../../constants/styled'
import { useTranslation } from 'react-i18next'

const VersionLabels = () => {
  const { t } = useTranslation()
  const labels = useAppSelector((state) => state.versionsReducer.labels)
  console.log(labels)
  return (
    <FlexContainer direction="row" justify="flex-start" align="flex-start" paddingProps="15px" gap="35px">
      <StyledVersionOptionName>
        <h6>{t('authorized.project.generateAndVersions.classes')}</h6>
      </StyledVersionOptionName>
      <FlexContainer direction="column" justify="flex-start" align="flex-start" gap="15px">
        {labels?.length ? (
          labels?.map((label, index) => (
            <FlexContainer key={label + index} direction="row" gap="60px">
              <StyledVersionOptionInfo>
                <FlexContainer direction="column" justify="flex-start" align="flex-start">
                  <FlexContainer direction="row" justify="center" align="center">
                    {label}
                  </FlexContainer>
                </FlexContainer>
              </StyledVersionOptionInfo>
            </FlexContainer>
          ))
        ) : (
          <StyledVersionOptionInfo>{t('authorized.project.generateAndVersions.noClasses')}</StyledVersionOptionInfo>
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default VersionLabels
