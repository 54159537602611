import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { eventSourceSlice } from './eventSourceReducer'

const EventSourcing = () => {
  // const dispatch = useAppDispatch()
  // const user = useAppSelector(state => state.userReduser.user)
  // const token = localStorage.getItem('token')
  // const isConnected = localStorage.getItem('SSECONNECT')
  // const controller = new AbortController();
  // const { signal } = controller;

	// useEffect(() => {
  //   if (controller) {
  //     dispatch(eventSourceSlice.actions.setController(controller))
  //   }
	// 	const SSEConnect = async (userId: string) => {
  //     console.log('SSE try to fetch')
	// 		await fetchEventSource(`${process.env.REACT_APP_API_URL + 'jobs/user-events/' + userId}`, {
	// 			headers: {
	// 				'Authorization': `Bearer ${token}`,
	// 			},
  //       async onopen(response: any) {
  //         const res = await JSON.parse(response)
  //         console.log('SSE RES CONNECT', res)
  //         console.log('SSE CONNECTED', response)
  //         localStorage.setItem('SSECONNECT', 'true')
  //       },
	// 			onmessage(event) {
	// 				try {
	// 					const parsedData = JSON.parse(event.data);

  //           console.log('SSE messageEvent data', parsedData)
  //           const data = JSON.parse(parsedData)
  //           dispatch(eventSourceSlice.actions.setEvent(data))

	// 				} catch(error) {
	// 					console.error('SSE parsing error');
	// 				}	
	// 			},
  //       onerror(event) {
  //         console.error('ERROR SSE CONNECT', event)
  //       },
  //       onclose() {
  //         localStorage.setItem('SSECONNECT', 'false')
  //       },
	// 			signal,
	// 		});
	// 	};

  //   console.log('SSE isConnected', isConnected)
    
  //   if (isConnected === 'true') {
  //     return
  //   }
  //   if (isConnected === 'false' && user?.id) {
  //     // SSEConnect(user.id);
  //   }
  //   if (!isConnected && user?.id) {
  //     // SSEConnect(user.id);
  //   }

    
	// 	return () => controller.abort();
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [ user, token, isConnected ]);

  return (
    <></>
  )
}

export default EventSourcing