import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

interface IDeleteResponce {
    message: string;
}

export interface ISummaryRole {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    roleGroupId: string;
    roleType: string;
    permissions?: any;
}

export interface IPermissionSummary {
    id: string;
    name: string;
    displayname: string;
    createdAt: string;
    updatedAt: string;
}

export interface IRoleByIdResponce {
    role: {
        id: string;
        name: string;
        displayName: string;
        createdAt: string;
        updatedAt: string;
        roleGroupId: string;
        permissions: IPermissionSummary[]
    }
}

export interface IGetRolesSummaryResponce {
    roles: ISummaryRole[];
}

export interface ICreateRoleResponce {
    role: ISummaryRole;
    message: string;
}
//
export interface IGetPermissionsSummaryRes {
    permissions: IPermissionSummary[];
}

export interface ICreatePermissionRes {
    permission: IPermissionSummary;
    message: string;
}
//
export interface IRouteAccess {
    id: string;
    description: string;
    method: string;
    path: string;
    createdAt: string;
    updatedAt: string;
}

export interface IGetRoutesSummaryRes {
    routeAccesses: IRouteAccess[];
}

export interface IRouteRole {
    createdAt: string;
    updatedAt: string;
    roleId: string;
    routeAccessId: string;
}

export interface IRolesInRoute {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    roleGroupId: string;
    RouteRoles: IRouteRole;
}

export interface IGetRoleAccessByIdRes {
    id: string;
    description: string;
    method: string;
    path: string;
    createdAt: string;
    updatedAt: string;
    roles: IRolesInRoute[];
}

interface IRouteRoleGroup {
    id: string;
}

export interface ICreateRouteReq {
    description: string;
    method: string;
    path: string;
    routeRoles: IRouteRoleGroup[];
    routeRoleGroups: IRouteRoleGroup[];
}

export interface IRouteRoleGroupOnCreate {
    createdAt: string;
    updatedAt: string;
    roleGroupId: string;
    routeAccessId: string;
}

export interface IRouteGroup {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    RouteRoleGroups: IRouteRoleGroupOnCreate[];
}



export interface IRouteAccessAtCreate {
    id: string;
    description: string;
    method: string;
    path: string;
    createdAt: string;
    updatedAt: string;
    roles: IRolesInRoute;
    roleGroups: IRouteGroup[]
}

export interface ICreateRouteRes {
    message: string;
    routeAccess: IRouteAccessAtCreate;
}

export interface IPutRouteReq {
    id: string;
    description: string;
    method: string;
    path: string;
    routeRoles: IRouteRoleGroup[];
    routeRoleGroups: IRouteRoleGroup[];
}

export default class AccessService {
    //Access Roles
    static async getRolesSummary(): Promise<AxiosResponse<IGetRolesSummaryResponce>> {
        return $authHost.get<IGetRolesSummaryResponce>(`/access/roles`)
    }
    static async getRoleById(roleId: string): Promise<AxiosResponse<IRoleByIdResponce>> {
        return $authHost.get<IRoleByIdResponce>(`/access/roles/${roleId}`)
    }
    static async createRole(role: ICreateRouteReq): Promise<AxiosResponse<ICreateRoleResponce>> {
        return $authHost.post<ICreateRoleResponce>(`/access/roles`, role)
    }
    static async putRole(role: IRoleByIdResponce): Promise<AxiosResponse<IRoleByIdResponce>> {
        return $authHost.put<IRoleByIdResponce>(`/access/roles`, role)
    }
    static async deleteRole(roleId: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/access/roles/${roleId}`)
    }

    //Access Permissions
    static async getPermissionsSummary(): Promise<AxiosResponse<IGetPermissionsSummaryRes>> {
        return $authHost.get<IGetPermissionsSummaryRes>(`/access/permissions`)
    }
    static async getPermissionById(permissionId: string): Promise<AxiosResponse<IPermissionSummary>> {
        return $authHost.get<IPermissionSummary>(`/access/permissions/${permissionId}`)
    }
    static async createPermission(name: string, displayName: string): Promise<AxiosResponse<ICreatePermissionRes>> {
        return $authHost.post<ICreatePermissionRes>(`/access/permissions`, {name: name, displayName: displayName})
    }
    static async putPermission(id: string, name: string, displayName: string): Promise<AxiosResponse<ICreatePermissionRes>> {
        return $authHost.put<ICreatePermissionRes>(`/access/permissions`, {id: id, name: name, displayName: displayName})
    }
    static async deletePermission(permissionId: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/access/permissions/${permissionId}`)
    }

    //Access Routes
    static async getRoutesSummary(): Promise<AxiosResponse<IGetRoutesSummaryRes>> {
        return $authHost.get<IGetRoutesSummaryRes>(`/access/routes`)
    }
    static async getRouteById(routeId: string): Promise<AxiosResponse<IGetRoleAccessByIdRes>> {
        return $authHost.get<IGetRoleAccessByIdRes>(`/access/routes/${routeId}`)
    }
    static async createRoute(route: ICreateRouteReq): Promise<AxiosResponse<ICreateRouteRes>> {
        return $authHost.post<ICreateRouteRes>(`/access/routes`, route)
    }
    static async putRoute(route: IPutRouteReq): Promise<AxiosResponse<any>> {
        return $authHost.put<ICreateRouteRes>(`/access/routes`, route)
    }
    static async deleteRoute(routeId: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/access/routes/${routeId}`)
    }

}