import React, { FC } from 'react'
import FirstSlide from './slides/FirstSlide/FirstSlide';
import TryModelsSlide from './slides/TryModelsSlide/TryModelsSlide';
import CounterSlide from './slides/CounterSlide/CounterSlide';
import LoadingSlide from './slides/LoadingSlide/LoadingSlide';
import TextModelsSlide from './slides/TextModelsSlide/TextModelsSlide';
import SelectTrashold from './slides/SelectTrashold/SelectTrashold';

interface IAutoMarkupSlideFormProps {
  slide: string;
  type?: string;

}

const AutoMarkupSlideForm: FC<IAutoMarkupSlideFormProps> = ({slide, type, ...props}) => {
  return (
    <>
      {slide === 'first' && <FirstSlide/>}
      {slide === 'models' && <TryModelsSlide/>}
      {slide === 'textModels' && <TextModelsSlide/>}
      {slide === 'segmentation' && ''}

      {slide === 'trashold' && <SelectTrashold type={type ? type : ''}/>}
      {slide === 'counter' && <CounterSlide type={type ? type : ''}/>}
      {slide === 'loading' && <LoadingSlide/>}
    </>
  )
}

export default AutoMarkupSlideForm