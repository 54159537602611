import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../models/Colors'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { IShortVersionInfo } from '../../services/VersionsService';
import { useAppDispatch } from '../../hooks/redux';
import { asyncGetVersionById } from '../../store/reducers/AcyncActions';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../services/ProjectService';
import { useTranslation } from 'react-i18next';

interface IVersionBtnProps {
  versionInfo: IShortVersionInfo;
  onClick?: () => void;
  paramId: string;
  projectInfo: IProject;
}

interface IVersionStyledProps {
  paramId: boolean;
}

const StyledVersion = styled.div<IVersionStyledProps>`
  width: 100%;
  height: 70px;
  padding: 16px;
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: ${({ paramId }) => (paramId ? `1px solid ${Colors.orange}` : "none")};
  background: ${Colors.darker};
  cursor: pointer;
  transition: all 0.1s linear;
  :hover {
    background: #323232;
  }
`;
const StyledTitle = styled.span`
  font-size: 12px;
  color: ${Colors.textgray};
  font-weight: 400;
`;

const StyledRedTitle = styled.span`
  font-size: 12px;
  color: ${Colors.orange};
  font-weight: 400;
`;

const StyledId = styled.span`
  font-size: 12px;
  color: ${Colors.textgray};
  font-weight: 300;
`;

const VersionBtn: FC<IVersionBtnProps> = ({versionInfo, paramId, projectInfo,...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onVersionClick = () => {
    if ((paramId === versionInfo.id) || !versionInfo.versionInfo) {
      return;
    }
    dispatch(asyncGetVersionById(versionInfo.id));
    navigate(`/projects/${projectInfo.id}/versions/version/${versionInfo.id}`);
  };

  return (
    <StyledVersion
      paramId={paramId === versionInfo.id}
      {...props}
      onClick={onVersionClick}
    >
      <FlexContainer direction="column" justify="center" align="center">
        <FlexContainer
          direction="row"
          justify="center"
          align="center"
          gap="5px"
        >
          <StyledId>V{versionInfo.versionNumber}</StyledId>
          <StyledTitle> {versionInfo.versionInfo ? versionInfo.name : <StyledRedTitle>{t('authorized.project.generateAndVersions.versionInProgress')}</StyledRedTitle>} </StyledTitle>
          {/* <StyledId>{versionInfo.createdAt}</StyledId> */}
        </FlexContainer>
      </FlexContainer>
    </StyledVersion>
  );
}

export default VersionBtn