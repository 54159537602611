import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { Colors } from '../../models/Colors'
import { IInputProps } from '../../models/IInputProps'
import { IInputRadioProps } from '../../models/IInputRadioProps'
import Tooltip from '../Tooltip/Tooltip'

const StyledInput = styled.input<IInputProps>`
  width: ${props => props.widthProps || '20px'};
  height:${props => props.heightProps || '20px'};
  padding:${props => props.paddingProps || '4px'};
  background-color:${props => props.backgroundProps || Colors.darker};
  border: 2px solid ${Colors.gray};
  border-color: ${props => props.borderColor || Colors.gray};
  border-radius: 6px;
  color: ${props => props.textColor || Colors.textgray};

  &:focus { 
    outline: none;
    border: 1px solid ${props => props.focusColor || Colors.orange}
   };
  ::placeholder {
    font-size: 12px;
    color: #4B6379;
  }
`
const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  /* width: 100%; */
  font-size: 12px;
  font-weight: 300;
`
const StyledTextfield = styled.div<IInputProps>`
  width: ${props => props.widthProps || ''};
`

const InputRadio: FC<IInputRadioProps> = ({label, withTooltip, tooltipText, onClick, onChange,  ...props}) => {
  
  return (
    <StyledTextfield >
      <FlexContainer direction='row' gap='5px' justify='flex-start' align='center' paddingProps='0 5px'  heightProps='50px'>
        <StyledInput {...props} onClick={onClick} onChange={onChange}/>
        {label? <StyledLabel labelColor={props.labelColor} onClick={onClick} onChange={onChange}>{label}</StyledLabel> : ''}
        {withTooltip? <Tooltip>{tooltipText}</Tooltip> : ''}
      </FlexContainer>
    </StyledTextfield>
    
  )
}

export default InputRadio