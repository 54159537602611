import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISummaryRole } from '../../services/AccessService';

interface IUserState {
  groupRoles: ISummaryRole[],
  projectRoles: ISummaryRole[],
  globalRoles: ISummaryRole[],
  localRoles: ISummaryRole[],
  isLoading: boolean;
  error:  string | null;
}

const initialState: IUserState = {
  groupRoles: [],
  projectRoles: [],
  globalRoles: [],
  localRoles: [],
  isLoading: false,
  error: '',
}

export const rolesSlice = createSlice(
{
    name: 'roles',
    initialState,

    reducers: {
      setLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setError(state, action: PayloadAction<string>) {
        state.error = action.payload;
      },
      addGlobalRole(state, action: PayloadAction<ISummaryRole>) {
        const findedRole = state.globalRoles.find(role => role.id === action.payload.id);
        if (!findedRole) {
          state.globalRoles.push(action.payload)
        }
      },
      addGroupRole(state, action: PayloadAction<ISummaryRole>) {
        const findedRole = state.groupRoles.find(role => role.id === action.payload.id)
        if (!findedRole) {
          state.groupRoles.push(action.payload)
        }
      },
      addProjectRole(state, action: PayloadAction<ISummaryRole>) {
        const findedRole = state.projectRoles.find(role => role.id === action.payload.id)
        if (!findedRole) {
          state.projectRoles.push(action.payload)
        }
      },
      addLocalRoles(state, action: PayloadAction<ISummaryRole>) {
        const findedRole = state.projectRoles.find(role => role.id === action.payload.id)
        if (!findedRole) {
          state.localRoles.push(action.payload)
        }
      },
    }
},

)

export default rolesSlice.reducer;