import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IReducerState {
  isLoading: boolean
  error: string | null | boolean

  editorHtmlState: string

  readme: string;
}

const initialState: IReducerState = {
  isLoading: false,
  error: '',
  editorHtmlState: '',

  //project overview
  readme: '',
}

export const editorSlice = createSlice({
  name: 'editor',
  initialState,

  reducers: {
    setInitialState(state) {
      state.isLoading = initialState.isLoading
      state.error = initialState.error
      state.editorHtmlState = initialState.editorHtmlState
    },
    setError(state, action: PayloadAction<string | boolean | null>) {
      state.error = action.payload
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setEditorState(state, action: PayloadAction<string>) {
      state.editorHtmlState = action.payload
    },
    setOverview(state, action: PayloadAction<string>){
      state.editorHtmlState = action.payload
    },
  }
})

export default editorSlice.reducer
