import React, { FC, useEffect } from 'react'
import { StyledAnnotsTab, StyledDrawAnnotsTabs, StyledDrawNavContent } from '../../Styled/styled'
import TabsLabel from './TabsLabel';
import { useParams } from 'react-router-dom';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { ICanvasElement } from '../DrawMain';
import { blackoutPolygon, drawBlackoutForClass, drawBlackoutPolygon, drawSelectedElemDragPoints } from '../../Utils/DrawMethods';
import { useTranslation } from 'react-i18next';

interface IDrawAnnotsProps {
  elements: any;
  selectedElement: ICanvasElement | null;
  setSelectedElement: React.Dispatch<React.SetStateAction<ICanvasElement | null>>;
  setSelectedElementDragPoints: React.Dispatch<React.SetStateAction<any[]>>;
  setBlackoutPolygon: React.Dispatch<React.SetStateAction<blackoutPolygon | null>>
}

const DrawAnnots: FC<IDrawAnnotsProps> = ({elements, selectedElement, setSelectedElement, setBlackoutPolygon, setSelectedElementDragPoints, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  const [currentProjectID, setCurrentProjectID] = React.useState("")
  const [isFirstTabClicked, setFirstTabClicked] = React.useState(true)
  const [elementsWithoutImageRect, setElementsWithoutImageRect] = React.useState<any[]>([])
  const [usedLabels, setUsedLabels] = React.useState<any[]>([])
  
  useEffect(() => {
    if (params.id) {
      setCurrentProjectID(params.id)
    }
    if (elements?.length >= 1) {
      const elementsWithoutImageRect = elements.filter((elem: any) => elem.id !== 0)
      const elementsWithName = elementsWithoutImageRect.filter((elem: any) => elem.name !== null)

      const uniqueElementsLabels: any[] = []
      elementsWithName.forEach((elem:any) => {
        if (!uniqueElementsLabels?.length) {
          uniqueElementsLabels.push(elem)
        }
        if (uniqueElementsLabels?.length) {
          const findedElemWithColor = uniqueElementsLabels.find((uniElem: any) => uniElem.labelColor === elem.labelColor)
          if (!findedElemWithColor) {
            uniqueElementsLabels.push(elem)
          }
        }
      });

      // const uniqueElementsLabels = classes.filter((elem: any, index: number) => {
      //   return classes.indexOf(elem) === index
      // })
      setElementsWithoutImageRect(elementsWithName)
      setUsedLabels(uniqueElementsLabels)
      // console.log('elementsWithName', elementsWithName)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements])

  const onLabelClick = (id: number, name: string, color: string) => {
    const findedElement = elements.find((elem:any) => elem.id === id)
    if (findedElement) {
      setSelectedElementDragPoints([])
      setSelectedElement(findedElement)
      drawSelectedElemDragPoints(elements[findedElement.id], setSelectedElementDragPoints)
      drawBlackoutPolygon(elements[0], elements[findedElement.id], setBlackoutPolygon)
    }
  }

  const onClassMouseOver = (id: number, name: string, color: string) => {
    const filteredElements = elements.filter((elem:any) => elem.labelColor === color)
    console.log(filteredElements)
    if (filteredElements) {
      drawBlackoutForClass(elements[0], filteredElements, setBlackoutPolygon)
    } 
  }

  return (
    <StyledDrawNavContent>
      <StyledDrawAnnotsTabs>
        <StyledAnnotsTab active={isFirstTabClicked} onClick={() => setFirstTabClicked(true)}>{t('authorized.project.draw.nav.classes')} ({usedLabels?.length})</StyledAnnotsTab>
        <StyledAnnotsTab active={!isFirstTabClicked} onClick={() => setFirstTabClicked(false)}>{t('authorized.project.draw.nav.objects')} ({elementsWithoutImageRect?.length})</StyledAnnotsTab>
      </StyledDrawAnnotsTabs>
      <FlexContainer direction='column' justify='flex-start' align='flex-start' margin='10px 0 0 0' gap='8px'>
        {isFirstTabClicked ? usedLabels?.map((elem: any) => 
          <TabsLabel 
            key={elem.id} 
            onLabelClick={() => {}} 
            id={elem.id} 
            color={elem.labelColor} 
            projectId={currentProjectID}
            currentLabelName={elem.label}
            name={elem.label}
            isClass={true}
            onHover={onClassMouseOver}
          />
        ) 
        : ''}
        {!isFirstTabClicked ? elementsWithoutImageRect?.map((elem: any, index) =>
          <TabsLabel 
            key={elem.id} 
            onLabelClick={onLabelClick} 
            id={elem.id} 
            color={elem.labelColor} 
            projectId={currentProjectID}
            currentLabelName={elem.label}
            name={elem.label}
            isClass={false}
            selectedElement={selectedElement} 
            onHover={() => {}}
            index={index}
          />
        )  
        : ''}
      </FlexContainer>
    </StyledDrawNavContent>
  )
}

export default DrawAnnots