import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Option {
  title: string;
  value: string;
};

interface IIsTabsClick {
    isTabManageClicked: boolean;
    isTabOverviewClicked: boolean;
    isTabUploadClicked: boolean;
    isTabAssignClicked: boolean;
    isTabAnnotateClicked: boolean;
    isTabDatasetClicked: boolean;
    isTabGenerateClicked: boolean;
    isTabVersionsClicked: boolean;
    isTabDeployClicked: boolean;
    isTabHealthClicked: boolean;
    isTabStatisticsClicked: boolean;
}

interface IReducerState {
  isTabsClick: IIsTabsClick;
  viewMode: Option;
  isLoading: boolean;
  error:  string | null;
  userRoleNumber: number;
  activeTab: string;
}

const initialState: IReducerState = {
  isTabsClick: {
    isTabManageClicked: false,
    isTabOverviewClicked: false,
    isTabUploadClicked: false,
    isTabAssignClicked: false,
    isTabAnnotateClicked: false,
    isTabDatasetClicked: false,
    isTabGenerateClicked: false,
    isTabVersionsClicked: false,
    isTabDeployClicked: false,
    isTabHealthClicked: false,
    isTabStatisticsClicked: false,
  },

  activeTab: '',
  userRoleNumber: 7,
  viewMode: { "title": "Гость", "value": "USER" },
  isLoading: false,
  error: '',
}

export const tabsSlice = createSlice(
{
    name: 'tabs',
    initialState,

    reducers: { 
      setInitialState(state) {
        state.isTabsClick = initialState.isTabsClick;
        state.activeTab = initialState.activeTab;
        state.userRoleNumber = initialState.userRoleNumber;
        state.viewMode = initialState.viewMode;
        state.isLoading = initialState.isLoading;
        state.error = initialState.error;
      },
      setActiveTab(state, action: PayloadAction<string>) {
        state.activeTab = action.payload;
      },
      setViewMode(state, action: PayloadAction<Option>) {
        state.viewMode = action.payload
      },
      setUserRoleNumber(state, action: PayloadAction<number>) {
        state.userRoleNumber = action.payload
      }
    }
},

)

export default tabsSlice.reducer;