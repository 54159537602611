import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { Colors } from '../../models/Colors'
import { IInputProps } from '../../models/IInputProps'
import Tooltip from '../Tooltip/Tooltip'
import { ReactDOM } from 'react'

const StyledInput = styled.input<IInputProps>`
  width: ${props => props.widthProps || '300px'};
  height:${props => props.heightProps || '35px'};
  padding:${props => props.paddingProps || '4px'};
  background-color:${props => props.backgroundProps || Colors.darker};
  border: 1px solid ${Colors.gray};
  border-color: ${props => props.borderColor || Colors.gray};
  border-radius: 6px;
  color: ${props => props.textColor || Colors.textgray};
  transition: all 0.1s linear;
  &:focus { 
    transition: all 0.1s linear;
    outline: none;
    border: 1px solid ${props => props.focusColor || Colors.orange}
   };

  ::placeholder {
    font-size: 12px;
    color: #a2a2a2;
  }

  ::-webkit-calendar-picker-indicator { 
    color: #e9e9e9; 
    background-color: #ffffffba; 
    cursor: pointer;
  }
  ::-webkit-datetime-edit-text { 
    color: #e9e9e9;
  }

  ::-webkit-datetime-edit-month-field { color: #e9e9e9; }
  ::-webkit-datetime-edit-day-field { color: #e9e9e9; }
  ::-webkit-datetime-edit-year-field { color: #e9e9e9; }

`
const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  margin-bottom: 4px;
  padding-left: 3px;
  
  font-size: 15px;
  font-weight: 300;
  
`
const StyledTextfield = styled.div<IInputProps>`
  width: ${props => props.widthProps || ''};
`

const Input: FC<IInputProps> = ({label, withTooltip, tooltipText, withIcon, ...props}) => {
  return (
    <StyledTextfield {...props}>
      <FlexContainer direction='row' gap='5px' justify='flex-start' align='center' paddingProps='0'>
        {withIcon? withIcon : ''}
        {label? <StyledLabel labelColor={props.labelColor}>{label}</StyledLabel> : ''}
        {withTooltip? <Tooltip>{tooltipText}</Tooltip> : ''}
      </FlexContainer>
      <StyledInput {...props} autoComplete='off'/>
    </StyledTextfield>
    
  )
}

export default Input