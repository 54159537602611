import React, { useState } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { StyledCenteredText, StyledCheckboxWrapper } from '../../constants/styled'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import InputRadio from '../../../../UI/InputRadio/InputRadio'
import TopUpBalanceForm from '../TopUpBalanceForm/TopUpBalanceForm'
import { StyledErrorText } from '../../../Projects/constants/Styled';
import { Colors } from '../../../../models/Colors'
import Loader from '../../../../UI/Loader/Loader'
import { ProjectBr } from '../../../../styles/GlobalStyles'
import { IExtendPackageFormProps } from '../../constants/interfaces'
import QuotaPackagesForm from './QuotaPackagesForm'
import { asyncExpandPackage } from '../../../../store/reducers/AcyncActions'
import { useTranslation } from 'react-i18next'

const ExtendPackageForm: React.FC<IExtendPackageFormProps> = ({groupPackage, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const group = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const selectedLicence = useAppSelector(state => state.WorkspacesReducer.selectedLicense)
  const user = useAppSelector(state => state.userReduser.user)
  const { error, isExpandLoading } = useAppSelector(state => state.SettingsReducer)
  const [isExtendChecked, setIsExtendChecked] = useState(true)

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExtendChecked(e.target.checked)
  }

  const onGoBackClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.groupPackages')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setModalWidth('80vw'))
    dispatch(modalWindowSlice.actions.setActiveScrollTrue())
    dispatch(modalWindowSlice.actions.setTooltipText(t('authorized.settings.packagesTooltip')))
    dispatch(modalWindowSlice.actions.setChildren(<QuotaPackagesForm/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onActivateClick = () => {
    const isNoMoney = user.balance < +groupPackage.cost
    if (isNoMoney) {
      dispatch(modalWindowSlice.actions.setTitle(`${t('authorized.settings.noMoney')} :(`))
      dispatch(modalWindowSlice.actions.setTitleColor(Colors.red))
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.topUpForm.formTitle')))
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(modalWindowSlice.actions.setChildren(<TopUpBalanceForm user={user} group={group} discountCost={+groupPackage.cost}/>))
        dispatch(modalWindowSlice.actions.setVisible())
      }, 2000)
      return
    }
    dispatch(asyncExpandPackage({groupId: group.id, licenseId: selectedLicence.id, quotaPackageId: groupPackage.id, autoRenewPackage: isExtendChecked}))
  }

  return (
    <>
    {isExpandLoading && !error && 
      <FlexContainer direction='column' justify='center' align='center' gap='10px'>
        <Loader/>
      </FlexContainer>
    }
    {!isExpandLoading && 
      <FlexContainer direction='column' justify='flex-start' align='center' gap='10px'>
        <StyledCenteredText>{groupPackage?.name}</StyledCenteredText>
        {error ? <StyledErrorText visible>{error}</StyledErrorText> : ''}

        <StyledCheckboxWrapper>
          <InputRadio
            widthProps='14px'
            heightProps='14px'
            checked={isExtendChecked}
            onChange={(e) => handleCheckedChange(e)}
            type="checkbox"
            label={t('authorized.settings.automatic')}  
          />
        </StyledCheckboxWrapper>
        <ProjectBr/>
        <FlexContainer direction='row' justify='space-between' align='center' paddingProps='10px 20px'>
            <span>{t('authorized.settings.topUpForm.total')}:</span>
            <span>{groupPackage?.cost + ' '}RV</span>
        </FlexContainer>  
        {/* <InputRadio
          widthProps='14px'
          heightProps='14px'
          checked={isExtendBalanceChecked}
          onChange={(e) => handleBalanceCheckedChange(e)}
          type="checkbox"
          label={'Ежемесячное пополнение'}  
        /> */}
        <FlexContainer direction='row' justify='space-around' margin='15px 0 0 0'>
          <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onGoBackClick}>{t('buttons.back')}</ProjectButton>
          <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onActivateClick} active>{t('buttons.purchase')}</ProjectButton>
        </FlexContainer>
      </FlexContainer>
    }
  </>
  )
}

export default ExtendPackageForm