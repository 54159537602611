import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { Colors } from '../../../../models/Colors'

interface ITeammateProps {
  name: string;
  email?: string;
  isActive: string;
  setIsActive: React.Dispatch<React.SetStateAction<string>>;
  userId: string;
}

interface IStyledWrapperProps {
  active?: string;
}

const StyledNameFirstLetter = styled.div<IStyledWrapperProps>`
  width: 30px;
  height: 30px;
  background-color: ${({ active }) => active ? Colors.dark :  Colors.darker};
  border: 1px solid ${({ active }) => active ? Colors.orange : Colors.gray};
  border-radius: 50%;
  padding: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.1s linear;
`

const StyledTeammateWrapepr = styled.div<IStyledWrapperProps>`
  width: 100%;
  height: 45px;
  background-color: ${({ active }) => active ? Colors.dark :  Colors.darker};
  border: 1px solid ${({ active }) => active ? Colors.orange : Colors.gray};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.1s linear;
  :hover {
    color: ${Colors.orange};
    background-color: ${Colors.dark};
    border-color: ${Colors.orange};
  }
  :hover ${StyledNameFirstLetter} {
    border-color: ${Colors.orange};
  }
`

const StyledName = styled.div`
  height: 20px;
  font-size: 12px;
`

const Teammate: FC<ITeammateProps> = ({name, email, isActive, setIsActive, userId, ...props}) => {
  const onTeammateClick = () => {
    setIsActive(userId)
  }

  return (
    <StyledTeammateWrapepr active={isActive === userId ? userId : ''} onClick={onTeammateClick}>
      <StyledNameFirstLetter active={isActive === userId ? userId : ''}>
        {name ? name[0]? name[0] : 'G' : 'G'}
      </StyledNameFirstLetter>
      <FlexContainer direction='column' justify='center'>
        <StyledName>{name ? name : 'Guest'}</StyledName>
        {email? <StyledName>{email}</StyledName> : "example@mail.ru"}
      </FlexContainer>
    </StyledTeammateWrapepr>
  )
}

export default Teammate