/* eslint-disable no-loop-func */
import React, { FC, useState } from 'react'
import { NavigateFunction } from 'react-router-dom';
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../hooks/redux';
import BatchesService from '../../../../../services/BatchesService';
import ImagesService from '../../../../../services/ImagesService';
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer';
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../../UI/Input/Input'
import { arraySplitter } from '../../../../../utils/arraySplitter';
import { blopImagesReducer } from './../../../store/reducers/BlopImagesReducer';
import UploadFormPercentage from './UploadFormPercentage';
import JobsService from '../../../../../services/JobsService';
import { StyledErrorText, StyledLink } from '../../../constants/styled';
import { SettingsReducer } from '../../../../UserSettings/reducers/SettingsReducer';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../services/ProjectService';

interface IUploadFormProps {
  projectInfo: IProject;
  images: any[];
  navigate: NavigateFunction
}
interface IImagesUploadObj {
  batchName: string;
  projectId: string;
  images: any[];
}

const UploadImagesForm: FC<IUploadFormProps> = ({projectInfo, images, navigate,...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [batchName, setBatchName] = useState('')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onCantelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBatchName(e.target.value)
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(projectInfo.group))
    navigate(`/settings/group/${projectInfo.group}`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onContinueClick = (batchId: string, successFullFiles: number) => {
    if (!successFullFiles) {
      onSettingsClick()
    }
    if (successFullFiles) {
      createUploadDetectJob(batchId)
      dispatch(modalWindowSlice.actions.setChildren(<UploadFormPercentage/>))
    }
  }


  const getProgress = async (job: string, batchId: string) => {
    try {
      if (job && batchId) {
        const getJobProgress = await ImagesService.getUploadDetectProgress(job)
        if (getJobProgress?.data?.progress === null) {
          dispatch(modalWindowSlice.actions.setChildren(
            <FlexContainer direction='column' justify='center' align='center' gap='30px'>
              <span>Error ;(</span>
              <span>null progress</span>
              <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps='150px'>{t('buttons.cancel')}</ProjectButton>
            </FlexContainer>  
          ))
          await JobsService.deleteJob(job)
          throw new Error('Null Percents :(')
        }
        if (!getJobProgress?.data) {
          dispatch(modalWindowSlice.actions.setChildren(
          <FlexContainer direction='column' justify='center' align='center' gap='30px'>
            <span>Error ;(</span>
            <span>500 server error</span>
            <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps='150px'>{t('buttons.cancel')}</ProjectButton>
          </FlexContainer>  
          ))
          await JobsService.deleteJob(job)
          throw new Error('No Responce.data :(')
        }
        if (getJobProgress?.data?.progress){
          console.log(getJobProgress?.data?.progress)
          dispatch(blopImagesReducer.actions.setPercents(getJobProgress?.data?.progress))
        }
        if (getJobProgress?.data?.message) {
          await JobsService.deleteJob(job)
          dispatch(modalWindowSlice.actions.setInititialState())
          navigate(`/projects/${projectInfo.id}/batch/${batchId}`)
          return
        }
        if (getJobProgress?.data?.progress !== '100%') {
          if (job) {
            console.log('Retry!', getJobProgress?.data)
            setTimeout(() => {
              getProgress(job, batchId)
            }, 500);
          }
        }
      }
    } catch (error) {
      console.log('error on uploadJob', error)
    }
  }

  const createUploadDetectJob = async (batchId: string) => {
    try {
      const responce = await ImagesService.createUploadJob(batchId)
      console.log('subscribe', responce)
      getProgress(responce.data.job, batchId)
    } catch (error) {
        console.log(error)
    }
  }
  
  const onButtonUploadClick = () => {
    const imagesUploadObj = {
      batchName: batchName,
      projectId: projectInfo.id,
      images: images
    }

    const asyncImagesUpload = async (imagesUploadObj: IImagesUploadObj) => {
      try {
        dispatch(blopImagesReducer.actions.setIsLoading(true))
        dispatch(modalWindowSlice.actions.setChildren(<UploadFormPercentage/>))
        const createBatchResponce = await BatchesService.createBatch(imagesUploadObj.batchName, imagesUploadObj.projectId)
        const batchId = createBatchResponce.data.batch.id

        const imagesArrayWithSubArrays = await arraySplitter(imagesUploadObj.images, 10)
        let responceCount = 0
        let diskSpaceQuotaErrors = 0
        let successFullFiles = 0

        for (const sub of imagesArrayWithSubArrays) {
          let fd = new FormData();
          for(let i = 0; i < sub.length; i++) {
            fd.append("files", sub[i]);
            // fd.append("files", sub[i]);
          } 
          fd.append('batch', batchId)

          const responce = await ImagesService.uploadFormData(fd)
          console.log(responce)
          if (responce.data.error) {
            throw new Error(responce.data.error)
          }
          console.log('responce', responce)

          responceCount++
          if (responce?.data?.failedFiles?.length) {
            diskSpaceQuotaErrors += responce?.data?.failedFiles?.length
          }
          if (responce?.data?.files?.length) {
            successFullFiles += responce?.data?.files?.length
          }
         
          responce.data.files.forEach((image) => {
            dispatch(blopImagesReducer.actions.setUploadedImages(image))
          })
          dispatch(blopImagesReducer.actions.setPercents(Math.floor(((responceCount)/(imagesArrayWithSubArrays.length)) * 100)))
          dispatch(blopImagesReducer.actions.setUploadValues({uploaded: responceCount, total: imagesArrayWithSubArrays.length}))
          console.log('responceCount', responceCount, 'length', imagesArrayWithSubArrays.length)
          if (responceCount === imagesArrayWithSubArrays.length) {
            if (responce?.data?.failedFiles?.length) {
              dispatch(blopImagesReducer.actions.setPercents(0))
              dispatch(blopImagesReducer.actions.setIsLoading(false))
              dispatch(modalWindowSlice.actions.setTitle('Oops!'))
              dispatch(modalWindowSlice.actions.setChildren(
                <FlexContainer direction='column' justify='center' align='center' gap='15px'>
                  <StyledErrorText visible>{t('errors.diskQuotaError')}</StyledErrorText>
                  <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>
                  <span>{t('authorized.project.upload.successImages')}: {successFullFiles}</span>
                  <span>{t('authorized.project.upload.failedImages')}: {diskSpaceQuotaErrors}</span> 
                  <FlexContainer direction='row' justify='center' align='center' paddingProps='10px 0 0 0'>
                    <ProjectButton onClick={() => onContinueClick(batchId, successFullFiles)}>{t('buttons.continue')}</ProjectButton>
                  </FlexContainer>
                </FlexContainer>
              ))
            }
            if (!responce?.data?.failedFiles?.length) {
              createUploadDetectJob(batchId)
            }
          }
        }
      } catch (error) {
        dispatch(modalWindowSlice.actions.setChildren(
          <FlexContainer direction='column' justify='center' align='center' paddingProps='15px'>
            <span>{t('authorized.project.upload.error')}</span>
          </FlexContainer>))
        dispatch(blopImagesReducer.actions.setIsLoading(false))
        dispatch(blopImagesReducer.actions.setIsError(t('authorized.project.upload.error')))
        console.log('asyncImagesUpload', error)
      }
    }

    asyncImagesUpload(imagesUploadObj)
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
        <Input label={t('authorized.project.upload.inputTitle')} placeholder={t('authorized.project.upload.inputPlaceholder')} value={batchName} onChange={onNameChange}/>
        <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
            <ProjectButton onClick={onCantelClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onButtonUploadClick}>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
    </FlexContainer>
  )
}

export default UploadImagesForm