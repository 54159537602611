import { $host } from "../http/index";
import { IUser } from '../models/IUser';
import { AuthResponse } from "../models/responses/AuthResponce";
import { AxiosResponse } from "axios";

export interface IRegistrationResponce {
    token: string;
    message: string;
}
// 201 created
// 500 internal server error

export interface ILoginResponce {
    token: string;
    message: string;
}
// 200 OK
// 500 internal server error

export interface IVerifyEmailResponce {
    message: string;
    token: string;
}
// 201 created
// 400 bad request
// 404 not found
// 500 internam server error

export interface IResendCodeResponce {
    token: string;
    message: string;
}
// 201
// 500

export interface IForgotPasswordResponce {
    token: string;
    message: string;
}
// 200
// 500 

export interface IActivateForgotPasswordResponce {
    message: string;
}
// 200 
// 400 bad request
// 401 unauthorized
// 404
// 500

export interface IInvitedUser {
    firstName: string;
    lastName: string;
    inviteCode: string;
    phone: string;
    userType: number;
}

export default class AuthService {
    static async login(email:string, password:string): Promise<AxiosResponse<AuthResponse>> {
        return $host.post<AuthResponse>('/auth/signin', {email, password})
    }

    static registration(UserData:IUser): Promise<AxiosResponse<AuthResponse>> {
        console.log({...UserData})
        return $host.post<AuthResponse>('/auth/signup', {...UserData})
    }

    static invitedRegistration(UserData:IInvitedUser): Promise<AxiosResponse<AuthResponse>> {
        console.log({...UserData})
        return $host.post<AuthResponse>('/auth/invite-signup', {...UserData})
    }

    static async verifyEmail(token: string, code: string): Promise<AxiosResponse<IVerifyEmailResponce>> {
        return $host.post<IVerifyEmailResponce>('/auth/activate-account', {token: token, code: code})
    } 

    // запрос на восстановление пароля, отправляется письмо на почту /forgot-password
     static async forgotPassword(email: string): Promise<AxiosResponse<IForgotPasswordResponce>> {
        return $host.post<IForgotPasswordResponce>('/auth/forgot-password', {email: email})
    }

     static async resendActivationCode(email: string): Promise<AxiosResponse<IResendCodeResponce>> {
        return $host.post<IResendCodeResponce>('/auth/resend-activation-code', {email: email})
    }

    static async activateForgotPassword(token: string, password: string, code: string): Promise<AxiosResponse<IActivateForgotPasswordResponce>> {
        return $host.put<IActivateForgotPasswordResponce>('auth/activate-forgot-password', {token, password, code})
    }

    static async logout(): Promise<void> {
        return $host.post('/logout')
    } 
}
