import React from "react";
import { ITabsProps } from "../../constants/interfaces";
import FlexContainer from "../../../../components/FlexContainer/FlexContainer";
import ProjectButton from "../../../../UI/Buttons/ProjectButton/ProjectButton";
import { useTranslation } from "react-i18next";

export const TransactionsTabs: React.FC<ITabsProps> = ({activeTab, setActiveTab}) => {
  const { t } = useTranslation()
  return (
    <FlexContainer direction='row' justify='center' align='center' gap='20px' wrap='wrap'>
      <ProjectButton 
        active={activeTab === 'fiat'} 
        onClick={() => setActiveTab('fiat')} 
        heightProps='35px' 
        widthProps='190px' 
        FontSizeProps='15px'
      >
        {t('authorized.settings.fiatOperations')}
      </ProjectButton> 
      <ProjectButton 
        active={activeTab === 'coins'} 
        onClick={() => setActiveTab('coins')} 
        heightProps='35px' 
        widthProps='190px' 
        FontSizeProps='15px'
      >
        {t('authorized.settings.tugricsOperations')}
      </ProjectButton>  
    </FlexContainer>
  )
}