import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRegistrationErrorTotal {
  isFirstNameError: boolean;
  isLastNameError: boolean;
  isEmailError: boolean;
  isPhoneError: boolean;
  isPasswordError: boolean;
  isPasswordMismatchError: boolean;
  total: boolean;

}

const initialState: IRegistrationErrorTotal = {
  isFirstNameError: false,
  isLastNameError: false,
  isEmailError: false,
  isPhoneError: true,
  isPasswordError: false,
  isPasswordMismatchError: true,
  total: false
}

export const registrationErrorTotal = createSlice(
{
    name: 'registrationErrorTotal',
    initialState,

    reducers: {
      setIsFirstNameError(state, action: PayloadAction<boolean>) {
        state.isFirstNameError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
            state.total = true
        }
      },
      setIsLastNameError(state, action: PayloadAction<boolean>) {
        state.isLastNameError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
          state.total = true
      }
      },
      setIsEmailEror(state, action: PayloadAction<boolean>) {
        state.isEmailError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
          state.total = true
      }
      },
      setIsPhoneError(state, action: PayloadAction<boolean>) {
        state.isPhoneError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
          state.total = true
      }
      },
      setIsPasswordError(state, action: PayloadAction<boolean>) {
        state.isPasswordError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
          state.total = true
      }
      },
      setIsPasswordMismatchError(state, action: PayloadAction<boolean>) {
        state.isPasswordMismatchError = action.payload
        if (state.isFirstNameError && state.isLastNameError && state.isEmailError && state.isPhoneError && state.isPasswordError && state.isPasswordMismatchError) {
          state.total = true
        }
      },
      checkTotal(state) {
        if (state.isEmailError && state.isFirstNameError && state.isLastNameError && state.isPasswordError && state.isPasswordMismatchError && state.isPhoneError) {
          state.total = true
        }
      },
      
    }
},
)

export default registrationErrorTotal.reducer;