import React, { FC, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import { asyncChangeTaskDescription } from '../../../../../../../store/reducers/AcyncActions';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import Input from '../../../../../../../UI/Input/Input';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useTranslation } from 'react-i18next';

interface IEditDescriptionProps {
  jobId: string;
  name: string;
}

const EditDescription: FC<IEditDescriptionProps> = ({jobId, name, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const task = useAppSelector(state => state.tasksReducer.currentTask)
  const [description, setDescription] = useState('')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onCancelClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const onButtonRenameClick = () => {    
    dispatch(asyncChangeTaskDescription(jobId, name, description , task?.task?.status?.id, task?.task?.moderator?.id, task?.task?.annotator?.id))
    setModalVisivble()
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.project.taskPage.instruction')} placeholder={t('forum.enterText')} value={description} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton widthProps='130px' FontSizeProps='16px' onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton widthProps='130px' FontSizeProps='16px' onClick={onButtonRenameClick}>{t('other.edit')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default EditDescription