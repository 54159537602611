import React, { MouseEventHandler, useEffect, useRef } from 'react'
import Styles from './styles.module.css'
import { Option } from './ProjectSelect';

interface OptionProps {
  option: Option;
  onClick: (value: Option["value"]) => void;
};
  
const OptionEl = (props: OptionProps) => {
  const {option: { value, title }, onClick} = props;
  const optionRef = useRef<HTMLLIElement>(null);

  const handleClick = (clickedValue: Option["value"]): MouseEventHandler<HTMLLIElement> => () => {
    onClick(clickedValue);
  };

  useEffect(() => {
    const option = optionRef.current;
    if (!option) return;
    const handleEnterKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === option && event.key === "Enter") {
        onClick(value);
      }
    };

    option.addEventListener("keydown", handleEnterKeyDown);
    return () => {
      option.removeEventListener("keydown", handleEnterKeyDown);
    };
  }, [value, onClick]);

  return (
    <li
      className={Styles.option}
      value={value}
      onClick={handleClick(value)}
      tabIndex={0}
      data-testid={`select-option-${value}`}
      ref={optionRef}
    >
      {title}
    </li>
  );
};
export default OptionEl;