import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroupPackage, IGroupQuota } from '../../../services/QuotasService';
import { IFullGroup, IShortGroup } from '../../../services/GroupService';
import { ILicense } from '../../../services/LicensesService';
import { IGetSettingsRes, ISettings } from '../../../services/SettingsService';
import { IGetSubscriptionsRes, ISubsctiption } from '../../../services/SubscriptionsService';

interface IReducerState {
    error: string | null;
    isLoading: boolean;
    isExpandLoading: boolean;

    activeTab: string;

    ownerGroups: IShortGroup[];
    memberGroups: IShortGroup[];
    selectedGroup:  IFullGroup[];
    groupQuotas:  IGroupQuota[];

    settings: ISettings[];
    subscriptions: ISubsctiption[];

    licenses: ILicense[];

    groupPackages: IGroupPackage[];
    isPackagesLoading: boolean;
}

const initialState: IReducerState = {
    error: '',
    isLoading: false,
    isExpandLoading: false,

    activeTab: '',

    ownerGroups: [],
    memberGroups: [],
    selectedGroup: [],
    groupQuotas: [],

    settings: [],
    subscriptions: [],

    licenses: [],

    groupPackages: [],
    isPackagesLoading: false,
}

export const SettingsReducer = createSlice(
{
    name: 'settings',
    initialState,

    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setExpandLoading(state, action: PayloadAction<boolean>) {
            state.isExpandLoading = action.payload; 
        },
        setPackagesLoading(state, action: PayloadAction<boolean>) {
            state.isPackagesLoading = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },

        setOwnerGroups(state, action: PayloadAction<IShortGroup[]>) {
            state.ownerGroups = action.payload;
        },

        setMemberGroups(state, action: PayloadAction<IShortGroup[]>) {
            state.memberGroups = action.payload;
        },

        setLicenses(state, action: PayloadAction<ILicense[]>) {
            state.licenses = action.payload
        },

        setGroupQuotas(state, action: PayloadAction<IGroupQuota[]>) {
            state.groupQuotas = action.payload
        },

        setSettings(state, action: PayloadAction<IGetSettingsRes['settings']>) {
            state.settings = action.payload
        },

        setSubsctiptions(state, action: PayloadAction<IGetSubscriptionsRes['subsctiptions']>) {
            state.subscriptions = action.payload
        },

        setGroupPackages(state, action: PayloadAction<IGroupPackage[]>) {
            state.groupPackages = action.payload
        },
    }
},

)

export default SettingsReducer.reducer;