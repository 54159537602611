import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Colors } from '../../../models/Colors'
import {
  StyledArrow,
  StyledArrowAndInfoWrapper,
  StyledArrowWrapper,
  StyledColoredSpan,
  StyledDrawHeader,
  StyledGreaterThanIcon,
  StyledImageInfo,
  StyledImageInfoHeader,
  StyledLessThanIcon,
  StyledModeratePanel,
  StyledSliderTabsWrapper
} from '../Styled/styled'
import { useAppDispatch, useAppSelector } from './../../../hooks/redux'
import DrawHeaderDropdown from './DrawHeaderDropdown'
import { asyncAnnotateImage, asyncGetProjectLabels } from '../../../store/reducers/AcyncActions'
import { drawTabsSlice } from '../store/DrawTabsReducer'
import { ICanvasElement } from './DrawMain'
import { dataConverterForApi, getApiElemsWithStatus } from '../Utils/DrawMethods'
import { StyledCrossIcon, StyledMarkIcon } from '../../Projects/constants/Styled'
import { useTranslation } from 'react-i18next'
import { INewImage } from '../../../services/BatchesService'
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton'

interface IDrawHeaderProps {
  batchName: string
  imageName: string
  imagesCount: number
  clearHistory: any
  elements: any
  setSelectedElement: React.Dispatch<React.SetStateAction<ICanvasElement | null>>
  setAction: React.Dispatch<React.SetStateAction<string>>

  moderate: string
  onModerateClick: (action: string) => void
  setModerate: React.Dispatch<React.SetStateAction<string>>
  imageStatus: string | null | undefined
  cleared: boolean

  setSelectedElementDragPoints: React.Dispatch<React.SetStateAction<any[]>>
}

const DrawHeader: FC<IDrawHeaderProps> = ({
  cleared,
  moderate,
  imageStatus,
  setModerate,
  setAction,
  setSelectedElement,
  batchName,
  imageName,
  clearHistory,
  imagesCount,
  elements,
  setSelectedElementDragPoints,
  onModerateClick,
  ...props
}) => {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const action = useAppSelector((state) => state.DrawTabsReducer.action)
  const viewMode = useAppSelector((state) => state.TabsReducer.viewMode)

  const currentUser = useAppSelector((state) => state.userReduser.user)
  const currentTask = useAppSelector((state) => state.tasksReducer.currentTask)
  const isGuestViewMode = useAppSelector((state) => state.DrawTabsReducer.guestViewMode)
  const drawLoaderVisible = useAppSelector((state) => state.drawLoaderReducer.visible)
  const moderPanelActivity = useAppSelector((state) => state.DrawTabsReducer.modelPanelActivity)

  const isModalVisible = useAppSelector((state) => state.modalWindowReducer.visible)
  const isImageLoaderVisible = useAppSelector((state) => state.drawLoaderReducer.visible)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  // const [doSlide, setDoSlide] = useState(true)
  const [currentJobName, setCurrentJobName] = useState('')
  const imagesFromList = useAppSelector((state) => state.ImagesListReducer.images)
  const { selectedImages, currentPageName, selectedSplit } = useAppSelector((state) => state.ImagesListReducer)
  const previousPage = useAppSelector(state => state.DrawTabsReducer.previousPage)
  const [images, setImages] = useState<INewImage[]>([])

  const imageInfo = useAppSelector(state => state.DrawTabsReducer.imageInfo)

  //Выход из рисовалки, если задача находится:
  //В запретном* статусе.
  //Формирование списка изображений исходя из экшена
  useEffect(() => {
    if (
      (currentTask?.task?.status?.name === 'processing' || currentTask?.task?.status?.name === 'in_queue' || currentTask?.task?.status?.name === 'automarkup') &&
      currentUser?.role?.name !== 'global_admin' &&
      currentUser?.role?.name !== 'developer'
    ) {
      previousPage 
        ? navigate(previousPage)
        : navigate(`/projects/${params.id}/annotate`)
    }

    if (!action) {
      previousPage 
        ? navigate(previousPage)
        : navigate(`/projects/${params.id}/annotate`)
    }

    if (params.id) {
      dispatch(asyncGetProjectLabels(params.id))
    }
  
    if (params.imgName && action === 'images') {
      setImages(imagesFromList)
      const findedImage = imagesFromList.find((image) => image.id === params.imgName)
      if (findedImage) {
        setCurrentImageIndex(imagesFromList.indexOf(findedImage))
      }
    }
  
    if (params.imgName && action === 'selectedImages') {
      setImages(selectedImages)
      const findedImage = selectedImages.find((image) => image.id === params.imgName)
      if (findedImage) {
        setCurrentImageIndex(selectedImages.indexOf(findedImage))
      }
    }
  
    setCurrentJobName(currentTask?.task?.name)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTask?.task?.status?.name])

  const onArrowClick = () => {
    console.log('previousPage0', previousPage)
    console.log('previousPage1', previousPage)
    console.log('previousPage2', previousPage)
    previousPage 
    ? navigate(previousPage)
    : navigate(`/projects/${params.id}/annotate`)
  }

  useEffect(() => {
    if (moderate) {
      if (moderate === 'reject') {
        onGreaterThanClick()
      }
      if (moderate === 'skip') {
        onGreaterThanClick()
      }
      if (moderate === 'approve') {
        onGreaterThanClick()
      }
      setModerate('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moderate])

  const onLessThanClick = () => {
    const apiElems = dataConverterForApi(elements, imageInfo!.image.width, imageInfo!.image.height, params.imgName!, params.id!)
    const apiElemsWithStatus = getApiElemsWithStatus(apiElems, imageStatus, moderate, viewMode, cleared)
    console.log('apiElemsWithStatus', apiElemsWithStatus)
    console.log('images', images)
    console.log('isModalVisible', isModalVisible)


    if (!isModalVisible && !isImageLoaderVisible) {
      if (images.length === 1) {
        if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
          dispatch(asyncAnnotateImage(apiElemsWithStatus))
          return
        }
        return
      }

      if (images.length > 1 && action !== 'selectedDatasets') {
        if (images[currentImageIndex - 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(currentImageIndex - 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[currentImageIndex - 1].id}`)
          setCurrentImageIndex(currentImageIndex - 1)
        }
        if (!images[currentImageIndex - 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(images.length - 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[images.length - 1].id}`)
          setCurrentImageIndex(images.length - 1)
        }
      }
      if (images.length > 1 && action === 'selectedDatasets') {
        if (images[currentImageIndex - 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(currentImageIndex - 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[currentImageIndex - 1].id}`)
          setCurrentImageIndex(currentImageIndex - 1)
        }
        if (!images[currentImageIndex - 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(images.length - 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[images.length - 1].id}`)
          setCurrentImageIndex(images.length - 1)
        }
      }
      // clearHistory([])
      setSelectedElementDragPoints([])
      setAction('none')
      setSelectedElement(null)
    }
  }

  const onGreaterThanClick = async () => {
    const apiElems = dataConverterForApi(elements, imageInfo!.image.width, imageInfo!.image.height, params.imgName!, params.id!)
    const apiElemsWithStatus = getApiElemsWithStatus(apiElems, imageStatus, moderate, viewMode, cleared)
    console.log('apiElemsWithStatus', apiElemsWithStatus)

    console.log('isImageLoaderVisible', isImageLoaderVisible)

    if (!isModalVisible && !isImageLoaderVisible) {
      if (images.length === 1) {
        if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
          dispatch(asyncAnnotateImage(apiElemsWithStatus))
          return
        }
        return
      }

      if (images.length > 1 && action !== 'selectedDatasets') {
        if (images[currentImageIndex + 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(currentImageIndex + 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[currentImageIndex + 1].id}`)
          setCurrentImageIndex(currentImageIndex + 1)
        }
        if (!images[currentImageIndex + 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(0))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[0].id}`)
          setCurrentImageIndex(0)
        }
      }
      if (images.length > 1 && action === 'selectedDatasets') {
        if (images[currentImageIndex + 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(currentImageIndex + 1))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[currentImageIndex + 1].id}`)
          setCurrentImageIndex(currentImageIndex + 1)
        }
        if (!images[currentImageIndex + 1]) {
          if ((apiElemsWithStatus?.boxes?.length || cleared) && (!isGuestViewMode || moderPanelActivity)) {
            dispatch(asyncAnnotateImage(apiElemsWithStatus))
          }

          dispatch(drawTabsSlice.actions.setCurrentImageIndex(0))
          navigate(`/projects/${params.id}/job/annotate/${params.jobId}/draw/${images[0].id}`)
          setCurrentImageIndex(0)
        }
      }
      // clearHistory([])
      setSelectedElementDragPoints([])
      setAction('none')
      setSelectedElement(null)
    }
  }

  useEffect(() => {
    const chengeImage = (e: any) => {
      if (!drawLoaderVisible && (e.key === 'a' || e.key === 'A' || e.key === 'Ф' || e.key === 'ф')) {
        if (!isModalVisible && !isImageLoaderVisible) {
          onLessThanClick()
        }
      }
      if (!drawLoaderVisible && (e.key === 'd' || e.key === 'D' || e.key === 'В' || e.key === 'в')) {
        if (!isModalVisible && !isImageLoaderVisible) {
          onGreaterThanClick()
        }
      }
    }

    document.addEventListener('keydown', chengeImage)
    return () => {
      document.removeEventListener('keydown', chengeImage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGreaterThanClick, onLessThanClick])

  return (
    <StyledDrawHeader>
      <StyledArrowAndInfoWrapper>
        <StyledArrowWrapper onClick={onArrowClick}>
          <StyledArrow />
        </StyledArrowWrapper>
        <StyledImageInfo>
          <StyledImageInfoHeader>
            <StyledColoredSpan textColor={Colors.lightgray}>{currentJobName ? currentJobName.split(' ')[0] : 'Job'}</StyledColoredSpan>
            <StyledGreaterThanIcon heightProps="10px" widthProps="10px" />
            <StyledColoredSpan textColor={Colors.orange}>{t('authorized.project.draw.nav.annotations')}</StyledColoredSpan>
          </StyledImageInfoHeader>
          <StyledColoredSpan textColor={Colors.textgray}>{images[currentImageIndex] ? images[currentImageIndex]?.displayName : 'imageName'}</StyledColoredSpan>
        </StyledImageInfo>
      </StyledArrowAndInfoWrapper>
      <StyledSliderTabsWrapper>
        <StyledArrowWrapper onClick={() => onLessThanClick()}>
          <StyledLessThanIcon widthProps="15px" heightProps="15px" />
        </StyledArrowWrapper>
        <StyledColoredSpan textColor={Colors.lightgray}>
          {currentImageIndex + 1} / {images.length || 0}
        </StyledColoredSpan>
        <StyledArrowWrapper onClick={() => onGreaterThanClick()}>
          <StyledGreaterThanIcon widthProps="15px" heightProps="15px" />
        </StyledArrowWrapper>
        {viewMode.value === 'MODER' && moderPanelActivity ? (
          <StyledModeratePanel>
            <ProjectButton onClick={() => onModerateClick('reject')} widthProps="50px" heightProps="30px" paddingProps="0">
              <StyledCrossIcon marginProps="0" height="15px" width="15px" />
            </ProjectButton>
            <ProjectButton onClick={() => onModerateClick('skip')} widthProps="100px" paddingProps="0 10px" heightProps="30px" FontSizeProps="14px">
              {t('authorized.project.draw.nav.skip')}
            </ProjectButton>
            <ProjectButton onClick={() => onModerateClick('approve')} widthProps="50px" heightProps="30px" paddingProps="0">
              <StyledMarkIcon marginProps="0" height="15px" width="18px" />
            </ProjectButton>
          </StyledModeratePanel>
        ) : (
          ''
        )}
      </StyledSliderTabsWrapper>
      <DrawHeaderDropdown elements={elements} params={params} moderate={moderate} imageStatus={imageStatus} cleared={cleared} isGuestViewMode={isGuestViewMode} />
    </StyledDrawHeader>
  )
}

export default DrawHeader
