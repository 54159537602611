import i18n from "../../../i18n";

const regExpObj = {
    password: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,100}/,
}
    
// Проверка на совпадение паролей
export const validatePasswordMismatch = (PasswordValue:string, repeatPasswordValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (PasswordValue === repeatPasswordValue) {
        isValid = true;
    }
    if (PasswordValue !== repeatPasswordValue) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidMismatch')
    }
    return {errorMessage, isValid}
}

// Проверка на "сложность" пароля
export const validatePasswordWeight = (PasswordValue:string) => {
    let isValid = false;
    let errorMessage = '';

    if (regExpObj.password.test(PasswordValue)) {
        isValid = true;
    }
    if (!regExpObj.password.test(PasswordValue)) {
        isValid = false;
        errorMessage = i18n.t('notAuthorized.signup.errors.invalidPass')
    }
    return {errorMessage, isValid}
}

