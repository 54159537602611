import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface ISettings {
    id: string;
    key: string;
    value: string;
    dataType: string;
    description: string;
}

export interface ICreateSettingsPayload {
  key: string;
  value: string;
  dataType: string;
  description: string;
}

export interface ICreateSettingsRes {
    settings: ISettings;
    message: string;
}

export interface IGetSettingsRes {
    settings: ISettings[];
}

export interface IPutSettingsPayload {
    settings: ISettings[];
}

export interface IPutSettingsRes {
    settings: ISettings;
    message: string;
}

export interface IDeleteSettingsRes {
    message: string;
}

export default class SettingsService {

    static async createSettings(payload: ICreateSettingsPayload): Promise<AxiosResponse<ICreateSettingsRes>> {
        return $authHost.post<ICreateSettingsRes>(`/settings/`, payload)
    }

    static async getSettings(): Promise<AxiosResponse<IGetSettingsRes>> {
        return $authHost.get<IGetSettingsRes>(`/settings/`)
    }

    static async putSettings(payload: IPutSettingsPayload): Promise<AxiosResponse<IPutSettingsRes>> {
        return $authHost.put<IPutSettingsRes>(`/settings/`, payload)
    }

    static async deleteSettings(id: string): Promise<AxiosResponse<ICreateSettingsRes>> {
        return $authHost.delete<ICreateSettingsRes>(`/settings/${id}`)
    }

}