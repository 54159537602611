import React, { FC, useState } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { asyncRenameProject } from '../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../UI/Input/Input'
import { useAppDispatch } from './../../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../services/ProjectService';

interface IRenameProjectFormProps {
  projectInfo: IProject;
}

const RenameProjectForm: FC<IRenameProjectFormProps> = ({projectInfo}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [projectName, setProjectName] = useState('')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value)
  }

  const onButtonRenameClick = () => {
    const project:IProject = {
      id: projectInfo.id,
      name: projectName,
      createdAt: projectInfo.createdAt,
      updatedAt: projectInfo.updatedAt,
      user: projectInfo.user,
      objectType: projectInfo.objectType,  
      group: projectInfo.group
    }
    
    dispatch(asyncRenameProject(project))
    console.log(projectInfo.id);
    setModalVisivble()
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
        <Input label={t('authorized.projects.groupProjects.project.renameLabel')} placeholder={t('authorized.projects.groupProjects.project.renamePlaceholder')} value={projectName} onChange={onNameChange}/>
        <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
          <ProjectButton onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onButtonRenameClick}>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
    </FlexContainer>
  )
}

export default RenameProjectForm