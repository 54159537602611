import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, Params } from 'react-router-dom'
import { StyledForumsWrapper, StyledTopicsWrapper } from '../../../constants/styled'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { asyncGetCategoryById, asyncGetSubCategoryById, asyncGetTopicById } from '../../../../../store/reducers/AcyncActions'
import TopicPageHeader from './TopicPageHeader'
import TopicDescription from './TopicDescription'
import TopicMessage from './TopicMessage'
import TopicMessageField from './TopicMessageField'
import { IUsersMeResponse } from '../../../../../services/UserService'

interface ITopicProps {
  navigate: NavigateFunction
  params: Readonly<Params<string>>
  user: IUsersMeResponse
}

const TopicPage: FC<ITopicProps> = ({ navigate, params, user, ...props }) => {
  const dispatch = useAppDispatch()
  // const currentUser = useAppSelector(state => state.userReduser.user)
  const currentTopic = useAppSelector((state) => state.ForumReducer.currentTopic)
  const currentCategory = useAppSelector((state) => state.ForumReducer.currentCategory)
  const currentSubCategory = useAppSelector((state) => state.ForumReducer.currentSubCategory)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [readOnly, setReadOnly] = useState(true)
  const [isEditClicked, setIsEditClicked] = useState(true)

  useEffect(() => {
    if (params.categoryId && params.subcategoryId && params.topicId) {
      dispatch(asyncGetTopicById(params.topicId))
      dispatch(asyncGetCategoryById(params.categoryId))
      dispatch(asyncGetSubCategoryById(params.subcategoryId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.topicId])

  return (
    <StyledForumsWrapper>
      <TopicPageHeader
        navigate={navigate}
        currentCategory={currentCategory}
        currentSubCategory={currentSubCategory}
        currentTopic={currentTopic}
        setIsEditClicked={setIsEditClicked}
        user={user}
      />
      <StyledTopicsWrapper>
        <TopicDescription currentUser={user} currentTopic={currentTopic} readOnly={readOnly} isEditClicked={isEditClicked} setIsEditClicked={setIsEditClicked} />
        {currentTopic?.messages?.length
          ? currentTopic?.messages?.map((message) => <TopicMessage message={message} currentTopic={currentTopic} readOnly={true} key={message.id} />)?.reverse()
          : ''}
        <TopicMessageField currentTopic={currentTopic} user={user} />
      </StyledTopicsWrapper>
    </StyledForumsWrapper>
  )
}

export default TopicPage
