const fileTypes = ["JPG", "PNG", 'JPEG', 'TXT', 'JSON', 'XML'];
const maxSize = 10;

export const getFileSizeMB = (size: number): number => {
  return size / 1000 / 1000;
};
  
export const checkType = (file: File, types: Array<string>): boolean => {
  const extension: string = file.name.split('.').pop() as string;
  const loweredTypes = types.map((type) => type.toLowerCase());
  return loweredTypes.includes(extension.toLowerCase());
};

export const validateFile = (file: File) => {
  if (!checkType(file, fileTypes)) {
    return false;
  }  
  if (getFileSizeMB(file.size) > maxSize) {
    return false;
  }  
  return true;
};