import React, { FC, useState } from 'react'
import styled from 'styled-components';
import RenameBatchForm from './RenameBatchForm';
import { IUserNavDropdownProps } from '../../../../../../../../models/IUserNavDropdownProps';
import { Colors } from '../../../../../../../../models/Colors';
import { modalWindowSlice } from '../../../../../../../../store/reducers/modalWindowReducer';
import { useAppDispatch } from '../../../../../../../../hooks/redux';
import ProjectButton from '../../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import FlexContainer from '../../../../../../../../components/FlexContainer/FlexContainer';
import DropdownButton from '../../../../../../../../UI/Buttons/DropdownButton/DropdownButton';
import burgerIcon from '../../../../../../../../assets/icons/hamburger-menu-svgrepo-com.svg'
import { asyncDeleteBatch } from '../../../../../../../../store/reducers/AcyncActions';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../../../services/ProjectService';

const StyledBurgerIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${burgerIcon});
  mask-image: url(${burgerIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

interface IBatchDropdownProps {
  projectInfo: IProject;
  batchInfo: string;
}

const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  .username {
    height: 30px;
    padding: 5px;
  }
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${props => props.visible ? 'block' : 'none'};
  width: 160px;
  height: auto;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  position: absolute;
  top: 60%;
  right: -20%;
  z-index: 1999;
`
const StyledSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width:30px;
  height: 30px;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  .count {
    position: absolute;
    top: -6px;
    right: 4px;
  }
`

const UnassignedBatchDropdown: FC<IBatchDropdownProps> = ({projectInfo, batchInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)
  

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }
  
  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }

  const onButtonDeleteClick = () => {
    setModalVisivble()
    dispatch(asyncDeleteBatch(batchInfo, projectInfo.id))
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.annotate.forms.renameTItle')))
    dispatch(modalWindowSlice.actions.setChildren(<RenameBatchForm projectInfo={projectInfo} batchInfo={batchInfo}></RenameBatchForm>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.annotate.forms.deleteBatch')))
    dispatch(modalWindowSlice.actions.setChildren(
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onButtonDeleteClick}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
    ))
    dispatch(modalWindowSlice.actions.setVisible())
  }
  
  return (
    <StyledDropDown {...props}>
        <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
          <DropdownButton onClick={onRenameClick} heightProps={'30px'}>{t('other.rename')}</DropdownButton>
          <DropdownButton onClick={onDeleteClick} colorProps='red' heightProps={'30px'}>{t('other.delete')}</DropdownButton>
        </StyledDropdownContent>
        <FlexContainer paddingProps='0' direction='row' justify='center' align='center' onClick={onDropdownClick}>
          <StyledSettings>
            <StyledBurgerIcon {...props} onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}/>
          </StyledSettings>
        </FlexContainer>
    </StyledDropDown> 
  )
}

export default UnassignedBatchDropdown