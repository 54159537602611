import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGenerateLabel } from '../../../../services/VersionsService';
import i18n from '../../../../i18n';

export interface IResizeOption {
    method: string;
    selected: boolean;
    displayName?: string;
    params: {
        fit: string;
        width: number;
        height: number;
    }
}
export interface IStaticCropOption {
    method: string;
    selected: boolean;
    displayName?: string;
    params: {
        left: number;
        right: number;
        top: number;
        bottom: number;
    }
}
export interface ITileOption {
    method: string;
    selected: boolean;
    displayName?: string;
    params: {
        rows: number;
        columns: number;
    }
}
export interface IAutoContrastOption {
    method: string;
    selected: boolean;
    displayName?: string;
}

export interface ISimpleOption {
    method: string;
    displayName: string;
    selected: boolean;
    percentage?: 0,
    params?: any;
}

export interface IFilterNullOption {
    method: string;
    selected: boolean;
    displayName?: string;
    percentage: number;
    params: {
        percents: number;
    }
}

export interface IPercentsOption {
    method: string;
    selected: boolean;
    displayName?: string;
    percentage: number;
    params: {
        percents: number;
    }
}

export interface IBlurOption {
    method: string;
    selected: boolean;
    percentage: number;
    displayName?: string;
    params: {
        maxBlur: number;
    }
}

export interface INoiseOption {
    method: string;
    selected: boolean;
    displayName?: string;
    percentage: number;
    params: {
        maxNoisePercentage: number;
    }
}

export interface IBrightness {
    method: string,
    selected: boolean,
    displayName?: string;
    percentage: number, //Процент исходных изображений к которому будет прим. аугментация
    params: {
      brightnessPercentage: number, // Коф. осветления/затемния в процентах
      mode: string, // lighten,darken,both - Оба
    }
}

export interface IGrayscaleAugOption {
    method: string;
    selected: boolean;
    displayName?: string;
    percentage: number;
}


interface IReducerState {
    isLoading: boolean;
    error: string | null;

    resizeOption: IResizeOption;
    tileOption: ITileOption;
    autoContrastOption: IAutoContrastOption;
    simpleOptions: ISimpleOption[];

    showButton: boolean;
    showAugmentationButton: boolean;
    currentOption: IResizeOption | IStaticCropOption | ITileOption | ISimpleOption;

    //augmentation
    grayscaleOption: IGrayscaleAugOption;
    blurOption: IBlurOption;
    noiseOption: INoiseOption;
    brightness: IBrightness;
    datasetLabels: string[];
    generateDatasetLabels: IGenerateLabel[];
    datasetApplyRename: boolean;
    addOrChangeTag: IGenerateLabel[];
}

const initialState: IReducerState = {
    isLoading: false,
    error: '',

    resizeOption: {
        method: 'resize',
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.resizeOption'),
        params: {
            fit: 'fill',
            width: 0,
            height: 0
        }
    },

    tileOption: {
        method: 'tile',
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.tileOption'),
        params: {
            columns: 0,
            rows: 0
        }
    },

    autoContrastOption: { //Без параметров на бэке
        method: 'negate',
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.autoContrastOption'),
    },

    simpleOptions: [
        {method: 'isolate', selected: false, displayName: i18n.t('authorized.project.generateAndVersions.formOptions.isolateOption')}, //Без параметров на бэке
        {method: 'grayscale', selected: false, displayName: i18n.t('authorized.project.generateAndVersions.formOptions.grayscaleOption')}, //Без параметров на бэке
    ],

    showButton: true,
    showAugmentationButton: true,
    currentOption: {} as ISimpleOption,

    grayscaleOption: {
        method: 'Grayscale Aug...', 
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.grayscaleOption'),
        percentage: 0,
    },

    blurOption: {
        method: 'blur',
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.blurOption'),
        percentage: 0,
        params: {
            maxBlur: 0
        }
    },
    noiseOption: {
        method: 'noise',
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.noiseOption'),
        percentage: 0,
        params: {
            maxNoisePercentage: 0
        }
    },

    brightness: {
        method: "brightness",
        selected: false,
        displayName: i18n.t('authorized.project.generateAndVersions.formOptions.brightness'),
        percentage: 0,
        params: {
          brightnessPercentage: 0,
          mode: 'both', // lighten, darken, both - Оба
        }
    },

    datasetLabels: [],
    generateDatasetLabels: [],
    addOrChangeTag: [],
    datasetApplyRename: false
}



export const generateReducer = createSlice(
{
    name: 'generate',
    initialState,

    reducers: {
        setInitialState(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.resizeOption = initialState.resizeOption;
            state.tileOption = initialState.tileOption;
            state.autoContrastOption = initialState.autoContrastOption;
            state.simpleOptions = initialState.simpleOptions;
            state.showButton = initialState.showButton;
            state.showAugmentationButton = initialState.showAugmentationButton;
            state.currentOption = initialState.currentOption;
            state.grayscaleOption = initialState.grayscaleOption;
            state.blurOption = initialState.blurOption;
            state.noiseOption = initialState.noiseOption;
            state.brightness = initialState.brightness;
            state.datasetLabels = initialState.datasetLabels;
            state.generateDatasetLabels = initialState.generateDatasetLabels;
        },
        setLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setErrorState(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        setResizeOptionState(state, action: PayloadAction<IResizeOption>) {
            state.resizeOption.selected = action.payload.selected
            state.resizeOption.params = action.payload.params
        },
        setTileOptionState(state, action: PayloadAction<ITileOption>) {
            state.tileOption.selected = action.payload.selected
            state.tileOption.params = action.payload.params
        },
        setAutoContrastOptionState(state, action: PayloadAction<IAutoContrastOption>) {
            state.autoContrastOption.selected = action.payload.selected
        },
        setSimpleOptionsState(state, action: PayloadAction<ISimpleOption>) {
            const filtededSimpleOptions = state.simpleOptions.filter(option => option.method !== action.payload.method)
            filtededSimpleOptions.push(action.payload)
            state.simpleOptions = filtededSimpleOptions
        },
        setCurrentOptionState(state, action: PayloadAction<IResizeOption | IStaticCropOption | ITileOption | ISimpleOption>) {
            state.currentOption.selected = action.payload.selected
            state.currentOption.params = action.payload.params
        },
        setShowButton(state) {
            const firstSimple = state.simpleOptions[0]
            const secondSimple = state.simpleOptions[1]
            state.showButton = true
            if (firstSimple.selected && secondSimple.selected && state.resizeOption.selected &&
            state.tileOption.selected && state.autoContrastOption.selected) {
                state.showButton = false    
            }
        },


        setGrayscaleOptionState(state, action: PayloadAction<IGrayscaleAugOption>) {
            state.grayscaleOption.selected = action.payload.selected
            state.grayscaleOption.percentage = action.payload.percentage
        },
        setNoiseOptionState(state, action: PayloadAction<INoiseOption>) {
            state.noiseOption.selected = action.payload.selected
            state.noiseOption.params = action.payload.params
            state.noiseOption.percentage = action.payload.percentage
        },
        setBlurOptionState(state, action: PayloadAction<IBlurOption>) {
            state.blurOption.selected = action.payload.selected
            state.blurOption.params = action.payload.params
            state.blurOption.percentage = action.payload.percentage
        },
        setBrightnessOptionState(state, action: PayloadAction<IBrightness>) {
            state.brightness.selected = action.payload.selected
            state.brightness.params = action.payload.params
            state.brightness.percentage = action.payload.percentage
        },
        setShowAugmentButton(state) {
            state.showAugmentationButton = true
            if (state.grayscaleOption.selected && state.noiseOption.selected && state.blurOption.selected && state.brightness.selected) {
                state.showAugmentationButton = false
            }
        },

        setDatasetLabels(state, action: PayloadAction<string[]>) {
            if (action.payload?.length) {
                state.datasetLabels = action.payload
                state.generateDatasetLabels = []
                action.payload.forEach(label => {
                    state.generateDatasetLabels.push({oldName: label, newName: label})
                })
            }
        },
        renameGenerateLabel(state, action: PayloadAction<IGenerateLabel>) {
            const findedGenerateLabel = state.generateDatasetLabels.find(label => label.oldName === action.payload.oldName)
            if (findedGenerateLabel) {
                findedGenerateLabel.newName = action.payload.newName
            }
        },
        rejectRenameLabels(state) {
            state.generateDatasetLabels = []
            state.datasetLabels.forEach(label => {
                state.generateDatasetLabels.push({oldName: label, newName: label})
            })
        },
        setRenamedGenerateLabels(state, action: PayloadAction<IGenerateLabel[]>) {
            state.generateDatasetLabels = action.payload
            state.generateDatasetLabels.forEach(label => {
                if (!label.newName) {
                    label.newName = label.oldName
                }
            })
        },
        setDatasetApplyRename(state, action: PayloadAction<boolean>) {
            state.datasetApplyRename = action.payload
        },

        addOrChangeTag(state, action: PayloadAction<IGenerateLabel>) {
            const findedTag = state.addOrChangeTag.find(tag => tag.oldName === action.payload.oldName)
            if (!findedTag) {
                state.addOrChangeTag.push(action.payload)
            }
            if (findedTag) {
                findedTag.newName = action.payload.newName
            }
        }
    }
},

)

export default generateReducer.reducer;