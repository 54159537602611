import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAppDispatch } from '../../../hooks/redux'
import { INewImage } from '../../../services/BatchesService'
import { EImagesPage, IImagesListReducerState, imagesListSlice } from '../store/ImagesListReducer'
import {
  StyledDescription,
  StyledImageName,
  StyledImageSkeleton,
  StyledImg,
  StyledImgWrapper,
  StyledSelectWrapper,
  StyledSelectedIcon,
  StyledWideIcon,
  StyledWideWrapper
} from '../constants/styled'
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'

interface IListImageProps {
  page: IImagesListReducerState['currentPageName']
  image: INewImage
  selectedImages: INewImage[]
  onImageClick?: any
  userRoleNumber: number;
}

const ListImage: React.FC<IListImageProps> = ({ page, image, selectedImages, onImageClick, userRoleNumber, ...props }) => {
  const dispatch = useAppDispatch()
  const { ref, inView } = useInView({
    threshold: 0
  })

  console.log('update')

  const [selected, setSelected] = useState(false)

  useEffect(() => {
    if (image?.id) {
      const findedImage = selectedImages?.find((sImage) => sImage.id === image.id)
      if (findedImage) {
        setSelected(true)
      } else {
        setSelected(false)
      }
    }
  }, [image, selectedImages])

  const onSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (selected) {
      setSelected(false)
      dispatch(imagesListSlice.actions.deselectImage(image))
    }
    if (!selected) {
      setSelected(true)
      dispatch(imagesListSlice.actions.selectImage(image))
    }
  }

  const onWideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.disableTitle())
    dispatch(modalWindowSlice.actions.setModalWidth('550px'))
    dispatch(modalWindowSlice.actions.setModalHeight('560px'))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="row" justify="center" align="center" widthProps="500px" heightProps="100%">
          <img src={image.imageUrl} alt="" style={{ maxWidth: '500px', maxHeight: '450px'}} />
        </FlexContainer>
      )
    )
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (onImageClick) {
      onImageClick(image, 'images')
      return
    }
    onWideClick(e)
  }

  const getRenderCondition = (userRoleNumber: number, condition?: string) => {
    if (condition === "versionPage") {
      return false
    }
    if (userRoleNumber === 6 || userRoleNumber === 5) {
      return false
    }
    return true
  }

  return (
    <StyledDescription onClick={onClick} ref={ref}>
      <StyledImgWrapper selected={selected && inView}>
        {inView ? <StyledImg src={image.thumbnailUrl + "?" + new Date().getTime()} alt={image.displayName} /> : <StyledImageSkeleton />}
        <StyledImageName>{inView ? image?.displayName : ' '}</StyledImageName>

        <StyledWideWrapper onClick={onWideClick}>
          <StyledWideIcon />
        </StyledWideWrapper>

        {getRenderCondition(userRoleNumber, page)
          ? <StyledSelectWrapper selected={selected && inView} onClick={onSelectClick}>
              <StyledSelectedIcon />
            </StyledSelectWrapper>
          : ''
        }
      </StyledImgWrapper>
    </StyledDescription>
  )
}

export default ListImage
