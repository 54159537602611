import React, { FC, ReactChild, ReactNode } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer';
import { StyledSplitCard, StyledSplitCardText, StyledStplitCardPercents } from '../../../../constants/styled';

interface ISplitCardProps {
    percent: number;
    children: ReactChild | ReactNode;
    percentColor: string;
}

const SplitCard: FC<ISplitCardProps> = ({percentColor, children, percent, ...props}) => {
  return (
    <StyledSplitCard>
        <FlexContainer direction='column' justify='center' align='center' heightProps='100%' gap='10px'>
            <StyledSplitCardText>{children}</StyledSplitCardText>
            <StyledStplitCardPercents percentColor={percentColor}>{percent}%</StyledStplitCardPercents>
        </FlexContainer>
    </StyledSplitCard>

  )
}

export default SplitCard