import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode, ReactChild } from 'react';

interface IDrawLoader {
  title: ReactNode | ReactChild;
  children: ReactNode | ReactChild;
  visible: boolean;
  titleColor: string;
  modalWidth: string;
}

const initialState: IDrawLoader = {
    title: '',
    titleColor: '',
    children: '',
    visible: false,
    modalWidth: '380px',
}

export const drawLoaderSlice = createSlice(
{
    name: 'drawLoaderSlice',
    initialState,

    reducers: {
      setChildren(state, action: PayloadAction<ReactNode | ReactChild>) {
        state.children = action.payload
      },
      setVisible(state) {
        state.visible = !state.visible
      },
      setVisibleFalse(state) {
        state.visible = false
      },
      setVisibleTrue(state) {
        state.visible = true
      },
      setTitle(state, action: PayloadAction<ReactNode | ReactChild>) {
        state.title = action.payload
      },
      setTitleColor(state, action: PayloadAction<string>) {
        state.titleColor = action.payload
      },
      setInititialState(state) {
        state.visible = false;
        state.children = '';
        state.title = '';
        state.modalWidth = initialState.modalWidth;
      },
      setModalWidth(state, action: PayloadAction<string>) {
        state.modalWidth = action.payload
      }
    }
},

)

export default drawLoaderSlice.reducer;