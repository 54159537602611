import React, { FC, useEffect, useState } from 'react'
import { StyledAccordionImagesWrapper, StyledArchivesTitle, StyledArchivesWrapper, StyledColoredSpan, 
         StyledColumnHeaderTitle, StyledVersionRightColumnHeader } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import VersionDropDown from './VersionDropDown'
import { NavigateFunction, Params } from 'react-router-dom'
import { Colors } from '../../../../../models/Colors'
import VersionSplitCard from './VersionSplitCard'
import styled from 'styled-components'
import VersionProcessingInfo from './VersionProcessingInfo'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import ExportVersionForm from './ExportVersionForm'
import { IFullVersionWithArchive } from '../../../store/reducers/VersionsReducer'
import VersionArchive from './VersionArchive'
import RenameProcessingInfo from './RenameProcessingInfo'
import VersionLabels from './VersionLabels'
import { useTranslation } from 'react-i18next'
import { ImagesList } from '../../../../ImagesList'
import { EImagesPage } from '../../../../ImagesList/store/ImagesListReducer'
import { IProject } from '../../../../../services/ProjectService'

export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.darkerBlack};
`

interface IVersionInfoProps {
    projectInfo: IProject;
    navigate: NavigateFunction;
    currentVersion: IFullVersionWithArchive;
    params: Readonly<Params<string>>;
}

interface IPercents {
  train: string | number;
  valid: string | number;
  test: string | number;
}

const VersionInfo: FC<IVersionInfoProps> = ({projectInfo, currentVersion, navigate, params, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [versionsImages, setVersionImages] = useState<any>([])
  const [percents, setPercents] = useState<IPercents>({
    train: '',
    valid: '',
    test: ''
  })
  
  const { selectedImages, images, imagesTotal } = useAppSelector((state) => state.ImagesListReducer)

  useEffect(() => {
    if (currentVersion?.id) {
      for(let i = 0; i<5; i++) {
        setVersionImages([...versionsImages, images[i]])
      }
    }
    setPercents({
      train: Math.round((currentVersion.trainTotal/imagesTotal) * 100),
      valid: Math.round((currentVersion.validTotal/imagesTotal) * 100),
      test: Math.round((currentVersion.testTotal/imagesTotal) * 100)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVersion]) 

  const onExportClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.exportVersion')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<ExportVersionForm projectInfo={projectInfo} currentVersion={currentVersion} navigate={navigate} params={params}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
  <div>
    <StyledVersionRightColumnHeader>
      <StyledColumnHeaderTitle>V{currentVersion.versionNumber} {currentVersion.name}</StyledColumnHeaderTitle>
      <FlexContainer direction='row' widthProps='100' gap='15px' align='center'>
        <ProjectButton FontSizeProps='14px' paddingProps='8px' heightProps='30px' widthProps='140px' onClick={onExportClick}>{t('authorized.project.generateAndVersions.exportVersion')}</ProjectButton>
        <VersionDropDown projectInfo={projectInfo} versionInfo={currentVersion} />
      </FlexContainer>
    </StyledVersionRightColumnHeader>
    <StyledBr/>
      {currentVersion?.archive?.length ?
        <StyledArchivesWrapper>
          <StyledArchivesTitle>{t('authorized.project.generateAndVersions.versionArchives')}:</StyledArchivesTitle>
          {currentVersion?.archive?.map(archive => <VersionArchive key={archive.id} archive={archive}/>)}
        </StyledArchivesWrapper>
      : ''}
    <StyledBr/>
    <FlexContainer direction='column' justify='center' align='flex-start' paddingProps='15px'>
      <h6><StyledColoredSpan>{imagesTotal}</StyledColoredSpan> images</h6>
      <StyledAccordionImagesWrapper>
        <ImagesList page={EImagesPage.versionPage} heightProps='400px'/>
      </StyledAccordionImagesWrapper>
    </FlexContainer>
    <StyledBr/>
    <FlexContainer direction='column' justify='center' align='flex-start' paddingProps='15px'>
      <h6>{t('authorized.project.generateAndVersions.rebalanceText')}</h6>
      <FlexContainer direction='row' justify='space-around' paddingProps='10px' gap='20px' margin='10px 0 0 0'>
        <VersionSplitCard percent={+percents?.train} percentColor='#8f2ceb' imagesCount={currentVersion?.trainTotal}>Training Set</VersionSplitCard>
        <VersionSplitCard percent={+percents?.valid} percentColor='#68CF6C' imagesCount={currentVersion?.validTotal}>Validation Set</VersionSplitCard>
        <VersionSplitCard percent={+percents?.test}  percentColor='#07d6cd' imagesCount={currentVersion?.testTotal}>Testing Set</VersionSplitCard>
      </FlexContainer>
    </FlexContainer>
    <StyledBr/>
    <VersionProcessingInfo type={'PREPROCESSING'} currentVersion={currentVersion}/>
    <StyledBr/>
    <VersionProcessingInfo type={'AUGMENTATION'} currentVersion={currentVersion}/>
    <StyledBr/>
    <VersionLabels/>
    <StyledBr/>
    <RenameProcessingInfo type={'LABELS'} currentVersion={currentVersion}/>
    <StyledBr/>
  </div>
  )
}

export default VersionInfo