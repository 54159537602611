import React, { FC, useEffect, useState } from 'react'
import { IRequireAuth } from './../../../models/IRequireAuth';
import { useAppDispatch } from './../../../hooks/redux';
import { userSlice } from '../../../store/reducers/userReducer';
import UserService from '../../../services/UserService';
import { useParams } from 'react-router-dom';
import { IProjectUserFullInfo, WorkspacesSlice } from '../../../modules/Projects/store/reducers/WorkspacesReducer';
import ProjectService from '../../../services/ProjectService';
import AccessService from '../../../services/AccessService';
import { tabsSlice } from '../../../modules/ProjectModule/store/reducers/TabsReducer';
import { asyncGetSettings, asyncGetSubsctiptions } from '../../../store/reducers/AcyncActions';

const RequireAuth: FC<IRequireAuth> = ({children, navigate, accessLevel = 7}) => {
  const params = useParams()
  const dispatch = useAppDispatch();
  const [roleNumber, setRoleNumber] = useState(7)

  const routerAccessGuard = async () => {
    try {
      //Check Auth
      dispatch(userSlice.actions.userAuth())
      const userInfoRes = await UserService.getUserInfo()
      dispatch(userSlice.actions.getUserInfoSuccess(userInfoRes.data.user))
      console.log('accessGuard userInfo', userInfoRes)
      
      dispatch(asyncGetSettings())
      
      dispatch(asyncGetSubsctiptions())

      if (params.id) {
        //Get Project users and roles
        dispatch(tabsSlice.actions.setUserRoleNumber(7))
        dispatch(WorkspacesSlice.actions.setLoadingStart)
        dispatch(WorkspacesSlice.actions.setIsRoleLoading(true))
        const responce = await ProjectService.getOneProjectById(params.id)
        dispatch(WorkspacesSlice.actions.setProjectInfo(responce.data.project))
    
        const usersInfo: IProjectUserFullInfo[] = []
        for (const member of responce.data.members) {
          const userRoleInfo = await AccessService.getRoleById(member.roleId)
          const userInfo = await UserService.getUserById(member.user)
          usersInfo.push({projectRole: userRoleInfo.data.role, user: userInfo.data.user})
        }
    
        //Guard
        dispatch(WorkspacesSlice.actions.setFullProjectUsersInfo(usersInfo))
        const findedUser = usersInfo.find(user => user.user.id === userInfoRes.data.user.id)
        if (userInfoRes.data.user.role.name === 'global_admin' || userInfoRes.data.user.role.name === 'developer') { 
          setRoleNumber(1)
          dispatch(tabsSlice.actions.setUserRoleNumber(1))
        }
        if (findedUser && findedUser.projectRole.name === 'group_owner') {
          setRoleNumber(2)
          dispatch(tabsSlice.actions.setUserRoleNumber(2))
        }
        if (findedUser && findedUser.projectRole.name === 'group_admin') {
          setRoleNumber(3)
          dispatch(tabsSlice.actions.setUserRoleNumber(3))
        }
        if (findedUser && findedUser.projectRole.name === 'project_owner') {
          setRoleNumber(4)
          dispatch(tabsSlice.actions.setUserRoleNumber(4))
        }
        if (findedUser && findedUser.projectRole.name === 'project_admin') {
          setRoleNumber(4)
          dispatch(tabsSlice.actions.setUserRoleNumber(4))
        }
        if (findedUser && findedUser.projectRole.name === 'project_moderator') {
          setRoleNumber(5)
          dispatch(tabsSlice.actions.setUserRoleNumber(5))
        }
        if (findedUser && findedUser.projectRole.name === 'project_annotator') {
          setRoleNumber(6)
          dispatch(tabsSlice.actions.setUserRoleNumber(6))
        }
        if (findedUser && findedUser.projectRole.name === 'project_user') {
          setRoleNumber(7)
          dispatch(tabsSlice.actions.setUserRoleNumber(7))
        }
        dispatch(WorkspacesSlice.actions.setLoadingEnd())
        dispatch(WorkspacesSlice.actions.setIsRoleLoading(false))
      }
    } catch (error: any) {
      dispatch(WorkspacesSlice.actions.setErrorState('Error on get Router getProjectUsersInfo'))
      if (error?.response?.status === 401) {
        navigate('/signin')
      }
      console.log('Router getProjectUsersInfo', error)
    }
  }

 
  useEffect(() => {
    routerAccessGuard()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params.id])

  return (
    <>
      {roleNumber <= accessLevel ? children : 'Access Denied'}
    </>
  )
}

export default RequireAuth