import React from 'react'
import { useAppSelector } from '../../../../../hooks/redux'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { StyledLoadingText, StyledModalWindowTitle } from '../../../constants/styled'
import Loader from '../../../../../UI/Loader/Loader'
import { useTranslation } from 'react-i18next'

const ExportVersionPercentageForm = () => {
  const { t } = useTranslation()
  // const dispatch = useAppDispatch()
  const progress = useAppSelector(state => state.versionsReducer.progress)
  // const job = useAppSelector(state => state.versionsReducer.job)

  // const onCancelClick = async () => {
  //   if (job) {
  //     try {
  //       const stopResponce = await JobsService.stopJob(job)
  //       console.log('stopResponce', stopResponce)
  //       const deleteResponce = await JobsService.deleteJob(job)
  //       console.log('deleteResponce', deleteResponce)
  //       dispatch(modalWindowSlice.actions.setInititialState())
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }
  
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='30px' paddingProps='10px'>
        <Loader/>
        <StyledLoadingText>
            {progress}%
        </StyledLoadingText>
        <StyledModalWindowTitle>
            {t('authorized.project.generateAndVersions.generateLoadText1')}
        </StyledModalWindowTitle>
        {/* <ProjectButton onClick={onCancelClick}>Cancel</ProjectButton> */}
    </FlexContainer>
  )
}

export default ExportVersionPercentageForm