import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import inputSearchIcon from '../../../assets/icons/search-loupe-input.svg'
import greaterThanIcon from '../../../assets/icons/drawIcons/greater-than-svgrepo-com.svg'
import lessThanIcon from '../../../assets/icons/drawIcons/less-than-svgrepo-com.svg'
import wideIcon from '../../../assets/icons/wide.svg'
import selectIcon from '../../../assets/icons/select.svg'

// other
export const StyledInputSearchIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${inputSearchIcon});
  mask-image: url(${inputSearchIcon});
  background-color: ${Colors.orange};
  width: 15px;
  height: 15px;
`

interface IListWrapperProps {
  heightProps: string
}

export const ImageListWrapper = styled.div<IListWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  gap: 5px;

  width: 100%;
  height: ${(props) => props.heightProps};
  padding: 10px 15px 0 15px;
`

//ListSearch
export const StyledListSearch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`

export const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
`

export const StyledSearchFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
`

export const StyledFilterText = styled.div`
  font-size: 13px;
`

export const StyledSearchOprionsWrapper = styled.div``

//Footer slider
export const StyledListFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  width: 100%;

  position: relative;
`

export const StyledListSliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px;
  min-width: 180px;
  max-width: 280px;
  height: 40px;

  border: 1px solid ${Colors.gray};
  border-radius: 15px;

  /* position: absolute;
  left: 50%;
  margin-left: -80px; */

`

export const StyledEmptyDiv = styled.div`
  max-width: 180px;
  min-width: 1px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const StyledSliderArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
  border: 1px solid ${Colors.dark};
  border-radius: 50px;

  transition: all 0.2s linear;
  cursor: pointer;

  :hover {
    border: 1px solid ${Colors.orange};
    background-color: ${Colors.black};
  }
`

interface IGreaterAndLessThanProps {
  widthProps?: string
  heightProps?: string
  selected?: boolean
}
export const StyledGreaterThanIcon = styled.i<IGreaterAndLessThanProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${greaterThanIcon});
  mask-image: url(${greaterThanIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '25px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '25px')};
`
export const StyledLessThanIcon = styled.i<IGreaterAndLessThanProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${lessThanIcon});
  mask-image: url(${lessThanIcon});
  background-color: ${Colors.orange};
  width: ${(props) => (props.widthProps ? props.widthProps : '25px')};
  height: ${(props) => (props.heightProps ? props.heightProps : '25px')};
`

//ImagesList
export const StyledImagesList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;

  width: 100%;

  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledWideIcon = styled.i<IGreaterAndLessThanProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${wideIcon});
  mask-image: url(${wideIcon});
  background-color: ${Colors.WHITE};
  padding: 2px;
  width: 15px;
  height: 15px;

  cursor: pointer;
  :hover {
    background-color: ${Colors.orange};
  }
`
export const StyledSelectedIcon = styled.i<IGreaterAndLessThanProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${selectIcon});
  mask-image: url(${selectIcon});
  background-color: ${Colors.WHITE};
  padding: 2px;
  width: 15px;
  height: 15px;

  cursor: pointer;
  :hover {
    background-color: ${Colors.orange};
  }
`

export const StyledWideWrapper = styled.div`
  width: 20px;
  height: 20px;
  transition: visibility 0.1s linear;
  visibility: hidden;
  position: absolute;
  z-index: 2;
  top: 3px;
  right: 3px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  :hover {
    border: ${'1px solid ' + Colors.orange};
  }
  :hover ${StyledWideIcon} {
    background-color: ${Colors.orange};
  }
`

export const StyledSelectWrapper = styled.div<IGreaterAndLessThanProps>`
  width: 20px;
  height: 20px;
  transition: visibility 0.1s linear;
  visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  position: absolute;
  z-index: 2;
  top: 3px;
  left: 3px;

  border: ${({ selected }) => (selected ? '1px solid ' + Colors.orange : 'none')};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  cursor: pointer;

  :hover ${StyledSelectedIcon} {
    background-color: ${Colors.orange};
  }
`

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  z-index: 1;
  margin: 10px 0;

  :hover ${StyledWideWrapper} {
    visibility: visible;
  }
  :hover ${StyledSelectWrapper} {
    visibility: visible;
  }
`

export const StyledImgWrapper = styled.div<IGreaterAndLessThanProps>`
  width: 110px;
  height: 125px;
  background-color: ${({ selected }) => (selected ? '#fcb94649' : '#4a4a4a4f')};

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: ${({ selected }) => (selected ? '2px solid #FFA000' : '2px solid #494949')};
  border-radius: 5px;
  transition: all 0.1s linear;
  cursor: pointer;
  :hover {
    border: 2px solid ${Colors.orange};
    background-color: #fcb94649;
  }

  :hover ${StyledWideWrapper} {
    visibility: visible;
  }
`

export const StyledImg = styled.img`
  max-width: 80px;
  max-height: 80px;
`

export const StyledImageName = styled.h6`
  width: 100px;
  text-align: center;
  font-size: 10px;
  color: ${Colors.lightgray};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const StyledImageSkeleton = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-color: #525252;
`

export const StyledSelectedImg = styled.img<IGreaterAndLessThanProps>`
  width: 20px;
  height: 20px;
  transition: visibility 0.1s linear;
  visibility: ${({ selected }) => (selected ? 'visible' : 'hidden')};
  position: absolute;
  top: 3px;
  left: 3px;
`
