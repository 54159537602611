import { useMemo } from "react";

import { IAutoMarkupClassWithModel } from "../services/AutoMarkupService";

export const UseAutoMarkupClassesQuery = (mClasses:IAutoMarkupClassWithModel[], query:string) => {
  const searchedClasses = useMemo(() => {
      if (!mClasses?.length) {
        return null
      }
      if (mClasses?.length) {
        return mClasses?.filter(mClass => ( 
            mClass?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
            mClass?.displayName?.toLowerCase()?.includes(query?.toLowerCase())
        ))
      }
  }, [query, mClasses])
  
  if (query) {
    return searchedClasses
  }
  if (!query) {
    return mClasses
  }
}