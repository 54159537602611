import React from 'react'
import { ICardQuotaProps } from '../../constants/interfaces'
import Tooltip from '../../../../UI/Tooltip/Tooltip'
import { StyledCardQuota } from '../../constants/styled'

const LicenseCardQuota: React.FC<ICardQuotaProps> = ({quota, leftTooltip, ...props}) => {
  return (
    <StyledCardQuota>
        <div>{quota.limit} {quota.displayName}</div>
        <Tooltip leftTooltip={leftTooltip}>{quota.description}</Tooltip>
    </StyledCardQuota>
  )
}

export default LicenseCardQuota