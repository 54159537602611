import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import AutoMarkupSlideForm from '../../AutoMarkupSlideForm'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { StyledClassesWrapper, StyledRightTryColumn, StyledSlideP, StyledTextColumnsWrapper, 
         StyledTextLeftTryColumn, StyledTextModelsSlideWrapper, StyledTextSlideModelsWrapper,
         StyledVerticalBr } from '../../../../Styled/styled'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { StyledBr } from '../../../DrawLoader/DrawLoader'
import Input from '../../../../../../UI/Input/Input'
import Model from '../../Model'
import dinoImage from '../../../../../../assets/images/dino.jpg'
import AutoMarkupTag from './AutoMarkupTag'
import { asyncGetModelsSummary } from '../../../../../../store/reducers/AcyncActions'
import { useTranslation } from 'react-i18next'

const TextModelsSlide = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { modelsSummary } = useAppSelector(state => state.AutoMarkupReducer)
  const [inputState, setInputState] = useState('')
  const [tags, setTags] = useState<string[]>([])
  const [tagsBoundary, setTagsBoundary] = useState('')
  const [filteredModels, setFilteredModels] = useState<any[]>([])

  const onAddTagClick = () => {
    if (tags?.length >= 100) {
      setTagsBoundary(t('authorized.project.draw.automarkupTool.tagsBoundary'))
      return
    }
    if (inputState && tags?.length < 100) {
      setTags([...tags, inputState])
      setInputState('')
      if (tagsBoundary) {
        setTagsBoundary('')
      }
    }
  }

  const onDeleteTagClick = (addedTag: string) => {
    const filteredTags = tags.filter(tag => tag !== addedTag)
    setTags(filteredTags)
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('580px'))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
    dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  useEffect(() => {
    const filteredModels = modelsSummary?.models?.filter(model => model.type === 'text')
    if (filteredModels?.length) {
      setFilteredModels(filteredModels)
    }
  }, [modelsSummary])

  useEffect(() => {
    dispatch(asyncGetModelsSummary())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        onAddTagClick()
      }
    }
    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState])

  // FOR DEV
  // const modelInfo = {
  //   id: '123',
  //   name: '123',
  //   displayName: '123',
  //   createdAt: '123',
  //   updatedAt: '123',
  //   usedOnSingleImage: true,
  //   usedOnMultipleImages: true,
  //   Classes: []
  // }

  return (
    <StyledTextModelsSlideWrapper>
      <StyledTextColumnsWrapper>
        <StyledTextLeftTryColumn>
          <FlexContainer direction='row' justify='space-between' align='center' gap='5px'>
            <Input 
              value={inputState} 
              onChange={(e) => setInputState(e.target.value)} 
              widthProps='300px' 
              label={t('authorized.project.draw.automarkupTool.textMarkupTitle')}
              placeholder='cat'
              withTooltip={true}
              tooltipText={t("authorized.project.draw.automarkupTool.textMarkupTooltip")}
            />
            <ProjectButton 
              margin='30px 0 0 0' 
              heightProps='35px' 
              widthProps='100px' 
              FontSizeProps='16px' 
              active={inputState ? true : false}
              onClick={onAddTagClick}
            >
              {t('buttons.add')}
            </ProjectButton>
          </FlexContainer>
          {!tags?.length 
            ? 
              <FlexContainer direction='row' justify='center' align='center' heightProps='500px'>
                <StyledSlideP align='center' widthProps='100%'>{t('authorized.project.draw.automarkupTool.promptEmpty')}</StyledSlideP>
              </FlexContainer> 
            : ''
          }
          <StyledClassesWrapper>
            {tagsBoundary
              ? <FlexContainer direction='row' justify='center' align='center' margin='20px 0'>
                  <StyledSlideP align='center' widthProps='100%'>{tagsBoundary}</StyledSlideP>
                </FlexContainer> 
              : ''
            }
            {tags?.map(tag => <AutoMarkupTag text={tag} onDelete={onDeleteTagClick}/>).reverse()}
          </StyledClassesWrapper>

        </StyledTextLeftTryColumn>

        <StyledVerticalBr/>

        <StyledRightTryColumn>
          <StyledSlideP align='center'>
            {t('authorized.project.draw.automarkupTool.fullText')} <br/>
          </StyledSlideP>
          <StyledBr/>

          <StyledTextSlideModelsWrapper>
            {filteredModels?.map(model => 
              <Model 
                key={model.id} 
                name={model.displayName} 
                imagePath={dinoImage}
                // isTestPassed={true}
                // isTestPassed={false}
                isTestPassed={model.usedOnMultipleImages || model.usedOnSingleImage}
                tagModelInfo={model}
                tags={tags}
              />
            )}
          </StyledTextSlideModelsWrapper>
        </StyledRightTryColumn>
      </StyledTextColumnsWrapper>
      <FlexContainer direction='column' justify='center' align='center'>
        <ProjectButton onClick={onCancelClick} heightProps='30px'>{t('buttons.back')}</ProjectButton>
      </FlexContainer>
    </StyledTextModelsSlideWrapper>
  )
}

export default TextModelsSlide