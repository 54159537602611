import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface IQuota {
    id: number;
    name: string;
    displayName: string;
    description: string;
    quotaDurationType: string;
    createdAt: string;
    updatedAt: string;
    cost: number | string;
}

export interface IGroupQuota {
    id: string;
    name: string;
    displayName: string;
    description: string;
    quotaDurationType: string;
    costPerUnit: number;
    isExtendable: boolean;
    maxLimit: number;
    limit: number;
    used: number;
    quotaMultiplicity: number; //Шаг для увеличения
    baseCostPerUnit: number;   //цена за шаг
    fullCostPerUnit: number;   //цена за шаг с учётом оставшихся месяцев лицензии
}

export interface IGetQuotasRes {
    quotas: IQuota[];
}

export interface IGetGroupQuotasRes {
    groupQuotas: IGroupQuota[]
}

export interface IExpandQuotaReq {
    groupId: string;
    licenseId: string;
    quotaTypeId: string;
    additionalUnits: number;
}

export interface IExpandQuotaRes {
    message: string;
}

export interface ICustomQuota {
    id: string;
    groupId: string;
    quotaTypeId: string;
    limit: number;
    licenseId: string;
    createdAt: string;
    updatedAt: string;
}

export interface IGetCustomQuotasRes {
    customGroupQuotas: ICustomQuota[];
}

export interface IDeleteCustomQuotaRes {
    massage: string;
}

export interface IPackageQuota {
    id: string;
    name: string;
    displayName: string;
    limit: number;
}

export interface IGroupPackage {
    id: string;
    name: string;
    description: string;
    cost: string;
    quotas: IPackageQuota[];
}

export interface IGetGroupPackagesRes {
    quotaPackages: IGroupPackage[];
}

export interface IExpandPackageReq {
    groupId: string;
    licenseId: string;
    quotaPackageId: string;
    autoRenewPackage: boolean;
}

export default class QuotasService {
    //Access Roles
    static async getQuotas(): Promise<AxiosResponse<IGetQuotasRes>> {
        return $authHost.get<IGetQuotasRes>(`/quotas/`)
    }

    static async getGroupQuotas(groupId: string): Promise<AxiosResponse<IGetGroupQuotasRes>> {
        return $authHost.get<IGetGroupQuotasRes>(`/quotas/group/${groupId}`)
    }

    static async getGetCustomQuotas(groupId: string): Promise<AxiosResponse<IGetCustomQuotasRes>> {
        return $authHost.get<IGetCustomQuotasRes>(`/quotas/group-custom/${groupId}`)
    }

    static async expandQuota(payload: IExpandQuotaReq): Promise<AxiosResponse<IExpandQuotaRes>> {
        return $authHost.post<IExpandQuotaRes>(`/quotas/expand`, {...payload})
    } 

    static async deleteCustomQuota(groupId: string): Promise<AxiosResponse<IDeleteCustomQuotaRes>> {
        return $authHost.delete<IDeleteCustomQuotaRes>(`/quotas/group-custom/${groupId}`)
    }

    static async getGroupPackages(groupId: string): Promise<AxiosResponse<IGetGroupPackagesRes>> {
        return $authHost.get<IGetGroupPackagesRes>(`/quotas/packages?groupId=${groupId}`)
    }

    static async expandPackage(payload: IExpandPackageReq): Promise<AxiosResponse<IExpandQuotaRes>> {
        return $authHost.post<IExpandQuotaRes>(`/quotas/packages-expand`, {...payload})
    }     
}