import React, { FC, useEffect, useState } from 'react'
import { StyledTableItem, StyledUsersTable, StyledUsersTableHeader } from '../../../../constants/styled'
import ProjectUserCard from '../ProjectUserCard/ProjectUserCard'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { ProjectBr } from '../../../../../../styles/GlobalStyles'
import { IProjectUserFullInfo } from '../../../../../Projects/store/reducers/WorkspacesReducer'
import { ISummaryRole } from '../../../../../../services/AccessService'
import { Option } from '../../../../../../UI/ProjectSelect/ProjectSelect'
import { useTranslation } from 'react-i18next'
import { IProject } from '../../../../../../services/ProjectService'

interface IUsersTableProps {
  projectInfo: IProject;
  projectRoles: ISummaryRole[];
  roleNumber: number;
  projectUsersInfo: IProjectUserFullInfo[] | null | undefined;
}

const UsersTable: FC<IUsersTableProps> = ({projectInfo, projectRoles, roleNumber, projectUsersInfo, ...props}) => {
  const { t } = useTranslation()
  const [optionsTable, setOptionsTable] = useState<Option[]>([])
  const [users, setUsers] = useState<IProjectUserFullInfo[]>([])
  console.log('projectUsersInfo', projectUsersInfo)

  useEffect(() => {
    if (projectRoles.length) {
      const options: Option[] = []
      projectRoles.forEach(role => {
        options.push({title: role.displayName, value: role.name})
      })
      setOptionsTable(options)
    }

    if (projectUsersInfo?.length) {
      setUsers(projectUsersInfo)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectRoles, projectUsersInfo])

  return (
    <StyledUsersTable>
        <StyledUsersTableHeader>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableUser')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableRole')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableStatus')}</StyledTableItem>
          <StyledTableItem>{t('authorized.projects.groups.groupManagement.tableActions')}</StyledTableItem>
        </StyledUsersTableHeader>
        <ProjectBr margin='0 0 15px 0'/>
        <FlexContainer direction='column' justify='center' align='flex-start' gap="15px">
          {users?.length ? users.map(user => 
            <ProjectUserCard 
              key={user.user.id} 
              userInfo={user.user} 
              userRole={user.projectRole} 
              optionsTable={optionsTable} 
              projectUsersInfo={users}
              setProjectUsersInfo={setUsers}
              roleNumber={roleNumber}
              projectInfo={projectInfo}
            />
          ) : ''}
        </FlexContainer>
    </StyledUsersTable>
  )
}

export default UsersTable