import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { Colors } from '../../../../models/Colors'
import { IModalProps } from '../../../../models/IModalProps'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../UI/Input/Input'
import InputText from '../../../../UI/InputText/InputText'
import { assignNavReducer } from './../../store/reducers/AssignNavReducer'
import Teammate from './Teammate'
import { tasksReducer } from '../../store/reducers/TasksReducer'
import TasksService, { ICreateTaskPayload } from '../../../../services/TasksService'
import { useNavigate } from 'react-router-dom'
import { tabsSlice } from '../../store/reducers/TabsReducer'
import { batchesReducer } from './../../store/reducers/BatchesReducer'
import { IProjectUserFullInfo } from '../../../Projects/store/reducers/WorkspacesReducer'
import { UseProjectUsersQuery } from '../../../../hooks/UseProjectUsersQuery'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../UI/Loader/Loader'

const StyledBackground = styled.div<IModalProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #0000007b;
  transition: opacity 0.1s linear;
  height: ${({ visible }) => (visible ? '100vh' : '0')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 1001;
`
const StyledModal = styled.div`
  max-width: 380px;
  width: 100%;
  height: 100vh;
  padding: 20px 20px 30px 20px;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1006;
  color: ${Colors.textgray};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledTitle = styled.h3`
  margin: 15px 0;
`
export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.black};
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
`

export const StyledColoredSpan = styled.span`
  color: ${Colors.WHITE};
  font-size: 16px;
`

const AssignNav: FC<IModalProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentProjectId = useAppSelector((state) => state.tasksReducer.currentProjectId)
  const isLoading = useAppSelector((state) => state.tasksReducer.isLoading)
  const assignNavVisible = useAppSelector((state) => state.assignNavReducer.visible)
  const [textAreaText, setTextAreaText] = useState('')

  const [moders, setModers] = useState<IProjectUserFullInfo[]>([])
  const [labellers, setLabellers] = useState<IProjectUserFullInfo[]>([])

  const [isModerActive, setIsModerActive] = useState('')
  const [isLabellerActive, setIsLabellerActive] = useState('')

  const projectUsersInfo = useAppSelector((state) => state.WorkspacesReducer.projectUsersInfo)

  const [usersFilter, setUsersfilter] = useState({ query: '' })
  const queryUsers = UseProjectUsersQuery(projectUsersInfo, usersFilter.query)

  const { imagesTotal, selectedImages } = useAppSelector((state) => state.ImagesListReducer)

  useEffect(() => {
    if (queryUsers?.length) {
      const moders = queryUsers.filter((user) => user.projectRole.name === 'project_moderator')
      const labellers = queryUsers.filter((user) => user.projectRole.name === 'project_annotator')
      setModers(moders)
      setLabellers(labellers)
    }
  }, [queryUsers])

  const onBgClick = () => {
    dispatch(assignNavReducer.actions.setVisibleFalse())
    dispatch(assignNavReducer.actions.setInititialState())
  }

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaText(e.target.value)
  }

  const getCreateTaskPayload = () => {
    if (selectedImages?.length) {
      const imagesArr = selectedImages.map((image) => image.id)
      return {
        description: textAreaText,
        moderatorId: isModerActive ? isModerActive : '',
        annotatorId: isLabellerActive ? isLabellerActive : '',
        project: currentProjectId,
        batchId: '',
        images: imagesArr
      }
    }
    return {
      description: textAreaText,
      moderatorId: isModerActive ? isModerActive : '',
      annotatorId: isLabellerActive ? isLabellerActive : '',
      project: currentProjectId,
      batchId: '',
      images: []
    }
  }

  const onAssignClick = () => {
    const createTaslObj = getCreateTaskPayload()
    console.log('createTaslObj', createTaslObj)
    const asyncCreateTask = async (task: ICreateTaskPayload) => {
      try {
        dispatch(tasksReducer.actions.setLoadingState(true))
        const responce = await TasksService.createTask(task)
        dispatch(tasksReducer.actions.setLoadingState(false))
        dispatch(tabsSlice.actions.setActiveTab('annotate'))
        dispatch(batchesReducer.actions.setInitialState())
        console.log('asyncCreateTask', responce)
        navigate(`/projects/${currentProjectId}/job/annotate/${responce.data.task.id}`)
        dispatch(assignNavReducer.actions.setInititialState())
      } catch (error) {
        dispatch(tasksReducer.actions.setLoadingState(false))
        dispatch(tasksReducer.actions.setErrorState('Error on create Task'))
        console.log('asyncCreateTask', error)
      }
    }
    asyncCreateTask(createTaslObj)
    setTextAreaText('')
    setIsModerActive('')
    setIsLabellerActive('')
    // dispatch(assignNavReducer.actions.setInititialState())
  }

  return (
    <StyledBackground visible={assignNavVisible} onClick={onBgClick}>
      <StyledModal onClick={(e) => e.stopPropagation()}>
        <StyledTitle>{t('authorized.project.assign.assignImages')}</StyledTitle>
        <StyledBr></StyledBr>
        {isLoading ? (
          <FlexContainer direction="column" justify="center" align="center" heightProps='100%' gap='20px'>
            <Loader />
          </FlexContainer>
        ) : (
          <FlexContainer direction="column" align="flex-start" gap="15px" widthProps="100%">
            <h5>
              {t('authorized.project.assign.totalImages')}: <span>{selectedImages?.length ? selectedImages?.length : imagesTotal}</span>
            </h5>
            <Input
              label={t('authorized.project.assign.paragraph')}
              widthProps="100%"
              placeholder={t('authorized.project.projectManagement.inputTitle')}
              value={usersFilter.query}
              onChange={(e) => setUsersfilter({ ...usersFilter, query: e.target.value })}
            />
            <StyledColoredSpan color="white">{t('authorized.project.assign.selectLabeller')}:</StyledColoredSpan>
            {labellers?.length ? (
              labellers.map((user) => (
                <Teammate
                  key={user.user.id}
                  userId={user.user.id}
                  name={user.user.firstName}
                  email={user.user.email}
                  isActive={isLabellerActive}
                  setIsActive={setIsLabellerActive}
                />
              ))
            ) : (
              <StyledColoredSpan>{t('authorized.project.assign.noLabellers')}</StyledColoredSpan>
            )}

            <StyledColoredSpan color="white">{t('authorized.project.assign.selectModer')}:</StyledColoredSpan>
            {moders?.length ? (
              moders.map((user) => (
                <Teammate key={user.user.id} userId={user.user.id} name={user.user.firstName} email={user.user.email} isActive={isModerActive} setIsActive={setIsModerActive} />
              ))
            ) : (
              <StyledColoredSpan>{t('authorized.project.assign.noModer')}</StyledColoredSpan>
            )}
            <InputText
              value={textAreaText}
              label={t('authorized.project.assign.description')}
              onChange={onTextAreaChange}
              placeholder={t('forum.enterText')}
              widthProps="100%"
              heightProps="140px"
            />
          </FlexContainer>
        )}
        <StyledButtonsWrapper>
          <StyledBr></StyledBr>
          <ProjectButton widthProps="150px" onClick={onAssignClick}>
            {t('buttons.createTask')}
          </ProjectButton>
        </StyledButtonsWrapper>
      </StyledModal>
    </StyledBackground>
  )
}

export default AssignNav
