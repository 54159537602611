import React, { FC } from 'react'
import { useAppSelector } from '../../../../hooks/redux';
import { Colors } from '../../../../models/Colors'
import { StyledBr, StyledColoredSpan, StyledDrawNavContent, StyledDrawTabContentHeader, StyledDrawTabContentWrapper, StyledPre } from '../../Styled/styled'
import DrawAnnots from '../DrawTabsContent/DrawAnnots';
import { ICanvasElement } from '../DrawMain';
import { blackoutPolygon } from '../../Utils/DrawMethods';
import DrawAttributes from '../DrawTabsContent/DrawAttributes';
import { useTranslation } from 'react-i18next';

interface IDrawTabsNavContent {
  elements: any;
  selectedElement: ICanvasElement | null;
  setSelectedElement: React.Dispatch<React.SetStateAction<ICanvasElement | null>>;
  setSelectedElementDragPoints: React.Dispatch<React.SetStateAction<any[]>>;
  setBlackoutPolygon: React.Dispatch<React.SetStateAction<blackoutPolygon | null>>
}

const DrawTabsNavContent: FC<IDrawTabsNavContent> = ({elements, selectedElement, setBlackoutPolygon, setSelectedElement, setSelectedElementDragPoints, ...props}) => {
  const { t } = useTranslation()
  const code = useAppSelector(state => state.DrawTabsReducer.annotation)
  const activeBtn = useAppSelector(state => state.DrawTabsReducer.activeBtn)
  const currentGroup = useAppSelector(state => state.WorkspacesReducer.groupInfo)

  return (
    <StyledDrawTabContentWrapper>
        <StyledDrawTabContentHeader>{activeBtn.displayName || ''}</StyledDrawTabContentHeader>
        <StyledColoredSpan textColor={Colors.textgray}>
          {t('authorized.project.draw.nav.group')}: 
          <StyledColoredSpan textColor={Colors.lightgray}>
            {" " + currentGroup?.name || ''}
          </StyledColoredSpan>
        </StyledColoredSpan>
        <StyledBr/>
        <StyledDrawNavContent>
          {activeBtn.name === 'Annotations' ? 
            <DrawAnnots 
              elements={elements} 
              selectedElement={selectedElement} 
              setSelectedElement={setSelectedElement}
              setSelectedElementDragPoints={setSelectedElementDragPoints}
              setBlackoutPolygon={setBlackoutPolygon}
            />
          : ''}
          {activeBtn.name === 'Attributes'? <DrawAttributes/>: ''}
          {activeBtn.name === 'Comments'? t('authorized.project.draw.nav.comments'): ''}
          {activeBtn.name === 'History'? t('authorized.project.draw.nav.history'): ''}
          {activeBtn.name === 'Tags'? t('authorized.project.draw.nav.tags'): ''}
          {activeBtn.name === 'Raw'? <StyledPre>{JSON.stringify(code)}</StyledPre>: ''}
        </StyledDrawNavContent>
    </StyledDrawTabContentWrapper>
  )
}

export default DrawTabsNavContent