import React, { FC } from 'react'
import { useAppDispatch } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import EditCategoryFormMain from './EditCategoryFormMain'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { IGetCategoryByIdResponce, ISubcategory } from '../../../../../services/ForumService'
import { ForumReducer } from '../../../store/slices/ForumReducer'
import { asyncDeleteCategory } from '../../../../../store/reducers/AcyncActions'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IEditCategoryFormProps {
  categoryInfo: IGetCategoryByIdResponce
  subCategoryInfo?: ISubcategory
  isSub: boolean
  navigate: NavigateFunction
}

const DeleteCategoryForm: FC<IEditCategoryFormProps> = ({ categoryInfo, navigate, subCategoryInfo, isSub }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onDeleteClick = () => {
    if (subCategoryInfo?.id) {
      dispatch(asyncDeleteCategory('10', categoryInfo, subCategoryInfo.id, isSub))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    if (!subCategoryInfo?.id) {
      dispatch(asyncDeleteCategory('10', categoryInfo, categoryInfo.category.id, isSub))
      dispatch(ForumReducer.actions.deleteCategory(categoryInfo.category.id))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setTitle('Edit Category'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<EditCategoryFormMain navigate={navigate} isSub={isSub} categoryInfo={categoryInfo} />))
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="20px">
      <FlexContainer direction="row" justify="center" align="center" gap="50px">
        <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onDeleteClick}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default DeleteCategoryForm
