import React, { FC } from 'react'
import { useAppDispatch } from '../../../../../../hooks/redux'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import FormOption from './FormOption'
import {
  IAutoContrastOption,
  IBlurOption,
  IBrightness,
  IGrayscaleAugOption,
  INoiseOption,
  IResizeOption,
  ISimpleOption,
  ITileOption
} from '../../../../store/reducers/GenerateReducer'
import owlImage from '../../../../../../assets/images/owl.jpg'
import owlContrastImage from '../../../../../../assets/images/owlContrast.png'
import owlTileImage from '../../../../../../assets/images/owlTIle.png'
import owlBrightness from '../../../../../../assets/images/owlBrightness.jpg'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import RenameOption from './RenameOption'
import { useTranslation } from 'react-i18next'

interface IGenerateOptionsFormProps {
  type?: string
  resizeOption: IResizeOption
  tileOption: ITileOption
  autoContrastOption: IAutoContrastOption
  simpleOptions: ISimpleOption[]
  blurOption: IBlurOption
  grayscaleOption: IGrayscaleAugOption
  brightness: IBrightness
  noiseOption: INoiseOption
}

const GenerateOptionsForm: FC<IGenerateOptionsFormProps> = ({
  resizeOption,
  tileOption,
  autoContrastOption,
  simpleOptions,
  blurOption,
  noiseOption,
  grayscaleOption,
  brightness,
  type,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <>
      {type === 'preprocessing' ? (
        <FlexContainer direction="row" justify="space-around" align="center" wrap="wrap" gap="10px">
          {autoContrastOption.selected ? '' : <FormOption globalType={type} option={autoContrastOption} imagePath={owlContrastImage} />}
          {resizeOption.selected ? '' : <FormOption globalType={type} option={resizeOption} imagePath={owlImage} />}
          {tileOption.selected ? '' : <FormOption globalType={type} option={tileOption} imagePath={owlTileImage} />}
          {simpleOptions ? simpleOptions.map((option) => (option.selected ? '' : <FormOption globalType={type} option={option} key={option.method + performance.now()} />)) : ''}
          <RenameOption globalType={type} />
        </FlexContainer>
      ) : (
        ''
      )}
      {type === 'augmentation' ? (
        <FlexContainer direction="row" justify="space-around" align="center" wrap="wrap" gap="10px">
          {blurOption?.selected ? '' : <FormOption globalType={type} option={blurOption} imagePath={owlImage} />}
          {brightness?.selected ? '' : <FormOption globalType={type} option={brightness} imagePath={owlBrightness} />}
          {noiseOption?.selected ? '' : <FormOption globalType={type} option={noiseOption} imagePath={owlImage} />}
          {grayscaleOption?.selected ? '' : <FormOption globalType={type} option={grayscaleOption} imagePath={owlImage} />}
        </FlexContainer>
      ) : (
        ''
      )}
      <FlexContainer direction="row" justify="center" align="center">
        <ProjectButton onClick={onCancelClick}>{t('buttons.close')}</ProjectButton>
      </FlexContainer>
    </>
  )
}

export default GenerateOptionsForm
