import React, { FC } from 'react'
import { IPagesProps } from '../../models/IPagesProps'
import UserNav from '../../components/UserNav/UserNav'
import styled from 'styled-components'
import { Colors } from '../../models/Colors'

const StyledNotFountWrapper = styled.div`
    width: 100%;
    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
`
const StyledH5 = styled.h5`
    font-size: 42px;
    font-weight: bold;
`
const StyledH4 = styled.h4`
    font-size: 32px;
    font-weight: bold;
`
const StyledDiv = styled.div`
    font-size: 28px;
    font-weight: bold;
`
const StyledGoToWrapper = styled.span`
    color: ${Colors.orange};
    cursor: pointer;
    transition: all .1s linear;
    :hover {
        color: ${Colors.lightorange};
    }
`


export const NotFountPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <StyledNotFountWrapper>
        <StyledH5>404</StyledH5>
        <StyledH4>Not found</StyledH4>
        <StyledDiv>Go to <StyledGoToWrapper onClick={() => navigate('/projects')}>Projects</StyledGoToWrapper></StyledDiv>
      </StyledNotFountWrapper>
    </>
  )
}