import {$authHost, $host} from "../http/index";
import { AxiosResponse } from "axios";

export interface ICategory   {
    id: string;
    name: string;
    description: string;
    parent: string;
    position: number;
    createdAt: string;
    updatedAt: string;
    topicsCount: number;
}

export interface IForumTopic {
    id: string;
    views: number;
    likes: number;
    title: string;
    description: string;
    category: string;
    user: string;
    updatedAt: string;
    createdAt: string;
}

export interface IShortTopicInfo {
    id: string;
    title: string;
    description: string;
    messageCount: string | number;
}

export interface ITopicMessage {
    id: string;
    content: string;
    topic: string;
    user: string;
    likes: number;
    createdAt: string;
    updatedAt: string;
    isLiked: boolean;
    author: {
        id: string;
        email: string;
        firstName: string;
    }
}

export interface IFullTopicInfo {
    id: string;
    title: string;
    description: string;
    views: number;
    likes: number;
    createdAt: string;
    updatedAt: string;
    messageCount: string;
    isLiked: boolean;
    author: {
        id: string;
        email: string;
        firstName: string;
    }
    latestMessage: {
        id: string;
        title: string;
        content: string;
        likes: number;
        createdAt: string;
        author: {
            id: string;
            email: string;
            firstName: string;
        }
    }
    forumCategory: {
        id: string;
        description: string;
        name: string;
    };

}

export interface ISubcategory {
    id: string;
    name: string;
    description: string;
    topicsCount: string | number;
    latestTopicDate: string;
    messagesCount: string;
    LatestTopic: {
        id: string;
        title: string;
        description: string;
        createdAt: string;
        author: {
            id: string;
            email: string;
            firstName: string;
        }
    }
}

export interface IGetCategoryByIdResponce {
    category: ICategory;
    topics: IFullTopicInfo[];
    subcategories: ISubcategory[];
}

export interface IDeleteResponce {
    message: string;
}

export interface IGetTopicByIdResponce {
    topic: IFullTopicInfo;
    messages: ITopicMessage[];
    remain: number;
    total: number;
}

export interface IGetLastTopicsResponce {
    remain: number;
    views: number;
    topics: IFullTopicInfo[];
}

export interface ICreateTopicResponce {
    forumTopic: IForumTopic;
    message: string;
}


export interface IPutTopicResponce {
    topic: IFullTopicInfo;
    message: string;
}

export interface IPutMessageResponce {
    forumMessage: ITopicMessage;
    message: string;
}

export interface ICreateMessageResponce {
    forumMessage: ITopicMessage;
    message: string;
}

export default class ForumService {
    static async getCategories(): Promise<AxiosResponse<ICategory[]>> {
        return $authHost.get<ICategory[]>('/forum/')
    }
    static async createCategory(name:string, description: string): Promise<AxiosResponse<IGetCategoryByIdResponce>> {
        return $authHost.post<IGetCategoryByIdResponce>('/forum/category', {name, description})
    }
    static async createSubCategory(name:string, description: string, parent: string): Promise<AxiosResponse<IGetCategoryByIdResponce>> {
        return $authHost.post<IGetCategoryByIdResponce>('/forum/category', {name, description, parent})
    }
    static async getCategoryById(id: string, limit:string, orderBy: string): Promise<AxiosResponse<IGetCategoryByIdResponce>> {
        return $authHost.get<IGetCategoryByIdResponce>(`/forum/category/${id}?limit=${limit}&orderBy=${orderBy}`)
    }
    static async deleteCategory(id: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/forum/category/${id}`)
    }
    static async putCategory(id: string, name:string, description: string): Promise<AxiosResponse<IGetCategoryByIdResponce>> {
        return $authHost.put<IGetCategoryByIdResponce>('/forum/category', {id, name, description})
    }
    static async putSubCategory(id: string, name:string, description: string, parent: string): Promise<AxiosResponse<IGetCategoryByIdResponce>> {
        return $authHost.put<IGetCategoryByIdResponce>('/forum/category', {id, name, description, parent})
    }

    static async createTopic(title:string, description: string, category: string): Promise<AxiosResponse<ICreateTopicResponce>> {
        return $authHost.post<ICreateTopicResponce>('/forum/topic', {title, description, category})
    }
    static async getLastTopics(limit:string):Promise<AxiosResponse<IGetLastTopicsResponce>> {
        return $authHost.get<IGetLastTopicsResponce>(`/forum/topics?limit=${limit}&orderBy=updatedAt`)
    }
    static async getTopicById(id:string): Promise<AxiosResponse<IGetTopicByIdResponce>> {
        return $host.get<IGetTopicByIdResponce>(`/forum/topic/${id}?orderBy=createdAt`)
    }
    static async deleteTopic(id:string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/forum/topic/${id}`)
    }
    static async putTopic(id: string, title:string, description: string, category: string): Promise<AxiosResponse<IPutTopicResponce>> {
        return $authHost.put<IPutTopicResponce>('/forum/topic', {id, title, description, category})
    }
    
    static async createMessage(content:string, topic: string): Promise<AxiosResponse<ICreateMessageResponce>> {
        return $authHost.post<ICreateMessageResponce>('/forum/message', {content, topic})
    }
    static async getLastMessages(limit:string):Promise<AxiosResponse<ITopicMessage[]>> {
        return $authHost.get<ITopicMessage[]>(`/forum/messages?limit=${limit}&orderBy=updatedAt`)
    }
    static async putMessage(id: string, content:string, topic: string): Promise<AxiosResponse<IPutMessageResponce>> {
        return $authHost.put<IPutMessageResponce>('/forum/message', {id, content, topic})
    }
    static async deleteMessage(id: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/forum/message/${id}`)
    }

    static async setLikeOnTopic(topic:string, value: boolean): Promise<AxiosResponse<IGetTopicByIdResponce>> {
        return $authHost.post<IGetTopicByIdResponce>('/forum/like', {topic, value})
    }
    static async setLikeOnMessage(message:string, value: boolean): Promise<AxiosResponse<IGetTopicByIdResponce>> {
        return $authHost.post<IGetTopicByIdResponce>('/forum/like', {message, value})
    }
}