import React, { MouseEventHandler, useEffect, useRef } from 'react'
import Styles from './styles.module.css'
import Input from '../Input/Input';
import { CheckboxOption } from './DropdownSelect';
import styled from 'styled-components';

interface OptionProps {
  option: CheckboxOption;
  onClick: (value: CheckboxOption["value"]) => void;
};

const StyledInput = styled.input`
  width: 15px;
  height: 15px;
`

const StyledSpan = styled.span`
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectOption = (props: OptionProps) => {
  const {option: { value, title, selected }, onClick} = props;
  const optionRef = useRef<HTMLLIElement>(null);

  const handleClick = (clickedValue: CheckboxOption["value"]): MouseEventHandler<HTMLLIElement> => () => {
    onClick(clickedValue);
  };

  useEffect(() => {
    const option = optionRef.current;
    if (!option) return;
    const handleEnterKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === option && event.key === "Enter") {
        onClick(value);
      }
    };

    option.addEventListener("keydown", handleEnterKeyDown);
    return () => {
      option.removeEventListener("keydown", handleEnterKeyDown);
    };
  }, [value, onClick]);


  return (
    <li
      className={Styles.option}
      value={value}
      onClick={handleClick(value)}
      tabIndex={0}
      data-testid={`select-option-${value}`}
      ref={optionRef}
    >
      <StyledInput type='checkbox' value={value} checked={selected}/>
      <StyledSpan>{title}</StyledSpan>
    </li>
  )
}

export default SelectOption