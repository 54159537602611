import React, { FC, useEffect } from 'react'
import { NavigateFunction, Params, useParams } from 'react-router-dom';
import { Colors } from '../../../../../models/Colors'
import loupeIcon from '../../../../../assets/icons/loupe-svgrepo-com.svg'
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { tabsSlice } from '../../../store/reducers/TabsReducer';
import { tasksReducer } from '../../../store/reducers/TasksReducer';
import { StyledHeader } from '../../../constants/styled';
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton';
import { drawTabsSlice } from '../../../../Draw/store/DrawTabsReducer';
import { datasetsReducer } from '../../../store/reducers/DatasetsReducer';
import { StyledBr } from '../../AssignNav/AssignNav';
import { useTranslation } from 'react-i18next';
import { ImagesList } from '../../../../ImagesList';
import { EImagesPage } from '../../../../ImagesList/store/ImagesListReducer';
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer';
import AddLabelForm from '../ProjectAnnotate/components/JobPage/AddLabelForm';
import { asyncGetProjectLabels } from '../../../../../store/reducers/AcyncActions';
import { INewImage } from '../../../../../services/BatchesService';
import { IProject } from '../../../../../services/ProjectService';

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 67px;
`
const StyledHeaderTitle = styled.div`
  min-width: 180px;
  width: 280px;
  text-align: left;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

interface IProjectDatasetsProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: Readonly<Params<string>>;
}

const ProjectDatasets: FC<IProjectDatasetsProps> = ({navigate, projectInfo, param, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useAppDispatch()

  const isRoleLoading = useAppSelector(state => state.WorkspacesReducer.isRoleLoading)
  const userRoleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const labels = useAppSelector(state => state.DrawTabsReducer.labels)

  const { images, selectedImages, total, imagesTotal} = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {
    if (params.id) {
      dispatch(asyncGetProjectLabels(params.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if (params.tab === 'datasets') {
      dispatch(tabsSlice.actions.setActiveTab('datasets'))
      dispatch(datasetsReducer.actions.setToOpenDrawInitialState())
    }
    if (params.id) {
      dispatch(tasksReducer.actions.setCurrentProjectId(params.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDrawViewMode = () => {
    if (userRoleNumber <= 4) {
        return false
    }
    return true
  }

  const navigateToDraw = (image: INewImage, action: string) => {
    if (isRoleLoading) {
      return
    }
    if (!labels?.length) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
      dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={params.id!}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }

    const drawViewMode = getDrawViewMode()
    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))

    dispatch(drawTabsSlice.actions.setAction(action))
    navigate(`/projects/${params.id}/job/annotate/${params.datasetId}/draw/${image.id}`)
    
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${projectInfo.id}/dataset/dataset/${params.datasetId!}`))
  }

  const onOpenSelectedClick = () => {
    if (isRoleLoading) {
      return
    }

    if (!labels?.length) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
      dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={params.id!}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }

    const drawViewMode = getDrawViewMode()
    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))

    dispatch(drawTabsSlice.actions.setAction('selectedImages'))
    navigate(`/projects/${params.id}/job/annotate/${params.datasetId}/draw/${selectedImages[0].id}`)
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${params.id!}/datasets`))
  }


  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo.name? <StyledHeaderTitle>{projectInfo.name} {t('authorized.project.datasets.dataset')} ({imagesTotal || '0'})</StyledHeaderTitle>: <StyledHeaderTitle>{t('authorized.project.datasets.dataset')} </StyledHeaderTitle>} 
        {selectedImages?.length
          ? <ProjectButton onClick={onOpenSelectedClick} widthProps='260px' heightProps='40px' paddingProps='0 10px' FontSizeProps='14px'>
              {t('authorized.project.datasets.datasetImages', {count: selectedImages?.length})}
            </ProjectButton>
          : ''
        }
      </StyledHeader>
      <StyledBr/>
      <ImagesList page={EImagesPage.datasetsPage} onImageClick={navigateToDraw}/>
    </StyledProjects>
  )
}

export default ProjectDatasets