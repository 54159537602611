import React, { FC, useEffect } from 'react'
import { StyledBr, StyledForumsWrapper, StyledTopicNavStat, StyledTopicsWrapper } from '../../constants/styled'
import { NavigateFunction, Params } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { asyncGetCategoryById, asyncGetSubCategoryById } from '../../../../store/reducers/AcyncActions'
import Topic from './Topic'
import TopicsHeader from './TopicsHeader'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { IUsersMeResponse } from '../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ITopicsMainProps {
  navigate: NavigateFunction
  params: Readonly<Params<string>>
  user: IUsersMeResponse
}

const TopicsMain: FC<ITopicsMainProps> = ({ navigate, params, user, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentCategory = useAppSelector((state) => state.ForumReducer.currentCategory)
  const currentSubCategory = useAppSelector((state) => state.ForumReducer.currentSubCategory)
  console.log('sub', currentSubCategory)
  useEffect(() => {
    if (params.topics && params.categoryId && params.subcategoryId) {
      dispatch(asyncGetCategoryById(params.categoryId))
      dispatch(asyncGetSubCategoryById(params.subcategoryId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.subcategoryId])

  return (
    <StyledForumsWrapper>
      <TopicsHeader navigate={navigate} currentCategory={currentCategory} currentSubCategory={currentSubCategory} user={user} />
      <StyledTopicsWrapper>
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="5px 30px">
          <StyledTopicNavStat>{t('forum.topics')}:</StyledTopicNavStat>
          <StyledTopicNavStat>{t('forum.statistics')}:</StyledTopicNavStat>
          <StyledTopicNavStat>{t('forum.lastMessage')}:</StyledTopicNavStat>
        </FlexContainer>
        <StyledBr />
        {currentSubCategory?.topics?.length
          ? currentSubCategory?.topics.map((topic) => <Topic topicInfo={topic} currentCategory={currentCategory} navigate={navigate} key={topic.id} />)
          : ''}
      </StyledTopicsWrapper>
    </StyledForumsWrapper>
  )
}

export default TopicsMain
