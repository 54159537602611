import React, { FC } from 'react'
import UserNav from '../../components/UserNav/UserNav'
import { IPagesProps } from '../../models/IPagesProps'
import UserSettings from '../../modules/UserSettings/UserSettings'

export const UserSettingsPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <UserSettings navigate={navigate}/>
    </>
  )
}