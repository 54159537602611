import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../models/Colors'
import { IButtonProps } from '../../../../models/IButtonProps'

const StyledButton = styled.div<IButtonProps>`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: ${props => props.widthProps || '100%'} ;
    padding:${props => props.paddingProps || '0px'};
    height:${props => props.heightProps || '50px'};

    border-left: 5px solid transparent;
    background: #252525;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    font-size: ${props => props.FontSizeProps || '16px'};

    color: ${Colors.textgray};
    text-align: center;
    transition: all 0.1s linear;

    opacity: 0.3;
`
const StyledButtonText = styled.span`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ProjectTabBtnDisabled: FC<IButtonProps> = ({children, imageProps, ...props}) => {
  return (
    <StyledButton {...props}>
        <FlexContainer direction='row' justify='flex-start' align='center' margin='0 10px'>
            {imageProps}
            <StyledButtonText>{children}</StyledButtonText>
        </FlexContainer>
    </StyledButton>
  )
}

export default ProjectTabBtnDisabled