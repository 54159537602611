import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetStatActionsResponce, IGetStatResponce, IGetTimelinesRes, IUserTasksStat } from '../../../../services/StatsService';
import { string } from 'prop-types';

interface IUserColor {
  id: string, 
  color: string,
  role: string,
}

export interface IReducerStatAction {
  id: string;
  name: string;
  displayName: string;
  description: string;
  counter: boolean;
  dates?: string[],
  datasets?: {
    label: string;
    pointBackgroundColor: string;
    backgroundColor: string;
    borderColor: string;
    pointRadius: number;
    data: number[];
  }[] | any;

  total?: number;
  userId?: string;

  minDataset?: {
    label: string;
    pointBackgroundColor: string;
    backgroundColor: string;
    borderColor: string;
    pointRadius: number;
    data: number[];
  }[] | any;

  maxDataset?: {
    label: string;
    pointBackgroundColor: string;
    backgroundColor: string;
    borderColor: string;
    pointRadius: number;
    data: number[];
  }[] | any;
}

export interface ITimeline {
  userId: string;
  firstname: string;
  lastname: string;
  action: string;
  date: string;
}

interface IReducerState {
  isLoading: boolean;
  error:  string | null;
  selectedUsers: string[];
  filteredUsersCounter: number;

  onloadStats: IGetStatResponce;
  stats: IGetStatResponce;
  statActions: IReducerStatAction[] ;
  usersColors: IUserColor[];

  actionStats: {
    [key: string]: IReducerStatAction;
  }

  usersTasksStat : IUserTasksStat[];

  taskTimeline: ITimeline[];
}

interface ISetActionStatsPayload {
  actionName: string;
  data: IGetStatResponce;
}

const initialState: IReducerState = {
  isLoading: false,
  error: '',
  selectedUsers: [],
  filteredUsersCounter: 0,
  
  onloadStats: {
    dates: [],
    datasets: [],
  },
  stats: {
    dates: [],
    datasets: [],
  },
  statActions: [],

  usersColors:[],

  actionStats: {},

  usersTasksStat: [],

  taskTimeline: [],
}

export const statisticsSlice = createSlice(
{
    name: 'statistics',
    initialState,

    reducers: {      
      setInitialState(state) {
        state.isLoading = false;
        state.error = '';
        state.selectedUsers = [];
        state.filteredUsersCounter = 0;
        state.onloadStats = initialState.onloadStats;
        state.stats = initialState.stats;
        state.statActions = initialState.statActions;
        state.usersColors = initialState.usersColors;
        state.actionStats = initialState.actionStats;
        state.usersTasksStat = initialState.usersTasksStat;
        state.taskTimeline = initialState.taskTimeline;
      },
      setIsLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setError(state, action: PayloadAction<string>) {
        state.error = action.payload;
      },
      setSelectedUser(state, action: PayloadAction<string>) {
        const findedUser = state.selectedUsers.find(user => user === action.payload)
        if (findedUser) {
            state.selectedUsers = state.selectedUsers.filter(user => user !== findedUser);
        }
        if (!findedUser) {
            if (state.selectedUsers.length >= 2) {
                return
            }
            state.selectedUsers.push(action.payload);
        }
      },
      setSelectedUsers(state, action: PayloadAction<string[]>) {
         state.selectedUsers = action.payload;
      },
      setFilteredUsersCounter(state, action: PayloadAction<number>) {
         state.filteredUsersCounter = action.payload;
      },

      setOnloadStats(state, action: PayloadAction<IGetStatResponce>) {
        state.onloadStats = action.payload;
      },
      setStats(state, action: PayloadAction<IGetStatResponce>) {
        state.stats = action.payload;
      },
      setStatActions(state, action: PayloadAction<IGetStatActionsResponce>) {
        state.statActions = action.payload.actions;
      },
      setUser(state, action: PayloadAction<IUserColor>) {
        const findedUser = state.usersColors.find(user => user.id === action.payload.id);
        if (!findedUser) {
          state.usersColors.push(action.payload);
        }
      },

      setActionStats(state, action: PayloadAction<ISetActionStatsPayload>) {
        const findedAction = state.statActions.find(statAction => statAction.name === action.payload.actionName)
        if (findedAction?.id) {
          // eslint-disable-next-line array-callback-return
          const datasets = action.payload.data.datasets.map(dataset => {
            if (typeof(dataset.user) !== 'string' && dataset.user.id) {
              const findedUserColor = state.usersColors.find(userColor => dataset.user.id === userColor.id)
              if (findedUserColor) {
                let total = 0 
                dataset.data.forEach(dataNumber => {
                  total += dataNumber
                })
                return {
                  label: dataset?.user?.firstName || "User",
                  pointBackgroundColor: '#fff',
                  backgroundColor: 'rgba(255, 255, 255, 0)',
                  borderColor: findedUserColor.color,
                  
                  pointRadius: 5,
                  data: dataset.data,
                  total: total,
                  userId: dataset?.user?.id,
                  userRole: findedUserColor.role,
                }
              }
            }
          })
          console.log('datasets', datasets)
          const findedMin = action.payload.data.datasets.find(dataset => dataset?.user === 'min')
          const findedMax = action.payload.data.datasets.find(dataset => dataset?.user === 'max')


          if (datasets?.length && datasets !== undefined && (!findedMax || !findedMin)) {
            state.actionStats[action.payload.actionName] = {...findedAction, datasets: datasets, dates: action.payload.data.dates, minDataset: {}, maxDataset: {}}
          }
          if (datasets?.length && datasets !== undefined && (findedMax || findedMin)) {
            const minDataset = {
              label: "Min",
              pointBackgroundColor: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0)',
              borderColor: 'rgba(17, 255, 0, 0.5)',
              pointRadius: 5,
              data: findedMin?.data || [],

              borderDash: [5],
              borderDashOffset: 3,
              borderJoinStyle: 'miter', 
            }
            const maxDataset = {
              label: "Max",
              pointBackgroundColor: '#fff',
              backgroundColor: 'rgba(255, 255, 255, 0)',
              borderColor: 'rgba(255, 0, 0, 0.5)',
              pointRadius: 5,
              data: findedMax?.data || [],

              borderDash: [5],
              borderDashOffset: 3,
              borderJoinStyle: 'miter',
            }

            state.actionStats[action.payload.actionName] = {...findedAction, datasets: datasets, dates: action.payload.data.dates, minDataset, maxDataset}
          }
        }
      },

      setUsersTasksStats(state, action: PayloadAction<IUserTasksStat[]>) {
        state.usersTasksStat = action.payload;
      },

      setTaskTimeline(state, action: PayloadAction<IGetTimelinesRes['timelines']>) {
        const timelines = action.payload?.map(res => {
          const timelineDate = res?.details?.createdAt?.slice(0, 10)
          return {
            userId: res.user.id,
            firstname: res.user.firstName,
            lastname: res.user.lastName,
            action: res.action.displayName,
            date: timelineDate ? timelineDate : ''
          }
        })
        state.taskTimeline = timelines
      },
    }
},
)



export default statisticsSlice.reducer;