import styled from 'styled-components'
import { Colors } from '../../models/Colors'
import { IUserNavDropdownProps } from '../../models/IUserNavDropdownProps'

export const StyledHeader = styled.header`
  width: 100%;
  height: 70px;
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.dark};

  position: absolute;
  top: 0;
  z-index: 100;

  @media (max-width: 470px) {
  }
`
export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-right: 5px;

  @media (max-width: 680px) {
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;

  @media (max-width: 750px) {
    img {
      width: 35px;
    }
    gap: 30px;
  }
  @media (max-width: 470px) {
    gap: 10px;
  }
`

export const StyledUserName = styled.h3`
  color: ${Colors.orange};
  font-size: 16px;
  cursor: pointer;
  :hover {
    color: ${Colors.lightorange};
  }
  @media (max-width: 470px) {
    font-size: 12px;
  }
`
export const StyledTriangle = styled.div<IUserNavDropdownProps>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 8.7px 5px;
  border-color: transparent transparent ${Colors.orange} transparent;

  transform: ${(props) => (props.active ? 'rotate(0)' : 'rotate(180deg)')};
`
export const StyledText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${Colors.orange};
  :hover {
    color: ${Colors.lightorange};
  }
  @media (max-width: 746px) {
    font-size: 16px;
  }
  @media (max-width: 570px) {
    display: none;
  }
`

export const StyledNotify = styled.div`
  width: 30px;
  height: 30px;

  font-weight: bold;
  font-size: 10px;
  cursor: pointer;

  position: relative;

  .count {
    position: absolute;
    top: -6px;
    right: 4px;
  }
  :hover {
    zoom: 105%;
  }

  @media (max-width: 746px) {
    display: none;
  }
`

export const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  .username {
    height: 30px;
    padding: 5px;
  }
  position: relative;
`

export const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 160px;
  min-height: 120px;
  height: auto;

  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;

  position: absolute;
  top: 100%;
  right: -20%;
  z-index: 1000;
`
