import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../models/Colors'
import EmptyImg from '../../../../assets/icons/empty.png'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectDropdown from '../ProjectDropdown/ProjectDropdown'
import { IProjectProps } from '../../models/IProjectProps';
import { useAppDispatch } from './../../../../hooks/redux';
import { WorkspacesSlice } from '../../store/reducers/WorkspacesReducer'
import detectingIcon from '../../../../assets/icons/detect.svg'
import imageIcon from '../../../../assets/icons/image-1-svgrepo-com.svg'
import usersIcon from '../../../../assets/icons/users-svgrepo-com.svg'
import { useTranslation } from 'react-i18next'

const StyledDetecting = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${detectingIcon});
  mask-image: url(${detectingIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
`
const StyledImage = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${imageIcon});
  mask-image: url(${imageIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`
// const StyledModels = styled.i`
//   -webkit-mask-size: cover;
//   mask-size: cover;
//   -webkit-mask-image: url(${modelsIcon});
//   mask-image: url(${modelsIcon});
//   background-color: ${Colors.orange};
//   width: 20px;
//   height: 20px;
// `
const StyledUsers = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${usersIcon});
  mask-image: url(${usersIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
`

const StyledProject = styled.div`
  display: flex;
  flex-direction: column;
  
  padding: 8px 10px;
  width: 370px;
  height: 150px;
  background: #2B2B2B;
  border-radius: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  border: 1px solid ${Colors.gray};
  cursor: pointer;

  transition: all 0.1s linear;     

  :hover {
    border-color: ${Colors.orange};
    color: ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  position: relative;
`
const StyledImageAndInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`
const StyledTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  max-width: 210px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #FFFFFF;
`
const StyledModifyAt = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  font-size: 12px;
  text-align: left;
  color: #8F8F8F;
`
const StyledProjectBtnText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  font-size: 11px;
  color: #575756;
`
const StyledProjectBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all 0.1s linear; 

  :hover ${StyledProjectBtnText} {
    color: ${Colors.orange};
  }
  img {
    width: 18px;
    height: 18px;
  }
`
const StyledDropdown = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.1s linear; 
  cursor: pointer;
  :hover img {
    box-shadow: 0 4px 30px rgba(0,0,0,0.25), 0 10px 30px rgba(0,0,0,0.22);
  }
`

const StyledProjectImg = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 5px;
`
 
const Project:FC <IProjectProps> = ({roleNumber, projectInfo, title, id, updatedAt, navigate, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onProjectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    dispatch(WorkspacesSlice.actions.setProjectInfo(projectInfo))
    navigate(`/projects/${id}/overview`)
  }

  return (

    <StyledProject {...props} onClick={onProjectClick}>
      {roleNumber < 4 
        ?
        <StyledDropdown>
          <ProjectDropdown projectInfo={projectInfo}/>
        </StyledDropdown>
        : ''}

      <StyledImageAndInfoWrapper>
        <StyledProjectImg src={projectInfo.projectLogoUrl} alt="img"/>
        <FlexContainer direction='column' gap='15px' align='flex-start'>
          <StyledTitle>{title}</StyledTitle>
          <StyledModifyAt>{t('other.type')}: {projectInfo.objectType}</StyledModifyAt>
          <StyledModifyAt>{t('authorized.projects.groupProjects.project.update') + ' ' + updatedAt.slice(0, 10)}</StyledModifyAt>
        </FlexContainer>
      </StyledImageAndInfoWrapper>
      <FlexContainer direction='row' justify='flex-start' align='center' paddingProps='10px 0' gap='10px'>
        <StyledProjectBtn>
          <StyledDetecting/>
          <StyledProjectBtnText>DetectingType</StyledProjectBtnText>
        </StyledProjectBtn>
        {/* <StyledProjectBtn>
          <StyledModels/>
          <StyledProjectBtnText>Models</StyledProjectBtnText>
        </StyledProjectBtn> */}
        <StyledProjectBtn>
          <StyledUsers/>
          <StyledProjectBtnText>{t('authorized.projects.groupProjects.project.users')}</StyledProjectBtnText>
        </StyledProjectBtn>
        <StyledProjectBtn>
          <StyledImage/>
          <StyledProjectBtnText>{t('authorized.projects.groupProjects.project.images')}</StyledProjectBtnText>
        </StyledProjectBtn>
      </FlexContainer>
    </StyledProject>
  )
}

export default Project