import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import { Colors } from '../../../../../../models/Colors'
import { validateEmail } from '../../../../../LoginForm/helpers/validateForms'
import { setInputFocusError } from '../../../../../LoginForm/helpers/inputFocusColorChange'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledErrorText, StyledFormTab, StyledInputSearchIcon, StyledInviteForm, StyledLink } from '../../../../constants/styled'
import Input from '../../../../../../UI/Input/Input'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { ProjectBr } from '../../../../../../styles/GlobalStyles'
import InviteCheckbox from '../InviteCheckbox/InviteCheckbox'
import GroupUserCheckbox from '../GroupUserCheckbox/GroupUserCheckbox'
import { Option } from '../../../../../../UI/ProjectSelect/ProjectSelect'
import RolesService, { IGroupMember } from '../../../../../../services/RolesService'
import { IGroupUserFullInfo, IProjectUserFullInfo, WorkspacesSlice } from '../../../../../Projects/store/reducers/WorkspacesReducer'
import { UseGroupUsersQuery } from '../../../../../../hooks/UseGroupUsersQuery'
import { SettingsReducer } from '../../../../../UserSettings/reducers/SettingsReducer'
import { useNavigate } from 'react-router-dom'
import ProjectService from '../../../../../../services/ProjectService'
import GroupService from '../../../../../../services/GroupService'
import AccessService from '../../../../../../services/AccessService'
import UserService from '../../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface IInviteUserFormProps {
  roleNumber: number;
}

const InviteUserForm: FC<IInviteUserFormProps> = ({roleNumber, ...props}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const projectInfo = useAppSelector(state => state.WorkspacesReducer.projectInfo)
  const projectRoles = useAppSelector(state => state.rolesReducer.projectRoles)
  const projectUsers = useAppSelector(state => state.WorkspacesReducer.projectUsersInfo)

  const groupUsers = useAppSelector(state => state.WorkspacesReducer.groupUsersInfo)

  //inputs state   
  const [emailFocusColor, setEmailFocusColor] = useState(Colors.gray)
  const [inputValue, setInputValue] = useState('')
  const [checkboxesValue, setCheckboxesValue] = useState<any[]>([])

  const [GroupCheckboxesValue, setGroupCheckboxesValue] = useState<any[]>([])

  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')

  const [groupErrorVisible, setGroupErrorVisible] = useState(false)
  const [groupErrorText, setGroupErrorText] = useState('')

  const [isQuotaError, setIsQuotaError] = useState(false)

  //checkbox
  const [emails, setEmails] = useState<string[]>([])
  const [optionsTable, setOptionsTable] = useState<Option[]>([])

  //tabs
  const [currentTab, setCurrentTab] = useState("group")

  const [filteredUsers, setFilteredUsers] = useState<IGroupUserFullInfo[]>([])

  //searchUsers
  const [filter, setFilter] = useState({query: ''})
  const queryUsers = UseGroupUsersQuery(filteredUsers, filter.query)

  console.log('filteredUsers', filteredUsers)
  useEffect(() => {
    const filteredUsers: IGroupUserFullInfo[] = []

    let groupUsersEmails = groupUsers.map(({user}) => (user.email))
    let projectUsersEmails = projectUsers.map(({user}) => (user.email))
    
    let difference = groupUsersEmails.filter(x => !projectUsersEmails.includes(x));
    console.log('diff', difference)
    difference.forEach(diff => {
      const diffUser = groupUsers.find(user => user.user.email === diff)
      if (diffUser) {
        filteredUsers.push(diffUser)
      }
    })
    
    setFilteredUsers(filteredUsers)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[groupUsers, projectUsers])

  useEffect(() => {
    if (projectRoles.length) {
      const optionsTable: Option[] = []
      projectRoles.forEach(role => {
        optionsTable.push({title: role.displayName, value: role.name})
      })
      //Select options filter
      if (optionsTable?.length && roleNumber === 1) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user'
      )
        setOptionsTable(filteredOptions)
      }
      if (optionsTable?.length && roleNumber === 2) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user'
        )
        setOptionsTable(filteredOptions)
      }
      if (optionsTable?.length && roleNumber === 3) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user'
        )
        setOptionsTable(filteredOptions)
      }
      if (optionsTable?.length && roleNumber === 4) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user' && 
          option.value !== 'project_owner' &&
          option.value !== 'project_admin'
        )
        setOptionsTable(filteredOptions)
      }
      if (optionsTable?.length && roleNumber === 5) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user' &&
          option.value !== 'project_owner' &&
          option.value !== 'project_admin'         
        )
        setOptionsTable(filteredOptions)
      }
      if (optionsTable?.length && roleNumber > 5) {
        const filteredOptions = optionsTable.filter(option => 
          option.value !== 'project_owner' &&
          option.value !== 'group_owner' && 
          option.value !== 'group_admin' &&
          option.value !== 'group_user' &&
          option.value !== 'project_owner' &&
          option.value !== 'project_admin'         
        )
        setOptionsTable(filteredOptions)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectRoles, roleNumber])

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        enterEmailClickHandler()
      }
    }
    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, errorText, visible])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupCheckboxesValue([])
    setFilter({...filter, query: e.target.value})
  }


  const onUserEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    const validateResult = validateEmail(e.target.value)
    setInputFocusError(setVisible, setErrorText, setEmailFocusColor, validateResult) 
  }

  const enterEmailClickHandler = () => {
    if (inputValue && !errorText) {
      setEmails([...emails, inputValue])
      setInputValue('')
    }
  }
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, setChecked: any) => {
    if (e.target.checked) {
        setChecked(true)
        const checkboxesValueCopy = [...checkboxesValue]
        let targetEmail = e.target.value.split(" ")[0]
        let targetRole = e.target.value.split(" ")[1]
        checkboxesValueCopy.push({email: targetEmail, role: targetRole})

        setCheckboxesValue(checkboxesValueCopy)
        setVisible(false)
        setErrorText('')
        console.log('✅ Checkbox is checked', checkboxesValueCopy);
      } else {
        if (e.target.value) {
            setChecked(false)
            let targetEmail = e.target.value.split(" ")[0]
            const filteredCheckboxesValues = checkboxesValue.filter((value: any) => value.email !== targetEmail)
            setCheckboxesValue(filteredCheckboxesValues)
            console.log('⛔️ Checkbox is NOT checked', filteredCheckboxesValues);
        }
      }
  }

  const onGroupCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, setChecked: any) => {
    if (e.target.checked) {
        setChecked(true)
        let targetEmail = e.target.value.split(" ")[0]
        let targetRole = e.target.value.split(" ")[1]
        const findedId = GroupCheckboxesValue.find(value => value.email === targetEmail)
        setGroupErrorVisible(false)
        setGroupErrorText('')
        if (!findedId) {
          setGroupCheckboxesValue([...GroupCheckboxesValue, {email: targetEmail, role: targetRole}])
        }
        console.log('✅ Checkbox is checked', [...GroupCheckboxesValue, {email: targetEmail, role: targetRole}]);
      } else {
        if (e.target.value) {
            setChecked(false)
            let targetEmail = e.target.value.split(" ")[0]
            const filteredCheckboxesValues = GroupCheckboxesValue.filter((value: any) => value.email !== targetEmail)
            setGroupCheckboxesValue(filteredCheckboxesValues)
            console.log('⛔️ Checkbox is NOT checked', filteredCheckboxesValues);
        }
      }
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onInviteClick = async () => {
    try {
      dispatch(WorkspacesSlice.actions.setLoadingStart())

      const objectToAPI: IGroupMember[] = []
      if (currentTab === 'email') {
        if (!checkboxesValue?.length) {
          setVisible(true)
          setErrorText(t('authorized.projects.groups.groupManagement.selectUsers'))
        }
  
        if (checkboxesValue?.length) {
          checkboxesValue.forEach(value => {
            const findedRole = projectRoles.find(role => role.name === value.role)
            if (findedRole) {
              objectToAPI.push({user: {email: value.email}, role: {id: findedRole.id}})
            }
          });
        }
      }
  
      if (currentTab === 'group') {
        if (!GroupCheckboxesValue?.length) {
          setGroupErrorVisible(true)
          setGroupErrorText(t('authorized.projects.groups.groupManagement.selectUsers'))
        }
  
        if (GroupCheckboxesValue?.length) {
          GroupCheckboxesValue.forEach(value => {
            const findedRole = projectRoles.find(role => role.name === value.role)
            console.log('findedRole', findedRole)
            if (findedRole) {
              objectToAPI.push({user: {email: value.email}, role: {id: findedRole.id}})
            }
          });
        }
      }
      
      if (objectToAPI?.length) {
        const responce = await RolesService.addUserToProject(projectInfo.id, {projectMembers: objectToAPI})
      
        const projectInfoResponce = await ProjectService.getOneProjectById(projectInfo.id)
        const groupInfoResponce = await GroupService.getOneGroupById(projectInfoResponce.data.project.group)
    
        const usersInfo: IGroupUserFullInfo[] = []
        const projectUsersInfo: IProjectUserFullInfo[] = []
        
        for (const member of groupInfoResponce.data.group.groupMembers) {
          const userRoleInfo = await AccessService.getRoleById(member.roleId)
          const userInfo = await UserService.getUserById(member.user)
          usersInfo.push({groupRole: userRoleInfo.data.role, user: userInfo.data.user})
        }
        
        for (const member of projectInfoResponce.data.members) {
          const userRoleInfo = await AccessService.getRoleById(member.roleId)
          const userInfo = await UserService.getUserById(member.user)
          projectUsersInfo.push({projectRole: userRoleInfo.data.role, user: userInfo.data.user})
        }
    
        dispatch(WorkspacesSlice.actions.setGroupInfo(groupInfoResponce.data.group))
        dispatch(WorkspacesSlice.actions.setProjectInfo(projectInfoResponce.data.project))
        dispatch(WorkspacesSlice.actions.setFullGroupUserInfo(usersInfo))
        dispatch(WorkspacesSlice.actions.setFullProjectUsersInfo(projectUsersInfo))
    
        console.log('asyncInviteUsersToProject', responce)
        dispatch(WorkspacesSlice.actions.setLoadingEnd())
        if (responce.data.failedUsers?.length) {
          setVisible(true)
          setIsQuotaError(true)
          setGroupErrorVisible(true)
          setErrorText(t('authorized.projects.groups.groupManagement.errorQuotaUsers'))
          setGroupErrorText(t('authorized.projects.groups.groupManagement.errorQuotaUsers'))
          return
        }
        dispatch(modalWindowSlice.actions.setInititialState())
      }

    } catch (error) {
      dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncInviteUsersToProject'))
      console.log('asyncInviteUsersToProject', error)
    }
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(projectInfo.group))
    navigate(`/settings/group/${projectInfo.group}`)
    setVisible(false)
    setIsQuotaError(false)
    setGroupErrorVisible(false)
    setErrorText('')
    setGroupErrorText('')
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
        <>
          <FlexContainer direction='row' justify='space-between' align='center' paddingProps='0 30px'>
            <StyledFormTab active={currentTab === 'group'} onClick={() => setCurrentTab('group')}>{t('authorized.project.projectManagement.groupUsers')}</StyledFormTab>
            <StyledFormTab active={currentTab === 'email'} onClick={() => setCurrentTab('email')}>{t('authorized.project.projectManagement.emailInvite')}</StyledFormTab>
          </FlexContainer>
          
          {currentTab === 'group'
            ?
            <FlexContainer direction='column' justify='flex-start' align='center' gap='25px'>
              <StyledErrorText colorProps={Colors.red} visible={groupErrorVisible}>{groupErrorText}</StyledErrorText>
              {isQuotaError && <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>}
              <FlexContainer direction='row' justify='space-around' align='center' >
                <Input 
                  type='text' 
                  label={t('authorized.projects.groups.groupManagement.inputTitle')}
                  withIcon={<StyledInputSearchIcon/>} 
                  placeholder='email.to.search@mail.net' 
                  widthProps='400px'
                  value={filter.query} 
                  onChange={onSearchChange}
                />
              </FlexContainer>

              <FlexContainer direction='column' justify='flex-start' align='center' gap="5px">
                  <FlexContainer direction='row' justify='space-between' paddingProps='0 10px'>
                    <span>{t('authorized.project.projectManagement.users')}</span>
                    <span>{t('authorized.projects.groups.groupManagement.groupRole')}</span>
                  </FlexContainer>
                  <ProjectBr/>

                  <StyledInviteForm>
                    {queryUsers?.length ? queryUsers.map(({user}) => 
                      <GroupUserCheckbox
                        key={user.id}
                        setCheckboxesValue={setGroupCheckboxesValue} 
                        checkboxesValue={GroupCheckboxesValue} 
                        setErrorText={setGroupErrorText} 
                        setVisible={setGroupErrorVisible} 
                        onChange={onGroupCheckboxChange} 
                        value={user.email} 
                        title={user.email}
                        firstName={user.firstName}
                        lastName={user.lastName}
                        optionsTable={optionsTable}
                      />
                    ) : ''}
                  </StyledInviteForm>
              </FlexContainer>
            </FlexContainer>
            : ''
          }

          {currentTab === 'email'
            ? 
            <FlexContainer direction='column' justify='flex-start' align='center' gap='25px'>
              <StyledErrorText colorProps={Colors.red} visible={visible}>{errorText}</StyledErrorText>
              {isQuotaError && <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>}
              <FlexContainer direction='row' justify='space-around' align='center' >
                <Input 
                  focusColor={emailFocusColor} 
                  borderColor={emailFocusColor} 
                  type='text' 
                  label={t('notAuthorized.inputTitles.email')}
                  placeholder='email.to.invite@mail.net' 
                  value={inputValue} 
                  onChange={onUserEmailChangeHandler}
                  widthProps='300px'
                />
                <ProjectButton onClick={enterEmailClickHandler} widthProps='90px' FontSizeProps='15px' margin='25px 0 0 0'>{t('buttons.add')}</ProjectButton>
              </FlexContainer>

              <FlexContainer direction='column' justify='flex-start' align='center' gap="5px">
                <FlexContainer direction='row' justify='space-between' paddingProps='0 10px'>
                  <span>{t('authorized.project.projectManagement.users')}</span>
                  <span>{t('authorized.projects.groups.groupManagement.groupRole')}</span>
                </FlexContainer>
                <ProjectBr/>

                <StyledInviteForm>
                  {emails?.length ? emails.map((email, index) => 
                    <InviteCheckbox
                      setCheckboxesValue={setCheckboxesValue} 
                      checkboxesValue={checkboxesValue} 
                      setErrorText={setErrorText} 
                      setVisible={setVisible} 
                      key={email + index} 
                      value={email} 
                      onChange={onCheckboxChange} 
                      title={email}
                      optionsTable={optionsTable}
                    />
                  ) : ''}
                </StyledInviteForm>
              </FlexContainer>
            </FlexContainer>
            : ''
          }
          <FlexContainer direction='row' justify='space-between' paddingProps='0 20px 0 20px' self='flex-end' margin='10px 0 0 0'>
            <ProjectButton onClick={onCancelClick} widthProps='130px' FontSizeProps='16px'>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton onClick={onInviteClick} widthProps='130px' FontSizeProps='16px'>{t('buttons.continue')}</ProjectButton>
          </FlexContainer>
        </>
  )
}

export default InviteUserForm