import React, { useEffect, useLayoutEffect, useState } from 'react'
import rough from 'roughjs/bundled/rough.esm'
import DrawHeader from './DrawHeader';
import { 
  StyledBr, StyledCanvas, StyledCanvasWrapper, StyledDisabledEyeIcon, StyledDisabledTool, StyledDrawWrapper, StyledEyeIcon, StyledHandIcon, 
  StyledHotKeysTitle, 
  StyledHotkeysContent, 
  StyledHotkeysWrapper, 
  StyledImgContainer, StyledLabelForm, StyledLabelsContaier, StyledMarkNullIcon, StyledMinusIcon, StyledPlusIcon, StyledPointIcon, 
  StyledRectangleIcon, StyledRedoIcon, StyledSlideP, StyledTippyBox, StyledTolltip, StyledTool,
  StyledToolsMenu, StyledTooltipText, StyledUndoIcon, StyledZoomPanel, StyledZoomPanelPercents 
} from '../Styled/styled';
import DrawTabsNav from './DrawTabsNav/DrawTabsNav';
import DrawTabsNavContent from './DrawTabsNav/DrawTabsNavContent';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { StyledPolygonIcon, StyledCommentsIcon } from './../Styled/styled';
import dragVideo from '../../../assets/videos/dragTool.webm'
import { useAppDispatch, useAppSelector } from './../../../hooks/redux';
import { drawTabsSlice } from './../store/DrawTabsReducer';
import { useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import Input from '../../../UI/Input/Input';
import DrawLabel from './DrawLabel';
import Loader from '../../../UI/Loader/Loader';
import { getAnnotsInfo, setApiLabels, resizedCords,
         getElementAtPosition, adjustElementCoordinates, cursorForPosition, 
         getPolygonPointsFromPolygonLines, checkPolygonPointsOnImageRect, 
         getPolygonNearPoint, setPolygonsCords, CreateElement, UpdateElement, 
         // eslint-disable-next-line @typescript-eslint/no-unused-vars
         checkPointsOnPointsRect, setPointsNumbers, sortElementsWithPoints, drawSelectedElemDragPoints, blackoutPolygon, drawBlackoutPolygon, distance, getImagePath} from '../Utils/DrawMethods';
import { drawLoaderSlice } from '../store/DrawLoaderSlice';
import DrawLoader from './DrawLoader/DrawLoader';
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer';
import AutoMarkupSlideForm from './AutoMarkupSlideForm/AutoMarkupSlideForm';
import { autoMarkupSlice } from '../store/AutoMarkupSlice';
import { useTranslation } from 'react-i18next';
import { getToolsRenderCondition } from '../Utils/AutoMarkupHelpers';
import { imagesListSlice } from '../../ImagesList/store/ImagesListReducer';
import HotKeys from './Hotkeys/HotKeys';

export interface IRouhgElement {
  shape: string;
  sets: any;
  options: any;
}

export interface ICanvasElement {
  offsetX?: number;
  offsetY?: number;
  id: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  label: string;
  position?: any;
  type: string;
  roughElement: IRouhgElement;
  offsetY2?: number;
  offsetX2?: number;
  labelId?: string;
  labelColor?: string;
  polygonPoints?: number[][]
  offsetClientXOnDown?: number;
  offsetClientYOnDown?: number;
  selectedPointIndex?: number;
  pointNumber?: number;
  points?: ICanvasElement[];
  pointParentId?: number | string;
  elementPointsOffsets?: any;

  name?: string | null;
  classId?: string | null;
  model_id?: string | null;
  automarkup?: boolean | null;
  success?: boolean | null;
  info?: string | null;
  prediction_id?: string | null;
}

const useHistory = (initialState: any) => {
  const [index, setIndex] = useState<number>(0)
  const [history, setHistory] = useState<any[]>([initialState])

  const setState = (action:any, overwrite = false) => {
    const newState = typeof action === "function" ? action(history[index]) : action;
    if (overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    } else {
      
      const updatedState = [...history].slice(0, index + 1);
      const historyCopy = [...newState]
      for(let i = 0; i < historyCopy.length; i++) {
        historyCopy[i].id = i
      }
      setHistory([...updatedState, historyCopy])
      setIndex(prevState => prevState + 1);
    }
  }

    const setOnloadState = (action:any, overwrite = false) => {
    const newState = typeof action === "function" ? action(history[index]) : action;
    if (overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    } else {
      setIndex(0);
      const historyCopy = [...newState]
      for(let i = 0; i < historyCopy.length; i++) {
        historyCopy[i].id = i
      }
      setHistory([historyCopy])
    }
  }

  const undo = () => index > 0 && setIndex(prevState => prevState - 1);
  const redo = () => index < history.length - 1 && setIndex(prevState => prevState + 1);

  return [history[index], setState, undo, redo, setOnloadState]
}

export const DrawMain = () => {  
  const  { t } = useTranslation()
  const params = useParams()

  const filename = params.imgName
  const jobId = params.jobId
  const projectId = params.id

  const dispatch = useAppDispatch()
  const labelVisible = useAppSelector(state => state.DrawTabsReducer.labelVisible)
  const labels = useAppSelector(state => state.DrawTabsReducer.labels)
  const roleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const currentImageIndex = useAppSelector(state => state.DrawTabsReducer.currentImageIndex)
  const openDrawAction = useAppSelector(state => state.DrawTabsReducer.action)
  const isAutoMarkupsLoading = useAppSelector(state => state.AutoMarkupReducer.isLoading)
  const loaderVisible = useAppSelector(state => state.drawLoaderReducer.visible)
  const viewMode = useAppSelector(state => state.TabsReducer.viewMode)
  const currentProject = useAppSelector(state => state.WorkspacesReducer.projectInfo)
  const [elements, setElements, undo, redo, setOnloadState] = useHistory([])
  const isGuestViewMode = useAppSelector(state => state.DrawTabsReducer.guestViewMode)
  const currentUser = useAppSelector(state => state.userReduser.user)
  const currentTask = useAppSelector(state => state.tasksReducer.currentTask)
  const imageInfo = useAppSelector(state => state.DrawTabsReducer.imageInfo)

  const imagesFromList = useAppSelector((state) => state.ImagesListReducer.images)
  const { selectedImages, currentPageName, selectedSplit } = useAppSelector((state) => state.ImagesListReducer)

  const [projectType, setProjectType] = useState('')

  //Dashed Lines
  const [lines, setLines] = useState<any>([])

  const [action, setAction] = useState<string>('none')
  const [tool, setTool] = useState<string>('selection')
  const [selectedElement, setSelectedElement] = useState<ICanvasElement | null>(null)
  const [tooltipVisible, setTooltipVisible] = useState('')

  const [imagePath, setImagePath] = useState('')
  const [inputValue, setInputValue] = useState(labels.length? labels[0].name : '')

  const [translatePosX, setTranslatePosX] = useState(0)
  const [translatePosY, setTranslatePosY] = useState(0)

  const [startDragOffsetX, setStartDragOffsetX] = useState(0)
  const [startDragOffsetY, setStartDragOffsetY] = useState(0)

  const [scale, setScale] = useState(1.0)

  const [scaleMultiplier, setScaleMultiplier] = useState(0.8)
  const [mouseDown, setMouseDown] = useState(false)

  const [userPercents, setUserPercents] = useState(100)

  const [currentLabelId, setCurrentLabelId] = useState(labels.length? labels[0].id : '')
  const [currentLabelColor, setCurrentLabelColor] = useState(labels.length? labels[0].color : '')
  const [currentLabelName, setCurrentLabelName] = useState(labels.length? labels[0].name : '')

  const [previousTool, setPreviousTool] = useState('')

  //PolygonStates
  const [polygonLines, setPolygonLines] = useState<ICanvasElement[]>([])
  const [polygonCursorPosition, setPolygonCursorPosition] = useState('')
  
  //Moderation
  const [moderate, setModerate] = useState('')
  const [imageStatus, setImageStatus] = useState<string | null | undefined>(null)
  const [cleared, setCleared] = useState(false)

  //dragPoints and Blackout
  const [selectedElementDragPoints, setSelectedElementDragPoints] = useState<any[]>([])
  const [blackoutPolygon, setBlackoutPolygon] = useState<blackoutPolygon | null>(null)

  useEffect(() => {
    if (roleNumber === 5 || viewMode.value === 'MODER') {
      // dispatch(drawTabsSlice.actions.setGuestViewMode(true))
      if ((currentTask?.task?.moderator?.id === currentUser?.id) || (roleNumber >= 4 || viewMode.value === 'MODER')) {
        dispatch(drawTabsSlice.actions.setModerPanelActivity(true))
      }
      onSpaceKeyClick()
      return
    }
    if (roleNumber === 7) {
      dispatch(drawTabsSlice.actions.setGuestViewMode(true))
      onSpaceKeyClick()
      return
    }
    if (openDrawAction !== 'selectedImages') {
      dispatch(imagesListSlice.actions.deselectAllImages())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Необходимо для ограничения функционала рисовалки в зависимости от типа проектка
  useEffect(() => {
    if (!projectType) {
      if (currentProject?.objectType) {
        setProjectType(currentProject.objectType)
      }
    }
  },[ currentProject, projectType])

  // console.log('currentProject', currentProject)

  // const getInfo = async () => {
  //   draw(scale, translatePosX, translatePosY, ' ');
  //   dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(false))
  //   const canvas = document.getElementById('canvas') as HTMLCanvasElement;
  //   const imageToCanvas = document.getElementById('someImg') as HTMLImageElement
  //   setSelectedElementDragPoints([])
  //   try {
  //     if (params.id && params.jobId && params.imgName) {
  //       setIsSlide(false)
  //       dispatch(drawLoaderSlice.actions.setChildren(<Loader/>))
  //       dispatch(drawLoaderSlice.actions.setVisibleTrue())

  //       const imageInfo = await getAnnotsInfo(params.imgName)
  //       if (imageInfo) {
  //         dispatch(drawTabsSlice.actions.setImageInfo(imageInfo))
  //       }

  //       const path = await getImagePath(openDrawAction, imagesFromList, selectedImages, params.imgName)
  //       draw(scale, translatePosX, translatePosY, path);
  //       setImageStatus(imageInfo?.image?.status || imageInfo?.image?.subStatus)

  //       if (path) {
  //         imageToCanvas.src = path
  //         setImagePath(path)
  //       }

  //       setTranslatePosX(canvas.width/2)
  //       setTranslatePosY(canvas.height/2)

  //       if (imageInfo) {
  //         let imageWidth = imageInfo?.image?.width
  //         let imageHeight = imageInfo?.image?.height
          
  //         let widthToScale = imageInfo?.image?.width
  //         let heightToScale = imageInfo?.image?.height
  //         let userPercents = 100
  //         let getInfoScale = 1.0

  //         const forDraw = await setApiLabels(elements, imageWidth, imageHeight, imageInfo)
          
  //         const imageRec = CreateElement.simpleElement(
  //           0, -imageWidth/2, -imageHeight/2, imageWidth/2, imageHeight/2, 'imageRec', 'imageRec', 'noLabel', 'noLabelColor', 
  //           null, null, null, null, null, null, null
  //         )
  //         if (imageHeight && imageWidth) {
  //           await setOnloadState([imageRec, ...forDraw])
  //           setScale(1.0)
  //           setUserPercents(100)
  //           while(widthToScale > canvas.width - 200 || heightToScale > canvas.height - 20) {
  //             setScale(getInfoScale * 0.8)
  //             setUserPercents(userPercents - 10)
  //             widthToScale = widthToScale * scaleMultiplier
  //             heightToScale = heightToScale * scaleMultiplier
  //             getInfoScale = getInfoScale * 0.8
  //             userPercents = userPercents - 10
  //           }
  //           while(widthToScale < canvas.width - 500 && heightToScale < canvas.height - 500) {
  //             setScale(getInfoScale / 0.8)
  //             setUserPercents(userPercents + 10)
  //             widthToScale = widthToScale / scaleMultiplier
  //             heightToScale = heightToScale / scaleMultiplier
  //             getInfoScale = getInfoScale / 0.8
  //             userPercents = userPercents + 10
  //           }
  //         }
  //         dispatch(drawLoaderSlice.actions.setVisibleFalse())
  //         if (!isLoading && loaderVisible) {
  //           setIsSlide(true)
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const getInfo = async () => {
    dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(false))
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const imageToCanvas = document.getElementById('someImg') as HTMLImageElement
    setSelectedElementDragPoints([])
    setLines([])

    try {
      if (params.id && params.jobId && params.imgName) {
        dispatch(drawLoaderSlice.actions.setVisibleTrue()) 

        imageToCanvas.src = ''
        draw(scale, translatePosX, translatePosY);


        setTranslatePosX(canvas.width/2)
        setTranslatePosY(canvas.height/2)

        const imageInfo = await getAnnotsInfo(params.imgName)
        if (imageInfo) {
          dispatch(drawTabsSlice.actions.setImageInfo(imageInfo))

          const path = await getImagePath(openDrawAction, imagesFromList, selectedImages, params.imgName)
          draw(scale, translatePosX, translatePosY, path);
          setImageStatus(imageInfo?.image?.status || imageInfo?.image?.subStatus)


          if (path) {
            setImagePath(path)

            let imageWidth = imageInfo?.image?.width
            let imageHeight = imageInfo?.image?.height
            
            let widthToScale = imageInfo?.image?.width
            let heightToScale = imageInfo?.image?.height
            let userPercents = 100
            let getInfoScale = 1.0
  
            const forDraw = await setApiLabels(elements, imageWidth, imageHeight, imageInfo)
            
            const imageRec = CreateElement.simpleElement(
              0, -imageWidth/2, -imageHeight/2, imageWidth/2, imageHeight/2, 'imageRec', 'imageRec', 'noLabel', 'noLabelColor', 
              null, null, null, null, null, null, null
            )
            if (imageHeight && imageWidth) {
              await setOnloadState([imageRec, ...forDraw])
              setScale(1.0)
              setUserPercents(100)
              while(widthToScale > canvas.width - 200 || heightToScale > canvas.height - 20) {
                setScale(getInfoScale * 0.8)
                setUserPercents(userPercents - 10)
                widthToScale = widthToScale * scaleMultiplier
                heightToScale = heightToScale * scaleMultiplier
                getInfoScale = getInfoScale * 0.8
                userPercents = userPercents - 10
              }
              while(widthToScale < canvas.width - 500 && heightToScale < canvas.height - 500) {
                setScale(getInfoScale / 0.8)
                setUserPercents(userPercents + 10)
                widthToScale = widthToScale / scaleMultiplier
                heightToScale = heightToScale / scaleMultiplier
                getInfoScale = getInfoScale / 0.8
                userPercents = userPercents + 10
              }
            }

            const img = new Image();
            img.src = path + "?" + new Date().getTime()
            img.onload = function() {
              // drawOnFirstLoad(getInfoScale, canvas.width/2, canvas.height/2, img)
              setImagePath(path)
              dispatch(drawLoaderSlice.actions.setVisibleFalse())
            };
            draw(scale, canvas.width/2, canvas.height/2, path + "?" + new Date().getTime())
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  const onTooltipMouseOver = (e: React.MouseEvent<HTMLDivElement>, tool: string) => {
    e.stopPropagation()
    setTooltipVisible(tool)
  }

  const onTooltipMouseLeave = () => {
    setTooltipVisible('')
  }

  function draw(scale:any, translatePosX:number, translatePosY:number, imagePath?: string) {
    const imageToCanvas = document.getElementById('someImg') as HTMLImageElement
    if (imagePath) {
      imageToCanvas.src = imagePath
    } 
    if (!imagePath) {
      return
    }

    if (imageToCanvas) {
      imageToCanvas.onload = () => {
        let naturalImageWidth = imageToCanvas.naturalWidth
        let naturalImageHeight = imageToCanvas.naturalHeight
        const canvas = document.getElementById('canvas') as HTMLCanvasElement;
        const context = canvas.getContext('2d');
        const rc = rough.canvas(canvas)

        if (context) {
          if (elements?.length) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.save();
            context.translate(translatePosX, translatePosY);
            context.scale(scale, scale);
            context.drawImage(imageToCanvas, -naturalImageWidth/2, -naturalImageHeight/2, naturalImageWidth, naturalImageHeight)
            elements.forEach((element: any) => {
              rc.draw(element.roughElement)
              if (element.type === 'circle') {
                context.textAlign = 'center'
                context.textBaseline = 'middle'
                context.strokeStyle = 'black';
                context.lineWidth = 1;
                context.fillStyle = element.labelColor
                if (element.pointNumber === 0) {
                  context.font = 'bold 10px sans-serif'
                  context.strokeText(element.pointNumber, element.x1, element.y1 + 0.5)
                  context.fillText(element.pointNumber, element.x1, element.y1 + 0.5)
                }
                if (element.pointNumber && element.pointNumber < 10 ) {
                  context.font = 'bold 10px sans-serif'
                  context.strokeText(element.pointNumber, element.x1, element.y1 + 0.5)
                  context.fillText(element.pointNumber, element.x1, element.y1 + 0.5)
                }
                if (element.pointNumber && element.pointNumber > 9 && element.pointNumber < 100 ) {
                  context.font = 'bold 8px sans-serif'
                  context.strokeText(element.pointNumber, element.x1, element.y1 + 0.5)
                  context.fillText(element.pointNumber, element.x1, element.y1 + 0.5)
                }
                if (element.pointNumber && element.pointNumber > 99 && element.pointNumber < 1000 ) {
                  context.font = 'bold 6px sans-serif'
                  context.strokeText(element.pointNumber, element.x1, element.y1 + 0.5)
                  context.fillText(element.pointNumber, element.x1, element.y1 + 0.5)
                }
              }
            })
            lines.forEach(({roughElement}: any) => rc.draw(roughElement))
            selectedElementDragPoints?.forEach(({roughElement}: any) => rc.draw(roughElement))
            polygonLines.forEach(({roughElement}: any) => rc.draw(roughElement))
            if (blackoutPolygon?.roughElement) {
              rc.draw(blackoutPolygon.roughElement)
            }
            context.restore();
          }
        }
      }
    }
  }

  useLayoutEffect(() => {
    draw(scale, translatePosX, translatePosY, imagePath);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, imagePath, translatePosX, translatePosY, scale, currentImageIndex, lines, selectedElementDragPoints, blackoutPolygon])

  const onWKeyClick = () => {
    const labelsCopy = [...labels]
    const findedLabel = labelsCopy.find(label => label.id === currentLabelId)
    
    if (!labelVisible) {
      dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
      setInputValue(labelsCopy[0].name)
      setCurrentLabelId(labelsCopy[0].id)
      setCurrentLabelName(labelsCopy[0].name)
      setCurrentLabelColor(labelsCopy[0].color)
    }

    if (findedLabel && labelVisible) {
      const currentLabelIndex = labelsCopy.indexOf(findedLabel)
      if (labelsCopy[currentLabelIndex - 1]) {
        setInputValue(labelsCopy[currentLabelIndex - 1].name)
        setCurrentLabelId(labelsCopy[currentLabelIndex - 1].id)
        setCurrentLabelName(labelsCopy[currentLabelIndex - 1].name)
        setCurrentLabelColor(labelsCopy[currentLabelIndex - 1].color)
      }
      if (!labelsCopy[currentLabelIndex - 1]) {
        setInputValue(labelsCopy[labelsCopy.length - 1].name)
        setCurrentLabelId(labelsCopy[labelsCopy.length - 1].id)
        setCurrentLabelName(labelsCopy[labelsCopy.length - 1].name)
        setCurrentLabelColor(labelsCopy[labelsCopy.length - 1].color)
      }
    }  
  }

  const onSKeyClick = () => {
    const labelsCopy = [...labels]
    const findedLabel = labelsCopy.find(label => label.id === currentLabelId)
    
    if (!labelVisible) {
      dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
      setInputValue(labelsCopy[0].name)
      setCurrentLabelId(labelsCopy[0].id)
      setCurrentLabelName(labelsCopy[0].name)
      setCurrentLabelColor(labelsCopy[0].color)
    }

    if (findedLabel && labelVisible) {
      const currentLabelIndex = labelsCopy.indexOf(findedLabel)
      if (labelsCopy[currentLabelIndex + 1]) {
        setInputValue(labelsCopy[currentLabelIndex + 1].name)
        setCurrentLabelId(labelsCopy[currentLabelIndex + 1].id)
        setCurrentLabelName(labelsCopy[currentLabelIndex + 1].name)
        setCurrentLabelColor(labelsCopy[currentLabelIndex + 1].color)
      }
      if (!labelsCopy[currentLabelIndex + 1]) {
        setInputValue(labelsCopy[0].name)
        setCurrentLabelId(labelsCopy[0].id)
        setCurrentLabelName(labelsCopy[0].name)
        setCurrentLabelColor(labelsCopy[0].color)
      }
    }  
  }

  const onSpaceKeyClick = () => {
    if (!previousTool) {
      const drawLayout = document.getElementById('canvas')
      
      if (drawLayout) {
        drawLayout.style.cursor = 'grab'
      }
      setPreviousTool(tool)
      setTool('selection')

      if (action === 'drawing') {
        setAction('none')
        onDeleteClick('spaceClick')
      }
    }
  }

  const onSpaceKeyUp = () => {
    const drawLayout = document.getElementById('canvas')
    if (drawLayout) {
      drawLayout.style.cursor = 'default'
    }

    setPreviousTool('')
    setTool(previousTool)
  }

  useEffect(() => {
    let canvas = document.getElementById('canvas')

    const keydownActionsFunc = (e: any) => {
      if (e.shiftKey && (e.metaKey || e.ctrlKey)) {
        onRedoClick()
      } 
      if ((e.metaKey || e.ctrlKey) && (e.key === "z" || e.key === "Z" || e.key === "я" || e.key === "Я")) {
        onOndoClick()
      }
      if (e.key === 'w' || e.key === 'ц' || e.key === 'W' || e.key === 'Ц') {
        onWKeyClick()
      }
      if (e.key === 's' || e.key === 'ы' || e.key === 'S' || e.key === 'Ы') {
        onSKeyClick()
      }
      if (e.keyCode === '32' || e.key === ' ') {
        onSpaceKeyClick()
      }
      if (e.keyCode === 13 || e.key === 'enter') {
        if (viewMode.value === 'MODER') {
          onModerateClick('approve')
        }
      }
      if (roleNumber < 6 && !isGuestViewMode) {
        if (e.key === '1') {
          setTool('selection')
        }
        if (e.key === '2') {
          setTool('rectangle')
        }
        if (e.key === '3') {
          setTool('polygon')
        }
        if (e.key === '4') {
          setTool('circle')
        }
        if (e.key === 'Backspace' || e.key === 'Delete') {
          onDeleteClick('')
        }
      }
    }
    
    const keyupActionsFunc = (e: any) => {
      if (e.keyCode === '32' || e.key === ' ') {
        if (!isGuestViewMode && viewMode.value !== 'MODER') {
          onSpaceKeyUp()
        }
      }
    }

    const addOnWheel = ((e : any) => {
      const delta = e.deltaY || e.detail || e.wheelDelta;
  
      if (delta > 0) onMinusClick() 
      else onPlusClick()
  
      // отменим прокрутку
      e.preventDefault();
    });

    document.addEventListener('keydown', keydownActionsFunc)
    document.addEventListener('keyup', keyupActionsFunc)
    document.addEventListener('keydown', keydownActionsFunc)
    if (canvas) {
      canvas.addEventListener("wheel", addOnWheel, {passive: false});
      canvas.addEventListener("mousewheel", addOnWheel, {passive: false});
      canvas.addEventListener("MozMousePixelScroll", addOnWheel, {passive: false});
    }

    return () => {
      document.removeEventListener('keydown', keydownActionsFunc)
      document.removeEventListener('keydown', keydownActionsFunc)
      document.removeEventListener('keyup', keyupActionsFunc)
      if (canvas) {
        canvas.removeEventListener("wheel", addOnWheel);
        canvas.removeEventListener("mousewheel", addOnWheel);
        canvas.removeEventListener("MozMousePixelScroll", addOnWheel);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undo, redo])

  useEffect(() => {
    getInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentImageIndex, params.imgName])

  //Клавиша пробел* или режим просмотра
  useEffect(() => {
    if (isGuestViewMode) {
      const drawLayout = document.getElementById('canvas')
      
      if (drawLayout) {
        drawLayout.style.cursor = 'grab'
      }
      setPreviousTool(tool)
      setTool('selection')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGuestViewMode])

  //Нажатие на левую кнопку
  const handleMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setSelectedElementDragPoints([])
    const {clientX, clientY} = event
    const offsetClientX = (clientX - 305 - translatePosX) /scale
    const offsetClientY = (clientY - 55  - translatePosY) /scale

    const id = elements?.length
    const labelName = currentLabelName? currentLabelName : labels[0].name
    const labelColor = currentLabelColor? currentLabelColor : labels[0].color
    const labelId = currentLabelId? currentLabelId : labels[0].id
    const isOnImage = getElementAtPosition(offsetClientX, offsetClientY, [elements[0]])

    //Начало рисования полигона, линии полигона.
    if (tool === 'polygon') {
      const line_id = polygonLines.length
      const element = CreateElement.simpleElement(line_id, offsetClientX, offsetClientY, offsetClientX, offsetClientY, 'line', labelName, labelId, labelColor)
      if (isOnImage && !polygonCursorPosition && element) {
        setPolygonLines((prevState: any) => [...prevState, element])
        setSelectedElement(element)
        setAction('drawing')
        dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
      }
    }
    //Создание полигона
    if (tool === 'polygon' && polygonCursorPosition && polygonLines?.length > 2) {
      (event.target as HTMLCanvasElement).style.cursor = 'default'
      const polygonPoints = getPolygonPointsFromPolygonLines(polygonLines)
      const element = CreateElement.polygon(id, offsetClientX, offsetClientY, offsetClientX, offsetClientY, tool, labelName, labelId, labelColor, polygonPoints)
      setAction('none')
      setPolygonLines([])
      setPolygonCursorPosition('')
      setElements((prevState: any) => [...prevState, element])
      setSelectedElement(element)
    }

    //Инструмент точка
    if (tool === 'circle') {
      const elementsWithinFirst = elements.filter((elem: any) => elem.id !== 0)
      const pointsFeldElement = getElementAtPosition(offsetClientX, offsetClientY, elementsWithinFirst)

      //Рисование точки, курсор внутри pointsRect
      if (pointsFeldElement && pointsFeldElement.type === 'pointsRect' && pointsFeldElement.position === 'inside') {
        const element = CreateElement.point(id, offsetClientX, offsetClientY, tool, pointsFeldElement.label, pointsFeldElement.labelId, pointsFeldElement.labelColor, pointsFeldElement.id, '', elements)
        if (element) {
          setElements((prevState: any) => [...prevState, element])
          setSelectedElement(element)
          setAction('none')
        }
      } 

      //Начало рисования pointsRect, курсор не внутри pointsRect
      if (!pointsFeldElement) {
        const element = CreateElement.simpleElement(id, offsetClientX, offsetClientY, offsetClientX, offsetClientY, 'pointsRect', labelName, labelId, labelColor)
        setElements((prevState: any) => [...prevState, element])
        setAction('drawing')
        if (element?.id) {
          setSelectedElement(element)
        }
      }
    }

    //Начало рисования прямоугольника
    if (tool === 'rectangle') {
      const id = elements.length
      const labelName = currentLabelName? currentLabelName : labels[0].name
      const labelColor = currentLabelColor? currentLabelColor : labels[0].color
      const labelId = currentLabelId? currentLabelId : labels[0].id
      const element = CreateElement.simpleElement(id, offsetClientX, offsetClientY, offsetClientX, offsetClientY, tool, labelName, labelId, labelColor)
      if (isOnImage && element) {
        setElements((prevState: any) => [...prevState, element])
        setSelectedElement(element)
        setAction('drawing')
        dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
      }
    }

    if (previousTool) {
      setMouseDown(true)
      setStartDragOffsetX(event.clientX - translatePosX)
      setStartDragOffsetY(event.clientY - translatePosY)
     }

    if(tool === 'selection' && !previousTool) {
       const elementsWithinFirst = elements.filter((elem: any) => elem.id !== 0)
       const element = getElementAtPosition(offsetClientX, offsetClientY, elementsWithinFirst)
       const elementPoints = elements.filter((elem:any) => elem.pointParentId === element?.id)
       const pointInsideElement = getElementAtPosition(offsetClientX, offsetClientY, elementPoints)

       //point select
       if (element && pointInsideElement && pointInsideElement.labelColor && pointInsideElement.labelId ) {
        const offsetX = offsetClientX - element.x1
        const offsetY = offsetClientY - element.y1
        const offsetX2 = offsetClientX - element.x2
        const offsetY2 = offsetClientY - element.y2
        setAction('moving')
        setInputValue(pointInsideElement.label)
        setCurrentLabelId(pointInsideElement.labelId)
        setCurrentLabelName(pointInsideElement.label)
        setCurrentLabelColor(pointInsideElement.labelColor)
        dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
        setSelectedElement({...pointInsideElement, offsetX, offsetX2, offsetY, offsetY2, offsetClientXOnDown: offsetClientX, offsetClientYOnDown: offsetClientY})
       }

       if (element && !pointInsideElement) {        
        const offsetX = offsetClientX - element.x1
        const offsetY = offsetClientY - element.y1
        const offsetX2 = offsetClientX - element.x2
        const offsetY2 = offsetClientY - element.y2

        const elementPointsOffsets = elementPoints.map((point:any) => {
          return {id: point.id, pointParentId: point.pointParentId, offsetX: offsetClientX - point.x1, offsetY: offsetClientY - point.y1}
        })

        if (element.labelColor && element.labelId) {
          if (element.type === 'polygon') {
            const selectedPolygonPoint = getPolygonNearPoint(element.polygonPoints, offsetClientX, offsetClientY)
            setSelectedElement({...element, offsetX, offsetX2, offsetY, offsetY2, offsetClientXOnDown: offsetClientX, offsetClientYOnDown: offsetClientY, selectedPointIndex: selectedPolygonPoint?.index ? selectedPolygonPoint.index : 0})
            dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
          } else {
            setSelectedElement({...element, offsetX, offsetX2, offsetY, offsetY2, offsetClientXOnDown: offsetClientX, offsetClientYOnDown: offsetClientY, elementPointsOffsets})
            dispatch(drawTabsSlice.actions.setLabelVisibleTrue())
          }
          setInputValue(element.label)
          setCurrentLabelName(element.label)
          setCurrentLabelColor(element.labelColor)
          setCurrentLabelId(element.labelId)
        }
        if (element?.position === "inside") {
          setAction('moving')
        } else {
          setAction('resize')
        }
       }
       if (!element) {
        setMouseDown(true)
        setStartDragOffsetX(event.clientX - translatePosX)
        setStartDragOffsetY(event.clientY - translatePosY)
       }
    }     
  }
  
  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (blackoutPolygon?.polygonPoints?.length) {
      setBlackoutPolygon(null)
    }

    if (!loaderVisible) {
      const {clientX, clientY} = event
      const offsetClientX = (clientX - 305 - translatePosX) / scale
      const offsetClientY = (clientY - 55 - translatePosY) / scale
  
      const lineOffsetX = (clientX - 305 - translatePosX) / scale
      const lineOffsetY = (clientY - 55 - translatePosY) / scale

      const labelColor = currentLabelColor? currentLabelColor : labels[0].color
      const labelId = currentLabelId? currentLabelId : labels[0].id
      
      //Dashed lines
      if (!lines?.length) {
        const lineHorizontal = CreateElement.simpleElement(1, lineOffsetX-10000, lineOffsetY, lineOffsetX+10000, lineOffsetY, 'lineDashed', 'line')
        const lineVertical = CreateElement.simpleElement(0, lineOffsetX, lineOffsetY+10000, lineOffsetX, lineOffsetY-10000, 'lineDashed', 'line')
        setLines([lineHorizontal, lineVertical])
      }
      if (lines?.length) {
        if (offsetClientX-10000 === lines[0].x1) {
          const lineHorizontal = CreateElement.simpleElement(1, lineOffsetX-10000, lineOffsetY, lineOffsetX+10000, lineOffsetY, 'lineDashed', 'line')
          const lineVertical = CreateElement.simpleElement(0, lines[1].x1, lines[1].y1, lines[1].x2, lines[1].y2, 'lineDashed', 'line')
          setLines([lineHorizontal, lineVertical])
        }
        if (offsetClientY+10000 === lines[1].y1) {
          const lineHorizontal = CreateElement.simpleElement(1, lines[0].x1, lines[0].y1, lines[0].x2, lines[0].y2, 'lineDashed', 'line')
          const lineVertical = CreateElement.simpleElement(0, lineOffsetX, lineOffsetY+10000, lineOffsetX, lineOffsetY-10000, 'lineDashed', 'line')
          setLines([lineHorizontal, lineVertical])
        }
        if (offsetClientY+10000 !== lines[1].y1 && offsetClientX-10000 !== lines[0].x1) {
          const lineHorizontal = CreateElement.simpleElement(1, lineOffsetX-10000, lineOffsetY, lineOffsetX+10000, lineOffsetY, 'lineDashed', 'line')
          const lineVertical = CreateElement.simpleElement(0, lineOffsetX, lineOffsetY+10000, lineOffsetX, lineOffsetY-10000, 'lineDashed', 'line')
          setLines([lineHorizontal, lineVertical])
        }
      }
  
      if (elements?.length) {
        const isOnImage = getElementAtPosition(offsetClientX, offsetClientY, [elements[0]])

        if (isOnImage) {
          if (action === 'drawing') {
            if (tool === 'circle' || tool === 'rectangle') {
              const index = elements.length - 1
              if (elements[index]) {
                const {x1, y1, label} = elements[index]
                UpdateElement.simple(elements, setElements, index, x1, y1, offsetClientX, offsetClientY, tool === 'circle' ? 'pointsRect' : 'rectangle', label, labelId, labelColor)
              }
            }
  
            //Полигон: Во время рисования 
            if (tool === 'polygon') {
              const index = polygonLines.length - 1
              if (polygonLines[index]) {
                const {x1, y1, label} = polygonLines[index]
                const isOnFirstLinePoint = getElementAtPosition(offsetClientX, offsetClientY, [polygonLines[0]])
                const isOnLastLinePoint = getElementAtPosition(offsetClientX, offsetClientY, [polygonLines[polygonLines?.length - 1]])
                UpdateElement.polygonLines(polygonLines, setPolygonLines, index, x1, y1, offsetClientX, offsetClientY, 'line', label, labelId, labelColor)
                
                if (polygonLines?.length > 2 && isOnFirstLinePoint?.position === 'start') {
                  (event.target as HTMLCanvasElement).style.cursor = 'pointer'
                  setPolygonCursorPosition(isOnFirstLinePoint?.position)
                }
                if (polygonLines?.length > 2 && isOnLastLinePoint?.position === 'start') {
                  (event.target as HTMLCanvasElement).style.cursor = 'pointer'
                  setPolygonCursorPosition(isOnLastLinePoint?.position)
                }
  
                if (isOnFirstLinePoint?.position !== 'start' && !isOnLastLinePoint) {
                  (event.target as HTMLCanvasElement).style.cursor = 'default'
                  setPolygonCursorPosition('')
                }
                if (isOnLastLinePoint?.position !== 'start' && !isOnFirstLinePoint) {
                  (event.target as HTMLCanvasElement).style.cursor = 'default'
                  setPolygonCursorPosition('')
                }
              }
            }
          }
          // console.log(selectedElement, action)
          if (action === 'moving') {
            
            if (selectedElement && selectedElement.offsetX && selectedElement.offsetX2 && selectedElement.offsetY && selectedElement.offsetY2) {
              const {
                id, x1, x2, y1, y2, elementPointsOffsets, polygonPoints, pointNumber, pointParentId, offsetX, offsetX2, 
                offsetY, offsetY2, type, label, labelId, labelColor, offsetClientXOnDown, offsetClientYOnDown,
                name, classId, model_id, automarkup, success, info, prediction_id
              } = selectedElement
              const width = x2-x1
              const height = y2-y1
              const cursorOffsetX = offsetClientX - offsetX
              const cursorOffsetY = offsetClientY - offsetY
              const cursorOffsetX2 = offsetClientX - offsetX2
              const cursorOffsetY2 = offsetClientY - offsetY2
              const isCursorOnImageLeftTop = getElementAtPosition(cursorOffsetX, cursorOffsetY, [elements[0]])
              const isCursorOnImageRightBottom = getElementAtPosition(cursorOffsetX2, cursorOffsetY2, [elements[0]])
              if (isCursorOnImageLeftTop && isCursorOnImageRightBottom) {
                if (type === 'rectangle') {
                  UpdateElement.simple(
                    elements, setElements, id, cursorOffsetX, cursorOffsetY, cursorOffsetX + width, cursorOffsetY + height, type, label, labelId, labelColor, false,
                    name, classId, model_id, automarkup, success, info, prediction_id
                  )
                }
                if (type === 'pointsRect' && offsetClientYOnDown && offsetClientXOnDown) {
                  const elementPoints = elements.filter((elem:any) => elem.pointParentId === id)
                  const updatedElement = CreateElement.simpleElement(
                    id, cursorOffsetX, cursorOffsetY, cursorOffsetX + width, cursorOffsetY + height, type, label, currentLabelId, currentLabelColor, 
                    name, classId, model_id, automarkup, success, info, prediction_id
                  )
                  const elementsCopy = [...elements]
                  elementsCopy[id] = updatedElement
                  // console.log('elementPointsOffsets', elementPointsOffsets)

                  elementPoints.forEach((point: any) => {
                    const findedOffset = elementPointsOffsets.find((offset:any) => offset.id === point.id)
                    if (findedOffset) {
                      const cursorOffsetX = offsetClientX - findedOffset.offsetX
                      const cursorOffsetY = offsetClientY - findedOffset.offsetY
                      // console.log(point)
                      const updatedPoint = CreateElement.point(
                        point.id, cursorOffsetX, cursorOffsetY, 'circle', point.label, point.labelId, point.labelColor, point.pointParentId, point.pointNumber, elements,
                        point?.name, point?.classId, point?.model_id, point?.automarkup, point?.success, point?.info, point?.prediction_id
                      )
                      elementsCopy[point.id] = updatedPoint
                    }
                  })

                  // const isEveryPointInsideRect = checkPointsOnPointsRect(elementPoints, [updatedElement])
                  // console.log('isEveryPointInsideRect', isEveryPointInsideRect)
                  // if (isEveryPointInsideRect && elementPoints?.length) {
                  //   setElements(elementsCopy, true)
                  // }
                  // if (!isEveryPointInsideRect && !elementPoints?.length) {
                  //   setElements(elementsCopy, true)
                  // }

                  setElements(elementsCopy, true)
                }
                if (type === 'circle' && pointParentId && offsetClientYOnDown && offsetClientXOnDown) {
                  const clientCursorOffsetX = offsetClientX - offsetClientXOnDown + x1
                  const clientCursorOffsetY = offsetClientY - offsetClientYOnDown + y1
                  const pointParent = elements.find((elem:any) => elem.id === pointParentId)
                  const elementUnderCursor = getElementAtPosition(clientCursorOffsetX, clientCursorOffsetY, [pointParent])
                  if (elementUnderCursor && elementUnderCursor.position === 'inside') {
                    UpdateElement.point(
                      elements, setElements, id, clientCursorOffsetX, clientCursorOffsetY, type, label, labelId, labelColor, pointParentId, pointNumber,
                      name, classId, model_id, automarkup, success, info, prediction_id
                    )
                  }
                }
              }
  
              if (type === 'polygon' && offsetClientYOnDown && offsetClientXOnDown) {
                const updatedPolygonPoints: number[][] = []
                polygonPoints?.forEach(point => {
                  const clientCursorOffsetX = offsetClientX - offsetClientXOnDown + point[0]
                  const clientCursorOffsetY = offsetClientY - offsetClientYOnDown + point[1]
                  updatedPolygonPoints.push([clientCursorOffsetX, clientCursorOffsetY])
                })
  
                const isEveryPolygonPointOnImage = checkPolygonPointsOnImageRect(updatedPolygonPoints, [elements[0]])
   
                if (isEveryPolygonPointOnImage && labelId && labelColor) {
                  UpdateElement.polygon(
                    elements, setElements, id, offsetClientX, offsetClientY, cursorOffsetX, cursorOffsetY, type, label, labelId, labelColor, updatedPolygonPoints,
                    name, classId, model_id, automarkup, success, info, prediction_id,
                  )
                }
               
              }          
            }
          }
      
          if (action === "resize") {

            if (selectedElement && selectedElement.position) {
              const {
                id, type, position, label, labelId, labelColor, polygonPoints, selectedPointIndex, 
                name, classId, model_id, automarkup, success, info, prediction_id, ...cords
              } = selectedElement
              if (type === 'rectangle') {
                const resizedPointCords = resizedCords(offsetClientX, offsetClientY, position, cords)
                if (resizedPointCords) {
                  const {x1, y1, x2, y2} = resizedPointCords
                  UpdateElement.simple(
                    elements, setElements, id, x1, y1, x2, y2, type, label, labelId, labelColor, false,
                    name, classId, model_id, automarkup, success, info, prediction_id
                  )
                }
              }

              if (type === 'pointsRect') {
                const resizedPointCords = resizedCords(offsetClientX, offsetClientY, position, cords)
                if (resizedPointCords) {
                  const {x1, y1, x2, y2} = resizedPointCords
                  const elementPoints = elements.filter((elem:any) => elem.pointParentId === id)
                  const updatedElement = CreateElement.simpleElement(
                    id, x1, y1, x2, y2, type, label, currentLabelId, currentLabelColor,
                    name, classId, model_id, automarkup, success, info, prediction_id,
                  )
                  const elementsCopy = [...elements]
                  elementsCopy[id] = updatedElement
                  const isEveryPointInsideRect = checkPointsOnPointsRect(elementPoints, [updatedElement])
                  if (isEveryPointInsideRect && elementPoints?.length) {
                    setElements(elementsCopy, true) 
                  }
                  if (!isEveryPointInsideRect && !elementPoints?.length) {
                    setElements(elementsCopy, true)
                  }
                }
              }
  
              if ((type === 'polygon' && polygonPoints && selectedPointIndex) || (type === 'polygon' && polygonPoints && selectedPointIndex === 0)) {
                const polygonPointsCopy = [...polygonPoints]
                const updatedPointCords = [offsetClientX, offsetClientY]
                polygonPointsCopy.splice(selectedPointIndex, 1, updatedPointCords)
                if (labelId && labelColor) {
                  UpdateElement.polygon(
                    elements, setElements, id, offsetClientX, offsetClientY, offsetClientX, offsetClientY, type, label, labelId, labelColor, polygonPointsCopy,
                    name, classId, model_id, automarkup, success, info, prediction_id
                  )
                }
              }
            }
          }
        }  
      }
      
      if(tool === 'selection') {
        if (mouseDown) {
          setTranslatePosX(event.clientX - startDragOffsetX)
          setTranslatePosY(event.clientY - startDragOffsetY)
          draw(scale, event.clientX - startDragOffsetX, event.clientY - startDragOffsetY);
        }
        const elementsWithinImageRec = elements.filter((elem: any) => elem.type !== 'imageRec')
        const element = getElementAtPosition(offsetClientX, offsetClientY, elementsWithinImageRec)

        if (element && !previousTool) {
          const elementPoints = elements.filter((elem:any) => elem.pointParentId === element.id)
          const pointInsideElement = getElementAtPosition(offsetClientX, offsetClientY, elementPoints)

          if (element?.position && element.type !== 'imageRec') {
            (event.target as HTMLCanvasElement).style.cursor = cursorForPosition(element?.position)
            // drawSelectedElemDragPoints(element, setSelectedElementDragPoints)
          }
          if (pointInsideElement && element.position && pointInsideElement.type === 'circle') {
            (event.target as HTMLCanvasElement).style.cursor = 'pointer'
            // setSelectedElementDragPoints([])
          }
        }

        if (!element && !previousTool) {
          (event.target as HTMLCanvasElement).style.cursor = 'default'
        }
      }

      if (tool === 'circle') {
        const elementsWithinImageRec = elements.filter((elem: any) => elem.type !== 'imageRec')
        const element = getElementAtPosition(offsetClientX, offsetClientY, elementsWithinImageRec)

        if (element && element?.type === 'pointsRect' && element?.position === 'inside') {
          (event.target as HTMLCanvasElement).style.cursor = 'pointer'
        }
        if (element?.position !== 'inside' || element?.type !== 'pointsRect') {
          (event.target as HTMLCanvasElement).style.cursor = 'default'
        }
      }
    }

  }

  const handleMouseUp = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setMouseDown(false)
    if (selectedElement && tool !== 'polygon') {
      const index = selectedElement.id
      if (elements[index] && selectedElement.type !== 'polygon'){
        const {id, type, label, labelId, labelColor, name, classId, model_id, automarkup, success, info, prediction_id, pointParentId, pointNumber} = elements[index]
        if (action === 'drawing' || action === 'resize' || action === 'moving') {
          const {x1, y1, x2, y2} = adjustElementCoordinates(elements[index])
          if (type === 'rectangle' || type === 'pointsRect') {
            const elementArea = (x2-x1) * (y2-y1)
            elementArea < 20
             ? onDeleteClick('littleArea')
             : UpdateElement.simple(
                elements, setElements, id, x1, y1, x2, y2, type, label, labelId, labelColor, false,
                name, classId, model_id, automarkup, success, info, prediction_id,
              )
          }
          if (type === 'polygon') {
            UpdateElement.simple(
              elements, setElements, id, x1, y1, x2, y2, type, label, labelId, labelColor, false,
              name, classId, model_id, automarkup, success, info, prediction_id
            )
          }
          if (type === 'circle') {
            UpdateElement.point(
              elements, setElements, id, elements[index].x1, elements[index].y1, type, label, labelId, labelColor, pointParentId, pointNumber, 
              name, classId, model_id, automarkup, success, info, prediction_id, false
            )
          }
        }
      }
      if (action !== 'none') {
        drawSelectedElemDragPoints(elements[index], setSelectedElementDragPoints)
      }
      setInputValue(selectedElement.label)
      setAction('none')
    }
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setMouseDown(false)
  }

  const handleMouseOut = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setMouseDown(false)
  }

  const onPlusClick = () => {
    setScale(scale/scaleMultiplier)
    draw(scale/scaleMultiplier, translatePosX, translatePosY)
    setUserPercents(userPercents + 10)
  }

  const onMinusClick = () => {
    setScale(scale*scaleMultiplier)
    draw(scale*scaleMultiplier, translatePosX, translatePosY)
    setUserPercents(userPercents - 10)
  }

  const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(drawTabsSlice.actions.setLabel(inputValue))
    setInputValue(e.target.value)
  }

  const onCantelClick = () => {
    setInputValue('')
    dispatch(drawTabsSlice.actions.setLabel(''))
    dispatch(drawTabsSlice.actions.setLabelVisibleFalse())
  }

  const onConfirmClick = () => {
    if (!inputValue) {
      setInputValue('Empty')
      dispatch(drawTabsSlice.actions.setLabel('Empty'))
    }
    if (inputValue && inputValue !== 'NoLabel' && selectedElement) {
      const labelColor = currentLabelColor? currentLabelColor : labels[0].color
      const labelId = currentLabelId? currentLabelId : labels[0].id    
      const {id, x1, y1, x2, y2, type, polygonPoints} = elements[selectedElement.id]
      if (type !== 'polygon') {
        UpdateElement.simple(elements, setElements, id, x1, y1, x2, y2, type, inputValue, labelId, labelColor)
      }
      if (type === 'polygon') {
        UpdateElement.polygon(elements, setElements, id, x1, y1, x2, y2, type, inputValue, labelId, labelColor, polygonPoints)
      }
      
      dispatch(drawTabsSlice.actions.setLabel(''))
      dispatch(drawTabsSlice.actions.setLabelVisibleFalse())
      setSelectedElement(null)
    }
  }

  const onDeleteClick = (action: string) => {
    setInputValue('')
    dispatch(drawTabsSlice.actions.setLabel(''))
    dispatch(drawTabsSlice.actions.setLabelVisibleFalse())
    const elementsCopy = [...elements]
    const filteredElements = elementsCopy.filter((elem: any) => elem.id !== selectedElement?.id)
    if (filteredElements.length === 1) {
      // console.log('filteredElements.length', filteredElements.length)
      setCleared(true)
    }
    if (selectedElement && selectedElement.type === 'rectangle' ||  selectedElement?.type === 'polygon') {
      if (!action) {
        setElements(filteredElements)
      }
      if (action) {
        setElements(filteredElements, true)
      }
      setSelectedElement(null)
    }

    if (selectedElement && selectedElement.type === 'pointsRect') {
      const elementsWithoutPoints = filteredElements.filter((elem: any) => elem.pointParentId !== selectedElement.id)
      if (!action) {
        setElements(elementsWithoutPoints)
      }
      if (action) {
        setElements(elementsWithoutPoints, true)
      }
      setSelectedElement(null)
    }

    if (selectedElement && selectedElement.type === 'circle') {
      const elementsWithoutRectPoints = filteredElements.filter((elem: any) => elem.pointParentId !== selectedElement.pointParentId)
      const rectPoints = filteredElements.filter((elem: any) => elem.pointParentId === selectedElement.pointParentId)
      const updatedPoints = setPointsNumbers(rectPoints, selectedElement.id)
      // console.log(updatedPoints)
      if (!action) {
        setElements([...elementsWithoutRectPoints, ...updatedPoints])
      }
      if (action) {
        setElements([...elementsWithoutRectPoints, ...updatedPoints], true)
      }
      setSelectedElement(null)
    }
    setSelectedElementDragPoints([])
  }

  const onLabelClick = (id: string, name: string, color: string) => {
    setInputValue(name)
    setCurrentLabelId(id)
    setCurrentLabelName(name)
    setCurrentLabelColor(color)
  }

  const onEyeClick = () => {
    if (roleNumber <= 4) {
      if (!isAutoMarkupsLoading) {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setModalWidth('580px'))
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
        dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
        dispatch(modalWindowSlice.actions.setVisible())
      }
      if (isAutoMarkupsLoading) {
        dispatch(modalWindowSlice.actions.setVisible())
      }
    }
  }

  const onToolClick = (tool: string) => {
    setAction('none')
    setPolygonLines([])
    setPolygonCursorPosition('')
    setTool(tool)
  }

  const onOndoClick = () => {
    undo()
    setSelectedElement(null)
    setSelectedElementDragPoints([])
  }

  const onRedoClick = () => {
    redo()
    setSelectedElement(null)
    setSelectedElementDragPoints([])
  }

  const onModerateClick = (action:string) => {
    setModerate(action)
  }

  const onMarkNullClick = () => {
    const onCancelClick = () => {
      dispatch(modalWindowSlice.actions.setInititialState())
    }
    const onDeleteClick = () => {
      let imageWidth = imageInfo?.image?.width
      let imageHeight = imageInfo?.image?.height
      if (imageHeight && imageWidth) {
        const imageRec = CreateElement.simpleElement(
          0, -imageWidth/2, -imageHeight/2, imageWidth/2, imageHeight/2, 'imageRec', 'imageRec', 'noLabel', 'noLabelColor', 
          null, null, null, null, null, null, null
        )
        setSelectedElementDragPoints([])
        setOnloadState([imageRec])
      }
      dispatch(modalWindowSlice.actions.setInititialState())
    }

    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.markNullTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(
      <FlexContainer direction='column' justify='space-between' align='center' gap='30px'>
        <StyledSlideP>{t('authorized.project.draw.markNullText')}</StyledSlideP>
        <FlexContainer direction='row' justify='space-around' margin='30px 0 0 0'>
          <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onDeleteClick}>{t('buttons.continue')}</ProjectButton>
        </FlexContainer>
      </FlexContainer>
    ))
    dispatch(modalWindowSlice.actions.setVisible())
    
  }

  return (
    <>
      <DrawLoader />
      <DrawHeader
        batchName={''}
        imageName={''}
        imagesCount={0}
        clearHistory={setOnloadState}
        elements={elements}
        setSelectedElement={setSelectedElement}
        setAction={setAction}
        onModerateClick={onModerateClick}
        moderate={moderate}
        setModerate={setModerate}
        imageStatus={imageStatus}
        cleared={cleared}
        setSelectedElementDragPoints={setSelectedElementDragPoints}
      />
      <StyledDrawWrapper onMouseOver={onTooltipMouseLeave}>
        <DrawTabsNav />
        <HotKeys/>
        <DrawTabsNavContent
          elements={elements}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
          setSelectedElementDragPoints={setSelectedElementDragPoints}
          setBlackoutPolygon={setBlackoutPolygon}
        />

        <StyledCanvasWrapper id="layout">
          <StyledImgContainer className="imgContainer">
            <img src="" alt="" id="someImg" />
          </StyledImgContainer>
          {!isGuestViewMode ? (
            <StyledLabelForm active={labelVisible}>
              <FlexContainer direction="column" justify="center" align="center" paddingProps="0" gap="15px">
                <Input disabled={true} widthProps="180px" heightProps="30px" label={t('authorized.project.draw.choosenClass')} value={inputValue} onChange={onLabelChange} />
                <StyledLabelsContaier>
                  {labels?.map((label: any) => (
                    <DrawLabel
                      id={label.id}
                      color={label.color}
                      projectId={params.id ? params.id : ''}
                      currentLabelName={currentLabelName}
                      name={label.name}
                      onLabelClick={onLabelClick}
                      key={label.id}
                    />
                  ))}
                </StyledLabelsContaier>
                <FlexContainer direction="row" justify="center" align="center" gap="5px">
                  <ProjectButton widthProps="65px" heightProps="30px" onClick={() => onDeleteClick('')} FontSizeProps={'12px'}>
                    {t('other.delete')}
                  </ProjectButton>
                  <ProjectButton widthProps="65px" heightProps="30px" onClick={onCantelClick} FontSizeProps={'12px'}>
                    {t('buttons.cancel')}
                  </ProjectButton>
                  <ProjectButton widthProps="65px" heightProps="30px" onClick={onConfirmClick} FontSizeProps={'12px'}>
                    {t('buttons.continue2')}
                  </ProjectButton>
                </FlexContainer>
              </FlexContainer>
            </StyledLabelForm>
          ) : (
            ''
          )}
          <StyledCanvas
            id="canvas"
            width={window.innerWidth - 305}
            height={window.innerHeight - 55}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            DrawMain
          </StyledCanvas>
        </StyledCanvasWrapper>

        <StyledToolsMenu>
          <StyledTool
            active={tool === 'selection'}
            onClick={
              getToolsRenderCondition(isGuestViewMode, projectType, 'selection', roleNumber) ? () => {} : () => onToolClick('selection')
            }
            onMouseOver={(e) => onTooltipMouseOver(e, 'selection')}
            disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'selection', roleNumber)}
          >
            <StyledHandIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'selection', roleNumber)}/>
            <StyledTolltip active={tooltipVisible === 'selection'}>
              <video width="100%" height="100px" autoPlay={true} muted loop playsInline={true} style={{ borderRadius: '15px', zoom: '1.2' }}>
                <source src={dragVideo} type="video/webm" />
              </video>
            </StyledTolltip>
            <StyledTooltipText active={tooltipVisible === 'selection'}>{t('authorized.project.draw.dragTool')}</StyledTooltipText>
            <StyledTippyBox active={tooltipVisible === 'selection'} onMouseLeave={onTooltipMouseLeave} />
          </StyledTool>

          {/* <StyledTool 
              active={tool === 'line'}
              onClick={() => setTool('line')}
            >
              <StyledLineIcon/>
            </StyledTool> */}

          <StyledTool
            active={tool === 'rectangle'}
            onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'rectangle', roleNumber) ? () => {} : () => onToolClick('rectangle')}
            disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'rectangle', roleNumber)}
            onMouseOver={(e) => onTooltipMouseOver(e, 'rectangle')}
          >
            <StyledRectangleIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'rectangle', roleNumber)}/>
            {/* <StyledTolltip active={tooltipVisible === 'rectangle'}>
                <video width="100%" height='100px' autoPlay={true} muted loop playsInline={true} style={{borderRadius: '15px', zoom: '1.2'}}>
                  <source src={dragVideo} type="video/webm"/>
                </video>
              </StyledTolltip> */}
            <StyledTooltipText active={tooltipVisible === 'rectangle'}>{t('authorized.project.draw.bBoxTool') + '. ' + t('drawToolsTooltips.tooltip1')}</StyledTooltipText>
            {/* <StyledTooltipText active={tooltipVisible === 'rectangle'}>{t('drawToolsTooltips.tooltip1')}</StyledTooltipText> */}
            <StyledTippyBox active={tooltipVisible === 'rectangle'} onMouseLeave={onTooltipMouseLeave} />
          </StyledTool>

          <StyledTool
            active={tool === 'polygon'}
            onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'polygon', roleNumber) ? () => {} : () => onToolClick('polygon')}
            onMouseOver={(e) => onTooltipMouseOver(e, 'polygon')}
            disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'polygon', roleNumber)}
          >
            <StyledPolygonIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'polygon', roleNumber)}/>
            {/* <StyledTolltip active={tooltipVisible === 'rectangle'}>
                <video width="100%" height='100px' autoPlay={true} muted loop playsInline={true} style={{borderRadius: '15px', zoom: '1.2'}}>
                  <source src={dragVideo} type="video/webm"/>
                </video>
              </StyledTolltip> */}
            <StyledTooltipText active={tooltipVisible === 'polygon'}>{t('authorized.project.draw.polygonTool') + '. ' + t('drawToolsTooltips.tooltip2')}</StyledTooltipText>
            <StyledTippyBox active={tooltipVisible === 'polygon'} onMouseLeave={onTooltipMouseLeave} />
          </StyledTool>

          <StyledTool
            active={tool === 'circle'}
            onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'circle', roleNumber) ? () => {} : () => onToolClick('circle')}
            onMouseOver={(e) => onTooltipMouseOver(e, 'circle')}
            disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'circle', roleNumber)}
          >
            <StyledPointIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'circle', roleNumber)}/>
            {/* <StyledTolltip active={tooltipVisible === 'rectangle'}>
                <video width="100%" height='100px' autoPlay={true} muted loop playsInline={true} style={{borderRadius: '15px', zoom: '1.2'}}>
                  <source src={dragVideo} type="video/webm"/>
                </video>
              </StyledTolltip> */}
            <StyledTooltipText active={tooltipVisible === 'circle'}>{t('authorized.project.draw.pointTool') + '. ' + t('drawToolsTooltips.tooltip3')}</StyledTooltipText>
            <StyledTippyBox active={tooltipVisible === 'circle'} onMouseLeave={onTooltipMouseLeave} />
          </StyledTool>

          <StyledBr />

          {roleNumber <= 4 && currentPageName !== 'datasetPage' && currentPageName !== 'datasetsPage' && !isGuestViewMode? (
            <StyledTool onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'automarkup', roleNumber) ? () => {} : () => onEyeClick()} disabled={isGuestViewMode} onMouseOver={(e) => onTooltipMouseOver(e, 'automarkup')}>
              <StyledEyeIcon />
              {/* <StyledTolltip active={tooltipVisible === 'rectangle'}>
                    <video width="100%" height='100px' autoPlay={true} muted loop playsInline={true} style={{borderRadius: '15px', zoom: '1.2'}}>
                      <source src={dragVideo} type="video/webm"/>
                    </video>
                  </StyledTolltip> */
                  }
              <StyledTooltipText active={tooltipVisible === 'automarkup'}>{t('authorized.project.draw.automarkupTool.title')}</StyledTooltipText>
              <StyledTippyBox active={tooltipVisible === 'automarkup'} onMouseLeave={onTooltipMouseLeave} />
            </StyledTool>
          ) : (
            <StyledDisabledTool>
              <StyledDisabledEyeIcon />
            </StyledDisabledTool>
          )}

          <StyledBr />
          <StyledTool disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber)} onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber) ? () => {} : () => onOndoClick()}>
            <StyledUndoIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber)}/>
          </StyledTool>

          <StyledTool disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber)} onClick={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber) ? () => {} : () => onRedoClick()}>
            <StyledRedoIcon disabled={getToolsRenderCondition(isGuestViewMode, projectType, 'doUnDo', roleNumber)}/>
          </StyledTool>

          <StyledBr />

          <StyledTool
            active={tool === 'markNull'}
            onClick={(elements?.length > 1) && roleNumber <= 6 ? onMarkNullClick : () => {}}
            onMouseOver={(e) => onTooltipMouseOver(e, 'markNull')}
          >
            <StyledMarkNullIcon disabled={(elements?.length > 1) && roleNumber <= 6}/>
            {/* <StyledTolltip active={tooltipVisible === 'rectangle'}>
                <video width="100%" height='100px' autoPlay={true} muted loop playsInline={true} style={{borderRadius: '15px', zoom: '1.2'}}>
                  <source src={dragVideo} type="video/webm"/>
                </video>
              </StyledTolltip> */}
            <StyledTooltipText active={tooltipVisible === 'markNull'}>{t('authorized.project.draw.markNullTool') + '. ' + t('drawToolsTooltips.tooltip4')}</StyledTooltipText>
            <StyledTippyBox active={tooltipVisible === 'markNull'} onMouseLeave={onTooltipMouseLeave} />
          </StyledTool>

          {/* <StyledTool 
              active={tool === 'text'}
              onClick={() => onToolClick('text')}
            >
              <StyledCommentsIcon/>
            </StyledTool> */}
        </StyledToolsMenu>

        <StyledZoomPanel>
          <ProjectButton onClick={onPlusClick} widthProps="50px" heightProps="30px" FontSizeProps="14px" paddingProps="0">
            <StyledPlusIcon />
          </ProjectButton>
          <StyledZoomPanelPercents>{userPercents}%</StyledZoomPanelPercents>
          <ProjectButton onClick={onMinusClick} widthProps="50px" heightProps="30px" FontSizeProps="14px" paddingProps="0">
            <StyledMinusIcon />
          </ProjectButton>
        </StyledZoomPanel>
      </StyledDrawWrapper>
    </>
  )
}


