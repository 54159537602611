import React, { FC, useEffect } from 'react'
import { NavigateFunction, Params } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { tabsSlice } from '../../../../../store/reducers/TabsReducer';
import { tasksReducer } from '../../../../../store/reducers/TasksReducer';
import { StyledHeader } from '../../../../../constants/styled';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import { StyledBr } from '../DatasetJob/DatasetJob';
import { assignNavReducer } from '../../../../../store/reducers/AssignNavReducer';
import styled from 'styled-components';
import { Colors } from '../../../../../../../models/Colors';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import { useTranslation } from 'react-i18next';
import { ImagesList } from '../../../../../../ImagesList';
import { EImagesPage } from '../../../../../../ImagesList/store/ImagesListReducer';
import { asyncGetAllBatches } from '../../../../../../../store/reducers/AcyncActions';
import { batchesReducer } from '../../../../../store/reducers/BatchesReducer';
import { IProject } from '../../../../../../../services/ProjectService';

interface IBatchProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: Readonly<Params<string>>;
}

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 65px;
`


const BatchPage: FC<IBatchProps> = ({projectInfo, navigate, param, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const batches = useAppSelector(state => state.batchesReducer.summaryBatches)  
  const { currentSummaryBatch } = useAppSelector(state => state.batchesReducer)

  useEffect(() => {
    if (param.tab === 'batch') {
      dispatch(tabsSlice.actions.setActiveTab('annotate'))
    }
    if (param.id) {
      dispatch(tasksReducer.actions.setCurrentProjectId(param.id))
    }
    if (param.id && !batches?.length) {
      dispatch(asyncGetAllBatches(param.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (batches?.length) {
      const findedBatch = batches.find(batch => batch.batch === param.batchId)
      if (findedBatch) {
        dispatch(batchesReducer.actions.setCurrentSummaryBatch(findedBatch))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batches])

  const onAnnotateImagesClick = () => {
    dispatch(assignNavReducer.actions.setBatchAssignVisible())
  }
  
  return (
    <StyledProjects>
      <StyledHeader>
        {currentSummaryBatch?.name? <span>{currentSummaryBatch?.name} - {t('authorized.project.assign.assignImages')}</span>: <span>{t('authorized.project.assign.assignImages')}</span>}
        <FlexContainer direction='row' justify='flex-end' align='center' widthProps='420px' gap='15px'>
          <ProjectButton onClick={onAnnotateImagesClick} widthProps="140px" heightProps='35px'>{t('buttons.createTask')}</ProjectButton>
        </FlexContainer>
      </StyledHeader>
      <StyledBr/>
      <ImagesList page={EImagesPage.batchPage}/>
    </StyledProjects>
  )
}

export default BatchPage