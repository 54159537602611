import React, { FC, ReactChild, ReactNode } from 'react'
import { StyledBr, StyledInfoCard, StyledInfoCardContent, StyledInfoCardHeader } from '../../constants/styled'

interface InfoCardProps {
    children: ReactChild | ReactNode;
    title: string;
}

const InfoCard: FC<InfoCardProps> = ({children, title, ...props}) => {
  return (
    <StyledInfoCard {...props}>
        <StyledInfoCardHeader>{title}</StyledInfoCardHeader>
        <StyledBr/>
        <StyledInfoCardContent>
            {children}
        </StyledInfoCardContent>

    </StyledInfoCard>
  )
}

export default InfoCard