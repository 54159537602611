import React, { FC } from 'react'
import { IFullTopicInfo, IGetCategoryByIdResponce } from '../../../../services/ForumService'
import {
  StyledNoMessage,
  StyledSubCategoryDate,
  StyledSubCategoryUserLink,
  StyledSubCategoryUserSpan,
  StyledTopicHeader,
  StyledTopicStats,
  StyledTopicWrapper,
  StyledUserIcon
} from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import TopicLastMessage from './TopicLastMessage'
import { NavigateFunction } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/redux'
import { ForumReducer } from '../../store/slices/ForumReducer'
import { useTranslation } from 'react-i18next'

interface ITopicProps {
  topicInfo: IFullTopicInfo
  navigate: NavigateFunction
  currentCategory: IGetCategoryByIdResponce
  isSub?: boolean
}

const Topic: FC<ITopicProps> = ({ topicInfo, navigate, currentCategory, isSub, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onTopicClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(ForumReducer.actions.clearEditorState())
    window.scrollTo(0, 0)
    navigate(`/forum/${currentCategory.category.id}/${topicInfo.forumCategory.id}/topics/${topicInfo.id}`)
  }

  const onLastMessageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    console.log('Click!')
  }

  return (
    <StyledTopicWrapper {...props} onClick={onTopicClick}>
      <StyledUserIcon />
      <FlexContainer direction="column" justify="flex-start" align="flex-start">
        <StyledTopicHeader>{topicInfo?.title.charAt(0).toUpperCase() + topicInfo?.title.slice(1)}</StyledTopicHeader>
        <FlexContainer direction="row" justify="flex-start" align="center">
          <StyledSubCategoryUserSpan>{t('forum.By')}</StyledSubCategoryUserSpan>
          <StyledSubCategoryUserLink>{topicInfo?.author?.firstName}</StyledSubCategoryUserLink>
        </FlexContainer>
        <StyledSubCategoryDate>{topicInfo?.createdAt?.slice(0, 10) + ` ${t('forum.in')} ` + topicInfo?.createdAt?.slice(11, 20)}</StyledSubCategoryDate>
      </FlexContainer>
      <FlexContainer direction="column" justify="center" align="flex-start" margin="0 0 0 40px">
        <StyledTopicStats>
          {t('forum.messages')}: {topicInfo?.messageCount}
        </StyledTopicStats>
        <StyledTopicStats>
          {t('forum.views')}: {topicInfo?.messageCount}
        </StyledTopicStats>
        <StyledTopicStats>
          {t('forum.likes')}: {topicInfo?.messageCount}
        </StyledTopicStats>
      </FlexContainer>
      {topicInfo?.latestMessage ? (
        <TopicLastMessage messageInfo={topicInfo?.latestMessage} onClick={onLastMessageClick} />
      ) : (
        <StyledNoMessage>{t('forum.noMessages')}</StyledNoMessage>
      )}
    </StyledTopicWrapper>
  )
}

export default Topic
