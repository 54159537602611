import React, { useEffect, useState } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { StyledSlideP } from '../../../../Styled/styled'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import AutoMarkupSlideForm from '../../AutoMarkupSlideForm'
import { asyncGetGroupQuotas, asyncGetModelsSummary } from '../../../../../../store/reducers/AcyncActions'
import { ProjectBr } from '../../../../../../styles/GlobalStyles'
import { useTranslation } from 'react-i18next'
import AutoMarkupTool from '../../AutoMarkupTool'
import checkboxesIcon from '../../../../../../assets/icons/checkbox-list.svg'
import writeIcon from '../../../../../../assets/icons/write.svg'
// import segmentationIcon from '../../../../../../assets/icons/drawIcons/automation-4p-svgrepo-com.svg'
import segmentationIcon from '../../../../../../assets/icons/drawIcons/aaaaaaaaaaaaaa.svg'
import { autoMarkupSlice } from '../../../../store/AutoMarkupSlice'
import AutoMarkupService from '../../../../../../services/AutoMarkupService'

interface IFirstSlideProps {
  isTestPassed?: boolean
}

const FirstSlide: React.FC<IFirstSlideProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { groupInfo } = useAppSelector((state) => state.WorkspacesReducer)
  const { modelsSummary } = useAppSelector((state) => state.AutoMarkupReducer)
  const { selectedSplit, images, selectedImages } = useAppSelector(state => state.ImagesListReducer)
  const currentTask = useAppSelector(state => state.tasksReducer.currentTask)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const imagesToSegment = useAppSelector(state => state.AutoMarkupReducer.imagesToSegment)

  const asyncGetSegmentActive = async () => {
    if (!imagesToSegment?.length) {
      const imagesToCheck = selectedImages?.length ? selectedImages.map(image => image.id) : images.map(image => image.id)
      try {
        setIsLoading(true)
        const res = await AutoMarkupService.checkImages({images: imagesToCheck})
        console.log('asyncGetSegmentActive', res)
        if (res?.data?.images?.length) {
          dispatch(autoMarkupSlice.actions.setImagesToSegment(res.data.images))
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setError(t('imagesList.errorLoading'))
        console.log('asyncGetSegmentActive', error)
      }
    }
  }

  useEffect(() => {
    dispatch(asyncGetGroupQuotas(groupInfo.id))
    dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(false))
    asyncGetSegmentActive()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!modelsSummary?.models?.length) {
      dispatch(asyncGetModelsSummary())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsSummary])

  const onClassesToolClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.classesSelection')))
    dispatch(modalWindowSlice.actions.setModalWidth('80%'))
    // dispatch(modalWindowSlice.actions.setModalHeight('90%'))
    dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="models" />))
  }

  const onTextClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.textEntering')))
    dispatch(modalWindowSlice.actions.setModalWidth('80%'))
    // dispatch(modalWindowSlice.actions.setModalHeight('90%'))
    dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="textModels" />))
  }

  const onSegmentationClick = () => {
    if (isLoading || error || !imagesToSegment?.length) {
      return
    }
    if (modelsSummary?.models?.length) {
      const findedModel: any = modelsSummary?.models?.find((model) => model.type === 'segmentation')
      if (findedModel) {
        if (findedModel.usedOnMultipleImages || findedModel.usedOnSingleImage) {
          dispatch(autoMarkupSlice.actions.setSelectedModel(findedModel))
          dispatch(modalWindowSlice.actions.setModalWidth('500px'))
          dispatch(modalWindowSlice.actions.setModalHeight('auto'))
          dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="counter" type='segment'/>))
        } else {
          dispatch(autoMarkupSlice.actions.setTestQuantity(20))
          dispatch(autoMarkupSlice.actions.setTestDemo(2))
          dispatch(autoMarkupSlice.actions.setSelectedModel(findedModel))
          dispatch(modalWindowSlice.actions.setModalWidth('500px'))
          dispatch(modalWindowSlice.actions.setModalHeight('auto'))
          dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide="trashold" type='segment'/>))
        }
      }
    }
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setVisibleFalse())
    dispatch(autoMarkupSlice.actions.setImagesToSegment([]))
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction="column" justify="flex-start" align="center" gap="10px">
      <StyledSlideP align="center">{t('authorized.project.draw.automarkupTool.toolsTItle')}</StyledSlideP>
      <FlexContainer direction="row" justify="center" align="center" gap="15px">
        <AutoMarkupTool
          image={checkboxesIcon}
          title={t('authorized.project.draw.automarkupTool.classesSelection')}
          description={t('authorized.project.draw.automarkupTool.selectClassesTooltip')}
          onClick={onClassesToolClick}
          disabled={false}
        />
        <AutoMarkupTool
          image={writeIcon}
          title={t('authorized.project.draw.automarkupTool.textEntering')}
          description={t('authorized.project.draw.automarkupTool.enterTextTooltip')}
          onClick={onTextClick}
          disabled={false}
        />
        <AutoMarkupTool
          image={segmentationIcon}
          title={t('authorized.project.draw.automarkupTool.makupType3')}
          description={t('authorized.project.draw.automarkupTool.segmentationTooltip')}
          onClick={onSegmentationClick}
          disabled={(isLoading || !imagesToSegment?.length) ? true : false}
          isLoading={isLoading}
        />
      </FlexContainer>
      <ProjectBr />
      <FlexContainer direction="row" justify="space-around" margin="30px 0 0 0">
        <ProjectButton onClick={onCancelClick} widthProps="140px">
          {t('buttons.cancel')}
        </ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default FirstSlide
