import React, { FC } from 'react'
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { IPagesProps } from '../../../models/IPagesProps';
import { EnterEmailForm } from '../../../modules/EnterEmailForm';
import UserNav from '../../../components/UserNav/UserNav';

export const EnterEmailPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <FlexContainer paddingProps='0' heightProps='80vh' justify='center' align='center' direction='column' gap='10px'>
        <EnterEmailForm navigate={navigate}></EnterEmailForm>
      </FlexContainer>
    </>
  )
}
