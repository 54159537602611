import React, { FC, useEffect, useState } from 'react'
import { IEmailConfirmation } from '../interfaces/IEmailConfirmation';
import Input from '../../../UI/Input/Input';
import { Colors } from '../../../models/Colors';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import { StyledErrorText, StyledErrorWrapepr, StyledForm, StyledFormHeader,StyledSupTitle } from '../constants/StyledConts';
import AuthService from '../../../services/AuthService';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EmailConfirmationForm: FC<IEmailConfirmation> = ({navigate, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  
  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  
  //Input values  
  const [inputValue, setInputValue] = useState('')

  //Focused input state   
  const [inputFocusColor, setinputFocusColor] = useState(Colors.gray)

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        submitClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  }, [inputValue])

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const showErrorField = (text:string) => {
    setVisible(true)
    setErrorText(text)
  }

  const submitClickHandler = async () => {
    if (inputValue) {
      try {
        let token = localStorage.getItem('token')!
        if(!token.length) {
          if (params.token) {
            token = params.token
          }
        }
        console.log(token)
        const responce = await AuthService.verifyEmail(token, inputValue)
        if (responce.data.token) {
          localStorage.setItem("token", responce.data.token)
          navigate('/projects')
        }
        console.log('verifyEmail', responce)
        
      } catch (error:any) {
        if (error?.code === "ERR_NETWORK") {
          showErrorField(t('errors.network'))
          return
        }
        showErrorField(error.response.data.message ? error.response.data.message : error.response.data.error)
        console.log(error);
      }
    }
  }

  return (
    <>
      <StyledErrorWrapepr visible={visible}>
          <StyledErrorText>{errorText}</StyledErrorText>
      </StyledErrorWrapepr>
      <StyledForm onSubmit={(e:any) => {e.preventDefault()}}>
          <StyledFormHeader>{t('notAuthorized.emailActivate.title')}</StyledFormHeader>
          <StyledSupTitle>{t('notAuthorized.emailActivate.paragraph')}</StyledSupTitle>
          <Input 
              label={t('notAuthorized.inputTitles.code')}
              focusColor={inputFocusColor} 
              borderColor={inputFocusColor} 
              value={inputValue} 
              onChange={inputChangeHandler}
              type='password'
              placeholder={t('notAuthorized.placeholders.code')}
          />
          <ProjectButton margin='10px 0' widthProps='160px' onClick={submitClickHandler}>{t('buttons.continue')}</ProjectButton>
      </StyledForm>
    </>
  )
}


