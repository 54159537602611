import React, { FC, useEffect, useState } from 'react'
import { StyledEmptyDatasets, StyledHealthContentWrapper, StyledHealthHeader, StyledHealthHeaderSubTitle, 
         StyledHealthRegenerate, StyledHistory, StyledLoupeIcon } from '../../../constants/styled'
import { StyledBr } from '../ProjectVersions/VersionInfo'
import { NavigateFunction, Params } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks/redux';
import { tabsSlice } from '../../../store/reducers/TabsReducer';
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer';
import HealthCard from './HealthCard';
import attentionIcom from '../../../../../assets/icons/attention-circle.svg'
import loupePlusIcon from '../../../../../assets/icons/noun-loupe-with-plus-2248320.svg'
import loupeMinusIcon from '../../../../../assets/icons/loupeminus.svg'
import wideIcon from '../../../../../assets/icons/wide.svg'
import cycleIcom from '../../../../../assets/icons/cycle.svg'
import codeIcom from '../../../../../assets/icons/code.svg'
import CardStatOption from './CardStatOption';
import HealthClassBalance from './HealthClassBalance';
import DatasetsService, { IGetHealthStatsResponce } from '../../../../../services/DatasetsService';
import Loader from '../../../../../UI/Loader/Loader';
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors } from '../../../../../models/Colors';
import { IProject } from '../../../../../services/ProjectService';

interface IProjectHealthCheckProps {
    navigate: NavigateFunction;
    projectInfo: IProject;
    param: Readonly<Params<string>>;
}

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 25px;
`

const ProjectHealthCheck: FC<IProjectHealthCheckProps> = ({navigate, projectInfo, param, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [statistics, setStatistics] = useState<IGetHealthStatsResponce>({} as IGetHealthStatsResponce)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState('')
  const [isResRecd, setIsResRecd] = useState(false)

  const getRegenerateProgress = async (job: string) => {
    try {
      setIsLoading(true)
      setIsResRecd(false)
      const {data} = await DatasetsService.getHealthStatProgress(job)
      console.log('regenerateProgress', data)
      if (data?.message && param.id) {
        const {data} = await DatasetsService.getHealthStats(param.id)
        setStatistics(data)
        const deleteJobResponce = await DatasetsService.deleteHealthStatJob(job)
        console.log('deleteJobResponce', deleteJobResponce)
        setIsLoading(false)
        setIsResRecd(true)
        return
      }
      if (data?.progress || data?.progress === 0) {
        setTimeout(() => {
          getRegenerateProgress(job)
        }, 500);
      }
    } catch (error) {
      console.log('Error on get Regenerate Progress', error)
      setIsLoading(false)
      setIsResRecd(true)
      setIsError("Error on get Regenerate Progress :'(")
    }
  }

  const asyncGetHealthStat = async (projectId: string) => {
    try {
      setIsLoading(true)
      setIsResRecd(false)
      const {data} = await DatasetsService.getHealthStats(projectId)
      
      setStatistics(data)
      console.log('asyncGetHealthStat', data)
      if (!data?.totalImages && param.id) {
        const createRegenerateJobResponce = await DatasetsService.generateHealthStat({service: 'regenerate', project: param.id})
        getRegenerateProgress(createRegenerateJobResponce.data.job)
        return
      }
      setIsLoading(false)
      setIsResRecd(true)
    } catch (error:any) {
      console.log('Error on asyncGetHealthStat', error)
      if (error.response.status !== 404) {
        setIsLoading(false)
        setIsResRecd(true)
        setIsError(t('errors.regenerateError'))
      }
      setIsLoading(false)
    }
  }

  const onRegenerateClick = async () => {
    if (param.id) {
      const createRegenerateJobResponce = await DatasetsService.generateHealthStat({service: 'regenerate', project: param.id})
      getRegenerateProgress(createRegenerateJobResponce.data.job)
    }
  }

  useEffect(() => {
    if (param.tab === 'health') {
      dispatch(tabsSlice.actions.setActiveTab('health'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (param.id) {
      setIsLoading(true)
      asyncGetHealthStat(param.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <StyledProjects>
        <StyledHealthHeader>
          {projectInfo.name? <h3>{projectInfo.name} {t("authorized.project.healthCheck.healthCheck")}</h3> : <h3>{t("authorized.project.healthCheck.healthCheck")}</h3>}
          {!isLoading && !isError && isResRecd ?
            <FlexContainer direction='row' justify='flex-start' align='center'>
              <StyledHealthHeaderSubTitle>{t('authorized.project.healthCheck.generated')} {statistics?.generatedAt}</StyledHealthHeaderSubTitle>
              <StyledHistory/><StyledHealthRegenerate onClick={() => onRegenerateClick()}>{t('authorized.project.healthCheck.regenerate')} </StyledHealthRegenerate>    
            </FlexContainer>
            : ''
          }
        </StyledHealthHeader>
        <StyledBr/>

        {isLoading &&
          <FlexContainer widthProps='100%' heightProps='80%' justify='center' align='center'>
            <Loader/>
          </FlexContainer>
        }

        {statistics?.totalImages && !isLoading && !isError && isResRecd ?
          <StyledHealthContentWrapper>
            <FlexContainer direction='column' justify='flex-start' align='center' widthProps='50%' gap='5px'>
              <HealthCard title={t('authorized.project.healthCheck.images')}  stat={statistics?.totalImages}>
                <CardStatOption icon={attentionIcom} text={t('authorized.project.healthCheck.images', {count: statistics?.imagesWithoutAnnotations})}/>
              </HealthCard>
              <HealthCard title={t('authorized.project.healthCheck.annotations')}  stat={statistics?.totalAnnotations}>
                <CardStatOption icon={cycleIcom} text={statistics?.averageAnnotationsPerImage?.toFixed(2) + ' per image (average)'}/>
                <CardStatOption icon={codeIcom} text={'across ' + statistics?.averageClassesPerImage + ' classes'}/>
              </HealthCard>
              <HealthCard title={t('authorized.project.healthCheck.imageSize')}  stat={statistics?.averageImageSizeMP?.toFixed(2) + ' mp'}>
                <CardStatOption icon={loupeMinusIcon} text={t('other.from') + ' ' + statistics?.imageSizeRange?.from?.toFixed(2) + ' mp'}/>
                <CardStatOption icon={loupePlusIcon} text={t('other.to') + ' ' + statistics?.imageSizeRange?.to?.toFixed(2) + ' mp'}/>
              </HealthCard>
              <HealthCard title={t('authorized.project.healthCheck.imageMedian')} stat={statistics?.medianImageRatio?.width + 'x' + statistics?.medianImageRatio?.height}>
                <CardStatOption icon={wideIcon} text={statistics?.medianImageRatio?.type}/>
              </HealthCard>
            </FlexContainer>

            <HealthClassBalance classes={statistics.classBalance} total={statistics.totalAnnotations}/>
          </StyledHealthContentWrapper>
          : ''
        }
        {!statistics?.totalImages && !isLoading && !isError && isResRecd ? 
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%' heightProps='70%'>
              <StyledEmptyDatasets>
                <StyledLoupeIcon/>
                <h4>{t('authorized.project.datasets.noDatasets')}</h4>
              <ProjectButton widthProps='150px' onClick={() =>  navigate(`/projects/${projectInfo.id}/annotate`)}>{t('buttons.goToAnnotate')}</ProjectButton>
          </StyledEmptyDatasets>
          </FlexContainer>
        : ''}
        {isError &&
          <FlexContainer direction='column' justify='center' align='center' widthProps='100%' heightProps='70%'>
            <StyledEmptyDatasets>
              <h4>{isError}</h4>
            </StyledEmptyDatasets>
          </FlexContainer>
        }


    </StyledProjects>
  )
}

export default ProjectHealthCheck