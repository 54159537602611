import React, { useEffect } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { StyledPackagesWrapper } from '../../constants/styled'
import Package from './Package'
import Loader from '../../../../UI/Loader/Loader'
import { asyncGetGroupPackages } from '../../../../store/reducers/AcyncActions'
import { useTranslation } from 'react-i18next'

const QuotaPackagesForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isPackagesLoading, error, groupPackages } = useAppSelector(state => state.SettingsReducer)
  const group = useAppSelector(state => state.WorkspacesReducer.groupInfo)

  useEffect(() => {
    if (!groupPackages?.length && group?.id) {
      dispatch(asyncGetGroupPackages(group.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <>
      {isPackagesLoading && 
        <FlexContainer direction='row' justify='center' align='center'>
          <Loader/>
        </FlexContainer>
      }
      {error &&
        <FlexContainer direction='column' justify='center' align='center' gap='30px'>
          <p>{error + ' :('} </p>
          <FlexContainer direction='row' justify='center' align='center' paddingProps='0 20px'>
            <ProjectButton onClick={onCancelClick}>{t('buttons.back')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
      {!isPackagesLoading && !error &&
        <FlexContainer direction='column' justify='center' align='center' gap='30px'>
          <StyledPackagesWrapper>
            {groupPackages?.map(groupPackage => 
              <Package key={groupPackage.id} quotas={groupPackage.quotas} groupPackage={groupPackage}/>
            )}
          </StyledPackagesWrapper>
          <FlexContainer direction='row' justify='center' align='center' paddingProps='0 20px'>
            <ProjectButton onClick={onCancelClick}>{t('buttons.back')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
    </>

  )
}

export default QuotaPackagesForm