import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../models/Colors'
import { IUserNavDropdownProps } from '../../../models/IUserNavDropdownProps'
import DropdownButton from '../DropdownButton/DropdownButton'
import ProjectButton from '../ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { asyncDeleteGroupById } from '../../../store/reducers/AcyncActions';
import { WorkspacesSlice } from '../../../modules/Projects/store/reducers/WorkspacesReducer';
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer'
import RenameForm from './RenameForm'
import settingsIcon from '../../../assets/icons/settings-svgrepo-com.svg' 
import { SettingsReducer } from '../../../modules/UserSettings/reducers/SettingsReducer'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DeleteGroupForm from './DeleteGroupForm'

const StyledSettingsIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${settingsIcon});
  mask-image: url(${settingsIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;
`

interface IDropdownProps {
    buttonFunc?: any;
    roleNumber: number;
    navigate: NavigateFunction;
}

interface IStyledTextProps {
  active?: boolean;
}

const StyledText = styled.span<IStyledTextProps>`
    padding-bottom:5px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${props => props.active ? Colors.orange : '#8F8F8F'};
    transition: all 0.1s linear;
    :hover{
        color: ${Colors.lightorange};
    }
    @media (max-width: 746px) {
        font-size: 16px;
    }
`
const StyledSettings = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width:30px;
    height: 30px;

    font-weight: bold;
    font-size: 10px;
    cursor: pointer;

    position: relative;

    .count {
        position: absolute;
        top: -6px;
        right: 4px;
    }
`
const StyledDropDown = styled.div`
    margin: 0 10px;
    cursor: pointer;
    height: auto;
    .username {
        height: 30px;
        padding: 5px;
    }
    position: relative;
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
    display: ${props => props.visible ? 'block' : 'none'};
    width: 160px;

    height: auto;

    background-color: ${Colors.dark};
    border: 2px solid ${Colors.gray};
    border-radius: 5px;

    position: absolute;
    top: 100%;
    right: -20%;
    z-index:999;
`

const SettingsDropdown: FC<IDropdownProps> = ({buttonFunc, roleNumber, navigate, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const group = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const currentUser = useAppSelector(state => state.userReduser.user)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }
  
  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }



  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groupProjects.settings.groupRename')))
    dispatch(modalWindowSlice.actions.setChildren(<RenameForm group={group}></RenameForm>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groupProjects.settings.groupDelete')))
    dispatch(modalWindowSlice.actions.setChildren(<DeleteGroupForm groupInfo={group}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onInviteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    buttonFunc(true)
  }

  const onSettingsClick = () => {
    if (roleNumber <= 2) {
      dispatch(SettingsReducer.actions.setActiveTab(group.id))
      navigate(`/settings/group/${group.id}`)
    }
  }

  return (
    <>
      <StyledDropDown>
          <FlexContainer paddingProps='0' direction='row' justify='center' align='center' onClick={onDropdownClick}>
              <StyledText active={activeColor}>{t('authorized.header.settings')}</StyledText>
              <StyledSettings>
                  <StyledSettingsIcon  onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}/>
              </StyledSettings>
          </FlexContainer>
          <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
              {roleNumber <= 2 ? <DropdownButton onClick={onSettingsClick}>{t('authorized.projects.groupProjects.settings.groupSettings')}</DropdownButton> : ''}
              {roleNumber <= 3 ? <DropdownButton onClick={onInviteClick}>{t('authorized.projects.groupProjects.settings.groupManagement')}</DropdownButton> : ''}
              {roleNumber <= 2 ? <DropdownButton onClick={onRenameClick}>{t('authorized.projects.groupProjects.settings.groupRename')}</DropdownButton> : ''}
              {currentUser?.role?.name === 'global_admin' || currentUser?.role?.name === 'developer' 
                ? <DropdownButton onClick={onDeleteClick} colorProps='red'>{t('authorized.projects.groupProjects.settings.groupDelete')}</DropdownButton> 
                : ''
              }
          </StyledDropdownContent>
      </StyledDropDown> 
    </>
  )
}

export default SettingsDropdown