import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";
import { INewImage } from "./BatchesService";

export interface ITaskImages {
    filename: string;
    displayname: string;
    size: number;
    sizeInMegaPixels: number;
    width: number;
    height: number;
    status: string | undefined;
    automarkup: boolean;
}

export interface ICreateTaskImage {
    filename: string;
}
export interface ICreateTaskBatch {
    batch: string;
    images: ICreateTaskImage[]
}
export interface ICreateTaskRequest {
  description: string;
  performers: string[],
  project: string | undefined;
  batches: ICreateTaskBatch[]
}

export interface ICreateTaslObj {
    description: string;
    annotator: string;
    moderetor: string
    project: string;
    batches: {
        unannotated: ICreateTaskBatch[]
        annotated: ICreateTaskBatch[];
    }[];
} 

export interface ITaskFromCreate {
    
}

export interface ICreateTaskResponce {
    task: {
        id: string;
        deadline: string;
        name: string;
        project: string;
        annotator: string;
        moderator: string;
        description: string;
        updatedAt: string;
        createdAt: string;
        statusId: string;
    },
    message: string;
}

export interface ITask {
    id: string;
    name: string;
    description: string,
    statusId: string;
    project: string;
    deadline: string;
    userId: string;
    createdAt: string;
    updatedAt: string;

    moderatorId: string;
    annotatorId: string;

    annotatedTotal: number,
    unannotatedTotal: number,
    approvedTotal: number;
    rejectedTotal: number;
    reviewTotal: number;
    automarkupTotal: number;

    moderator: {
        id: string;
        email: string;
    }
    annotator: {
        id: string;
        email: string;
    }
    status: {
        id: string;
        name: string;
        displayName: string;
    }
}

export interface IPutTaskResponce {
    task: {
        id: string;
        name: string;
        description: string;
        status: string;
        project: string;
        deadline: string;
        moderator: string;
        annotator: string;
        createdAt: string;
        updatedAt: string;
    }
    message: string;
}

export interface IDeleteTaskResponce {
    message: string;
    success: boolean;
}

export interface ISummaryTask {
    task: string
    name: string
    description: string;
    status: {
      id: string
      name: string
      displayName: string
    },
    annotator: {
      id: string
      email: string
    },
    moderator: {
      id: string
      email: string
    },
    imagesCount: number
    annotatedCount: number;
    unannotatedCount: number;
}

export interface IGetSummaryTaskResponce {
    tasks: ISummaryTask[];
    total: number;
}

export interface IGetTaskByIdResponce {
    task: ITask;
    total: number;
    remain: number;
    imagesOnPage: number;
    imagesTotal: number;
    images: INewImage[]
}

export interface IGetTasksStatusesRes {
    tasks: {
        id: string;
        name: string;
        status: {
          id: string;
          name: string;
          displayName: string;
        }
    }[]
}

export interface ICreateTaskPayload {
    description: string;
    annotatorId: string;
    moderatorId: string;
    project: string;
    batchId: string | undefined;
    images: string[] | any[]
}

export default class TasksService {
    
    static async createTask(payload: ICreateTaskPayload): Promise<AxiosResponse<ICreateTaskResponce>> {
        return $authHost.post<ICreateTaskResponce>('/tasks', payload)
    }

    static async putTask(id: string, name: string, description: string, statusId: string | null, moderatorId: string | null, annotatorId: string | null): Promise<AxiosResponse<IPutTaskResponce>> {
        return $authHost.put<IPutTaskResponce>('/tasks', {id: id, name: name, moderatorId: moderatorId, annotatorId: annotatorId, description: description, statusId: statusId})
    }

    static async deleteTask(taskId: string): Promise<AxiosResponse<IDeleteTaskResponce>> {
        return $authHost.delete<IDeleteTaskResponce>(`/tasks/${taskId}`)
    }

    static async getTasksSummary(projectId: string): Promise<AxiosResponse<IGetSummaryTaskResponce>> {
        return $authHost.get<IGetSummaryTaskResponce>(`/tasks/summary/${projectId}`)
    }

    static async getTaskById(id: string, limit: number, offset: number, status: string, subStatus: string, search: string): Promise<AxiosResponse<IGetTaskByIdResponce>> {
        return $authHost.get<IGetTaskByIdResponce>(`/tasks/${id}?limit=${limit}&offset=${offset}&status=${status}&subStatus=${subStatus}&search=${search}`)
    }

    static async getTasksStatuses(projectId: string): Promise<AxiosResponse<IGetTasksStatusesRes>> {
        return $authHost.get<IGetTasksStatusesRes>(`/tasks/statuses/${projectId}`)
    }
}