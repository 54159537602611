import React, { FC } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { useAppSelector } from '../../../../hooks/redux';
import { Colors } from '../../../../models/Colors';
import { StyledCodeIcon, StyledColoredSpan, StyledCommentsIcon, StyledDrawTabBtn, StyledDrawTabsNav, StyledEditIcon, StyledHistoryIcon, StyledTagsIcon } from '../../Styled/styled'
import { StyledAnnotateIcon } from './../../Styled/styled';
import { useAppDispatch } from './../../../../hooks/redux';
import { drawTabsSlice } from '../../store/DrawTabsReducer';
import { useTranslation } from 'react-i18next';

interface IDrawTabsNavProps {
    
}

const DrawTabsNav: FC<IDrawTabsNavProps> = ({...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const activeBtn = useAppSelector(state => state.DrawTabsReducer.activeBtn)

  return (
    <StyledDrawTabsNav>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'Annotations' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onAnnotationsClick())}
        >
          <StyledAnnotateIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.annotations')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'Attributes' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onAttributesClick())}
        >
          <StyledEditIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.atribytes')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'Comments' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onCommentsClick())}
        >
          <StyledCommentsIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.comments')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'Tags' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onTagsClick())}
        >
          <StyledTagsIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.tags')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'History' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onHistoryClick())}
        >
          <StyledHistoryIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.history')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
      <FlexContainer direction='column' justify='center' align='center'>
        <StyledDrawTabBtn 
          active={activeBtn.name === 'Raw' ? true : false}
          onClick={() => dispatch(drawTabsSlice.actions.onRawClick())}
        >
          <StyledCodeIcon/>
          <StyledColoredSpan textColor={Colors.lightgray}>{t('authorized.project.draw.nav.code')}</StyledColoredSpan>
        </StyledDrawTabBtn>
      </FlexContainer>
    </StyledDrawTabsNav>
  )
}

export default DrawTabsNav