import React, { FC } from 'react'
import styled from 'styled-components'
import { IProjectTabWrapperProps } from '../models/IProjectTabWrapperProps'
import ProjectsTabsNav from './ProjectsTabsNav'
import TabsContentWrapper from './TabsContentWrapper'
import { useAppSelector } from '../../../hooks/redux'

const StyledProjectTabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 90px;
  gap: 15px;
  width: 100%;
  /* padding: 0 25px 25px 25px; */
  position: relative;
  max-height: 100%;
  min-height: 100%;
`

const StyledContent = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  overscroll-behavior: none!important;
  padding: 0 15px 0px 15px; 
`

export const ProjectTabsWrapper: FC<IProjectTabWrapperProps> = ({children, navigate, ...props}) => {
  const projectInfo = useAppSelector(state => state.WorkspacesReducer.projectInfo)
  const projectRoles = useAppSelector(state => state.rolesReducer.projectRoles)
  const roleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)

  return (
    <StyledContent id='pageContainer'>
      <StyledProjectTabsWrapper id='tabsWrapper'>
        <ProjectsTabsNav navigate={navigate} roleNumber={roleNumber}/>
        <TabsContentWrapper navigate={navigate} roleNumber={roleNumber} projectInfo={projectInfo} projectRoles={projectRoles}/>
      </StyledProjectTabsWrapper>
    </StyledContent>
  )
}
