import i18n from '../../../i18n'
import BatchesService from '../../../services/BatchesService'
import DatasetsService from '../../../services/DatasetsService'
import TasksService from '../../../services/TasksService'
import VersionsService from '../../../services/VersionsService'
import { AppDispatch } from '../../../store/store'
import { batchesReducer } from '../../ProjectModule/store/reducers/BatchesReducer'
import { datasetsReducer } from '../../ProjectModule/store/reducers/DatasetsReducer'
import { tasksReducer } from '../../ProjectModule/store/reducers/TasksReducer'
import { versionsReducer } from '../../ProjectModule/store/reducers/VersionsReducer'
import { IImagesListReducerState, ISplit, imagesListSlice } from './ImagesListReducer'

export interface IAsyncGetListImagesPayload {
  id: string | undefined
  page: IImagesListReducerState['currentPageName']
  limit: number
  offset: number
  split: ISplit | null
  search: string
}

const getStatusFilter = (split: ISplit | null) => {
  if (split?.value === 'annotated' 
  || split?.value === 'unannotated' 
  || split?.value === 'automarkup'
  || split?.value === 'train'
  || split?.value === 'valid'
  || split?.value === 'test') {
    return split.value
  }
  return ''
}

const getSubStatusFilter = (split: ISplit | null) => {
  if (split?.value === 'review' || split?.value === 'approved' || split?.value === 'rejected') {
    return split.value
  }
  return ''
}

export const asyncGetListImages = (payload: IAsyncGetListImagesPayload) => async (dispatch: AppDispatch) => {
  try {
    dispatch(imagesListSlice.actions.setIsLoading(true))
    const imageStatusFilter = getStatusFilter(payload.split)
    const imagesSubStatusFilter = getSubStatusFilter(payload.split)
    if (payload.page === 'batchPage') {
      if (payload?.id) {
        const responce = await BatchesService.getBatchImages(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
        dispatch(batchesReducer.actions.setCurrentBatchId(payload.id))
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'assignPage') {
      if (payload?.id) {
        const responce = await BatchesService.getBatchesImages(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(tasksReducer.actions.setCurrentProjectId(payload.id))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'taskPage') {
      if (payload?.id) {
        const responce = await TasksService.getTaskById(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
        dispatch(tasksReducer.actions.setCurrentTask(responce.data))
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'datasetPage') {
      if (payload?.id) {
        const responce = await DatasetsService.getDatasetById(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
        dispatch(datasetsReducer.actions.setCurrentDataset(responce.data))
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'datasetsPage') {
      if (payload?.id) {
        const responce = await DatasetsService.getDatasetsAll(payload.id, payload.limit, payload.offset, imageStatusFilter, imagesSubStatusFilter, payload.search)
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'versionPage') {
      if (payload?.id) {
        const responce = await VersionsService.getVersionById(payload.id, payload.limit, payload.offset, imageStatusFilter, payload.search)
        dispatch(imagesListSlice.actions.setImages(responce.data.images))
        dispatch(
          imagesListSlice.actions.setPagesInfo({
            limit: payload.limit,
            imagesTotal: responce.data.imagesTotal,
            remain: responce.data.remain,
            imagesOnPage: responce.data.imagesOnPage,
            total: responce.data.total
          })
        )

        dispatch(versionsReducer.actions.setCurrentVersion({
          version: responce.data.version,
          archive: responce.data.archive
        }))
        console.log('asyncGetListImages', responce)
      }
    }
    if (payload.page === 'libraryPage') {
      if (payload?.id) {
      }
    }

    dispatch(imagesListSlice.actions.setIsLoading(false))
  } catch (error) {
    dispatch(imagesListSlice.actions.setIsLoading(false))
    dispatch(imagesListSlice.actions.setError(i18n.t('imagesList.errorLoading')))
    console.log('asyncGetListImages', error)
  }
}
