import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../../models/Colors'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer';
import { StyledCardTitle, StyledOptionText } from '../../../constants/styled';

interface ICardStatOptionProps {
    icon: any;
    text: string;
}

interface IIconProps {
    icon: any;
}

export const StyledIcon = styled.i<IIconProps>`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${({icon}) => icon? icon : icon});
  mask-image: url(${({icon}) => icon? icon : icon});
  background-color: ${Colors.darkblue};
  width: 20px;
  height: 20px;
  
`

const CardStatOption: FC<ICardStatOptionProps> = ({icon, text}) => {
  return (
    <FlexContainer direction='row' justify='flex-start' align='center' gap='8px'>
        <StyledIcon icon={icon}/>
        <StyledOptionText>{text}</StyledOptionText>
    </FlexContainer>
  )
}

export default CardStatOption