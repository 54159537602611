import React from 'react'
import { ProjectBr, StyledProjectTableHeader, StyledProjectTableItem, StyledProjectTableWrapper } from '../../styles/GlobalStyles';
import styled from 'styled-components';
import { Colors } from '../../models/Colors';

interface IProjectTableProps {
    headerItems: string[];
    children: React.ReactNode;
    pages?: number[];
}

export const StyledProjectTableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

  width: 100%;
  max-height: 40vh;

  overflow: auto;

  ::-webkit-scrollbar {
      width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

const ProjectTable: React.FC<IProjectTableProps> = ({headerItems, children, pages}) => {
  return (
    <StyledProjectTableWrapper>
      <StyledProjectTableHeader pages={pages}>
        {headerItems?.map(item => <StyledProjectTableItem>{item}</StyledProjectTableItem>)}  
      </StyledProjectTableHeader>
      <ProjectBr margin='0 0 15px 0'/>
      <StyledProjectTableContent>
        {children}
      </StyledProjectTableContent>
    </StyledProjectTableWrapper>
  )
}

export default ProjectTable