import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Colors } from '../../models/Colors';
import styled from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface StyledChartContainerProps {
  widthProps?: string;
  heightProps?: string;
}

const StyledChartContainer = styled.div<StyledChartContainerProps>`
  width: ${({widthProps}) => widthProps || '100%'};
  height: ${({heightProps}) => heightProps || '100%'};
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

interface IUsersLineChartProps {
  displayLegend?: boolean;
  displayTitle?:boolean;

  data?: number[];
  chartTitle?: string;

  labels?: string[];
  datasets?: any[]

  titleColor?: string;
  redraw?: boolean;

  gridColor?: string;

  heightProps?: string;
  ticksColor?: string;
}

const UsersLineChart: React.FC<IUsersLineChartProps> = ({
  displayLegend, displayTitle, chartTitle, titleColor, labels, datasets, 
  redraw=true, gridColor, heightProps, ticksColor, ...props 
}) => {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: displayLegend || false,
        padding: {
          bottom: 20
        },
      },
      title: {
        align: 'start' as const,
        padding: {
          bottom: 30
        },
        display: displayTitle || false,
        text: chartTitle || 'Chart',
        color: titleColor || 'white',
        font: {
          size: 21,
          weight: 'bold',
        }
      },
    },
    scales: {
      y: {
        ticks: {
          color: ticksColor || Colors.orange,
          beginAtZero: true
        },
        grid: {
          color: gridColor || "#ffffff5e",
          lineWidth: 1,
          drawTicks: true,
          tickBorderDash: [10],
          z: 1,
        }
      },
      x: {
        ticks: {
          color: ticksColor || Colors.orange,
          beginAtZero: true
        },
        grid: {
          color: gridColor || "#ffffff5e",
          lineWidth: 1,
          drawTicks: true,
          tickBorderDash: [10],
          z: 1,
        }
      },
      
    },
  };

  const data = {
    labels,
    datasets: datasets ? datasets : [],
  };


  return (
    <StyledChartContainer heightProps={heightProps || '100%'} widthProps='100%'>
      <Line options={options} data={data} redraw={false} width={1}/>
    </StyledChartContainer>
  )
}

export default UsersLineChart