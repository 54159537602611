import React from 'react'
import ContentSection from '../SettingsContent/ContentSection'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import LicensesTabs from './LicensesTabs'
import LicenseCard from './LicenseCard'
import { ILicensesProps } from '../../constants/interfaces'
import { useTranslation } from 'react-i18next'

const Licenses: React.FC<ILicensesProps> = ({licenses, settings, group, user, selectedLicense, activeTab, setActiveTab}) => {
  const { t } = useTranslation()
  return (
    <>
      <ContentSection title='' subtitle=''>
         <FlexContainer direction='row' justify='center' align='center'>
           <h3>{t('authorized.settings.licensesTitle')}</h3>
         </FlexContainer>
         <LicensesTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
         <FlexContainer direction='row' justify='space-around' align='center' gap='20px' wrap='wrap'>
           {licenses?.map(license => 
             <LicenseCard key={license.id} settings={settings} type={activeTab} license={license} group={group} user={user} selectedLicense={selectedLicense}/>
           )}
         </FlexContainer>
      </ContentSection>
    </>
  )
}

export default Licenses