import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { Colors } from '../../../../models/Colors'
import { IModalProps } from '../../../../models/IModalProps'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../../../UI/Input/Input'
import { assignNavReducer } from '../../store/reducers/AssignNavReducer'
import Teammate from './Teammate'
import { IProjectUserFullInfo } from '../../../Projects/store/reducers/WorkspacesReducer'
import { asyncChangeTaskPerformer } from '../../../../store/reducers/AcyncActions'
import { UseProjectUsersQuery } from '../../../../hooks/UseProjectUsersQuery'
import { useTranslation } from 'react-i18next'
import { tasksReducer } from '../../store/reducers/TasksReducer'

const StyledBackground = styled.div<IModalProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #0000007b;
  transition: opacity 0.1s linear;
  height: ${({ visible }) => (visible ? '100vh' : '0')};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 1001;
`
const StyledModal = styled.div`
  max-width: 380px;
  width: 100%;
  height: 100vh;
  padding: 20px 20px 30px 20px;
  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 1006;
  color: ${Colors.textgray};
  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px; /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledTitle = styled.h3`
  margin: 15px 0;
`
export const StyledBr = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${Colors.black};
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;

  width: 100%;
  height: 100%;
`
export const StyledColoredSpan = styled.span`
  color: ${Colors.WHITE};
  font-size: 16px;
`

const ReAssignNavComponent: FC<IModalProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const reassignNavVisible = useAppSelector((state) => state.assignNavReducer.reassignVisible)
  const projectUsersInfo = useAppSelector((state) => state.WorkspacesReducer.projectUsersInfo)
  const currentTask = useAppSelector((state) => state.tasksReducer.currentTask)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOwnerActive, setIsOwnerActive] = useState(true)

  const [moders, setModers] = useState<IProjectUserFullInfo[]>([])
  const [labellers, setLabellers] = useState<IProjectUserFullInfo[]>([])

  const [isModerActive, setIsModerActive] = useState('')
  const [isLabellerActive, setIsLabellerActive] = useState('')

  const [usersFilter, setUsersfilter] = useState({ query: '' })
  const queryUsers = UseProjectUsersQuery(projectUsersInfo, usersFilter.query)

  useEffect(() => {
    if (queryUsers?.length) {
      const moders = queryUsers.filter((user) => user.projectRole.name === 'project_moderator')
      const labellers = queryUsers.filter((user) => user.projectRole.name === 'project_annotator')
      setModers(moders)
      setLabellers(labellers)
    }
  }, [queryUsers])

  const onBgClick = () => {
    dispatch(assignNavReducer.actions.setReassignVisibleFalse())
    dispatch(assignNavReducer.actions.setInititialState())
  }

  const onReassignClick = () => {
    dispatch(
      asyncChangeTaskPerformer(
        currentTask.task.id,
        currentTask.task.name,
        currentTask.task.description,
        currentTask?.task?.status?.id ? currentTask.task.status.id : null,
        isModerActive ? isModerActive : null,
        isLabellerActive ? isLabellerActive : null
      )
    )
    if (isModerActive) {
      const findedModer = moders.find(moder => moder?.user?.id === isModerActive)
      if (findedModer) {
        dispatch(tasksReducer.actions.setSelectedModerator({id: findedModer?.user?.id, email: findedModer?.user?.email}))
        return
      }
      dispatch(tasksReducer.actions.setSelectedModerator({id: '', email: ''}))
    }
    if (isLabellerActive) {
      const findedLabeller = labellers.find(labeller => labeller?.user?.id === isLabellerActive)
      if (findedLabeller) {
        dispatch(tasksReducer.actions.setSelectedAnnotator({id: findedLabeller?.user?.id, email: findedLabeller?.user?.email}))
        return
      }
      dispatch(tasksReducer.actions.setSelectedAnnotator({id: '', email: ''}))
    }
    // dispatch(tasksReducer.actions.setSelectedAnnotator())
    dispatch(assignNavReducer.actions.setReassignVisibleFalse())
    dispatch(assignNavReducer.actions.setInititialState())
  }

  return (
    <StyledBackground visible={reassignNavVisible} onClick={onBgClick}>
      <StyledModal onClick={(e) => e.stopPropagation()}>
        <StyledTitle>{t('authorized.project.taskPage.reassign')}</StyledTitle>
        <StyledBr></StyledBr>
        <FlexContainer direction="column" align="flex-start" gap="15px">
          <Input
            label={t('authorized.project.assign.paragraph')}
            widthProps="100%"
            placeholder={t('authorized.project.projectManagement.inputTitle')}
            value={usersFilter.query}
            onChange={(e) => setUsersfilter({ ...usersFilter, query: e.target.value })}
          />

          <StyledColoredSpan color="white">{t('authorized.project.assign.selectLabeller')}:</StyledColoredSpan>
          {labellers?.length ? (
            labellers.map((user) => (
              <Teammate
                key={user.user.id}
                currentTask={currentTask}
                userId={user.user.id}
                name={user.user.firstName}
                email={user.user.email}
                isActive={isLabellerActive}
                setIsActive={setIsLabellerActive}
              />
            ))
          ) : (
            <StyledColoredSpan>{t('authorized.project.assign.noLabellers')}</StyledColoredSpan>
          )}

          <StyledColoredSpan color="white">{t('authorized.project.assign.selectModer')}:</StyledColoredSpan>
          {moders?.length ? (
            moders.map((user) => (
              <Teammate
                key={user.user.id}
                currentTask={currentTask}
                userId={user.user.id}
                name={user.user.firstName}
                email={user.user.email}
                isActive={isModerActive}
                setIsActive={setIsModerActive}
              />
            ))
          ) : (
            <StyledColoredSpan>{t('authorized.project.assign.noModer')}</StyledColoredSpan>
          )}
        </FlexContainer>
        <StyledButtonsWrapper>
          <StyledBr></StyledBr>
          {isOwnerActive ? (
            <ProjectButton widthProps="150px" onClick={onReassignClick}>
              {t('buttons.apply')}
            </ProjectButton>
          ) : (
            'Select labeller'
          )}
        </StyledButtonsWrapper>
      </StyledModal>
    </StyledBackground>
  )
}

export default ReAssignNavComponent
