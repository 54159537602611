import React, { FC, useEffect, useState } from 'react'
import { Colors } from '../../../../models/Colors';
import styled from 'styled-components';
import { ICanvasElement } from '../DrawMain';

interface ILabelProps {
  id: number;
  color: string;
  name: string;
  projectId: string;
  onLabelClick: (id: number, name: string, color: string) => void;
  currentLabelName: string;
  isClass: boolean;
  selectedElement?: ICanvasElement | null;
  onHover: (id: number, name: string, color: string) => void;
  index?: number;
}

interface ILabelNameProps {
  colorProps: string;
  active?: boolean;
}

const StyledLabelName = styled.span<ILabelNameProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  border-color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
  background-color: ${({active}) => active ? Colors.black : ''};
  min-width: 50px;
  height: 8;
  font-size: 12px;
  font-weight: bold;

  width: 100%;
  padding: 2px 5px;

  cursor: pointer;
  transition: all .1s linear;

  color: ${props => props.active ? props.colorProps : ''};

  :hover {
    color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
    background-color: ${Colors.black};
  }
`
const StyledLabelColor = styled.div<ILabelNameProps>`
  width: 8px;
  height: 8px;
  border-radius: 15px;
  background-color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
`

const TabsLabel:FC<ILabelProps> = ({id, color, name, projectId, onLabelClick, currentLabelName, isClass, selectedElement, onHover, index, ...props}) => {
  const [objectIndex, setObjectIndex] = useState(0)

  useEffect(() => {
    if (index || index === 0) {
      setObjectIndex(index + 1)
    }
  }, [index])

  return (
    <StyledLabelName colorProps={color} onClick={() => onLabelClick(id, name, color)} active={selectedElement?.id === id} onMouseOver={isClass ? () => onHover(id, name, color) : () => onLabelClick(id, name, color)}>
      {isClass ? name : objectIndex + ' ' + name}
      <StyledLabelColor colorProps={color}/>
    </StyledLabelName>
  )
}

export default TabsLabel