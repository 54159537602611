import { Colors } from "../../../models/Colors";

export const setInputFocusError = (
    setVisible: React.Dispatch<React.SetStateAction<boolean>>, 
    setErrorText:React.Dispatch<React.SetStateAction<string>>, 
    setColor:React.Dispatch<React.SetStateAction<Colors>>, result:any) => {
        setVisible(false) 
        setColor(Colors.gray)
        setErrorText('')
        
        if (result.isValid == false) {
            setVisible(true)
            setColor(Colors.red)
            setErrorText(result.errorMessage)
        }
        if (result.isValid == true) {
            setVisible(false) 
            setColor(Colors.gray)
            setErrorText('')
        }
}
