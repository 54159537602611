import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReduser from "./reducers/userReducer"
import registrationErrorTotal  from './../modules/RegistrationForm/reducers/registrationErrorTotal';
import loginErrorTotal from "../modules/LoginForm/reducers/loginErrorTotal";
import passwordResetErrorTotal from "../modules/PasswordRecoveryForm/reducers/passwordResetErrorTotal";
import WorkspacesReducer from "../modules/Projects/store/reducers/WorkspacesReducer";
import TabsReducer from "../modules/ProjectModule/store/reducers/TabsReducer";
import blopImagesReducer from "../modules/ProjectModule/store/reducers/BlopImagesReducer";
import modalWindowReducer from './reducers/modalWindowReducer'
import assignNavReducer from './../modules/ProjectModule/store/reducers/AssignNavReducer';
import batchesReducer from "../modules/ProjectModule/store/reducers/BatchesReducer";
import tasksReducer from './../modules/ProjectModule/store/reducers/TasksReducer';
import DrawTabsReducer from "../modules/Draw/store/DrawTabsReducer";
import AutoMarkupReducer from '../modules/Draw/store/AutoMarkupSlice'
import datasetsReducer from '../modules/ProjectModule/store/reducers/DatasetsReducer';
import datasetsNavReducer from '../modules/ProjectModule/store/reducers/DatasetsNavReducer';
import generateReducer from '../modules/ProjectModule/store/reducers/GenerateReducer';
import versionsReducer from '../modules/ProjectModule/store/reducers/VersionsReducer';
import drawLoaderReducer from '../modules/Draw/store/DrawLoaderSlice';
import ForumReducer from "../modules/Forum/store/slices/ForumReducer";
import rolesReducer from './reducers/rolesReducer'
import invitedRegErrorTotal from "../modules/InvitedRegistrationForm/reducers/invitedRegErrorTotal";
import StatisticsReducer from "../modules/ProjectModule/store/reducers/StatisticsReducer";
import SettingsReducer from '../modules/UserSettings/reducers/SettingsReducer';
import PaymentsReducer from "../modules/UserSettings/reducers/PaymentsReducer";
import editorReducer from "../modules/Editor/editorReducer";
import eventSourceReducer from "../http/eventSources/eventSourceReducer";
import ImagesListReducer from "../modules/ImagesList/store/ImagesListReducer";

const rootReducer = combineReducers({
    userReduser,
    registrationErrorTotal,
    loginErrorTotal,
    passwordResetErrorTotal,
    WorkspacesReducer,
    TabsReducer,
    blopImagesReducer,
    modalWindowReducer,
    assignNavReducer,
    batchesReducer,
    tasksReducer,
    DrawTabsReducer,
    AutoMarkupReducer,
    datasetsReducer,
    datasetsNavReducer,
    generateReducer,
    versionsReducer,
    drawLoaderReducer,
    ForumReducer,
    rolesReducer,
    invitedRegErrorTotal,
    StatisticsReducer,
    SettingsReducer,
    PaymentsReducer,
    editorReducer,
    eventSourceReducer,
    ImagesListReducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }),
    })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']