import React, { FC, useEffect, useState } from 'react'
import { StyledUsersTable } from '../../../../constants/styled'
import { ProjectBr } from '../../../../../../styles/GlobalStyles'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { IProjectUserFullInfo } from '../../../../../Projects/store/reducers/WorkspacesReducer';
import UserCard from './UserCard';
import ProjectSelect from '../../../../../../UI/ProjectSelect/ProjectSelect';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux';
import styled from 'styled-components';
import { StatisticsOption } from '../ProjectStatistics';
import { statisticsSlice } from '../../../../store/reducers/StatisticsReducer';
import { TableColors } from '../../../../../../models/Colors';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../services/ProjectService';

export const StyledUsersTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 10px 0;
`

export const StyledTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  min-width: 120px;
  text-align: center;
  width: 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const StyledTableItemWork = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  min-width: 120px;
  width: 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const StyledSelectWrapper = styled.div`
  width: 120px;
`

export const StyledRating = styled.div`
  width: 50px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`

export const StyledUserCol = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  min-width: 220px;
  width: 220px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`


interface IUsersTableProps {
  projectInfo: IProject;
  roleNumber: number;
  selectedRole: StatisticsOption | null;
  optionsTable: StatisticsOption[];
  setSelectedRole: React.Dispatch<React.SetStateAction<StatisticsOption>>;
}

const StatisticsTable: FC<IUsersTableProps> = ({projectInfo, selectedRole, setSelectedRole, optionsTable, roleNumber, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { actionStats } = useAppSelector(state => state.StatisticsReducer)
  const projectUsers = useAppSelector(state => state.WorkspacesReducer.projectUsersInfo)
  const selectedUsers = useAppSelector(state => state.StatisticsReducer.selectedUsers)
  
  console.log('projectUsers', projectUsers)

  const [roleFilteredUsers, setRoleFilteredUsers] = useState<IProjectUserFullInfo[]>([])
  const [usersWithTotals, setUsersWithTotals] = useState<any>([])

  useEffect(() => {
    if (roleFilteredUsers?.length) {
      const usersWithTotals: any[] = []

      roleFilteredUsers.forEach((user) => {
        const totals = {
          annotated: 0,
          bboxes: 0,
          annotatorAverage: 0,
      
          approved: 0,
          rejected: 0,
          moderatorAverage: 0,
        }

        if (actionStats?.['image_annotated']?.id) {
          const findedDataset = actionStats?.['image_annotated']?.datasets?.find((dataset: any) => dataset?.userId === user.user.id)
          if (findedDataset) {
            totals.annotated = findedDataset?.total || 0
          }
        }
        if (actionStats?.['new_markup']?.id) {
          const findedDataset = actionStats?.['new_markup']?.datasets?.find((dataset: any) => dataset?.userId === user.user.id)
          if (findedDataset) {
            totals.bboxes = findedDataset?.total || 0
            totals.annotatorAverage = Math.round(findedDataset.total / (actionStats?.['new_markup']?.dates?.length ? actionStats?.['new_markup']?.dates?.length : 0))
          }
        }
        if (actionStats?.['image_approved']?.id) {
          const findedDataset = actionStats?.['image_approved']?.datasets?.find((dataset: any) => dataset?.userId === user.user.id)
          if (findedDataset) {
            totals.approved = findedDataset?.total || 0
          }
        }
        if (actionStats?.['image_rejected']?.id) {
          const findedDataset = actionStats?.['image_rejected']?.datasets?.find((dataset: any) => dataset?.userId === user.user.id)
          const findedApprovedDataset = actionStats?.['image_approved']?.datasets?.find((dataset: any) => dataset?.userId === user.user.id)
          if (findedDataset) {
            totals.rejected = findedDataset?.total || 0
          }

          if (findedApprovedDataset && findedDataset) {
            totals.moderatorAverage = Math.round(
              (findedApprovedDataset.total + findedDataset.total) / (actionStats?.['image_rejected']?.dates?.length ? actionStats?.['image_rejected']?.dates?.length : 0)
            )
          }
        }

        usersWithTotals.push({...user, totals: totals, moderSort: totals.moderatorAverage, annotatorSort: totals.annotatorAverage})
      })
      console.log('usersWithTotals', usersWithTotals)
      const kek = selectedRole?.value === 'project_annotator'
      ? usersWithTotals.sort((a, b) => a.annotatorSort - b.annotatorSort).reverse()
      : usersWithTotals.sort((a, b) => a.moderSort - b.moderSort).reverse()
      const sortedDeepCopy = JSON.parse(JSON.stringify(kek))
      setUsersWithTotals(sortedDeepCopy)
      
      sortedDeepCopy.forEach((user:any, index: number) => {
        dispatch(statisticsSlice.actions.setUser({id: user.user.id, color: TableColors[index], role: user.projectRole.name}))
      });
      

      dispatch(statisticsSlice.actions.setFilteredUsersCounter(sortedDeepCopy?.length))
      console.log('sortedDeepCopy', sortedDeepCopy)
    }
    if (!roleFilteredUsers?.length) {
      setUsersWithTotals([])
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleFilteredUsers, actionStats, selectedUsers, projectUsers, selectedRole])

  useEffect(() => {
    if (selectedRole?.value) {
        const filteredUsers = projectUsers.filter(user => user.projectRole.name === selectedRole.value)
        setRoleFilteredUsers(filteredUsers)
    }
  }, [projectUsers, selectedRole])

  const handleRoleSelect = (value: string) => {
    const findedRole = optionsTable.find(option => option.value === value)
    if (findedRole) {
      setSelectedRole(findedRole)
    }
  };
  
  return (
    <StyledUsersTable>
      <StyledUsersTableHeader>
        <StyledRating>{t('authorized.project.statistics.rating')}</StyledRating>
        <StyledUserCol>{t('authorized.project.statistics.user')}</StyledUserCol>
        <StyledTableItem>
          <StyledSelectWrapper>
            <ProjectSelect 
              options={optionsTable} 
              mode='rows' 
              selected={selectedRole || null} 
              onChange={handleRoleSelect} 
              placeholder="Выберите роль"
            />
          </StyledSelectWrapper>
  
        </StyledTableItem>
        {selectedRole?.value === 'project_annotator' ? <StyledTableItemWork>{t('authorized.project.statistics.labellerInWork')}</StyledTableItemWork>: ''}
        {selectedRole?.value === 'project_annotator' ? <StyledTableItem>{t('authorized.project.statistics.annotated')} </StyledTableItem>: ''}
        {selectedRole?.value === 'project_annotator' ? <StyledTableItem>Bboxes</StyledTableItem>: ''}
        {selectedRole?.value === 'project_annotator' ? <StyledTableItem>{t('authorized.project.statistics.average')}</StyledTableItem>: ''}

        {selectedRole?.value === 'project_moderator' ? <StyledTableItemWork>{t('authorized.project.statistics.labellerInWork')}</StyledTableItemWork>: ''}
        {selectedRole?.value === 'project_moderator' ? <StyledTableItem>{t('authorized.project.statistics.approved')} </StyledTableItem>: ''}
        {selectedRole?.value === 'project_moderator' ? <StyledTableItem>{t('authorized.project.statistics.rejected')}</StyledTableItem>: ''}
        {selectedRole?.value === 'project_moderator' ? <StyledTableItem>{t('authorized.project.statistics.average')}</StyledTableItem>: ''}
        <StyledTableItem>{t('authorized.project.statistics.selected')}</StyledTableItem>
        </StyledUsersTableHeader>
        <ProjectBr margin='0 0 15px 0'/>
        <FlexContainer direction='column' justify='center' align='flex-start' gap="15px">
          {usersWithTotals?.map((user:any, index:number) => 
            <UserCard 
              index={index}
              key={user.user.id} 
              userInfo={user.user} 
              totals={user.totals}
              userRole={user.projectRole} 
              projectUsersInfo={projectUsers}
              projectInfo={projectInfo}
              selectedRole={selectedRole ? selectedRole.value : ''}
            />
          )}
        </FlexContainer>
    </StyledUsersTable>
  )
}

export default StatisticsTable