import React, { FC, useLayoutEffect, useState } from 'react'
import { StyledCanvas, StyledCanvasOptionImage, StyledCanvasWrapper } from '../../../../constants/styled'
import owlImage from '../../../../../../assets/images/owl.jpg'
import rough from 'roughjs/bundled/rough.esm'
import { Colors } from '../../../../../../models/Colors'
import owlContrastImage from '../../../../../../assets/images/owlContrast.png'
import owlIsolateImage from '../../../../../../assets/images/OwlIsolate.png'
import owlGrayScaleImage from '../../../../../../assets/images/owlGrayscale.png'
import owlNoice from '../../../../../../assets/images/owlNoise.png'

interface ICanvasImageProps {
  type: string
  select: string
  resizeWidth?: number
  resizeHeight?: number
  image: any
  rows: number
  columns: number
  noice: number
  blur: number
  imagesToAugmentCount: number
  brightnessValue: number
  brightnessSelect: string
}

const generator = rough.generator()

const createElement = (id: number, x1: number, y1: number, x2: number, y2: number, type: string) => {
  let roughElement

  if (type === 'imageRec') {
    roughElement = generator.rectangle(x1, y1, x2 - x1, y2 - y1, { roughness: 0, strokeWidth: 0, stroke: `${Colors.orange}` })
  }

  if (type === 'line') {
    roughElement = generator.line(x1, y1, x2, y2, { roughness: 0, strokeWidth: 3, stroke: `${Colors.orange}` })
  }

  return { id, x1, y1, x2, y2, type, roughElement }
}

const CanvasImage: FC<ICanvasImageProps> = ({
  type,
  noice,
  brightnessValue,
  brightnessSelect,
  blur,
  imagesToAugmentCount,
  rows,
  columns,
  resizeHeight,
  resizeWidth,
  select,
  image,
  ...props
}) => {
  const [elements, setElements]: any = useState([])

  useLayoutEffect(() => {
    const owlImage = document.getElementById('owlImage') as HTMLImageElement
    const owlGrayScaleImage = document.getElementById('owlGrayScaleImage') as HTMLImageElement
    const owlContrastImage = document.getElementById('owlContrastImage') as HTMLImageElement
    const owlIsolateImage = document.getElementById('owlIsolateImage') as HTMLImageElement
    const owlNoice = document.getElementById('owlNoice') as HTMLImageElement

    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    const context = canvas.getContext('2d')
    const rc = rough.canvas(canvas)
    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.save()
      context.translate(90, 90)
      //   context.drawImage(image, -90, -90, 180, 180)
      if (type === 'Resize' && resizeWidth && resizeHeight && select !== 'Fill') {
        context.drawImage(owlImage, -90, -90, resizeWidth, resizeHeight)
      }
      if (type === 'Resize' && select === 'Fill') {
        context.drawImage(owlImage, -90, -90, 180, 180)
      }
      if (type === 'Auto Contrast') {
        context.drawImage(owlContrastImage, -90, -90, 180, 180)
      }
      if (type === 'Isolate Objects') {
        context.drawImage(owlIsolateImage, -90, -90, 180, 180)
      }
      if (type === 'Grayscale' || type === 'Grayscale Aug...') {
        context.drawImage(owlGrayScaleImage, -90, -90, 180, 180)
      }
      if (type === 'Blur') {
        context.drawImage(owlImage, -90, -90, 180, 180)
        canvas.style.filter = `blur(${blur}px)`
      }
      if (type === 'Noise') {
        context.drawImage(owlNoice, -90, -90, 180, 180)
      }

      let elems: any = []
      if (type === 'Tile') {
        context.clearRect(-90, -90, canvas.width, canvas.height)
        context.drawImage(owlImage, -90, -90, 180, 180)
        setElements([])
        const imageRec = createElement(0, -90, -90, 180 / 2, 180 / 2, 'imageRec')
        setElements((prevState: any) => [imageRec])
        const rowLinesWithin = 180 / rows
        const columnsLinesWithin = 180 / columns

        if (rows > 0) {
          for (let i = 0; i <= 180; i += rowLinesWithin) {
            const rowLine = createElement(elements.length, -90, i - 90, 90, i - 90, 'line')
            elems.push(rowLine)
          }
        }
        if (columns > 0) {
          for (let i = 0; i <= 180; i += columnsLinesWithin) {
            const columnLine = createElement(elements.length, i - 90, -90, i - 90, 90, 'line')
            elems.push(columnLine)
          }
        }
      }
      elems.forEach(({ roughElement }: any) => rc.draw(roughElement))
      context.restore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, rows, columns, resizeWidth, resizeHeight, select, image, noice, blur, brightnessValue, brightnessSelect])

  return (
    <StyledCanvasWrapper>
      <StyledCanvasOptionImage src={owlImage} id="owlImage" />
      <StyledCanvasOptionImage src={owlGrayScaleImage} id="owlGrayScaleImage" />
      <StyledCanvasOptionImage src={owlContrastImage} id="owlContrastImage" />
      <StyledCanvasOptionImage src={owlIsolateImage} id="owlIsolateImage" />
      <StyledCanvasOptionImage src={owlNoice} id="owlNoice" />
      <StyledCanvas id="canvas" width={180} height={180} />
    </StyledCanvasWrapper>
  )
}

export default CanvasImage
