import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, Params, useParams } from 'react-router-dom';
import styled from 'styled-components';
import editIcon from '../../../../../../../assets/icons/edit-svgrepo-com.svg'
import EditDatasetDescription from './EditDatasetDescription';
// import AddLabelForm from '../JobPage/AddLabelForm';
import Label from '../JobPage/Label';
import { Colors } from '../../../../../../../models/Colors';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { asyncGetDatasetById, asyncGetProjectLabels } from '../../../../../../../store/reducers/AcyncActions';
import { drawTabsSlice } from '../../../../../../Draw/store/DrawTabsReducer';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import { StyledHeader, StyledProjects } from '../../../../../constants/styled';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import { tasksReducer } from '../../../../../store/reducers/TasksReducer';
import { IGetTaskByIdResponce } from '../../../../../../../services/TasksService';
import { useTranslation } from 'react-i18next';
import { ImagesList } from '../../../../../../ImagesList';
import { EImagesPage } from '../../../../../../ImagesList/store/ImagesListReducer';
import { INewImage } from '../../../../../../../services/BatchesService';
import AddLabelForm from '../JobPage/AddLabelForm';
import { IProject } from '../../../../../../../services/ProjectService';

interface IBatchProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: Readonly<Params<string>>;
}

const StyledEditIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${editIcon});
  mask-image: url(${editIcon});
  background-color: ${Colors.orange};
  width: 15px;
  height: 15px;
`

const StyledBatch = styled.div`
  width: 100%;
  max-width: 250px;
  height: auto;
  padding: 10px;
  

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  border: 1px solid ${Colors.gray};
  border-radius: 5px;

  position: relative;

  transition: all 0.1s linear;     

  &:hover {
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
const StyledDate = styled.h6`
  color: ${Colors.textgray};
`
const StyledCounter = styled.h6`
  color: ${Colors.textgray};
`
const StyledLabeller = styled.h5`
  color: ${Colors.textgray};
  height: 20px;
`
const StyledName = styled.span`
  color: ${Colors.textgray};
  height: 20px;
  font-size: 14px;
`
const JobWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 40px;
`
const JobOverview = styled.div`
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
const StyledTitle = styled.span`
  
`
const StyledTextButton = styled.span`
  font-size: 14px;
`
const StyledEdit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.orangeSaturated};
  }
`

export const StyledBr = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${Colors.black};
    margin: 10px 0;
`

const StyledLabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 100px;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

const DatasetPage:FC<IBatchProps> = ({projectInfo, navigate, param, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useAppDispatch()
  const isRoleLoading = useAppSelector(state => state.WorkspacesReducer.isRoleLoading)
  const currentUser = useAppSelector(state => state.userReduser.user)
  const userRoleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const {currentDataset} = useAppSelector(state => state.datasetsReducer)
  const labels = useAppSelector(state => state.DrawTabsReducer.labels)
  const { selectedSplit, images, selectedImages, total } = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {
    dispatch(tasksReducer.actions.setCurrentTask({} as IGetTaskByIdResponce))
    if (params.id) {
      dispatch(asyncGetProjectLabels(params.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getDrawViewMode = () => {
    if (currentDataset?.dataset?.moderator?.id === currentUser?.id ||
      currentDataset?.dataset.annotator?.id === currentUser?.id || userRoleNumber <= 4) {
        return false
    }
    return true
  }

  const navigateToDraw = (image: INewImage, action: string) => {
    if (isRoleLoading) {
      return
    }
    if (!labels?.length) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
      dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={params.id!}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }

    const drawViewMode = getDrawViewMode()
    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))

    dispatch(drawTabsSlice.actions.setAction(action))
    navigate(`/projects/${params.id}/job/annotate/${params.datasetId}/draw/${image.id}`)
    
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${projectInfo.id}/dataset/dataset/${params.datasetId!}`))
  }
  
  const onGenerateClick = () => {
    navigate(`/projects/${params.id}/generate`)
  }

  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.editInstruction')))
    dispatch(modalWindowSlice.actions.setChildren(<EditDatasetDescription currentDataset={currentDataset}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onAddClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
    dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={params.id!}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onOpenSelectedClick = () => {
    if (isRoleLoading) {
      return
    }
    if (!labels?.length) {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.taskPage.addingClass')))
      dispatch(modalWindowSlice.actions.setChildren(<AddLabelForm projectId={params.id!}/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }

    const drawViewMode = getDrawViewMode()
    dispatch(drawTabsSlice.actions.setGuestViewMode(drawViewMode))

    dispatch(drawTabsSlice.actions.setAction('selectedImages'))
    navigate(`/projects/${params.id}/job/annotate/${params.datasetId}/draw/${selectedImages[0].id}`)
    dispatch(drawTabsSlice.actions.setPreviousPage(`/projects/${projectInfo.id}/dataset/dataset/${params.datasetId!}`))
  }

  return (
    <StyledProjects>
      <StyledHeader>
        {currentDataset?.dataset?.name? <h5>{currentDataset?.dataset?.name}</h5>: <h3>{t('authorized.project.datasets.dataset')}</h3>}
        <FlexContainer direction='row' justify='flex-end' align='center' gap='10px'>
          {selectedImages?.length ? <ProjectButton onClick={onOpenSelectedClick} widthProps="180px" FontSizeProps='16px'>{t('authorized.project.draw.openSelected')}</ProjectButton> : ''}
          {currentDataset?.dataset?.annotatedTotal ? <ProjectButton onClick={onGenerateClick} widthProps="180px">{t('authorized.project.taskPage.generateVersion')}</ProjectButton> : ''}
        </FlexContainer>
      </StyledHeader>
      <StyledBr/>

      <JobWrapper>
        <JobOverview>
          <StyledBatch>
            <StyledDate>{currentDataset?.dataset?.createdAt?.slice(0, 10)}</StyledDate>
            <StyledCounter>
              {currentDataset 
                ? t('authorized.project.annotate.images', { count: +currentDataset?.imagesTotal })
                : ""
              }
            </StyledCounter>
            <StyledCounter>({currentDataset?.dataset?.annotatedTotal || '0'}) {t('authorized.project.taskPage.annotated')}</StyledCounter>
            <StyledCounter>({currentDataset?.dataset?.unannotatedTotal || '0'}) {t('authorized.project.taskPage.unannotated')}</StyledCounter>
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledTitle>{t('authorized.project.taskPage.instructions')}</StyledTitle>
              <StyledEdit>
                <StyledEditIcon/>
                <StyledTextButton onClick={onEditClick}>{t('other.edit')}</StyledTextButton>
              </StyledEdit>
            </FlexContainer>
            {
              currentDataset?.dataset?.description 
              ? <StyledCounter>{currentDataset.dataset.description}</StyledCounter>
              : <StyledCounter>{t('authorized.project.taskPage.noInstruction')}</StyledCounter>
            }
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledCounter>{t('authorized.project.projectManagement.users')}</StyledCounter>
            </FlexContainer>
            <FlexContainer direction='row' gap='5px' justify='flex-start' align='center'>
              <StyledLabeller>{t('other.labeller')}:</StyledLabeller>
              <StyledName>{currentDataset?.dataset?.annotator?.email || t('authorized.project.annotate.notAssigned')}</StyledName>
            </FlexContainer>
            <FlexContainer direction='row' gap='5px' justify='flex-start' align='center'>
              <StyledLabeller>{t('other.moderator')}:</StyledLabeller>
              <StyledName>{currentDataset?.dataset?.moderator?.email || t('authorized.project.annotate.notAssigned')}</StyledName>
            </FlexContainer>
          </StyledBatch>

          <StyledBatch>
            <FlexContainer paddingProps='0' margin='0' direction='row' justify='space-between' align='center'>
              <StyledCounter>{t('authorized.project.taskPage.classes')}</StyledCounter>
              {userRoleNumber <= 4 ? 
                <StyledEdit onClick={onAddClick}>
                  <StyledEditIcon/>
                  <StyledTextButton>{t('buttons.add')}</StyledTextButton>
                </StyledEdit>
              : ''}
            </FlexContainer>
              <StyledLabelsWrapper>
                {labels
                  ? labels.map(label => <Label isDataset={false} id={label.id} color={label.color} name={label.name} projectId={label.project} key={label.name}/>)
                  : ''
                }
              </StyledLabelsWrapper>
          </StyledBatch>
        </JobOverview>
        
        <ImagesList page={EImagesPage.datasetPage} heightProps='auto' onImageClick={navigateToDraw}/>

      </JobWrapper>
    </StyledProjects>
  )
}

export default DatasetPage