import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import { IPlusBtnProps } from '../../../models/IPlusBtnProps'

const StyledButton = styled.div<IPlusBtnProps>`
    display:flex;
    align-items: center;
    justify-content:center;

    margin:${props => props.margin || "0"};
    width: ${props => props.widthProps || '30px'};
    padding:${props => props.paddingProps || '0px'};
    height:${props => props.heightProps || '30px'};

    border: 1px solid ${Colors.gray};
    border-radius: 70px;

    background: ${({ active }) => active ? Colors.black : '#D9D9D9'};

    font-style: normal;
    font-size: ${props => props.FontSizeProps || '28px'};
    font-weight: 400;
    color: ${Colors.WHITE};
    text-align: center;
    line-height: 17px;

    transition: all 0.1s linear;     

    cursor: pointer;
    &:hover {
        font-weight: bold;
        background: ${Colors.lightblue};
        border-color: ${Colors.blue};
        box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`

const PlusBtn: FC<IPlusBtnProps> = ({children, ...props}) => {
  return (
    <StyledButton {...props}>+</StyledButton>
  )
}

export default PlusBtn