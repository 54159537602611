import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";
import { IGroupMember } from "../modules/Projects/models/IGroupMember";
import { ILocalRole } from "../models/ILocalRole";
import { ILicense } from "./LicensesService";

export interface IGroup {
    id: string;
    name: string;
    user: string;
    updatedAt: string;
    createdAt: string;
}

export interface ISelectedLicense {
  id: string;
  name: string;
  displayName: string;
  description: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  endDate: string;
  cost: number;
}

export interface IGroupLicense {
  id: string;
  groupId: string;
  licenseId: string;
  startDate: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  license: ILicense;
  cost: number;
}

export interface IShortGroup {
    id: string;
    name: string;
    updatedAt: string;
    createdAt: string;
    projects: string;
}

export interface IFullGroup {
    id: string;
    name: string;
    user: string;
    createdAt: string;
    UpdatedAt: string;
    groupMembers: IGroupMember[];
    groupLicense: IGroupLicense[];
}

export interface ICreateGroupResponce {
    group: IGroup;
    message: string;
}

export interface IGetAllGroups {
    groups: IShortGroup[];
    total: number;
}

export interface IGetGroupInfoById {
    group: IFullGroup;
    selectedLicense: ISelectedLicense;
    localRole: ILocalRole;
}

export interface IPutGroupByIdResponce {
    group: IGroup;
    message: string;
}

export interface IDeleteGroupByIdResponce {
    message: string;
}

export default class GroupService {

    static async createGroup(name: string, licenseId: string): Promise<AxiosResponse<ICreateGroupResponce>> {
        return $authHost.post<ICreateGroupResponce>('/groups', {name: name, licenseId: licenseId})
    }

    static async getAllGroups(): Promise<AxiosResponse<IGetAllGroups>> {
        return $authHost.get<IGetAllGroups>('/groups')
    }

    static async getAllOwnerGroups(): Promise<AxiosResponse<IGetAllGroups>> {
        return $authHost.get<IGetAllGroups>('/groups?owner=true')
    }

    static async getAllMemberGroups(): Promise<AxiosResponse<IGetAllGroups>> {
        return $authHost.get<IGetAllGroups>('/groups?owner=false')
    }

    static async getOneGroupById(id: string): Promise<AxiosResponse<IGetGroupInfoById>> {
        return $authHost.get<IGetGroupInfoById>(`/groups/${id}`)
    }

    static async putGroupNameById(id: string, newGroupName: string, licenseId?: string): Promise<AxiosResponse<IPutGroupByIdResponce>> {
        return $authHost.put<IPutGroupByIdResponce>(`/groups/`, {id: id, name: newGroupName, licenseId: licenseId ? licenseId : ''})
    }

    static async deleteGroupById(id: string): Promise<AxiosResponse<IDeleteGroupByIdResponce>> {
        return $authHost.delete<IDeleteGroupByIdResponce>(`/groups/${id}`)
    }

}

    

