import React, { FC } from 'react'
import { StyledDiv, StyledOptionEdit, StyledOptionInfo, StyledPreprocessingOption } from '../../../../constants/styled'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../../hooks/redux'
import { generateReducer } from '../../../../store/reducers/GenerateReducer'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { IGenerateLabel } from '../../../../../../services/VersionsService'
import RenameLabelsForm from '../../../RenameLabelsForm/RenameLabelsForm'
import { useTranslation } from 'react-i18next'

interface ITabOptionProps {
  labels: IGenerateLabel[]
  selected: boolean
}

const RenameTabOption: FC<ITabOptionProps> = ({ labels, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const onOptionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.classRename')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('500px'))
    dispatch(modalWindowSlice.actions.setChildren(<RenameLabelsForm type="dataset" />))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    dispatch(generateReducer.actions.rejectRenameLabels())
  }

  return (
    <StyledPreprocessingOption {...props}>
      <StyledOptionEdit onClick={onDeleteClick}>{t('other.delete')}</StyledOptionEdit>
      <StyledDiv onClick={onOptionClick}>
        <FlexContainer direction="row" justify="flex-start" align="center" gap="8px">
          <span>{t('authorized.project.generateAndVersions.classRename')}:</span>
        </FlexContainer>
        <FlexContainer direction="column" justify="space-between" align="flex-start">
          {labels?.map((label, index) => (
            <StyledOptionInfo key={label.newName + index}>
              {label.oldName}
              <span style={{ fontSize: '28px' }}>→</span>
              {label.newName}
            </StyledOptionInfo>
          ))}
        </FlexContainer>
      </StyledDiv>
    </StyledPreprocessingOption>
  )
}

export default RenameTabOption
