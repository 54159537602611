import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { IUserNavDropdownProps } from '../../../../../models/IUserNavDropdownProps'
import { Colors } from '../../../../../models/Colors'
import { StyledEdit, StyledEditIcon, StyledTextButton } from '../../../constants/styled'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { useAppDispatch } from '../../../../../hooks/redux'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import DropdownButton from '../../../../../UI/Buttons/DropdownButton/DropdownButton'
import { ITopicMessage } from '../../../../../services/ForumService'
import { asyncDeleteMessage } from '../../../../../store/reducers/AcyncActions'
import { IUsersMeResponse } from '../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  .username {
    height: 30px;
    padding: 5px;
  }
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 160px;
  height: auto;

  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;

  position: absolute;
  top: 60%;
  right: -20%;
  z-index: 1999;
`

interface IMessageDropDownProps {
  message: ITopicMessage
  user: IUsersMeResponse
  setIsEditClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const MessageDropdown: FC<IMessageDropDownProps> = ({ message, user, setIsEditClicked }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(true)
  }

  const onDropdownMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(false)
  }

  const onSettingsMouseOver = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setActiveColor(!activeColor)
  }

  const onButtonDeleteClick = () => {
    setModalVisivble()
    dispatch(asyncDeleteMessage(message.id))
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setTitle(t('forum.deleteMessage')))
    dispatch(
      modalWindowSlice.actions.setChildren(
        <FlexContainer direction="row" justify="space-around" paddingProps="20px 0 0 0">
          <ProjectButton onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onButtonDeleteClick}>{t('other.delete')}</ProjectButton>
        </FlexContainer>
      )
    )
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsEditClicked(false)
    setDropdownVisible(false)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <StyledDropDown>
      <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
        <DropdownButton onClick={onEditClick} heightProps={'30px'}>
          {t('other.edit')}
        </DropdownButton>
        <DropdownButton onClick={onDeleteClick} colorProps="red" heightProps={'30px'}>
          {t('other.delete')}
        </DropdownButton>
      </StyledDropdownContent>
      <StyledEdit onClick={onDropdownClick}>
        <StyledEditIcon onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave} />
        <StyledTextButton onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}>
          {t('other.edit')}
        </StyledTextButton>
      </StyledEdit>
    </StyledDropDown>
  )
}

export default MessageDropdown
