import React, { useEffect, useState } from 'react'
import { IActivateLicenceFormProps } from '../../constants/interfaces'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { StyledCenteredText, StyledCheckboxWrapper } from '../../constants/styled'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import InputRadio from '../../../../UI/InputRadio/InputRadio'
import TopUpBalanceForm from '../TopUpBalanceForm/TopUpBalanceForm'
import { StyledErrorText } from '../../../Projects/constants/Styled';
import { asyncExtendLicense } from '../../../../store/reducers/AcyncActions'
import { Colors } from '../../../../models/Colors'
import Loader from '../../../../UI/Loader/Loader'
import { ProjectBr } from '../../../../styles/GlobalStyles'
import { useTranslation } from 'react-i18next'

const ActivateLicenceForm: React.FC<IActivateLicenceFormProps> = ({group, license, months, user, getRenderСondition, cost}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { error, isLoading } = useAppSelector(state => state.SettingsReducer)
  
  const [extendsCost, setExtendsCost] = useState(0)
  
  const [withCustomQuotas, setWithCustomQuotas] = useState(true)
  const [isExtendLicenseChecked, setIsExtendLicenseChecked] = useState(true)

  useEffect(() => {
    if (license.fullCost > license.baseCost) {
      setExtendsCost(license.fullCost - license.baseCost)
    }
  }, [license])

  const onGoBackClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onActivateClick = () => {
    const isNoMoney = withCustomQuotas ? user.balance < cost + extendsCost : user.balance < cost
    const isExtendLicense = getRenderСondition('extendLicense')
    if (isNoMoney) {
      dispatch(modalWindowSlice.actions.setTitle(`${t('authorized.settings.noMoney')} :(`))
      dispatch(modalWindowSlice.actions.setTitleColor(Colors.red))
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.topUpForm.formTitle')))
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        if (extendsCost && withCustomQuotas) {
          dispatch(modalWindowSlice.actions.setChildren(<TopUpBalanceForm license={license} user={user} group={group} discountCost={cost + extendsCost}/>))
          dispatch(modalWindowSlice.actions.setVisible())
          return
        }
        dispatch(modalWindowSlice.actions.setChildren(<TopUpBalanceForm license={license} user={user} group={group} discountCost={cost}/>))
        dispatch(modalWindowSlice.actions.setVisible())
      }, 2000)
      return
    }
    if (isExtendLicense) {
      dispatch(asyncExtendLicense({groupId: group.id, licenseId: license.id, months: months, autoRenewLicense: isExtendLicenseChecked, withCustomQuotas: withCustomQuotas}))
    }
  }

  const handleLicenseCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsExtendLicenseChecked(e.target.checked)
  }

  const handleExtendsCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWithCustomQuotas(e.target.checked)
  }

  
  return (
    <>
      {isLoading && !error && 
        <FlexContainer direction='column' justify='center' align='center' gap='10px'>
            <Loader/>
        </FlexContainer>
      }
      {!isLoading && 
        <FlexContainer direction='column' justify='flex-start' align='center' gap='10px'>
          <StyledCenteredText>{license.displayName}</StyledCenteredText>
          {error ? <StyledErrorText visible>{error}</StyledErrorText> : ''}
          {extendsCost 
            ? <StyledCheckboxWrapper>
                <InputRadio
                  widthProps='14px'
                  heightProps='14px'
                  checked={withCustomQuotas}
                  onChange={(e) => handleExtendsCheckedChange(e)}
                  type="checkbox"
                  label={t('authorized.settings.selectLicenseForm.withExtends')}  
                />
              </StyledCheckboxWrapper>
            : ''
          }

          <StyledCheckboxWrapper>
            <InputRadio
              widthProps='14px'
              heightProps='14px'
              checked={isExtendLicenseChecked}
              onChange={(e) => handleLicenseCheckedChange(e)}
              type="checkbox"
              label={t('authorized.settings.selectLicenseForm.checkbox')}  
            />
          </StyledCheckboxWrapper>
          <ProjectBr/>
          <FlexContainer direction='row' justify='space-between' align='center' paddingProps='10px 20px'>
              <span>Итого:</span>
              {extendsCost && withCustomQuotas
                ? <span>{cost + extendsCost + ' '}RV/{t('other.month')}</span>
                : <span>{cost + ' '}RV/{t('other.month')}</span>
              }
          </FlexContainer>  


          {/* <InputRadio
            widthProps='14px'
            heightProps='14px'
            checked={isExtendBalanceChecked}
            onChange={(e) => handleBalanceCheckedChange(e)}
            type="checkbox"
            label={'Ежемесячное пополнение'}  
          /> */}
          <FlexContainer direction='row' justify='space-around' margin='15px 0 0 0'>
            <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onGoBackClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onActivateClick} active>{t('buttons.activate')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
    </>
  )
}

export default ActivateLicenceForm