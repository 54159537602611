import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactChild, ReactNode } from 'react';

interface IReducerState {
    title: string;
    children: ReactNode | ReactChild;
    visible: boolean;
    reassignVisible: boolean;

    batchAssignVisible: boolean;
}

const initialState: IReducerState = {
    title: '',
    children: '',
    visible: false,
    reassignVisible: false,

    batchAssignVisible: false,
}

export const assignNavReducer = createSlice(
{
    name: 'assignNav',
    initialState,

    reducers: {
        
    
        setChildren(state, action: PayloadAction<ReactNode | ReactChild>) {
          state.children = action.payload
        },
        setVisible(state) {
          state.visible = !state.visible
        },
        setVisibleFalse(state) {
          state.visible = false
        },
        setBatchAssignVisible(state) {
          state.batchAssignVisible = !state.visible
        },
        setBatchAssignVisibleFalse(state) {
          state.batchAssignVisible = false
        },
        setReassignVisible(state) {
          state.reassignVisible = !state.reassignVisible
        },
        setReassignVisibleFalse(state) {
          state.reassignVisible = false
        },
        setTitle(state, action: PayloadAction<string>) {
          state.title = action.payload
        },
        setInititialState(state) {
          state.visible = false;
          state.children = '';
          state.title = '';
          state.batchAssignVisible = false;
        },
      }
},

)

export default assignNavReducer.reducer;