import React, { FC, useState } from 'react'
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../../../hooks/redux';
import { ISummaryTask } from '../../../../../../../services/TasksService';
import { asyncRenameTask } from '../../../../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import Input from './../../../../../../../UI/Input/Input';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../../services/ProjectService';

interface IRenameJobFormProps {
  projectInfo: IProject;
  jobInfo: ISummaryTask;
}

const RenameAnnotatingJobForm: FC<IRenameJobFormProps> = ({projectInfo, jobInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [jobName, setJobName] = useState('')

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobName(e.target.value)
  }

  const onButtonRenameClick = () => {
    let disctiption = jobInfo?.description? jobInfo?.description : ''
    dispatch(asyncRenameTask(
      jobInfo?.task, 
      jobName, 
      disctiption, 
      jobInfo?.status?.id, 
      jobInfo?.moderator?.id ? jobInfo?.moderator?.id : '', 
      jobInfo?.annotator?.id ? jobInfo?.annotator?.id : ''))
    setJobName('')
    dispatch(modalWindowSlice.actions.setInititialState())
  }
  
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.project.annotate.forms.newNameTitle')} placeholder={t('forum.enterText')} value={jobName} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton widthProps='140px' onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton widthProps='140px' onClick={onButtonRenameClick}>{t('other.rename')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameAnnotatingJobForm