import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Global from './styles/GlobalStyles';
import { BrowserRouter as Router } from "react-router-dom";
import { setupStore } from './store/store';
import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import './i18n'

const store = setupStore()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <SkeletonTheme baseColor='#313131' highlightColor='#525252'>
    <Router>
      <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
          <App/>
        </Suspense>
        <Global/>
      </Provider>
    </Router>
  </SkeletonTheme>
);

