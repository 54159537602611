import React, { useEffect, useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeLineStyles.css'
import { Colors } from '../../models/Colors';
import styled from 'styled-components';
import FlexContainer from '../../components/FlexContainer/FlexContainer';

export interface IStoryPoint {
    userId: string | number;
    firstname: string;
    lastname: string;
    action: string;
    date: string;
}

interface ITimelineProps {
    title?: string;
    storyPoints: IStoryPoint[];
    limit: number;
}

interface StyledTimeLineWrapperProps {
  heightProps: number;
}

let sp = [
    {
        userId: "124mmgujp/cv3",
        firstname: 'Floppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "12fahdgvnfs3",
        firstname: 'Floppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "1fh2o3",
        firstname: 'Floppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    },
    {
        userId: "121asdfaasdfasdf233",
        firstname: 'Floppa',
        lastname: 'cat',
        action: 'updated the labeling instructions at Task.',
        date: '08-08-2023 at 19:00'
    }, 
]

const StyledTimeLineWrapper = styled.div`
  padding: 10px 0 0 0;
`

const StyledTimeLineTitle = styled.div`
  margin: 0 0 10px 12px;
`

const StyledTimeLine = styled.div<StyledTimeLineWrapperProps>`
  max-width: 270px;
  width: 100%;
  height: ${({heightProps}) => heightProps + 'px'|| 'auto'};
  max-height: ${({heightProps}) => heightProps + 'px'|| 'auto'};

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

const StyledTimelineViewAll = styled.div`
  margin: 0 0 10px 12px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    color: ${Colors.orange};
  }
`

const TimeLine: React.FC<ITimelineProps> = ({title, storyPoints, limit, ...props}) => {

  const [isViewAll, setIsViewAll] = useState(false)
  const [sp, setSp] = useState<IStoryPoint[]>([])

  useEffect(() => {
    if (storyPoints?.length) {
      const spCopy = JSON.parse(JSON.stringify(storyPoints))
      if (!isViewAll) {
        const slicedSp = spCopy.slice(0, limit).reverse()
        setSp(slicedSp)
      }
      if (isViewAll) {
        setSp(spCopy.reverse())
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewAll])

  return (
    <StyledTimeLineWrapper>
      <FlexContainer direction='row' justify='space-between' align='center'>
        <StyledTimeLineTitle className='timeline-title'>
          {title || ''}
        </StyledTimeLineTitle>
        <StyledTimelineViewAll className='timeline-title' onClick={() => setIsViewAll(!isViewAll)}>
          {limit < storyPoints?.length ? 'View All' : ''}
        </StyledTimelineViewAll>
      </FlexContainer>
      <StyledTimeLine heightProps={sp?.length * 120}>
        <VerticalTimeline className='timeline' layout='1-column-left' lineColor={Colors.orange} >
          {sp?.map((point, index: number) => 
            
            <VerticalTimelineElement
              textClassName='timeline-text'
              className='timeline-elem'
              key={index}
              date={point.date}
              dateClassName="date"
              iconStyle={{ background: Colors.black }}
              iconClassName='timeline-icon'
              icon={<div className='timelineUserWord'>{point?.firstname? point?.firstname[0] : 'q'}</div>}
            >
              <div className="vertical-timeline-element-title">
                {point.action}
              </div>
            </VerticalTimelineElement>
          )}
        </VerticalTimeline>
      </StyledTimeLine>
    </StyledTimeLineWrapper>


  )
}

export default TimeLine