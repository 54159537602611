import React, { FC } from 'react'
import styled from 'styled-components';
import { Colors } from '../../../models/Colors';

interface ILabelProps {
  id: string;
  color: string;
  name: string;
  projectId: string;
  onLabelClick: (id: string, name: string, color: string) => void;
  currentLabelName: string;
}

interface ILabelNameProps {
  colorProps: string;
  active?: boolean;
}

const StyledLabelName = styled.span<ILabelNameProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  border-color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
  background-color: ${({active}) => active ? Colors.black : ''};
  min-width: 50px;
  height: 8;
  font-size: 12px;
  font-weight: bold;

  width: 100%;
  padding: 2px 5px;

  cursor: pointer;
  transition: all .1s linear;

  :hover {
    color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
    background-color: ${Colors.black};
  }
`
const StyledLabelColor = styled.div<ILabelNameProps>`
  width: 8px;
  height: 8px;
  border-radius: 15px;
  background-color: ${({colorProps}) => colorProps ? colorProps : Colors.textgray};
`

const DrawLabel:FC<ILabelProps> = ({id, color, name, projectId, onLabelClick, currentLabelName, ...props}) => {

  const onLabelCLick = () => {
    onLabelClick(id, name, color)
  }

  return (
    <StyledLabelName colorProps={color} onClick={onLabelCLick} active={currentLabelName === name}>
      {name}
      <StyledLabelColor colorProps={color}/>
    </StyledLabelName>
  )
}

export default DrawLabel