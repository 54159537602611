import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInputsErrorTotal {
  isPasswordError: boolean;
  isPasswordMismatchError: boolean;
  total: boolean;

}

const initialState: IInputsErrorTotal = {
  isPasswordError: false,
  isPasswordMismatchError: false,
  total: false
}

export const passwordResetErrorTotal = createSlice(
{
    name: 'passwordResetErrorTotal',
    initialState,

    reducers: {
      setIsPasswordError(state, action: PayloadAction<boolean>) {
        state.isPasswordError = action.payload
        if (state.isPasswordError && state.isPasswordMismatchError) {
            state.total = true
        }
      },
      setIsPasswordMismatchError(state, action: PayloadAction<boolean>) {
        state.isPasswordMismatchError = action.payload
        if (state.isPasswordError && state.isPasswordMismatchError) {
            state.total = true
        }
      },
      
    }
},
)

export default passwordResetErrorTotal.reducer;