import React, { FC } from 'react'
import { NavigateFunction } from 'react-router-dom';
import styled from 'styled-components'
import { Colors } from '../../../../../../../models/Colors';
import BatchDropdown from './UnassignedBatchDropdown/UnassignedBatchDropdown';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { ISummaryBatch, batchesReducer } from '../../../../../store/reducers/BatchesReducer';
import { imagesListSlice } from '../../../../../../ImagesList/store/ImagesListReducer';
import { IProject } from '../../../../../../../services/ProjectService';

interface IUnnanotatedBatchProps {
  date: string;
  counter: number;
  projectInfo: IProject;
  navigate: NavigateFunction;
  batchInfo: string;
  batch: ISummaryBatch
}

const StyledBatch = styled.div`
  width: 100%;
  height: 70px;
  padding: 10px 35px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  border: 1px solid ${Colors.gray};
  border-radius: 5px;
  position: relative;
  transition: all 0.1s linear;     
  cursor: pointer;
  &:hover {
    color: ${Colors.orangeSaturated};
    background: ${Colors.black};
    border: 1px solid ${Colors.orange};
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`
const StyledDate = styled.h6`
  color: ${Colors.textgray};
  max-width: 175px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledCounter = styled.h6`
  color: ${Colors.textgray};
`
const StyledDropdown = styled.div`
  width: 30px;
  height: 30px;

  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.1s linear; 
  cursor: pointer;
  :hover img {
    box-shadow: 0 4px 30px rgba(0,0,0,0.25), 0 10px 30px rgba(0,0,0,0.22);
  }
  z-index: 1;
`

const UnassignedBatch: FC<IUnnanotatedBatchProps> = ({date, counter, projectInfo, navigate, batchInfo, batch, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(state => state.batchesReducer.isLoading)

  const onBatchClick = () => {
    if (batchInfo && projectInfo.id) {
      dispatch(imagesListSlice.actions.setInitialState())
      dispatch(batchesReducer.actions.setCurrentSummaryBatch(batch))
      navigate(`/projects/${projectInfo.id}/batch/${batchInfo}`)
    }
  }
  
  return (
    <StyledBatch onClick={onBatchClick}>
      <StyledDropdown>
        <BatchDropdown projectInfo={projectInfo} batchInfo={batchInfo}/>
      </StyledDropdown>
      <StyledDate>{date}</StyledDate>
      <StyledCounter>{t('authorized.project.annotate.images', { count: +counter })}</StyledCounter>
    </StyledBatch>
  )
}

export default UnassignedBatch