import React, { FC, useEffect, useState } from 'react'
import { StyledGroupUserCard, StyledUserCardAvatar, StyledUserCredentials, StyledUserEmail } from '../../../../constants/styled'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledRoleName } from '../../../../../Projects/constants/Styled'
import { IProjectUserFullInfo } from '../../../../../Projects/store/reducers/WorkspacesReducer'
import { IProject } from '../../../../../../services/ProjectService'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import styled from 'styled-components'
import { StyledUserCol } from './StatisticsTable'
import { statisticsSlice } from '../../../../store/reducers/StatisticsReducer'
import { IUserTasksStat } from '../../../../../../services/StatsService'

interface ProjectUserCardProps {
    userInfo: IProjectUserFullInfo['user'];
    userRole: IProjectUserFullInfo['projectRole'];
    projectUsersInfo: IProjectUserFullInfo[];
    projectInfo: IProject;
    index: number;
    selectedRole: string;
    totals: {
      annotated: number;
      bboxes: number;
      annotatorAverage: number;
  
      approved: number;
      rejected: number;
      moderatorAverage: number;
    }
}

export const StyledTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  min-width: 120px;
  width: 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const StyledRole = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  min-width: 120px;
  width: 120px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

interface IStyledRoleNameProps {
  color?: string;
}

export const StyledRatingNumber = styled.h4<IStyledRoleNameProps>`
  text-align: center;
  width: 50px;

  color: ${({color}) => color || 'white'};
`

const StyledInput = styled.input`
    width: 15px;
    height: 15px;
`

const UserCard: FC<ProjectUserCardProps> = ({userInfo, selectedRole, userRole, projectUsersInfo, projectInfo, index, totals, ...props}) => {
  const dispatch = useAppDispatch()
  const { usersColors, usersTasksStat, selectedUsers } = useAppSelector(state => state.StatisticsReducer)
  const [findedUserColor, setFindedUserColor] = useState('')

  const [checked, setChecked] = useState(false)
  const [inWork, setInWork] = useState(0)
  
  useEffect(() => {
    const findedUser = selectedUsers.find(user => user === userInfo.id)
    if (findedUser) {
      setChecked(true)
    }
    if (!findedUser) {
      setChecked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedUsers])

  // useEffect(() => {
  //   dispatch(statisticsSlice.actions.setUser({id: userInfo.id, color: TableColors[index], role: userRole.name}))
  // },[index])

  useEffect(() => {
    console.log('usersTasksStat', usersTasksStat)
    if (usersColors?.length) {
      const findedUser = usersColors.find(user => user.id === userInfo.id)
      if (findedUser) {
        setFindedUserColor(findedUser.color)
      }
    }
    if (usersTasksStat?.length) {
      const findedStat = usersTasksStat.find((stat: IUserTasksStat) => stat.userId === userInfo.id)
      console.log('findedStat', findedStat)
      if (findedStat && selectedRole === "project_annotator") {
        setInWork(findedStat.unannotatedImages!)
      }
      if (findedStat && selectedRole === "project_moderator") {
        setInWork(findedStat.reviewImages!)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersColors, usersTasksStat])

  const onCheckboxClick = () => {
    dispatch(statisticsSlice.actions.setSelectedUser(userInfo.id))
  }

  return (
    <StyledGroupUserCard>
        <StyledRatingNumber color={findedUserColor}>{index + 1}</StyledRatingNumber>

        <StyledUserCol>
            <FlexContainer direction='row' justify='flex-start' align='center' gap='5px'>
                <StyledUserCardAvatar minWidth="25px" heightProps='25px' widthProps='25px'/>
                <FlexContainer direction='column' justify='center' align='flex-start'>
                    <StyledUserCredentials>{userInfo.firstName? userInfo.firstName + ' ' + userInfo.lastName : 'Invited User'}</StyledUserCredentials>
                    <StyledUserEmail>{userInfo.email}</StyledUserEmail>
                </FlexContainer>
            </FlexContainer>
        </StyledUserCol>

        <StyledRole>
            <StyledRoleName color={findedUserColor}>{userRole.displayName.split(' ')[0]}</StyledRoleName>
        </StyledRole>

        {selectedRole === 'project_annotator' ? <StyledTableItem>{inWork}</StyledTableItem>: ''}
        {selectedRole === 'project_annotator' ? <StyledTableItem>{totals.annotated}</StyledTableItem>: ''}
        {selectedRole === 'project_annotator' ? <StyledTableItem>{totals.bboxes}</StyledTableItem>: ''}
        {selectedRole === 'project_annotator' ? <StyledTableItem>{totals.annotatorAverage}</StyledTableItem>: ''}

        {selectedRole === 'project_moderator' ? <StyledTableItem>{inWork}</StyledTableItem>: ''}
        {selectedRole === 'project_moderator' ? <StyledTableItem>{totals.approved}</StyledTableItem>: ''}
        {selectedRole === 'project_moderator' ? <StyledTableItem>{totals.rejected}</StyledTableItem>: ''}
        {selectedRole === 'project_moderator' ? <StyledTableItem>{totals.moderatorAverage}</StyledTableItem>: ''}

        <StyledTableItem>
            <StyledInput type='checkbox' checked={checked} onClick={onCheckboxClick}/>
        </StyledTableItem>
    </StyledGroupUserCard>
  )
}

export default UserCard