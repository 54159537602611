import React from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useTranslation } from 'react-i18next';
import Input from '../../../../UI/Input/Input';
import { useAppDispatch } from '../../../../hooks/redux';
import { asyncDeleteProject } from '../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import { CenteredText } from '../../../../styles/GlobalStyles';
import { Colors } from '../../../../models/Colors';
import { IProject } from '../../../../services/ProjectService';

interface IDeleteProjectFormProps {
    setModalVisivble: () => void;
    projectInfo: IProject;
}

const DeleteProjectForm: React.FC<IDeleteProjectFormProps> = ({setModalVisivble, projectInfo}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [inputValue, setInputValue] = React.useState('')

  const onButtonDeleteClick = () => {
    if (inputValue !== projectInfo.name) {
      return
    }
    dispatch(asyncDeleteProject(projectInfo.id))
    setModalVisivble()
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction='column' justify='space-between' align='center' gap='10px'>
      <CenteredText>{t('authorized.projects.groupProjects.project.deletePrText')}</CenteredText>
      <CenteredText color={Colors.orange}>{projectInfo.name}</CenteredText>
      <Input
        type='text'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={t('authorized.projects.groupProjects.enterName')}
        label={t('authorized.projects.groupProjects.project.deleteProjectPl')}
      />  
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton FontSizeProps='16px' onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton FontSizeProps='16px' onClick={onButtonDeleteClick} disabled={inputValue !== projectInfo.name}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>

  )
}

export default DeleteProjectForm