import React, { FC } from 'react'
import styled from 'styled-components';

export interface IFlexContainerProps {
  direction?: "column" | "row";
  align?: "flex-start" | "center" | "flex-end" | "space-between";
  justify?: string;
  margin?: string;
  children?:  React.ReactChild | React.ReactNode;
  widthProps?: string;
  heightProps?: string;
  gap?:string;
  maxwidthProps?:string;
  paddingProps?: string;
  self?:string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: () => void;
  onMouseOver?: () => void;
  wrap?: string;
  fsize?: string;
  id?: string;
}

const StyledFlexContainer = styled.div<IFlexContainerProps>`
    display: flex;
    height: ${props => props.heightProps || 'auto'} ;
    max-width: ${props => props.maxwidthProps || '100%'} ;
    width: ${props => props.widthProps || '100%'} ;
    flex-direction: ${props => props.direction || 'row'} ;
    align-items: ${props => props.align || 'stretch'} ;
    justify-content: ${props => props.justify || 'stretch'} ;
    gap: ${props => props.gap || 'stretch'} ;
    margin: ${({margin}) => margin || '0'} ;
    padding:${props => props.paddingProps || '0px'};
    align-self: ${props => props.self || ''};
    flex-wrap: ${props => props.wrap || ''};
    font-size: ${({fsize}) => fsize || '' };
`
const FlexContainer: FC<IFlexContainerProps> = (props) => {
  return <StyledFlexContainer {...props}>{props.children}</StyledFlexContainer>
}

export default FlexContainer