import React, { FC, useEffect, useState } from 'react'
import {
  StyledLastSubCategoryPostWrapper,
  StyledSubCategoryDate,
  StyledSubCategoryLastHeader,
  StyledSubCategoryUserLink,
  StyledSubCategoryUserSpan,
  StyledUserIcon
} from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useTranslation } from 'react-i18next'

interface IMessage {
  id: string
  title: string
  content: string
  likes: number
  createdAt: string
  author: {
    id: string
    email: string
    firstName: string
  }
}

interface ILastMessageProps {
  messageInfo: IMessage
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const TopicLastMessage: FC<ILastMessageProps> = ({ messageInfo, ...props }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState<any>('')
  useEffect(() => {
    if (messageInfo) {
      setTitle(messageInfo?.content?.charAt(0).toUpperCase() + messageInfo?.content?.slice(1))
    }
  }, [messageInfo])
  return (
    <StyledLastSubCategoryPostWrapper {...props}>
      <StyledUserIcon />
      <FlexContainer direction="column" justify="flex-start" align="flex-start">
        <StyledSubCategoryLastHeader>{title.replace(/<[^>]+>/g, '')}</StyledSubCategoryLastHeader>
        <FlexContainer direction="row" justify="flex-start" align="center">
          <StyledSubCategoryUserSpan>{t('forum.By')}</StyledSubCategoryUserSpan>
          <StyledSubCategoryUserLink>{messageInfo?.author?.firstName}</StyledSubCategoryUserLink>
        </FlexContainer>
        <StyledSubCategoryDate>{messageInfo?.createdAt?.slice(0, 10)}</StyledSubCategoryDate>
      </FlexContainer>
    </StyledLastSubCategoryPostWrapper>
  )
}

export default TopicLastMessage
