import { $authHost } from "../http/index"
import { AxiosResponse } from "axios";

export interface IStatDataset {
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  } | any;
  data: number[];
}

export interface IGetStatResponce {
  dates: string[];
  datasets: IStatDataset[]
}

export interface IGetStatActionsResponce {
  actions: {
    id: string;
    name: string;
    displayName: string;
    description: string;
    counter: boolean;
  }[]
}

export interface IUserTasksStat {
  userId: string;
  role: string;
  tasksInWork: number;
  reviewImages?: number;
  rejectedImages?: number;
  approvedImages?: number;
  unknownStatusImages?: number;
  unannotatedImages?: number;
  annotatedImages?: number;
  totalImages: number;
}

export interface IGetUserTasksStatsRes {
  userTasksStats: IUserTasksStat[];
}

export interface ITimelineAction {
  id: string;
  name: string;
  displayName: string;
  description: string;
  counter: boolean;
}

export interface ITimelineUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ITimelineDetaills {
  id: string;
  name: string;
  userId: string;
  project: string;
  createdAt: string;
  updatedAt: string;
}

export interface ITimeline {
  id: string;
  timestamp: string;
  entityId: string;
  entityType: string;
  details: ITimelineDetaills;
  user: ITimelineUser;
  action: ITimelineAction;
}

export interface IGetTimelinesRes {
  timelines: ITimeline[];
  total: number;
  remain: number;
}

export default class StatsService {
  static async getStat(userIds: string, projectId: string, startDate: string, endDate: string, actionNames: string, includeMinMax?: boolean): Promise<AxiosResponse<IGetStatResponce>> {
    return $authHost.get<IGetStatResponce>(`/stats/?projectId=${projectId}&userIds=${userIds}&startDate=${startDate}&endDate=${endDate}&actionName=${actionNames}&includeMinMax=${includeMinMax}`)
  }

  static async getStatActions(): Promise<AxiosResponse<IGetStatActionsResponce>> {
    return $authHost.get<IGetStatActionsResponce>(`/stats/actions`)
  }

  static async getUserTasksStats(projectId: string, userIds: string, roleNames: string, roleIds?:string): Promise<AxiosResponse<IGetUserTasksStatsRes>> {
    return $authHost.get<IGetUserTasksStatsRes>(`/stats/user-tasks?projectId=${projectId}&userIds=${userIds}&roleNames=${roleNames}`)
  }

  static async getTaskTimelines(entityId: string, entityType: string): Promise<AxiosResponse<IGetTimelinesRes>> {
    return $authHost.get<IGetTimelinesRes>(`/stats/timelines?entityId=${entityId}&entityType=${entityType}`)
  }

  static async getUserTimelines(userId: string): Promise<AxiosResponse<IGetTimelinesRes>> {
    return $authHost.get<IGetTimelinesRes>(`/stats/timelines?userId=${userId}`)
  }
}
