import React, { FC, useEffect } from 'react'
import { asyncGetGroupAndUsersInfo, asyncGetGroupProjects, asyncGetProjectInfoAndUsers } from '../../../store/reducers/AcyncActions'
import { useAppDispatch, useAppSelector } from './../../../hooks/redux';
import { ITabsContentWrapperProps } from '../models/ITabsContentWrapperProps'
import ProjectOverview from './TabsContentPages/ProjectOverview/ProjectOverview'
import { useParams } from 'react-router-dom'
import ProjectUploadTab from './TabsContentPages/ProjectUploadTab/DropFileInput'
import ProjectAssign from './TabsContentPages/ProjectAssign/ProjectAssign'
import ProjectAnnotate from './TabsContentPages/ProjectAnnotate/ProjectAnnotate'
import JobPage from './TabsContentPages/ProjectAnnotate/components/JobPage/JobPage';
import DatasetPage from './TabsContentPages/ProjectAnnotate/components/DatasetPage/DatasetPage';
import BatchPage from './TabsContentPages/ProjectAnnotate/components/BatchPage/BatchPage';
import ProjectDatasets from './TabsContentPages/ProjectDatasets/ProjectDatasets';
import ProjectGenerate from './TabsContentPages/ProjectGenerate/ProjectGenerate';
import ProjectVersions from './TabsContentPages/ProjectVersions/ProjectVersions';
import ProjectHealthCheck from './TabsContentPages/ProjectHealthCheck/ProjectHealthCheck';
import ProjectManagement from './TabsContentPages/ProjectManagement/ProjectManagement';
import ProjectStatistics from './TabsContentPages/ProjectStatistics/ProjectStatistics';

// const StyledTabsContentWrapper = styled.div`
//   border-radius: 5px;
//   background-color: ${Colors.dark};
//   width: 100%;
// `

const TabsContentWrapper: FC<ITabsContentWrapperProps> = ({navigate, roleNumber, projectRoles, projectInfo, ...props}) => {
  const projectUsersInfo = useAppSelector(state => state.WorkspacesReducer.projectUsersInfo)
  const dispatch = useAppDispatch()
  const params = useParams()

  useEffect(() => {
    if (projectInfo.id && projectInfo.group) {
      dispatch(asyncGetGroupAndUsersInfo(projectInfo.group))
      dispatch(asyncGetGroupProjects(projectInfo.group))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectInfo])

  useEffect(() => {
    if (params.id) {
      dispatch(asyncGetProjectInfoAndUsers(params.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
  <>
      {params.tab === 'manage' && !params.userId? 
        <ProjectManagement 
          navigate={navigate} 
          projectInfo={projectInfo} 
          param={params.tab}
          projectRoles={projectRoles}
          roleNumber={roleNumber}
          projectUsersInfo={projectUsersInfo}
        /> : ''}
      {params.tab === 'overview' ? <ProjectOverview navigate={navigate} projectInfo={projectInfo} param={params.tab}/> : ''}
      {params.tab === 'statistics' ? <ProjectStatistics navigate={navigate} projectInfo={projectInfo} param={params.tab} roleNumber={roleNumber}/> : ''}
      {params.tab === 'upload' && roleNumber <= 4 ? <ProjectUploadTab navigate={navigate} projectInfo={projectInfo} param={params.tab}/> : ''}
      {params.tab === 'assign' && roleNumber <= 4 ? <ProjectAssign navigate={navigate} projectInfo={projectInfo} param={params.tab}/> : ''}
      {/* Annotate tab start */}
      {params.tab === 'annotate' ? <ProjectAnnotate navigate={navigate} projectInfo={projectInfo}></ProjectAnnotate> : ''}
      {params.tab === 'batch' && roleNumber <= 4 ? <BatchPage navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
      {params.tab === 'job' ? <JobPage navigate={navigate} projectInfo={projectInfo} param={params}></JobPage> : ''}
      {params.tab === 'dataset' && roleNumber <= 4 ? <DatasetPage navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
      {/* Annotate tab end */}
      {params.tab === 'datasets' && roleNumber <= 4 ? <ProjectDatasets navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
      {params.tab === 'generate' && roleNumber <= 4 ? <ProjectGenerate navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
      {params.tab === 'versions' && roleNumber <= 4 ? <ProjectVersions navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
      {params.tab === 'health'   ? <ProjectHealthCheck navigate={navigate} projectInfo={projectInfo} param={params}/> : ''}
  </>

  )
}

export default TabsContentWrapper