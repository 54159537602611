import styled from "styled-components"
import { Colors } from "../../../models/Colors"
import { IProjectUploadTabWrapper } from "../models/IProjectUploadTab"
import loupeIcon from '../../../assets/icons/loupe-svgrepo-com.svg'
import { IInputProps } from "../../../models/IInputProps"
import historyIcon from '../../../assets/icons/drawIcons/history-svgrepo-com.svg'
import userPlusIcon from '../../../assets/icons/user-plus-1.svg'
import usersGroupIcon from '../../../assets/icons/users-group-rounded.svg'
import inputSearchIcon from '../../../assets/icons/search-loupe-input.svg'
import userCardAvatar from '../../../assets/icons/avatar.png'
import crossIcon from '../../../assets/icons/cross.svg'
import markIcon from '../../../assets/icons/mark.svg'
import jsonIcon from '../../../assets/icons/json-file-icon.svg'
import xmlIcon from '../../../assets/icons/xml-icon.svg'
import txtIcom from '../../../assets/icons/txt-icon.svg'
import { StyledArrowLeftIcon } from "../../Projects/constants/Styled"

interface IIconProps {
  color?: string;
  width?: string;
  height?: string;
  hoverColor?: string;
  cursorProps?: boolean;
}

export const StyledJsonIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${jsonIcon});
  mask-image: url(${jsonIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${Colors.orange};
  width: 50px;
  height: 50px;
`
export const StyledXmlIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${xmlIcon});
  mask-image: url(${xmlIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${Colors.orange};
  width: 50px;
  height: 50px;
`
export const StyledTxtIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${txtIcom});
  mask-image: url(${txtIcom});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${Colors.orange};
  width: 50px;
  height: 50px;
`

export const StyledUserPlusIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${userPlusIcon});
  mask-image: url(${userPlusIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

export const StyledUsersGroupIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${usersGroupIcon});
  mask-image: url(${usersGroupIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledInputSearchIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${inputSearchIcon});
  mask-image: url(${inputSearchIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
`

export const StyledCrossIcon = styled.i<IIconProps>`
  display: block;
  position: relative;
  z-index: 2;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${crossIcon});
  mask-image: url(${crossIcon});
  background-color:  ${props => props.color || Colors.red};
  width: 22px;
  height: 22px;
  margin-bottom: 5px;

  cursor: ${props => props.cursorProps ? 'default' : 'pointer'};
  transition: all .1s linear;
  :hover {
    background-color: ${props => props.hoverColor || Colors.lightred};
  }
`

export const StyledMarkIcon = styled.i<IIconProps>`
  display: block;
  position: relative;
  z-index: 2;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${markIcon});
  mask-image: url(${markIcon});
  background-color:  ${props => props.color || Colors.green};
  width: 22px;
  height: 17px;
  margin-bottom: 5px;

  cursor: ${props => props.cursorProps ? 'default' : 'pointer'};

  transition: all .1s linear;
  :hover {
    background-color: ${props => props.hoverColor || Colors.lightgreen};
  }
`

interface IUserCardAvatarProps {
  img?: any;
  widthProps?: string;
  heightProps?: string;
  minWidth?: string;
}

export const StyledUserCardAvatar = styled.i<IUserCardAvatarProps>`
  display: block;
  background: url(${props => props.img || userCardAvatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: ${props => props.widthProps ? props.widthProps : '45px'};
  height: ${props => props.heightProps ?  props.heightProps : '45px'};
  min-width: ${props => props.minWidth ? props.minWidth : '0'};
  border-radius: 50px;
`

export const StyledHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  height: 55px;
  padding: 5px 15px;
`
export const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 55px;
`
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 30px 30px 30px;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .dragover {
    opacity: 0.6;
  }
`
export const StyledDropFileDiv = styled.div<IProjectUploadTabWrapper>`
  position: relative;
  width: 100%;
  height: 300px;
  border: 2px dashed ${Colors.orange};
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  opacity: ${({ isDragProps }) => isDragProps ? `opacity: 0.6;` : ''};;
  .dragover {
    opacity: 0.6;
  }

  :hover {
    opacity: 0.6;
  }
`
export const StyledDropFileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index:100;
`
export const StyledInputLabel = styled.div`
  text-align: center;
  font-weight: 600;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${Colors.textgray};
  img {
    width: 100px;
  }
`
export const StyledImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%; 
  height: auto;
  overflow: auto;
  padding: 15px 15px 0 15px;
  margin-top: 10px;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
export const StyledImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #4a4a4a4f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
`
export const StyledImg = styled.img`
  max-width: 70px;
  max-height: 70px;
`
export const StyledDeleteBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;
  background-color: ${Colors.dark};
  border: 1px solid ${Colors.orange};
  color: ${Colors.orange};
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top:0;
  right: 1px;
  z-index: 102;

  :hover {
    background-color: ${Colors.darker};
    border: 1px solid ${Colors.red};
    color: ${Colors.red};
  }
`
export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  
`
export const StyledImageName = styled.h6`
  width: 100px;
  text-align: center;
  font-size: 10px;
  color: ${Colors.textgray};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: all .1s linear;
`
export const StyledDeleteWrapper = styled.div`
  position: relative;
`
export const StyledTypesText = styled.h4`
  width: 300px;
  text-align: center;
  font-size: 16px;
  color: ${Colors.gray};
  margin-top: 5px;
`
export const StyledNewErrorText = styled.div`
  font-size: 14px;
`
export const StyledRedErrorText = styled.div`
  font-size: 14px;
  color: red;
`
export const StyledButton = styled.button`
  border: none;
  background: none;
  padding: 0;
`

//Generate Page Styled

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 15px;
  padding: 15px;

  width: 100%;
  height: 90%;
`

export const StyledLeftColumn = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 10px 5px;
  width: 256px;
  height: auto;

  border: 1px solid ${Colors.black};

`

export const StyledVersionsColumn = styled.div`
  margin-top: 10px;
  width: 100%;
  max-height: 550px;
  padding: 0 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`
export const StyledVersionsHeader = styled.h4`
  color: ${Colors.textgray};
  background-color: ${Colors.black};
  border-radius: 5px 5px 0 0;

  width: 100%;
  text-align: center;
`

export const StyledRightColumn = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */

  padding: 5px 5px;
  width: 76%;
  
  border: 1px solid ${Colors.black};

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledRightColumnHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start;
  gap: 5px;
  padding: 15px;
`
export const StyledVersionRightColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: flex-start;
  gap: 5px;
  padding: 15px 15px 20px 15px;
`

export const StyledColumnHeaderTitle = styled.h4`
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.WHITE};
  text-align: left;
`
export const StyledColumnHeaderText = styled.p`
  padding: 0;
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  color: ${Colors.textgray};
  text-align: left;
`

//Generate Accordion

export const StyledGenerateAccordion = styled.div`
  padding: 15px 40px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface IAccordionTitleProps {
  active: boolean;
}
interface IAccordionSectionContentProps {
  active: boolean;
}

export const StyledAccordionSectionTitle = styled.div<IAccordionTitleProps>`
  background-color:${props => props.active? Colors.darkerBlack : Colors.black};
  color: ${Colors.WHITE};
  padding: 15px;
  width: 100%;

  text-align: left;
  font-size: 16px;
  font-weight: 600;

  border-top: 1px solid ${Colors.middledark};
  border-bottom: 1px solid ${Colors.middledark};

  cursor: pointer;

  :hover {
    background-color: ${Colors.darkerBlack};
  };
  &:after {
  content: ${props => props.active? '"-"' : '"+"'};
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  float: right;
  margin-left: 5px;
  };
  
`

export const StyledAccordionSectionContent = styled.div<IAccordionSectionContentProps>`
  padding: ${({active}) => active? `8px 16px` : '0 16px'};
  background-color: ${Colors.dark};
  height: ${({active}) => active? 'max-content' : 0};
  width: 100%;
  overflow: hidden;
  transition: all .1s linear;
  border: 1px solid ${Colors.darkerBlack};
`

export const StyledAccordionImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%; 
  height: auto;
  margin: 10px 0;

  overflow: auto;
  padding: 0 15px;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledAccodrionLink = styled.div`
  align-self: flex-end;
  color: ${Colors.orange};

  cursor: pointer;
  transition: color .1s linear;
  :hover {
    color: ${Colors.lightorange};
  }
`

export const StyledColoredSpan = styled.span`
  color: ${Colors.orange};
`

export const StyledSplitCard = styled.div`
  border: 1px solid ${Colors.darkerBlack};
  border-radius: 20px;
  width: 140px;
  height: 100px;
`

export const StyledVersionsSplitCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border: 1px solid ${Colors.darkerBlack};
  border-radius: 20px;
  width: 220px;
  height: 120px;
`

export const StyledSplitCardText = styled.h5`
  font-size: 16px;
  color: ${Colors.textgray};
`

interface IPercentColorProps {
  percentColor: string;
}

export const StyledStplitCardPercents = styled.div<IPercentColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  border-radius: 20px;
  color: #fff;
  background-color: ${props => props.percentColor? props.percentColor : ''};

  width: 60px;
  height: 30px;
  padding: 5px;
  text-align: center;
`

// PreprocessingTab 
export const StyledBigText = styled.span`
  font-size: 20px;
`

export const StyledPreprocessingTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px; 

  max-width: 330px;
  height: auto;
  margin: 15px 0;
  padding: 5px;

  border: 1px solid #373737;
  border-radius: 5px;
`

export const StyledPreprocessingButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 16px;

  width: 320px;
  height: 40px;

  border: 1px solid ${Colors.orange};
  border-radius: 5px;

  cursor: pointer;
  transition: all .1s linear;
  :hover {
    color: ${Colors.lightorange};
    border-color: ${Colors.lightorange};
  }
`

interface IOptionProps {
  selected? : boolean;
}

export const StyledPreprocessingOption = styled.div<IOptionProps>`
  display: ${({selected}) => selected? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  width: 320px;
  min-height: 40px;
  margin: 1px 0;
  border: 1px solid #323232;

  transition: all .1s linear;
  cursor: pointer;
  :hover {
    background: ${Colors.black};
    border-color: ${Colors.orange};
  }

  position: relative;
`

export const StyledOptionEdit = styled.div`
  font-size: 14px;
  color: ${Colors.lightgray};

  cursor: pointer;
  transition: all .1s linear;

  :hover {
    color: ${Colors.red};
  };

  position: absolute;
  right: 5px;
  z-index: 999;
`

export const StyledOptionInfo = styled.div`
  font-size: 14px;
  color: ${Colors.lightgray};

`

export const StyledFormOptionText = styled.div`
  font-size: 14px;
`
export const StyledFormOptionImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #4a4a4a4f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`
export const StyledFormOptionImage = styled.img`
  max-width: 70px;
  max-height: 70px;

`
export const StyledFormOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 5px 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    background-color: rgba(255, 138, 16, 0.56);
    color: #fff;
  }
  :hover ${StyledImageName} {
    color: #fff;
  }
`

export const StyledBeforeImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  gap: 10px;
`
export const StyledBeforeImage = styled.img`
  max-width: 200px;
  max-height: 200px;
`

export const StyledAfterImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  gap: 10px;
`
export const StyledAfterImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`

export const StyledDiv = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  padding: 5px 25px;
`

export const StyledCanvasWrapper = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledCanvas = styled.canvas`
    background-color: ${Colors.black};
`

export const StyledCanvasOptionImage = styled.img`
  width: 0;
  height: 0;
`

export const StyledSelect = styled.select`
  width: 170px;
  height: 30px;

  background-color: ${Colors.black};
  color: ${Colors.WHITE};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;

  :focus {
    outline: none;
  }
`

export const StyledAfterBothImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  max-height: 200px;
  gap: 10px;
  padding-bottom:0;
  margin-bottom:0;
`


export const StyledCanvasBrightnessWrapper = styled.div`
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const StyledPercentageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
`
export const StyledEmptyDatasets = styled.div`
  width: 100%;
  height: 80%;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 25px;
`

export const StyledLoupeIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${loupeIcon});
  mask-image: url(${loupeIcon});
  background-color: ${Colors.orange};
  width: 220px;
  height: 220px;
`

export const StyledVersionOptionName = styled.span`
  font-size: 15px;
  min-width: 130px;
  color: ${Colors.textgray};
`
export const StyledVersionOptionInfo = styled.span`
  font-size: 14px;
  color: ${Colors.lightgray};
`

export const StyledModalWindowTitle = styled.h6`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 14px;
`

export const StyledLoadingText = styled.h5`
  color: ${Colors.WHITE};
`

export const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  font-size: 15px;
  font-weight: 300;
  align-self: flex-start;
  margin: 10px 20px;
`

export const StyledExportFormSelect = styled.select`
  width: 90%;
  height: 35px;

  padding: 4px;
  background-color:${Colors.darker};
  border: 1px solid ${Colors.gray};
  border-color: ${Colors.gray};
  border-radius: 6px;
  color: ${Colors.textgray};

  &:focus { 
    outline: none;
    border: 1px solid ${Colors.orange}
   };
`

export const StyledOption = styled.option`
  width: 100%;
  height: 30px;
  font-size: 16px;
  color: ${Colors.textgray};
`

export const StyledLink = styled.a`

  color: ${Colors.orange};
  text-decoration:none;
  cursor: pointer;
  transition: color .1s linear;
  :hover {
    color: ${Colors.lightorange};
  }

  :visited {
    color: ${Colors.orange};
  }
`

export const StyledExportFormText = styled.span`
  width: 300px;
  height: auto;
  text-align: center;
  font-size: 15px;
  color: ${Colors.textgray};
  margin-top: 5px;
`

export const StyledProjectName = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

//statistics
export const StyledHealthContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;;
  gap: 10px;
  padding: 15px;

  width: 100%;
  height: 85%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`


export const StyledHealthHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  height: 95px;
  padding: 15px;
`

export const StyledHealthHeaderSubTitle = styled.div`
  font-size: 18px;
  color: ${Colors.textgray};

  padding-right: 10px;
`

export const StyledHistory = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${historyIcon});
  mask-image: url(${historyIcon});
  background-color: ${Colors.orange};
  width: 25px;
  height: 25px;

`

export const StyledHealthRegenerate = styled.div`
  font-size: 18px;
  color: ${Colors.orange};
  cursor: pointer;
  transition: all .1s linear;
  :hover {
    color: ${Colors.lightorange};
    text-decoration: underline;
  }
`

export const StyledHealthCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  width: 100%;
  height: max-content;

  border: 1px solid ${Colors.gray};

  padding: 10px 15px;
`

export const StyledCardTitle = styled.h6`
  font-size: 16px;
  color: ${Colors.textgray};
`

export const StyledOptionText = styled.h6`
  font-size: 14px;
  color: ${Colors.textgray};
`

export const StyledCardStat = styled.h1`
  color: ${Colors.orange};
`

export const StyledHealthClassBalanceCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  width: 70%;
  height: max-content;

  border: 1px solid ${Colors.gray};

  padding: 10px 15px;
`

export const StyledClassCardTitle = styled.h6`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${Colors.textgray};
`

interface IStyledHealthClassName {
  color: string;
}

export const StyledHealthClassName = styled.span`
  transition: all .1s linear;
`

export const StyledBalanceClass = styled.div<IStyledHealthClassName>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  font-size: 16px;

  transition: all .1s linear;
  :hover {
    background: ${Colors.blackDarker}
  }
  :hover ${StyledHealthClassName} {
    color: ${({color}) => color ? color : '#fff'};
  }
`

export const StyledBalanceLineStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

export const StyledLineWrapper = styled.div`
  max-width: 500px;
  min-width: 400px;
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: ${Colors.gray};

  position: relative;
`

interface IStyledColoredLineProps {
  color: string;
  percentage: string | number;
}

export const StyledColoredLine = styled.div<IStyledColoredLineProps>`
  background-color: ${({color}) => color? color : '#fff'};
  width: ${({percentage}) => percentage ? percentage+"%" : 0};
  max-width: 300px;
  height: 5px;
  border-radius: 2px;
  position: absolute;
  left: 0;

`

export const StyledAmount = styled.span`
  min-width: 45px;
  text-align: right;
`

export const StyledRepresented = styled.span`
  min-width: 135px;
  max-width: 135px;
`

//statistics

export const StyledStatistics = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  padding: 0 35px;
  height: auto;

  position: relative;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 80px;
`

export const StyledStatisticsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  min-height: 100%;
  max-height: 100%;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

export const StyledVHContentWrapper = styled.div`
  min-height: 100%;
  max-height: 100%;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

export const StyledStatGoBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  transition: all .1s linear;
  cursor: pointer;

  position: absolute;
  top: -13px;
  left: 15px;
  
  :hover {
    color: ${Colors.orange};
  }

  :hover ${StyledArrowLeftIcon} {
    background-color: ${Colors.orange};
  }
`

export const StyledStatDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`

export const StyledDateText = styled.span`
  font-size: 16px;
`

export const StyledLeftStatCol = styled.div`
  height: 600px;
  max-width: 280px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

`

export const StyledUserChartsWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 35px;
`

export const StyledChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  font-size: 14px;
  font-weight: bold;
  width: 90%;
`

export const StyledChartTitle = styled.h3`
  font-size: 21px;
`

export const StyledCustomTooltip = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  text-align: center;

  padding: 10px;
`

//Management 
export const StyledManagement = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  padding: 0 35px;
  
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 80px;

  position: relative;

`

export const StyledManagementContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 15px;
  gap: 25px;
`


//users table
export const StyledUsersTable = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #393939;
  padding: 0 15px 15px 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledUsersTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  padding: 10px 0;
`

export const StyledTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  width: 215px;
`

export const StyledStatTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;

  text-align: left;

  width: 215px;

  transition: all .1s linear;
  cursor: pointer;

  :hover {
    color: ${Colors.orange};
    text-decoration: underline;
  }
`

export const StyledGroupUserCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 55px;
`

export const StyledUserCredentials = styled.h5`
    width: 165px;
    height: 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledUserEmail = styled.h5`
    max-width: 190px;
    height: 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #C1C1C1;
`

export const StyledParagraph = styled.p`
  padding: 25px 15px;
  font-size: 15px;
`

export const StyledTooltip = styled.span`
  display: none;

  position: absolute;
  top: -40px;
  left: -220%;

  font-size: 12px;
  font-weight: 400;
  text-align: center;
  background: ${Colors.dark};
  border: 1px solid ${Colors.orange};
  color: #fff;

  width: 120px;

  z-index: 10;

  :after {
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid ${Colors.orange};
  border-top: 5px solid ${Colors.orange};
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}
`

interface ITooltipProps {
  isAdmin?: boolean;
}

export const StyledTooltipWrapper = styled.div<ITooltipProps>`
  position: relative;
  height: 22px;

  :hover ${StyledTooltip} {
    display: ${props => props.isAdmin ? 'none' : 'block'};
  }
`

export const StyledInviteForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 170px;
  width: 100%;
  padding-bottom:120px;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledSelectWrapper = styled.div`
  width: 330px;
`

export interface IErrorProps {
  visible: boolean;
  colorProps?: string;
  fsize?: string;
}

export const StyledErrorWrapepr = styled.div<IErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    border: 1px solid ${Colors.darkred};
    border-radius: 15px;
    padding: 5px 10px;
    max-width: 400px;
    min-width: 400px;
    min-height: 60px;
    overflow-wrap: break-word;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 680px) {
        max-width: 350px;
        min-width: 350px;
    }
`

export const StyledErrorText = styled.div<IErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    font-size: ${props => props.fsize ? props.fsize : '14px'};
    font-weight: bold;
    height: 20px;
    text-align: center;
    padding: 10px 0;
    color: ${props => props.colorProps ? props.colorProps : '#fff'};
`

interface IStyledFormTabProps {
  active: boolean;
}

export const StyledFormTab = styled.div<IStyledFormTabProps>`
  color: white;
  cursor: pointer;
  transition: all .1s linear;
  text-align: center;
  width: 180px;
  font-size: 14px;
  padding-bottom: 5px;

  border-bottom: 3px solid ${({active}) => active ? Colors.orange : Colors.gray};

  :hover {
    color: ${Colors.orange};
  }
`

export const StyledModeWrapper = styled.div`
  width: 120px;
`

export const StyledArchivesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
`

export const StyledArchivesTitle = styled.h4`
  margin-bottom: 10px;
`

export const StyledArchiveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  width: 100%;

  padding: 0 15px;

`

export const StyledArchiveName = styled.div`
  text-decoration: underline;
  cursor: pointer;
  width: 320px;
  text-align: left;

  transition: all .1s linear;

  :hover {
    color: ${Colors.orange};
  }
`

export const StyledTimerWrapper = styled.div`
  width: 300px;
  font-size: 12px;
  text-align: left;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 30px;
`

interface IStyledGenerateImageProps {
  srcProps: any;
}

export const StyledGenerateImage = styled.i<IStyledGenerateImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${props => props.srcProps});
  mask-image: url(${props => props.srcProps});
  background-color: ${Colors.orange};
  height: 35px;
  min-width: 35px;
  margin-top: 20px;
`

export const GenerateLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  gap: 20px;
  margin: 0 0 40px 0;
  max-height: 430px;
  height: 100%;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.btngray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`