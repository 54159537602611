import React, { useEffect } from 'react'
import { ILicenseCardProps, TLicenseCardConditions } from '../../constants/interfaces'
import { StyledBaseCost, StyledLicenseCard, StyledLicenseName, 
         StyledLicensePrice, StyledLicensePriceWrapper, StyledNoDiscountText } from '../../constants/styled'
import { ProjectBr } from '../../../../styles/GlobalStyles'
import { Colors } from '../../../../models/Colors'
import LicenseCardQuota from './LicenseCardQuota'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import ActivateLicenceForm from '../ActivateLicenceForm/ActivateLicenceForm'
import { useTranslation } from 'react-i18next'

const LicenseCard: React.FC<ILicenseCardProps> = ({license, settings, group, type, user, selectedLicense}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [cost, setCost] = React.useState<number>(0)
  
  useEffect(() => {
    if (settings) {
      const MAX_DISCOUNT = settings.find(settings => settings.key === 'MAX_DISCOUNT')
      const DISCOUNT_COEFFICIENT = settings.find(settings => settings.key === 'DISCOUNT_COEFFICIENT')
      
      if (license.baseCost) {
        if (type === 1) {
          setCost(license.baseCost)
        }
        if (type === 6 && DISCOUNT_COEFFICIENT && MAX_DISCOUNT) {
          const calculatedDiscount = Math.min((type * (+DISCOUNT_COEFFICIENT.value / 12)), +MAX_DISCOUNT.value)
          setCost(Math.ceil(license.baseCost * (1 - calculatedDiscount)))
        }
        if (type === 12 && DISCOUNT_COEFFICIENT && MAX_DISCOUNT) {
          const calculatedDiscount = Math.min((type * (+DISCOUNT_COEFFICIENT.value / 12)), +MAX_DISCOUNT.value)
          setCost(Math.ceil(license.baseCost * (1 - calculatedDiscount)))
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, settings])

  const getRenderСondition = (condition: TLicenseCardConditions) => {
    if (condition === "extendLicense") {
      return selectedLicense.status !== "Active"
    }
    if (condition === "Select License text") {
      return license?.id !== selectedLicense?.id
    }
    if (condition === "disabledBtn") {
      if (!license.baseCost) {
        return true
      }
      return selectedLicense.status === "Active"
    }
  }

  const onChangeLicenseClick = () => {
    const canChange = getRenderСondition('disabledBtn')
    if (canChange) {
      return
    }
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.selectLicenseForm.title')))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setChildren(<ActivateLicenceForm getRenderСondition={getRenderСondition} license={license} group={group} months={type} user={user} cost={cost}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledLicenseCard active={selectedLicense?.id === license.id}>
      <StyledLicenseName>{license?.displayName}</StyledLicenseName>
      
      <StyledLicensePriceWrapper>
        <StyledBaseCost>{license?.baseCost && type !== 1 ? license.baseCost : ''}</StyledBaseCost>
        <StyledLicensePrice>{cost ? cost + ` RV/${t('other.month')}` :  t('authorized.settings.licenceCard.free')} </StyledLicensePrice>
      </StyledLicensePriceWrapper>
      <StyledNoDiscountText>{license.description}</StyledNoDiscountText>

      <ProjectBr color={Colors.gray}/>

      <FlexContainer direction='column' justify='flex-start' align='flex-start' gap='15px' margin='15px 0 0 0'>
        {license?.quotas?.map(quota => 
          <LicenseCardQuota key={quota?.quotaId} quota={quota} leftTooltip={license?.name === 'business2'}/>
        )}
      </FlexContainer>

      <FlexContainer direction='column' justify='flex-end' align='center' heightProps='100%' margin='40px 0 0 0'>
        <ProjectButton widthProps='220px' disabled={getRenderСondition('disabledBtn')} onClick={onChangeLicenseClick}>
          {license?.id === selectedLicense?.id && selectedLicense?.status !== 'Expired' ? t('authorized.settings.licenceCard.active') : ''}
          {license?.id === selectedLicense?.id && selectedLicense?.status === 'Expired' ? t('authorized.settings.licenceCard.extend') : ''}
          {license?.id !== selectedLicense?.id ?  t('authorized.settings.licenceCard.select') : ''}
        </ProjectButton>
      </FlexContainer>
    </StyledLicenseCard>
  )
}

export default LicenseCard