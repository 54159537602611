import React, { FC, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../hooks/redux'
import { StyledFormOptionImage, StyledFormOptionImageWrapper, StyledFormOptionWrapper, StyledImageName } from '../../../../constants/styled'
import emptyImage from '../../../../../../assets/icons/empty.png'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import FormOptionForm from './FormOptionForm/FormOptionForm'
import {
  IAutoContrastOption,
  IBlurOption,
  IBrightness,
  IGrayscaleAugOption,
  INoiseOption,
  IPercentsOption,
  IResizeOption,
  ISimpleOption,
  IStaticCropOption,
  ITileOption
} from '../../../../store/reducers/GenerateReducer'
import owlImage from '../../../../../../assets/images/owl.jpg'
import owlContrastImage from '../../../../../../assets/images/owlContrast.png'
import owlIsolateImage from '../../../../../../assets/images/OwlIsolate.png'
import owlGrayScaleImage from '../../../../../../assets/images/owlGrayscale.png'
import owlTileImage from '../../../../../../assets/images/owlTIle.png'

import owlNoice from '../../../../../../assets/images/owlNoise.png'
import owlBrightness from '../../../../../../assets/images/owlBrightness.jpg'

interface IFormOptionProps {
  globalType: string
  imagePath?: string
  option: IGrayscaleAugOption | INoiseOption | IBlurOption | IBrightness | IResizeOption | IStaticCropOption | ITileOption | IAutoContrastOption | ISimpleOption | IPercentsOption
}

const FormOption: FC<IFormOptionProps> = ({imagePath, option, globalType, ...props }) => {
  const dispatch = useAppDispatch()
  const [image, setImage] = useState<any>(owlImage)

  useEffect(() => {
    if (option.method === 'isolate') {
      setImage(owlIsolateImage)
    }
    if (option.method === 'grayscale') {
      setImage(owlGrayScaleImage)
    }
    if (option.method === 'negate') {
      setImage(owlContrastImage)
    }
    if (option.method === 'tile') {
      setImage(owlTileImage)
    }
    if (option.method === 'grayscale') {
      setImage(owlGrayScaleImage)
    }

    if (option.method === 'Grayscale Aug...') {
      setImage(owlGrayScaleImage)
    }
    if (option.method === 'resize') {
      setImage(owlImage)
    }
    if (option.method === 'blur') {
      setImage(owlImage)
    }
    if (option.method === 'noise') {
      setImage(owlNoice)
    }
    if (option.method === 'brightness') {
      setImage(owlBrightness)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOptionClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(option?.displayName || option?.method))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('560px'))
    dispatch(modalWindowSlice.actions.setChildren(<FormOptionForm type={option.method} globalType={globalType} />))
  }

  return (
    <StyledFormOptionWrapper onClick={onOptionClick}>
      <StyledFormOptionImageWrapper>
        <StyledFormOptionImage src={image ? image : emptyImage} />
      </StyledFormOptionImageWrapper>
      <StyledImageName>{option?.displayName || ''}</StyledImageName>
    </StyledFormOptionWrapper>
  )
}

export default FormOption
