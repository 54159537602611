import React, { FC, useState } from 'react'
import { IGetTopicByIdResponce } from '../../../../../services/ForumService'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { useAppDispatch } from '../../../../../hooks/redux'
import { putTopicTitle } from '../../../../../store/reducers/AcyncActions'
import Input from '../../../../../UI/Input/Input'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'

interface IRenameTopicFormProps {
  currentTopic: IGetTopicByIdResponce
}

const RenameTopicForm: FC<IRenameTopicFormProps> = ({ currentTopic, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(currentTopic.topic.title)

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onRenameClick = () => {
    if (value && currentTopic) {
      dispatch(putTopicTitle(currentTopic.topic.id, value, currentTopic.topic.description, currentTopic.topic.forumCategory.id))
      dispatch(modalWindowSlice.actions.setInititialState())
    }
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="45px">
      <Input placeholder={t('other.name')} label={t('forum.enterText')} value={value} onChange={onValueChange} />
      <FlexContainer direction="row" justify="center" align="center" gap="30px">
        <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onRenameClick}>{t('other.rename')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameTopicForm
