import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, useParams } from 'react-router-dom'
import { StyledForumContentColumn, StyledForumWrapper, StyledInfoColumn, StyledLink } from '../../constants/styled';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import InfoCard from '../InfoCard/InfoCard';
import InfoPost from '../InfoCard/InfoPost';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { asyncGetCategoriesSummary, asyncGetLastTopics } from '../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import CreateCategoryForm from './CreateCategoryForm';
import TopicsMain from '../Topics/TopicsMain';
import TopicPage from '../Topics/TopicPage/TopicPage';
import ForumCategories from './ForumCategories';
import CreateTopicPage from '../Topics/CreateTopicPage/CreateTopicPage';
import { useTranslation } from 'react-i18next';

interface IForumMainWrapperProps {
    navigate: NavigateFunction;
}

export const ForumMainWrapper: FC<IForumMainWrapperProps> = ({navigate, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const params = useParams()
  const categories = useAppSelector(state => state.ForumReducer.fullCategoriesInfo)
  const lastTopics = useAppSelector(state => state.ForumReducer.latestTopics)
  const user = useAppSelector(state => state.userReduser.user)
  const [currentTab, setCurrentTab] = useState('forums')

  useEffect(() => {
    dispatch(asyncGetCategoriesSummary())
    dispatch(asyncGetLastTopics('5'))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const onEditClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('forum.createCategory')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setChildren(<CreateCategoryForm isSub={true}/>))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <>
      <StyledForumWrapper>
        <StyledForumContentColumn>
          {!params.topics && !params.topic && !params.createTopic &&
            <ForumCategories 
              navigate={navigate} 
              currentTab={currentTab} 
              categories={categories} 
              onEditClick={onEditClick} 
              user={user}
              setCurrentTab={setCurrentTab}
            />
          }

          {params.createTopic === 'create-topic' && 
            <CreateTopicPage 
              navigate={navigate} 
              user={user} 
              params={params}
              isSub={false}
            />
          }

          {params.topics === 'topics' && 
            <TopicsMain 
              navigate={navigate} 
              user={user} 
              params={params}
            />
          }

          {params.topics === 'create' && 
            <CreateTopicPage 
              navigate={navigate} 
              user={user} 
              params={params}
              isSub={true}
            />
          }
          
          {params.topic && 
            <TopicPage 
              navigate={navigate} 
              user={user} 
              params={params}
            />
          }

        </StyledForumContentColumn>
        <StyledInfoColumn>
          <InfoCard title={t('forum.welcome1')}>
            <p>{t('forum.welcome3')}:<StyledLink>{t('forum.topic')}.</StyledLink></p>
          </InfoCard>
          <InfoCard title={t('forum.latestTopics')}>
            <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
              {lastTopics?.topics.length
                ? lastTopics?.topics?.map(lastTopic =>
                  <InfoPost lastTopic={lastTopic} key={lastTopic.id}/>  
                )
                : ''
              }
            </FlexContainer>
          </InfoCard>
          {/* <InfoCard title={'Recent Articles'}>
            <FlexContainer direction='column' justify='center' align='center' widthProps='100%'>
              <InfoPost lastTopic={undefined}/>
              <InfoPost lastTopic={undefined}/>
              <InfoPost lastTopic={undefined}/>
            </FlexContainer>
          </InfoCard> */}
          {/* <InfoCard title={'Join Us on Social Media'}>
            Social Media links
          </InfoCard> */}
        </StyledInfoColumn>
      </StyledForumWrapper>
    </>
  )
}
