import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface ILicenseQuota {
    limit: number;
    maxLimit: number;
    quotaId: string;
    isExtendable: boolean;
    name: string;
    displayName: string;
    description: string;
    quotaDurationType: string;
    cost: number;
}

export interface ILicense {
    id: string;
    name: string;
    displayName: string;
    description: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
    baseCost: number;
    fullCost: number;
    quotas: ILicenseQuota[]
}

export interface IGetLicensesRes {
    licenses: ILicense[];
}

//autoRenewLicense Автоматическое продление лицензии 
// При True
// Если баланса хватает - Создается подписка на лицензию
// Если продлеваем лицензию и баланса хватает, 
// смещаем дату платежа по по подписке если она есть, если нет создаем подписку.
export interface IExtendLicensePayload {
    groupId: string;
    licenseId: string;
    months: number;
    autoRenewLicense: boolean;
    withCustomQuotas: boolean;
}

export interface IExtendLicenseRes {
    message: string;
    baseCost: number;
    totalCost: number;
    discount: number;
}

export default class LicensesService {

    static async getLicenses(groupId: string): Promise<AxiosResponse<IGetLicensesRes>> {
        return $authHost.get<IGetLicensesRes>(`/licenses/?groupId=${groupId}`)
    }

    static async getLicensesWithoutId(): Promise<AxiosResponse<IGetLicensesRes>> {
        return $authHost.get<IGetLicensesRes>(`/licenses/`)
    }

    static async extendLicense(payload: IExtendLicensePayload): Promise<AxiosResponse<IExtendLicenseRes>> {
        return $authHost.post<IExtendLicenseRes>(`/licenses/extend`, payload)
    }

}