import React, { FC, useState } from 'react'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../UI/Buttons/ProjectButton/ProjectButton'
import Input from '../../UI/Input/Input'
import { useAppDispatch } from '../../hooks/redux'
import { modalWindowSlice } from '../../store/reducers/modalWindowReducer'
import { EditorState } from 'draft-js'
import { useTranslation } from 'react-i18next'

interface IPasteImageFormProps {
  currentState: any
  setEditorState: (value: React.SetStateAction<EditorState>) => void
  htmlToDraftBlocks: (onloadContent: any) => EditorState
  imageBase: string | ArrayBuffer | null
}

const PasteImageForm: FC<IPasteImageFormProps> = ({ currentState, setEditorState, htmlToDraftBlocks, imageBase }) => {
  const { t } = useTranslation()
  const [width, setWidth] = useState('400')
  const [height, setHeight] = useState('')
  const dispatch = useAppDispatch()

  const onWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWidth(e.target.value)
  }
  const onHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(e.target.value)
  }

  const onPasteClick = () => {
    const imageToEditor = `<div style="text-align:none;"><a href="${imageBase}" target="_blank"><img src="${imageBase}" alt="undefined" style="height: ${
      height ? height + 'px' : 'auto'
    };width: ${width + 'px'}"/></a></div><p></p>`
    setEditorState(htmlToDraftBlocks(currentState + imageToEditor))
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="30px">
      <Input 
        placeholder={t('forum.enterText')}
        label={t('editor.imageWidth')}
        value={width} 
        onChange={onWidthChange} 
        withTooltip 
        tooltipText={t('editor.imageMaxWidth')}
      />
      <Input
        placeholder={t('forum.enterText')}
        label={t('editor.imageHeight')}
        value={height}
        onChange={onHeightChange}
        withTooltip
        tooltipText={t('editor.ifImageEmpty')}
      />
      <FlexContainer direction="row" justify="center" align="center" gap="40px">
        <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onPasteClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default PasteImageForm
