import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode, ReactChild } from 'react';

interface IModalWindow {
  title: string;
  children: ReactNode | ReactChild;
  visible: boolean;
  titleColor: string;
  modalWidth: string;
  modalHeihgt: string;
  isCloseability: boolean;
  scroll: number;
  activeScroll: boolean;
  tooltipText: string;
  isTitleDisabled: boolean;
}

const initialState: IModalWindow = {
    title: '',
    titleColor: '',
    children: '',
    visible: false,
    modalWidth: '380px',
    modalHeihgt: 'auto',
    isCloseability: true,
    scroll: 0,
    activeScroll: false,
    tooltipText: '',
    isTitleDisabled: false,
}

export const modalWindowSlice = createSlice(
{
    name: 'modal',
    initialState,

    reducers: {
      setChildren(state, action: PayloadAction<ReactNode | ReactChild>) {
        state.children = action.payload
      },
      setVisible(state) {
        state.visible = !state.visible
      },
      setVisibleFalse(state) {
        state.visible = false
      },
      setTitle(state, action: PayloadAction<string>) {
        state.title = action.payload
      },
      setScrollY(state, action: PayloadAction<number>) {
        state.scroll = action.payload
      },
      setTitleColor(state, action: PayloadAction<string>) {
        state.titleColor = action.payload
      },
      setInititialState(state) {
        state.visible = false;
        state.children = '';
        state.title = '';
        state.modalWidth = initialState.modalWidth;
        state.modalHeihgt = initialState.modalHeihgt;
        state.titleColor = initialState.titleColor
        state.isCloseability = true;
        state.scroll = 0;
        state.tooltipText = '';
        state.activeScroll = false;
        state.isTitleDisabled = false
      },
      disableTitle(state) {
        state.isTitleDisabled = true
      },
      setModalWidth(state, action: PayloadAction<string>) {
        state.modalWidth = action.payload
      },
      setModalHeight(state, action: PayloadAction<string>) {
        state.modalHeihgt = action.payload
      },
      setIsCloseability(state, action: PayloadAction<boolean>) {
        state.isCloseability = action.payload
      },
      setActiveScrollTrue(state) {
        state.activeScroll = true
      },
      setActiveScrollFalse(state) {
        state.activeScroll = false
      },
      setTooltipText(state, action: PayloadAction<string>) {
        state.tooltipText = action.payload
      }
    }
},

)

export default modalWindowSlice.reducer;