export const arraySplitter = (arr:any, qtyItems: number) => {
  let countItems = 0
  const arrLength = arr.length
  const resultArr:any = []

  const splitter = (arr:any) => {
    if (countItems >= arrLength) {
      return
    }
    resultArr.push(arr.slice(countItems, countItems + qtyItems))
    countItems = countItems + qtyItems
    splitter(arr)
  }
  
  splitter(arr)
  return resultArr
}