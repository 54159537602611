import React, { FC, useEffect, useState } from 'react'
import { StyledAmount, StyledBalanceClass, StyledBalanceLineStats, StyledColoredLine, StyledHealthClassName, StyledLineWrapper, StyledRepresented } from '../../../constants/styled';

interface IBalanceClassProps {
    total: number;
    amount: number;
    name: string;
    color: string;
    represented: string;
}

const BalanceClass: FC<IBalanceClassProps> = ({name, color, total, amount, represented, ...props}) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (total && amount) {
        setPercentage(Math.ceil((amount/total) * 100))
    }
  }, [total, amount])

  return (
    <StyledBalanceClass color={color}>
        <StyledHealthClassName>{name ? name : ''}</StyledHealthClassName>
        <StyledBalanceLineStats>
            <StyledAmount>{amount ? amount : ''}</StyledAmount>
            <StyledLineWrapper>
               <StyledColoredLine percentage={percentage} color={color ? color : "#fff"}/>
            </StyledLineWrapper>
            <StyledRepresented>{represented ? represented : ''} represented</StyledRepresented>
        </StyledBalanceLineStats>
    </StyledBalanceClass>
  )
}

export default BalanceClass