import React, { FC } from 'react'
import { StyledBr, StyledCategoryHeader, StyledCategoryWrapper, StyledEdit, StyledEditIcon, StyledTextButton } from '../../constants/styled';
import SubCategory from './SubCategory';
import { IGetCategoryByIdResponce } from '../../../../services/ForumService';
import { useAppDispatch } from '../../../../hooks/redux';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import EditCategoryFormMain from './EditCategoryForm/EditCategoryFormMain';
import { NavigateFunction } from 'react-router-dom';
import Topic from '../Topics/Topic';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { IUsersMeResponse } from '../../../../services/UserService';
import { useTranslation } from 'react-i18next';

interface ICategoryProps {
    categoryInfo: IGetCategoryByIdResponce;
    user: IUsersMeResponse;
    navigate: NavigateFunction;
}

const Category: FC<ICategoryProps> = ({categoryInfo, user, navigate, ...props}) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    console.log(categoryInfo)
    const onEditClick = () => {
        dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
        dispatch(modalWindowSlice.actions.setTitle('Edit Category'))
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setChildren(<EditCategoryFormMain isSub={false} categoryInfo={categoryInfo} navigate={navigate}/>))
        dispatch(modalWindowSlice.actions.setVisible())
    }

  return (
        <StyledCategoryWrapper>
            <StyledBr/>
            <StyledCategoryHeader>
                {categoryInfo.category.name.charAt(0).toUpperCase() + categoryInfo.category.name.slice(1)}
                {user?.role?.name === 'global_admin' || user?.role?.name === 'developer'
                    ? <StyledEdit onClick={onEditClick}>
                        <StyledEditIcon/>
                        <StyledTextButton>{t('other.edit')}</StyledTextButton>
                      </StyledEdit>
                    : ''
                }
                
            </StyledCategoryHeader>
            {categoryInfo?.subcategories?.length
                ? categoryInfo?.subcategories.map(sub => 
                    <SubCategory subCategoryInfo={sub} categoryInfo={categoryInfo} key={sub.id} user={user} navigate={navigate}/>
                  )
                : ''
            }
            
            {categoryInfo?.topics?.length && categoryInfo?.subcategories?.length ? <FlexContainer direction='column' gap={'5px'} paddingProps='0 5px'><StyledBr/>{t('forum.topics')}:</FlexContainer> : ''}
            {categoryInfo?.topics?.length 
                ? categoryInfo?.topics.map(topic => 
                    <Topic isSub={false} currentCategory={categoryInfo} navigate={navigate} topicInfo={topic} key={topic.id}/>
                  ).reverse()
                : ''
            }

        </StyledCategoryWrapper>
  )
}

export default Category