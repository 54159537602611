import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface IGroupMember {
    user: {
        email?: string;
        id?: string;
    };
    role: {
        id: string;
    }
}

export interface IFailedUser {
    user: {
        id: string;
        email: string;
    };
    error: string;
}

export interface ISuccessfulUser {
    user: {
        id: string;
        email: string;
    };
    message: string;
}

export interface IAddUserToGroupReq {
    groupMembers: IGroupMember[];
}

export interface IAddUserToProjectReq {
    projectMembers: IGroupMember[];
}

interface IDeleteResponce {
    message: string;
}

export interface IGroup {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
}

export interface IFullGroupMember {
    id: string;
    group: string;
    user: string;
    invitedBy: string;
    roleId: string;
    createdAt: string;
    updatedAt: string;
}

export interface IFullProjectMember {
    id: string;
    project: string;
    user: string;
    invitedBy: string;
    roleId: string;
    createdAt: string;
    updatedAt: string;
}

export interface IPermission {
    id: string;
    name: string;
    displayName: string;
}

export interface ILocalRole  {
    id: string;
    name: string;
    displayName: string;
    roleType: string;
    permissions: IPermission[]
}

export interface IGetGroupUsersResponce {
    group: IGroup;
    members: IFullGroupMember[];
    localRole: ILocalRole;
}

export interface IGetProjectUsersResponce {
    project: IGroup;
    members: IFullProjectMember[];
    localRole: ILocalRole;
}

export interface IAddUserToGroupResponce {
    message: string;
    successfulUsers: ISuccessfulUser[];
    failedUsers: IFailedUser[];
}

export default class RolesService {
    //GroupMembers
    static async getGroupUsers(groupId: string): Promise<AxiosResponse<IGetGroupUsersResponce>> {
        return $authHost.get<IGetGroupUsersResponce>(`/group-members/${groupId}`)
    }
    static async addUserToGroup(groupId: string, groupMembers: IAddUserToGroupReq): Promise<AxiosResponse<IAddUserToGroupResponce>> {
        return $authHost.post<IAddUserToGroupResponce>(`/group-members/${groupId}`, groupMembers)
    }
    static async putGroupUsers(groupId: string, groupMembers: IAddUserToGroupReq): Promise<AxiosResponse<IAddUserToGroupResponce>> {
        return $authHost.put<IAddUserToGroupResponce>(`/group-members/${groupId}`, groupMembers)
    }
    static async deleteGroupUser(groupId: string, userId: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/group-members/${groupId}/${userId}`)
    }

    //ProjectMembers
    static async getProjectUsers(projectId: string): Promise<AxiosResponse<IGetProjectUsersResponce>> {
        return $authHost.get<IGetProjectUsersResponce>(`/project-members/${projectId}`)
    }
    static async addUserToProject(projectId: string, projectMembers: IAddUserToProjectReq): Promise<AxiosResponse<IAddUserToGroupResponce>> {
        return $authHost.post<IAddUserToGroupResponce>(`/project-members/${projectId}`, projectMembers)
    }
    static async putProjectUsers(projectId: string, projectMembers: IAddUserToProjectReq): Promise<AxiosResponse<IAddUserToGroupResponce>> {
        return $authHost.put<IAddUserToGroupResponce>(`/project-members/${projectId}`, projectMembers)
    }
    static async deleteProjectUser(projectId: string, userId: string): Promise<AxiosResponse<IDeleteResponce>> {
        return $authHost.delete<IDeleteResponce>(`/project-members/${projectId}/${userId}`)
    }
}