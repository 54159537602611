import React, { FC } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../models/Colors'
import { IButtonProps } from '../../../../models/IButtonProps'
import emptyImage from '../../../../assets/icons/empty.png'

const StyledButton = styled.div<IButtonProps>`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    width: ${props => props.widthProps || '100%'} ;
    padding:${props => props.paddingProps || '0px'};
    height:${props => props.heightProps || '50px'};
    border-left: 5px solid transparent;
    border-left: ${({ active }) => active ? `5px solid ${Colors.orange}` : ''};
    background: ${({ active }) => active ? Colors.black : Colors.dark};

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    font-size: ${props => props.FontSizeProps || '16px'};
    color: ${({ active }) => active ? Colors.orange : Colors.textgray};
    text-align: center;
    transition: all 0.1s linear;

    cursor: pointer;
    &:hover {
        background: ${Colors.black};
        border-left: 5px solid ${Colors.orange};
        color: ${Colors.orange};
    }
`
const StyledButtonText = styled.span`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ProjectTabBtn: FC<IButtonProps> = ({children, imageProps, ...props}) => {
  return (
    <StyledButton {...props}>
        <FlexContainer direction='row' justify='flex-start' align='center' margin='0 10px'>
            {imageProps}
            <StyledButtonText>{children}</StyledButtonText>
        </FlexContainer>
    </StyledButton>
  )
}

export default ProjectTabBtn