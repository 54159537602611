import styled from "styled-components"
import { AuthErrorProps } from "../../../models/IAuthErrorProps"
import { Colors } from "../../../models/Colors"
import passwordEye from '../../../assets/icons/eye-svgrepo-com.svg'

interface IPasswordEyeProps {
    active: boolean;
    widthProps? : string;
    heightProps? : string;
}

export const StyledEyeIcon = styled.i<IPasswordEyeProps>`
    display: block;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url(${passwordEye});
    mask-image: url(${passwordEye});
    background-color: ${props => props.active ? Colors.orange : Colors.gray};
    min-width: 25px;
    min-height: 25px;
    margin-top:28px;
    cursor: pointer;
    transition: all .1s linear;

    :hover {
        background-color: ${Colors.orange};
    }
`

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border: 1px solid ${Colors.gray};
    border-radius: 15px;
    padding: 30px 35px 15px 35px;
    background-color: ${Colors.grayInvisible};
    width: 400px;

    @media (max-width: 680px) {
        width: 350px;
        padding: 20px 25px 20px 25px;
        gap:10px;
        margin-top: 40px;
    }

    @media (max-height: 650px) {
        width: 350px;
        padding: 10px 25px 5px 25px;
        gap:10px;
        margin-top: 5px;
    }
`
export const StyledFormHeader = styled.h2`
    margin-bottom: 25px;
    @media (max-height: 650px) {
        marginBottom: 10px;
    }
`
export const StyledSupTitle = styled.p`
    color : ${Colors.texttable};
    font-size: 14px;
`
export const StyledLink = styled.span`
    cursor: pointer;
    color: ${Colors.dirtyorange};
    :hover{
        color: ${Colors.orange};
    }
`
export const StyledErrorWrapepr = styled.div<AuthErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    border: 1px solid ${Colors.darkred};
    border-radius: 15px;
    padding: 15px;
    max-width: 400px;
    min-width: 400px;
    min-height: 80px;
    overflow-wrap: break-word;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-height: 767px) {
        max-width: 350px;
        min-width: 350px;
        display: none;
    }
`
export const StyledErrorText = styled.p`
    color: ${Colors.red};
    font-size: 14px;
    font-weight: bold;
`
 