import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICoinsTransactionsRes, IFiatTransactionsRes } from '../../../services/PaymentsService';

interface IModalWindow {
  error: string | null;
  isLoading: boolean;
  fiatOperations: IFiatTransactionsRes;
  coinsOperations: ICoinsTransactionsRes;
}

const initialState: IModalWindow = {
  error: '',
  isLoading: false,
  fiatOperations: {} as IFiatTransactionsRes,
  coinsOperations: {} as ICoinsTransactionsRes,
}

export const paymentsSlice = createSlice(
{
  name: 'payments',
  initialState, 

  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },  

    setFiatOperations(state, action: PayloadAction<IFiatTransactionsRes>) {
      state.fiatOperations = action.payload;
    },  
    
    setCoinsOperations(state, action: PayloadAction<ICoinsTransactionsRes>) {
      state.coinsOperations = action.payload;
    },  
  }
},

)

export default paymentsSlice.reducer;