import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../models/Colors'
import { ICardProps } from '../../models/ICardProps'
import publicIcon from '../../../../assets/icons/public.svg'
import privateIcon from '../../../../assets/icons/lock.svg'
import { useTranslation } from 'react-i18next'

interface IStyledImageProps {
  srcProps?: any;
}

const StyledImage = styled.i<IStyledImageProps>`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${props => props.srcProps});
  mask-image: url(${props => props.srcProps});
  background-color: ${Colors.orange};
  width: 16px;
  height: 16px;
  min-width: 16px;

  transition: all 0.2s linear;  

  :hover {
    background-color: ${Colors.orange};
  }
`

const StyledType = styled.div<ICardProps>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: ${({ active }) => active ? `1px solid ${Colors.orange}` : `#575756`};
    display: flex;
    gap: 5px;
    transition: all 0.2s linear;  
`

const StyledCard = styled.div<ICardProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5px;

    width: 300px;
    height: auto;
    padding: 10px 10px 15px 10px;

    border: ${({ active }) => active ? `1px solid ${Colors.orange}` : `1px solid ${Colors.gray}`};

    transition: all 0.2s linear;  
    cursor: pointer;
    :hover {
        border-color: ${Colors.orange};
        color: ${Colors.orange};
        box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    :hover ${StyledType} {
        color: ${Colors.orange};
    }

    :hover ${StyledImage} {
        background-color: ${Colors.orange};
    }
 `
const StyledCardTitle = styled.div`
    font-size: 16px;
    color: ${Colors.textgray};
`
const StyledText = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #8F8F8F;
`

const Card: FC<ICardProps> = ({type, active, ...props}) => {
  const { t } = useTranslation()
  return (
    <StyledCard {...props} active={active}>
      {type === "community" ? <StyledCardTitle>{t('authorized.projects.groups.createForm.freeCard.title')}</StyledCardTitle> : <StyledCardTitle>{t('authorized.projects.groups.createForm.paidCard.title')}</StyledCardTitle>}
      {type === "community" ? <StyledText>{t('authorized.projects.groups.createForm.freeCard.text1')}</StyledText> : <StyledText>{t('authorized.projects.groups.createForm.paidCard.text1')}</StyledText>}
      {type === "community" 
      ?  <StyledType active={true}>
           <StyledImage srcProps={publicIcon}/>
           {t('authorized.projects.groups.createForm.freeCard.datasets')}
         </StyledType>
      :  <StyledType active={true}>
           <StyledImage srcProps={privateIcon}/>
           {t('authorized.projects.groups.createForm.paidCard.datasets')}
         </StyledType>
      }
      {type === "community" ? <StyledText>{t('authorized.projects.groups.createForm.freeCard.text2')}</StyledText> : <StyledText>{t('authorized.projects.groups.createForm.paidCard.text2')}</StyledText>}
    </StyledCard>
  )
}

export default Card