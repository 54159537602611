import React from 'react'
import { ISettingsUserCardProps } from '../../constants/interfaces'
import { StyledAddAvatar, StyledAvatarWrapper, StyledUserCardAvatar, StyledUserCardWrapper, StyledUserCredentials, StyledUserEmail } from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import ChangeAvatarForm from '../ChangeAvatarForm/ChangeAvatarForm'
import { useTranslation } from 'react-i18next'

const SettingsUserCard: React.FC<ISettingsUserCardProps> = ({userInfo, navigate, ...props}) => {
  const [ t ] = useTranslation()
  const dispatch = useAppDispatch()
  
  const onLogoutClick = () => {
    localStorage.removeItem('token')
    navigate('/signin')
  }

  const onAvatarClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setModalWidth('420px'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.changeAvatarTitle')))
    dispatch(modalWindowSlice.actions.setChildren(<ChangeAvatarForm/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledUserCardWrapper>
      <FlexContainer direction='row' justify='flex-start' align='center' gap='8px'>
        <StyledAvatarWrapper onClick={onAvatarClick}>
          <StyledAddAvatar minWidth="40px" widthProps='40px' heightProps='40px'/>
          <StyledUserCardAvatar minWidth="50px" widthProps='50px' heightProps='50px' img={''}/>
        </StyledAvatarWrapper>
        <FlexContainer direction='column' justify='center' align='flex-start'>
          <StyledUserCredentials>{userInfo.firstName? userInfo.firstName + ' ' + userInfo.lastName : ''}</StyledUserCredentials>
          <StyledUserEmail>{userInfo.email}</StyledUserEmail>
        </FlexContainer>
      </FlexContainer>
      <ProjectButton onClick={onLogoutClick}  widthProps='140px'>{t('buttons.logout')}</ProjectButton>
    </StyledUserCardWrapper>
  )
}

export default SettingsUserCard