import React, { FC, MouseEventHandler, useEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowDown } from '../../assets/icons/GroupMark.svg'
import Styles from './styles.module.css'
import SelectOption from './SelectOption';
import { useTranslation } from 'react-i18next';

export interface CheckboxOption {
  value: string;
  title: string;
  selected: boolean;
}

interface SelectProps {
    selected: CheckboxOption | null;
    options: CheckboxOption[];
    placeholder?: string;
    mode?: "rows" | "cells";
    status?: "default" | "invalid";
    onChange?: (value: string) => void;
    onClose?: () => void;
  };

const DropdownSelect: FC<SelectProps> = ({mode, options, placeholder, status, selected, onChange, onClose, ...props}) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const { target } = event;
      if (target instanceof Node && !rootRef.current?.contains(target)) {
        isOpen && onClose?.();
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [onClose]);

  const handleOptionClick = (value: CheckboxOption["value"]) => {
    onChange?.(value);
  };
  
  const handlePlaceHolderClick: MouseEventHandler<HTMLDivElement> = () => {
    setIsOpen((prev) => !prev);
  };


  return (
    <div
        className={Styles.selectWrapper}
        ref={rootRef}
        data-is-active={isOpen}
        data-mode={mode}
        data-testid="selectWrapper"
    >
        <div className={Styles.arrow}>
          <ArrowDown onClick={() => setIsOpen((prev) => !prev)}/>
        </div>
        <div
          className={Styles.placeholder}
          data-status={status}
          data-selected={!!selected?.value}
          onClick={handlePlaceHolderClick}
          role="button"
          tabIndex={0}
          ref={placeholderRef}
        >
        {t('authorized.project.statistics.graphs')}
    </div>
    {isOpen && (
      <ul className={Styles.select} data-testid="selectDropdown">
        {options.map((option) => (
          <SelectOption
            key={option.value}
            option={option}
            onClick={handleOptionClick}
          />
        ))}
      </ul>
    )}
  </div>
  )
}

export default DropdownSelect