import React from 'react'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { StyledVersionOptionInfo, StyledVersionOptionName } from '../../../constants/styled'
import { IFullVersionWithArchive } from '../../../store/reducers/VersionsReducer'
import { useTranslation } from 'react-i18next'

interface IVersionProcessingInfoProps {
  type: string
  currentVersion: IFullVersionWithArchive
}

const RenameProcessingInfo: React.FC<IVersionProcessingInfoProps> = ({ type, currentVersion, ...props }) => {
  const { t } = useTranslation()
  return (
    <FlexContainer direction="row" justify="flex-start" align="flex-start" paddingProps="15px" gap="35px">
      <StyledVersionOptionName>
        <h6>{t('authorized.project.generateAndVersions.classRename')}</h6>
      </StyledVersionOptionName>
      <FlexContainer direction="column" justify="flex-start" align="flex-start" gap="15px">
        {type === 'LABELS' ? (
          currentVersion?.versionInfo?.renameLabels?.length ? (
            currentVersion?.versionInfo?.renameLabels?.map((option, index) => (
              <FlexContainer key={Date.now() + index} direction="row" gap="60px">
                <StyledVersionOptionName>{t('authorized.project.annotate.forms.renameTItle')}:</StyledVersionOptionName>
                <StyledVersionOptionInfo>
                  <FlexContainer direction="column" justify="flex-start" align="flex-start">
                    <FlexContainer direction="row" justify="center" align="center">
                      {option.oldName}
                      <span style={{ fontSize: '28px', marginBottom: '6px', alignSelf: 'flex-start' }}>→</span>
                      {option.newName}
                    </FlexContainer>
                  </FlexContainer>
                </StyledVersionOptionInfo>
              </FlexContainer>
            ))
          ) : (
            <StyledVersionOptionInfo>{t('authorized.project.generateAndVersions.noClasses')}</StyledVersionOptionInfo>
          )
        ) : (
          ''
        )}
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameProcessingInfo
