import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface ISubsctiption {
    id: string;
    userId: string;
    amount: number;
    status: string;
    paymentPeriodMonths: number;
    nextChargeDate: string;
    type: string;
    isCombined: boolean;
    combinedSubscriptionId: string;
}

export interface ICreateCombinedSubsciptionPayload {
    balanceSubscriptionId: string;
    licenseSubscriptionId: string;
}

export interface ICreateCombinedSubsciptionRes {
    message: string;
}

export interface IGetSubscriptionsRes {
    subsctiptions: ISubsctiption[]
}

export default class SubscriptionsService {

    static async createCombined(payload: ICreateCombinedSubsciptionPayload): Promise<AxiosResponse<ICreateCombinedSubsciptionRes>> {
        return $authHost.post<ICreateCombinedSubsciptionRes>(`/subscriptions/combined`, payload)
    }

    static async deleteCombined(id: string): Promise<AxiosResponse<ICreateCombinedSubsciptionRes>> {
        return $authHost.delete<ICreateCombinedSubsciptionRes>(`/subscriptions/combined/${id}`)
    }

    static async getSubscriptions(): Promise<AxiosResponse<IGetSubscriptionsRes>> {
        return $authHost.get<IGetSubscriptionsRes>(`/subscriptions/`)
    }

}