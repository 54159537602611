import React, { FC } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { StyledDashedBr, StyledInfoPost, StyledSubCategoryUserLink, StyledSubCategoryUserSpan, StyledUserIcon, StyledyLastTopicHeader } from '../../constants/styled'
import { IFullTopicInfo } from '../../../../services/ForumService'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface ILastSubCategoryPost {
  lastTopic: IFullTopicInfo
}

const InfoPost: FC<ILastSubCategoryPost> = ({ lastTopic, ...props }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onTopicClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`/forum/${lastTopic.forumCategory.id}/${lastTopic.forumCategory.id}/topic/${lastTopic.id}`)
  }

  const onTopicCategoryClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(`/forum/${lastTopic.forumCategory.id}/${lastTopic.forumCategory.id}/topics`)
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" widthProps="100%">
      <StyledDashedBr />
      <StyledInfoPost>
        <StyledUserIcon />
        <FlexContainer direction="column" justify="flex-start" align="flex-start" onClick={onTopicClick}>
          <StyledyLastTopicHeader>{lastTopic?.title.charAt(0).toUpperCase() + lastTopic?.title?.slice(1)}</StyledyLastTopicHeader>
          <FlexContainer direction="row" justify="flex-start" align="center">
            <StyledSubCategoryUserSpan>{t('forum.By')}</StyledSubCategoryUserSpan>
            <StyledSubCategoryUserLink>{lastTopic?.author?.firstName}</StyledSubCategoryUserLink>
          </FlexContainer>
          <FlexContainer direction="row" justify="flex-start" align="center" paddingProps="0" margin="0">
            <StyledSubCategoryUserSpan>{t('forum.category')}:</StyledSubCategoryUserSpan>
            <StyledSubCategoryUserLink onClick={onTopicCategoryClick}>
              {lastTopic?.forumCategory?.name?.charAt(0).toUpperCase() + lastTopic?.forumCategory?.name?.slice(1)}
            </StyledSubCategoryUserLink>
          </FlexContainer>
        </FlexContainer>
      </StyledInfoPost>
      <StyledDashedBr />
    </FlexContainer>
  )
}

export default InfoPost
