import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { ICreateGroupForm } from '../../../models/ICreateGroupForm'
import FirstSlide from './FirstSlide'
import SecondSlide from './SecondSlide'
import ThirdSlide from './ThirdSlide'

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`
const CreateGroupForm: FC<ICreateGroupForm> = ({setVisible, ...props}) => {
  
  const [firstSlide, setFirstSlide] = useState(true)
  const [secondSlide, setSecondSlide] = useState(false)
  const [thirdSlide, setThirdSlide] = useState(false)

  const [textAreaText, setTextAreaText] = useState('')

  const [slidesValues, setSlidesValues] = useState({
    usage: 'Work', //Work, Academia, Hobbies
    name: '',
    type: '',
    users: '',
  })

  return (
    <StyledFormWrapper>
        {firstSlide 
          ? 
            <FirstSlide 
              setVisible={setVisible}
              slidesValues={slidesValues}
              setSlidesValues={setSlidesValues}
              setFirstSlide={setFirstSlide}
              setSecondSlide={setSecondSlide}
              setThirdSlide={setThirdSlide}
            /> 
          : null 
        }

        {secondSlide 
          ? 
            <SecondSlide
              setFirstSlide={setFirstSlide}
              setSecondSlide={setSecondSlide}
              setThirdSlide={setThirdSlide}
              slidesValues={slidesValues}
              setSlidesValues={setSlidesValues}
              setVisible={setVisible}
              textAreaText={textAreaText}
              setTextAreaText={setTextAreaText}
            /> 
          : null
        }

        {thirdSlide 
          ? 
            <ThirdSlide
              setFirstSlide={setFirstSlide}
              setSecondSlide={setSecondSlide}
              setThirdSlide={setThirdSlide}
              slidesValues={slidesValues}
              setSlidesValues={setSlidesValues}
              setVisible={setVisible}
            /> 
          : null
        }
    </StyledFormWrapper>
  )
}

export default CreateGroupForm