import React, { FC, useEffect, useState } from 'react'
import { NavigateFunction, useParams } from 'react-router-dom'
import ForumService, { IGetCategoryByIdResponce } from '../../../../../services/ForumService'
import { StyledBr, StyledHeaderRoute, StyledTopicsHeader, StyledTopicsHeaderRoutes, StyledTopicsHeaderSubTitle, StyledTopicsHeaderTitle } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'

interface ITopicsHeaderProps {
  navigate: NavigateFunction
  currentCategory: IGetCategoryByIdResponce
  currentSubCategory: IGetCategoryByIdResponce
}

const CreateTopicHeader: FC<ITopicsHeaderProps> = ({ navigate, currentCategory, currentSubCategory, ...props }) => {
  const { t } = useTranslation()
  const params = useParams()
  const [category, setCategory] = useState<IGetCategoryByIdResponce>()
  const getCategoryInfo = async () => {
    try {
      if (params.categoryId) {
        const responce = await ForumService.getCategoryById(params.categoryId, '1', 'updatedAt')
        console.log('CreateTopicCategoryInfo', responce)
        setCategory(responce.data)
      }
    } catch (error) {
      console.log('Error on CreateTopicCategoryInfo', error)
    }
  }

  useEffect(() => {
    getCategoryInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onHeaderLinkClick = () => {
    if (params.createTopic) {
      navigate(`/forum/${category?.category?.id}/${category?.category?.id}/topics`)
    }
    if (!params.createTopic) {
      navigate(`/forum/${currentCategory.category.id}/${currentSubCategory.category.id}/topics`)
    }
  }

  return (
    <StyledTopicsHeader>
      <StyledTopicsHeaderRoutes>
        <StyledHeaderRoute isLink={true} onClick={() => navigate('/forum')}>
          {t('forum.forum')}
        </StyledHeaderRoute>
        <span>{'/'}</span>
        {/* <StyledHeaderRoute isLink={false}>{currentCategory?.category?.name ? currentCategory?.category?.name.charAt(0).toUpperCase() + currentCategory?.category?.name.slice(1) : 'Category'}</StyledHeaderRoute>
            <span>{'/'}</span> */}
        <StyledHeaderRoute isLink={true} active onClick={onHeaderLinkClick}>
          {currentSubCategory?.category?.name ? currentSubCategory?.category?.name.charAt(0).toUpperCase() + currentSubCategory?.category?.name.slice(1) : category?.category?.name}
        </StyledHeaderRoute>
      </StyledTopicsHeaderRoutes>
      <StyledBr />
      <StyledTopicsHeaderTitle>{t('forum.createTopic')}</StyledTopicsHeaderTitle>
      <StyledTopicsHeaderSubTitle>{t('forum.folowRules')}</StyledTopicsHeaderSubTitle>
      <FlexContainer direction="row" justify="flex-end" align="center" paddingProps="0 10px">
        <ProjectButton onClick={() => navigate(`/forum/${currentCategory.category.id}/${currentSubCategory.category.id}/topics`)}>{t('buttons.back')}</ProjectButton>
      </FlexContainer>
      <StyledBr />
    </StyledTopicsHeader>
  )
}

export default CreateTopicHeader
