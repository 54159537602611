import { ISettings } from "../../../services/SettingsService"
import { IAutoMarkupModelWithTagClass } from "../store/AutoMarkupSlice"
import i18n from "../../../i18n"

export const getPrice = (selectedModel: IAutoMarkupModelWithTagClass, classesPrice: ISettings | undefined, textPrice: ISettings | undefined, setmentPrice: ISettings | undefined,) => {
  if (selectedModel.type === 'classes' && classesPrice) {
    return classesPrice.value
  }
  if (selectedModel.type === 'text' && textPrice) {
    return textPrice.value
  }
  if (selectedModel.type === 'segmentation' && setmentPrice) {
    return setmentPrice.value
  }
  return 3
}

export const getTitle = (selectedModel: IAutoMarkupModelWithTagClass) => {
  if (selectedModel.type === 'classes') {
    return i18n.t('authorized.project.draw.automarkupTool.makupType')
  }
  if (selectedModel.type === 'text') {
    return i18n.t('authorized.project.draw.automarkupTool.makupType2')
  }
  if (selectedModel.type === 'segmentation') {
    return i18n.t('authorized.project.draw.automarkupTool.makupType3')
  }
  return 'Object detection'
}

export const getMaxTrehold = (modelType: string) => {
  if (modelType === 'text') {
    return 30
  }
  if (modelType === 'classes') {
    return 99
  }
  if (modelType === 'segmentation') {
    return 99
  }
  return 99
}

export const getTreholdDefault = (modelType: string) => {
  if (modelType === 'text') {
    return 25
  }
  if (modelType === 'classes') {
    return 60
  }
  if (modelType === 'segmentation') {
    return 60
  }
  return 60
}

export const getTestImagesCount = (getImagesCount: () => number | undefined) => {
  const imagesCount = getImagesCount()
  if (imagesCount && (imagesCount < 20 && imagesCount > 1)) {
    return imagesCount
  }
  if (imagesCount && (imagesCount > 20)) {
    return 20
  }
  return 1
}

////////
//
// Доступ к инструментам разметки в зависимости от роли 
//
////////
export const getToolsRenderCondition = (guest: boolean, projectType: string, tool: string, roleNumber: number) => {
  // true === запретить!
  //roleNumber <= 4 --- админы/владелец/разрабы.
  //5 --- модер
  //6 --- разметчик
  //7 --- гость
  // console.log('conditions', guest, projectType, tool, roleNumber)

  if (guest || roleNumber > 6) {
    return true
  }

  if (tool === 'selection') {
    return false
  }

  //rectangle start
  if (tool === 'rectangle' && roleNumber <= 4) {
    return false
  }
  if (tool === 'rectangle' && roleNumber === 5) {
    return true
  }
  if (tool === 'rectangle' && roleNumber === 6) {
    return false
  }
  //rectangle end

  //polygon start
  if (tool === 'polygon' && roleNumber <= 4) {
    return false
  }
  if (tool === 'polygon' && roleNumber === 5) {
    return true
  }
  if (tool === 'polygon' && roleNumber === 6) {
    return false
  }
  //polygon end

  //circle start
  if (tool === 'circle' && roleNumber <= 4) {
    return false
  }
  if (tool === 'circle' && roleNumber === 5) {
    return true
  }
  if (tool === 'circle' && roleNumber === 6) {
    return false
  }
  //circle end


  //automarkup start
  if (tool === 'automarkup' && roleNumber <= 4) {
    return false
  }
  if (tool === 'automarkup' && roleNumber === 5) {
    return true
  }
  if (tool === 'automarkup' && roleNumber === 6) {
    return true
  }
  //automarkup end


  //doUnDo start
  if (tool === 'doUnDo' && roleNumber <= 4) {
    return false
  }
  if (tool === 'doUnDo' && roleNumber === 5) {
    return true
  }
  if (tool === 'doUnDo' && roleNumber === 6) {
    return false
  }
  //doUnDo end
}

export const getAutoMarkupToolsRenderCondition = (guest: boolean, projectType: string, tool: string, roleNumber: number) => {
  if (guest || roleNumber > 6) {
    return true
  }

  if (projectType === 'Other') {
    return false
  }

  if (projectType === 'Object detection' && (tool === 'text' || tool === 'classes')) {
    return false
  }

  if (projectType === 'Segmentation' && (tool === 'text' || tool === 'classes')) {
    return true
  }

  if (projectType === 'Key points' && (tool === 'text' || tool === 'classes' || tool === 'segmentation')) {
    return false
  }
}


////////
//
// Доступ к инструментам разметки в зависимости от типа проекта. 
// Функция выше регулирует доступ тольпо по роли.
//
////////
// export const getToolsRenderCondition = (guest: boolean, projectType: string, tool: string, roleNumber: number) => {
//   // true === запретить!
//   //roleNumber <= 4 --- админы/владелец/разрабы.
//   //5 --- модер
//   //6 --- разметчик
//   //7 --- гость
//   // console.log('conditions', guest, projectType, tool, roleNumber)

//   if (guest || roleNumber > 6) {
//     return true
//   }

//   if (tool === 'selection') {
//     return false
//   }
//   //rectangle start
//   if (projectType === 'Other' && tool === 'rectangle' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Other' && tool === 'rectangle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Other' && tool === 'rectangle' && roleNumber === 6) {
//     return false
//   }

//   if (projectType === 'Object detection' && tool === 'rectangle' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Object detection' && tool === 'rectangle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Object detection' && tool === 'rectangle' && roleNumber === 6) {
//     return false
//   }

//   if (projectType === 'Segmentation' && tool === 'rectangle' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Segmentation' && tool === 'rectangle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Segmentation' && tool === 'rectangle' && roleNumber === 6) {
//     return true
//   }

//   if (projectType === 'Key points' && tool === 'rectangle' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Key points' && tool === 'rectangle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Key points' && tool === 'rectangle' && roleNumber === 6) {
//     return true
//   }
//   //rectangle end

//   //polygon start
//   if (projectType === 'Other' && tool === 'polygon' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Other' && tool === 'polygon' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Other' && tool === 'polygon' && roleNumber === 6) {
//     return false
//   }

//   if (projectType === 'Object detection' && tool === 'polygon' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Object detection' && tool === 'polygon' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Object detection' && tool === 'polygon' && roleNumber === 6) {
//     return true
//   }

//   if (projectType === 'Segmentation' && tool === 'polygon' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Segmentation' && tool === 'polygon' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Segmentation' && tool === 'polygon' && roleNumber === 6) {
//     return false
//   }

//   if (projectType === 'Key points' && tool === 'polygon' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Key points' && tool === 'polygon' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Key points' && tool === 'polygon' && roleNumber === 6) {
//     return true
//   }
//   //polygon end

//   //circle start
//   if (projectType === 'Other' && tool === 'circle' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Other' && tool === 'circle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Other' && tool === 'circle' && roleNumber === 6) {
//     return false
//   }

//   if (projectType === 'Object detection' && tool === 'circle' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Object detection' && tool === 'circle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Object detection' && tool === 'circle' && roleNumber === 6) {
//     return true
//   }

//   if (projectType === 'Segmentation' && tool === 'circle' && roleNumber <= 4) {
//     return true
//   }
//   if (projectType === 'Segmentation' && tool === 'circle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Segmentation' && tool === 'circle' && roleNumber === 6) {
//     return true
//   }

//   if (projectType === 'Key points' && tool === 'circle' && roleNumber <= 4) {
//     return false
//   }
//   if (projectType === 'Key points' && tool === 'circle' && roleNumber === 5) {
//     return true
//   }
//   if (projectType === 'Key points' && tool === 'circle' && roleNumber === 6) {
//     return false
//   }
//   //circle end


//   //automarkup start
//   if (tool === 'automarkup' && roleNumber <= 4) {
//     return false
//   }
//   if (tool === 'automarkup' && roleNumber === 5) {
//     return true
//   }
//   if (tool === 'automarkup' && roleNumber === 6) {
//     return true
//   }
//   //automarkup end


//   //doUnDo start
//   if (tool === 'doUnDo' && roleNumber <= 4) {
//     return false
//   }
//   if (tool === 'doUnDo' && roleNumber === 5) {
//     return true
//   }
//   if (tool === 'doUnDo' && roleNumber === 6) {
//     return false
//   }
//   //doUnDo end
// }
