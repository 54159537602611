import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export interface ICoinTransaction {
    id: string;
    userId: string;
    amount: number;
    type: string;
    description: string;
    relatedTransactionId: string;
    createdAt: string;
    updatedAt: string;
}

export interface IFiatTransaction {
    id: string;
    userId: string;
    invoiceId: string;
    amount: number;
    type: string;
    status: string;
    createdAt: string;
    updatedAt: string;
}

export interface IRobokassaInitiateReq {
    amount: string;
    description: string;
    recurring: boolean;
}

export interface IRobokassaInitiateRes {
    paymentsLink: string;
    transactionId: string;
    message: string;
}

// export interface IRobokassaResultReq {

// }

// export interface IRobokassaResultRes {
    
// }

export interface IFiatTransactionsRes {
    total: number;
    remain: number;
    transaction: IFiatTransaction[];
}

export interface ICoinsTransactionsRes {
    total: number;
    remain: number;
    internalTransaction: ICoinTransaction[];
}

export default class PaymentsService {

    static async robokassaInitiate(payload: IRobokassaInitiateReq): Promise<AxiosResponse<IRobokassaInitiateRes>> {
        return $authHost.post<IRobokassaInitiateRes>(`/payments/robokassa/initiate`, payload)
    }

    // static async robokassaResult(payload: IRobokassaResultReq): Promise<AxiosResponse<IRobokassaResultRes>> {
    //     return $authHost.post<IRobokassaResultRes>(`/payments/robokassa/result`, payload)
    // }

    static async getFiatTransactions(offset: number = 0, limit: number = 1000): Promise<AxiosResponse<IFiatTransactionsRes>> {
        return $authHost.get<IFiatTransactionsRes>(`/payments/transactions/currency?limit=${limit}&offset=${offset}`)
    }

    static async getCoinsTransactions(offset: number = 0, limit: number = 1000): Promise<AxiosResponse<ICoinsTransactionsRes>> {
        return $authHost.get<ICoinsTransactionsRes>(`/payments/transactions/internal?limit=${limit}&offset=${offset}`)
    }

}