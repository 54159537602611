import React, { FC, useEffect, useState } from 'react'
import { IPasswordRecoveryProps } from '../interfaces/IPasswordRecoveryProps';
import Input from '../../../UI/Input/Input';
import { Colors } from '../../../models/Colors';
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton';
import { StyledErrorText, StyledErrorWrapepr, StyledEyeIcon, StyledForm, StyledFormHeader} from '../constants/StyledConts';
import { validatePasswordMismatch, validatePasswordWeight } from '../helpers/validateForms';
import { setInputFocusError } from '../helpers/inputFocusColorChange';
import { passwordResetErrorTotal } from '../reducers/passwordResetErrorTotal';
import { useAppDispatch, useAppSelector } from './../../../hooks/redux';
import AuthService from '../../../services/AuthService';
import { useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { useTranslation } from 'react-i18next';

export const PasswordRecoveryForm: FC<IPasswordRecoveryProps> = ({navigate, ...props}) => {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useAppDispatch()
  const total = useAppSelector(state => state.passwordResetErrorTotal.total)
  //Error state
  const [visible, setVisible] = useState(false)
  const [errorText, setErrorText] = useState('')
  
  //Input values  
  const [PasswordValue, setPasswordValue] = useState('')
  const [repeatPasswordValue, setRepeatPasswordValue] = useState('')
  const [codeValue, setCodeValue] = useState('')

  //Focused input state   
  const [PasswordFocusColor, setPasswordFocusColor] = useState(Colors.gray)
  const [repeatPasswordFocusColor, setRepeatPasswordFocusColor] = useState(Colors.gray)

  const [isPasswordHidden, setIsPasswordHidden] = useState(false)

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if (e.keyCode === 13 || e.key === 'enter') {
        submitClickHandler()
      }
    }

    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, PasswordValue, repeatPasswordValue])

  const passwordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(e.target.value)
    const validateResult = validatePasswordWeight(e.target.value)
    setInputFocusError(setVisible, setErrorText, setPasswordFocusColor, validateResult) 
    if (validateResult.isValid) {
      dispatch(passwordResetErrorTotal.actions.setIsPasswordError(validateResult.isValid))
    } 
  }

  const repeatPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPasswordValue(e.target.value)
    const validateResult = validatePasswordMismatch(PasswordValue, e.target.value)
    setInputFocusError(setVisible, setErrorText, setRepeatPasswordFocusColor, validateResult) 
    if (validateResult.isValid) {
      dispatch(passwordResetErrorTotal.actions.setIsPasswordMismatchError(validateResult.isValid))
    } 
  }

  const codeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeValue(e.target.value)
  }

  const showErrorField = (text:string) => {
    setVisible(true)
    setErrorText(text)
}

const submitClickHandler = async () => {
  if (total) {
    try {
      if (params.code) {
        const responce = await AuthService.activateForgotPassword(params.code, PasswordValue, codeValue)
        if (responce.data.message) {
          navigate('/signin')
        }
        console.log('activateForgotPassword', responce)
      }
    } catch (error:any) {
      if (error?.code === "ERR_NETWORK") {
        setVisible(true)
        setErrorText(t('errors.network'))
        return
      }
      showErrorField(error.response.data.error ? error.response.data.error : error.response.data.message)
      console.log(error);
    }
  }
  if (!total) {
    showErrorField(t('other.formError'))
  }
}

  return (
    <>
      <StyledErrorWrapepr visible={visible}>
          <StyledErrorText>{errorText}</StyledErrorText>
      </StyledErrorWrapepr>   
      <StyledForm onSubmit={(e:any) => {e.preventDefault()}}>
          <StyledFormHeader>{t('notAuthorized.passReset.title')}</StyledFormHeader>
          <FlexContainer direction='row' justify='center' align='center' gap="5px" margin='0 0 0 30px'>
            <Input 
              withTooltip={true}
              tooltipText={t('notAuthorized.passwordTooltip')}
              label={t('notAuthorized.placeholders.enterNewPassword')}
              focusColor={PasswordFocusColor} 
              borderColor={PasswordFocusColor} 
              value={PasswordValue} 
              onChange={passwordChangeHandler}
              placeholder={t('notAuthorized.placeholders.enterPassword')}
              type={isPasswordHidden ? "text" : 'password'}
            />
            <StyledEyeIcon active={isPasswordHidden} onClick={() => setIsPasswordHidden(!isPasswordHidden)}/>
          </FlexContainer>

          <Input 
            label={t('notAuthorized.placeholders.repeatNewPassword')}
            focusColor={repeatPasswordFocusColor} 
            borderColor={repeatPasswordFocusColor}
            value={repeatPasswordValue} 
            onChange={repeatPasswordChangeHandler}
            type={isPasswordHidden ? "text" : 'password'}
            placeholder={t('notAuthorized.placeholders.enterPassword')}
          />
          <Input 
            label={t('notAuthorized.inputTitles.code')} 
            value={codeValue} 
            onChange={codeChangeHandler}
            placeholder='code'
            type={t('notAuthorized.placeholders.code')}
          />
          <ProjectButton margin='10px 0' widthProps='160px' onClick={submitClickHandler}>{t("buttons.continue")}</ProjectButton>
      </StyledForm>
    </>
  )
}


