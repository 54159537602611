import styled from "styled-components"
import { Colors } from "../../../models/Colors"
import userPlusIcon from '../../../assets/icons/user-plus-1.svg'
import usersGroupIcon from '../../../assets/icons/users-group-rounded.svg'
import inputSearchIcon from '../../../assets/icons/search-loupe-input.svg'
import arrowLeftIcom from '../../../assets/icons/arrow-to-left.svg'
import userCardAvatar from '../../../assets/icons/avatar.png'
import crossIcon from '../../../assets/icons/cross.svg'
import markIcon from '../../../assets/icons/mark.svg'
import { IWorkspaceProps } from "../models/IWorkspaceProps"

export const StyledUserPlusIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${userPlusIcon});
  mask-image: url(${userPlusIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

export const StyledUsersGroupIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${usersGroupIcon});
  mask-image: url(${usersGroupIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

export const StyledInputSearchIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${inputSearchIcon});
  mask-image: url(${inputSearchIcon});
  background-color: ${Colors.orange};
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
`

export const StyledArrowLeftIcon = styled.i`
  display: block;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${arrowLeftIcom});
  mask-image: url(${arrowLeftIcom});
  background-color: ${Colors.WHITE};
  width: 20px;
  height: 20px;

  transition: all .1s linear;
`

export const StyledGoBack = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  transition: all .1s linear;
  cursor: pointer;

  position: absolute;
  top: -38px;
  left: 5px;
  
  :hover {
    color: ${Colors.orange};
  }

  :hover ${StyledArrowLeftIcon} {
    background-color: ${Colors.orange};
  }
`

interface IIconProps {
  color?: string;
  width?: string;
  height?: string;
  hoverColor?: string;
  cursorProps?: boolean;
  marginProps?: string;
}

export const StyledCrossIcon = styled.i<IIconProps>`
  display: block;
  position: relative;
  z-index: 2;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${crossIcon});
  mask-image: url(${crossIcon});
  background-color:  ${props => props.color || Colors.red};
  width: ${({width}) => width || '22px'};
  height: ${({height}) => height || '22px'};
  margin: ${props => props.marginProps ? props.marginProps : '0 0 5px 0'};

  cursor: ${props => props.cursorProps ? 'default' : 'pointer'};
  transition: all .1s linear;
  :hover {
    background-color: ${props => props.hoverColor || Colors.lightred};
  }
`

export const StyledMarkIcon = styled.i<IIconProps>`
  display: block;
  position: relative;
  z-index: 2;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${markIcon});
  mask-image: url(${markIcon});
  background-color:  ${props => props.color || Colors.green};
  width: ${({width}) => width || '22px'};
  height: ${({height}) => height || '17px'};
  margin-bottom: 5px;

  cursor: ${props => props.cursorProps ? 'default' : 'pointer'};

  transition: all .1s linear;
  :hover {
    background-color: ${props => props.hoverColor || Colors.lightgreen};
  }
`

interface IUserCardAvatarProps {
  img?: any;
  widthProps?: string;
  heightProps?: string;
  minWidth?: string;
}

export const StyledUserCardAvatar = styled.i<IUserCardAvatarProps>`
  display: block;
  background: url(${props => props.img || userCardAvatar});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: ${props => props.widthProps ? props.widthProps : '45px'};
  height: ${props => props.heightProps ?  props.heightProps : '45px'};
  min-width: ${props => props.minWidth ? props.minWidth : '0'};
  border-radius: 50px;
`

export const StyledHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  height: 65px;
  padding: 15px;
  
`

interface IStyledBrProps {
  margin?: string;
}

export const StyledBr = styled.div<IStyledBrProps>`
    width: 100%;
    height: 2px;
    background-color: ${Colors.black};
    margin: ${props => props.margin ? props.margin : 0};
`

export const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  padding-bottom: 30px;
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 85px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-around;
  max-height: 100%;
  padding: 25px 20px;
  gap: 20px;
  flex-wrap: wrap;

  overflow: auto;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

export const StyledEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  gap: 35px;
  h1 {
    color: ${Colors.textgray}
  }
`

export const StyledEmptyProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 35px;
  h1 {
    color: ${Colors.textgray}
  }
`

export const StyledGroupName = styled.h3`
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledManagement = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  margin-top: 40px;
  width: 100%;
  padding: 0 35px;

  position: relative;

  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 80px;
`

export const StyledManagementContentWrapper = styled.div`
  min-height: 100%;
  max-height: 100%;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 8px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.darker}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.gray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.btngray}; /* отступ вокруг бегунка */
  }
`

export const StyledManagementContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 25px 15px;
  gap: 25px;
`

//users table
export const StyledUsersTable = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #393939;
  padding: 0 15px 15px 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const StyledUsersTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  width: 100%;
  padding: 10px 0;
`

export const StyledTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;

  width: 235px;
`

interface IStyledRoleNameProps {
  color?: string;
}

export const StyledRoleName = styled.span<IStyledRoleNameProps>`
  margin-left: 5px;
  color: ${({color}) => color || 'white'};
`

export const StyledGroupUserCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 55px;
`

export const StyledUserCredentials = styled.h5`
    width: 165px;
    height: 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledUserEmail = styled.h5`
    width: 165px;
    height: 18px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #C1C1C1;
`

export const StyledParagraph = styled.p`
  padding: 25px 15px;
  font-size: 16px;
`

export const StyledTooltip = styled.span`
  display: none;

  position: absolute;
  top: -40px;
  left: -220%;

  font-size: 12px;
  font-weight: 400;
  text-align: center;
  background: ${Colors.dark};
  border: 1px solid ${Colors.orange};
  color: #fff;

  width: 120px;

  z-index: 10;

  :after {
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid ${Colors.orange};
  border-top: 5px solid ${Colors.orange};
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}
`

interface ITooltipProps {
  isAdmin?: boolean;
}

export const StyledTooltipWrapper = styled.div<ITooltipProps>`
  position: relative;
  height: 22px;

  :hover ${StyledTooltip} {
    display: ${props => props.isAdmin ? 'none' : 'block'};
  }
`

export const StyledInviteForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 170px;
  width: 100%;
  padding-bottom:120px;

  overflow: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 12px; /* ширина скролла */
  }
  ::-webkit-scrollbar-track {
    background: ${Colors.black}; /* цвет зоны отслеживания */
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.dark}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 2px solid ${Colors.black}; /* отступ вокруг бегунка */
  }
`

export const StyledSelectWrapper = styled.div`
  width: 400px;
`

export interface IErrorProps {
  visible?: boolean;
}

export const StyledErrorWrapepr = styled.div<IErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    border: 1px solid ${Colors.darkred};
    border-radius: 15px;
    padding: 5px 10px;
    max-width: 400px;
    min-width: 400px;
    min-height: 60px;
    overflow-wrap: break-word;
    line-height: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 680px) {
        max-width: 350px;
        min-width: 350px;
    }
`

export const StyledErrorText = styled.p<IErrorProps>`
    opacity: ${({ visible }) => visible ? '1' : '0'};
    color: ${Colors.red};
    font-size: 14px;
    font-weight: bold;
    height: 20px;
`
//workspace

export const StyledNewGroupTitle = styled.div<IWorkspaceProps>`
    color: ${props => props.activeProps ? Colors.lightorange : Colors.orange};
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

export const StyledCounter = styled.div<IWorkspaceProps>`
    display:flex;
    align-items: center;
    justify-content:center;

    min-width: 30px;
    min-height: 30px;

    border-radius: 70px;

    background: ${props => props.activeProps ? Colors.lightorange : Colors.orange};

    font-style: normal;
    font-size: 24px;
    font-weight: 400;
    color: ${Colors.WHITE};
    text-align: center;
    line-height: 17px;

    transition: all 0.1s linear;     

    cursor: pointer;
    &:hover {
        background: ${Colors.lightorange};
        box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`

export const StyledNewGroup = styled.div<IWorkspaceProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  width: 230px;
  height: 40px;

  border: 1px solid ${props => props.activeProps ? Colors.lightorange : Colors.orange};
  border-radius: 5px;
  transition: all 0.1s linear;

  cursor: pointer;

  &:hover {
    color: ${Colors.lightorange};
    border-color: ${Colors.lightorange}; 
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  &:hover ${StyledCounter} {
    background: ${Colors.lightorange};
  }
  &:hover ${StyledNewGroupTitle} {
    color: ${Colors.lightorange};
  }
`