import React, { FC, useEffect, useState } from 'react'
import { StyledCrossIcon, StyledGroupUserCard, StyledMarkIcon, StyledTableItem, StyledTooltip, 
         StyledTooltipWrapper, StyledUserCardAvatar, StyledUserCredentials, StyledUserEmail } from '../../../../constants/styled';
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer';
import ProjectSelect, { Option } from '../../../../../../UI/ProjectSelect/ProjectSelect';
import { Colors } from '../../../../../../models/Colors';
import { IPermissionSummary, ISummaryRole } from '../../../../../../services/AccessService';
import { IProjectUserFullInfo, WorkspacesSlice } from '../../../../../Projects/store/reducers/WorkspacesReducer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux';
import RolesService, { IAddUserToProjectReq } from '../../../../../../services/RolesService';
import { StyledRoleName } from '../../../../../Projects/constants/Styled';
import { asyncGetProjectInfoAndUsers } from '../../../../../../store/reducers/AcyncActions';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../services/ProjectService';

interface IUserRole {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    permissions?: IPermissionSummary[];
}

interface ProjectUserCardProps {
    userInfo: IProjectUserFullInfo['user'];
    userRole: IProjectUserFullInfo['projectRole'];
    projectUsersInfo: IProjectUserFullInfo[];
    setProjectUsersInfo: React.Dispatch<React.SetStateAction<IProjectUserFullInfo[]>>
    optionsTable: Option[];
    roleNumber: number;
    projectInfo: IProject;
}

const ProjectUserCard: FC<ProjectUserCardProps> = ({userInfo, userRole, projectUsersInfo, setProjectUsersInfo, optionsTable, roleNumber, projectInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const projectRoles = useAppSelector(state => state.rolesReducer.projectRoles)
  const [role, setRole] = useState("");
  const [options, setOptions] = useState<Option[]>([])
  const [roleInfo, setRoleInfo] = useState<IUserRole>({} as IUserRole)
  const [selectedRole, setSelectedRole] = useState<Option | null>(null)
  // !!! true === false !!!
  const [canSaveOrDelete, setCanSaveOrDelete] = useState(false)
  const [canChange, setCanChange] = useState(false)

  useEffect(() => {
    if (userRole) {
      setRole(userRole.name)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userRole?.id) {
      setRoleInfo(userRole)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getUserRoleNumberInUserCard = () => {
        // console.log('myROle', roleNumber)
        // console.log('userRole', userRole)
        // if (roleInfo.name === 'group_owner' && roleNumber && role) {
        //   setCanChange(2 <= roleNumber)
        //   setCanSaveOrDelete(2 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'group_admin' && roleNumber && role) {
        //   setCanChange(3 <= roleNumber)
        //   setCanSaveOrDelete(3 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'project_admin' && roleNumber && role) {
        //   setCanChange(4 <= roleNumber)
        //   setCanSaveOrDelete(4 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'project_owner' && roleNumber && role) {
        //   setCanChange(4 <= roleNumber)
        //   setCanSaveOrDelete(4 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'project_moderator' && roleNumber && role) {
        //   setCanChange(5 <= roleNumber && roleInfo.name === role)
        //   setCanSaveOrDelete(5 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'project_annotator' && roleNumber && role) {
        //   setCanChange(6 <= roleNumber)
        //   setCanSaveOrDelete(6 <= roleNumber && roleInfo.name === role)
        // }
        // if (roleInfo.name === 'project_user' && roleNumber && role) {
        //   setCanChange(7 <= roleNumber)
        //   setCanSaveOrDelete(7 <= roleNumber && roleInfo.name === role)
        // }

        if (roleNumber === 1 || roleNumber === 2 || roleNumber === 3 || roleNumber === 4) {
          if (roleNumber === 2 && roleInfo.name === 'group_owner' || roleInfo.name === 'group_admin') {
            setCanChange(true)
            setCanSaveOrDelete(true)
            return
          }
          if (roleNumber === 3 && roleInfo.name === 'group_admin' || roleInfo.name === 'group_owner') {
            setCanChange(true)
            setCanSaveOrDelete(true)
            return
          }
          if (roleNumber === 4 && roleInfo.name === 'project_owner' || roleInfo.name === 'group_admin' || roleInfo.name === 'group_owner') {
            setCanChange(true)
            setCanSaveOrDelete(true)
            return
          }
          if (roleNumber === 4 && roleInfo.name === 'project_admin') {
            setCanChange(true)
            setCanSaveOrDelete(true)
            return
          }
          
          setCanChange(false)
          setCanSaveOrDelete(false)
        } else {
          setCanChange(true)
          setCanSaveOrDelete(true)
        }
    }
    
    if (projectUsersInfo?.length) {
      getUserRoleNumberInUserCard()
    }

    //Select options filter
    if (optionsTable?.length && roleNumber === 1) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user'
    )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }
    if (optionsTable?.length && roleNumber === 2) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user'
      )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }
    if (optionsTable?.length && roleNumber === 3) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user'
      )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }
    if (optionsTable?.length && roleNumber === 4) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user' && 
        option.value !== 'project_owner' &&
        option.value !== 'project_admin'
      )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }
    if (optionsTable?.length && roleNumber === 5) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user' &&
        option.value !== 'project_owner' &&
        option.value !== 'project_admin'         
      )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }
    if (optionsTable?.length && roleNumber > 5) {
      const filteredOptions = optionsTable.filter(option => 
        option.value !== 'group_owner' && 
        option.value !== 'group_admin' &&
        option.value !== 'group_user' &&
        option.value !== 'project_owner' &&
        option.value !== 'project_admin'         
      )
      setOptions(filteredOptions)
      const selectedRole = optionsTable.find((item) => item.value === role);
      if (selectedRole) {
        setSelectedRole(selectedRole)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersInfo, roleNumber, role, optionsTable])
  
  const handleRoleSelect = (value: string) => {
    setRole(value);
  };

  const onDeleteUserClick = async () => {
    if (!canSaveOrDelete) {
      try {
        dispatch(WorkspacesSlice.actions.setLoadingStart())
        const responce = await RolesService.deleteProjectUser(projectInfo.id, userInfo.id)
        console.log('onDeleteUserClick', responce)
        const filteredUsers = projectUsersInfo.filter(user => user.user.id !== userInfo.id)
        setProjectUsersInfo(filteredUsers)
        if (projectInfo.id) {
          dispatch(asyncGetProjectInfoAndUsers(projectInfo.id))
        }
        dispatch(WorkspacesSlice.actions.setLoadingEnd())
      } catch (error) {
        dispatch(WorkspacesSlice.actions.setErrorState('Error on DeleteUserClick'))
        console.log('onDeleteUserClick', error)
      }
    }
  }

  const asyncPutProjectUsers = async (projectId: string, projectMembers: IAddUserToProjectReq, usersInfo: IProjectUserFullInfo[], findedRole: ISummaryRole) => {
    try {
      dispatch(WorkspacesSlice.actions.setLoadingStart())
      const responce = await RolesService.putProjectUsers(projectId, projectMembers)
      console.log('asyncPutProjectUsers', responce)
      setProjectUsersInfo(usersInfo)
      setRole(findedRole.name)
      setRoleInfo(findedRole)
      if (findedRole.name === 'group_owner' && roleNumber && role) {
        setCanChange(2 <= roleNumber)
        setCanSaveOrDelete(2 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'group_admin' && roleNumber && role) {
        setCanChange(3 <= roleNumber)
        setCanSaveOrDelete(3 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'project_admin' && roleNumber && role) {
        setCanChange(4 <= roleNumber)
        setCanSaveOrDelete(4 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'project_owner' && roleNumber && role) {
        setCanChange(4 <= roleNumber)
        setCanSaveOrDelete(4 <= roleNumber && findedRole.name === role)
        }
      if (findedRole.name === 'project_moderator' && roleNumber && role) {
        setCanChange(5 <= roleNumber)
        setCanSaveOrDelete(5 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'project_annotator' && roleNumber && role) {
        setCanChange(6 <= roleNumber)
        setCanSaveOrDelete(6 <= roleNumber && findedRole.name === role)
      }
      if (findedRole.name === 'project_user' && roleNumber && role) {
        setCanChange(7 <= roleNumber)
        setCanSaveOrDelete(7 <= roleNumber && findedRole.name === role)
      }
      dispatch(WorkspacesSlice.actions.setLoadingEnd())
      if (projectId) {
        dispatch(asyncGetProjectInfoAndUsers(projectId))
      }
    } catch (error) {
      dispatch(WorkspacesSlice.actions.setErrorState('Error on asyncPutProjectUsers'))
      console.log('asyncPutProjectUsers', error)
    }
  }

  const onSaveUserRoleClick = () => {
    if (roleInfo.name !== role) {
      const findedRole = projectRoles.find(projectRole => projectRole.name === role) // 
      const projectUsersInfoCopy: IProjectUserFullInfo[] = JSON.parse(JSON.stringify(projectUsersInfo))
      
      if (findedRole) {
        const usersToApi = projectUsersInfo.map(user => ({user: {id: user.user.id}, role: {id: user.projectRole.id}}))
        const findedUserInUsersToApi = usersToApi.find(user => user.user.id === userInfo.id)
        const findedUserInGroupUsers = projectUsersInfoCopy.find(user => user.user.id === userInfo.id)

        if (findedUserInUsersToApi && findedUserInGroupUsers) {
          findedUserInUsersToApi.role.id = findedRole.id
          findedUserInGroupUsers.projectRole.id = findedRole.id
          asyncPutProjectUsers(projectInfo.id, {projectMembers: [findedUserInUsersToApi]}, projectUsersInfoCopy, findedRole)
        }
      }
    }
  }

  return (
    <StyledGroupUserCard>
      <StyledTableItem>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='8px'>
          <StyledUserCardAvatar minWidth="45px"/>
          <FlexContainer direction='column' justify='center' align='flex-start'>
            <StyledUserCredentials>{userInfo.firstName? userInfo.firstName + ' ' + userInfo.lastName : t('authorized.project.projectManagement.tableUser')}</StyledUserCredentials>
            <StyledUserEmail>{userInfo.email}</StyledUserEmail>
          </FlexContainer>
        </FlexContainer>
      </StyledTableItem>
      <StyledTableItem>
        {canChange 
          ? <StyledTableItem><StyledRoleName>{roleInfo.displayName}</StyledRoleName></StyledTableItem>
          : <ProjectSelect options={options} mode='rows' selected={selectedRole || null} onChange={handleRoleSelect} placeholder={t('authorized.project.projectManagement.selectRole')}/>
        }
      </StyledTableItem>
      <StyledTableItem>{userInfo.isActive ? "Active" : "Pending"}</StyledTableItem>
      <StyledTableItem>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='20px'>
          <StyledTooltipWrapper isAdmin={roleInfo.name === role}>
            <StyledTooltip>{t('other.save')}</StyledTooltip>
            <StyledMarkIcon onClick={onSaveUserRoleClick} color={roleInfo.name === role ? Colors.gray : ''} hoverColor={roleInfo.name === role ? Colors.gray : ''} cursorProps={roleInfo.name === role}/>
          </StyledTooltipWrapper>
          <StyledTooltipWrapper isAdmin={canSaveOrDelete}>
            <StyledTooltip>{t('other.delete')}</StyledTooltip>
            <StyledCrossIcon onClick={onDeleteUserClick} color={canSaveOrDelete ? Colors.gray : ''} hoverColor={canSaveOrDelete ? Colors.gray : ''} cursorProps={canSaveOrDelete}/>
          </StyledTooltipWrapper>
        </FlexContainer>
      </StyledTableItem>
    </StyledGroupUserCard>
  )
}

export default ProjectUserCard