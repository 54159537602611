import React, { FC } from 'react'
import { IGetTopicByIdResponce } from '../../../../../services/ForumService'
import { StyledDate, StyledTopicAnswer, StyledTopicDescription, StyledTopicEditorWrapper, StyledTopicMessageToolbar } from '../../../constants/styled'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import TopicPageUser from './TopicPageUser'
import Editor from '../../../../Editor'
// import { StyledLikeIcon } from '../../../constants/styled'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { ForumReducer } from '../../../store/slices/ForumReducer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { asyncPutTopicDescription } from '../../../../../store/reducers/AcyncActions'
import { IUsersMeResponse } from '../../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ITopicDescriptionProps {
  currentUser: IUsersMeResponse
  currentTopic: IGetTopicByIdResponce
  readOnly: boolean
  isEditClicked: boolean
  setIsEditClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const TopicDescription: FC<ITopicDescriptionProps> = ({ currentUser, currentTopic, readOnly, isEditClicked, setIsEditClicked }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const editorState = useAppSelector((state) => state.ForumReducer.editorHtmlState)
  const onAnswerClick = () => {
    const field = document.getElementById('field')
    if (field) {
      window.scrollTo(0, field.offsetTop)
      dispatch(ForumReducer.actions.setEditorMessage(`<p><span style="color: rgb(65,168,95);">@${currentTopic?.topic?.author?.firstName}</span><span>,</span></p>`))
    }
  }

  const onCancelClick = () => {
    setIsEditClicked(true)
  }

  const onEditClick = () => {
    if (editorState) {
      dispatch(asyncPutTopicDescription(currentTopic.topic.id, currentTopic.topic.title, editorState, currentTopic.topic.forumCategory.id))
    }
    setIsEditClicked(true)
  }

  return (
    <StyledTopicDescription>
      <FlexContainer direction="row" justify="flex-start" align="flex-start">
        <FlexContainer direction="column" justify="flex-start" align="center" widthProps="content-width" paddingProps="10px 0">
          <StyledDate>{t('forum.creator')}</StyledDate>
          <TopicPageUser user={currentTopic?.topic?.author} currentTopic={currentTopic} />
          <StyledDate>{currentTopic?.topic?.createdAt?.slice(0, 10) + ` ${t('forum.in')} ` + currentTopic?.topic?.createdAt?.slice(11, 19)}</StyledDate>
        </FlexContainer>
        <StyledTopicEditorWrapper>
          {currentTopic?.topic?.description ? <Editor onloadContent={currentTopic?.topic?.description} readOnly={isEditClicked} cancel={isEditClicked} overview={false}/> : ''}

          {!isEditClicked ? (
            <FlexContainer direction="row" align="center" justify="flex-end" gap="20px" paddingProps="0 15px">
              <ProjectButton widthProps="140px" onClick={onCancelClick}>
                {t('buttons.cancel')}
              </ProjectButton>
              <ProjectButton widthProps="140px" onClick={onEditClick}>
                {t('other.edit')}
              </ProjectButton>
            </FlexContainer>
          ) : (
            ''
          )}
        </StyledTopicEditorWrapper>
      </FlexContainer>
      <StyledTopicMessageToolbar>
        {currentUser?.id ? <StyledTopicAnswer onClick={onAnswerClick}>{t('forum.reply')}</StyledTopicAnswer> : ''}
        {/* <FlexContainer direction='row' justify='flex-end' widthProps='100px' gap='10px'>
                  <span>{currentTopic?.topic?.likes}</span>
                  <StyledLikeIcon/>
                </FlexContainer> */}
      </StyledTopicMessageToolbar>
    </StyledTopicDescription>
  )
}

export default TopicDescription
