import React, { FC, useEffect } from "react";
import { NavigateFunction, Params, useParams } from "react-router-dom";
import {
  StyledColumnHeaderText,
  StyledColumnHeaderTitle,
  StyledContentWrapper,
  StyledEmptyDatasets,
  StyledHeader,
  StyledLeftColumn,
  StyledLoupeIcon,
  StyledRightColumn,
  StyledRightColumnHeader,
  StyledVersionsColumn,
  StyledVersionsHeader,
} from "../../../constants/styled";
import { StyledBr } from "../../AssignNav/AssignNav";
import ProjectButton from "../../../../../UI/Buttons/ProjectButton/ProjectButton";
import VersionBtn from "../../../../../UI/VersionBtnComponent/VersionBtn";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { tabsSlice } from "../../../store/reducers/TabsReducer";
import GenerateAccordion from "./Components/GenerateAccordion";
import {
  asyncGetDatasetLabels,
  asyncGetVersionsSummary,
} from "../../../../../store/reducers/AcyncActions";
import FlexContainer from "../../../../../components/FlexContainer/FlexContainer";
import Loader from "../../../../../UI/Loader/Loader";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Colors } from "../../../../../models/Colors";
import { asyncGetListImages } from "../../../../ImagesList/store/ImagesListAsyncActions";
import { EImagesPage, imagesListSlice } from "../../../../ImagesList/store/ImagesListReducer";
import { IProject } from "../../../../../services/ProjectService";
import { StyledALink } from '../../../../../styles/GlobalStyles'

interface IProjectGenerateProps {
  navigate: NavigateFunction;
  projectInfo: IProject;
  param: Readonly<Params<string>>;
}

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 5px;
`

const ProjectGenerate: FC<IProjectGenerateProps> = ({
  navigate,
  projectInfo,
  param,
  ...props
}) => {
  const { t } = useTranslation();
  const params = useParams()
  const dispatch = useAppDispatch();
  const versionsSummary = useAppSelector((state) => state.versionsReducer.versionsSummary);
  const allDatasetsImages = useAppSelector((state) => state.datasetsReducer.allAnnotatedDatasetsImages);
  const generateLabels = useAppSelector((state) => state.generateReducer.generateDatasetLabels);
  const { imagesTotal, isLoading } = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {
    if (param.tab === "generate" && param.id) {
      dispatch(tabsSlice.actions.setActiveTab("generate"));
      dispatch(asyncGetVersionsSummary(param.id));
      dispatch(asyncGetDatasetLabels(projectInfo?.id));
      dispatch(imagesListSlice.actions.setInitialState())
      const payload = {
        id: params.id!,
        page: EImagesPage.datasetsPage,
        limit: 50,
        offset: 0,
        split: { title: t('imagesList.allTypes'), value: 'all' },
        search: ''
      }
      console.log('payload', payload)
      dispatch(asyncGetListImages(payload))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onAnnotateBtnClick = () => {
    navigate(`/projects/${projectInfo.id}/annotate`);
  };

  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo.name ? (
          <h3>
            {projectInfo.name} {t("authorized.project.nav.generate")}
          </h3>
        ) : (
          <h3>{t("authorized.project.nav.generate")}</h3>
        )}
      </StyledHeader>
      <StyledBr />

      <StyledContentWrapper>
        <StyledLeftColumn>
          <ProjectButton
            widthProps="240px"
            heightProps="40px"
            paddingProps="10px"
            active
          >
            {t("authorized.project.generateAndVersions.versionGeneration")}
          </ProjectButton>
          <StyledVersionsColumn>
            <StyledVersionsHeader>
              {t("authorized.project.generateAndVersions.versions")}
            </StyledVersionsHeader>
            {versionsSummary && !isLoading
              ? versionsSummary.map((version) => (
                  <VersionBtn
                    projectInfo={projectInfo}
                    versionInfo={version}
                    key={version.id + Date.now()}
                    paramId={param.versionId ? param.versionId : ""}
                  />
                ))
              : ""}
          </StyledVersionsColumn>
        </StyledLeftColumn>

        <StyledRightColumn>
          <StyledRightColumnHeader>
            <StyledColumnHeaderTitle>
              {t("authorized.project.generateAndVersions.generateNewVer")}
            </StyledColumnHeaderTitle>
            {imagesTotal ? (
              <StyledColumnHeaderText>
                {t("authorized.project.generateAndVersions.generateText1")}{" "}
                <br />
                {t("authorized.project.generateAndVersions.generateText2")}
                <br />
                {t("docs.loginScreen1")}
                <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.seeGuide')}</StyledALink>
              </StyledColumnHeaderText>
            ) : (
              ""
            )}
            {isLoading ? (
              <FlexContainer
                direction="row"
                justify="center"
                align="center"
                heightProps="50vh"
              >
                <Loader />
              </FlexContainer>
            ) : (
              ""
            )}
          </StyledRightColumnHeader>
          {imagesTotal && !isLoading ? (
            <GenerateAccordion
              allDatasetsImages={allDatasetsImages}
              projectInfo={projectInfo}
              navigate={navigate}
              generateLabels={generateLabels}
            />
          ) : (
            ""
          )}

          {!imagesTotal && !isLoading ? (
            <StyledEmptyDatasets>
              <StyledLoupeIcon />
              <h4>{t('authorized.project.datasets.noDatasets')}</h4>
              <FlexContainer direction='column' justify='center' align='center' gap='10px'>
                {t("docs.loginScreen1")}
                <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.seeGuide')}</StyledALink>
              </FlexContainer>
              <ProjectButton widthProps="150px" onClick={onAnnotateBtnClick}>
                {t('buttons.goToAnnotate')}
              </ProjectButton>
            </StyledEmptyDatasets>
          ) : (
            ""
          )}
        </StyledRightColumn>
      </StyledContentWrapper>
    </StyledProjects>
  );
};

export default ProjectGenerate;
