import React from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { StyledErrorText, StyledLink } from '../../modules/ProjectModule/constants/styled'
import ProjectButton from '../Buttons/ProjectButton/ProjectButton'
import { useNavigate } from 'react-router-dom'
import AutoMarkupSlideForm from '../../modules/Draw/components/AutoMarkupSlideForm/AutoMarkupSlideForm'
import { modalWindowSlice } from '../../store/reducers/modalWindowReducer'
import { SettingsReducer } from '../../modules/UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'

interface IQuotaLimitFormProps {
  text: string;
}

const QuotaLimitForm: React.FC<IQuotaLimitFormProps> = ({text}) => {
  const { t } = useTranslation()
  const { groupInfo } = useAppSelector(state => state.WorkspacesReducer)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
    navigate(`/settings/group/${groupInfo.id}`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onBackClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
    dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='models'/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='30px' paddingProps='10px 20px'>
      <StyledErrorText visible>{text}</StyledErrorText>
      <StyledLink onClick={onSettingsClick}>{t('authorized.project.projectManagement.goToGroupSetting')}</StyledLink>
      <FlexContainer direction='row' justify='center' align='center'>
        <ProjectButton onClick={onBackClick}>{t('buttons.back')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default QuotaLimitForm