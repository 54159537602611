import React, { FC, useEffect } from 'react'
import { NavigateFunction, Params } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux'
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import {
  StyledContentWrapper,
  StyledEmptyDatasets,
  StyledHeader,
  StyledLeftColumn,
  StyledLoupeIcon,
  StyledRightColumn,
  StyledVersionsColumn,
  StyledVersionsHeader
} from '../../../constants/styled'
import { StyledBr } from '../../AssignNav/AssignNav'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import VersionBtn from '../../../../../UI/VersionBtnComponent/VersionBtn'
import VersionInfo from './VersionInfo'
import { asyncGetVersionsSummary } from '../../../../../store/reducers/AcyncActions'
import { datasetsReducer } from '../../../store/reducers/DatasetsReducer'
import Loader from '../../../../../UI/Loader/Loader'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from '../../../../../models/Colors'
import { IProject } from '../../../../../services/ProjectService'
import { IShortVersionInfo } from '../../../../../services/VersionsService'

interface IProjectVersionsProps {
  navigate: NavigateFunction
  projectInfo: IProject
  param: Readonly<Params<string>>
}

const StyledProjects = styled.div`
  border-radius: 5px;
  background-color: ${Colors.dark};
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  margin-bottom: 15px;
  padding-bottom: 5px;
`

const ProjectVersions: FC<IProjectVersionsProps> = ({ navigate, projectInfo, param, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoading, versionsSummary } = useAppSelector((state) => state.versionsReducer)
  const currentVersion = useAppSelector((state) => state.versionsReducer.currentVersion)
  const isVersionLoading = useAppSelector((state) => state.ImagesListReducer.isLoading)

  console.log('currentVersion', currentVersion)
  useEffect(() => {
    if ((param.tab === 'versions' && param.id) || (param.versionId && param.id)) {
      dispatch(tabsSlice.actions.setActiveTab('versions'))
      dispatch(asyncGetVersionsSummary(param.id))
    }
    if (param.versionId) {
      navigate(`/projects/${param.id}/versions/version/${param.versionId}`)
    }
    if (param.id && !param.versionId) {
      dispatch(asyncGetVersionsSummary(param.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (versionsSummary && (!currentVersion?.id || !param.versionId)) {
      let firstVersionWithVersionInfo: IShortVersionInfo = {} as IShortVersionInfo
      versionsSummary.forEach(version => {
        if (version?.versionInfo) {
          firstVersionWithVersionInfo = version
          return
        } 
      })
      if (firstVersionWithVersionInfo?.versionInfo) {
        navigate(`/projects/${param.id}/versions/version/${firstVersionWithVersionInfo.id}`)
      }
      if (!firstVersionWithVersionInfo?.versionInfo) {
        navigate(`/projects/${projectInfo.id}/versions`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionsSummary, currentVersion?.id])

  const onAnnotateBtnClick = () => {
    navigate(`/projects/${projectInfo.id}/generate`)
  }

  const onGenerateClick = () => {
    dispatch(datasetsReducer.actions.setInitialState())
    navigate(`/projects/${projectInfo.id}/generate`)
  }

  return (
    <StyledProjects>
      <StyledHeader>
        {projectInfo.name ? (
          <h3>
            {projectInfo.name} {t('authorized.project.generateAndVersions.versions')}
          </h3>
        ) : (
          <h3>{t('authorized.project.generateAndVersions.versions')}</h3>
        )}
      </StyledHeader>
      <StyledBr />

      <StyledContentWrapper>
        <StyledLeftColumn>
          <ProjectButton widthProps="240px" heightProps="40px" paddingProps="10px" onClick={onGenerateClick}>
            {t('authorized.project.generateAndVersions.versionGeneration')}
          </ProjectButton>
          <StyledVersionsColumn>
            <StyledVersionsHeader>{t('authorized.project.generateAndVersions.versions')}</StyledVersionsHeader>
            {versionsSummary?.length && !isLoading
              ? versionsSummary.map((version) => (
                  <VersionBtn projectInfo={projectInfo} versionInfo={version} key={version.id + Date.now() + performance.now()} paramId={param.versionId ? param.versionId : ''} />
                ))
              : ''}
          </StyledVersionsColumn>
        </StyledLeftColumn>

        <StyledRightColumn>

          {versionsSummary?.length && !isLoading ? <VersionInfo projectInfo={projectInfo} navigate={navigate} currentVersion={currentVersion} params={param} /> : ''}

          {isLoading 
            ? <FlexContainer direction='row' justify='center' align='center' heightProps='300px'>
                <Loader/>
              </FlexContainer>
            : ''
          }

          {!versionsSummary?.length && !currentVersion?.id && !isLoading  ? (
            <StyledEmptyDatasets>
              <StyledLoupeIcon />
              <h4>{t('authorized.project.generateAndVersions.noVersions')}</h4>
              <ProjectButton widthProps="180px" onClick={onAnnotateBtnClick}>
                {t('authorized.project.nav.generate')}
              </ProjectButton>
            </StyledEmptyDatasets>
          ) : (
            ''
          )}
        </StyledRightColumn>
      </StyledContentWrapper>
      <StyledBr />
    </StyledProjects>
  )
}

export default ProjectVersions
