import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBlopImage {
  fileUrl: string;
  name: string;
  size: number;
  type: string;
  isAnchor: boolean;
}

interface IUploadValues {
  uploaded: number;
  total: number;
}

interface IReducerState {
  blopImages: IBlopImage[];
  isLoading: boolean;
  error:  string | null;
  counter: number;
  fileList: any;
  batchId: string;
  uploadedImages: any[]
  percents: string | number;
  uploaded: number;
  totalToUpload: number;
}

const initialState: IReducerState = {
  blopImages: [],
  isLoading: false,
  error: null,
  counter: 0,
  fileList: '',
  batchId: '',
  uploadedImages:[],
  percents: 0,
  uploaded: 0,
  totalToUpload: 0,
}

export const blopImagesReducer = createSlice(
{
    name: 'blopImages',
    initialState,

    reducers: {
      setInitialState(state) {
        state.blopImages = initialState.blopImages;
        state.isLoading = initialState.isLoading;
        state.error = initialState.error;
        state.counter = initialState.counter;
        state.fileList = initialState.fileList;
        state.batchId = initialState.batchId;
        state.uploadedImages = initialState.uploadedImages;
        state.percents = initialState.percents; 
        state.uploaded = initialState.uploaded;
        state.totalToUpload = initialState.uploaded;
      },
      addImagesToArray(state, action: PayloadAction<any>) {
        const findedImage = state.blopImages.find(image => image.name === action.payload.name)
        if (!findedImage) {
            state.blopImages.push(action.payload)
        }
      },
      setImages(state, action: PayloadAction<any>) {
        state.blopImages = [...state.blopImages, ...action.payload]
      },
      deleteImage(state, action: PayloadAction<any>) {
        state.blopImages = state.blopImages.filter(image => image.name !== action.payload)
      },
      setEmpty(state) {
        state.blopImages = []
      },
      counterIncrement(state) {
        state.counter += 1
      },
      setCounterInitialState(state) {
        state.counter = 0
      },
      setFileList(state, action: PayloadAction<any>) {
        state.fileList = action.payload
      },
      setBatchId(state, action: PayloadAction<string>) {
        state.batchId = action.payload
      },
      setIsLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload
      },
      setIsError(state, action: PayloadAction<string>) {
        state.isLoading = false
        state.error = action.payload
      },
      setUploadedImages(state, action: PayloadAction<any>) {
        state.uploadedImages.push(action.payload)
      },
      setPercents(state, action: PayloadAction<string | number>) {
        state.percents = action.payload
      },
      setUploadValues(state, action: PayloadAction<IUploadValues>) {
        state.uploaded = action.payload.uploaded
        state.totalToUpload = action.payload.total
      },
    }
},

)

export default blopImagesReducer.reducer;