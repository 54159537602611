import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { useAppSelector } from '../../../../hooks/redux'
import { Colors } from '../../../../models/Colors'
import { IInputProps } from '../../../../models/IInputProps'
import Input from '../../../../UI/Input/Input'
import { ICreateProjectFormProps } from '../../models/ICreateProjectFormProps'
import ProjectButton from './../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useAppDispatch } from './../../../../hooks/redux';
import { modalWindowSlice } from './../../../../store/reducers/modalWindowReducer';
import { StyledErrorText } from '../../constants/Styled'
import { StyledLink } from '../../../ProjectModule/constants/styled'
import { useNavigate } from 'react-router-dom'
import { SettingsReducer } from '../../../UserSettings/reducers/SettingsReducer'
import { WorkspacesSlice } from '../../store/reducers/WorkspacesReducer'
import ProjectService from '../../../../services/ProjectService'
import { drawTabsSlice } from '../../../Draw/store/DrawTabsReducer'
import { useTranslation } from 'react-i18next'
import Tooltip from '../../../../UI/Tooltip/Tooltip'

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 5px;
`
const StyledLabel = styled.div<IInputProps>`
  color: ${props => props.labelColor || Colors.lightgray};
  font-size: 15px;
  font-weight: 300;
`
const StyledSelect = styled.select`
  width: 100%;
  height: 35px;

  padding: 4px;
  background-color:${Colors.darker};
  border: 1px solid ${Colors.gray};
  border-color: ${Colors.gray};
  border-radius: 6px;
  color: ${Colors.textgray};

  display: flex;
  gap: 10px;

  &:focus { 
    outline: none;
    border: 1px solid ${Colors.orange};
   };
`
const CreateProjectForm: FC<ICreateProjectFormProps> = ({setVisible, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { groupInfo } = useAppSelector(state => state.WorkspacesReducer)
  const selectedLicense = useAppSelector(state => state.WorkspacesReducer.selectedLicense)
  const [error, setError] = useState('')
  const [formValue, setFormValue] = useState({
    name: '',
    objectType: 'Object detection',
    description: '',
    access: 'public'
  })

  const chengeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValue({...formValue, objectType: e.target.value})
  }

  const chengeTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValue({...formValue, access: e.target.value})
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({...formValue, name: e.target.value})
  }

  const onDetectingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({...formValue, description: e.target.value})
  }

  const createProjectAndLabels = async () => {
    try {
      if (formValue.name) {
        if (formValue.description) {
          dispatch(WorkspacesSlice.actions.setLoadingStart)
          const payload = {
            name: formValue.name,
            group: groupInfo.id,
            classNames: formValue.description,
            objectType: formValue.objectType,
            accessType: formValue.access
          }
          const createProjectResponce = await ProjectService.createProject(payload)
          const projectResponce = await ProjectService.getOneProjectById(createProjectResponce.data.project.id)
          dispatch(WorkspacesSlice.actions.ProjectsFetchingSuccess(projectResponce.data.project))
          dispatch(WorkspacesSlice.actions.setNewProject(projectResponce.data.project))
          setFormValue({...formValue, name: '', description: ''})
          dispatch(modalWindowSlice.actions.setInititialState())
        }
        if (!formValue.description) {
          dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.createProjectForm.errors.classes')))
          dispatch(modalWindowSlice.actions.setTitleColor(`${Colors.red}`))
          setTimeout(() => {
            dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.createProjectForm.title')))
            dispatch(modalWindowSlice.actions.setTitleColor(''))
          }, 2000);
        }
      }
      if (!formValue.name) {
        dispatch(modalWindowSlice.actions.setTitle('Error, try again'))
        dispatch(modalWindowSlice.actions.setTitleColor(`${Colors.red}`))
        setTimeout(() => {
          dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.createProjectForm.title')))
          dispatch(modalWindowSlice.actions.setTitleColor(''))
        }, 2000);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        setError('Превышена квота проектов.')
      }
      dispatch(drawTabsSlice.actions.setLoadingState(false))
      dispatch(drawTabsSlice.actions.setErrorState('Error on Create Label and project'))
      console.log('createProjectAndLabels', error)
    }
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
    navigate(`/settings/group/${groupInfo.id}`)
    setFormValue({...formValue, name: '', description: ''})
    dispatch(modalWindowSlice.actions.setInititialState())
    setError('')
    dispatch(WorkspacesSlice.actions.setErrorState(''))
  }

  const onCloseClick = () => {
    setFormValue({...formValue, name: '', description: ''})
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  return (
    <StyledFormWrapper>
        {error && <StyledErrorText visible>{error}</StyledErrorText>}
        {error && <StyledLink onClick={onSettingsClick}>{t('authorized.projects.groupProjects.settings.groupSettings')}</StyledLink>}
        <Input label={t('authorized.projects.createProjectForm.projectName')} placeholder={t('forum.enterText')} value={formValue.name} onChange={onNameChange}/>
        <FlexContainer direction='column' gap='5px'>
          <StyledLabel>{t('authorized.projects.createProjectForm.selectMarkupType')}:</StyledLabel>
          <StyledSelect value={formValue.objectType} onChange={chengeSelect}>
            <option>Object detection</option>
            <option>Segmentation</option>
            <option>Key points</option>
            {/* <option>Classification</option> */}
            <option>Other</option>
          </StyledSelect>     
        </FlexContainer>
        <FlexContainer direction='column' gap='5px'>
          <FlexContainer direction='row' justify='flex-start' align='center' gap='5px'> 
            <StyledLabel>{t('authorized.projects.createProjectForm.selectProjectType')}:</StyledLabel> 
            <Tooltip>{t('authorized.projects.createProjectForm.selectProjectTypeTP')}</Tooltip>
          </FlexContainer>
            <StyledSelect value={formValue.access} onChange={chengeTypeSelect}>
              <option value={'public'}>{t('authorized.projects.createProjectForm.public')}</option>
              <option value={'private'} disabled={selectedLicense?.cost === 0}>{t('authorized.projects.createProjectForm.private')}</option>
            </StyledSelect>     
        </FlexContainer>
        <Input 
          label={t('authorized.projects.createProjectForm.enterClasses')}
          placeholder='label, cat, eye, dog' 
          value={formValue.description} 
          onChange={onDetectingChange}
          withTooltip
          tooltipText={t('authorized.projects.createProjectForm.tooltipText')}
        />
        <FlexContainer direction='row' justify='space-around' align='center'>
          <ProjectButton widthProps='140px' margin='15px 0 0 0' onClick={onCloseClick}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton widthProps='140px' margin='15px 0 0 0' onClick={createProjectAndLabels}>{t('buttons.create')}</ProjectButton>
        </FlexContainer>
    </StyledFormWrapper>
  )
}

export default CreateProjectForm