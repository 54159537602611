import React, { FC } from 'react'
import { StyledBr, StyledHeaderRoute, StyledTopicsHeader, StyledTopicsHeaderRoutes, StyledTopicsHeaderSubTitle, StyledTopicsHeaderTitle } from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { NavigateFunction } from 'react-router-dom'
import { IGetCategoryByIdResponce } from '../../../../services/ForumService'
import { IUsersMeResponse } from '../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface ITopicsHeaderProps {
  user: IUsersMeResponse
  navigate: NavigateFunction
  currentCategory: IGetCategoryByIdResponce
  currentSubCategory: IGetCategoryByIdResponce
}

const TopicsHeader: FC<ITopicsHeaderProps> = ({ user, navigate, currentCategory, currentSubCategory, ...props }) => {
  const { t } = useTranslation()
  return (
    <StyledTopicsHeader>
      <StyledTopicsHeaderRoutes>
        <StyledHeaderRoute isLink={true} onClick={() => navigate('/forum')}>
          {t('forum.forum')}
        </StyledHeaderRoute>
        <span>{'/'}</span>
        <StyledHeaderRoute isLink={false} active>
          {currentSubCategory?.category?.name ? currentSubCategory?.category?.name.charAt(0).toUpperCase() + currentSubCategory?.category?.name.slice(1) : 'SubCategory'}
        </StyledHeaderRoute>
      </StyledTopicsHeaderRoutes>
      <StyledBr />
      <StyledTopicsHeaderTitle>
        {currentSubCategory?.category?.name ? currentSubCategory?.category?.name.charAt(0).toUpperCase() + currentSubCategory?.category?.name.slice(1) : 'SubCategory'}
      </StyledTopicsHeaderTitle>
      <StyledTopicsHeaderSubTitle>{currentSubCategory?.category?.description}</StyledTopicsHeaderSubTitle>
      <FlexContainer direction="row" justify="space-between" align="center" paddingProps="0 10px">
        {user?.id ? <ProjectButton onClick={() => navigate(`/forum/${currentCategory.category.id}/${currentSubCategory.category.id}/create`)}>+ {t('buttons.create')}</ProjectButton> : ''}
        {/* <ProjectButton>+ Subscribe</ProjectButton> */}
      </FlexContainer>
      <StyledBr />
    </StyledTopicsHeader>
  )
}

export default TopicsHeader
