import React, { FC } from 'react'
import { IPagesProps } from '../../../models/IPagesProps';
import AuthHeader from '../../../components/AuthHeader/AuthHeader';
import FlexContainer from '../../../components/FlexContainer/FlexContainer';
import { SignInForm } from './../../../modules/LoginForm/components/SignInForm';
import UserNav from '../../../components/UserNav/UserNav';

export const Auth: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
      <UserNav activeType='' navigate={navigate}></UserNav>
      <FlexContainer paddingProps='0' heightProps='85vh' justify='center' align='center' direction='column' gap='10px'>
        <SignInForm navigate={navigate}></SignInForm>
      </FlexContainer>
    </>
  )
}

