import React, { FC } from 'react'
import AuthHeader from '../../components/AuthHeader/AuthHeader'
import FlexContainer from '../../components/FlexContainer/FlexContainer'
import { IPagesProps } from '../../models/IPagesProps'
import { DrawMain } from '../../modules/Draw'


export const DrawPage: FC<IPagesProps> = ({navigate, ...props}) => {
  return (
    <>
        <DrawMain></DrawMain>
    </>
  )
}
