import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { datasetAnnots, IGetDatasetByIdResponce, IGetDatasetsSummaryResponce } from '../../../../services/DatasetsService';


export interface IAllAnnotatedDatasetsImages {
    dataset: string;
    images: datasetAnnots[];
}

interface IOpenDrawDataset {
    dataset: string;
    images: {
        filename: string;
    }[];
}

interface IReducerState {
    isEmpty: boolean;
    allAnnotatedDatasetsImages: IAllAnnotatedDatasetsImages[];
    total: number;
    openDrawDatasets: IOpenDrawDataset[];
    datasetsImagesCount: number;

    datasetsSummary: IGetDatasetsSummaryResponce;
    currentDataset: IGetDatasetByIdResponce,
    isLoading: boolean;
    error: string | null;

}

const initialState: IReducerState = {
    datasetsSummary: {} as IGetDatasetsSummaryResponce,
    currentDataset: {} as IGetDatasetByIdResponce,
    isLoading: false,
    error: '',

    total: 0,
    isEmpty: false,
    allAnnotatedDatasetsImages: [],
    openDrawDatasets: [],
    datasetsImagesCount: 0,
}

interface IRenamePayload {
    name: string;
    id: string;
    description: string;
}

export const datasetsReducer = createSlice(
{
    name: 'datasets',
    initialState,

    reducers: {
        setInitialState(state) {
            state.allAnnotatedDatasetsImages = initialState.allAnnotatedDatasetsImages
            state.currentDataset = initialState.currentDataset
            state.datasetsImagesCount = initialState.datasetsImagesCount
            state.datasetsSummary = initialState.datasetsSummary
            state.isEmpty = initialState.isEmpty
            state.openDrawDatasets = initialState.openDrawDatasets
            state.total = initialState.total
            state = initialState
        },
        
        setLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setErrorState(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },
        setToOpenDrawInitialState(state) {
            state.openDrawDatasets = initialState.openDrawDatasets
            state.total = 0
            state.allAnnotatedDatasetsImages = initialState.allAnnotatedDatasetsImages
        },

        renameDataset(state, action: PayloadAction<IRenamePayload>) {
            const findedTask = state.datasetsSummary.datasets.find(dataset => dataset.dataset === action.payload.id)
            if (findedTask) {
                findedTask.name = action.payload.name
            }
        },

        chengeDatasetDesctiption(state, action: PayloadAction<IRenamePayload>) {
            state.currentDataset.dataset.description = action.payload.description
        },


        renameCurrentDataset(state, action: PayloadAction<IRenamePayload>) {
            state.currentDataset.dataset.name = action.payload.name
            state.currentDataset.dataset.description = action.payload.description
        },

        deleteDataset(state, action: PayloadAction<string>) {
            state.currentDataset = initialState.currentDataset
            state.datasetsSummary.datasets = state.datasetsSummary.datasets.filter(dataset => dataset.dataset !== action.payload)
        },

        setDatasetsSummary(state, action: PayloadAction<IGetDatasetsSummaryResponce>) {
            state.datasetsSummary = action.payload
        },

        setCurrentDataset(state, action: PayloadAction<IGetDatasetByIdResponce>) {
            state.currentDataset = action.payload
        },

        setAllAnnotatedDatasetsImages(state, action: PayloadAction<IAllAnnotatedDatasetsImages>) {
            const findedDataset = state.allAnnotatedDatasetsImages.find(dataset => dataset.dataset === action.payload.dataset)
            if (findedDataset) {
                findedDataset.images.push(...action.payload.images)
            }
            if (!findedDataset) {
                state.allAnnotatedDatasetsImages.push(action.payload)
            }
        },

        setDatasetsImagesCount(state) {
            state.allAnnotatedDatasetsImages.forEach(dataset => {
                state.datasetsImagesCount += dataset.images.length
            })
        },

        setIsEmptyTrue(state) {
            state.isEmpty = true
        },

        setIsEmptyFalse(state) {
            state.isEmpty = false
        },

        incrementTotal(state, action: PayloadAction<number>) {
            state.total += action.payload
        },

        decrementTotal(state, action: PayloadAction<number>) {
            state.total -= action.payload
        },

        setImagesToOpenDrawDatasets(state, action:PayloadAction<IOpenDrawDataset>) {
            if (state.openDrawDatasets.length === 0) {
                state.openDrawDatasets = [action.payload]
            }
            const findedDataset = state.openDrawDatasets.find(dataset => dataset.dataset === action.payload.dataset)
            if (findedDataset) {
                const findedImage = findedDataset.images.find(image => image.filename === action.payload.images[0].filename)
                if (!findedImage) {
                    findedDataset.images.push(action.payload.images[0])
                }
                if (findedImage) {
                    return
                }
            }
            if (!findedDataset) {
                state.openDrawDatasets.push(action.payload)
            }
        },

        removeImageFromOpenDrawDatasets(state, action:PayloadAction<IOpenDrawDataset>) {
            const findedDataset = state.openDrawDatasets.find(dataset => dataset.dataset === action.payload.dataset)
            if (findedDataset) {
                if (findedDataset.images?.length === 1) {
                    state.openDrawDatasets = state.openDrawDatasets.filter(dataset => dataset.dataset !== action.payload.dataset)
                }
                if (findedDataset.images?.length >= 2) {
                    findedDataset.images = findedDataset.images.filter(image => image.filename !== action.payload.images[0].filename)
                }
            }
        },

    }
},

)

export default datasetsReducer.reducer;