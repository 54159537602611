import { useMemo } from "react";
import { IProject } from "../services/ProjectService";

export const UseProjectsQuery = (projects:IProject[], query:string) => {
  const searchedProjects = useMemo(() => {
      if (!Object.keys(projects).length) {
        return null
      }
      if (projects) {
        return projects.filter(project => project.name.toLowerCase().includes(query.toLowerCase()))
      }
  }, [query, projects])
  
  if (query) {
    return searchedProjects
  }
  if (!query) {
    return projects
  }
}