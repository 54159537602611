import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledBr, StyledRangeNumber, StyledSlideP } from '../../../../Styled/styled'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import AutoMarkupSlideForm from '../../AutoMarkupSlideForm'
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { Colors } from '../../../../../../models/Colors'
import AutoMarkupService, { IAutoMarkupTaskToCreate } from '../../../../../../services/AutoMarkupService'
import JobsService from '../../../../../../services/JobsService'
import { autoMarkupSlice } from '../../../../store/AutoMarkupSlice'
import { useNavigate } from 'react-router-dom'
import InputRadio from '../../../../../../UI/InputRadio/InputRadio'
import { StyledLink } from '../../../../../ProjectModule/constants/styled'
import { SettingsReducer } from '../../../../../UserSettings/reducers/SettingsReducer'
import QuotaLimitForm from '../../../../../../UI/QuotaLimitForm/QuotaLimitForm'
import { drawTabsSlice } from '../../../../store/DrawTabsReducer'
import { getMaxTrehold, getPrice, getTestImagesCount, getTitle, getTreholdDefault } from '../../../../Utils/AutoMarkupHelpers'
import { useTranslation } from 'react-i18next'
import { EImagesPage, imagesListSlice } from '../../../../../ImagesList/store/ImagesListReducer'
import { asyncGetListImages } from '../../../../../ImagesList/store/ImagesListAsyncActions'

interface ISelectTrasholdProps {
  type: string;
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 15px;
  width: 90%;

  .horizontal-slider {
    width: 100%;
    height: 48px;
    margin: auto;
    cursor: pointer;
  }

  /* .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: red;
    border: 8px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
    
  } */

  .example-thumb.active {
    border: 2px solid orange;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #ddd;
  }

  .example-track.example-track-1 {
    background:  #ddd;
  }

  .example-track.example-track-2 {
    background: #ddd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    /* top: 20px; */
    /* width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px; */
  }
`

const StyledThumb = styled.div`
  position: absolute;
  top: 10px;
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  border: 2px solid ${Colors.gray};
  background-color: #fff;
  color: ${Colors.gray};
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
`;

const StyledThumbValue = styled.div`
      position: absolute;
      top: 25px;
      left: -7px;
      
      text-align: center;
      min-width: 35px;
      height: 25px;
      color: #fff;

`

const Thumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
        {state.valueNow}
    </StyledThumbValue>
  </StyledThumb>
);

const PercentsThumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
        {state.valueNow + '%'}
    </StyledThumbValue>
  </StyledThumb>
);

const SelectTrashold: FC<ISelectTrasholdProps> = ({type, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {classesState} = useAppSelector(state => state.AutoMarkupReducer)
  const { groupInfo } = useAppSelector(state => state.WorkspacesReducer)
  const currentTask = useAppSelector(state => state.tasksReducer.currentTask)
  const { selectedModel, tags , testQuantity, testDemo} = useAppSelector(state => state.AutoMarkupReducer)
  const isCloseClick = useAppSelector(state => state.AutoMarkupReducer.automarkupCloseClick)
  const classesPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE')
  const textPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE_TEXT')  
  const segmentPrice = useAppSelector(state => state.SettingsReducer.settings)
    .find(settings => settings.key === 'COINS_FOR_IMAGE_SEGMENTATION')
  const quota = useAppSelector(state => state.SettingsReducer.groupQuotas)
    .find(quota => quota.name === 'automarkup_credits_monthly')
  const imagesToSegment = useAppSelector(state => state.AutoMarkupReducer.imagesToSegment)
  
  const [threshold, setThreshold] = useState(65)
  const [checked, setChecked] = useState(false);
  const [createChecked, setCreateChecked] = useState(true);

  const [isEnoughBalance, setIsEnoughBalance] = useState(false)

  const { selectedSplit, selectedImages, images } = useAppSelector(state => state.ImagesListReducer)

  useEffect(() => {
    if (quota) {
      const imagesCount = getTestImagesCount(getImagesSliderMax)
      const isEnoughBalance = (imagesCount * +getPrice(selectedModel, classesPrice, textPrice, textPrice)) > (quota.limit - quota.used)
      setIsEnoughBalance(isEnoughBalance)
      dispatch(autoMarkupSlice.actions.setAutomarkupCloseClick(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quota, testQuantity, selectedModel, classesPrice, textPrice])
  
  const onCancelClick = () => {
    if (selectedModel.type === 'segmentation') {
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(modalWindowSlice.actions.setModalWidth('580px'))
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
      dispatch(modalWindowSlice.actions.setVisible())
      return
    }
    if (selectedModel.type === 'text') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.textEntering')))
      dispatch(modalWindowSlice.actions.setModalWidth('80%'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='textModels'/>))
    }
    if (selectedModel.type === 'classes') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.classesSelection')))
      dispatch(modalWindowSlice.actions.setModalWidth('80%'))
      dispatch(modalWindowSlice.actions.setModalHeight('90%'))
      dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='models'/>))
    }
  }

  const deleteTask = async (job: string) => {
    try {
      const responce = await JobsService.deleteJob(job)
      if (responce.status === 404 || responce.status === 200) {
        return
      }
    } catch (error) {
      console.log('deleteTask', error)
    }
  }

  const getProgress = async (job : string) => {
    try {
        if (job) {
          const autoMarkupResponce = await AutoMarkupService.getAutoMarkupTaskById(job)
          console.log('autoMarkupResponce', autoMarkupResponce)

          if (autoMarkupResponce.status === 500) {
            dispatch(modalWindowSlice.actions.setChildren(
            <FlexContainer direction='column' justify='center' align='center' gap='30px'>
              <span>{t('errors.network')}</span>
              <ProjectButton onClick={() => dispatch(modalWindowSlice.actions.setInititialState())} widthProps='150px'>{t('buttons.close')}</ProjectButton>
            </FlexContainer>  
            ))
            deleteTask(job)
            throw new Error('No Responce.data :(')
          }

          if (autoMarkupResponce.status === 202){
            console.log(autoMarkupResponce?.data?.progress)
            dispatch(autoMarkupSlice.actions.setProgress(autoMarkupResponce?.data?.progress))
          }
  
          if (autoMarkupResponce.status === 200 && currentTask.task.project && currentTask.task.id) {
            console.log('DONE!')

            dispatch(autoMarkupSlice.actions.setProgress(0))
            dispatch(modalWindowSlice.actions.setInititialState())
            dispatch(autoMarkupSlice.actions.setInitialState())
            dispatch(imagesListSlice.actions.setInitialState())
            // if (selectedSplit?.value) {
            //   selectedSplit.value === 'all'
            //   ? dispatch(imagesListSlice.actions.setSelectedSplit({title: t('imagesList.automarkup'), value: 'automarkup' }))
            //   : dispatch(imagesListSlice.actions.setSelectedSplit({ title: t('imagesList.allTypes'), value: 'all' }))
            // }
            navigate(`/projects/${currentTask.task.project}/job/annotate/${currentTask.task.id}`)
            deleteTask(job)
          }

          if (isCloseClick) {
            return
          }

          if (autoMarkupResponce.status === 202) {
            if (isCloseClick) {
              return
            }
            if (job) {
                console.log('Retry!, isCloseClick', isCloseClick)
                setTimeout(() => {
                    getProgress(job)
                }, 500);
            }
          }
        }
    } catch (error: any) {
      console.log(error)
      if (error.response.status === 400) {
        dispatch(modalWindowSlice.actions.setModalWidth('500px'))
        dispatch(modalWindowSlice.actions.setModalHeight('auto'))
        dispatch(modalWindowSlice.actions.setChildren(<QuotaLimitForm text={t('errors.coinsQuotaError')}/>))
        return
      }
      dispatch(drawTabsSlice.actions.setErrorState('400'))
      dispatch(modalWindowSlice.actions.setTitle('Oops!'))
      dispatch(modalWindowSlice.actions.setChildren(error.responce.data.error))
    }
  }

  const subscribe = async (payload: IAutoMarkupTaskToCreate) => {
    try {
        const responce = await AutoMarkupService.createAutoMarkupTask(payload)
        console.log('subscribe', responce)
        getProgress(responce.data.job)

        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(modalWindowSlice.actions.setIsCloseability(false))
        dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.progressTitle')))
        dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='loading'/>))
        dispatch(modalWindowSlice.actions.setVisible())
    } catch (error: any) {
      console.log(error)
      if (error.response.status === 400) {
        dispatch(modalWindowSlice.actions.setModalWidth('500px'))
        dispatch(modalWindowSlice.actions.setModalHeight('auto'))
        dispatch(modalWindowSlice.actions.setChildren(<QuotaLimitForm text={t('errors.coinsQuotaError')}/>))
        return
      }
      dispatch(drawTabsSlice.actions.setErrorState('400'))
      dispatch(modalWindowSlice.actions.setTitle('Oops!'))
      dispatch(modalWindowSlice.actions.setChildren(error.responce.data.error))
    }
  }

  const onContinueClick = () => {    
    if (selectedModel && !isEnoughBalance && selectedModel.type === 'classes') {
      const classesToAutoMarkup = classesState.map((cl: any) => ({id: cl.id, name: cl.name}))
      const payload = {
        service: 'automarkup',
        taskId: currentTask.task.id,
        quantity: testQuantity,
        demo: testDemo,
        remarkup: checked,
        score_threshold: threshold/100,
        imagesTypeList: selectedSplit?.value === 'all' ? '' : selectedSplit?.value,
        createNewLabel: createChecked,
        models: [
          {
            id: selectedModel.id,
            name: selectedModel.name,
            classes: classesToAutoMarkup
          }
        ]
      }
      subscribe(payload)
      console.log(payload)
    }

    if (selectedModel && !isEnoughBalance && selectedModel.type === 'text') {
      const payload = {
        service: 'automarkupText',
        taskId: currentTask.task.id,
        quantity: testQuantity,
        demo: testDemo,
        remarkup: checked,
        score_threshold: threshold/100,
        createNewLabel: createChecked,
        imagesTypeList: selectedSplit?.value === 'all' ? '' : selectedSplit?.value,
        models: [
          {
            id: selectedModel.id,
            name: selectedModel.name,
            textPrompts: tags
          }
        ]
      }
      subscribe(payload)
      console.log(payload)
    }

    if (selectedModel && !isEnoughBalance && selectedModel.type === 'segmentation') {
      const payload = {
        service: 'segmentation',
        taskId: currentTask.task.id,
        quantity: testQuantity,
        demo: testDemo,
        remarkup: checked,
        score_threshold: threshold/100,
        imagesTypeList: selectedSplit?.value === 'all' ? '' : selectedSplit?.value,
        models: [
          {
            id: selectedModel.id,
            name: selectedModel.name,
            classes: []
          }
        ]
      }
      subscribe(payload)
      console.log(payload)
    }
  }

  const onThresholdChange = (value: number | readonly number[], index: number) => {
    console.log('value', value)
    if (typeof value === 'number') {
        setThreshold(value)
    }
  }

  const onSettingsClick = () => {
    dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
    navigate(`/settings/group/${groupInfo.id}`)
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const getImagesSliderMax = () => {
    if (type === 'segment') {
      return imagesToSegment?.length
    }
    if (selectedImages?.length) {
      return selectedImages?.length
    }
    if (!selectedImages?.length) {
      return images.length
    }
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='20px'>
      <StyledSlideP color={Colors.lightred}>
        {t('authorized.project.draw.testMarkupText')}
      </StyledSlideP>
      <StyledSlideP>
        {getTitle(selectedModel)}
      </StyledSlideP>
      <StyledSlideP>
        {t('authorized.project.draw.automarkupTool.availableCredits')}
        <StyledLink onClick={onSettingsClick}>{quota? ' ' + (quota.limit - quota.used) : ''}</StyledLink>
      </StyledSlideP>
      <FlexContainer  direction='column' justify='flex-start' align='center' gap='8px'>
        <StyledSlideP>
        {t('authorized.project.draw.automarkupTool.selectTrashold')}
        </StyledSlideP>
        <StyledWrapper>
          <StyledRangeNumber isCoincidence={threshold === 1}>1%</StyledRangeNumber>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            defaultValue={[getTreholdDefault(selectedModel.type)]}
            min={1}
            max={getMaxTrehold(selectedModel.type)}
            ariaValuetext={state => `${state.valueNow}`}
            renderThumb={PercentsThumb}
            minDistance={1}
            onChange={onThresholdChange}
          />
        <StyledRangeNumber isCoincidence={selectedModel.type === 'text' ? threshold === 30 : threshold === 99}>{selectedModel.type === 'text' ? 30 : 99}%</StyledRangeNumber>
        </StyledWrapper>
      </FlexContainer>

      {type !== 'segment'
        ?
          <FlexContainer direction='row' justify='center' align='center' widthProps='100%'>
            <InputRadio 
              type="checkbox"
              label={t('authorized.project.draw.createAutoClasses')}  
              checked={createChecked} 
              onClick={() => setCreateChecked(!createChecked)}
            />
          </FlexContainer>
        : ''
      }
      <FlexContainer direction='row' justify='center' align='center' widthProps='100%'>
        <InputRadio 
          type="checkbox"
          label={t('authorized.project.draw.automarkupTool.clearMarkup')}  
          checked={checked} 
          onClick={() => setChecked(!checked)}
        />
      </FlexContainer>
      <StyledBr/>
      <FlexContainer direction='row' justify='space-between' align='center' paddingProps='0 10px'>
        <StyledSlideP fSize='14px' align='left'>{t('authorized.project.draw.automarkupTool.images')}:</StyledSlideP>
        <span>{getTestImagesCount(getImagesSliderMax)}</span>
      </FlexContainer>
      <FlexContainer direction='row' justify='space-between' align='center' paddingProps='0 10px 20px 10px'>
        <StyledSlideP fSize='14px'>{t('authorized.settings.topUpForm.total')}:</StyledSlideP>
        <span>{t('authorized.project.draw.automarkupTool.cretit', {count: +getPrice(selectedModel, classesPrice, textPrice, segmentPrice) * getTestImagesCount(getImagesSliderMax)})}</span>
      </FlexContainer>
      <FlexContainer direction='row' justify='space-around'>
        <ProjectButton onClick={onCancelClick} FontSizeProps='16px'>{t('buttons.back')}</ProjectButton>
        <ProjectButton onClick={onContinueClick} disabled={isEnoughBalance} FontSizeProps='16px'>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
    
  )
}

export default SelectTrashold