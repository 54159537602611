import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUsersMeResponse } from '../../services/UserService'

interface IUserState {
  user: IUsersMeResponse
  isLoading: boolean
  error: string | null
  _isAuth: boolean
  percentComplete: number
}

const initialState: IUserState = {
  user: { firstName: 'Guest' } as IUsersMeResponse,
  isLoading: false,
  error: '',
  _isAuth: false,
  percentComplete: 0
}

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    userAuth(state) {
      state.isLoading = true
    },
    userAuthError(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = action.payload
    },
    userRegistrationSuccess(state) {
      state.isLoading = false
      state.error = ''
    },
    userLoginSuccess(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = ''
      state._isAuth = true
      state.user.email = action.payload
    },
    getUserInfoSuccess(state, action: PayloadAction<IUsersMeResponse>) {
      state.isLoading = false
      state.error = ''
      state._isAuth = true
      state.user = action.payload
    },
    userRefreshSuccess(state, action: PayloadAction<IUsersMeResponse>) {
      state.isLoading = false
      state.error = ''
      state._isAuth = true
      state.user = action.payload
    },
    setPercentComplete(state, action: PayloadAction<number>) {
      state.percentComplete = action.payload
    }
  }
})

export default userSlice.reducer
