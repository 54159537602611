import React, { FC, ReactChild, ReactNode, useEffect, useRef, useState } from 'react'
import { StyledBr } from '../../../../Projects/constants/Styled'
import { IProjectUploadTab } from '../../../models/IProjectUploadTab'
import uploadImage from '../../../../../assets/icons/cloud-upload-regular-240.png'
import { useAppDispatch } from './../../../../../hooks/redux';
import { blopImagesReducer } from './../../../store/reducers/BlopImagesReducer';
import { tabsSlice } from '../../../store/reducers/TabsReducer'
import { checkType, getFileSizeMB } from './utils';
import { modalWindowSlice } from './../../../../../store/reducers/modalWindowReducer';
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from './../../../../../UI/Buttons/ProjectButton/ProjectButton';
import UploadImagesForm from './UploadImagesForm';
import { 
  StyledDropFileDiv, StyledDropFileInput, StyledErrorText, StyledHeader,
  StyledImagesWrapper, StyledInputLabel, StyledProjects, StyledRedErrorText, StyledTypesText, Wrapper 
} from '../../../constants/styled'
import DroppedFile from './DroppedFile'
import { useTranslation } from 'react-i18next';

const DropFileInput: FC<IProjectUploadTab> = ({projectInfo, navigate, param, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const wrapperRef: React.MutableRefObject<any> = useRef();  
  const inputRef: React.MutableRefObject<any> = useRef();  
  const [uploadedFiles, setUploadedFiles]:any = useState([])
  // console.log('uploadedFiles', uploadedFiles)
  const [anchorCounter, setAnchorCounter] = useState(1)

  const allFileTypes = ["JPG", "PNG", 'JPEG', 'TXT', 'JSON', 'XML'];
  const markupFilesTypes = ['TXT', 'JSON', 'XML'];
  const imagesTypes = ["JPG", "PNG", 'JPEG',]
  const maxImageSize = 10;
  const maxMarkupFileSize = 50;

  useEffect(() => {
    if (param === 'upload') {
      dispatch(tabsSlice.actions.setActiveTab('upload'))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showModal = (children: ReactNode | ReactChild | JSX.Element) => {
      console.log('children', children)
      dispatch(modalWindowSlice.actions.setInititialState())
      dispatch(modalWindowSlice.actions.setTitle(t('errors.error')))
      dispatch(modalWindowSlice.actions.setChildren(children))
      dispatch(modalWindowSlice.actions.setVisible())
      setTimeout(() => {
        dispatch(modalWindowSlice.actions.setInititialState())
        dispatch(blopImagesReducer.actions.setCounterInitialState())
      }, 3000);
  }

  const validateFileSize = (file: File) => {
    if (getFileSizeMB(file.size) > maxImageSize && checkType(file, imagesTypes)) {
      return false;
    }
    if (getFileSizeMB(file.size) > maxMarkupFileSize && checkType(file, markupFilesTypes)) {
      return false;
    }
    //minSize
    // if (getFileSizeMB(file.size) < sizesInMegabytes.minSize) {
    //   return false;
    // } 
    return true;
  };

  const validateFilesType = (file: File) => {
    if (!checkType(file, allFileTypes)) {
      return false;
    }
    return true;
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.add('dragover')
  }

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.remove('dragover')
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    wrapperRef.current.classList.remove('dragover')
  }

  const handleFileEvent =  async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploaded:any = [...uploadedFiles];
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        const isValidType = await validateFilesType(event.target.files[i])
        const isValidSize = await validateFileSize(event.target.files[i])
        if (isValidType && isValidSize) {
          uploaded.push(event.target.files[i])
        }
        if (!isValidType) {
          const children = 
            <FlexContainer direction='column' justify='center' align='center' gap='10px' paddingProps='0 0 40px 0'>
              <StyledErrorText visible={true}>{t('authorized.project.upload.errorFileType')}</StyledErrorText>
              <StyledErrorText visible={true}>
                <StyledRedErrorText>
                  .{event.target.files[i].name.split('.').pop()} 
                </StyledRedErrorText>
                  
              </StyledErrorText>
              <StyledErrorText visible={true}>{t('authorized.project.upload.support')} .JPG .PNG .JPEG .XML .TXT .JSON</StyledErrorText>
            </FlexContainer>
          showModal(children)
        }
        if (!isValidSize) {
          showModal(
            <FlexContainer direction='column' justify='center' align='center' gap='15px' >
                <StyledRedErrorText>
                  {event.target.files[i].name} 
                </StyledRedErrorText>
                <StyledErrorText visible={true}>
                  {t('errors.error')}
                </StyledErrorText>
              <StyledErrorText visible={true}>{t('authorized.project.upload.maxSize')}</StyledErrorText>
              <StyledErrorText visible={true}>{t('authorized.project.upload.maxMarkupSize')}</StyledErrorText>
            </FlexContainer>
          )
        }
      }
    }
    setUploadedFiles(uploaded)
  }

  const deleteImgClickHandler = (file: any) => {
    const fileList:any = [...uploadedFiles]
    const splicedList = fileList.filter((files: File) => files.name !== file.name)
    setUploadedFiles(splicedList)
    inputRef.current.value = ""
  }

  const onUploadClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.upload.upload')))
    dispatch(modalWindowSlice.actions.setChildren(<UploadImagesForm navigate={navigate} images={uploadedFiles} projectInfo={projectInfo}></UploadImagesForm>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
      <StyledProjects>
        <StyledHeader>
            {projectInfo.name 
              ? <h3>{projectInfo.name} {t('authorized.project.upload.upload') + ' ' + (uploadedFiles?.length === 0 ? '' 
              : '(' + uploadedFiles?.length + ')')}</h3> : <h3>{t('authorized.project.upload.upload')}</h3>
            }  
            {uploadedFiles.length? <ProjectButton onClick={onUploadClick}>{t('buttons.upload')}</ProjectButton> : ''}
        </StyledHeader>
        <StyledBr/>
          <Wrapper>
              <StyledDropFileDiv
                ref={wrapperRef}
                onDragEnter={(e: React.DragEvent<HTMLDivElement>) => onDragEnter(e)}
                onDragLeave={(e: React.DragEvent<HTMLDivElement>) => onDragLeave(e)}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => onDrop(e)}
              >
                
                <StyledInputLabel>
                  <img src={uploadImage} alt="Upload" />
                  <h3>{t('authorized.project.upload.drag1')}</h3>
                  <h4>{t('authorized.project.upload.drag2')}</h4>
                  
                  <StyledTypesText>{t('authorized.project.upload.support')} .JPG .PNG .JPEG</StyledTypesText>
                  <StyledTypesText>.XML .TXT .JSON</StyledTypesText>
                  <StyledTypesText>{t('authorized.project.upload.maxSize')}</StyledTypesText>
                </StyledInputLabel>
                
                <StyledDropFileInput ref={inputRef} type="file" multiple={true} onChange={handleFileEvent} accept="image/png, image/jpeg, image/jpg"/>
              </StyledDropFileDiv>

              {/* <input ref={inputRef} type="file" directory="" webkitdirectory="" multiple={true} onChange={handleFileEvent} accept="image/png, image/jpeg, image/jpg"/> */}

              <StyledImagesWrapper>
                  {uploadedFiles?.map((image: any, index: any) => 
                    index > anchorCounter * 200 ? '' :
                    <DroppedFile image={image} key={index + image.name + performance.now()} deleteImgClickHandler={deleteImgClickHandler} index={index} showIndex={200} anchorCounter={anchorCounter} setAnchorCounter={setAnchorCounter}/>
                  )} 
              </StyledImagesWrapper>
          </Wrapper>
      </StyledProjects>
  )
}

export default DropFileInput