import React, { FC, useState } from 'react'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { asyncPutGroupNameById } from '../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer';
import Input from '../../Input/Input'
import ProjectButton from '../ProjectButton/ProjectButton'
import { useAppDispatch } from './../../../hooks/redux';
import { IFullGroup } from '../../../services/GroupService';
import { useTranslation } from 'react-i18next';

interface IRenameForm {
  group: IFullGroup;
}

const RenameForm: FC<IRenameForm> = ({group}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [groupName, setGroupName] = useState('')
  
  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onButtonRenameClick = () => {
    if (group?.groupLicense?.length) {
      dispatch(asyncPutGroupNameById(group.id, groupName, group?.groupLicense[0]?.license?.id))
      setModalVisivble()
      dispatch(modalWindowSlice.actions.setInititialState())
    }
  } 
  
  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value)
  }

  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.projects.groups.createForm.inputTitle')} placeholder={t('authorized.projects.groups.createForm.inputPlaceholder')} value={groupName} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onButtonRenameClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default RenameForm