import React from 'react'
import { ISettingsNavButtonProps } from '../../constants/interfaces'
import { StyledSettingsNavButton } from '../../constants/styled'

const SettingsNavButton: React.FC<ISettingsNavButtonProps> = ({text, activeTab, id, ...props}) => {
  return (
    <StyledSettingsNavButton {...props} active={activeTab === id}>{text}</StyledSettingsNavButton>
  )
}

export default SettingsNavButton