import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFullGroup, IGroup, ISelectedLicense, IShortGroup } from '../../../../services/GroupService';
import { IPermissionSummary } from '../../../../services/AccessService';
import { IProject } from '../../../../services/ProjectService';

export interface IGroupUserFullInfo {
  groupRole: {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    roleGroupId: string;
    permissions: IPermissionSummary[]
  }

  user: {
    id: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    type: number;
    isActive: boolean;
    updatedAt: string;
    createdAt: string;
  }
}

export interface IProjectUserFullInfo {
  projectRole: {
    id: string;
    name: string;
    displayName: string;
    createdAt: string;
    updatedAt: string;
    permissions: IPermissionSummary[]
  }
  
  user: {
    id: string;
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    type: number;
    isActive: boolean;
    updatedAt: string;
    createdAt: string;
  }
}

interface IWorkspacesState {
    shortGroupsInfo: IShortGroup[];
    fullGroupsInfo: IFullGroup[];
    projects: IProject[];
    tabContent: IProject[];
    isLoading: boolean;
    error: string | null;
    projectInfo: IProject;
    groupInfo: IFullGroup;
    isGroupClicked: boolean;
    groupUsersInfo: IGroupUserFullInfo[];
    projectUsersInfo: IProjectUserFullInfo[];
    selectedLicense: ISelectedLicense;
    isRoleLoading: boolean;
}

const initialState: IWorkspacesState = {
    shortGroupsInfo: [],

    fullGroupsInfo: [],

    projects: [],

    tabContent: [],

    isLoading: false,

    selectedLicense: {} as ISelectedLicense,

    isRoleLoading: false,

    error: '',

    projectInfo: {} as IProject,

    groupInfo: {
      id: '',
      name: '',
      user: '',
      createdAt: '',
      UpdatedAt: '',
      groupMembers: [],
      groupLicense: [],
    },

    isGroupClicked: false,

    groupUsersInfo: [],
    projectUsersInfo: [],
}

export const WorkspacesSlice = createSlice(
{
    name: 'workspaces',
    initialState,

    reducers: {
      setErrorState(state, action: PayloadAction<string>) {
        state.isLoading = false
        state.error = action.payload
      },
      setLoadingStart(state) {
        state.isLoading = true
      },
      setLoadingEnd(state) {
        state.error = '';
        state.isLoading = false
      },

      setIsRoleLoading(state, action: PayloadAction<boolean>) {
        state.isRoleLoading = action.payload;
      },

      //Short   
      ShortGroupsInfoFetchingSuccess(state, action: PayloadAction<IShortGroup[]>) {
        state.isLoading = false;
        state.error = '';
        state.shortGroupsInfo = action.payload;
      },
      ShortGroupsInfoFetchingError(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      //Full
      FullGroupsInfoFetchingSuccess(state, action: PayloadAction<IFullGroup>) {
        state.isLoading = false;
        state.error = '';
        state.fullGroupsInfo.push(action.payload);
      },
      FullGroupsInfoFetchingError(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      setGroupInfo(state, action: PayloadAction<IFullGroup>) {
        state.groupInfo = action.payload
        state.isLoading = false
      },

      setGroupInitialState(state) {
        state.groupInfo = initialState.groupInfo;
        state.groupUsersInfo = initialState.groupUsersInfo;
      },

      changeGroupName(state, action: PayloadAction<IGroup>) {
        const group = state.shortGroupsInfo.find(group => group.id === action.payload.id)
        if (group) {
          group.name = action.payload.name
          state.groupInfo.name = action.payload.name
        }
        
        state.isLoading = false
        state.error = '';
      },
      deleteGroup(state, action: PayloadAction<string>) {
        state.fullGroupsInfo = state.fullGroupsInfo.filter(group => group.id !== action.payload)
        state.shortGroupsInfo = state.shortGroupsInfo.filter(group => group.id !== action.payload)
        state.isLoading = false
        state.error = '';
        state.groupInfo = initialState.groupInfo;
        state.groupUsersInfo = initialState.groupUsersInfo;
      },
      
      //Projects
      ProjectsFetching(state) {
        state.isLoading = true;
      },
      ProjectsFetchingSuccess(state, action: PayloadAction<IProject>) {
        state.isLoading = false;
        state.error = '';
        state.projects.push(action.payload);
      },
      AllProjectsFetchingSuccess(state, action: PayloadAction<IProject[]>) {
        state.isLoading = false;
        state.error = '';
        state.projects = action.payload;
      },
      ProjectsFetchingError(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      setProjectInfo(state, action: PayloadAction<IProject>) {
        state.projectInfo = action.payload
        state.isLoading = false
      },
      renameProjectName(state, action: PayloadAction<IProject>) {
        const tabProject = state.tabContent.find(project => project.id === action.payload.id)
        if (tabProject) {
          tabProject.name = action.payload.name
        }
        const project = state.projects.find(project => project.id === action.payload.id)
        if (project) {
          project.name = action.payload.name
        }
        state.isLoading = false
        state.error = '';
      },
      deleteProject(state, action: PayloadAction<string>) {
        state.projects = state.projects.filter(project => project.id !== action.payload)
        state.tabContent = state.tabContent.filter(project => project.id !== action.payload)
        state.isLoading = false
        state.error = '';
      },

      //Tabs
      setActiveTabContent(state, action: PayloadAction<IProject[]>) {
        state.tabContent = action.payload
      },
      setNewProject(state, action: PayloadAction<IProject>) {
        state.tabContent.push(action.payload)
      },
      setGroupClicked(state, action: PayloadAction<boolean>) {
        state.isGroupClicked = action.payload
      },

      setFullGroupUserInfo(state, action: PayloadAction<IGroupUserFullInfo[]>) {
        state.groupUsersInfo = action.payload
      },

      setFullProjectUsersInfo(state, action: PayloadAction<IProjectUserFullInfo[]>) {
        state.projectUsersInfo = action.payload
      },

      setSelectedLicense(state, action: PayloadAction<ISelectedLicense>) {
        state.selectedLicense = action.payload
      }
    }
},

)

export default WorkspacesSlice.reducer;