import styled, { createGlobalStyle } from 'styled-components'
import { Colors } from '../models/Colors'

const Global = createGlobalStyle`
body {
  overflow-x: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  margin: 0;
  font-size: 18px;
  line-height: 130%;
  color: #ffffff;
  font-weight: 400;
  background: ${Colors.black};

  overflow: auto;
  ::-webkit-scrollbar {
    width: 15px; /* ширина скролла */
    background-color: ${Colors.dark}
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${Colors.btngray}; /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */
    border: 4px solid ${Colors.dark}; /* отступ вокруг бегунка */
  }
}
`

interface IStyledBrProps {
  margin?: string;
  color?: string;
  padding?: string;
  width?: string;
}

export const ProjectBr = styled.div<IStyledBrProps>`
    width: ${({width}) => width || '100%'};
    height: 2px;
    background-color: ${({color}) => color || Colors.black};
    margin: ${props => props.margin ? props.margin : 0};
    padding: ${({padding}) => padding || 0};
`

export const ProjectLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${Colors.dirtyorange};

  :visited {
    color: ${Colors.dirtyorange};
  }

  :hover{
    color: ${Colors.orange};
  }
  
  :visited:hover {
    color: ${Colors.orange};
  }
`

export const ProjectGrayLink = styled.a`
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
  color: ${Colors.lightgray};

  transition: all .1s linear;

  :visited {
    color: ${Colors.lightgray};
  }
  
  :hover{
    color: ${Colors.texttable};
  }

  :visited:hover {
    color: ${Colors.lightgray};
  }
`

export const StyledProjectTableWrapper = styled.div`
  width: 100%;
  height: auto;
  border: 1px solid #393939;
  padding: 0 15px 15px 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

interface IStyledProjectTableHeaderProps {
  pages?: number[]
}

export const StyledSupTitle = styled.p`
  color : '#fff';
  font-size: 16px;
`

export const StyledALink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: ${Colors.dirtyorange};
    :hover{
      color: ${Colors.orange};
      text-decoration: underline;
      text-decoration-color: ${Colors.orange};
    }
    :visited {
      text-decoration: none;
      color: ${Colors.dirtyorange};
    }
`

export const StyledProjectTableHeader = styled.div<IStyledProjectTableHeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  // eslint-disable-next-line no-useless-concat
  padding: ${props => props.pages && props.pages.length - 1 ? '10px' : '10px 0'};
`

export const StyledProjectTableItem = styled.div`
  font-weight: bold;
  font-size: 14px;

  min-width: 120px;
  max-width: 235px;
  text-align: center;
`

export const StyledTableItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 55px;
`

interface IStyledPageNumberProps {
  active: boolean;
}

export const StyledProjectPageNumber = styled.div<IStyledPageNumberProps>`
  width: 28px;
  height: 25px;
  text-align: center;
  border: 1px solid ${props => props.active ? Colors.orange : Colors.gray};
  border-radius: 5px;
  font-size: 14px;
  color: ${props => props.active? Colors.orange : Colors.gray};
  cursor: pointer;
  margin-top: 5px;

  transition: all .1s linear;
  :hover {
    color: ${Colors.lightorange};
    border-color: ${Colors.lightorange};
  }
`

interface ICenteredTextProps {
  fsize?: string;
  color?: string;
}

export const CenteredText = styled.div<ICenteredTextProps>`
  text-align: center;
  font-size: ${({fsize}) => fsize? fsize : "16px"};
  color: ${({color}) => color ? color : ''}
`

export default Global

