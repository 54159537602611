import React, { FC } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../models/Colors'
import { IDropdownButtonProps } from '../../../models/IDropdownButtonProps'

const StyledButton = styled.div<IDropdownButtonProps>`
  display:flex;
  align-items: center;
  justify-content:center;
  position: relative;
  font-size: 12px;
  opacity: ${({disabled}) => disabled ? .3 : 1};
  width: ${props => props.widthProps || '100%'} ;
  padding:${props => props.paddingProps || '0px'};
  height:${props => props.heightProps || '50px'};
  border-right: 3px solid transparent;
  border-right: ${({ active }) => active ? `3px solid ${Colors.orange}` : ''};
  border-left: 3px solid transparent;
  border-left: ${({ active }) => active ? `3px solid ${Colors.orange}` : ''};
  background: ${({ active }) => active ? Colors.black : Colors.dark};
  font-size: ${props => props.FontSizeProps || '15px'};
  font-weight: 500;
  color: ${props => props.colorProps || Colors.orange};
  text-align: center;
  transition: all 0.1s linear;
  cursor: pointer;
  &:hover {
    background: ${Colors.black};
    border-right: 3px solid ${Colors.orange};
    border-left: 3px solid ${Colors.orange};
  }
`

const DropdownButton: FC<IDropdownButtonProps> = ({children, disabled, ...props}) => {
  return (
    <StyledButton {...props} disabled={disabled}>
      {children}
    </StyledButton>
  )
}

export default DropdownButton