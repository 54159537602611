import React, { FC } from 'react'
import { IUserResponce } from '../../../../../models/IUserResponce'
import { StyledTopicUser, StyledTopicUserEmail, StyledTopicUserIcon, StyledTopicUserName } from '../../../constants/styled'
import { IGetTopicByIdResponce } from '../../../../../services/ForumService'
import { IMessageUser } from '../../../../../models/IMessageUser'

interface ITopicPageUserProps {
  user: IUserResponce | IMessageUser
  currentTopic: IGetTopicByIdResponce | undefined | null
}

const TopicPageUser: FC<ITopicPageUserProps> = ({ user, currentTopic }) => {
  return (
    <StyledTopicUser>
      <StyledTopicUserIcon />
      <StyledTopicUserName>{currentTopic ? currentTopic?.topic?.author?.firstName : user?.firstName}</StyledTopicUserName>
      <StyledTopicUserEmail>{currentTopic ? currentTopic?.topic?.author?.email : user?.email}</StyledTopicUserEmail>
    </StyledTopicUser>
  )
}

export default TopicPageUser
