import React, { useState } from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer'
import { StyledQuotaPrice, StyledQuotaPriceDescription, StyledQuotaTotal, StyledRangeNumber, StyledRowWrapper, StyledSlideP, StyledThumb, StyledThumbValue } from '../../constants/styled'
import ReactSlider from 'react-slider'
import { IEditQuotaProps } from '../../constants/interfaces'
import { StyledErrorText } from '../../../Projects/constants/Styled'
import { asyncExpandQuota } from '../../../../store/reducers/AcyncActions'
import { ProjectBr } from '../../../../styles/GlobalStyles'
import Loader from '../../../../UI/Loader/Loader'
import SelectQuotaForm from './SelectQuotaForm'
import { useTranslation } from 'react-i18next'

const Thumb = (props: any, state: any) => (
  <StyledThumb {...props}>
    <StyledThumbValue>
        {state.valueNow}
    </StyledThumbValue>
  </StyledThumb>
)

const EditQuotaForm: React.FC<IEditQuotaProps> = ({quota, groupQuotas}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const group = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const selectedLicense = useAppSelector(state => state.WorkspacesReducer.selectedLicense)
  const { error, isExpandLoading } = useAppSelector(state => state.SettingsReducer)
  const [sliderValue, setSliderValue] = React.useState(+quota.quotaMultiplicity)
  const [limit, setLimit] = useState(false)

  // Установка лимита, может пригодится?
  // useEffect(() => {
  //   if ((quota?.maxLimit - quota?.limit) <= 0) {
  //     setLimit(true)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  
  const onReactSliderChange = (value: number | readonly number[], index: number) => {
    console.log('value', value)
    if (typeof value === 'number') {
      setSliderValue(value)
    }
  }

  const onCloseClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.settings.quotasFormTitle')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setModalWidth('80vw'))
    dispatch(modalWindowSlice.actions.setActiveScrollTrue())
    dispatch(modalWindowSlice.actions.setTooltipText(t('authorized.settings.quotasFormTooltip')))
    dispatch(modalWindowSlice.actions.setChildren(<SelectQuotaForm groupQuotas={groupQuotas}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onEditClick = () => {
    dispatch(asyncExpandQuota({
        groupId: group.id,
        licenseId: selectedLicense.id,
        quotaTypeId: quota.id,
        additionalUnits: sliderValue
    }))
  }

  return (
    <>
      {error && !limit && <StyledErrorText visible>{error}</StyledErrorText>}
      {isExpandLoading && !limit &&
        <FlexContainer direction='row' justify='center' align='center' heightProps='100%' widthProps='100%'>
          <Loader/>
        </FlexContainer>
      }
      {!isExpandLoading && !limit &&
        <FlexContainer direction='column' justify='flex-start' align='center'>
          <FlexContainer direction='column' justify='center' align='center' paddingProps='0 20px' margin='0 0 30px 0'>
            <StyledQuotaPriceDescription>{t('authorized.settings.baseCost')}</StyledQuotaPriceDescription>
            <StyledQuotaPriceDescription>{t('authorized.settings.extendText')}</StyledQuotaPriceDescription>
          </FlexContainer>
          <StyledSlideP>{t('authorized.settings.extendCounter')}</StyledSlideP>
          <StyledRowWrapper>
            <StyledRangeNumber isCoincidence={sliderValue === +quota.quotaMultiplicity}>{+quota.quotaMultiplicity}</StyledRangeNumber>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[+quota.quotaMultiplicity]}
              min={+quota.quotaMultiplicity}
              max={quota?.limit * 5}
              ariaValuetext={state => `${state.valueNow + quota?.limit}`}
              renderThumb={Thumb}
              step={+quota.quotaMultiplicity}
              onChange={onReactSliderChange}
            />
            <StyledRangeNumber isCoincidence={sliderValue === +quota?.limit * 5}>{+quota?.limit * 5}</StyledRangeNumber>
          </StyledRowWrapper>  
        
            <ProjectBr/>
            <FlexContainer direction='row' justify='space-between' align='center' paddingProps='10px 20px'>
              <FlexContainer direction='row' justify='flex-start' align='flex-start' gap='5px'>
                <StyledQuotaTotal>{t('authorized.settings.totalPrice')}</StyledQuotaTotal>
              </FlexContainer>
              <StyledQuotaPrice>{Math.ceil((sliderValue) * quota.fullCostPerUnit)  + ' RV'}</StyledQuotaPrice>
            </FlexContainer>  
          <FlexContainer direction='row' justify='space-around' paddingProps='30px 0 0 0'>
            <ProjectButton onClick={onCloseClick} widthProps='140px'>{t('buttons.back')}</ProjectButton>
            <ProjectButton onClick={onEditClick} widthProps='140px'>{t('authorized.settings.groupIncrease')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
      {limit &&
        <FlexContainer direction='column' justify='flex-start' align='center'>
          <StyledSlideP>{t('authorized.settings.maxLimit')}</StyledSlideP>
          <FlexContainer direction='row' justify='space-around' paddingProps='30px 0 0 0'>
            <ProjectButton onClick={onCloseClick} widthProps='140px'>{t('buttons.back')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
    </>
  )
}

export default EditQuotaForm