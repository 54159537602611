import React, { FC, useEffect, useState } from 'react'
import { StyledPreprocessingButton, StyledPreprocessingTab } from '../../../../constants/styled'
import TabOption from './TabOption'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import GenerateOptionsForm from './GenerateOptionsForm'
import { IGenerateLabel } from '../../../../../../services/VersionsService'
import RenameTabOption from './RenameTabOption'
import { useTranslation } from 'react-i18next'

interface IPreprocessingTabProps {
  type: string
}

const PreprocessingTab: FC<IPreprocessingTabProps> = ({ type, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { resizeOption, tileOption, autoContrastOption, simpleOptions, showButton, showAugmentationButton, brightness, blurOption, noiseOption, grayscaleOption } = useAppSelector(
    (state) => state.generateReducer
  )
  const labels = useAppSelector((state) => state.generateReducer.generateDatasetLabels)
  const [changedLabels, setChangedLabels] = useState<IGenerateLabel[]>([])

  useEffect(() => {
    if (labels?.length) {
      console.log('labels', labels)
      const filteredLabels = labels.filter((label) => label.oldName !== label.newName)
      console.log('filteredLabels', filteredLabels)
      setChangedLabels(filteredLabels)
    }
  }, [labels])

  const onAddClick = () => {
    if (type === 'preprocessing') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateTitle3')))
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(
        modalWindowSlice.actions.setChildren(
          <GenerateOptionsForm
            type={type}
            tileOption={tileOption}
            resizeOption={resizeOption}
            simpleOptions={simpleOptions}
            autoContrastOption={autoContrastOption}
            blurOption={blurOption}
            brightness={brightness}
            noiseOption={noiseOption}
            grayscaleOption={grayscaleOption}
          />
        )
      )
      dispatch(modalWindowSlice.actions.setVisible())
    }
    if (type === 'augmentation') {
      dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.generateTitle4')))
      dispatch(modalWindowSlice.actions.setIsCloseability(false))
      dispatch(
        modalWindowSlice.actions.setChildren(
          <GenerateOptionsForm
            type={type}
            tileOption={tileOption}
            resizeOption={resizeOption}
            simpleOptions={simpleOptions}
            autoContrastOption={autoContrastOption}
            blurOption={blurOption}
            brightness={brightness}
            noiseOption={noiseOption}
            grayscaleOption={grayscaleOption}
          />
        )
      )
      dispatch(modalWindowSlice.actions.setVisible())
    }
  }

  return (
    <StyledPreprocessingTab>
      {type === 'preprocessing' ? (
        <>
          {showButton || !changedLabels?.length ? (
            <StyledPreprocessingButton onClick={onAddClick}>{t('authorized.project.generateAndVersions.addPrepr')}</StyledPreprocessingButton>
          ) : (
            ''
          )}
          {changedLabels?.length ? <RenameTabOption labels={changedLabels} selected={true} /> : ''}
          {resizeOption.selected && (
            <TabOption
              displayName={t('authorized.project.generateAndVersions.formOptions.resizeOption')}
              globalType={type}
              type={resizeOption.method}
              info={resizeOption.params.width + ' x ' + resizeOption.params.height}
              reducerInfo={resizeOption.params}
              selected={resizeOption.selected}
            />
          )}
          {tileOption.selected && (
            <TabOption
              displayName={t('authorized.project.generateAndVersions.formOptions.tileOption')}
              globalType={type}
              type={tileOption.method}
              info={'Columns ' + tileOption?.params?.columns + ' Rows ' + tileOption?.params?.rows}
              reducerInfo={tileOption.params}
              selected={tileOption.selected}
            />
          )}
          {autoContrastOption.selected && (
            <TabOption  displayName={t('authorized.project.generateAndVersions.formOptions.autoContrastOption')} globalType={type} type={autoContrastOption?.method} info={''} reducerInfo={autoContrastOption} selected={autoContrastOption?.selected} />
          )}
          {simpleOptions
            ? simpleOptions.map((option) =>
                option.selected ? <TabOption displayName={option.displayName ? option.displayName : ''} globalType={type} type={option.method} info={''} selected={option?.selected} key={option.method + performance.now()} /> : ''
              )
            : ''}
        </>
      ) : (
        ''
      )}
      {type === 'augmentation' ? (
        <>
          {showAugmentationButton ? <StyledPreprocessingButton onClick={onAddClick}>{t('authorized.project.generateAndVersions.addPrepr')}</StyledPreprocessingButton> : ''}
          {blurOption.selected && (
            <TabOption
              displayName={t('authorized.project.generateAndVersions.formOptions.blurOption')}
              globalType={type}
              type={'blur'}
              info={blurOption.params.maxBlur + '%'}
              reducerInfo={blurOption.params}
              selected={blurOption.selected}
              apply={blurOption.percentage}
            />
          )}
          {noiseOption.selected && (
            <TabOption
              displayName={t('authorized.project.generateAndVersions.formOptions.noiseOption')}
              globalType={type}
              type={'noise'}
              info={noiseOption.params.maxNoisePercentage + '%'}
              reducerInfo={noiseOption.params}
              selected={noiseOption.selected}
              apply={noiseOption.percentage}
            />
          )}
          {grayscaleOption.selected && (
            <TabOption displayName={t('authorized.project.generateAndVersions.formOptions.grayscaleOption')} globalType={type} type={'Grayscale Aug...'} info={''} reducerInfo={''} selected={grayscaleOption?.selected} apply={grayscaleOption.percentage} />
          )}
          {brightness.selected && (
            <TabOption
              displayName={t('authorized.project.generateAndVersions.formOptions.brightness')}
              globalType={type}
              type={'brightness'}
              info={brightness.params.brightnessPercentage + '% ' + brightness.params.mode}
              reducerInfo={brightness.params}
              selected={brightness?.selected}
              apply={brightness.percentage}
            />
          )}
        </>
      ) : (
        ''
      )}
    </StyledPreprocessingTab>
  )
}

export default PreprocessingTab
