import React from 'react'
import { ITabsProps } from '../../constants/interfaces'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'

export const LicensesTabs: React.FC<ITabsProps> = ({activeTab, setActiveTab}) => {
  const { t } = useTranslation()
  return (
    <FlexContainer direction='row' justify='center' align='center' gap='20px' wrap='wrap'>
      <FlexContainer direction='row' justify='center' align='center' gap='20px' wrap='wrap'>
        <ProjectButton 
          active={activeTab === 1} 
          onClick={() => setActiveTab(1)} 
          heightProps='30px' 
          widthProps='110px' 
          FontSizeProps='16px'
        >
          1 {t('other.month')}
        </ProjectButton> 
        <ProjectButton 
          active={activeTab === 6} 
          onClick={() => setActiveTab(6)} 
          heightProps='30px' 
          widthProps='110px' 
          FontSizeProps='16px'
        >
          6 {t('other.months')}
        </ProjectButton>
        <ProjectButton 
          active={activeTab === 12} 
          onClick={() => setActiveTab(12)} 
          heightProps='30px' 
          widthProps='110px' 
          FontSizeProps='16px'
        >
          12 {t('other.months')}
        </ProjectButton>    
      </FlexContainer>
    </FlexContainer>
  )
}

export default LicensesTabs