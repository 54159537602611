import React, { FC } from 'react'
import { StyledBr, StyledContentTabs, StyledEdit, StyledEditIcon, StyledForumTab, StyledTextButton } from '../../constants/styled'
import { IGetCategoryByIdResponce } from '../../../../services/ForumService'
import CategoriesWrapper from '../Category/CategoriesWrapper'
import { NavigateFunction } from 'react-router-dom'
import { IUsersMeResponse } from '../../../../services/UserService'
import { useTranslation } from 'react-i18next'

interface IForumCategoriesProps {
  currentTab: string
  categories: IGetCategoryByIdResponce[]
  onEditClick: () => void
  user: IUsersMeResponse
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  navigate: NavigateFunction
}

const ForumCategories: FC<IForumCategoriesProps> = ({ currentTab, categories, onEditClick, user, setCurrentTab, navigate, ...props }) => {
  const { t } = useTranslation()
  return (
    <div className="wrapper">
      <StyledContentTabs>
        <StyledForumTab active={currentTab === 'forums'} onClick={() => setCurrentTab('forums')}>
          {t('forum.forum')}
          {user?.role?.name === 'global_admin' || user?.role?.name === 'developer' ? (
            <StyledEdit onClick={onEditClick}>
              <StyledEditIcon />
              <StyledTextButton>{t('buttons.create')}</StyledTextButton>
            </StyledEdit>
          ) : (
            ''
          )}
        </StyledForumTab>
        {/* <StyledForumTab active={currentTab === 'activity'} onClick={() => setCurrentTab('activity')}>Latest Activity</StyledForumTab>
          <StyledForumTab active={currentTab === 'subscriptions'} onClick={() => setCurrentTab('subscriptions')}>My subscriprions</StyledForumTab> */}
      </StyledContentTabs>
      <StyledBr />
      {currentTab === 'forums' && <CategoriesWrapper categories={categories} user={user} navigate={navigate} />}
      {currentTab === 'activity' && 'activity'}
      {currentTab === 'subscriptions' && 'subscriptions'}
    </div>
  )
}

export default ForumCategories
