import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Colors } from '../../../../models/Colors'
import { IWorkspaceProps } from '../../models/IWorkspaceProps'
import { useAppSelector } from '../../../../hooks/redux'

const StyledTitle = styled.div<IWorkspaceProps>`
    color: ${props => props.activeProps ? Colors.lightblue : Colors.blue};
    transition: all 0.1s linear;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const StyledCounter = styled.div<IWorkspaceProps>`
    display:flex;
    align-items: center;
    justify-content:center;

    min-width: 30px;
    min-height: 30px;

    border-radius: 70px;

    background: ${props => props.activeProps ? Colors.lightblue : Colors.blue};

    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    color: ${Colors.WHITE};
    text-align: center;
    line-height: 17px;

    transition: all 0.1s linear;     

    cursor: pointer;
    &:hover {
        background: ${Colors.lightblue};
        box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
`

const StyledWorkspace = styled.div<IWorkspaceProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  width: 230px;
  min-height: 40px;
  max-height: 40px;

  border: 1px solid ${props => props.activeProps ? Colors.lightblue : Colors.blue};
  border-radius: 5px;
  transition: all 0.1s linear;

  cursor: pointer;

  &:hover {
    color: ${Colors.lightblue};
    border-color: ${Colors.lightblue}; 
    box-shadow: 0 15px 30px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  &:hover ${StyledCounter} {
    background: ${Colors.lightblue};
  }
  &:hover ${StyledTitle} {
    color: ${Colors.lightblue};
  }
`

const Workspace: FC<IWorkspaceProps> = ({activeProps, title, id, count, ...props}) => {
  const groupInfo = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (groupInfo?.id !== id) {
      setActive(false)
    }
    if (groupInfo?.id === id) {
      setActive(true)
    }
  },[groupInfo])

  return (
    <StyledWorkspace activeProps={active} {...props}>
        <StyledTitle activeProps={active}>{title}</StyledTitle>
        <StyledCounter activeProps={active}>{count? count : 0}</StyledCounter>
    </StyledWorkspace>
  )
}

export default Workspace 