import React, { FC, useState } from 'react'
import { NavigateFunction, Params } from 'react-router-dom'
import VersionsService, { ICreateExportRequest } from '../../../../../services/VersionsService'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { StyledExportFormSelect, StyledExportFormText, StyledLabel, StyledLink, StyledOption } from '../../../constants/styled'
import { useAppDispatch } from '../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../store/reducers/modalWindowReducer'
import { IFullVersionWithArchive, versionsReducer } from '../../../store/reducers/VersionsReducer'
import { asyncGetVersionById } from '../../../../../store/reducers/AcyncActions'
import RenameLabelsForm from '../../RenameLabelsForm/RenameLabelsForm'
import { useTranslation } from 'react-i18next'
import InputRadio from '../../../../../UI/InputRadio/InputRadio'
import { IProject } from '../../../../../services/ProjectService'

interface IExportVersionFormProps {
  projectInfo: IProject
  navigate: NavigateFunction
  currentVersion: IFullVersionWithArchive
  params: Readonly<Params<string>>
}

const ExportVersionForm: FC<IExportVersionFormProps> = ({ projectInfo, navigate, currentVersion, params, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [selectValue, setSelectValue] = useState('coco')
  const [checked, setChecked] = useState(true)

  const getExportProgress = async (job: string) => {
    try {
      if (job) {
        const exportVersionResponce = await VersionsService.getExportVersionProgress(job)
        if (exportVersionResponce?.data?.progress === null) {
          dispatch(
            modalWindowSlice.actions.setChildren(
              <FlexContainer direction="column" justify="center" align="center" gap="30px">
                <span>{t('errors.serverError')}</span>
                <ProjectButton onClick={onCancelClick} widthProps="150px">
                  {t('buttons.close')}
                </ProjectButton>
              </FlexContainer>
            )
          )
          // await JobsService.deleteJob(job)
          throw new Error('Null Percents :(')
        }
        if (exportVersionResponce?.data?.progress) {
          console.log(exportVersionResponce?.data?.progress)
          dispatch(versionsReducer.actions.setProgress(exportVersionResponce?.data?.progress))
        }
        console.log('subscribeOnExport', exportVersionResponce)
        if (!exportVersionResponce.data) {
          dispatch(
            modalWindowSlice.actions.setChildren(
              <FlexContainer direction="column" justify="center" align="center" gap="30px">
                <span>{t('errors.serverError')}</span>
                <ProjectButton onClick={onCancelClick} widthProps="150px">
                  {t('buttons.close')}
                </ProjectButton>
              </FlexContainer>
            )
          )
          // await JobsService.deleteJob(job)
          throw new Error('No Responce.data :(')
        }
        if (exportVersionResponce.data?.link) {
          dispatch(
            modalWindowSlice.actions.setChildren(
              <FlexContainer direction="column" justify="center" align="center" gap="30px">
                <StyledExportFormText>{t('authorized.project.generateAndVersions.exportText1')}</StyledExportFormText>
                <StyledLink href={exportVersionResponce.data?.link} id="link">
                  {t('authorized.project.generateAndVersions.exportText2')}
                </StyledLink>
                <ProjectButton onClick={onCancelClick} widthProps="150px">
                  {t('buttons.close')}
                </ProjectButton>
              </FlexContainer>
            )
          )
          window.open(exportVersionResponce.data?.link, '_self')
          dispatch(asyncGetVersionById(params.versionId!))
        }
        if (!exportVersionResponce.data?.link) {
          if (job) {
            console.log('Retry!')
            setTimeout(() => {
              getExportProgress(job)
            }, 500)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const subscribe = async (payload: ICreateExportRequest) => {
    console.log('exportPayload', payload)
    try {
      const responce = await VersionsService.createExportVersion(payload)
      console.log('subscribe', responce)
      dispatch(versionsReducer.actions.setJob(responce.data.job))
      getExportProgress(responce.data.job)
    } catch (error) {
      console.log(error)
    }
  }

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onContinueClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.generateAndVersions.classRename')))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('500px'))
    dispatch(modalWindowSlice.actions.setChildren(<RenameLabelsForm type="version" subscribe={subscribe} selectValue={selectValue} checked={checked}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(e.target.value)
  }

  return (
    <FlexContainer direction="column" justify="center" align="center" gap="20px">
      <FlexContainer direction="column" justify="center" align="center">
        <StyledLabel>{t('authorized.project.generateAndVersions.format')}:</StyledLabel>
        <StyledExportFormSelect value={selectValue} onChange={onSelectChange}>
          <StyledOption disabled>JSON:</StyledOption>
          <option value={'coco'}>COCO</option>
          <option value={'createml'}>CreateML</option>
          <StyledOption disabled>XML:</StyledOption>
          <option value={'voc'}>Pascal VOC</option>
          <StyledOption disabled>TXT:</StyledOption>
          <option value={'yolov7'}>YOLO v7 PyTorch</option>
        </StyledExportFormSelect>
      </FlexContainer>

      <FlexContainer direction='row' justify='center' align='center'>
        <InputRadio 
          widthProps='14px'
          heightProps='14px'
          type="checkbox"
          label={t('authorized.project.generateAndVersions.useOririnalFileNames')}  
          checked={checked} 
          onChange={() => setChecked(!checked)}
        />
      </FlexContainer>

      <FlexContainer direction="row" justify="space-between" paddingProps="0 20px">
        <ProjectButton onClick={onCancelClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onContinueClick}>{t('buttons.continue')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default ExportVersionForm
