import { $authHost } from "../http/index";
import { AxiosResponse } from "axios";

export default class JobsService {
    static async stopJob(id: string): Promise<AxiosResponse<any>> {
        return $authHost.post<any>(`/jobs/stop`, {id})
    }
    static async deleteJob(id: string): Promise<AxiosResponse<any>> {
        return $authHost.delete<any>(`/jobs/delete/${id}`)
    }

}