import React from 'react'
import { IContentSectionProps } from '../../constants/interfaces'
import { StyledContentSection, StyledContentSectionTitle , StyledContentSectionSubTitle} from '../../constants/styled'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'

const ContentSection: React.FC<IContentSectionProps> = ({title, subtitle, children, ...props}) => {
  return (
    <StyledContentSection>
      {title || subtitle 
        ?
        <FlexContainer direction='column' justify='flex-start' align='flex-start' gap='10px'>
          <StyledContentSectionTitle>{title}</StyledContentSectionTitle>
          <StyledContentSectionSubTitle>{subtitle}</StyledContentSectionSubTitle>
        </FlexContainer>
        : ''
      }
      {children}
    </StyledContentSection>
  )
}

export default ContentSection