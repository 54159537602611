import React from 'react'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import Loader from '../../../../../UI/Loader/Loader'
import { useAppSelector } from '../../../../../hooks/redux'
import { useTranslation } from 'react-i18next'

const UploadFormPercentage = () => {
  const { t } = useTranslation()
  const {percents, uploaded, totalToUpload} = useAppSelector(state => state.blopImagesReducer)
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px' paddingProps='10px'>
      <Loader/>
      <div>{percents}%</div>
      <div>{uploaded} {t('authorized.project.upload.from')} {totalToUpload} {t('authorized.project.upload.loaded')}</div>
    </FlexContainer>
  )
}

export default UploadFormPercentage