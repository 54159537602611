import React, { useEffect } from 'react'
import { StyledFilterText, StyledInputSearchIcon, StyledListSearch, StyledSearchFiltersWrapper, StyledSearchWrapper } from '../constants/styled'
import Input from '../../../UI/Input/Input'
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton'
import { useTranslation } from 'react-i18next'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import { EImagesPage, ISplit } from '../store/ImagesListReducer'
import ProjectSelect from '../../../UI/ProjectSelect/ProjectSelect'

interface IListSearchProps {
  searchContent: string
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  split: ISplit[]
  selectedSplit: ISplit | null
  onSplitChange: (value: string) => void
  selectedImages: any
  onSelectAllClick: () => void
  onDeselectClick: () => void
  currentLimit: ISplit
  onSearchClick: () => void
  userRoleNumber: number;
  page: EImagesPage;
}

const ListSearch: React.FC<IListSearchProps> = ({
  searchContent,
  onSearchChange,
  split,
  selectedSplit,
  onSplitChange,
  selectedImages,
  onSelectAllClick,
  onDeselectClick,
  currentLimit,
  onSearchClick,
  userRoleNumber,
  page,
  ...props
}) => {
  const { t } = useTranslation()
  const [focused, setFocused] = React.useState(false)

  useEffect(() => {
    const onEnterClick = (e: any) => {
      if ((e.keyCode === 13 || e.key === 'enter') && focused) {
        onSearchClick()
      }
    }
    document.addEventListener('keydown', onEnterClick)
    return () => {
      document.removeEventListener('keydown', onEnterClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContent])


  const getRenderCondition = (userRoleNumber: number, condition?: string) => {
    if (condition === EImagesPage.versionPage) {
      return false
    }
    if (userRoleNumber === 6 || userRoleNumber === 5) {
      return false
    }
    return true
  }

  return (
    <StyledListSearch>
      <StyledSearchWrapper>
        <Input
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          label={t('imagesList.searchTitle')}
          placeholder={t('forum.enterText')}
          onChange={onSearchChange}
          widthProps="100%"
          heightProps="35px"
        />
        <ProjectButton onClick={onSearchClick} widthProps="150px" margin="25px 0 0 0" heightProps="35px">
          <FlexContainer direction="row" justify="center" align="center" gap="10px">
            <StyledInputSearchIcon />
            {t('buttons.search')}
          </FlexContainer>
        </ProjectButton>
      </StyledSearchWrapper>

      <FlexContainer direction="row" justify="space-between" align="center" widthProps="100%">
        <StyledSearchFiltersWrapper>
          <FlexContainer widthProps="auto" justify="flex-start" align="center" gap="10px">
            <StyledFilterText>{t('imagesList.imagesType')}</StyledFilterText>
            <div style={{ width: '150px' }}>
              <ProjectSelect options={split} mode="rows" selected={selectedSplit || null} onChange={onSplitChange} placeholder={selectedSplit?.title} />
            </div>
          </FlexContainer>
          {selectedImages?.length ? (
            <FlexContainer justify="flex-start" align="center" gap="10px" widthProps='auto'>
              <StyledFilterText>{t('imagesList.selectedImages')}</StyledFilterText>
              <StyledFilterText>{selectedImages?.length || 0}</StyledFilterText>
            </FlexContainer>
          ) : (
            ''
          )}
        </StyledSearchFiltersWrapper>

        <FlexContainer direction="row" justify="flex-end" align="center" gap="10px" widthProps='auto'>
          {selectedImages?.length ? (
            <ProjectButton onClick={onDeselectClick} widthProps="140px" paddingProps='5px 10px' heightProps="32px" FontSizeProps="14px">
              {t('buttons.deselectALL')}
            </ProjectButton>
          ) : (
            ''
          )}
          {getRenderCondition(userRoleNumber, page)
          ? <ProjectButton onClick={onSelectAllClick} widthProps="120px" paddingProps='5px 10px' heightProps="32px" FontSizeProps="14px">
              {t('buttons.selectAll')}
            </ProjectButton>
          : ''
          }
        </FlexContainer>
      </FlexContainer>
    </StyledListSearch>
  )
}

export default ListSearch
