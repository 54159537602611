import React from 'react';
import PagesRouter from './components/PagesRouter/PagesRouter';
import AssignNav from './modules/ProjectModule/components/AssignNav/AssignNav';
import ReAssignNavComponent from './modules/ProjectModule/components/ReassignNav/ReAssignNavComponent';
import Modal from './UI/ModalWindow/Modal';
import DatasetsNav from './modules/ProjectModule/components/DatasetsNav/DatasetsNav';
import BatchAssignNav from './modules/ProjectModule/components/BatchAssignNav/BatchAssignNav';
import EventSourcing from './http/eventSources/EventSourcing';

function App() {
  return (
    <div className="App">
      <EventSourcing/>
      <Modal/>
      <AssignNav/>
      <PagesRouter/>
      <DatasetsNav/>
      <BatchAssignNav/>
      <ReAssignNavComponent/>
    </div>
  );
}

export default App;
