import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../models/Colors'
import DropdownButton from '../../../../UI/Buttons/DropdownButton/DropdownButton';
import { IUserNavDropdownProps } from '../../../../models/IUserNavDropdownProps';
import BurgerIcon from '../../../../assets/icons/hamburger.png'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useAppDispatch } from '../../../../hooks/redux';
import { asyncDeleteProject } from './../../../../store/reducers/AcyncActions';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import RenameProjectForm from './RenameProjectForm';
import burgerIcon from '../../../../assets/icons/hamburger-menu-svgrepo-com.svg'
import { useTranslation } from 'react-i18next';
import DeleteProjectForm from './DeleteProjectForm';
const StyledBurger = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${burgerIcon});
  mask-image: url(${burgerIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

interface IProject {
    id: string;
    group: string;
    name: string;
    user: string;
    objectType: string;
    createdAt: string;
    updatedAt: string;
}

interface IDropdownProps {
    active?: boolean;
    projectInfo: IProject;
}

const StyledSettings = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width:30px;
    height: 30px;

    font-weight: bold;
    font-size: 10px;
    cursor: pointer;

    .count {
        position: absolute;
        top: -6px;
        right: 4px;
    }
`
const StyledDropDown = styled.div`
    margin: 0 10px;
    cursor: pointer;
    .username {
        height: 30px;
        padding: 5px;
    }
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
    display: ${props => props.visible ? 'block' : 'none'};
    width: 160px;
    min-height: 100px;
    height: auto;

    background-color: ${Colors.dark};
    border: 2px solid ${Colors.gray};
    border-radius: 5px;

    position: absolute;
    top: 100%;
    right: 0%;
    z-index:999;
`

const ProjectDropdown: FC<IDropdownProps> = ({projectInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }
  
  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groupProjects.project.rename')))
    dispatch(modalWindowSlice.actions.setChildren(<RenameProjectForm projectInfo={projectInfo}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onInviteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle('Invite Members'))
    dispatch(modalWindowSlice.actions.setChildren('Invite Members Form'))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.groupProjects.project.delete')))
    dispatch(modalWindowSlice.actions.setChildren(<DeleteProjectForm projectInfo={projectInfo} setModalVisivble={setModalVisivble}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  return (
    <StyledDropDown {...props} >
      <FlexContainer paddingProps='0' direction='row' justify='center' align='center' onClick={onDropdownClick}>
        <StyledSettings>
          <StyledBurger {...props} onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}/>
        </StyledSettings>
      </FlexContainer>
      <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
        <DropdownButton onClick={onRenameClick}>{t('other.rename')}</DropdownButton>
        {/* <DropdownButton onClick={onInviteClick}>Invite Members</DropdownButton> */}
        <DropdownButton onClick={onDeleteClick} colorProps='red'>{t('other.delete')}</DropdownButton>
      </StyledDropdownContent>
    </StyledDropDown> 
  )
}

export default ProjectDropdown