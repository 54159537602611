import React, { FC, useEffect, useLayoutEffect } from 'react'
import { StyledDeleteBtn, StyledDeleteWrapper, StyledJsonIcon, StyledTxtIcon, StyledXmlIcon } from '../../../constants/styled'
import styled from 'styled-components'
import { Colors } from '../../../../../models/Colors'
import { useInView } from 'react-intersection-observer'

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`
export const StyledImgWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #4a4a4a4f;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${Colors.gray};
  border-radius: 5px;
`
export const StyledImg = styled.img`
  max-width: 70px;
  max-height: 70px;
`

export const StyledCanvas = styled.canvas`
  max-width: 70px;
  max-height: 70px;
  width: 70px;
  height: 70px;

  position: absolute;
  top: -1000px;
  left: -10000000px;
`

const StyledNullImage = styled.img`
  position: absolute;
  top: -1000px;
  left: -10000000px;
`

export const StyledImageName = styled.h6`
  width: 100px;
  text-align: center;
  font-size: 10px;
  color: ${Colors.textgray};
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: all .1s linear;
`

interface IDroppedFileProps {
    deleteImgClickHandler: any;
    image: any;
    index: number;
    showIndex: number;
    anchorCounter: number;
    setAnchorCounter: React.Dispatch<React.SetStateAction<number>>
}

const DroppedFile: FC<IDroppedFileProps> = ({deleteImgClickHandler, image, index, showIndex, setAnchorCounter, anchorCounter, ...props}) => {
  const {ref, inView} = useInView({
    threshold: 0.4
  })
  
  const [url, setUrl] = React.useState<any>('')
  const [name, setName] = React.useState<any>('')
  const [type, setType] = React.useState<any>('')

  // useEffect(() => {
  //   const fileUrl = URL.createObjectURL(image)
  //   const name = image.name
  //   const type = image.type
  //   setUrl(fileUrl)
  //   setName(name)
  //   setType(type)
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  useLayoutEffect(() => {
    if (url) {
      return
    }
    console.log('url', url)
    const imageToCanvas = document.getElementById(index.toString() + image.name) as HTMLImageElement
    const canvas = document.getElementById('uploadCanvas' + index.toString() + image.name) as HTMLCanvasElement

    setName(image.name)
    setType(image.type)
    if (inView) {
      if (!canvas) {
        return
      }

      const fileUrl = URL.createObjectURL(image)
      imageToCanvas.src = fileUrl

      imageToCanvas.onload = () => {
        var MAX_WIDTH = canvas.width;
        var MAX_HEIGHT = canvas.height;
        var width = imageToCanvas.width;
        var height = imageToCanvas.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d")!.drawImage(imageToCanvas, 0, 0, width, height);   
        const createdImage = canvas.toDataURL("image/png")
        setUrl(createdImage)
        if (!url) {
          imageToCanvas.remove()
        }
      } 

     
    }
  }, [image, inView])

  // fileUrl: URL.createObjectURL(file),
  // name: file.name,
  // type: file.type

  useEffect(() => {
    if (inView && index === anchorCounter * showIndex) {
      console.log('increaseAnchor', inView && index === anchorCounter * showIndex)
      setAnchorCounter(anchorCounter + 1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inView])

  return (
    <StyledDeleteWrapper key={url} ref={ref}>
        <StyledNullImage id={index.toString() + image.name}/>
        <StyledDeleteBtn onClick={() => deleteImgClickHandler(image)}>x</StyledDeleteBtn>
        <StyledDescription>
          {inView ?                      
            <StyledImgWrapper>
              {type?.includes('text/plain') ? <StyledTxtIcon/> : ''}
              {type?.includes('application/json') ? <StyledJsonIcon/> : ''}
              {type?.includes('text/xml') ?  <StyledXmlIcon/> : ''}
              {type?.includes('image') && url ? <StyledImg src={url} alt='alt' /> : ''}
              {type?.includes('image') ? <StyledCanvas id={'uploadCanvas' + index.toString() + image.name} /> : ''}
            </StyledImgWrapper>
          : <StyledImgWrapper/>}
          <StyledImageName>{inView ? name : ' '}</StyledImageName>  
        </StyledDescription>  
    </StyledDeleteWrapper>
  )
}

export default DroppedFile