import React, { FC, useState } from 'react'
import { IGetDatasetByIdResponce } from '../../../../../../../services/DatasetsService';
import { useAppDispatch } from '../../../../../../../hooks/redux';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import { asyncChangeDatasetDescription } from '../../../../../../../store/reducers/AcyncActions';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import Input from '../../../../../../../UI/Input/Input';
import ProjectButton from '../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import { useTranslation } from 'react-i18next';

interface IEditDescriptionProps {
  currentDataset: IGetDatasetByIdResponce;
}

const EditDatasetDescription: FC<IEditDescriptionProps> = ({currentDataset, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [description, setDescription] = useState(currentDataset.dataset.description? currentDataset.dataset.description : '')

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setModalVisivble()
  }

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }

  const onButtonRenameClick = () => {    
    dispatch(asyncChangeDatasetDescription(currentDataset.dataset.id, currentDataset.dataset.name, description))
    setModalVisivble()
  }
  
  return (
    <FlexContainer direction='column' justify='center' align='center' gap='15px'>
      <Input label={t('authorized.project.taskPage.instruction')} placeholder={t('forum.enterText')} value={description} onChange={onNameChange}/>
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onRenameClick}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton widthProps='140px' FontSizeProps='16px' onClick={onButtonRenameClick}>{t('other.edit')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default EditDatasetDescription