import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components';
import ProjectSelect, { Option } from '../../../../UI/ProjectSelect/ProjectSelect';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import { StyledSelectWrapper, StyledUserCardAvatar } from '../../constants/Styled';
import { useTranslation } from 'react-i18next';

interface IProjectCheckboxProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>, setChecked: any) => void;
    value: string;
    title: string;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorText: React.Dispatch<React.SetStateAction<string>>;
    checkboxesValue: any[];
    setCheckboxesValue: React.Dispatch<React.SetStateAction<any[]>>;
    optionsTable: Option[];
}

const StyledInput = styled.input`
    width: 15px;
    height: 15px;
`

const StyledCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    font-size: 14px;
`

const ProjectCheckbox: FC<IProjectCheckboxProps> = ({optionsTable, onChange, setVisible, setErrorText, setCheckboxesValue, checkboxesValue, value, title, ...props}) => {
  const { t } = useTranslation()
  const [role, setRole] = useState("");
  const [valueWithRole, setValueWithRole] = useState('')
  const [checked, setChecked] = useState(false)

  const handleRoleSelect = (value: string) => {
    setRole(value);
    setErrorText('')
    setVisible(false)

    if (checked) {
      const checkboxesValueCopy = [...checkboxesValue]
      const findedEmail = checkboxesValueCopy.find(email => email.email === title)
      if (findedEmail) {
        findedEmail.role = value
        setCheckboxesValue(checkboxesValueCopy)
      }
    }
  };

  const showError = () => {
    setErrorText(t('authorized.project.projectManagement.selectRole'))
    setVisible(true)
  }

  useEffect(() => {
    if (value) {
      setValueWithRole(value + ' ' + role)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[role])

  const selectedRole = optionsTable.find((item) => item.value === role);
  
  return (
    <StyledCheckboxWrapper>
        <FlexContainer direction='row' justify='flex-start' align='center' gap='10px'>
          <StyledInput type='checkbox' value={valueWithRole} onChange={(e) => role ? onChange(e, setChecked) : showError()} checked={checked}/>
          <StyledUserCardAvatar widthProps='25px' heightProps='25px'/>
          <span>{title}</span>
        </FlexContainer>
        <StyledSelectWrapper>
          <ProjectSelect options={optionsTable} mode='rows' selected={selectedRole || null} onChange={handleRoleSelect} placeholder={t('authorized.project.projectManagement.selectRole')}/>
        </StyledSelectWrapper>
    </StyledCheckboxWrapper>
  )
}

export default ProjectCheckbox