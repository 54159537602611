import React, { useEffect, useState } from 'react'
import { ITopUpBalanceFormProps } from '../../constants/interfaces';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { modalWindowSlice } from '../../../../store/reducers/modalWindowReducer';
import FlexContainer from '../../../../components/FlexContainer/FlexContainer';
import Input from '../../../../UI/Input/Input';
// import InputRadio from '../../../../UI/InputRadio/InputRadio';
import { ProjectBr, ProjectLink } from '../../../../styles/GlobalStyles';
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton';
import Loader from '../../../../UI/Loader/Loader';
import { StyledCenteredText } from '../../constants/styled';
import { StyledErrorText } from '../../../Projects/constants/Styled';
import PaymentsService from '../../../../services/PaymentsService';
import { useTranslation } from 'react-i18next';

const TopUpBalanceForm: React.FC<ITopUpBalanceFormProps> = ({license, user, group, discountCost}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const min = 0;
  const max = 100000000;
  const EXCHANGE_RATE = useAppSelector(state => state.SettingsReducer.settings).find(settings => settings.key === 'EXCHANGE_RATE')
  const [tugriksValue, setTugriksValue] = useState<string | number>('1000')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [link, setLink] = useState('')
  // const [checked, setChecked] = useState(true)

  const minValue = 500

  useEffect(() => {
    if (discountCost) {
      setTugriksValue(discountCost)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTugriksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(min, Math.min(max, Number(e.target.value)));
   
    setTugriksValue(value);
    if (!e.target.value) {
      setTugriksValue('')
    }
    if (+e.target.value < minValue) {
      setError(t('authorized.settings.topUpForm.errorMin'))
    }
    if (+e.target.value >= minValue) {
      setError('')
    }
  };

  const onGoBackClick = () => {
    setError('')
    setLink('')
    setTugriksValue('1000')
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onContinueClick = async () => {
    try {
      if (+tugriksValue < minValue) {
        return
      }
      setIsLoading(true)
      const { data } = await PaymentsService.robokassaInitiate({amount: tugriksValue.toString(), description: `Покупка ${tugriksValue} кредитов`, recurring: false})
      setLink(data.paymentsLink)
      window.open(data.paymentsLink);
      setIsLoading(false)
    } catch (error: any) {
      setError(`Error on TopUp balance. Try later `)
      setIsLoading(false)
    }

  }

  // const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(e.target.checked)
  // }

  return (
    <>
      {!isLoading && !link &&
        <FlexContainer direction='column' justify='flex-start' align='center' gap='10px'>
          <StyledErrorText visible>{error}</StyledErrorText>
          <Input 
            type='number' 
            label={t('authorized.settings.topUpForm.inputTitle')}
            value={tugriksValue}
            onChange={(e) => handleTugriksChange(e)}
            min="1"
            max="100000000"
          />
          {/* <InputRadio
            widthProps='14px'
            heightProps='14px'
            checked={checked}
            onChange={(e) => handleCheckedChange(e)}
            type="checkbox"
            label={'Ежемесячное пополнение'}  
          /> */}
  
          <ProjectBr/>
          <FlexContainer direction='row' justify='space-between' align='center' paddingProps='10px 20px'>
            <span>{t('authorized.settings.topUpForm.total')}:</span>
            <span>{!tugriksValue ? 0 : (+tugriksValue * (EXCHANGE_RATE?.value ? +EXCHANGE_RATE?.value : 1))}{process.env.REACT_APP_LANGUAGE === 'ru' ? 'р': '$'}</span>
          </FlexContainer>
          <FlexContainer direction='row' justify='space-around' margin='15px 0 0 0'>
            <ProjectButton onClick={onGoBackClick}>{t('buttons.cancel')}</ProjectButton>
            <ProjectButton disabled={+tugriksValue < 500} onClick={onContinueClick}>{t('buttons.continue')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      }
      {isLoading && 
        <FlexContainer direction='column' justify='center' align='center' heightProps='100%' widthProps='100%'>
          <Loader/>
        </FlexContainer>
      }
      {link && !error && !isLoading && 
        <FlexContainer direction='column' justify='flex-start' align='center' gap='10px'>
          <ProjectLink href={link} target='_blank'>{t('authorized.settings.topUpForm.topUpLink')}</ProjectLink>
          <StyledCenteredText>{t('authorized.settings.topUpForm.topuplinkText')}</StyledCenteredText>
          <FlexContainer direction='row' justify='space-around' margin='15px 0 0 0'>
            <ProjectButton onClick={onGoBackClick}>{t('buttons.cancel')}</ProjectButton>
          </FlexContainer>
        </FlexContainer>
      } 
    </>

  )
}

export default TopUpBalanceForm