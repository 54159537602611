import React, { FC } from 'react'
import { IModalProps } from '../../../../models/IModalProps';
import styled from 'styled-components';
import { Colors } from '../../../../models/Colors';
import { useAppSelector } from '../../../../hooks/redux';
import LoadingText from '../../../../UI/LoadingText/LoadingText';
import Loader from '../../../../UI/Loader/Loader';

const StyledBackground = styled.div<IModalProps>`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #0000007b;
    transition: opacity 0.1s linear;
    height: ${({ visible }) => visible ? '100vh' : '0'};
    opacity: ${({ visible }) => visible ? '1' : '0'};
    visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
    
    z-index: 1001;
` 

export const StyledBr = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${Colors.black};
`

const DrawLoader: FC<IModalProps> = ({...props}) => {
    const modalChildren = useAppSelector(state => state.drawLoaderReducer.children)
    const modalVisible = useAppSelector(state => state.drawLoaderReducer.visible)

    return (
      <StyledBackground visible={modalVisible}>
          <Loader/>
          <LoadingText/>
      </StyledBackground>
    )
  }

export default DrawLoader