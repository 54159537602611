import React, { FC, useEffect, useState } from 'react'
import SettingsDropdown from '../../../UI/Buttons/SettingsDropdown/SettingsDropdown'
import Input from '../../../UI/Input/Input'
import { StyledArrowLeftIcon, StyledBr, StyledEmptyWrapper, 
         StyledGroupName, StyledHeader, StyledInputSearchIcon,
         StyledManagementContent, StyledParagraph, StyledProjects, 
         StyledManagement, StyledUserPlusIcon, StyledUsersGroupIcon, Wrapper, StyledGoBack, StyledManagementContentWrapper } from '../constants/Styled'
import CreateProjectButton from './CreateProjectButton/CreateProjectButton'
import CreateProjectForm from './ModalForms/CreateProjectForm'
import Project from './Project/Project'
import { useAppDispatch, useAppSelector } from './../../../hooks/redux';
import { UseProjectsQuery } from '../../../hooks/UseProjectsQuery'
import { WorkspacesSlice } from './../store/reducers/WorkspacesReducer';
import { IProjectsProps } from '../models/IProjectsProps'
import { modalWindowSlice } from '../../../store/reducers/modalWindowReducer'
import ProjectButton from '../../../UI/Buttons/ProjectButton/ProjectButton'
import useQueryParams from '../../../hooks/useQueryParams'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'
import UsersTable from './UsersTable/UsersTable'
import InviteUserForm from './InviteUserForm/InviteUserForm'
import { UseGroupUsersQuery } from '../../../hooks/UseGroupUsersQuery'
import { StyledLink } from '../../ProjectModule/constants/styled'
import { SettingsReducer } from '../../UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'
import { StyledALink, StyledSupTitle } from '../../../styles/GlobalStyles'

const Projects: FC<IProjectsProps> = ({navigate, isManagementMode, isManagementClicked, setIsManagementMode, setIsManagementClicked, ...props}) => {
  const { t } = useTranslation()
  const query = useQueryParams()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState({query: ''})
  const tabContent = useAppSelector(state => state.WorkspacesReducer.tabContent)
  const queryProjects = UseProjectsQuery(tabContent, filter.query)
  const groupInfo = useAppSelector(state => state.WorkspacesReducer.groupInfo)
  const groupUsersInfo = useAppSelector(state => state.WorkspacesReducer.groupUsersInfo)
  const groupRoles = useAppSelector(state => state.rolesReducer.groupRoles)
  const currentUser = useAppSelector(state => state.userReduser?.user)

  const [roleNumber, setRoleNumber] = useState(5)

  const [usersFilter, setUsersfilter] = useState({query: ''})
  const queryUsers = UseGroupUsersQuery(groupUsersInfo, usersFilter.query)

  useEffect(() => {
    const managementMode = query.get('mode')
    if (!managementMode) {
      setIsManagementMode(false)
      setIsManagementClicked(false)
    }
    if  (managementMode) {
      setIsManagementMode(true)
      setIsManagementClicked(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(WorkspacesSlice.actions.setGroupClicked(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentUser?.id && groupUsersInfo?.length) {
      getMyRoleInGroup()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupUsersInfo, currentUser])

  const getMyRoleInGroup = () => {
    const findedUserInGroupMembers = groupUsersInfo.find(user => user.user.id === currentUser.id)
    if (currentUser?.role?.name === 'global_admin' || currentUser?.role?.name === 'developer') {
      setRoleNumber(1)
    }
    if (findedUserInGroupMembers && findedUserInGroupMembers?.groupRole?.name === 'group_owner') {
      setRoleNumber(2)
    }
    if (findedUserInGroupMembers && findedUserInGroupMembers?.groupRole?.name === 'group_admin') {
      setRoleNumber(3)
    }
    if (findedUserInGroupMembers && findedUserInGroupMembers?.groupRole?.name === 'group_user') {
      setRoleNumber(4)
    }
  }

  const onGoBackClick = () => {
    setIsManagementMode(false)
    setIsManagementClicked(false)
  }

  const onCreateClick = () => {
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.projects.createProjectForm.title')))
    dispatch(modalWindowSlice.actions.setChildren(<CreateProjectForm setVisible={setModalVisible}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, query: e.target.value})
  }

  const setModalVisible = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onInviteClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setModalWidth('460px'))
    // dispatch(modalWindowSlice.actions.setModalHeight('600px'))
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setScrollY(window.scrollY))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.projectManagement.formTitle')))
    dispatch(modalWindowSlice.actions.setChildren(<InviteUserForm roleNumber={roleNumber}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onLicensesClick = () => {
    if (roleNumber <= 2) {
      dispatch(SettingsReducer.actions.setActiveTab(groupInfo.id))
      navigate(`/settings/group/${groupInfo.id}/licenses`)
    }
  }
  
  return (
    <>
      {groupInfo?.id && !isManagementClicked && !isManagementMode
        ?     
          <StyledProjects>
            <StyledHeader>
              {groupInfo?.id ? <StyledGroupName>{groupInfo?.name + ' ' + t('authorized.projects.groupProjects.projects')}</StyledGroupName> : ''}
              {groupInfo?.id ? <Input widthProps='80%' placeholder={t('authorized.projects.groupProjects.enterName')} value={filter.query} onChange={onSearchChange}></Input> : ''}
              {groupInfo?.id && roleNumber <= 3 ? <SettingsDropdown buttonFunc={setIsManagementClicked} navigate={navigate} roleNumber={roleNumber}/> : ''}
            </StyledHeader>
            <StyledBr/>
            <Wrapper>
              {groupInfo?.id && roleNumber < 4 ? <CreateProjectButton onClick={onCreateClick}/> : '' }
              {groupInfo?.id ? queryProjects?.map(project => 
                <Project roleNumber={roleNumber} projectInfo={project} key={project.id} id={project.id} title={project.name} updatedAt={project.updatedAt} navigate={navigate}></Project>
              ) : null}
              {!groupInfo?.id && tabContent.length === 0 ? <StyledEmptyWrapper><h1>{t('other.empty')}</h1><p>{t('authorized.projects.groupProjects.emptyCreate')}</p></StyledEmptyWrapper> : ''}
              {/* {!isManagementClicked && isGroupClicked && tabContent.length === 0 ? <StyledEmptyProjectsWrapper><h1>Пусто</h1><p>Создайте проект</p></StyledEmptyProjectsWrapper> : ''} */}
            </Wrapper>
          </StyledProjects>
        : 
          !groupInfo?.id 
            ? <StyledProjects>
                <StyledEmptyWrapper>
                  <h1>{t('other.empty')}</h1>
                  <p>{t('authorized.projects.groupProjects.emptyCreate')}</p>
                  <StyledSupTitle>
                    {t('docs.loginScreen1')}
                    <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.loginScreen2')}</StyledALink>
                  </StyledSupTitle>
                </StyledEmptyWrapper>
              </StyledProjects> 
            : 
              <StyledManagement>
                <StyledGoBack onClick={onGoBackClick}>
                  <StyledArrowLeftIcon/>
                  <h3>{groupInfo?.name + '' } {t('authorized.projects.groupProjects.settings.groupManagement')}</h3>
                </StyledGoBack>
                <StyledHeader>
                    <FlexContainer direction='row' justify='flex-start' align='center' gap="10px">
                      <StyledUsersGroupIcon/>
                      <h3>{t('authorized.projects.groups.groupManagement.management')}</h3>
                    </FlexContainer>
                    <ProjectButton heightProps='30px' widthProps='160px' onClick={onInviteClick}>
                      <StyledUserPlusIcon/> 
                      {t('authorized.projects.groups.groupManagement.formTitle')}
                    </ProjectButton>
                  </StyledHeader>
                <StyledManagementContentWrapper>
                  <StyledBr/>
                  <StyledParagraph>{t('authorized.projects.groups.groupManagement.paragraph')}</StyledParagraph>
                  <StyledParagraph>
                    <StyledALink href={process.env.REACT_APP_LINK_TO_GITBOOK!} target='_blank' rel="noreferrer"> {t('docs.seeGuide')}</StyledALink>
                  </StyledParagraph>
                  <StyledBr/>
                  {roleNumber <= 2 &&
                    <StyledParagraph>
                      {t('authorized.projects.groups.groupManagement.increaseText')} <StyledLink onClick={onLicensesClick}>{t('authorized.projects.groups.groupManagement.increaseLink')}</StyledLink>
                    </StyledParagraph>
                  }
                  <StyledManagementContent>
                    <Input value={usersFilter.query} onChange={(e) => setUsersfilter({...usersFilter, query: e.target.value})} type="text" withIcon={<StyledInputSearchIcon/>} label={t('authorized.projects.groups.groupManagement.inputTitle')} placeholder='user.email@mail.ru'/>
  
                    <h4>{t('authorized.projects.groups.groupManagement.usersCount')} {groupUsersInfo?.length || 0}</h4>
  
                    <UsersTable groupInfo={groupInfo} groupRoles={groupRoles} groupUsersInfo={queryUsers} roleNumber={roleNumber}/>
                  </StyledManagementContent>
                </StyledManagementContentWrapper>
              </StyledManagement>
      }
    </>
  )
}

export default Projects