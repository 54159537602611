import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetSummaryTaskResponce, IGetTaskByIdResponce, IGetTasksStatusesRes } from '../../../../services/TasksService';

export interface ITaskImages {
    filename: string;
    displayname: string;
}

export interface ICreateTaskImage {
    filename: string;
}
export interface ICreateTaskBatch {
    batch: string;
    images: ICreateTaskImage[]
}
export interface ICreateTaskRequest {
    description: string;
    performers: string[],
    project: string;
    batches: ICreateTaskBatch[]
}

export interface ISummaryTask {
    task: string; //id
    name: string;
    annotatedCount: number;
    unannotatedCount: number;
}

interface IRenamePayload {
    id: string;
    name: string;
}


interface IReducerState {
    createTaskBatch: ICreateTaskBatch[];
    createTaskObj: ICreateTaskRequest;
    tasksSummary: IGetSummaryTaskResponce
    currentTask: IGetTaskByIdResponce,
    currentProjectId: string;
    isLoading: boolean;
    error: string | null;
    total: number;

    selectedAnnotator: IGetTaskByIdResponce['task']['annotator'];
    selectedModerator: IGetTaskByIdResponce['task']['moderator'];

    annotatedTotal: number;
    createAnnotatedTaskBatch: ICreateTaskBatch[];
    createAnnotatedTaskObj: ICreateTaskRequest;

    activeTab: string;

}

const initialState: IReducerState = {
    createTaskBatch: [],
    createTaskObj: {
        description: '',
        performers: [],
        project: '',
        batches: []
    },
    tasksSummary: {} as IGetSummaryTaskResponce,
    currentTask: {} as IGetTaskByIdResponce,
    currentProjectId: '',
    isLoading: false,
    error: '',
    total: 0,

    selectedAnnotator: {} as IGetTaskByIdResponce['task']['annotator'],
    selectedModerator: {} as IGetTaskByIdResponce['task']['moderator'],

    annotatedTotal: 0,
    createAnnotatedTaskBatch: [],
    createAnnotatedTaskObj: {
        description: '',
        performers: [],
        project: '',
        batches: []
    },

    activeTab: '',
}

export const tasksReducer = createSlice(
{
    name: 'tasks',
    initialState,

    reducers: {
        setTabClicked(state, action: PayloadAction<string>) {
            state.activeTab = action.payload;
        },
        
        setLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setErrorState(state, action: PayloadAction<string | null>) {
            state.error = action.payload;
        },

        renameTask(state, action: PayloadAction<IRenamePayload>) {
            const findedTask = state.tasksSummary.tasks.find(task => task.task === action.payload.id)
            if (findedTask) {
                findedTask.name = action.payload.name
            }
        },

        renameCurrentTask(state, action: PayloadAction<IRenamePayload>) {
            state.currentTask.task.name = action.payload.name
        },

        deleteTask(state, action: PayloadAction<string>) {
            state.currentTask = initialState.currentTask
            const tasksSummary = state.tasksSummary
            const filteredTasks = state.tasksSummary.tasks.filter(task => task.task !== action.payload)
            state.tasksSummary.tasks = filteredTasks
            state.tasksSummary.total = state.tasksSummary.total - 1
        },

        setTasksSummary(state, action: PayloadAction<IGetSummaryTaskResponce>) {
            state.tasksSummary = action.payload
        },

        setCurrentTask(state, action: PayloadAction<IGetTaskByIdResponce>) {
            state.currentTask = action.payload
        },

        setTaskDescription(state, action: PayloadAction<string>) {
            state.currentTask.task.description = action.payload
        },

        setCurrentProjectId(state, action: PayloadAction<string>) {
            state.currentProjectId = action.payload
        },
        
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload
        },

        incrementTotal(state, action: PayloadAction<number>) {
            state.total += action.payload
        },

        decrementTotal(state, action: PayloadAction<number>) {
            state.total -= action.payload
        },

        setAnnotatedBatchTotal(state, action: PayloadAction<number>) {
            state.total = action.payload
        },

        incrementAnnotatedBatchTotal(state, action: PayloadAction<number>) {
            state.total += action.payload
        },

        decrementAnnotatedBatchTotal(state, action: PayloadAction<number>) {
            state.total -= action.payload
        },

        updateTasksStatuses(state, action: PayloadAction<IGetTasksStatusesRes>) {
            if (action?.payload?.tasks?.length) {
                action?.payload?.tasks?.forEach(updatedState => {
                    const findedTask = state.tasksSummary.tasks.find(task => task.task === updatedState.id)
                    if (findedTask) {
                        findedTask.status = updatedState.status
                    }
                })
            }
        },

        setSelectedModerator(state, action: PayloadAction<IGetTaskByIdResponce['task']['moderator']>) {
            state.currentTask.task.moderator = action.payload
        },
        setSelectedAnnotator(state, action: PayloadAction<IGetTaskByIdResponce['task']['annotator']>) {
            state.currentTask.task.annotator = action.payload
        },
    }
},

)

export default tasksReducer.reducer;