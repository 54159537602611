import React from 'react'
import FlexContainer from '../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../UI/Buttons/ProjectButton/ProjectButton'
import ContentSection from '../SettingsContent/ContentSection'
import { StyledText } from '../../../../components/UserNav/styled'
import { LicenceSettingsTextWrapper, StyledContentCardWrapper, StyledLittleText } from '../../constants/styled'
import GroupQuota from '../GroupQuota/GroupQuota'
import { IGroupSettingsProps } from '../../constants/interfaces'
import { Colors } from '../../../../models/Colors'
import GroupUsage from '../SettingsContent/GroupUsage'
import { useTranslation } from 'react-i18next'

const GroupSettings: React.FC<IGroupSettingsProps> = ({selectedLicense, groupQuotas, onGoToGroupClick, onChangePlanClick, onGoToManagementClick}) => {
  const { t } = useTranslation()
  return (
    <>
      <FlexContainer direction='row' justify='space-between' align='center'>
        <h2>{t('authorized.settings.groupTitle')}</h2>
        <FlexContainer direction='row' justify='center' align='center' widthProps='360px' gap='20px'>
          <ProjectButton onClick={onGoToManagementClick} widthProps='170px' FontSizeProps='15px'>{t('buttons.groupManagement')}</ProjectButton>
          <ProjectButton onClick={onGoToGroupClick} widthProps='170px' FontSizeProps='15px'>{t('buttons.goToGroup')}</ProjectButton>
        </FlexContainer>
      </FlexContainer>
      <ContentSection title={t('authorized.settings.groupTitle2')} subtitle={t('authorized.settings.group3')}>
        <span>{t('authorized.settings.groupLicense')}: <StyledText>{selectedLicense?.displayName || 'Community'}</StyledText></span>
        {selectedLicense?.name
          ? 
            <FlexContainer direction='column' gap='10px'>
              <LicenceSettingsTextWrapper>
                <span>{t('authorized.settings.groupPayment')} : </span>
                <StyledLittleText>{selectedLicense?.cost || '0'} RV</StyledLittleText>
              </LicenceSettingsTextWrapper>
              <LicenceSettingsTextWrapper>
                <span>{t('authorized.settings.groupLicenseDate')}: </span>
                <StyledLittleText>{selectedLicense?.createdAt?.slice(0, 10)}</StyledLittleText>
              </LicenceSettingsTextWrapper>
              <LicenceSettingsTextWrapper>
                <span>{t('authorized.settings.groupLicenseExp')} : </span>
                <StyledLittleText>{selectedLicense?.endDate?.slice(0, 10) || '∞'}</StyledLittleText>
              </LicenceSettingsTextWrapper>
            </FlexContainer>
          : ''
        }
        <StyledContentCardWrapper>
          <div>{t('authorized.settings.groupLicenses')} </div> 
          <ProjectButton  widthProps='180px' onClick={onChangePlanClick} active>{t('authorized.settings.licenses')}</ProjectButton>
        </StyledContentCardWrapper>
      </ContentSection>
      {/* <ContentSection title='Group Management' subtitle='Manage people from your Group'>
        <StyledContentCardWrapper>
            <div>View Group Management:</div> 
            <ProjectButton onClick={onGoToManagementClick} widthProps='140px'>Management</ProjectButton>
        </StyledContentCardWrapper>
      </ContentSection> */}
      <GroupUsage title={t('authorized.settings.groupQuotas')} >
        {groupQuotas?.map(quota => 
          <GroupQuota 
            key={quota?.id}
            desc={quota?.description}
            title={quota?.displayName}
            name={quota?.name}
            used={Math?.ceil(quota?.used)}
            max={quota?.limit}
            lineColor={(quota?.limit / quota?.used) > 1.3 || (quota?.limit / quota?.used) === Infinity ? Colors.lightgreen : Colors.red}
            withTooptip={true}
            IsExpandable={quota.isExtendable}
            quota={quota}
            groupQuotas={groupQuotas}
          />  
        )}              
      </GroupUsage>
    </>
  )
}

export default GroupSettings