import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import { StyledStplitCardPercents } from '../../../../constants/styled'
import { Colors } from '../../../../../../models/Colors'
import styled from 'styled-components'
import ReactSlider from 'react-slider'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch } from '../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import { useTranslation } from 'react-i18next'

interface IRebalanceSplitForm {
  setSplitPercents: any
  splitPercents: {
    train: number
    valid: number
    test: number
  }
}

const StyledWrapper = styled.div`
  width: 90%;
  .horizontal-slider {
    width: 100%;
    height: 50px;
    margin: auto;
  }

  .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 7px solid ${Colors.WHITE};
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  }

  .example-thumb.active {
    background-color: grey;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: #8f2ceb;
  }

  .example-track.example-track-1 {
    background: ${Colors.green};
  }

  .example-track.example-track-2 {
    background: #07d6cd;
  }

  .horizontal-slider .example-track {
    top: 20px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    top: 15px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
  }
`

const RebalanceSplitForm: FC<IRebalanceSplitForm> = ({ splitPercents, setSplitPercents }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [trainValue, setTrainValue] = useState(splitPercents.train)
  const [validValue, setValidValue] = useState(splitPercents.valid)
  const [testValue, setTestValue] = useState(splitPercents.test)
  const onReactSliderChange = (value: number | readonly number[], index: number) => {
    if (value instanceof Array) {
      setTrainValue(value[0])
      setValidValue(value[1] - value[0])
      setTestValue(100 - value[1])
    }
  }

  const onCantelClick = () => {
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onApplyClick = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    setSplitPercents({ train: trainValue, valid: validValue, test: testValue })
  }

  return (
    <FlexContainer direction="column" justify="center" align="center">
      <StyledWrapper>
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          defaultValue={[trainValue, 100 - testValue]}
          ariaLabel={['Lower thumb', 'Upper thumb']}
          ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => <div {...props}></div>}
          pearling
          minDistance={5}
          onChange={onReactSliderChange}
        />
        <FlexContainer direction="row" justify="space-around" align="center" margin="25px 0 0 0">
          <StyledStplitCardPercents percentColor={'#8f2ceb'}>{trainValue}%</StyledStplitCardPercents>
          <StyledStplitCardPercents percentColor={Colors.green}>{validValue}%</StyledStplitCardPercents>
          <StyledStplitCardPercents percentColor={'#07d6cd'}>{testValue}%</StyledStplitCardPercents>
        </FlexContainer>
        <FlexContainer direction="row" justify="space-between" align="center" paddingProps="35px 0 0 0">
          <ProjectButton onClick={onCantelClick}>{t('buttons.cancel')}</ProjectButton>
          <ProjectButton onClick={onApplyClick}>{t('buttons.apply')}</ProjectButton>
        </FlexContainer>
      </StyledWrapper>
    </FlexContainer>
  )
}

export default RebalanceSplitForm
