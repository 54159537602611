import React, { useEffect, useState } from 'react'
import { StyledEmptyDiv, StyledFilterText, StyledGreaterThanIcon, StyledLessThanIcon, StyledListFooter, StyledListSliderWrapper } from '../constants/styled'
import { StyledArrowWrapper, StyledColoredSpan } from '../../Draw/Styled/styled'
import { Colors } from '../../../models/Colors'
import { ISplit } from '../store/ImagesListReducer'
import ProjectSelect from '../../../UI/ProjectSelect/ProjectSelect'
import { useTranslation } from 'react-i18next'
import FlexContainer from '../../../components/FlexContainer/FlexContainer'

interface IListFooterProps {
  onLessThanClick: () => void
  onGreaterThanClick: () => void
  pages: number[]
  currentPage: number
  total: number
  limits: ISplit[]
  imagesOnPage: number
  currentLimit: ISplit
  onLimitChange: (value: string) => void
}

const ListFooter: React.FC<IListFooterProps> = ({
  onLessThanClick,
  onGreaterThanClick,
  pages,
  currentPage,
  total,
  limits,
  imagesOnPage,
  currentLimit,
  onLimitChange,
  ...props
}) => {
  const { t } = useTranslation()
  const [fromNumber, setFromNumber] = useState(0)
  const [toNumber, setToNumber] = useState(0)

  const getFromNumber = () => {
    if (currentPage === 1) {
      setFromNumber(1)
      return
    }
    if (currentPage !== 1) {
      setFromNumber(+currentLimit.value * (currentPage - 1) + 1)
    }
  }

  const getToNumber = () => {
    if (total < (+currentLimit.value * currentPage)) {
      setToNumber(total) 
      return
    }
    setToNumber(+currentLimit.value * currentPage)
  }

  useEffect(() => {
    getFromNumber()
    getToNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentLimit, total])

  return (
    <StyledListFooter>
      <StyledEmptyDiv>
        <FlexContainer widthProps="300px" justify="flex-start" align="center" gap="10px" id='kek'>
          <StyledFilterText>{t('imagesList.perPage')}</StyledFilterText>
          <div style={{ width: '140px' }}>
            <ProjectSelect toTop='toTop' options={limits} mode="rows" selected={currentLimit || null} onChange={onLimitChange} placeholder={currentLimit?.value} />
          </div>
        </FlexContainer>
      </StyledEmptyDiv>
      <StyledListSliderWrapper>
        <StyledArrowWrapper onClick={onLessThanClick}>
          <StyledLessThanIcon widthProps="15px" heightProps="15px" />
        </StyledArrowWrapper>
        <StyledColoredSpan textColor={Colors.lightgray}>{fromNumber + ' - ' + toNumber + ' ' + t('other.fromImages') + ' ' + total}</StyledColoredSpan>
        <StyledArrowWrapper onClick={onGreaterThanClick}>
          <StyledGreaterThanIcon widthProps="15px" heightProps="15px" />
        </StyledArrowWrapper>
      </StyledListSliderWrapper>
      <StyledEmptyDiv></StyledEmptyDiv>
    </StyledListFooter>
  )
}

export default ListFooter
