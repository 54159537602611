import React, { FC, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../../models/Colors'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import InputText from '../../../../../UI/InputText/InputText'
import { ISecondSlideProps } from '../../../models/ISecondSlideProps'

const StyledLabel = styled.div`
  color: ${Colors.lightgray};
  font-size: 14px;
  font-weight: 300;
  margin-left: 3px;
`

const SecondSlide: FC<ISecondSlideProps> = ({slidesValues, setSlidesValues, setVisible, setFirstSlide, setSecondSlide, setThirdSlide, textAreaText, setTextAreaText, ...props}) => {

  const onBackClick = () => {
    setSecondSlide(false)
    setFirstSlide(true)
  }

  const onContinueClick = () => {
    setSecondSlide(false)
    setThirdSlide(true)
  }

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaText(e.target.value)
  } 

  return (
    <FlexContainer direction='column' gap='20px'>
      <StyledLabel>Invite others to collaborate on projects.</StyledLabel>
      <InputText 
        value={textAreaText} 
        label='Invite via Email:' 
        onChange={onTextAreaChange} 
        placeholder="Enter email's Comma Separated" 
        
        heightProps='150px'
      />
      <FlexContainer margin='10px 0 0 0' widthProps='100%' direction='row' align='center' justify='space-between'>
        <ProjectButton onClick={onBackClick}>Back</ProjectButton>
        <ProjectButton onClick={onContinueClick}>Continue</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default SecondSlide