import React, { FC, useEffect, useState } from 'react'
import FlexContainer from '../../../../../../components/FlexContainer/FlexContainer'
import ProjectButton from '../../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/redux'
import { modalWindowSlice } from '../../../../../../store/reducers/modalWindowReducer'
import AutoMarkupSlideForm from '../../AutoMarkupSlideForm'
import Input from '../../../../../../UI/Input/Input'
import MarkupClass from '../../MarkupClass'
import Model from '../../Model'
import styled from 'styled-components'
import { Colors } from '../../../../../../models/Colors'
import { asyncGetClassesSummary, asyncGetModelsSummary } from '../../../../../../store/reducers/AcyncActions'
import { UseAutoMarkupClassesQuery } from '../../../../../../hooks/UseAutoMarkupClassesQuery'
import { IAutoMarkupModelWithTagClass } from '../../../../store/AutoMarkupSlice'
import { StyledBr, StyledClassesWrapper, StyledLeftTryColumn, 
         StyledRightTryColumn, StyledSlideModelsWrapper, StyledSlideP, 
         StyledTryColumnsWrapper, StyledTryModelsSlideWrapper, StyledVerticalBr } from '../../../../Styled/styled'
import { useTranslation } from 'react-i18next'

interface ITryModelsSlideProps {
  
}

interface ITabProps {
  active: boolean;
}

const StyledTabs = styled.div`
  width: 100%;
  padding: 10px;
`

const StyledTab = styled.div<ITabProps>`
  color: ${({active}) => active ? Colors.orange : Colors.textgray};
  cursor: pointer;
  transition: all 0.1s linear;
  :hover {
    color: ${Colors.orange};
  }
`

const TryModelsSlide: FC<ITryModelsSlideProps> = ({...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {modelsSummary, classesSummary, classesState} = useAppSelector(state => state.AutoMarkupReducer)
  const [isFirstTabClicked, setIsFirstTabClicked] = useState(true)
  const [isSecondTabClicked, setIsSecondTabClicked] = useState(false)
  // console.log('classesState', classesState)
  // console.log('modelsSummary', modelsSummary)

  const [filteredModels, setFilteredModels] = useState<IAutoMarkupModelWithTagClass[]>([])
  const [filter, setFilter] = useState({query: ''})
  const queryClasses = UseAutoMarkupClassesQuery(isFirstTabClicked ? classesSummary?.classes : [], filter.query)
  // console.log('filteredModels', filteredModels)

  useEffect(() => {
    dispatch(asyncGetModelsSummary())
    dispatch(asyncGetClassesSummary())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {    
    if (!classesState?.length) {
      setFilteredModels([])
    }

    if (classesState?.length) {
      const selectedTags: string[] = []
      const modelsWithTagClasses: IAutoMarkupModelWithTagClass[] = []

      classesState.forEach(cl => selectedTags?.push(cl?.id))

      modelsSummary.models.forEach(model => {
        const modelWithTagClasses: IAutoMarkupModelWithTagClass = {...model, Classes: []}
        model.Classes.forEach(cl => modelWithTagClasses?.Classes?.push(cl?.id))
        modelsWithTagClasses.push(modelWithTagClasses)
      })

      console.log('TAGMODELS', modelsWithTagClasses)
      console.log('TAGS', selectedTags)

      let filteredTagModels = modelsWithTagClasses?.filter(model => selectedTags?.every( tag => model?.Classes?.includes(tag)));

      setFilteredModels(filteredTagModels)

      // Фильтр моделей по выбранным классам, отображает все модели, не проверяет наличие конкретного
      // класса в каждой модели. Нужно доработать, проверка на наличие модели.
      // const kek: any[] = []
      // classesState.forEach( (qClass, qClassIndex) => {
      //   qClass.Models.forEach(model => {
      //     const findedModel = modelsSummary.models.find(summaryModel => summaryModel.id === model.id)
      //     if (findedModel) {
      //       const findedModelInKek = kek.find(kek => kek.id === findedModel.id)
      //       if (!findedModelInKek) {
      //         kek.push(findedModel)
      //       }
      //     } 
      //   })
      // })
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesState])

  const onCancelClick = () => {
    dispatch(modalWindowSlice.actions.setInititialState())
    dispatch(modalWindowSlice.actions.setIsCloseability(false))
    dispatch(modalWindowSlice.actions.setModalWidth('580px'))
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.draw.automarkupTool.title')))
    dispatch(modalWindowSlice.actions.setChildren(<AutoMarkupSlideForm slide='first'/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onFirstTabClick = () => {
    setIsFirstTabClicked(true)
    setIsSecondTabClicked(false)
  }

  // const onSecondTabClick = () => {
  //   setIsFirstTabClicked(false)
  //   setIsSecondTabClicked(true)
  // }

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({...filter, query: e.target.value})
  }

  return (
    <StyledTryModelsSlideWrapper>
        <StyledTryColumnsWrapper>
            <StyledLeftTryColumn>
              <Input value={filter.query} onChange={onSearchChange} widthProps='260px' label={t('authorized.project.draw.automarkupTool.searchClasses')} placeholder={t('forum.enterText')}/>
              <StyledClassesWrapper>
                {queryClasses?.length ? queryClasses?.map(autoMarkupClass =>
                  <MarkupClass key={autoMarkupClass.id} label={autoMarkupClass.displayName} classInfo={autoMarkupClass} queryClasses={queryClasses}/>
                ) : ''}
              </StyledClassesWrapper>
            </StyledLeftTryColumn>

            <StyledVerticalBr/>

            <StyledRightTryColumn>
              <StyledSlideP>
                {t('authorized.project.draw.automarkupTool.fullText')} <br/>
              </StyledSlideP>
              <StyledBr/>
              <StyledTabs>
                <FlexContainer direction='row' justify='space-around'>
                  <StyledTab onClick={onFirstTabClick} active={isFirstTabClicked}>{t('authorized.project.draw.automarkupTool.classesModelsTitle')} ({filteredModels?.length})</StyledTab>
                  {/* <StyledTab onClick={onSecondTabClick} active={isSecondTabClicked}>Your Models (0)</StyledTab> */}
                </FlexContainer>
                  <StyledSlideModelsWrapper>
                    {isFirstTabClicked && filteredModels?.length ? filteredModels?.map( model => 
                        <Model 
                          key={model.id} 
                          name={model.displayName} 
                          imagePath='' 
                          // isTestPassed={true}
                          // isTestPassed={false}
                          isTestPassed={model.usedOnMultipleImages || model.usedOnSingleImage}
                          tagModelInfo={model}
                        />
                      ) : ''}
                    {isFirstTabClicked && !filteredModels?.length && !classesState?.length
                      ? t('authorized.project.draw.automarkupTool.selectClasses')
                      : ''
                    }
                    {isFirstTabClicked && !filteredModels?.length && classesState?.length 
                      ? t('authorized.project.draw.automarkupTool.noModelClasses')
                      : ''
                    }
                    {isSecondTabClicked && ''
                      // <Model name="UserModel" imagePath='' isTestPassed={isTestPassed}/>
                      // <FlexContainer 
                      //     direction='column' 
                      //     widthProps='100%' 
                      //     heightProps='100%' 
                      //     justify='center' 
                      //     align='center' 
                      //     gap='15px'
                      //     margin='40px 0 0 0'
                      // >
                      //     <div>You don't have your own models :(</div>
                      //     <StyledSlideColoredSpan>Click and Upload here.</StyledSlideColoredSpan>
                      // </FlexContainer>
                    }
                  </StyledSlideModelsWrapper>
              </StyledTabs>
            </StyledRightTryColumn>
        </StyledTryColumnsWrapper>

        <FlexContainer direction='column' justify='center' align='center'>
          <ProjectButton onClick={onCancelClick} heightProps='30px'>{t('buttons.back')}</ProjectButton>
        </FlexContainer>
    </StyledTryModelsSlideWrapper>
  )
}

export default TryModelsSlide