import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components';
import FlexContainer from '../../../../../../../components/FlexContainer/FlexContainer';
import { Colors } from '../../../../../../../models/Colors';
import { modalWindowSlice } from '../../../../../../../store/reducers/modalWindowReducer';
import { useAppDispatch, useAppSelector } from './../../../../../../../hooks/redux';
import { IUserNavDropdownProps } from './../../../../../../../models/IUserNavDropdownProps';
import ProjectButton from './../../../../../../../UI/Buttons/ProjectButton/ProjectButton';
import DropdownButton from './../../../../../../../UI/Buttons/DropdownButton/DropdownButton';
import RenameAnnotatingJobForm from './RenameAnnotatingJobForm';
import burgerIcon from '../../../../../../../assets/icons/hamburger-menu-svgrepo-com.svg'
import { asyncDeleteTask, asyncGetAllBatches, asyncGetTasksSummary } from '../../../../../../../store/reducers/AcyncActions';
import TasksService, { ISummaryTask } from '../../../../../../../services/TasksService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IProject } from '../../../../../../../services/ProjectService';

const StyledBurgerIcon = styled.i`
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-image: url(${burgerIcon});
  mask-image: url(${burgerIcon});
  background-color: ${Colors.orange};
  width: 30px;
  height: 30px;
`

interface IDatasetJobDropdownProps {
  projectInfo: IProject;
  jobInfo: ISummaryTask;
}

const StyledDropDown = styled.div`
  margin: 0 10px;
  cursor: pointer;
  .username {
      height: 30px;
      padding: 5px;
  }
`
const StyledDropdownContent = styled.div<IUserNavDropdownProps>`
  display: ${props => props.visible ? 'block' : 'none'};
  width: 160px;
  height: auto;

  background-color: ${Colors.dark};
  border: 2px solid ${Colors.gray};
  border-radius: 5px;

  position: absolute;
  top: 60%;
  right: -20%;
  z-index: 1999;
`
const StyledSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width:30px;
  height: 30px;

  font-weight: bold;
  font-size: 10px;
  cursor: pointer;

  .count {
      position: absolute;
      top: -6px;
      right: 4px;
  }
`

const AnnotatingJobDropDown: FC<IDatasetJobDropdownProps> = ({projectInfo, jobInfo, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userRoleNumber = useAppSelector(state => state.TabsReducer.userRoleNumber)
  const currentUser = useAppSelector(state => state.userReduser.user)
  const viewMode = useAppSelector(state => state.TabsReducer.viewMode)

  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [activeColor, setActiveColor] = useState(false)
  const [isUnavailable, setIsUnavailable] = useState(false)

  useEffect(() => {
    if ((jobInfo?.status?.name === 'processing' || 
         jobInfo?.status?.name === 'in_queue'   ||
         jobInfo?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer')) {
      setIsUnavailable(true)
    } else {
      setIsUnavailable(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[jobInfo])

  const setModalVisivble = () => {
    dispatch(modalWindowSlice.actions.setVisible())
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onDropdownClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setDropdownVisible(!dropdownVisible)
  }

  const onDropdownMouseLeave = () => {
    setDropdownVisible(!dropdownVisible)
  }
  
  const onSettingsMouseOver = () => {
    setActiveColor(!activeColor)
  }

  const onSettingsMouseLeave = () => {
    setActiveColor(!activeColor)
  }

  const onButtonDeleteClick = () => {
    if ((jobInfo?.status?.name === 'processing' || 
         jobInfo?.status?.name === 'in_queue'   ||
         jobInfo?.status?.name === 'automarkup') && (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer')) {
      return
    }
    dispatch(asyncDeleteTask(jobInfo?.task, projectInfo.id))
    dispatch(modalWindowSlice.actions.setInititialState())
  }

  const onRenameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if ((jobInfo?.status?.name === 'processing' || 
         jobInfo?.status?.name === 'in_queue'   ||
         jobInfo?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer')) {
      return
    }
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.annotate.forms.renameTItle')))
    dispatch(modalWindowSlice.actions.setChildren(<RenameAnnotatingJobForm projectInfo={projectInfo} jobInfo={jobInfo}/>))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onDeleteClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if ((jobInfo?.status?.name === 'processing' || 
         jobInfo?.status?.name === 'in_queue'   ||
         jobInfo?.status?.name === 'automarkup') && 
        (currentUser?.role?.name !== 'global_admin' && currentUser?.role?.name !== 'developer')) {
      return
    }
    dispatch(modalWindowSlice.actions.setTitle(t('authorized.project.annotate.forms.deleteTitle')))
    dispatch(modalWindowSlice.actions.setChildren(
      <FlexContainer direction='row' justify='space-around' paddingProps='20px 0 0 0'>
        <ProjectButton onClick={setModalVisivble}>{t('buttons.cancel')}</ProjectButton>
        <ProjectButton onClick={onButtonDeleteClick}>{t('other.delete')}</ProjectButton>
      </FlexContainer>
    ))
    dispatch(modalWindowSlice.actions.setVisible())
  }

  const onModerateClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (jobInfo?.status?.name === 'processing' || 
        jobInfo?.status?.name === 'in_queue'   ||
        jobInfo?.status?.name === 'automarkup' ) {
      return
    }
    try {
      const responce = await TasksService.putTask(jobInfo.task, jobInfo.name, jobInfo.description, jobInfo.status.id, currentUser.id, jobInfo?.annotator?.id ? jobInfo.annotator.id : '')
      console.log('moderate', responce)
      if (responce?.data?.task?.id) {
        dispatch(asyncGetTasksSummary(projectInfo.id))
        console.log('moderate summary', responce)
        setDropdownVisible(false)
      }
    } catch (error) {
      console.log('onModerateClick', error)
    }
  }

  const onAnnotateClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (jobInfo?.status?.name === 'processing' || 
        jobInfo?.status?.name === 'in_queue'   ||
        jobInfo?.status?.name === 'automarkup' ) {
      return
    }
    try {
      const responce = await TasksService.putTask(jobInfo.task, jobInfo.name, jobInfo.description, '2604237c-455b-49b2-ba54-417c5c65d326', jobInfo?.moderator?.id ? jobInfo.moderator.id : '', currentUser.id)
      if (responce?.data?.task?.id) {
        navigate(`/projects/${projectInfo.id}/job/annotate/${responce.data.task.id}`)
      }
    } catch (error) {
      console.log('onAnnotateClick', error)
    }
  }

  return (
    <StyledDropDown {...props}>
        <StyledDropdownContent visible={dropdownVisible} onMouseLeave={onDropdownMouseLeave}>
            {userRoleNumber <= 4 && viewMode.value === 'ADMIN' ? <DropdownButton onClick={onRenameClick} heightProps={'30px'} colorProps={isUnavailable ? Colors.darkOrange : Colors.orange}>{t('other.rename')}</DropdownButton> : ''}
            {userRoleNumber <= 4 && viewMode.value === 'ADMIN' ? <DropdownButton onClick={onDeleteClick} colorProps={isUnavailable ? Colors.redSaturated : Colors.red} heightProps={'30px'}>{t('other.delete')}</DropdownButton> : ''}
            {userRoleNumber >= 5 && viewMode.value === 'LABELLER' && !jobInfo?.annotator?.id ? <DropdownButton onClick={onAnnotateClick} heightProps={'30px'}>{t('authorized.project.annotate.startAnnotate')}</DropdownButton> : ''}
            {userRoleNumber >= 5 && viewMode.value === 'MODER'  && !jobInfo?.moderator?.id ? <DropdownButton onClick={onModerateClick} heightProps={'30px'}>{t('authorized.project.annotate.startModerate')}</DropdownButton> : ''}

            {userRoleNumber >= 5 && viewMode.value === 'LABELLER' && jobInfo?.annotator?.id ? <DropdownButton onClick={(e) => e.stopPropagation()} heightProps={'30px'}>{t('authorized.project.annotate.noActions')}</DropdownButton> : ''}
            {userRoleNumber >= 5 && viewMode.value === 'MODER' && jobInfo?.moderator?.id? <DropdownButton onClick={(e) => e.stopPropagation()} heightProps={'30px'}>{t('authorized.project.annotate.noActions')}</DropdownButton> : ''}

            {userRoleNumber <= 4 && (viewMode.value === 'LABELLER' || viewMode.value === 'MODER') ? <DropdownButton onClick={(e) => e.stopPropagation()} heightProps={'30px'}>{t('authorized.project.annotate.noActions')}</DropdownButton> : ''}
        </StyledDropdownContent>
        <FlexContainer paddingProps='0' direction='row' justify='center' align='center' onClick={onDropdownClick}>
            <StyledSettings>
                <StyledBurgerIcon {...props} onMouseOver={onSettingsMouseOver} onMouseLeave={onSettingsMouseLeave}/>
            </StyledSettings>
        </FlexContainer>
    </StyledDropDown> 
  )
}

export default AnnotatingJobDropDown