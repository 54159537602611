import { $authHost, $formDataAuthHost } from "../http/index";
import { AxiosResponse } from "axios";


interface IImagesInUploadResponce {
    path: 'string',
    displayname: 'string',
    filename: 'string',
}

interface IFailedImage {
    error: string;
    filename: string;
}

export interface IUploadResponce {
    batch: 'string',
    failedFiles?: IFailedImage[],
    files: IImagesInUploadResponce[],
    message: string,
    error?: string;  
}

export interface IUploadDetectRes {
    job: string;
    service: string;
    message: string;
}

export interface IUploadDetectProgress {
    batch: {
      id: string;
      name: string;
      user: string;
      project: string;
      imageCount: number;
      createdAt: string;
      updatedAt: string;
    },
    files: [
      {
        type: string
        filename: string
        ext: string
      }
    ],
    createdMarkupFiles: string[];
    message: string;
    progress?: string | number;
}

interface IDeleteRes {
    message: string;
}

export default class ImagesService {

    static async uploadFormData(IUploadObj: FormData): Promise<AxiosResponse<IUploadResponce>> {
        return $formDataAuthHost.post<IUploadResponce>('/upload', IUploadObj)
    }

    static async createUploadJob(batchId: string): Promise<AxiosResponse<IUploadDetectRes>> {
        return $authHost.post<IUploadDetectRes>('/upload/detect', {service: 'detect', batch: batchId})
    }

    static async getUploadDetectProgress(jobId: string): Promise<AxiosResponse<IUploadDetectProgress>> {
        return $authHost.get<IUploadDetectProgress>(`/upload/detect/${jobId}`)
    }

    static async deleteUploadDetectJob(jobId: string): Promise<AxiosResponse<IDeleteRes>> {
        return $authHost.delete<IDeleteRes>(`/upload/detect/${jobId}`)
    }

}
