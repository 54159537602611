import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import FlexContainer from '../../../../../components/FlexContainer/FlexContainer'
import { Colors } from '../../../../../models/Colors'
import { asyncGetLicensesWithoutId, asyncGetOwnerAndMemberGroups } from '../../../../../store/reducers/AcyncActions'
import ProjectButton from '../../../../../UI/Buttons/ProjectButton/ProjectButton'
import { IThirdSlideProps } from '../../../models/IThirdSlideProps'
import Card from '../../Card/Card'
import { useAppDispatch, useAppSelector } from './../../../../../hooks/redux';
import { useNavigate } from 'react-router-dom'
import { WorkspacesSlice } from '../../../store/reducers/WorkspacesReducer'
import GroupService from '../../../../../services/GroupService'
import { SettingsReducer } from '../../../../UserSettings/reducers/SettingsReducer'
import { useTranslation } from 'react-i18next'

const StyledLabel = styled.div`
  color: ${Colors.lightgray};
  font-size: 14px;
  font-weight: 300;
  margin-left: 3px;
`

const ThirdSlide: React.FC<IThirdSlideProps> = ({slidesValues, setSlidesValues, setVisible, setFirstSlide, setSecondSlide, setThirdSlide, ...props}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [freeActive, setFreeActive] = useState(false)
  const [businessActive, setBusinessActive] = useState(false)
  const { licenses } = useAppSelector(state => state.SettingsReducer)

  useEffect(() => {
    if (!licenses?.length) {
      dispatch(asyncGetLicensesWithoutId())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[licenses])

  const onBackClick = () => {
    setThirdSlide(false)
    setFirstSlide(true)
  }

  const onCreateClick = async () => {
    console.log('licenses', licenses)
    try {
      setThirdSlide(false)
      setVisible(false)
      const selectedLicense = freeActive 
      ? licenses?.find(license => license.name === 'public')
      : licenses?.find(license => license.name === 'business')

      const publicLicense = licenses?.find(license => license.name === 'public')
      console.log('selectedLicense', selectedLicense)
      console.log('publicLicense', publicLicense)
      if (selectedLicense && publicLicense) {
        dispatch(WorkspacesSlice.actions.setLoadingStart())
        const responce = await GroupService.createGroup(slidesValues.name, publicLicense?.id)
        await dispatch(asyncGetOwnerAndMemberGroups())
        console.log('asyncCreateGroup', responce)
        setFirstSlide(true)
  
        if (selectedLicense?.name === 'business') {
          dispatch(SettingsReducer.actions.setActiveTab(responce.data.group.id))
          navigate(`/settings/group/${responce.data.group.id}/licenses`)
        }
      }
      dispatch(WorkspacesSlice.actions.setLoadingEnd())
    } catch (error) {
      dispatch(WorkspacesSlice.actions.setErrorState(t('authorized.projects.groups.createForm.errors.createGroupError')))
      console.log('asyncCreateGroup', error)
    }
  }

  const onFreeClick = () => {
    setSlidesValues({...slidesValues, type: 'Free'})
    setBusinessActive(false)
    setFreeActive(true)
  }

  const onBusinessClick = () => {
    setSlidesValues({...slidesValues, type: 'Business'})
    setBusinessActive(true)
    setFreeActive(false)
  }

  return (
    <FlexContainer direction='column' gap='20px' justify='center' align='center'>
      <StyledLabel>{t('authorized.projects.groups.createForm.paragraph2')}</StyledLabel>
      <FlexContainer direction='column' justify='space-between' align='center' gap='20px'>
        <Card type="community" onClick={onFreeClick} active={freeActive}/>
        <Card type="business" onClick={onBusinessClick} active={businessActive}/>
      </FlexContainer>
      <FlexContainer margin='10px 0 0 0' widthProps='100%' direction='row' align='center' justify='space-between'>
        <ProjectButton onClick={onBackClick}>{t('buttons.back')}</ProjectButton>
        <ProjectButton onClick={onCreateClick}>{t('buttons.create')}</ProjectButton>
      </FlexContainer>
    </FlexContainer>
  )
}

export default ThirdSlide